/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		User:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Users:{
			where:"User_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countUsers:{
			where:"User_where",
			locale:"LocaleInputType"
		},
		docAccessUser:{

		},
		BlogCategory:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		BlogCategories:{
			where:"BlogCategory_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countBlogCategories:{
			where:"BlogCategory_where",
			locale:"LocaleInputType"
		},
		docAccessBlogCategory:{

		},
		Tag:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Tags:{
			where:"Tag_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countTags:{
			where:"Tag_where",
			locale:"LocaleInputType"
		},
		docAccessTag:{

		},
		SpecialTag:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		SpecialTags:{
			where:"SpecialTag_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countSpecialTags:{
			where:"SpecialTag_where",
			locale:"LocaleInputType"
		},
		docAccessSpecialTag:{

		},
		Date:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Dates:{
			where:"Date_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countDates:{
			where:"Date_where",
			locale:"LocaleInputType"
		},
		docAccessDate:{

		},
		District:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Districts:{
			where:"District_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countDistricts:{
			where:"District_where",
			locale:"LocaleInputType"
		},
		docAccessDistrict:{

		},
		Audience:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Audiences:{
			where:"Audience_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countAudiences:{
			where:"Audience_where",
			locale:"LocaleInputType"
		},
		docAccessAudience:{

		},
		Event:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Events:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEvents:{
			where:"Event_where",
			locale:"LocaleInputType"
		},
		docAccessEvent:{

		},
		Location:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Locations:{
			where:"Location_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countLocations:{
			where:"Location_where",
			locale:"LocaleInputType"
		},
		docAccessLocation:{

		},
		Post:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Posts:{
			where:"Post_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countPosts:{
			where:"Post_where",
			locale:"LocaleInputType"
		},
		docAccessPost:{

		},
		PrivateMedia:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		PrivateMedias:{
			where:"PrivateMedia_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countPrivateMedias:{
			where:"PrivateMedia_where",
			locale:"LocaleInputType"
		},
		docAccessPrivateMedia:{

		},
		Media:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		allMedia:{
			where:"Media_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countallMedia:{
			where:"Media_where",
			locale:"LocaleInputType"
		},
		docAccessMedia:{

		},
		BackupMedia:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		BackupMedias:{
			where:"BackupMedia_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countBackupMedias:{
			where:"BackupMedia_where",
			locale:"LocaleInputType"
		},
		docAccessBackupMedia:{

		},
		EventRanking:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		EventRankings:{
			where:"EventRanking_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEventRankings:{
			where:"EventRanking_where",
			locale:"LocaleInputType"
		},
		docAccessEventRanking:{

		},
		LocationRanking:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		LocationRankings:{
			where:"LocationRanking_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countLocationRankings:{
			where:"LocationRanking_where",
			locale:"LocaleInputType"
		},
		docAccessLocationRanking:{

		},
		AccountSuspendRequest:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		AccountSuspendRequests:{
			where:"AccountSuspendRequest_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countAccountSuspendRequests:{
			where:"AccountSuspendRequest_where",
			locale:"LocaleInputType"
		},
		docAccessAccountSuspendRequest:{

		},
		Bookmark:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Bookmarks:{
			where:"Bookmark_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countBookmarks:{
			where:"Bookmark_where",
			locale:"LocaleInputType"
		},
		docAccessBookmark:{

		},
		ViewRecord:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		ViewRecords:{
			where:"ViewRecord_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countViewRecords:{
			where:"ViewRecord_where",
			locale:"LocaleInputType"
		},
		docAccessViewRecord:{

		},
		EventCategory:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		EventCategories:{
			where:"EventCategory_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEventCategories:{
			where:"EventCategory_where",
			locale:"LocaleInputType"
		},
		docAccessEventCategory:{

		},
		Comment:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Comments:{
			where:"Comment_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countComments:{
			where:"Comment_where",
			locale:"LocaleInputType"
		},
		docAccessComment:{

		},
		EventCalendar:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		EventCalendars:{
			where:"EventCalendar_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEventCalendars:{
			where:"EventCalendar_where",
			locale:"LocaleInputType"
		},
		docAccessEventCalendar:{

		},
		Subscription:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Subscriptions:{
			where:"Subscription_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countSubscriptions:{
			where:"Subscription_where",
			locale:"LocaleInputType"
		},
		docAccessSubscription:{

		},
		EditorChoice:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		EditorChoices:{
			where:"EditorChoice_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEditorChoices:{
			where:"EditorChoice_where",
			locale:"LocaleInputType"
		},
		docAccessEditorChoice:{

		},
		BannerSection:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		BannerSections:{
			where:"BannerSection_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countBannerSections:{
			where:"BannerSection_where",
			locale:"LocaleInputType"
		},
		docAccessBannerSection:{

		},
		MacaoBannerSection:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		MacaoBannerSections:{
			where:"MacaoBannerSection_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countMacaoBannerSections:{
			where:"MacaoBannerSection_where",
			locale:"LocaleInputType"
		},
		docAccessMacaoBannerSection:{

		},
		Banner:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Banners:{
			where:"Banner_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countBanners:{
			where:"Banner_where",
			locale:"LocaleInputType"
		},
		docAccessBanner:{

		},
		Campaign:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Campaigns:{
			where:"Campaign_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countCampaigns:{
			where:"Campaign_where",
			locale:"LocaleInputType"
		},
		docAccessCampaign:{

		},
		Advertise:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Advertises:{
			where:"Advertise_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countAdvertises:{
			where:"Advertise_where",
			locale:"LocaleInputType"
		},
		docAccessAdvertise:{

		},
		Device:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Devices:{
			where:"Device_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countDevices:{
			where:"Device_where",
			locale:"LocaleInputType"
		},
		docAccessDevice:{

		},
		Notification:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Notifications:{
			where:"Notification_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countNotifications:{
			where:"Notification_where",
			locale:"LocaleInputType"
		},
		docAccessNotification:{

		},
		Newsletter:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Newsletters:{
			where:"Newsletter_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countNewsletters:{
			where:"Newsletter_where",
			locale:"LocaleInputType"
		},
		docAccessNewsletter:{

		},
		RedirectSetting:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		RedirectSettings:{
			where:"RedirectSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countRedirectSettings:{
			where:"RedirectSetting_where",
			locale:"LocaleInputType"
		},
		docAccessRedirectSetting:{

		},
		Static:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		Statics:{
			where:"Static_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countStatics:{
			where:"Static_where",
			locale:"LocaleInputType"
		},
		docAccessStatic:{

		},
		CriteriaSetting:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		CriteriaSettings:{
			where:"CriteriaSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countCriteriaSettings:{
			where:"CriteriaSetting_where",
			locale:"LocaleInputType"
		},
		docAccessCriteriaSetting:{

		},
		OrganiserSetting:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		OrganiserSettings:{
			where:"OrganiserSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countOrganiserSettings:{
			where:"OrganiserSetting_where",
			locale:"LocaleInputType"
		},
		docAccessOrganiserSetting:{

		},
		PostSetting:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		PostSettings:{
			where:"PostSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countPostSettings:{
			where:"PostSetting_where",
			locale:"LocaleInputType"
		},
		docAccessPostSetting:{

		},
		HomepageSetting:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		HomepageSettings:{
			where:"HomepageSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countHomepageSettings:{
			where:"HomepageSetting_where",
			locale:"LocaleInputType"
		},
		docAccessHomepageSetting:{

		},
		EventView:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		EventViews:{
			where:"EventView_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countEventViews:{
			where:"EventView_where",
			locale:"LocaleInputType"
		},
		docAccessEventView:{

		},
		PostView:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		PostViews:{
			where:"PostView_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countPostViews:{
			where:"PostView_where",
			locale:"LocaleInputType"
		},
		docAccessPostView:{

		},
		PayloadPreference:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		PayloadPreferences:{
			where:"PayloadPreference_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		countPayloadPreferences:{
			where:"PayloadPreference_where",
			locale:"LocaleInputType"
		},
		docAccessPayloadPreference:{

		},
		GetEvents:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetFilterEvents:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetFilterPastEvents:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetEventMaxUsedTag:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		BookmarkEvents:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetBookmarks:{
			where:"Bookmark_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		BookmarkItems:{
			where:"Bookmark_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		ViewedEvents:{
			where:"Event_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		NonFeaturedPosts:{
			where:"Post_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		CheckEmailUniqueness:{

		},
		GetOrganisers:{
			where:"User_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetOrganiser:{
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetSubscriptions:{
			where:"Subscription_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetFollowed:{
			where:"Subscription_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetComments:{
			where:"Comment_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		CommentsCount:{
			where:"Comment_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetBanners:{
			where:"Banner_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetBannerSection:{
			where:"Banner_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetNotifications:{
			where:"Notification_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetCriteriaSettings:{
			where:"CriteriaSetting_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetFilterLocations:{
			where:"Location_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetContainEventTagLocations:{
			where:"Location_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		},
		GetEventRankings:{
			where:"EventRanking_where",
			fallbackLocale:"FallbackLocaleInputType",
			locale:"LocaleInputType"
		}
	},
	User_region: "enum" as const,
	User_roles: "enum" as const,
	User_Member:{
		avatar:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"User_Member_Avatar_where"
		},
		categories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	User_Member_region: "enum" as const,
	User_Member_language: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	LocaleInputType: "enum" as const,
	FallbackLocaleInputType: "enum" as const,
	User_Member_Avatar_where:{
		alt:"User_Member_Avatar_alt_operator",
		updatedAt:"User_Member_Avatar_updatedAt_operator",
		createdAt:"User_Member_Avatar_createdAt_operator",
		url:"User_Member_Avatar_url_operator",
		filename:"User_Member_Avatar_filename_operator",
		mimeType:"User_Member_Avatar_mimeType_operator",
		filesize:"User_Member_Avatar_filesize_operator",
		width:"User_Member_Avatar_width_operator",
		height:"User_Member_Avatar_height_operator",
		focalX:"User_Member_Avatar_focalX_operator",
		focalY:"User_Member_Avatar_focalY_operator",
		sizes__small__url:"User_Member_Avatar_sizes__small__url_operator",
		sizes__small__width:"User_Member_Avatar_sizes__small__width_operator",
		sizes__small__height:"User_Member_Avatar_sizes__small__height_operator",
		sizes__small__mimeType:"User_Member_Avatar_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Member_Avatar_sizes__small__filesize_operator",
		sizes__small__filename:"User_Member_Avatar_sizes__small__filename_operator",
		sizes__medium__url:"User_Member_Avatar_sizes__medium__url_operator",
		sizes__medium__width:"User_Member_Avatar_sizes__medium__width_operator",
		sizes__medium__height:"User_Member_Avatar_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Member_Avatar_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Member_Avatar_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Member_Avatar_sizes__medium__filename_operator",
		sizes__large__url:"User_Member_Avatar_sizes__large__url_operator",
		sizes__large__width:"User_Member_Avatar_sizes__large__width_operator",
		sizes__large__height:"User_Member_Avatar_sizes__large__height_operator",
		sizes__large__mimeType:"User_Member_Avatar_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Member_Avatar_sizes__large__filesize_operator",
		sizes__large__filename:"User_Member_Avatar_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Member_Avatar_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Member_Avatar_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Member_Avatar_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Member_Avatar_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Member_Avatar_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Member_Avatar_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Member_Avatar_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Member_Avatar_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Member_Avatar_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Member_Avatar_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Member_Avatar_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Member_Avatar_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Member_Avatar_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Member_Avatar_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Member_Avatar_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Member_Avatar_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Member_Avatar_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Member_Avatar_sizes__large_jpeg__filename_operator",
		id:"User_Member_Avatar_id_operator",
		AND:"User_Member_Avatar_where_and",
		OR:"User_Member_Avatar_where_or"
	},
	User_Member_Avatar_alt_operator:{

	},
	User_Member_Avatar_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Member_Avatar_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Member_Avatar_url_operator:{

	},
	User_Member_Avatar_filename_operator:{

	},
	User_Member_Avatar_mimeType_operator:{

	},
	User_Member_Avatar_filesize_operator:{

	},
	User_Member_Avatar_width_operator:{

	},
	User_Member_Avatar_height_operator:{

	},
	User_Member_Avatar_focalX_operator:{

	},
	User_Member_Avatar_focalY_operator:{

	},
	User_Member_Avatar_sizes__small__url_operator:{

	},
	User_Member_Avatar_sizes__small__width_operator:{

	},
	User_Member_Avatar_sizes__small__height_operator:{

	},
	User_Member_Avatar_sizes__small__mimeType_operator:{

	},
	User_Member_Avatar_sizes__small__filesize_operator:{

	},
	User_Member_Avatar_sizes__small__filename_operator:{

	},
	User_Member_Avatar_sizes__medium__url_operator:{

	},
	User_Member_Avatar_sizes__medium__width_operator:{

	},
	User_Member_Avatar_sizes__medium__height_operator:{

	},
	User_Member_Avatar_sizes__medium__mimeType_operator:{

	},
	User_Member_Avatar_sizes__medium__filesize_operator:{

	},
	User_Member_Avatar_sizes__medium__filename_operator:{

	},
	User_Member_Avatar_sizes__large__url_operator:{

	},
	User_Member_Avatar_sizes__large__width_operator:{

	},
	User_Member_Avatar_sizes__large__height_operator:{

	},
	User_Member_Avatar_sizes__large__mimeType_operator:{

	},
	User_Member_Avatar_sizes__large__filesize_operator:{

	},
	User_Member_Avatar_sizes__large__filename_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__url_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__width_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__height_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__mimeType_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__filesize_operator:{

	},
	User_Member_Avatar_sizes__small_jpeg__filename_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__url_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__width_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__height_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__mimeType_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__filesize_operator:{

	},
	User_Member_Avatar_sizes__medium_jpeg__filename_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__url_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__width_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__height_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__mimeType_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__filesize_operator:{

	},
	User_Member_Avatar_sizes__large_jpeg__filename_operator:{

	},
	User_Member_Avatar_id_operator:{

	},
	User_Member_Avatar_where_and:{
		alt:"User_Member_Avatar_alt_operator",
		updatedAt:"User_Member_Avatar_updatedAt_operator",
		createdAt:"User_Member_Avatar_createdAt_operator",
		url:"User_Member_Avatar_url_operator",
		filename:"User_Member_Avatar_filename_operator",
		mimeType:"User_Member_Avatar_mimeType_operator",
		filesize:"User_Member_Avatar_filesize_operator",
		width:"User_Member_Avatar_width_operator",
		height:"User_Member_Avatar_height_operator",
		focalX:"User_Member_Avatar_focalX_operator",
		focalY:"User_Member_Avatar_focalY_operator",
		sizes__small__url:"User_Member_Avatar_sizes__small__url_operator",
		sizes__small__width:"User_Member_Avatar_sizes__small__width_operator",
		sizes__small__height:"User_Member_Avatar_sizes__small__height_operator",
		sizes__small__mimeType:"User_Member_Avatar_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Member_Avatar_sizes__small__filesize_operator",
		sizes__small__filename:"User_Member_Avatar_sizes__small__filename_operator",
		sizes__medium__url:"User_Member_Avatar_sizes__medium__url_operator",
		sizes__medium__width:"User_Member_Avatar_sizes__medium__width_operator",
		sizes__medium__height:"User_Member_Avatar_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Member_Avatar_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Member_Avatar_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Member_Avatar_sizes__medium__filename_operator",
		sizes__large__url:"User_Member_Avatar_sizes__large__url_operator",
		sizes__large__width:"User_Member_Avatar_sizes__large__width_operator",
		sizes__large__height:"User_Member_Avatar_sizes__large__height_operator",
		sizes__large__mimeType:"User_Member_Avatar_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Member_Avatar_sizes__large__filesize_operator",
		sizes__large__filename:"User_Member_Avatar_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Member_Avatar_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Member_Avatar_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Member_Avatar_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Member_Avatar_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Member_Avatar_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Member_Avatar_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Member_Avatar_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Member_Avatar_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Member_Avatar_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Member_Avatar_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Member_Avatar_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Member_Avatar_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Member_Avatar_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Member_Avatar_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Member_Avatar_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Member_Avatar_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Member_Avatar_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Member_Avatar_sizes__large_jpeg__filename_operator",
		id:"User_Member_Avatar_id_operator",
		AND:"User_Member_Avatar_where_and",
		OR:"User_Member_Avatar_where_or"
	},
	User_Member_Avatar_where_or:{
		alt:"User_Member_Avatar_alt_operator",
		updatedAt:"User_Member_Avatar_updatedAt_operator",
		createdAt:"User_Member_Avatar_createdAt_operator",
		url:"User_Member_Avatar_url_operator",
		filename:"User_Member_Avatar_filename_operator",
		mimeType:"User_Member_Avatar_mimeType_operator",
		filesize:"User_Member_Avatar_filesize_operator",
		width:"User_Member_Avatar_width_operator",
		height:"User_Member_Avatar_height_operator",
		focalX:"User_Member_Avatar_focalX_operator",
		focalY:"User_Member_Avatar_focalY_operator",
		sizes__small__url:"User_Member_Avatar_sizes__small__url_operator",
		sizes__small__width:"User_Member_Avatar_sizes__small__width_operator",
		sizes__small__height:"User_Member_Avatar_sizes__small__height_operator",
		sizes__small__mimeType:"User_Member_Avatar_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Member_Avatar_sizes__small__filesize_operator",
		sizes__small__filename:"User_Member_Avatar_sizes__small__filename_operator",
		sizes__medium__url:"User_Member_Avatar_sizes__medium__url_operator",
		sizes__medium__width:"User_Member_Avatar_sizes__medium__width_operator",
		sizes__medium__height:"User_Member_Avatar_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Member_Avatar_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Member_Avatar_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Member_Avatar_sizes__medium__filename_operator",
		sizes__large__url:"User_Member_Avatar_sizes__large__url_operator",
		sizes__large__width:"User_Member_Avatar_sizes__large__width_operator",
		sizes__large__height:"User_Member_Avatar_sizes__large__height_operator",
		sizes__large__mimeType:"User_Member_Avatar_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Member_Avatar_sizes__large__filesize_operator",
		sizes__large__filename:"User_Member_Avatar_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Member_Avatar_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Member_Avatar_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Member_Avatar_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Member_Avatar_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Member_Avatar_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Member_Avatar_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Member_Avatar_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Member_Avatar_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Member_Avatar_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Member_Avatar_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Member_Avatar_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Member_Avatar_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Member_Avatar_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Member_Avatar_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Member_Avatar_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Member_Avatar_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Member_Avatar_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Member_Avatar_sizes__large_jpeg__filename_operator",
		id:"User_Member_Avatar_id_operator",
		AND:"User_Member_Avatar_where_and",
		OR:"User_Member_Avatar_where_or"
	},
	User_Member_gender: "enum" as const,
	EventCategory:{
		tags:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		icon:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"EventCategory_Icon_where"
		}
	},
	Tag_region: "enum" as const,
	EventCategory_Icon_where:{
		alt:"EventCategory_Icon_alt_operator",
		updatedAt:"EventCategory_Icon_updatedAt_operator",
		createdAt:"EventCategory_Icon_createdAt_operator",
		url:"EventCategory_Icon_url_operator",
		filename:"EventCategory_Icon_filename_operator",
		mimeType:"EventCategory_Icon_mimeType_operator",
		filesize:"EventCategory_Icon_filesize_operator",
		width:"EventCategory_Icon_width_operator",
		height:"EventCategory_Icon_height_operator",
		focalX:"EventCategory_Icon_focalX_operator",
		focalY:"EventCategory_Icon_focalY_operator",
		sizes__small__url:"EventCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"EventCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"EventCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"EventCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"EventCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"EventCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"EventCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"EventCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"EventCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"EventCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"EventCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"EventCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"EventCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"EventCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"EventCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"EventCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"EventCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"EventCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"EventCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"EventCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"EventCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"EventCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"EventCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"EventCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"EventCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"EventCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"EventCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"EventCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"EventCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"EventCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"EventCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"EventCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"EventCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"EventCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"EventCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"EventCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"EventCategory_Icon_id_operator",
		AND:"EventCategory_Icon_where_and",
		OR:"EventCategory_Icon_where_or"
	},
	EventCategory_Icon_alt_operator:{

	},
	EventCategory_Icon_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCategory_Icon_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCategory_Icon_url_operator:{

	},
	EventCategory_Icon_filename_operator:{

	},
	EventCategory_Icon_mimeType_operator:{

	},
	EventCategory_Icon_filesize_operator:{

	},
	EventCategory_Icon_width_operator:{

	},
	EventCategory_Icon_height_operator:{

	},
	EventCategory_Icon_focalX_operator:{

	},
	EventCategory_Icon_focalY_operator:{

	},
	EventCategory_Icon_sizes__small__url_operator:{

	},
	EventCategory_Icon_sizes__small__width_operator:{

	},
	EventCategory_Icon_sizes__small__height_operator:{

	},
	EventCategory_Icon_sizes__small__mimeType_operator:{

	},
	EventCategory_Icon_sizes__small__filesize_operator:{

	},
	EventCategory_Icon_sizes__small__filename_operator:{

	},
	EventCategory_Icon_sizes__medium__url_operator:{

	},
	EventCategory_Icon_sizes__medium__width_operator:{

	},
	EventCategory_Icon_sizes__medium__height_operator:{

	},
	EventCategory_Icon_sizes__medium__mimeType_operator:{

	},
	EventCategory_Icon_sizes__medium__filesize_operator:{

	},
	EventCategory_Icon_sizes__medium__filename_operator:{

	},
	EventCategory_Icon_sizes__large__url_operator:{

	},
	EventCategory_Icon_sizes__large__width_operator:{

	},
	EventCategory_Icon_sizes__large__height_operator:{

	},
	EventCategory_Icon_sizes__large__mimeType_operator:{

	},
	EventCategory_Icon_sizes__large__filesize_operator:{

	},
	EventCategory_Icon_sizes__large__filename_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__url_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__width_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__height_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__mimeType_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__filesize_operator:{

	},
	EventCategory_Icon_sizes__small_jpeg__filename_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__url_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__width_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__height_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__mimeType_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__filesize_operator:{

	},
	EventCategory_Icon_sizes__medium_jpeg__filename_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__url_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__width_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__height_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__mimeType_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__filesize_operator:{

	},
	EventCategory_Icon_sizes__large_jpeg__filename_operator:{

	},
	EventCategory_Icon_id_operator:{

	},
	EventCategory_Icon_where_and:{
		alt:"EventCategory_Icon_alt_operator",
		updatedAt:"EventCategory_Icon_updatedAt_operator",
		createdAt:"EventCategory_Icon_createdAt_operator",
		url:"EventCategory_Icon_url_operator",
		filename:"EventCategory_Icon_filename_operator",
		mimeType:"EventCategory_Icon_mimeType_operator",
		filesize:"EventCategory_Icon_filesize_operator",
		width:"EventCategory_Icon_width_operator",
		height:"EventCategory_Icon_height_operator",
		focalX:"EventCategory_Icon_focalX_operator",
		focalY:"EventCategory_Icon_focalY_operator",
		sizes__small__url:"EventCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"EventCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"EventCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"EventCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"EventCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"EventCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"EventCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"EventCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"EventCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"EventCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"EventCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"EventCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"EventCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"EventCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"EventCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"EventCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"EventCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"EventCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"EventCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"EventCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"EventCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"EventCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"EventCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"EventCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"EventCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"EventCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"EventCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"EventCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"EventCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"EventCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"EventCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"EventCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"EventCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"EventCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"EventCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"EventCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"EventCategory_Icon_id_operator",
		AND:"EventCategory_Icon_where_and",
		OR:"EventCategory_Icon_where_or"
	},
	EventCategory_Icon_where_or:{
		alt:"EventCategory_Icon_alt_operator",
		updatedAt:"EventCategory_Icon_updatedAt_operator",
		createdAt:"EventCategory_Icon_createdAt_operator",
		url:"EventCategory_Icon_url_operator",
		filename:"EventCategory_Icon_filename_operator",
		mimeType:"EventCategory_Icon_mimeType_operator",
		filesize:"EventCategory_Icon_filesize_operator",
		width:"EventCategory_Icon_width_operator",
		height:"EventCategory_Icon_height_operator",
		focalX:"EventCategory_Icon_focalX_operator",
		focalY:"EventCategory_Icon_focalY_operator",
		sizes__small__url:"EventCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"EventCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"EventCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"EventCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"EventCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"EventCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"EventCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"EventCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"EventCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"EventCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"EventCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"EventCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"EventCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"EventCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"EventCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"EventCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"EventCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"EventCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"EventCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"EventCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"EventCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"EventCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"EventCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"EventCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"EventCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"EventCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"EventCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"EventCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"EventCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"EventCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"EventCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"EventCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"EventCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"EventCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"EventCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"EventCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"EventCategory_Icon_id_operator",
		AND:"EventCategory_Icon_where_and",
		OR:"EventCategory_Icon_where_or"
	},
	EventCategory_region: "enum" as const,
	User_Organiser:{
		logo:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"User_Organiser_Logo_where"
		},
		banner:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"User_Organiser_Banner_where"
		}
	},
	User_Organiser_Logo_where:{
		alt:"User_Organiser_Logo_alt_operator",
		updatedAt:"User_Organiser_Logo_updatedAt_operator",
		createdAt:"User_Organiser_Logo_createdAt_operator",
		url:"User_Organiser_Logo_url_operator",
		filename:"User_Organiser_Logo_filename_operator",
		mimeType:"User_Organiser_Logo_mimeType_operator",
		filesize:"User_Organiser_Logo_filesize_operator",
		width:"User_Organiser_Logo_width_operator",
		height:"User_Organiser_Logo_height_operator",
		focalX:"User_Organiser_Logo_focalX_operator",
		focalY:"User_Organiser_Logo_focalY_operator",
		sizes__small__url:"User_Organiser_Logo_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Logo_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Logo_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Logo_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Logo_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Logo_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Logo_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Logo_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Logo_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Logo_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Logo_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Logo_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Logo_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Logo_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Logo_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Logo_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Logo_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Logo_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Logo_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Logo_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Logo_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Logo_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Logo_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Logo_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Logo_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Logo_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Logo_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Logo_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Logo_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Logo_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Logo_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Logo_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Logo_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Logo_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Logo_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Logo_id_operator",
		AND:"User_Organiser_Logo_where_and",
		OR:"User_Organiser_Logo_where_or"
	},
	User_Organiser_Logo_alt_operator:{

	},
	User_Organiser_Logo_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Organiser_Logo_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Organiser_Logo_url_operator:{

	},
	User_Organiser_Logo_filename_operator:{

	},
	User_Organiser_Logo_mimeType_operator:{

	},
	User_Organiser_Logo_filesize_operator:{

	},
	User_Organiser_Logo_width_operator:{

	},
	User_Organiser_Logo_height_operator:{

	},
	User_Organiser_Logo_focalX_operator:{

	},
	User_Organiser_Logo_focalY_operator:{

	},
	User_Organiser_Logo_sizes__small__url_operator:{

	},
	User_Organiser_Logo_sizes__small__width_operator:{

	},
	User_Organiser_Logo_sizes__small__height_operator:{

	},
	User_Organiser_Logo_sizes__small__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__small__filesize_operator:{

	},
	User_Organiser_Logo_sizes__small__filename_operator:{

	},
	User_Organiser_Logo_sizes__medium__url_operator:{

	},
	User_Organiser_Logo_sizes__medium__width_operator:{

	},
	User_Organiser_Logo_sizes__medium__height_operator:{

	},
	User_Organiser_Logo_sizes__medium__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__medium__filesize_operator:{

	},
	User_Organiser_Logo_sizes__medium__filename_operator:{

	},
	User_Organiser_Logo_sizes__large__url_operator:{

	},
	User_Organiser_Logo_sizes__large__width_operator:{

	},
	User_Organiser_Logo_sizes__large__height_operator:{

	},
	User_Organiser_Logo_sizes__large__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__large__filesize_operator:{

	},
	User_Organiser_Logo_sizes__large__filename_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__url_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__width_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__height_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__filesize_operator:{

	},
	User_Organiser_Logo_sizes__small_jpeg__filename_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__url_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__width_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__height_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__filesize_operator:{

	},
	User_Organiser_Logo_sizes__medium_jpeg__filename_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__url_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__width_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__height_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__mimeType_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__filesize_operator:{

	},
	User_Organiser_Logo_sizes__large_jpeg__filename_operator:{

	},
	User_Organiser_Logo_id_operator:{

	},
	User_Organiser_Logo_where_and:{
		alt:"User_Organiser_Logo_alt_operator",
		updatedAt:"User_Organiser_Logo_updatedAt_operator",
		createdAt:"User_Organiser_Logo_createdAt_operator",
		url:"User_Organiser_Logo_url_operator",
		filename:"User_Organiser_Logo_filename_operator",
		mimeType:"User_Organiser_Logo_mimeType_operator",
		filesize:"User_Organiser_Logo_filesize_operator",
		width:"User_Organiser_Logo_width_operator",
		height:"User_Organiser_Logo_height_operator",
		focalX:"User_Organiser_Logo_focalX_operator",
		focalY:"User_Organiser_Logo_focalY_operator",
		sizes__small__url:"User_Organiser_Logo_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Logo_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Logo_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Logo_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Logo_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Logo_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Logo_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Logo_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Logo_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Logo_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Logo_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Logo_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Logo_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Logo_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Logo_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Logo_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Logo_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Logo_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Logo_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Logo_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Logo_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Logo_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Logo_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Logo_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Logo_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Logo_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Logo_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Logo_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Logo_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Logo_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Logo_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Logo_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Logo_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Logo_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Logo_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Logo_id_operator",
		AND:"User_Organiser_Logo_where_and",
		OR:"User_Organiser_Logo_where_or"
	},
	User_Organiser_Logo_where_or:{
		alt:"User_Organiser_Logo_alt_operator",
		updatedAt:"User_Organiser_Logo_updatedAt_operator",
		createdAt:"User_Organiser_Logo_createdAt_operator",
		url:"User_Organiser_Logo_url_operator",
		filename:"User_Organiser_Logo_filename_operator",
		mimeType:"User_Organiser_Logo_mimeType_operator",
		filesize:"User_Organiser_Logo_filesize_operator",
		width:"User_Organiser_Logo_width_operator",
		height:"User_Organiser_Logo_height_operator",
		focalX:"User_Organiser_Logo_focalX_operator",
		focalY:"User_Organiser_Logo_focalY_operator",
		sizes__small__url:"User_Organiser_Logo_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Logo_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Logo_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Logo_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Logo_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Logo_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Logo_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Logo_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Logo_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Logo_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Logo_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Logo_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Logo_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Logo_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Logo_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Logo_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Logo_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Logo_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Logo_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Logo_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Logo_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Logo_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Logo_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Logo_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Logo_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Logo_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Logo_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Logo_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Logo_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Logo_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Logo_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Logo_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Logo_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Logo_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Logo_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Logo_id_operator",
		AND:"User_Organiser_Logo_where_and",
		OR:"User_Organiser_Logo_where_or"
	},
	User_Organiser_Banner_where:{
		alt:"User_Organiser_Banner_alt_operator",
		updatedAt:"User_Organiser_Banner_updatedAt_operator",
		createdAt:"User_Organiser_Banner_createdAt_operator",
		url:"User_Organiser_Banner_url_operator",
		filename:"User_Organiser_Banner_filename_operator",
		mimeType:"User_Organiser_Banner_mimeType_operator",
		filesize:"User_Organiser_Banner_filesize_operator",
		width:"User_Organiser_Banner_width_operator",
		height:"User_Organiser_Banner_height_operator",
		focalX:"User_Organiser_Banner_focalX_operator",
		focalY:"User_Organiser_Banner_focalY_operator",
		sizes__small__url:"User_Organiser_Banner_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Banner_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Banner_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Banner_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Banner_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Banner_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Banner_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Banner_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Banner_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Banner_id_operator",
		AND:"User_Organiser_Banner_where_and",
		OR:"User_Organiser_Banner_where_or"
	},
	User_Organiser_Banner_alt_operator:{

	},
	User_Organiser_Banner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Organiser_Banner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_Organiser_Banner_url_operator:{

	},
	User_Organiser_Banner_filename_operator:{

	},
	User_Organiser_Banner_mimeType_operator:{

	},
	User_Organiser_Banner_filesize_operator:{

	},
	User_Organiser_Banner_width_operator:{

	},
	User_Organiser_Banner_height_operator:{

	},
	User_Organiser_Banner_focalX_operator:{

	},
	User_Organiser_Banner_focalY_operator:{

	},
	User_Organiser_Banner_sizes__small__url_operator:{

	},
	User_Organiser_Banner_sizes__small__width_operator:{

	},
	User_Organiser_Banner_sizes__small__height_operator:{

	},
	User_Organiser_Banner_sizes__small__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__small__filesize_operator:{

	},
	User_Organiser_Banner_sizes__small__filename_operator:{

	},
	User_Organiser_Banner_sizes__medium__url_operator:{

	},
	User_Organiser_Banner_sizes__medium__width_operator:{

	},
	User_Organiser_Banner_sizes__medium__height_operator:{

	},
	User_Organiser_Banner_sizes__medium__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__medium__filesize_operator:{

	},
	User_Organiser_Banner_sizes__medium__filename_operator:{

	},
	User_Organiser_Banner_sizes__large__url_operator:{

	},
	User_Organiser_Banner_sizes__large__width_operator:{

	},
	User_Organiser_Banner_sizes__large__height_operator:{

	},
	User_Organiser_Banner_sizes__large__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__large__filesize_operator:{

	},
	User_Organiser_Banner_sizes__large__filename_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__url_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__width_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__height_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__filesize_operator:{

	},
	User_Organiser_Banner_sizes__small_jpeg__filename_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__url_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__width_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__height_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__filesize_operator:{

	},
	User_Organiser_Banner_sizes__medium_jpeg__filename_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__url_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__width_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__height_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__mimeType_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__filesize_operator:{

	},
	User_Organiser_Banner_sizes__large_jpeg__filename_operator:{

	},
	User_Organiser_Banner_id_operator:{

	},
	User_Organiser_Banner_where_and:{
		alt:"User_Organiser_Banner_alt_operator",
		updatedAt:"User_Organiser_Banner_updatedAt_operator",
		createdAt:"User_Organiser_Banner_createdAt_operator",
		url:"User_Organiser_Banner_url_operator",
		filename:"User_Organiser_Banner_filename_operator",
		mimeType:"User_Organiser_Banner_mimeType_operator",
		filesize:"User_Organiser_Banner_filesize_operator",
		width:"User_Organiser_Banner_width_operator",
		height:"User_Organiser_Banner_height_operator",
		focalX:"User_Organiser_Banner_focalX_operator",
		focalY:"User_Organiser_Banner_focalY_operator",
		sizes__small__url:"User_Organiser_Banner_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Banner_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Banner_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Banner_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Banner_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Banner_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Banner_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Banner_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Banner_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Banner_id_operator",
		AND:"User_Organiser_Banner_where_and",
		OR:"User_Organiser_Banner_where_or"
	},
	User_Organiser_Banner_where_or:{
		alt:"User_Organiser_Banner_alt_operator",
		updatedAt:"User_Organiser_Banner_updatedAt_operator",
		createdAt:"User_Organiser_Banner_createdAt_operator",
		url:"User_Organiser_Banner_url_operator",
		filename:"User_Organiser_Banner_filename_operator",
		mimeType:"User_Organiser_Banner_mimeType_operator",
		filesize:"User_Organiser_Banner_filesize_operator",
		width:"User_Organiser_Banner_width_operator",
		height:"User_Organiser_Banner_height_operator",
		focalX:"User_Organiser_Banner_focalX_operator",
		focalY:"User_Organiser_Banner_focalY_operator",
		sizes__small__url:"User_Organiser_Banner_sizes__small__url_operator",
		sizes__small__width:"User_Organiser_Banner_sizes__small__width_operator",
		sizes__small__height:"User_Organiser_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"User_Organiser_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"User_Organiser_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"User_Organiser_Banner_sizes__small__filename_operator",
		sizes__medium__url:"User_Organiser_Banner_sizes__medium__url_operator",
		sizes__medium__width:"User_Organiser_Banner_sizes__medium__width_operator",
		sizes__medium__height:"User_Organiser_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"User_Organiser_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"User_Organiser_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"User_Organiser_Banner_sizes__medium__filename_operator",
		sizes__large__url:"User_Organiser_Banner_sizes__large__url_operator",
		sizes__large__width:"User_Organiser_Banner_sizes__large__width_operator",
		sizes__large__height:"User_Organiser_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"User_Organiser_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"User_Organiser_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"User_Organiser_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"User_Organiser_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"User_Organiser_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"User_Organiser_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"User_Organiser_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"User_Organiser_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"User_Organiser_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"User_Organiser_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"User_Organiser_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"User_Organiser_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"User_Organiser_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"User_Organiser_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"User_Organiser_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"User_Organiser_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"User_Organiser_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"User_Organiser_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"User_Organiser_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"User_Organiser_Banner_sizes__large_jpeg__filename_operator",
		id:"User_Organiser_Banner_id_operator",
		AND:"User_Organiser_Banner_where_and",
		OR:"User_Organiser_Banner_where_or"
	},
	User_Organiser_language: "enum" as const,
	User_status: "enum" as const,
	EmailAddress: `scalar.EmailAddress` as const,
	User_where:{
		region:"User_region_operator",
		name:"User_name_operator",
		displayEmail:"User_displayEmail_operator",
		legacyUsername:"User_legacyUsername_operator",
		roles:"User_roles_operator",
		member__region:"User_member__region_operator",
		member__language:"User_member__language_operator",
		member__phone:"User_member__phone_operator",
		member__avatar:"User_member__avatar_operator",
		member__birthday:"User_member__birthday_operator",
		member__gender:"User_member__gender_operator",
		member__categories:"User_member__categories_operator",
		organiser__logo:"User_organiser__logo_operator",
		organiser__banner:"User_organiser__banner_operator",
		organiser__description:"User_organiser__description_operator",
		organiser__foundedIn:"User_organiser__foundedIn_operator",
		organiser__address:"User_organiser__address_operator",
		organiser__website:"User_organiser__website_operator",
		organiser__facebook:"User_organiser__facebook_operator",
		organiser__instagram:"User_organiser__instagram_operator",
		organiser__language:"User_organiser__language_operator",
		organiser__phone:"User_organiser__phone_operator",
		organiser__followers:"User_organiser__followers_operator",
		emailVerified:"User_emailVerified_operator",
		sso__googleId:"User_sso__googleId_operator",
		sso__facebookId:"User_sso__facebookId_operator",
		sso__appleId:"User_sso__appleId_operator",
		oldPassword:"User_oldPassword_operator",
		status:"User_status_operator",
		updatedAt:"User_updatedAt_operator",
		createdAt:"User_createdAt_operator",
		email:"User_email_operator",
		id:"User_id_operator",
		AND:"User_where_and",
		OR:"User_where_or"
	},
	User_region_operator:{
		equals:"User_region_Input",
		not_equals:"User_region_Input",
		in:"User_region_Input",
		not_in:"User_region_Input",
		all:"User_region_Input"
	},
	User_region_Input: "enum" as const,
	User_name_operator:{

	},
	User_displayEmail_operator:{

	},
	User_legacyUsername_operator:{

	},
	User_roles_operator:{
		equals:"User_roles_Input",
		not_equals:"User_roles_Input",
		in:"User_roles_Input",
		not_in:"User_roles_Input",
		all:"User_roles_Input"
	},
	User_roles_Input: "enum" as const,
	User_member__region_operator:{
		equals:"User_member__region_Input",
		not_equals:"User_member__region_Input",
		in:"User_member__region_Input",
		not_in:"User_member__region_Input",
		all:"User_member__region_Input"
	},
	User_member__region_Input: "enum" as const,
	User_member__language_operator:{
		equals:"User_member__language_Input",
		not_equals:"User_member__language_Input",
		in:"User_member__language_Input",
		not_in:"User_member__language_Input",
		all:"User_member__language_Input"
	},
	User_member__language_Input: "enum" as const,
	User_member__phone_operator:{

	},
	User_member__avatar_operator:{

	},
	User_member__birthday_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_member__gender_operator:{
		equals:"User_member__gender_Input",
		not_equals:"User_member__gender_Input",
		in:"User_member__gender_Input",
		not_in:"User_member__gender_Input",
		all:"User_member__gender_Input"
	},
	User_member__gender_Input: "enum" as const,
	User_member__categories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	JSON: `scalar.JSON` as const,
	User_organiser__logo_operator:{

	},
	User_organiser__banner_operator:{

	},
	User_organiser__description_operator:{

	},
	User_organiser__foundedIn_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_organiser__address_operator:{

	},
	User_organiser__website_operator:{

	},
	User_organiser__facebook_operator:{

	},
	User_organiser__instagram_operator:{

	},
	User_organiser__language_operator:{
		equals:"User_organiser__language_Input",
		not_equals:"User_organiser__language_Input",
		in:"User_organiser__language_Input",
		not_in:"User_organiser__language_Input",
		all:"User_organiser__language_Input"
	},
	User_organiser__language_Input: "enum" as const,
	User_organiser__phone_operator:{

	},
	User_organiser__followers_operator:{

	},
	User_emailVerified_operator:{

	},
	User_sso__googleId_operator:{

	},
	User_sso__facebookId_operator:{

	},
	User_sso__appleId_operator:{

	},
	User_oldPassword_operator:{

	},
	User_status_operator:{
		equals:"User_status_Input",
		not_equals:"User_status_Input",
		in:"User_status_Input",
		not_in:"User_status_Input",
		all:"User_status_Input"
	},
	User_status_Input: "enum" as const,
	User_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	User_email_operator:{
		equals:"EmailAddress",
		not_equals:"EmailAddress",
		like:"EmailAddress",
		contains:"EmailAddress",
		in:"EmailAddress",
		not_in:"EmailAddress",
		all:"EmailAddress"
	},
	User_id_operator:{

	},
	User_where_and:{
		region:"User_region_operator",
		name:"User_name_operator",
		displayEmail:"User_displayEmail_operator",
		legacyUsername:"User_legacyUsername_operator",
		roles:"User_roles_operator",
		member__region:"User_member__region_operator",
		member__language:"User_member__language_operator",
		member__phone:"User_member__phone_operator",
		member__avatar:"User_member__avatar_operator",
		member__birthday:"User_member__birthday_operator",
		member__gender:"User_member__gender_operator",
		member__categories:"User_member__categories_operator",
		organiser__logo:"User_organiser__logo_operator",
		organiser__banner:"User_organiser__banner_operator",
		organiser__description:"User_organiser__description_operator",
		organiser__foundedIn:"User_organiser__foundedIn_operator",
		organiser__address:"User_organiser__address_operator",
		organiser__website:"User_organiser__website_operator",
		organiser__facebook:"User_organiser__facebook_operator",
		organiser__instagram:"User_organiser__instagram_operator",
		organiser__language:"User_organiser__language_operator",
		organiser__phone:"User_organiser__phone_operator",
		organiser__followers:"User_organiser__followers_operator",
		emailVerified:"User_emailVerified_operator",
		sso__googleId:"User_sso__googleId_operator",
		sso__facebookId:"User_sso__facebookId_operator",
		sso__appleId:"User_sso__appleId_operator",
		oldPassword:"User_oldPassword_operator",
		status:"User_status_operator",
		updatedAt:"User_updatedAt_operator",
		createdAt:"User_createdAt_operator",
		email:"User_email_operator",
		id:"User_id_operator",
		AND:"User_where_and",
		OR:"User_where_or"
	},
	User_where_or:{
		region:"User_region_operator",
		name:"User_name_operator",
		displayEmail:"User_displayEmail_operator",
		legacyUsername:"User_legacyUsername_operator",
		roles:"User_roles_operator",
		member__region:"User_member__region_operator",
		member__language:"User_member__language_operator",
		member__phone:"User_member__phone_operator",
		member__avatar:"User_member__avatar_operator",
		member__birthday:"User_member__birthday_operator",
		member__gender:"User_member__gender_operator",
		member__categories:"User_member__categories_operator",
		organiser__logo:"User_organiser__logo_operator",
		organiser__banner:"User_organiser__banner_operator",
		organiser__description:"User_organiser__description_operator",
		organiser__foundedIn:"User_organiser__foundedIn_operator",
		organiser__address:"User_organiser__address_operator",
		organiser__website:"User_organiser__website_operator",
		organiser__facebook:"User_organiser__facebook_operator",
		organiser__instagram:"User_organiser__instagram_operator",
		organiser__language:"User_organiser__language_operator",
		organiser__phone:"User_organiser__phone_operator",
		organiser__followers:"User_organiser__followers_operator",
		emailVerified:"User_emailVerified_operator",
		sso__googleId:"User_sso__googleId_operator",
		sso__facebookId:"User_sso__facebookId_operator",
		sso__appleId:"User_sso__appleId_operator",
		oldPassword:"User_oldPassword_operator",
		status:"User_status_operator",
		updatedAt:"User_updatedAt_operator",
		createdAt:"User_createdAt_operator",
		email:"User_email_operator",
		id:"User_id_operator",
		AND:"User_where_and",
		OR:"User_where_or"
	},
	JSONObject: `scalar.JSONObject` as const,
	BlogCategory:{
		icon:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"BlogCategory_Icon_where"
		}
	},
	BlogCategory_Icon_where:{
		alt:"BlogCategory_Icon_alt_operator",
		updatedAt:"BlogCategory_Icon_updatedAt_operator",
		createdAt:"BlogCategory_Icon_createdAt_operator",
		url:"BlogCategory_Icon_url_operator",
		filename:"BlogCategory_Icon_filename_operator",
		mimeType:"BlogCategory_Icon_mimeType_operator",
		filesize:"BlogCategory_Icon_filesize_operator",
		width:"BlogCategory_Icon_width_operator",
		height:"BlogCategory_Icon_height_operator",
		focalX:"BlogCategory_Icon_focalX_operator",
		focalY:"BlogCategory_Icon_focalY_operator",
		sizes__small__url:"BlogCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"BlogCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"BlogCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"BlogCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"BlogCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"BlogCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"BlogCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"BlogCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"BlogCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"BlogCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BlogCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"BlogCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"BlogCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"BlogCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"BlogCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"BlogCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"BlogCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"BlogCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BlogCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BlogCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BlogCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BlogCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BlogCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BlogCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BlogCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BlogCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BlogCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BlogCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BlogCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BlogCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BlogCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BlogCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BlogCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BlogCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BlogCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"BlogCategory_Icon_id_operator",
		AND:"BlogCategory_Icon_where_and",
		OR:"BlogCategory_Icon_where_or"
	},
	BlogCategory_Icon_alt_operator:{

	},
	BlogCategory_Icon_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BlogCategory_Icon_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BlogCategory_Icon_url_operator:{

	},
	BlogCategory_Icon_filename_operator:{

	},
	BlogCategory_Icon_mimeType_operator:{

	},
	BlogCategory_Icon_filesize_operator:{

	},
	BlogCategory_Icon_width_operator:{

	},
	BlogCategory_Icon_height_operator:{

	},
	BlogCategory_Icon_focalX_operator:{

	},
	BlogCategory_Icon_focalY_operator:{

	},
	BlogCategory_Icon_sizes__small__url_operator:{

	},
	BlogCategory_Icon_sizes__small__width_operator:{

	},
	BlogCategory_Icon_sizes__small__height_operator:{

	},
	BlogCategory_Icon_sizes__small__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__small__filesize_operator:{

	},
	BlogCategory_Icon_sizes__small__filename_operator:{

	},
	BlogCategory_Icon_sizes__medium__url_operator:{

	},
	BlogCategory_Icon_sizes__medium__width_operator:{

	},
	BlogCategory_Icon_sizes__medium__height_operator:{

	},
	BlogCategory_Icon_sizes__medium__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__medium__filesize_operator:{

	},
	BlogCategory_Icon_sizes__medium__filename_operator:{

	},
	BlogCategory_Icon_sizes__large__url_operator:{

	},
	BlogCategory_Icon_sizes__large__width_operator:{

	},
	BlogCategory_Icon_sizes__large__height_operator:{

	},
	BlogCategory_Icon_sizes__large__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__large__filesize_operator:{

	},
	BlogCategory_Icon_sizes__large__filename_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__url_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__width_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__height_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__filesize_operator:{

	},
	BlogCategory_Icon_sizes__small_jpeg__filename_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__url_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__width_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__height_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__filesize_operator:{

	},
	BlogCategory_Icon_sizes__medium_jpeg__filename_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__url_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__width_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__height_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__mimeType_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__filesize_operator:{

	},
	BlogCategory_Icon_sizes__large_jpeg__filename_operator:{

	},
	BlogCategory_Icon_id_operator:{

	},
	BlogCategory_Icon_where_and:{
		alt:"BlogCategory_Icon_alt_operator",
		updatedAt:"BlogCategory_Icon_updatedAt_operator",
		createdAt:"BlogCategory_Icon_createdAt_operator",
		url:"BlogCategory_Icon_url_operator",
		filename:"BlogCategory_Icon_filename_operator",
		mimeType:"BlogCategory_Icon_mimeType_operator",
		filesize:"BlogCategory_Icon_filesize_operator",
		width:"BlogCategory_Icon_width_operator",
		height:"BlogCategory_Icon_height_operator",
		focalX:"BlogCategory_Icon_focalX_operator",
		focalY:"BlogCategory_Icon_focalY_operator",
		sizes__small__url:"BlogCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"BlogCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"BlogCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"BlogCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"BlogCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"BlogCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"BlogCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"BlogCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"BlogCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"BlogCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BlogCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"BlogCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"BlogCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"BlogCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"BlogCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"BlogCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"BlogCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"BlogCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BlogCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BlogCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BlogCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BlogCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BlogCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BlogCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BlogCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BlogCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BlogCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BlogCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BlogCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BlogCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BlogCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BlogCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BlogCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BlogCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BlogCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"BlogCategory_Icon_id_operator",
		AND:"BlogCategory_Icon_where_and",
		OR:"BlogCategory_Icon_where_or"
	},
	BlogCategory_Icon_where_or:{
		alt:"BlogCategory_Icon_alt_operator",
		updatedAt:"BlogCategory_Icon_updatedAt_operator",
		createdAt:"BlogCategory_Icon_createdAt_operator",
		url:"BlogCategory_Icon_url_operator",
		filename:"BlogCategory_Icon_filename_operator",
		mimeType:"BlogCategory_Icon_mimeType_operator",
		filesize:"BlogCategory_Icon_filesize_operator",
		width:"BlogCategory_Icon_width_operator",
		height:"BlogCategory_Icon_height_operator",
		focalX:"BlogCategory_Icon_focalX_operator",
		focalY:"BlogCategory_Icon_focalY_operator",
		sizes__small__url:"BlogCategory_Icon_sizes__small__url_operator",
		sizes__small__width:"BlogCategory_Icon_sizes__small__width_operator",
		sizes__small__height:"BlogCategory_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"BlogCategory_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"BlogCategory_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"BlogCategory_Icon_sizes__small__filename_operator",
		sizes__medium__url:"BlogCategory_Icon_sizes__medium__url_operator",
		sizes__medium__width:"BlogCategory_Icon_sizes__medium__width_operator",
		sizes__medium__height:"BlogCategory_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"BlogCategory_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BlogCategory_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"BlogCategory_Icon_sizes__medium__filename_operator",
		sizes__large__url:"BlogCategory_Icon_sizes__large__url_operator",
		sizes__large__width:"BlogCategory_Icon_sizes__large__width_operator",
		sizes__large__height:"BlogCategory_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"BlogCategory_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"BlogCategory_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"BlogCategory_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BlogCategory_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BlogCategory_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BlogCategory_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BlogCategory_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BlogCategory_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BlogCategory_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BlogCategory_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BlogCategory_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BlogCategory_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BlogCategory_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BlogCategory_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BlogCategory_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BlogCategory_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BlogCategory_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BlogCategory_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BlogCategory_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BlogCategory_Icon_sizes__large_jpeg__filename_operator",
		id:"BlogCategory_Icon_id_operator",
		AND:"BlogCategory_Icon_where_and",
		OR:"BlogCategory_Icon_where_or"
	},
	BlogCategory_region: "enum" as const,
	BlogCategory_where:{
		name:"BlogCategory_name_operator",
		value:"BlogCategory_value_operator",
		icon:"BlogCategory_icon_operator",
		region:"BlogCategory_region_operator",
		updatedAt:"BlogCategory_updatedAt_operator",
		createdAt:"BlogCategory_createdAt_operator",
		id:"BlogCategory_id_operator",
		AND:"BlogCategory_where_and",
		OR:"BlogCategory_where_or"
	},
	BlogCategory_name_operator:{

	},
	BlogCategory_value_operator:{

	},
	BlogCategory_icon_operator:{

	},
	BlogCategory_region_operator:{
		equals:"BlogCategory_region_Input",
		not_equals:"BlogCategory_region_Input",
		in:"BlogCategory_region_Input",
		not_in:"BlogCategory_region_Input",
		all:"BlogCategory_region_Input"
	},
	BlogCategory_region_Input: "enum" as const,
	BlogCategory_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BlogCategory_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BlogCategory_id_operator:{

	},
	BlogCategory_where_and:{
		name:"BlogCategory_name_operator",
		value:"BlogCategory_value_operator",
		icon:"BlogCategory_icon_operator",
		region:"BlogCategory_region_operator",
		updatedAt:"BlogCategory_updatedAt_operator",
		createdAt:"BlogCategory_createdAt_operator",
		id:"BlogCategory_id_operator",
		AND:"BlogCategory_where_and",
		OR:"BlogCategory_where_or"
	},
	BlogCategory_where_or:{
		name:"BlogCategory_name_operator",
		value:"BlogCategory_value_operator",
		icon:"BlogCategory_icon_operator",
		region:"BlogCategory_region_operator",
		updatedAt:"BlogCategory_updatedAt_operator",
		createdAt:"BlogCategory_createdAt_operator",
		id:"BlogCategory_id_operator",
		AND:"BlogCategory_where_and",
		OR:"BlogCategory_where_or"
	},
	Tag_where:{
		name:"Tag_name_operator",
		value:"Tag_value_operator",
		region:"Tag_region_operator",
		count:"Tag_count_operator",
		skipCountCheck:"Tag_skipCountCheck_operator",
		removeNextCron:"Tag_removeNextCron_operator",
		id:"Tag_id_operator",
		AND:"Tag_where_and",
		OR:"Tag_where_or"
	},
	Tag_name_operator:{

	},
	Tag_value_operator:{

	},
	Tag_region_operator:{
		equals:"Tag_region_Input",
		not_equals:"Tag_region_Input",
		in:"Tag_region_Input",
		not_in:"Tag_region_Input",
		all:"Tag_region_Input"
	},
	Tag_region_Input: "enum" as const,
	Tag_count_operator:{

	},
	Tag_skipCountCheck_operator:{

	},
	Tag_removeNextCron_operator:{

	},
	Tag_id_operator:{

	},
	Tag_where_and:{
		name:"Tag_name_operator",
		value:"Tag_value_operator",
		region:"Tag_region_operator",
		count:"Tag_count_operator",
		skipCountCheck:"Tag_skipCountCheck_operator",
		removeNextCron:"Tag_removeNextCron_operator",
		id:"Tag_id_operator",
		AND:"Tag_where_and",
		OR:"Tag_where_or"
	},
	Tag_where_or:{
		name:"Tag_name_operator",
		value:"Tag_value_operator",
		region:"Tag_region_operator",
		count:"Tag_count_operator",
		skipCountCheck:"Tag_skipCountCheck_operator",
		removeNextCron:"Tag_removeNextCron_operator",
		id:"Tag_id_operator",
		AND:"Tag_where_and",
		OR:"Tag_where_or"
	},
	SpecialTag_region: "enum" as const,
	SpecialTag_where:{
		name:"SpecialTag_name_operator",
		value:"SpecialTag_value_operator",
		region:"SpecialTag_region_operator",
		id:"SpecialTag_id_operator",
		AND:"SpecialTag_where_and",
		OR:"SpecialTag_where_or"
	},
	SpecialTag_name_operator:{

	},
	SpecialTag_value_operator:{

	},
	SpecialTag_region_operator:{
		equals:"SpecialTag_region_Input",
		not_equals:"SpecialTag_region_Input",
		in:"SpecialTag_region_Input",
		not_in:"SpecialTag_region_Input",
		all:"SpecialTag_region_Input"
	},
	SpecialTag_region_Input: "enum" as const,
	SpecialTag_id_operator:{

	},
	SpecialTag_where_and:{
		name:"SpecialTag_name_operator",
		value:"SpecialTag_value_operator",
		region:"SpecialTag_region_operator",
		id:"SpecialTag_id_operator",
		AND:"SpecialTag_where_and",
		OR:"SpecialTag_where_or"
	},
	SpecialTag_where_or:{
		name:"SpecialTag_name_operator",
		value:"SpecialTag_value_operator",
		region:"SpecialTag_region_operator",
		id:"SpecialTag_id_operator",
		AND:"SpecialTag_where_and",
		OR:"SpecialTag_where_or"
	},
	Date_type: "enum" as const,
	Date_region: "enum" as const,
	Date_where:{
		name:"Date_name_operator",
		value:"Date_value_operator",
		type:"Date_type_operator",
		start:"Date_start_operator",
		end:"Date_end_operator",
		_title:"Date__title_operator",
		region:"Date_region_operator",
		updatedAt:"Date_updatedAt_operator",
		createdAt:"Date_createdAt_operator",
		id:"Date_id_operator",
		AND:"Date_where_and",
		OR:"Date_where_or"
	},
	Date_name_operator:{

	},
	Date_value_operator:{

	},
	Date_type_operator:{
		equals:"Date_type_Input",
		not_equals:"Date_type_Input",
		in:"Date_type_Input",
		not_in:"Date_type_Input",
		all:"Date_type_Input"
	},
	Date_type_Input: "enum" as const,
	Date_start_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Date_end_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Date__title_operator:{

	},
	Date_region_operator:{
		equals:"Date_region_Input",
		not_equals:"Date_region_Input",
		in:"Date_region_Input",
		not_in:"Date_region_Input",
		all:"Date_region_Input"
	},
	Date_region_Input: "enum" as const,
	Date_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Date_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Date_id_operator:{

	},
	Date_where_and:{
		name:"Date_name_operator",
		value:"Date_value_operator",
		type:"Date_type_operator",
		start:"Date_start_operator",
		end:"Date_end_operator",
		_title:"Date__title_operator",
		region:"Date_region_operator",
		updatedAt:"Date_updatedAt_operator",
		createdAt:"Date_createdAt_operator",
		id:"Date_id_operator",
		AND:"Date_where_and",
		OR:"Date_where_or"
	},
	Date_where_or:{
		name:"Date_name_operator",
		value:"Date_value_operator",
		type:"Date_type_operator",
		start:"Date_start_operator",
		end:"Date_end_operator",
		_title:"Date__title_operator",
		region:"Date_region_operator",
		updatedAt:"Date_updatedAt_operator",
		createdAt:"Date_createdAt_operator",
		id:"Date_id_operator",
		AND:"Date_where_and",
		OR:"Date_where_or"
	},
	District:{
		children:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	District_region: "enum" as const,
	District_where:{
		name:"District_name_operator",
		level:"District_level_operator",
		children:"District_children_operator",
		value:"District_value_operator",
		lat:"District_lat_operator",
		long:"District_long_operator",
		radius:"District_radius_operator",
		region:"District_region_operator",
		updatedAt:"District_updatedAt_operator",
		createdAt:"District_createdAt_operator",
		id:"District_id_operator",
		AND:"District_where_and",
		OR:"District_where_or"
	},
	District_name_operator:{

	},
	District_level_operator:{

	},
	District_children_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	District_value_operator:{

	},
	District_lat_operator:{

	},
	District_long_operator:{

	},
	District_radius_operator:{

	},
	District_region_operator:{
		equals:"District_region_Input",
		not_equals:"District_region_Input",
		in:"District_region_Input",
		not_in:"District_region_Input",
		all:"District_region_Input"
	},
	District_region_Input: "enum" as const,
	District_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	District_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	District_id_operator:{

	},
	District_where_and:{
		name:"District_name_operator",
		level:"District_level_operator",
		children:"District_children_operator",
		value:"District_value_operator",
		lat:"District_lat_operator",
		long:"District_long_operator",
		radius:"District_radius_operator",
		region:"District_region_operator",
		updatedAt:"District_updatedAt_operator",
		createdAt:"District_createdAt_operator",
		id:"District_id_operator",
		AND:"District_where_and",
		OR:"District_where_or"
	},
	District_where_or:{
		name:"District_name_operator",
		level:"District_level_operator",
		children:"District_children_operator",
		value:"District_value_operator",
		lat:"District_lat_operator",
		long:"District_long_operator",
		radius:"District_radius_operator",
		region:"District_region_operator",
		updatedAt:"District_updatedAt_operator",
		createdAt:"District_createdAt_operator",
		id:"District_id_operator",
		AND:"District_where_and",
		OR:"District_where_or"
	},
	Audience:{
		icon:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Audience_Icon_where"
		}
	},
	Audience_Icon_where:{
		alt:"Audience_Icon_alt_operator",
		updatedAt:"Audience_Icon_updatedAt_operator",
		createdAt:"Audience_Icon_createdAt_operator",
		url:"Audience_Icon_url_operator",
		filename:"Audience_Icon_filename_operator",
		mimeType:"Audience_Icon_mimeType_operator",
		filesize:"Audience_Icon_filesize_operator",
		width:"Audience_Icon_width_operator",
		height:"Audience_Icon_height_operator",
		focalX:"Audience_Icon_focalX_operator",
		focalY:"Audience_Icon_focalY_operator",
		sizes__small__url:"Audience_Icon_sizes__small__url_operator",
		sizes__small__width:"Audience_Icon_sizes__small__width_operator",
		sizes__small__height:"Audience_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"Audience_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"Audience_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"Audience_Icon_sizes__small__filename_operator",
		sizes__medium__url:"Audience_Icon_sizes__medium__url_operator",
		sizes__medium__width:"Audience_Icon_sizes__medium__width_operator",
		sizes__medium__height:"Audience_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"Audience_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Audience_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"Audience_Icon_sizes__medium__filename_operator",
		sizes__large__url:"Audience_Icon_sizes__large__url_operator",
		sizes__large__width:"Audience_Icon_sizes__large__width_operator",
		sizes__large__height:"Audience_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"Audience_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"Audience_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"Audience_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Audience_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Audience_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Audience_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Audience_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Audience_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Audience_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Audience_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Audience_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Audience_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Audience_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Audience_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Audience_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Audience_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Audience_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Audience_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Audience_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Audience_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Audience_Icon_sizes__large_jpeg__filename_operator",
		id:"Audience_Icon_id_operator",
		AND:"Audience_Icon_where_and",
		OR:"Audience_Icon_where_or"
	},
	Audience_Icon_alt_operator:{

	},
	Audience_Icon_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Audience_Icon_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Audience_Icon_url_operator:{

	},
	Audience_Icon_filename_operator:{

	},
	Audience_Icon_mimeType_operator:{

	},
	Audience_Icon_filesize_operator:{

	},
	Audience_Icon_width_operator:{

	},
	Audience_Icon_height_operator:{

	},
	Audience_Icon_focalX_operator:{

	},
	Audience_Icon_focalY_operator:{

	},
	Audience_Icon_sizes__small__url_operator:{

	},
	Audience_Icon_sizes__small__width_operator:{

	},
	Audience_Icon_sizes__small__height_operator:{

	},
	Audience_Icon_sizes__small__mimeType_operator:{

	},
	Audience_Icon_sizes__small__filesize_operator:{

	},
	Audience_Icon_sizes__small__filename_operator:{

	},
	Audience_Icon_sizes__medium__url_operator:{

	},
	Audience_Icon_sizes__medium__width_operator:{

	},
	Audience_Icon_sizes__medium__height_operator:{

	},
	Audience_Icon_sizes__medium__mimeType_operator:{

	},
	Audience_Icon_sizes__medium__filesize_operator:{

	},
	Audience_Icon_sizes__medium__filename_operator:{

	},
	Audience_Icon_sizes__large__url_operator:{

	},
	Audience_Icon_sizes__large__width_operator:{

	},
	Audience_Icon_sizes__large__height_operator:{

	},
	Audience_Icon_sizes__large__mimeType_operator:{

	},
	Audience_Icon_sizes__large__filesize_operator:{

	},
	Audience_Icon_sizes__large__filename_operator:{

	},
	Audience_Icon_sizes__small_jpeg__url_operator:{

	},
	Audience_Icon_sizes__small_jpeg__width_operator:{

	},
	Audience_Icon_sizes__small_jpeg__height_operator:{

	},
	Audience_Icon_sizes__small_jpeg__mimeType_operator:{

	},
	Audience_Icon_sizes__small_jpeg__filesize_operator:{

	},
	Audience_Icon_sizes__small_jpeg__filename_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__url_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__width_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__height_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__mimeType_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__filesize_operator:{

	},
	Audience_Icon_sizes__medium_jpeg__filename_operator:{

	},
	Audience_Icon_sizes__large_jpeg__url_operator:{

	},
	Audience_Icon_sizes__large_jpeg__width_operator:{

	},
	Audience_Icon_sizes__large_jpeg__height_operator:{

	},
	Audience_Icon_sizes__large_jpeg__mimeType_operator:{

	},
	Audience_Icon_sizes__large_jpeg__filesize_operator:{

	},
	Audience_Icon_sizes__large_jpeg__filename_operator:{

	},
	Audience_Icon_id_operator:{

	},
	Audience_Icon_where_and:{
		alt:"Audience_Icon_alt_operator",
		updatedAt:"Audience_Icon_updatedAt_operator",
		createdAt:"Audience_Icon_createdAt_operator",
		url:"Audience_Icon_url_operator",
		filename:"Audience_Icon_filename_operator",
		mimeType:"Audience_Icon_mimeType_operator",
		filesize:"Audience_Icon_filesize_operator",
		width:"Audience_Icon_width_operator",
		height:"Audience_Icon_height_operator",
		focalX:"Audience_Icon_focalX_operator",
		focalY:"Audience_Icon_focalY_operator",
		sizes__small__url:"Audience_Icon_sizes__small__url_operator",
		sizes__small__width:"Audience_Icon_sizes__small__width_operator",
		sizes__small__height:"Audience_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"Audience_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"Audience_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"Audience_Icon_sizes__small__filename_operator",
		sizes__medium__url:"Audience_Icon_sizes__medium__url_operator",
		sizes__medium__width:"Audience_Icon_sizes__medium__width_operator",
		sizes__medium__height:"Audience_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"Audience_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Audience_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"Audience_Icon_sizes__medium__filename_operator",
		sizes__large__url:"Audience_Icon_sizes__large__url_operator",
		sizes__large__width:"Audience_Icon_sizes__large__width_operator",
		sizes__large__height:"Audience_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"Audience_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"Audience_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"Audience_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Audience_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Audience_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Audience_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Audience_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Audience_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Audience_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Audience_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Audience_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Audience_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Audience_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Audience_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Audience_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Audience_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Audience_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Audience_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Audience_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Audience_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Audience_Icon_sizes__large_jpeg__filename_operator",
		id:"Audience_Icon_id_operator",
		AND:"Audience_Icon_where_and",
		OR:"Audience_Icon_where_or"
	},
	Audience_Icon_where_or:{
		alt:"Audience_Icon_alt_operator",
		updatedAt:"Audience_Icon_updatedAt_operator",
		createdAt:"Audience_Icon_createdAt_operator",
		url:"Audience_Icon_url_operator",
		filename:"Audience_Icon_filename_operator",
		mimeType:"Audience_Icon_mimeType_operator",
		filesize:"Audience_Icon_filesize_operator",
		width:"Audience_Icon_width_operator",
		height:"Audience_Icon_height_operator",
		focalX:"Audience_Icon_focalX_operator",
		focalY:"Audience_Icon_focalY_operator",
		sizes__small__url:"Audience_Icon_sizes__small__url_operator",
		sizes__small__width:"Audience_Icon_sizes__small__width_operator",
		sizes__small__height:"Audience_Icon_sizes__small__height_operator",
		sizes__small__mimeType:"Audience_Icon_sizes__small__mimeType_operator",
		sizes__small__filesize:"Audience_Icon_sizes__small__filesize_operator",
		sizes__small__filename:"Audience_Icon_sizes__small__filename_operator",
		sizes__medium__url:"Audience_Icon_sizes__medium__url_operator",
		sizes__medium__width:"Audience_Icon_sizes__medium__width_operator",
		sizes__medium__height:"Audience_Icon_sizes__medium__height_operator",
		sizes__medium__mimeType:"Audience_Icon_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Audience_Icon_sizes__medium__filesize_operator",
		sizes__medium__filename:"Audience_Icon_sizes__medium__filename_operator",
		sizes__large__url:"Audience_Icon_sizes__large__url_operator",
		sizes__large__width:"Audience_Icon_sizes__large__width_operator",
		sizes__large__height:"Audience_Icon_sizes__large__height_operator",
		sizes__large__mimeType:"Audience_Icon_sizes__large__mimeType_operator",
		sizes__large__filesize:"Audience_Icon_sizes__large__filesize_operator",
		sizes__large__filename:"Audience_Icon_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Audience_Icon_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Audience_Icon_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Audience_Icon_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Audience_Icon_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Audience_Icon_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Audience_Icon_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Audience_Icon_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Audience_Icon_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Audience_Icon_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Audience_Icon_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Audience_Icon_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Audience_Icon_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Audience_Icon_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Audience_Icon_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Audience_Icon_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Audience_Icon_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Audience_Icon_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Audience_Icon_sizes__large_jpeg__filename_operator",
		id:"Audience_Icon_id_operator",
		AND:"Audience_Icon_where_and",
		OR:"Audience_Icon_where_or"
	},
	Audience_region: "enum" as const,
	Audience_where:{
		name:"Audience_name_operator",
		value:"Audience_value_operator",
		icon:"Audience_icon_operator",
		region:"Audience_region_operator",
		updatedAt:"Audience_updatedAt_operator",
		createdAt:"Audience_createdAt_operator",
		id:"Audience_id_operator",
		AND:"Audience_where_and",
		OR:"Audience_where_or"
	},
	Audience_name_operator:{

	},
	Audience_value_operator:{

	},
	Audience_icon_operator:{

	},
	Audience_region_operator:{
		equals:"Audience_region_Input",
		not_equals:"Audience_region_Input",
		in:"Audience_region_Input",
		not_in:"Audience_region_Input",
		all:"Audience_region_Input"
	},
	Audience_region_Input: "enum" as const,
	Audience_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Audience_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Audience_id_operator:{

	},
	Audience_where_and:{
		name:"Audience_name_operator",
		value:"Audience_value_operator",
		icon:"Audience_icon_operator",
		region:"Audience_region_operator",
		updatedAt:"Audience_updatedAt_operator",
		createdAt:"Audience_createdAt_operator",
		id:"Audience_id_operator",
		AND:"Audience_where_and",
		OR:"Audience_where_or"
	},
	Audience_where_or:{
		name:"Audience_name_operator",
		value:"Audience_value_operator",
		icon:"Audience_icon_operator",
		region:"Audience_region_operator",
		updatedAt:"Audience_updatedAt_operator",
		createdAt:"Audience_createdAt_operator",
		id:"Audience_id_operator",
		AND:"Audience_where_and",
		OR:"Audience_where_or"
	},
	Event:{
		creator:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		parent:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		thumbnail:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Event_Thumbnail_where"
		},
		banner:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Event_Banner_where"
		},
		mobileBanner:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Event_MobileBanner_where"
		},
		legacyGallery:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Event_region: "enum" as const,
	Event_level: "enum" as const,
	Event_Thumbnail_where:{
		alt:"Event_Thumbnail_alt_operator",
		updatedAt:"Event_Thumbnail_updatedAt_operator",
		createdAt:"Event_Thumbnail_createdAt_operator",
		url:"Event_Thumbnail_url_operator",
		filename:"Event_Thumbnail_filename_operator",
		mimeType:"Event_Thumbnail_mimeType_operator",
		filesize:"Event_Thumbnail_filesize_operator",
		width:"Event_Thumbnail_width_operator",
		height:"Event_Thumbnail_height_operator",
		focalX:"Event_Thumbnail_focalX_operator",
		focalY:"Event_Thumbnail_focalY_operator",
		sizes__small__url:"Event_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Event_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Event_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Event_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Event_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Event_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Event_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Event_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Event_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Event_Thumbnail_id_operator",
		AND:"Event_Thumbnail_where_and",
		OR:"Event_Thumbnail_where_or"
	},
	Event_Thumbnail_alt_operator:{

	},
	Event_Thumbnail_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_Thumbnail_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_Thumbnail_url_operator:{

	},
	Event_Thumbnail_filename_operator:{

	},
	Event_Thumbnail_mimeType_operator:{

	},
	Event_Thumbnail_filesize_operator:{

	},
	Event_Thumbnail_width_operator:{

	},
	Event_Thumbnail_height_operator:{

	},
	Event_Thumbnail_focalX_operator:{

	},
	Event_Thumbnail_focalY_operator:{

	},
	Event_Thumbnail_sizes__small__url_operator:{

	},
	Event_Thumbnail_sizes__small__width_operator:{

	},
	Event_Thumbnail_sizes__small__height_operator:{

	},
	Event_Thumbnail_sizes__small__mimeType_operator:{

	},
	Event_Thumbnail_sizes__small__filesize_operator:{

	},
	Event_Thumbnail_sizes__small__filename_operator:{

	},
	Event_Thumbnail_sizes__medium__url_operator:{

	},
	Event_Thumbnail_sizes__medium__width_operator:{

	},
	Event_Thumbnail_sizes__medium__height_operator:{

	},
	Event_Thumbnail_sizes__medium__mimeType_operator:{

	},
	Event_Thumbnail_sizes__medium__filesize_operator:{

	},
	Event_Thumbnail_sizes__medium__filename_operator:{

	},
	Event_Thumbnail_sizes__large__url_operator:{

	},
	Event_Thumbnail_sizes__large__width_operator:{

	},
	Event_Thumbnail_sizes__large__height_operator:{

	},
	Event_Thumbnail_sizes__large__mimeType_operator:{

	},
	Event_Thumbnail_sizes__large__filesize_operator:{

	},
	Event_Thumbnail_sizes__large__filename_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__url_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__width_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__height_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__mimeType_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__filesize_operator:{

	},
	Event_Thumbnail_sizes__small_jpeg__filename_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__url_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__width_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__height_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__mimeType_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__filesize_operator:{

	},
	Event_Thumbnail_sizes__medium_jpeg__filename_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__url_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__width_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__height_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__mimeType_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__filesize_operator:{

	},
	Event_Thumbnail_sizes__large_jpeg__filename_operator:{

	},
	Event_Thumbnail_id_operator:{

	},
	Event_Thumbnail_where_and:{
		alt:"Event_Thumbnail_alt_operator",
		updatedAt:"Event_Thumbnail_updatedAt_operator",
		createdAt:"Event_Thumbnail_createdAt_operator",
		url:"Event_Thumbnail_url_operator",
		filename:"Event_Thumbnail_filename_operator",
		mimeType:"Event_Thumbnail_mimeType_operator",
		filesize:"Event_Thumbnail_filesize_operator",
		width:"Event_Thumbnail_width_operator",
		height:"Event_Thumbnail_height_operator",
		focalX:"Event_Thumbnail_focalX_operator",
		focalY:"Event_Thumbnail_focalY_operator",
		sizes__small__url:"Event_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Event_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Event_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Event_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Event_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Event_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Event_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Event_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Event_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Event_Thumbnail_id_operator",
		AND:"Event_Thumbnail_where_and",
		OR:"Event_Thumbnail_where_or"
	},
	Event_Thumbnail_where_or:{
		alt:"Event_Thumbnail_alt_operator",
		updatedAt:"Event_Thumbnail_updatedAt_operator",
		createdAt:"Event_Thumbnail_createdAt_operator",
		url:"Event_Thumbnail_url_operator",
		filename:"Event_Thumbnail_filename_operator",
		mimeType:"Event_Thumbnail_mimeType_operator",
		filesize:"Event_Thumbnail_filesize_operator",
		width:"Event_Thumbnail_width_operator",
		height:"Event_Thumbnail_height_operator",
		focalX:"Event_Thumbnail_focalX_operator",
		focalY:"Event_Thumbnail_focalY_operator",
		sizes__small__url:"Event_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Event_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Event_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Event_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Event_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Event_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Event_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Event_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Event_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Event_Thumbnail_id_operator",
		AND:"Event_Thumbnail_where_and",
		OR:"Event_Thumbnail_where_or"
	},
	Event_Banner_where:{
		alt:"Event_Banner_alt_operator",
		updatedAt:"Event_Banner_updatedAt_operator",
		createdAt:"Event_Banner_createdAt_operator",
		url:"Event_Banner_url_operator",
		filename:"Event_Banner_filename_operator",
		mimeType:"Event_Banner_mimeType_operator",
		filesize:"Event_Banner_filesize_operator",
		width:"Event_Banner_width_operator",
		height:"Event_Banner_height_operator",
		focalX:"Event_Banner_focalX_operator",
		focalY:"Event_Banner_focalY_operator",
		sizes__small__url:"Event_Banner_sizes__small__url_operator",
		sizes__small__width:"Event_Banner_sizes__small__width_operator",
		sizes__small__height:"Event_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Event_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Event_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Event_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Event_Banner_sizes__large__url_operator",
		sizes__large__width:"Event_Banner_sizes__large__width_operator",
		sizes__large__height:"Event_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Banner_sizes__large_jpeg__filename_operator",
		id:"Event_Banner_id_operator",
		AND:"Event_Banner_where_and",
		OR:"Event_Banner_where_or"
	},
	Event_Banner_alt_operator:{

	},
	Event_Banner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_Banner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_Banner_url_operator:{

	},
	Event_Banner_filename_operator:{

	},
	Event_Banner_mimeType_operator:{

	},
	Event_Banner_filesize_operator:{

	},
	Event_Banner_width_operator:{

	},
	Event_Banner_height_operator:{

	},
	Event_Banner_focalX_operator:{

	},
	Event_Banner_focalY_operator:{

	},
	Event_Banner_sizes__small__url_operator:{

	},
	Event_Banner_sizes__small__width_operator:{

	},
	Event_Banner_sizes__small__height_operator:{

	},
	Event_Banner_sizes__small__mimeType_operator:{

	},
	Event_Banner_sizes__small__filesize_operator:{

	},
	Event_Banner_sizes__small__filename_operator:{

	},
	Event_Banner_sizes__medium__url_operator:{

	},
	Event_Banner_sizes__medium__width_operator:{

	},
	Event_Banner_sizes__medium__height_operator:{

	},
	Event_Banner_sizes__medium__mimeType_operator:{

	},
	Event_Banner_sizes__medium__filesize_operator:{

	},
	Event_Banner_sizes__medium__filename_operator:{

	},
	Event_Banner_sizes__large__url_operator:{

	},
	Event_Banner_sizes__large__width_operator:{

	},
	Event_Banner_sizes__large__height_operator:{

	},
	Event_Banner_sizes__large__mimeType_operator:{

	},
	Event_Banner_sizes__large__filesize_operator:{

	},
	Event_Banner_sizes__large__filename_operator:{

	},
	Event_Banner_sizes__small_jpeg__url_operator:{

	},
	Event_Banner_sizes__small_jpeg__width_operator:{

	},
	Event_Banner_sizes__small_jpeg__height_operator:{

	},
	Event_Banner_sizes__small_jpeg__mimeType_operator:{

	},
	Event_Banner_sizes__small_jpeg__filesize_operator:{

	},
	Event_Banner_sizes__small_jpeg__filename_operator:{

	},
	Event_Banner_sizes__medium_jpeg__url_operator:{

	},
	Event_Banner_sizes__medium_jpeg__width_operator:{

	},
	Event_Banner_sizes__medium_jpeg__height_operator:{

	},
	Event_Banner_sizes__medium_jpeg__mimeType_operator:{

	},
	Event_Banner_sizes__medium_jpeg__filesize_operator:{

	},
	Event_Banner_sizes__medium_jpeg__filename_operator:{

	},
	Event_Banner_sizes__large_jpeg__url_operator:{

	},
	Event_Banner_sizes__large_jpeg__width_operator:{

	},
	Event_Banner_sizes__large_jpeg__height_operator:{

	},
	Event_Banner_sizes__large_jpeg__mimeType_operator:{

	},
	Event_Banner_sizes__large_jpeg__filesize_operator:{

	},
	Event_Banner_sizes__large_jpeg__filename_operator:{

	},
	Event_Banner_id_operator:{

	},
	Event_Banner_where_and:{
		alt:"Event_Banner_alt_operator",
		updatedAt:"Event_Banner_updatedAt_operator",
		createdAt:"Event_Banner_createdAt_operator",
		url:"Event_Banner_url_operator",
		filename:"Event_Banner_filename_operator",
		mimeType:"Event_Banner_mimeType_operator",
		filesize:"Event_Banner_filesize_operator",
		width:"Event_Banner_width_operator",
		height:"Event_Banner_height_operator",
		focalX:"Event_Banner_focalX_operator",
		focalY:"Event_Banner_focalY_operator",
		sizes__small__url:"Event_Banner_sizes__small__url_operator",
		sizes__small__width:"Event_Banner_sizes__small__width_operator",
		sizes__small__height:"Event_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Event_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Event_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Event_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Event_Banner_sizes__large__url_operator",
		sizes__large__width:"Event_Banner_sizes__large__width_operator",
		sizes__large__height:"Event_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Banner_sizes__large_jpeg__filename_operator",
		id:"Event_Banner_id_operator",
		AND:"Event_Banner_where_and",
		OR:"Event_Banner_where_or"
	},
	Event_Banner_where_or:{
		alt:"Event_Banner_alt_operator",
		updatedAt:"Event_Banner_updatedAt_operator",
		createdAt:"Event_Banner_createdAt_operator",
		url:"Event_Banner_url_operator",
		filename:"Event_Banner_filename_operator",
		mimeType:"Event_Banner_mimeType_operator",
		filesize:"Event_Banner_filesize_operator",
		width:"Event_Banner_width_operator",
		height:"Event_Banner_height_operator",
		focalX:"Event_Banner_focalX_operator",
		focalY:"Event_Banner_focalY_operator",
		sizes__small__url:"Event_Banner_sizes__small__url_operator",
		sizes__small__width:"Event_Banner_sizes__small__width_operator",
		sizes__small__height:"Event_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Event_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Event_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Event_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Event_Banner_sizes__large__url_operator",
		sizes__large__width:"Event_Banner_sizes__large__width_operator",
		sizes__large__height:"Event_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_Banner_sizes__large_jpeg__filename_operator",
		id:"Event_Banner_id_operator",
		AND:"Event_Banner_where_and",
		OR:"Event_Banner_where_or"
	},
	Event_MobileBanner_where:{
		alt:"Event_MobileBanner_alt_operator",
		updatedAt:"Event_MobileBanner_updatedAt_operator",
		createdAt:"Event_MobileBanner_createdAt_operator",
		url:"Event_MobileBanner_url_operator",
		filename:"Event_MobileBanner_filename_operator",
		mimeType:"Event_MobileBanner_mimeType_operator",
		filesize:"Event_MobileBanner_filesize_operator",
		width:"Event_MobileBanner_width_operator",
		height:"Event_MobileBanner_height_operator",
		focalX:"Event_MobileBanner_focalX_operator",
		focalY:"Event_MobileBanner_focalY_operator",
		sizes__small__url:"Event_MobileBanner_sizes__small__url_operator",
		sizes__small__width:"Event_MobileBanner_sizes__small__width_operator",
		sizes__small__height:"Event_MobileBanner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_MobileBanner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_MobileBanner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_MobileBanner_sizes__small__filename_operator",
		sizes__medium__url:"Event_MobileBanner_sizes__medium__url_operator",
		sizes__medium__width:"Event_MobileBanner_sizes__medium__width_operator",
		sizes__medium__height:"Event_MobileBanner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_MobileBanner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_MobileBanner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_MobileBanner_sizes__medium__filename_operator",
		sizes__large__url:"Event_MobileBanner_sizes__large__url_operator",
		sizes__large__width:"Event_MobileBanner_sizes__large__width_operator",
		sizes__large__height:"Event_MobileBanner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_MobileBanner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_MobileBanner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_MobileBanner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_MobileBanner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_MobileBanner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_MobileBanner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_MobileBanner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_MobileBanner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_MobileBanner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_MobileBanner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_MobileBanner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_MobileBanner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_MobileBanner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_MobileBanner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_MobileBanner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_MobileBanner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_MobileBanner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_MobileBanner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_MobileBanner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_MobileBanner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_MobileBanner_sizes__large_jpeg__filename_operator",
		id:"Event_MobileBanner_id_operator",
		AND:"Event_MobileBanner_where_and",
		OR:"Event_MobileBanner_where_or"
	},
	Event_MobileBanner_alt_operator:{

	},
	Event_MobileBanner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_MobileBanner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_MobileBanner_url_operator:{

	},
	Event_MobileBanner_filename_operator:{

	},
	Event_MobileBanner_mimeType_operator:{

	},
	Event_MobileBanner_filesize_operator:{

	},
	Event_MobileBanner_width_operator:{

	},
	Event_MobileBanner_height_operator:{

	},
	Event_MobileBanner_focalX_operator:{

	},
	Event_MobileBanner_focalY_operator:{

	},
	Event_MobileBanner_sizes__small__url_operator:{

	},
	Event_MobileBanner_sizes__small__width_operator:{

	},
	Event_MobileBanner_sizes__small__height_operator:{

	},
	Event_MobileBanner_sizes__small__mimeType_operator:{

	},
	Event_MobileBanner_sizes__small__filesize_operator:{

	},
	Event_MobileBanner_sizes__small__filename_operator:{

	},
	Event_MobileBanner_sizes__medium__url_operator:{

	},
	Event_MobileBanner_sizes__medium__width_operator:{

	},
	Event_MobileBanner_sizes__medium__height_operator:{

	},
	Event_MobileBanner_sizes__medium__mimeType_operator:{

	},
	Event_MobileBanner_sizes__medium__filesize_operator:{

	},
	Event_MobileBanner_sizes__medium__filename_operator:{

	},
	Event_MobileBanner_sizes__large__url_operator:{

	},
	Event_MobileBanner_sizes__large__width_operator:{

	},
	Event_MobileBanner_sizes__large__height_operator:{

	},
	Event_MobileBanner_sizes__large__mimeType_operator:{

	},
	Event_MobileBanner_sizes__large__filesize_operator:{

	},
	Event_MobileBanner_sizes__large__filename_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__url_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__width_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__height_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__mimeType_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__filesize_operator:{

	},
	Event_MobileBanner_sizes__small_jpeg__filename_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__url_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__width_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__height_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__mimeType_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__filesize_operator:{

	},
	Event_MobileBanner_sizes__medium_jpeg__filename_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__url_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__width_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__height_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__mimeType_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__filesize_operator:{

	},
	Event_MobileBanner_sizes__large_jpeg__filename_operator:{

	},
	Event_MobileBanner_id_operator:{

	},
	Event_MobileBanner_where_and:{
		alt:"Event_MobileBanner_alt_operator",
		updatedAt:"Event_MobileBanner_updatedAt_operator",
		createdAt:"Event_MobileBanner_createdAt_operator",
		url:"Event_MobileBanner_url_operator",
		filename:"Event_MobileBanner_filename_operator",
		mimeType:"Event_MobileBanner_mimeType_operator",
		filesize:"Event_MobileBanner_filesize_operator",
		width:"Event_MobileBanner_width_operator",
		height:"Event_MobileBanner_height_operator",
		focalX:"Event_MobileBanner_focalX_operator",
		focalY:"Event_MobileBanner_focalY_operator",
		sizes__small__url:"Event_MobileBanner_sizes__small__url_operator",
		sizes__small__width:"Event_MobileBanner_sizes__small__width_operator",
		sizes__small__height:"Event_MobileBanner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_MobileBanner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_MobileBanner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_MobileBanner_sizes__small__filename_operator",
		sizes__medium__url:"Event_MobileBanner_sizes__medium__url_operator",
		sizes__medium__width:"Event_MobileBanner_sizes__medium__width_operator",
		sizes__medium__height:"Event_MobileBanner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_MobileBanner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_MobileBanner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_MobileBanner_sizes__medium__filename_operator",
		sizes__large__url:"Event_MobileBanner_sizes__large__url_operator",
		sizes__large__width:"Event_MobileBanner_sizes__large__width_operator",
		sizes__large__height:"Event_MobileBanner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_MobileBanner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_MobileBanner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_MobileBanner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_MobileBanner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_MobileBanner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_MobileBanner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_MobileBanner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_MobileBanner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_MobileBanner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_MobileBanner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_MobileBanner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_MobileBanner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_MobileBanner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_MobileBanner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_MobileBanner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_MobileBanner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_MobileBanner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_MobileBanner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_MobileBanner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_MobileBanner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_MobileBanner_sizes__large_jpeg__filename_operator",
		id:"Event_MobileBanner_id_operator",
		AND:"Event_MobileBanner_where_and",
		OR:"Event_MobileBanner_where_or"
	},
	Event_MobileBanner_where_or:{
		alt:"Event_MobileBanner_alt_operator",
		updatedAt:"Event_MobileBanner_updatedAt_operator",
		createdAt:"Event_MobileBanner_createdAt_operator",
		url:"Event_MobileBanner_url_operator",
		filename:"Event_MobileBanner_filename_operator",
		mimeType:"Event_MobileBanner_mimeType_operator",
		filesize:"Event_MobileBanner_filesize_operator",
		width:"Event_MobileBanner_width_operator",
		height:"Event_MobileBanner_height_operator",
		focalX:"Event_MobileBanner_focalX_operator",
		focalY:"Event_MobileBanner_focalY_operator",
		sizes__small__url:"Event_MobileBanner_sizes__small__url_operator",
		sizes__small__width:"Event_MobileBanner_sizes__small__width_operator",
		sizes__small__height:"Event_MobileBanner_sizes__small__height_operator",
		sizes__small__mimeType:"Event_MobileBanner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Event_MobileBanner_sizes__small__filesize_operator",
		sizes__small__filename:"Event_MobileBanner_sizes__small__filename_operator",
		sizes__medium__url:"Event_MobileBanner_sizes__medium__url_operator",
		sizes__medium__width:"Event_MobileBanner_sizes__medium__width_operator",
		sizes__medium__height:"Event_MobileBanner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Event_MobileBanner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Event_MobileBanner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Event_MobileBanner_sizes__medium__filename_operator",
		sizes__large__url:"Event_MobileBanner_sizes__large__url_operator",
		sizes__large__width:"Event_MobileBanner_sizes__large__width_operator",
		sizes__large__height:"Event_MobileBanner_sizes__large__height_operator",
		sizes__large__mimeType:"Event_MobileBanner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Event_MobileBanner_sizes__large__filesize_operator",
		sizes__large__filename:"Event_MobileBanner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Event_MobileBanner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Event_MobileBanner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Event_MobileBanner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Event_MobileBanner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Event_MobileBanner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Event_MobileBanner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Event_MobileBanner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Event_MobileBanner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Event_MobileBanner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Event_MobileBanner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Event_MobileBanner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Event_MobileBanner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Event_MobileBanner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Event_MobileBanner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Event_MobileBanner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Event_MobileBanner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Event_MobileBanner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Event_MobileBanner_sizes__large_jpeg__filename_operator",
		id:"Event_MobileBanner_id_operator",
		AND:"Event_MobileBanner_where_and",
		OR:"Event_MobileBanner_where_or"
	},
	Event_Sections:{
		location:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		district:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Event_Sections_Recurrance_type: "enum" as const,
	Event_Sections_Recurrance_weekday: "enum" as const,
	Location:{
		creator:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		parent:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		thumbnail:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Location_Thumbnail_where"
		},
		banner:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Location_Banner_where"
		},
		district:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		legacyGallery:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Location_region: "enum" as const,
	Location_level: "enum" as const,
	Location_Thumbnail_where:{
		alt:"Location_Thumbnail_alt_operator",
		updatedAt:"Location_Thumbnail_updatedAt_operator",
		createdAt:"Location_Thumbnail_createdAt_operator",
		url:"Location_Thumbnail_url_operator",
		filename:"Location_Thumbnail_filename_operator",
		mimeType:"Location_Thumbnail_mimeType_operator",
		filesize:"Location_Thumbnail_filesize_operator",
		width:"Location_Thumbnail_width_operator",
		height:"Location_Thumbnail_height_operator",
		focalX:"Location_Thumbnail_focalX_operator",
		focalY:"Location_Thumbnail_focalY_operator",
		sizes__small__url:"Location_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Location_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Location_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Location_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Location_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Location_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Location_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Location_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Location_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Location_Thumbnail_id_operator",
		AND:"Location_Thumbnail_where_and",
		OR:"Location_Thumbnail_where_or"
	},
	Location_Thumbnail_alt_operator:{

	},
	Location_Thumbnail_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_Thumbnail_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_Thumbnail_url_operator:{

	},
	Location_Thumbnail_filename_operator:{

	},
	Location_Thumbnail_mimeType_operator:{

	},
	Location_Thumbnail_filesize_operator:{

	},
	Location_Thumbnail_width_operator:{

	},
	Location_Thumbnail_height_operator:{

	},
	Location_Thumbnail_focalX_operator:{

	},
	Location_Thumbnail_focalY_operator:{

	},
	Location_Thumbnail_sizes__small__url_operator:{

	},
	Location_Thumbnail_sizes__small__width_operator:{

	},
	Location_Thumbnail_sizes__small__height_operator:{

	},
	Location_Thumbnail_sizes__small__mimeType_operator:{

	},
	Location_Thumbnail_sizes__small__filesize_operator:{

	},
	Location_Thumbnail_sizes__small__filename_operator:{

	},
	Location_Thumbnail_sizes__medium__url_operator:{

	},
	Location_Thumbnail_sizes__medium__width_operator:{

	},
	Location_Thumbnail_sizes__medium__height_operator:{

	},
	Location_Thumbnail_sizes__medium__mimeType_operator:{

	},
	Location_Thumbnail_sizes__medium__filesize_operator:{

	},
	Location_Thumbnail_sizes__medium__filename_operator:{

	},
	Location_Thumbnail_sizes__large__url_operator:{

	},
	Location_Thumbnail_sizes__large__width_operator:{

	},
	Location_Thumbnail_sizes__large__height_operator:{

	},
	Location_Thumbnail_sizes__large__mimeType_operator:{

	},
	Location_Thumbnail_sizes__large__filesize_operator:{

	},
	Location_Thumbnail_sizes__large__filename_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__url_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__width_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__height_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__mimeType_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__filesize_operator:{

	},
	Location_Thumbnail_sizes__small_jpeg__filename_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__url_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__width_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__height_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__mimeType_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__filesize_operator:{

	},
	Location_Thumbnail_sizes__medium_jpeg__filename_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__url_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__width_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__height_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__mimeType_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__filesize_operator:{

	},
	Location_Thumbnail_sizes__large_jpeg__filename_operator:{

	},
	Location_Thumbnail_id_operator:{

	},
	Location_Thumbnail_where_and:{
		alt:"Location_Thumbnail_alt_operator",
		updatedAt:"Location_Thumbnail_updatedAt_operator",
		createdAt:"Location_Thumbnail_createdAt_operator",
		url:"Location_Thumbnail_url_operator",
		filename:"Location_Thumbnail_filename_operator",
		mimeType:"Location_Thumbnail_mimeType_operator",
		filesize:"Location_Thumbnail_filesize_operator",
		width:"Location_Thumbnail_width_operator",
		height:"Location_Thumbnail_height_operator",
		focalX:"Location_Thumbnail_focalX_operator",
		focalY:"Location_Thumbnail_focalY_operator",
		sizes__small__url:"Location_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Location_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Location_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Location_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Location_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Location_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Location_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Location_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Location_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Location_Thumbnail_id_operator",
		AND:"Location_Thumbnail_where_and",
		OR:"Location_Thumbnail_where_or"
	},
	Location_Thumbnail_where_or:{
		alt:"Location_Thumbnail_alt_operator",
		updatedAt:"Location_Thumbnail_updatedAt_operator",
		createdAt:"Location_Thumbnail_createdAt_operator",
		url:"Location_Thumbnail_url_operator",
		filename:"Location_Thumbnail_filename_operator",
		mimeType:"Location_Thumbnail_mimeType_operator",
		filesize:"Location_Thumbnail_filesize_operator",
		width:"Location_Thumbnail_width_operator",
		height:"Location_Thumbnail_height_operator",
		focalX:"Location_Thumbnail_focalX_operator",
		focalY:"Location_Thumbnail_focalY_operator",
		sizes__small__url:"Location_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Location_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Location_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Location_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Location_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Location_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Location_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Location_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Location_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Location_Thumbnail_id_operator",
		AND:"Location_Thumbnail_where_and",
		OR:"Location_Thumbnail_where_or"
	},
	Location_Banner_where:{
		alt:"Location_Banner_alt_operator",
		updatedAt:"Location_Banner_updatedAt_operator",
		createdAt:"Location_Banner_createdAt_operator",
		url:"Location_Banner_url_operator",
		filename:"Location_Banner_filename_operator",
		mimeType:"Location_Banner_mimeType_operator",
		filesize:"Location_Banner_filesize_operator",
		width:"Location_Banner_width_operator",
		height:"Location_Banner_height_operator",
		focalX:"Location_Banner_focalX_operator",
		focalY:"Location_Banner_focalY_operator",
		sizes__small__url:"Location_Banner_sizes__small__url_operator",
		sizes__small__width:"Location_Banner_sizes__small__width_operator",
		sizes__small__height:"Location_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Location_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Location_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Location_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Location_Banner_sizes__large__url_operator",
		sizes__large__width:"Location_Banner_sizes__large__width_operator",
		sizes__large__height:"Location_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Banner_sizes__large_jpeg__filename_operator",
		id:"Location_Banner_id_operator",
		AND:"Location_Banner_where_and",
		OR:"Location_Banner_where_or"
	},
	Location_Banner_alt_operator:{

	},
	Location_Banner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_Banner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_Banner_url_operator:{

	},
	Location_Banner_filename_operator:{

	},
	Location_Banner_mimeType_operator:{

	},
	Location_Banner_filesize_operator:{

	},
	Location_Banner_width_operator:{

	},
	Location_Banner_height_operator:{

	},
	Location_Banner_focalX_operator:{

	},
	Location_Banner_focalY_operator:{

	},
	Location_Banner_sizes__small__url_operator:{

	},
	Location_Banner_sizes__small__width_operator:{

	},
	Location_Banner_sizes__small__height_operator:{

	},
	Location_Banner_sizes__small__mimeType_operator:{

	},
	Location_Banner_sizes__small__filesize_operator:{

	},
	Location_Banner_sizes__small__filename_operator:{

	},
	Location_Banner_sizes__medium__url_operator:{

	},
	Location_Banner_sizes__medium__width_operator:{

	},
	Location_Banner_sizes__medium__height_operator:{

	},
	Location_Banner_sizes__medium__mimeType_operator:{

	},
	Location_Banner_sizes__medium__filesize_operator:{

	},
	Location_Banner_sizes__medium__filename_operator:{

	},
	Location_Banner_sizes__large__url_operator:{

	},
	Location_Banner_sizes__large__width_operator:{

	},
	Location_Banner_sizes__large__height_operator:{

	},
	Location_Banner_sizes__large__mimeType_operator:{

	},
	Location_Banner_sizes__large__filesize_operator:{

	},
	Location_Banner_sizes__large__filename_operator:{

	},
	Location_Banner_sizes__small_jpeg__url_operator:{

	},
	Location_Banner_sizes__small_jpeg__width_operator:{

	},
	Location_Banner_sizes__small_jpeg__height_operator:{

	},
	Location_Banner_sizes__small_jpeg__mimeType_operator:{

	},
	Location_Banner_sizes__small_jpeg__filesize_operator:{

	},
	Location_Banner_sizes__small_jpeg__filename_operator:{

	},
	Location_Banner_sizes__medium_jpeg__url_operator:{

	},
	Location_Banner_sizes__medium_jpeg__width_operator:{

	},
	Location_Banner_sizes__medium_jpeg__height_operator:{

	},
	Location_Banner_sizes__medium_jpeg__mimeType_operator:{

	},
	Location_Banner_sizes__medium_jpeg__filesize_operator:{

	},
	Location_Banner_sizes__medium_jpeg__filename_operator:{

	},
	Location_Banner_sizes__large_jpeg__url_operator:{

	},
	Location_Banner_sizes__large_jpeg__width_operator:{

	},
	Location_Banner_sizes__large_jpeg__height_operator:{

	},
	Location_Banner_sizes__large_jpeg__mimeType_operator:{

	},
	Location_Banner_sizes__large_jpeg__filesize_operator:{

	},
	Location_Banner_sizes__large_jpeg__filename_operator:{

	},
	Location_Banner_id_operator:{

	},
	Location_Banner_where_and:{
		alt:"Location_Banner_alt_operator",
		updatedAt:"Location_Banner_updatedAt_operator",
		createdAt:"Location_Banner_createdAt_operator",
		url:"Location_Banner_url_operator",
		filename:"Location_Banner_filename_operator",
		mimeType:"Location_Banner_mimeType_operator",
		filesize:"Location_Banner_filesize_operator",
		width:"Location_Banner_width_operator",
		height:"Location_Banner_height_operator",
		focalX:"Location_Banner_focalX_operator",
		focalY:"Location_Banner_focalY_operator",
		sizes__small__url:"Location_Banner_sizes__small__url_operator",
		sizes__small__width:"Location_Banner_sizes__small__width_operator",
		sizes__small__height:"Location_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Location_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Location_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Location_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Location_Banner_sizes__large__url_operator",
		sizes__large__width:"Location_Banner_sizes__large__width_operator",
		sizes__large__height:"Location_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Banner_sizes__large_jpeg__filename_operator",
		id:"Location_Banner_id_operator",
		AND:"Location_Banner_where_and",
		OR:"Location_Banner_where_or"
	},
	Location_Banner_where_or:{
		alt:"Location_Banner_alt_operator",
		updatedAt:"Location_Banner_updatedAt_operator",
		createdAt:"Location_Banner_createdAt_operator",
		url:"Location_Banner_url_operator",
		filename:"Location_Banner_filename_operator",
		mimeType:"Location_Banner_mimeType_operator",
		filesize:"Location_Banner_filesize_operator",
		width:"Location_Banner_width_operator",
		height:"Location_Banner_height_operator",
		focalX:"Location_Banner_focalX_operator",
		focalY:"Location_Banner_focalY_operator",
		sizes__small__url:"Location_Banner_sizes__small__url_operator",
		sizes__small__width:"Location_Banner_sizes__small__width_operator",
		sizes__small__height:"Location_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Location_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Location_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Location_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Location_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Location_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Location_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Location_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Location_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Location_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Location_Banner_sizes__large__url_operator",
		sizes__large__width:"Location_Banner_sizes__large__width_operator",
		sizes__large__height:"Location_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Location_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Location_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Location_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Location_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Location_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Location_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Location_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Location_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Location_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Location_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Location_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Location_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Location_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Location_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Location_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Location_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Location_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Location_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Location_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Location_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Location_Banner_sizes__large_jpeg__filename_operator",
		id:"Location_Banner_id_operator",
		AND:"Location_Banner_where_and",
		OR:"Location_Banner_where_or"
	},
	Location_type: "enum" as const,
	Location_category: "enum" as const,
	RichText:{
		richText:{

		}
	},
	Gallery_Gallery:{
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Gallery_Gallery_Item_where"
		}
	},
	Gallery_Gallery_Item_where:{
		alt:"Gallery_Gallery_Item_alt_operator",
		updatedAt:"Gallery_Gallery_Item_updatedAt_operator",
		createdAt:"Gallery_Gallery_Item_createdAt_operator",
		url:"Gallery_Gallery_Item_url_operator",
		filename:"Gallery_Gallery_Item_filename_operator",
		mimeType:"Gallery_Gallery_Item_mimeType_operator",
		filesize:"Gallery_Gallery_Item_filesize_operator",
		width:"Gallery_Gallery_Item_width_operator",
		height:"Gallery_Gallery_Item_height_operator",
		focalX:"Gallery_Gallery_Item_focalX_operator",
		focalY:"Gallery_Gallery_Item_focalY_operator",
		sizes__small__url:"Gallery_Gallery_Item_sizes__small__url_operator",
		sizes__small__width:"Gallery_Gallery_Item_sizes__small__width_operator",
		sizes__small__height:"Gallery_Gallery_Item_sizes__small__height_operator",
		sizes__small__mimeType:"Gallery_Gallery_Item_sizes__small__mimeType_operator",
		sizes__small__filesize:"Gallery_Gallery_Item_sizes__small__filesize_operator",
		sizes__small__filename:"Gallery_Gallery_Item_sizes__small__filename_operator",
		sizes__medium__url:"Gallery_Gallery_Item_sizes__medium__url_operator",
		sizes__medium__width:"Gallery_Gallery_Item_sizes__medium__width_operator",
		sizes__medium__height:"Gallery_Gallery_Item_sizes__medium__height_operator",
		sizes__medium__mimeType:"Gallery_Gallery_Item_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Gallery_Gallery_Item_sizes__medium__filesize_operator",
		sizes__medium__filename:"Gallery_Gallery_Item_sizes__medium__filename_operator",
		sizes__large__url:"Gallery_Gallery_Item_sizes__large__url_operator",
		sizes__large__width:"Gallery_Gallery_Item_sizes__large__width_operator",
		sizes__large__height:"Gallery_Gallery_Item_sizes__large__height_operator",
		sizes__large__mimeType:"Gallery_Gallery_Item_sizes__large__mimeType_operator",
		sizes__large__filesize:"Gallery_Gallery_Item_sizes__large__filesize_operator",
		sizes__large__filename:"Gallery_Gallery_Item_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Gallery_Gallery_Item_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Gallery_Gallery_Item_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Gallery_Gallery_Item_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Gallery_Gallery_Item_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Gallery_Gallery_Item_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Gallery_Gallery_Item_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Gallery_Gallery_Item_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Gallery_Gallery_Item_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Gallery_Gallery_Item_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Gallery_Gallery_Item_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Gallery_Gallery_Item_sizes__large_jpeg__filename_operator",
		id:"Gallery_Gallery_Item_id_operator",
		AND:"Gallery_Gallery_Item_where_and",
		OR:"Gallery_Gallery_Item_where_or"
	},
	Gallery_Gallery_Item_alt_operator:{

	},
	Gallery_Gallery_Item_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Gallery_Gallery_Item_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Gallery_Gallery_Item_url_operator:{

	},
	Gallery_Gallery_Item_filename_operator:{

	},
	Gallery_Gallery_Item_mimeType_operator:{

	},
	Gallery_Gallery_Item_filesize_operator:{

	},
	Gallery_Gallery_Item_width_operator:{

	},
	Gallery_Gallery_Item_height_operator:{

	},
	Gallery_Gallery_Item_focalX_operator:{

	},
	Gallery_Gallery_Item_focalY_operator:{

	},
	Gallery_Gallery_Item_sizes__small__url_operator:{

	},
	Gallery_Gallery_Item_sizes__small__width_operator:{

	},
	Gallery_Gallery_Item_sizes__small__height_operator:{

	},
	Gallery_Gallery_Item_sizes__small__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__small__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__small__filename_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__url_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__width_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__height_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__medium__filename_operator:{

	},
	Gallery_Gallery_Item_sizes__large__url_operator:{

	},
	Gallery_Gallery_Item_sizes__large__width_operator:{

	},
	Gallery_Gallery_Item_sizes__large__height_operator:{

	},
	Gallery_Gallery_Item_sizes__large__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__large__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__large__filename_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__url_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__width_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__height_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__small_jpeg__filename_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__url_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__width_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__height_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__url_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__width_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__height_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator:{

	},
	Gallery_Gallery_Item_sizes__large_jpeg__filename_operator:{

	},
	Gallery_Gallery_Item_id_operator:{

	},
	Gallery_Gallery_Item_where_and:{
		alt:"Gallery_Gallery_Item_alt_operator",
		updatedAt:"Gallery_Gallery_Item_updatedAt_operator",
		createdAt:"Gallery_Gallery_Item_createdAt_operator",
		url:"Gallery_Gallery_Item_url_operator",
		filename:"Gallery_Gallery_Item_filename_operator",
		mimeType:"Gallery_Gallery_Item_mimeType_operator",
		filesize:"Gallery_Gallery_Item_filesize_operator",
		width:"Gallery_Gallery_Item_width_operator",
		height:"Gallery_Gallery_Item_height_operator",
		focalX:"Gallery_Gallery_Item_focalX_operator",
		focalY:"Gallery_Gallery_Item_focalY_operator",
		sizes__small__url:"Gallery_Gallery_Item_sizes__small__url_operator",
		sizes__small__width:"Gallery_Gallery_Item_sizes__small__width_operator",
		sizes__small__height:"Gallery_Gallery_Item_sizes__small__height_operator",
		sizes__small__mimeType:"Gallery_Gallery_Item_sizes__small__mimeType_operator",
		sizes__small__filesize:"Gallery_Gallery_Item_sizes__small__filesize_operator",
		sizes__small__filename:"Gallery_Gallery_Item_sizes__small__filename_operator",
		sizes__medium__url:"Gallery_Gallery_Item_sizes__medium__url_operator",
		sizes__medium__width:"Gallery_Gallery_Item_sizes__medium__width_operator",
		sizes__medium__height:"Gallery_Gallery_Item_sizes__medium__height_operator",
		sizes__medium__mimeType:"Gallery_Gallery_Item_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Gallery_Gallery_Item_sizes__medium__filesize_operator",
		sizes__medium__filename:"Gallery_Gallery_Item_sizes__medium__filename_operator",
		sizes__large__url:"Gallery_Gallery_Item_sizes__large__url_operator",
		sizes__large__width:"Gallery_Gallery_Item_sizes__large__width_operator",
		sizes__large__height:"Gallery_Gallery_Item_sizes__large__height_operator",
		sizes__large__mimeType:"Gallery_Gallery_Item_sizes__large__mimeType_operator",
		sizes__large__filesize:"Gallery_Gallery_Item_sizes__large__filesize_operator",
		sizes__large__filename:"Gallery_Gallery_Item_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Gallery_Gallery_Item_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Gallery_Gallery_Item_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Gallery_Gallery_Item_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Gallery_Gallery_Item_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Gallery_Gallery_Item_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Gallery_Gallery_Item_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Gallery_Gallery_Item_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Gallery_Gallery_Item_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Gallery_Gallery_Item_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Gallery_Gallery_Item_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Gallery_Gallery_Item_sizes__large_jpeg__filename_operator",
		id:"Gallery_Gallery_Item_id_operator",
		AND:"Gallery_Gallery_Item_where_and",
		OR:"Gallery_Gallery_Item_where_or"
	},
	Gallery_Gallery_Item_where_or:{
		alt:"Gallery_Gallery_Item_alt_operator",
		updatedAt:"Gallery_Gallery_Item_updatedAt_operator",
		createdAt:"Gallery_Gallery_Item_createdAt_operator",
		url:"Gallery_Gallery_Item_url_operator",
		filename:"Gallery_Gallery_Item_filename_operator",
		mimeType:"Gallery_Gallery_Item_mimeType_operator",
		filesize:"Gallery_Gallery_Item_filesize_operator",
		width:"Gallery_Gallery_Item_width_operator",
		height:"Gallery_Gallery_Item_height_operator",
		focalX:"Gallery_Gallery_Item_focalX_operator",
		focalY:"Gallery_Gallery_Item_focalY_operator",
		sizes__small__url:"Gallery_Gallery_Item_sizes__small__url_operator",
		sizes__small__width:"Gallery_Gallery_Item_sizes__small__width_operator",
		sizes__small__height:"Gallery_Gallery_Item_sizes__small__height_operator",
		sizes__small__mimeType:"Gallery_Gallery_Item_sizes__small__mimeType_operator",
		sizes__small__filesize:"Gallery_Gallery_Item_sizes__small__filesize_operator",
		sizes__small__filename:"Gallery_Gallery_Item_sizes__small__filename_operator",
		sizes__medium__url:"Gallery_Gallery_Item_sizes__medium__url_operator",
		sizes__medium__width:"Gallery_Gallery_Item_sizes__medium__width_operator",
		sizes__medium__height:"Gallery_Gallery_Item_sizes__medium__height_operator",
		sizes__medium__mimeType:"Gallery_Gallery_Item_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Gallery_Gallery_Item_sizes__medium__filesize_operator",
		sizes__medium__filename:"Gallery_Gallery_Item_sizes__medium__filename_operator",
		sizes__large__url:"Gallery_Gallery_Item_sizes__large__url_operator",
		sizes__large__width:"Gallery_Gallery_Item_sizes__large__width_operator",
		sizes__large__height:"Gallery_Gallery_Item_sizes__large__height_operator",
		sizes__large__mimeType:"Gallery_Gallery_Item_sizes__large__mimeType_operator",
		sizes__large__filesize:"Gallery_Gallery_Item_sizes__large__filesize_operator",
		sizes__large__filename:"Gallery_Gallery_Item_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Gallery_Gallery_Item_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Gallery_Gallery_Item_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Gallery_Gallery_Item_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Gallery_Gallery_Item_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Gallery_Gallery_Item_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Gallery_Gallery_Item_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Gallery_Gallery_Item_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Gallery_Gallery_Item_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Gallery_Gallery_Item_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Gallery_Gallery_Item_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Gallery_Gallery_Item_sizes__large_jpeg__filename_operator",
		id:"Gallery_Gallery_Item_id_operator",
		AND:"Gallery_Gallery_Item_where_and",
		OR:"Gallery_Gallery_Item_where_or"
	},
	Image:{
		media:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Image_Media_where"
		}
	},
	Image_Media_where:{
		alt:"Image_Media_alt_operator",
		updatedAt:"Image_Media_updatedAt_operator",
		createdAt:"Image_Media_createdAt_operator",
		url:"Image_Media_url_operator",
		filename:"Image_Media_filename_operator",
		mimeType:"Image_Media_mimeType_operator",
		filesize:"Image_Media_filesize_operator",
		width:"Image_Media_width_operator",
		height:"Image_Media_height_operator",
		focalX:"Image_Media_focalX_operator",
		focalY:"Image_Media_focalY_operator",
		sizes__small__url:"Image_Media_sizes__small__url_operator",
		sizes__small__width:"Image_Media_sizes__small__width_operator",
		sizes__small__height:"Image_Media_sizes__small__height_operator",
		sizes__small__mimeType:"Image_Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Image_Media_sizes__small__filesize_operator",
		sizes__small__filename:"Image_Media_sizes__small__filename_operator",
		sizes__medium__url:"Image_Media_sizes__medium__url_operator",
		sizes__medium__width:"Image_Media_sizes__medium__width_operator",
		sizes__medium__height:"Image_Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Image_Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Image_Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Image_Media_sizes__medium__filename_operator",
		sizes__large__url:"Image_Media_sizes__large__url_operator",
		sizes__large__width:"Image_Media_sizes__large__width_operator",
		sizes__large__height:"Image_Media_sizes__large__height_operator",
		sizes__large__mimeType:"Image_Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Image_Media_sizes__large__filesize_operator",
		sizes__large__filename:"Image_Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Image_Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Image_Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Image_Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Image_Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Image_Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Image_Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Image_Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Image_Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Image_Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Image_Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Image_Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Image_Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Image_Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Image_Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Image_Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Image_Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Image_Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Image_Media_sizes__large_jpeg__filename_operator",
		id:"Image_Media_id_operator",
		AND:"Image_Media_where_and",
		OR:"Image_Media_where_or"
	},
	Image_Media_alt_operator:{

	},
	Image_Media_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Image_Media_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Image_Media_url_operator:{

	},
	Image_Media_filename_operator:{

	},
	Image_Media_mimeType_operator:{

	},
	Image_Media_filesize_operator:{

	},
	Image_Media_width_operator:{

	},
	Image_Media_height_operator:{

	},
	Image_Media_focalX_operator:{

	},
	Image_Media_focalY_operator:{

	},
	Image_Media_sizes__small__url_operator:{

	},
	Image_Media_sizes__small__width_operator:{

	},
	Image_Media_sizes__small__height_operator:{

	},
	Image_Media_sizes__small__mimeType_operator:{

	},
	Image_Media_sizes__small__filesize_operator:{

	},
	Image_Media_sizes__small__filename_operator:{

	},
	Image_Media_sizes__medium__url_operator:{

	},
	Image_Media_sizes__medium__width_operator:{

	},
	Image_Media_sizes__medium__height_operator:{

	},
	Image_Media_sizes__medium__mimeType_operator:{

	},
	Image_Media_sizes__medium__filesize_operator:{

	},
	Image_Media_sizes__medium__filename_operator:{

	},
	Image_Media_sizes__large__url_operator:{

	},
	Image_Media_sizes__large__width_operator:{

	},
	Image_Media_sizes__large__height_operator:{

	},
	Image_Media_sizes__large__mimeType_operator:{

	},
	Image_Media_sizes__large__filesize_operator:{

	},
	Image_Media_sizes__large__filename_operator:{

	},
	Image_Media_sizes__small_jpeg__url_operator:{

	},
	Image_Media_sizes__small_jpeg__width_operator:{

	},
	Image_Media_sizes__small_jpeg__height_operator:{

	},
	Image_Media_sizes__small_jpeg__mimeType_operator:{

	},
	Image_Media_sizes__small_jpeg__filesize_operator:{

	},
	Image_Media_sizes__small_jpeg__filename_operator:{

	},
	Image_Media_sizes__medium_jpeg__url_operator:{

	},
	Image_Media_sizes__medium_jpeg__width_operator:{

	},
	Image_Media_sizes__medium_jpeg__height_operator:{

	},
	Image_Media_sizes__medium_jpeg__mimeType_operator:{

	},
	Image_Media_sizes__medium_jpeg__filesize_operator:{

	},
	Image_Media_sizes__medium_jpeg__filename_operator:{

	},
	Image_Media_sizes__large_jpeg__url_operator:{

	},
	Image_Media_sizes__large_jpeg__width_operator:{

	},
	Image_Media_sizes__large_jpeg__height_operator:{

	},
	Image_Media_sizes__large_jpeg__mimeType_operator:{

	},
	Image_Media_sizes__large_jpeg__filesize_operator:{

	},
	Image_Media_sizes__large_jpeg__filename_operator:{

	},
	Image_Media_id_operator:{

	},
	Image_Media_where_and:{
		alt:"Image_Media_alt_operator",
		updatedAt:"Image_Media_updatedAt_operator",
		createdAt:"Image_Media_createdAt_operator",
		url:"Image_Media_url_operator",
		filename:"Image_Media_filename_operator",
		mimeType:"Image_Media_mimeType_operator",
		filesize:"Image_Media_filesize_operator",
		width:"Image_Media_width_operator",
		height:"Image_Media_height_operator",
		focalX:"Image_Media_focalX_operator",
		focalY:"Image_Media_focalY_operator",
		sizes__small__url:"Image_Media_sizes__small__url_operator",
		sizes__small__width:"Image_Media_sizes__small__width_operator",
		sizes__small__height:"Image_Media_sizes__small__height_operator",
		sizes__small__mimeType:"Image_Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Image_Media_sizes__small__filesize_operator",
		sizes__small__filename:"Image_Media_sizes__small__filename_operator",
		sizes__medium__url:"Image_Media_sizes__medium__url_operator",
		sizes__medium__width:"Image_Media_sizes__medium__width_operator",
		sizes__medium__height:"Image_Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Image_Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Image_Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Image_Media_sizes__medium__filename_operator",
		sizes__large__url:"Image_Media_sizes__large__url_operator",
		sizes__large__width:"Image_Media_sizes__large__width_operator",
		sizes__large__height:"Image_Media_sizes__large__height_operator",
		sizes__large__mimeType:"Image_Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Image_Media_sizes__large__filesize_operator",
		sizes__large__filename:"Image_Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Image_Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Image_Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Image_Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Image_Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Image_Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Image_Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Image_Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Image_Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Image_Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Image_Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Image_Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Image_Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Image_Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Image_Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Image_Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Image_Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Image_Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Image_Media_sizes__large_jpeg__filename_operator",
		id:"Image_Media_id_operator",
		AND:"Image_Media_where_and",
		OR:"Image_Media_where_or"
	},
	Image_Media_where_or:{
		alt:"Image_Media_alt_operator",
		updatedAt:"Image_Media_updatedAt_operator",
		createdAt:"Image_Media_createdAt_operator",
		url:"Image_Media_url_operator",
		filename:"Image_Media_filename_operator",
		mimeType:"Image_Media_mimeType_operator",
		filesize:"Image_Media_filesize_operator",
		width:"Image_Media_width_operator",
		height:"Image_Media_height_operator",
		focalX:"Image_Media_focalX_operator",
		focalY:"Image_Media_focalY_operator",
		sizes__small__url:"Image_Media_sizes__small__url_operator",
		sizes__small__width:"Image_Media_sizes__small__width_operator",
		sizes__small__height:"Image_Media_sizes__small__height_operator",
		sizes__small__mimeType:"Image_Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Image_Media_sizes__small__filesize_operator",
		sizes__small__filename:"Image_Media_sizes__small__filename_operator",
		sizes__medium__url:"Image_Media_sizes__medium__url_operator",
		sizes__medium__width:"Image_Media_sizes__medium__width_operator",
		sizes__medium__height:"Image_Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Image_Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Image_Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Image_Media_sizes__medium__filename_operator",
		sizes__large__url:"Image_Media_sizes__large__url_operator",
		sizes__large__width:"Image_Media_sizes__large__width_operator",
		sizes__large__height:"Image_Media_sizes__large__height_operator",
		sizes__large__mimeType:"Image_Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Image_Media_sizes__large__filesize_operator",
		sizes__large__filename:"Image_Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Image_Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Image_Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Image_Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Image_Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Image_Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Image_Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Image_Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Image_Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Image_Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Image_Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Image_Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Image_Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Image_Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Image_Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Image_Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Image_Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Image_Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Image_Media_sizes__large_jpeg__filename_operator",
		id:"Image_Media_id_operator",
		AND:"Image_Media_where_and",
		OR:"Image_Media_where_or"
	},
	Location_Criteria:{
		audiences:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		dates:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		categories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		tags:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Location_Sections_Recurrance_type: "enum" as const,
	Location_Sections_Recurrance_weekday: "enum" as const,
	Event_Ticketing:{
		location:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Event_Ticketing_type: "enum" as const,
	Event_Ticketing_ticketingType: "enum" as const,
	Event_Ticketing_promotionType: "enum" as const,
	Event_Criteria:{
		categories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		audiences:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		tags:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		specialTags:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Event_where:{
		region:"Event_region_operator",
		creator:"Event_creator_operator",
		permalink:"Event_permalink_operator",
		status:"Event_status_operator",
		level:"Event_level_operator",
		parent:"Event_parent_operator",
		name:"Event_name_operator",
		thumbnail:"Event_thumbnail_operator",
		banner:"Event_banner_operator",
		mobileBanner:"Event_mobileBanner_operator",
		sections__startDatetime:"Event_sections__startDatetime_operator",
		sections__endDatetime:"Event_sections__endDatetime_operator",
		sections__fullDay:"Event_sections__fullDay_operator",
		sections__repeat:"Event_sections__repeat_operator",
		sections__toThisDay:"Event_sections__toThisDay_operator",
		sections__recurrance__type:"Event_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Event_sections__recurrance__weekday_operator",
		sections__sameLocation:"Event_sections__sameLocation_operator",
		sections__location:"Event_sections__location_operator",
		sections__district:"Event_sections__district_operator",
		sections__coordinate:"Event_sections__coordinate_operator",
		sections__linkAddress:"Event_sections__linkAddress_operator",
		sections__address:"Event_sections__address_operator",
		sections__addressCoordinate:"Event_sections__addressCoordinate_operator",
		sections__id:"Event_sections__id_operator",
		ticketing__startDatetime:"Event_ticketing__startDatetime_operator",
		ticketing__endDatetime:"Event_ticketing__endDatetime_operator",
		ticketing__fullDay:"Event_ticketing__fullDay_operator",
		ticketing__type:"Event_ticketing__type_operator",
		ticketing__ticketingType:"Event_ticketing__ticketingType_operator",
		ticketing__promotionType:"Event_ticketing__promotionType_operator",
		ticketing__location:"Event_ticketing__location_operator",
		ticketing__website:"Event_ticketing__website_operator",
		ticketing__description:"Event_ticketing__description_operator",
		ticketing__id:"Event_ticketing__id_operator",
		legacyContent:"Event_legacyContent_operator",
		showLegacyContent:"Event_showLegacyContent_operator",
		criteria__categories:"Event_criteria__categories_operator",
		criteria__audiences:"Event_criteria__audiences_operator",
		criteria__tags:"Event_criteria__tags_operator",
		criteria__specialTags:"Event_criteria__specialTags_operator",
		walkIn:"Event_walkIn_operator",
		contact__code:"Event_contact__code_operator",
		contact__phone:"Event_contact__phone_operator",
		contact__page:"Event_contact__page_operator",
		contact__email:"Event_contact__email_operator",
		views:"Event_views_operator",
		free:"Event_free_operator",
		fees__fee:"Event_fees__fee_operator",
		fees__remark:"Event_fees__remark_operator",
		fees__id:"Event_fees__id_operator",
		homePageInternalClick:"Event_homePageInternalClick_operator",
		homePageImpression:"Event_homePageImpression_operator",
		eventSearchInternalClick:"Event_eventSearchInternalClick_operator",
		eventListImpression:"Event_eventListImpression_operator",
		latestEventInternalClick:"Event_latestEventInternalClick_operator",
		latestEventImpression:"Event_latestEventImpression_operator",
		topTenEventRankingInternalClick:"Event_topTenEventRankingInternalClick_operator",
		topTenImpression:"Event_topTenImpression_operator",
		editorChoiceInternalClick:"Event_editorChoiceInternalClick_operator",
		editorChoiceImpression:"Event_editorChoiceImpression_operator",
		otherEventRankingInternalClick:"Event_otherEventRankingInternalClick_operator",
		otherEventRankingImpression:"Event_otherEventRankingImpression_operator",
		miscInternalClick:"Event_miscInternalClick_operator",
		miscImpression:"Event_miscImpression_operator",
		internalClick:"Event_internalClick_operator",
		totalImpression:"Event_totalImpression_operator",
		externalClick:"Event_externalClick_operator",
		outboundClick:"Event_outboundClick_operator",
		_viewsCountByMonth:"Event__viewsCountByMonth_operator",
		legacyGallery:"Event_legacyGallery_operator",
		earliestStartDate:"Event_earliestStartDate_operator",
		latestEndDate:"Event_latestEndDate_operator",
		updatedAt:"Event_updatedAt_operator",
		createdAt:"Event_createdAt_operator",
		id:"Event_id_operator",
		AND:"Event_where_and",
		OR:"Event_where_or"
	},
	Event_region_operator:{
		equals:"Event_region_Input",
		not_equals:"Event_region_Input",
		in:"Event_region_Input",
		not_in:"Event_region_Input",
		all:"Event_region_Input"
	},
	Event_region_Input: "enum" as const,
	Event_creator_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_permalink_operator:{

	},
	Event_status_operator:{

	},
	Event_level_operator:{
		equals:"Event_level_Input",
		not_equals:"Event_level_Input",
		in:"Event_level_Input",
		not_in:"Event_level_Input",
		all:"Event_level_Input"
	},
	Event_level_Input: "enum" as const,
	Event_parent_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_name_operator:{

	},
	Event_thumbnail_operator:{

	},
	Event_banner_operator:{

	},
	Event_mobileBanner_operator:{

	},
	Event_sections__startDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_sections__endDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_sections__fullDay_operator:{

	},
	Event_sections__repeat_operator:{

	},
	Event_sections__toThisDay_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_sections__recurrance__type_operator:{
		equals:"Event_sections__recurrance__type_Input",
		not_equals:"Event_sections__recurrance__type_Input",
		in:"Event_sections__recurrance__type_Input",
		not_in:"Event_sections__recurrance__type_Input",
		all:"Event_sections__recurrance__type_Input"
	},
	Event_sections__recurrance__type_Input: "enum" as const,
	Event_sections__recurrance__weekday_operator:{
		equals:"Event_sections__recurrance__weekday_Input",
		not_equals:"Event_sections__recurrance__weekday_Input",
		in:"Event_sections__recurrance__weekday_Input",
		not_in:"Event_sections__recurrance__weekday_Input",
		all:"Event_sections__recurrance__weekday_Input"
	},
	Event_sections__recurrance__weekday_Input: "enum" as const,
	Event_sections__sameLocation_operator:{

	},
	Event_sections__location_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_sections__district_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_sections__coordinate_operator:{
		within:"GeoJSONObject",
		intersects:"GeoJSONObject"
	},
	GeoJSONObject:{
		coordinates:"JSON"
	},
	Event_sections__linkAddress_operator:{

	},
	Event_sections__address_operator:{

	},
	Event_sections__addressCoordinate_operator:{
		within:"GeoJSONObject",
		intersects:"GeoJSONObject"
	},
	Event_sections__id_operator:{

	},
	Event_ticketing__startDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_ticketing__endDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_ticketing__fullDay_operator:{

	},
	Event_ticketing__type_operator:{
		equals:"Event_ticketing__type_Input",
		not_equals:"Event_ticketing__type_Input",
		in:"Event_ticketing__type_Input",
		not_in:"Event_ticketing__type_Input",
		all:"Event_ticketing__type_Input"
	},
	Event_ticketing__type_Input: "enum" as const,
	Event_ticketing__ticketingType_operator:{
		equals:"Event_ticketing__ticketingType_Input",
		not_equals:"Event_ticketing__ticketingType_Input",
		in:"Event_ticketing__ticketingType_Input",
		not_in:"Event_ticketing__ticketingType_Input",
		all:"Event_ticketing__ticketingType_Input"
	},
	Event_ticketing__ticketingType_Input: "enum" as const,
	Event_ticketing__promotionType_operator:{
		equals:"Event_ticketing__promotionType_Input",
		not_equals:"Event_ticketing__promotionType_Input",
		in:"Event_ticketing__promotionType_Input",
		not_in:"Event_ticketing__promotionType_Input",
		all:"Event_ticketing__promotionType_Input"
	},
	Event_ticketing__promotionType_Input: "enum" as const,
	Event_ticketing__location_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_ticketing__website_operator:{

	},
	Event_ticketing__description_operator:{

	},
	Event_ticketing__id_operator:{

	},
	Event_legacyContent_operator:{

	},
	Event_showLegacyContent_operator:{

	},
	Event_criteria__categories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_criteria__audiences_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_criteria__tags_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_criteria__specialTags_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_walkIn_operator:{

	},
	Event_contact__code_operator:{

	},
	Event_contact__phone_operator:{

	},
	Event_contact__page_operator:{

	},
	Event_contact__email_operator:{
		equals:"EmailAddress",
		not_equals:"EmailAddress",
		like:"EmailAddress",
		contains:"EmailAddress",
		in:"EmailAddress",
		not_in:"EmailAddress",
		all:"EmailAddress"
	},
	Event_views_operator:{

	},
	Event_free_operator:{

	},
	Event_fees__fee_operator:{

	},
	Event_fees__remark_operator:{

	},
	Event_fees__id_operator:{

	},
	Event_homePageInternalClick_operator:{

	},
	Event_homePageImpression_operator:{

	},
	Event_eventSearchInternalClick_operator:{

	},
	Event_eventListImpression_operator:{

	},
	Event_latestEventInternalClick_operator:{

	},
	Event_latestEventImpression_operator:{

	},
	Event_topTenEventRankingInternalClick_operator:{

	},
	Event_topTenImpression_operator:{

	},
	Event_editorChoiceInternalClick_operator:{

	},
	Event_editorChoiceImpression_operator:{

	},
	Event_otherEventRankingInternalClick_operator:{

	},
	Event_otherEventRankingImpression_operator:{

	},
	Event_miscInternalClick_operator:{

	},
	Event_miscImpression_operator:{

	},
	Event_internalClick_operator:{

	},
	Event_totalImpression_operator:{

	},
	Event_externalClick_operator:{

	},
	Event_outboundClick_operator:{

	},
	Event__viewsCountByMonth_operator:{

	},
	Event_legacyGallery_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Event_earliestStartDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_latestEndDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Event_id_operator:{

	},
	Event_where_and:{
		region:"Event_region_operator",
		creator:"Event_creator_operator",
		permalink:"Event_permalink_operator",
		status:"Event_status_operator",
		level:"Event_level_operator",
		parent:"Event_parent_operator",
		name:"Event_name_operator",
		thumbnail:"Event_thumbnail_operator",
		banner:"Event_banner_operator",
		mobileBanner:"Event_mobileBanner_operator",
		sections__startDatetime:"Event_sections__startDatetime_operator",
		sections__endDatetime:"Event_sections__endDatetime_operator",
		sections__fullDay:"Event_sections__fullDay_operator",
		sections__repeat:"Event_sections__repeat_operator",
		sections__toThisDay:"Event_sections__toThisDay_operator",
		sections__recurrance__type:"Event_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Event_sections__recurrance__weekday_operator",
		sections__sameLocation:"Event_sections__sameLocation_operator",
		sections__location:"Event_sections__location_operator",
		sections__district:"Event_sections__district_operator",
		sections__coordinate:"Event_sections__coordinate_operator",
		sections__linkAddress:"Event_sections__linkAddress_operator",
		sections__address:"Event_sections__address_operator",
		sections__addressCoordinate:"Event_sections__addressCoordinate_operator",
		sections__id:"Event_sections__id_operator",
		ticketing__startDatetime:"Event_ticketing__startDatetime_operator",
		ticketing__endDatetime:"Event_ticketing__endDatetime_operator",
		ticketing__fullDay:"Event_ticketing__fullDay_operator",
		ticketing__type:"Event_ticketing__type_operator",
		ticketing__ticketingType:"Event_ticketing__ticketingType_operator",
		ticketing__promotionType:"Event_ticketing__promotionType_operator",
		ticketing__location:"Event_ticketing__location_operator",
		ticketing__website:"Event_ticketing__website_operator",
		ticketing__description:"Event_ticketing__description_operator",
		ticketing__id:"Event_ticketing__id_operator",
		legacyContent:"Event_legacyContent_operator",
		showLegacyContent:"Event_showLegacyContent_operator",
		criteria__categories:"Event_criteria__categories_operator",
		criteria__audiences:"Event_criteria__audiences_operator",
		criteria__tags:"Event_criteria__tags_operator",
		criteria__specialTags:"Event_criteria__specialTags_operator",
		walkIn:"Event_walkIn_operator",
		contact__code:"Event_contact__code_operator",
		contact__phone:"Event_contact__phone_operator",
		contact__page:"Event_contact__page_operator",
		contact__email:"Event_contact__email_operator",
		views:"Event_views_operator",
		free:"Event_free_operator",
		fees__fee:"Event_fees__fee_operator",
		fees__remark:"Event_fees__remark_operator",
		fees__id:"Event_fees__id_operator",
		homePageInternalClick:"Event_homePageInternalClick_operator",
		homePageImpression:"Event_homePageImpression_operator",
		eventSearchInternalClick:"Event_eventSearchInternalClick_operator",
		eventListImpression:"Event_eventListImpression_operator",
		latestEventInternalClick:"Event_latestEventInternalClick_operator",
		latestEventImpression:"Event_latestEventImpression_operator",
		topTenEventRankingInternalClick:"Event_topTenEventRankingInternalClick_operator",
		topTenImpression:"Event_topTenImpression_operator",
		editorChoiceInternalClick:"Event_editorChoiceInternalClick_operator",
		editorChoiceImpression:"Event_editorChoiceImpression_operator",
		otherEventRankingInternalClick:"Event_otherEventRankingInternalClick_operator",
		otherEventRankingImpression:"Event_otherEventRankingImpression_operator",
		miscInternalClick:"Event_miscInternalClick_operator",
		miscImpression:"Event_miscImpression_operator",
		internalClick:"Event_internalClick_operator",
		totalImpression:"Event_totalImpression_operator",
		externalClick:"Event_externalClick_operator",
		outboundClick:"Event_outboundClick_operator",
		_viewsCountByMonth:"Event__viewsCountByMonth_operator",
		legacyGallery:"Event_legacyGallery_operator",
		earliestStartDate:"Event_earliestStartDate_operator",
		latestEndDate:"Event_latestEndDate_operator",
		updatedAt:"Event_updatedAt_operator",
		createdAt:"Event_createdAt_operator",
		id:"Event_id_operator",
		AND:"Event_where_and",
		OR:"Event_where_or"
	},
	Event_where_or:{
		region:"Event_region_operator",
		creator:"Event_creator_operator",
		permalink:"Event_permalink_operator",
		status:"Event_status_operator",
		level:"Event_level_operator",
		parent:"Event_parent_operator",
		name:"Event_name_operator",
		thumbnail:"Event_thumbnail_operator",
		banner:"Event_banner_operator",
		mobileBanner:"Event_mobileBanner_operator",
		sections__startDatetime:"Event_sections__startDatetime_operator",
		sections__endDatetime:"Event_sections__endDatetime_operator",
		sections__fullDay:"Event_sections__fullDay_operator",
		sections__repeat:"Event_sections__repeat_operator",
		sections__toThisDay:"Event_sections__toThisDay_operator",
		sections__recurrance__type:"Event_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Event_sections__recurrance__weekday_operator",
		sections__sameLocation:"Event_sections__sameLocation_operator",
		sections__location:"Event_sections__location_operator",
		sections__district:"Event_sections__district_operator",
		sections__coordinate:"Event_sections__coordinate_operator",
		sections__linkAddress:"Event_sections__linkAddress_operator",
		sections__address:"Event_sections__address_operator",
		sections__addressCoordinate:"Event_sections__addressCoordinate_operator",
		sections__id:"Event_sections__id_operator",
		ticketing__startDatetime:"Event_ticketing__startDatetime_operator",
		ticketing__endDatetime:"Event_ticketing__endDatetime_operator",
		ticketing__fullDay:"Event_ticketing__fullDay_operator",
		ticketing__type:"Event_ticketing__type_operator",
		ticketing__ticketingType:"Event_ticketing__ticketingType_operator",
		ticketing__promotionType:"Event_ticketing__promotionType_operator",
		ticketing__location:"Event_ticketing__location_operator",
		ticketing__website:"Event_ticketing__website_operator",
		ticketing__description:"Event_ticketing__description_operator",
		ticketing__id:"Event_ticketing__id_operator",
		legacyContent:"Event_legacyContent_operator",
		showLegacyContent:"Event_showLegacyContent_operator",
		criteria__categories:"Event_criteria__categories_operator",
		criteria__audiences:"Event_criteria__audiences_operator",
		criteria__tags:"Event_criteria__tags_operator",
		criteria__specialTags:"Event_criteria__specialTags_operator",
		walkIn:"Event_walkIn_operator",
		contact__code:"Event_contact__code_operator",
		contact__phone:"Event_contact__phone_operator",
		contact__page:"Event_contact__page_operator",
		contact__email:"Event_contact__email_operator",
		views:"Event_views_operator",
		free:"Event_free_operator",
		fees__fee:"Event_fees__fee_operator",
		fees__remark:"Event_fees__remark_operator",
		fees__id:"Event_fees__id_operator",
		homePageInternalClick:"Event_homePageInternalClick_operator",
		homePageImpression:"Event_homePageImpression_operator",
		eventSearchInternalClick:"Event_eventSearchInternalClick_operator",
		eventListImpression:"Event_eventListImpression_operator",
		latestEventInternalClick:"Event_latestEventInternalClick_operator",
		latestEventImpression:"Event_latestEventImpression_operator",
		topTenEventRankingInternalClick:"Event_topTenEventRankingInternalClick_operator",
		topTenImpression:"Event_topTenImpression_operator",
		editorChoiceInternalClick:"Event_editorChoiceInternalClick_operator",
		editorChoiceImpression:"Event_editorChoiceImpression_operator",
		otherEventRankingInternalClick:"Event_otherEventRankingInternalClick_operator",
		otherEventRankingImpression:"Event_otherEventRankingImpression_operator",
		miscInternalClick:"Event_miscInternalClick_operator",
		miscImpression:"Event_miscImpression_operator",
		internalClick:"Event_internalClick_operator",
		totalImpression:"Event_totalImpression_operator",
		externalClick:"Event_externalClick_operator",
		outboundClick:"Event_outboundClick_operator",
		_viewsCountByMonth:"Event__viewsCountByMonth_operator",
		legacyGallery:"Event_legacyGallery_operator",
		earliestStartDate:"Event_earliestStartDate_operator",
		latestEndDate:"Event_latestEndDate_operator",
		updatedAt:"Event_updatedAt_operator",
		createdAt:"Event_createdAt_operator",
		id:"Event_id_operator",
		AND:"Event_where_and",
		OR:"Event_where_or"
	},
	Location_where:{
		region:"Location_region_operator",
		creator:"Location_creator_operator",
		permalink:"Location_permalink_operator",
		status:"Location_status_operator",
		level:"Location_level_operator",
		parent:"Location_parent_operator",
		name:"Location_name_operator",
		thumbnail:"Location_thumbnail_operator",
		banner:"Location_banner_operator",
		type:"Location_type_operator",
		category:"Location_category_operator",
		district:"Location_district_operator",
		coordinate:"Location_coordinate_operator",
		linkAddress:"Location_linkAddress_operator",
		address:"Location_address_operator",
		contact__code:"Location_contact__code_operator",
		contact__phone:"Location_contact__phone_operator",
		contact__page:"Location_contact__page_operator",
		contact__email:"Location_contact__email_operator",
		legacyContent:"Location_legacyContent_operator",
		showLegacyContent:"Location_showLegacyContent_operator",
		criteria__audiences:"Location_criteria__audiences_operator",
		criteria__dates:"Location_criteria__dates_operator",
		criteria__categories:"Location_criteria__categories_operator",
		criteria__tags:"Location_criteria__tags_operator",
		sections__startDatetime:"Location_sections__startDatetime_operator",
		sections__endDatetime:"Location_sections__endDatetime_operator",
		sections__fullDay:"Location_sections__fullDay_operator",
		sections__repeat:"Location_sections__repeat_operator",
		sections__recurrance__type:"Location_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Location_sections__recurrance__weekday_operator",
		sections__id:"Location_sections__id_operator",
		_title:"Location__title_operator",
		legacyGallery:"Location_legacyGallery_operator",
		updatedAt:"Location_updatedAt_operator",
		createdAt:"Location_createdAt_operator",
		id:"Location_id_operator",
		AND:"Location_where_and",
		OR:"Location_where_or"
	},
	Location_region_operator:{
		equals:"Location_region_Input",
		not_equals:"Location_region_Input",
		in:"Location_region_Input",
		not_in:"Location_region_Input",
		all:"Location_region_Input"
	},
	Location_region_Input: "enum" as const,
	Location_creator_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_permalink_operator:{

	},
	Location_status_operator:{

	},
	Location_level_operator:{
		equals:"Location_level_Input",
		not_equals:"Location_level_Input",
		in:"Location_level_Input",
		not_in:"Location_level_Input",
		all:"Location_level_Input"
	},
	Location_level_Input: "enum" as const,
	Location_parent_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_name_operator:{

	},
	Location_thumbnail_operator:{

	},
	Location_banner_operator:{

	},
	Location_type_operator:{
		equals:"Location_type_Input",
		not_equals:"Location_type_Input",
		in:"Location_type_Input",
		not_in:"Location_type_Input",
		all:"Location_type_Input"
	},
	Location_type_Input: "enum" as const,
	Location_category_operator:{
		equals:"Location_category_Input",
		not_equals:"Location_category_Input",
		in:"Location_category_Input",
		not_in:"Location_category_Input",
		all:"Location_category_Input"
	},
	Location_category_Input: "enum" as const,
	Location_district_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_coordinate_operator:{
		within:"GeoJSONObject",
		intersects:"GeoJSONObject"
	},
	Location_linkAddress_operator:{

	},
	Location_address_operator:{

	},
	Location_contact__code_operator:{

	},
	Location_contact__phone_operator:{

	},
	Location_contact__page_operator:{

	},
	Location_contact__email_operator:{
		equals:"EmailAddress",
		not_equals:"EmailAddress",
		like:"EmailAddress",
		contains:"EmailAddress",
		in:"EmailAddress",
		not_in:"EmailAddress",
		all:"EmailAddress"
	},
	Location_legacyContent_operator:{

	},
	Location_showLegacyContent_operator:{

	},
	Location_criteria__audiences_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_criteria__dates_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_criteria__categories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_criteria__tags_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_sections__startDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_sections__endDatetime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_sections__fullDay_operator:{

	},
	Location_sections__repeat_operator:{

	},
	Location_sections__recurrance__type_operator:{
		equals:"Location_sections__recurrance__type_Input",
		not_equals:"Location_sections__recurrance__type_Input",
		in:"Location_sections__recurrance__type_Input",
		not_in:"Location_sections__recurrance__type_Input",
		all:"Location_sections__recurrance__type_Input"
	},
	Location_sections__recurrance__type_Input: "enum" as const,
	Location_sections__recurrance__weekday_operator:{
		equals:"Location_sections__recurrance__weekday_Input",
		not_equals:"Location_sections__recurrance__weekday_Input",
		in:"Location_sections__recurrance__weekday_Input",
		not_in:"Location_sections__recurrance__weekday_Input",
		all:"Location_sections__recurrance__weekday_Input"
	},
	Location_sections__recurrance__weekday_Input: "enum" as const,
	Location_sections__id_operator:{

	},
	Location__title_operator:{

	},
	Location_legacyGallery_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Location_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Location_id_operator:{

	},
	Location_where_and:{
		region:"Location_region_operator",
		creator:"Location_creator_operator",
		permalink:"Location_permalink_operator",
		status:"Location_status_operator",
		level:"Location_level_operator",
		parent:"Location_parent_operator",
		name:"Location_name_operator",
		thumbnail:"Location_thumbnail_operator",
		banner:"Location_banner_operator",
		type:"Location_type_operator",
		category:"Location_category_operator",
		district:"Location_district_operator",
		coordinate:"Location_coordinate_operator",
		linkAddress:"Location_linkAddress_operator",
		address:"Location_address_operator",
		contact__code:"Location_contact__code_operator",
		contact__phone:"Location_contact__phone_operator",
		contact__page:"Location_contact__page_operator",
		contact__email:"Location_contact__email_operator",
		legacyContent:"Location_legacyContent_operator",
		showLegacyContent:"Location_showLegacyContent_operator",
		criteria__audiences:"Location_criteria__audiences_operator",
		criteria__dates:"Location_criteria__dates_operator",
		criteria__categories:"Location_criteria__categories_operator",
		criteria__tags:"Location_criteria__tags_operator",
		sections__startDatetime:"Location_sections__startDatetime_operator",
		sections__endDatetime:"Location_sections__endDatetime_operator",
		sections__fullDay:"Location_sections__fullDay_operator",
		sections__repeat:"Location_sections__repeat_operator",
		sections__recurrance__type:"Location_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Location_sections__recurrance__weekday_operator",
		sections__id:"Location_sections__id_operator",
		_title:"Location__title_operator",
		legacyGallery:"Location_legacyGallery_operator",
		updatedAt:"Location_updatedAt_operator",
		createdAt:"Location_createdAt_operator",
		id:"Location_id_operator",
		AND:"Location_where_and",
		OR:"Location_where_or"
	},
	Location_where_or:{
		region:"Location_region_operator",
		creator:"Location_creator_operator",
		permalink:"Location_permalink_operator",
		status:"Location_status_operator",
		level:"Location_level_operator",
		parent:"Location_parent_operator",
		name:"Location_name_operator",
		thumbnail:"Location_thumbnail_operator",
		banner:"Location_banner_operator",
		type:"Location_type_operator",
		category:"Location_category_operator",
		district:"Location_district_operator",
		coordinate:"Location_coordinate_operator",
		linkAddress:"Location_linkAddress_operator",
		address:"Location_address_operator",
		contact__code:"Location_contact__code_operator",
		contact__phone:"Location_contact__phone_operator",
		contact__page:"Location_contact__page_operator",
		contact__email:"Location_contact__email_operator",
		legacyContent:"Location_legacyContent_operator",
		showLegacyContent:"Location_showLegacyContent_operator",
		criteria__audiences:"Location_criteria__audiences_operator",
		criteria__dates:"Location_criteria__dates_operator",
		criteria__categories:"Location_criteria__categories_operator",
		criteria__tags:"Location_criteria__tags_operator",
		sections__startDatetime:"Location_sections__startDatetime_operator",
		sections__endDatetime:"Location_sections__endDatetime_operator",
		sections__fullDay:"Location_sections__fullDay_operator",
		sections__repeat:"Location_sections__repeat_operator",
		sections__recurrance__type:"Location_sections__recurrance__type_operator",
		sections__recurrance__weekday:"Location_sections__recurrance__weekday_operator",
		sections__id:"Location_sections__id_operator",
		_title:"Location__title_operator",
		legacyGallery:"Location_legacyGallery_operator",
		updatedAt:"Location_updatedAt_operator",
		createdAt:"Location_createdAt_operator",
		id:"Location_id_operator",
		AND:"Location_where_and",
		OR:"Location_where_or"
	},
	Post:{
		authur:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		blogCategories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		thumbnail:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Post_Thumbnail_where"
		},
		banner:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Post_Banner_where"
		},
		location:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		legacyGallery:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Post_region: "enum" as const,
	Post_Thumbnail_where:{
		alt:"Post_Thumbnail_alt_operator",
		updatedAt:"Post_Thumbnail_updatedAt_operator",
		createdAt:"Post_Thumbnail_createdAt_operator",
		url:"Post_Thumbnail_url_operator",
		filename:"Post_Thumbnail_filename_operator",
		mimeType:"Post_Thumbnail_mimeType_operator",
		filesize:"Post_Thumbnail_filesize_operator",
		width:"Post_Thumbnail_width_operator",
		height:"Post_Thumbnail_height_operator",
		focalX:"Post_Thumbnail_focalX_operator",
		focalY:"Post_Thumbnail_focalY_operator",
		sizes__small__url:"Post_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Post_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Post_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Post_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Post_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Post_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Post_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Post_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Post_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Post_Thumbnail_id_operator",
		AND:"Post_Thumbnail_where_and",
		OR:"Post_Thumbnail_where_or"
	},
	Post_Thumbnail_alt_operator:{

	},
	Post_Thumbnail_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_Thumbnail_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_Thumbnail_url_operator:{

	},
	Post_Thumbnail_filename_operator:{

	},
	Post_Thumbnail_mimeType_operator:{

	},
	Post_Thumbnail_filesize_operator:{

	},
	Post_Thumbnail_width_operator:{

	},
	Post_Thumbnail_height_operator:{

	},
	Post_Thumbnail_focalX_operator:{

	},
	Post_Thumbnail_focalY_operator:{

	},
	Post_Thumbnail_sizes__small__url_operator:{

	},
	Post_Thumbnail_sizes__small__width_operator:{

	},
	Post_Thumbnail_sizes__small__height_operator:{

	},
	Post_Thumbnail_sizes__small__mimeType_operator:{

	},
	Post_Thumbnail_sizes__small__filesize_operator:{

	},
	Post_Thumbnail_sizes__small__filename_operator:{

	},
	Post_Thumbnail_sizes__medium__url_operator:{

	},
	Post_Thumbnail_sizes__medium__width_operator:{

	},
	Post_Thumbnail_sizes__medium__height_operator:{

	},
	Post_Thumbnail_sizes__medium__mimeType_operator:{

	},
	Post_Thumbnail_sizes__medium__filesize_operator:{

	},
	Post_Thumbnail_sizes__medium__filename_operator:{

	},
	Post_Thumbnail_sizes__large__url_operator:{

	},
	Post_Thumbnail_sizes__large__width_operator:{

	},
	Post_Thumbnail_sizes__large__height_operator:{

	},
	Post_Thumbnail_sizes__large__mimeType_operator:{

	},
	Post_Thumbnail_sizes__large__filesize_operator:{

	},
	Post_Thumbnail_sizes__large__filename_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__url_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__width_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__height_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__mimeType_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__filesize_operator:{

	},
	Post_Thumbnail_sizes__small_jpeg__filename_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__url_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__width_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__height_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__mimeType_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__filesize_operator:{

	},
	Post_Thumbnail_sizes__medium_jpeg__filename_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__url_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__width_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__height_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__mimeType_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__filesize_operator:{

	},
	Post_Thumbnail_sizes__large_jpeg__filename_operator:{

	},
	Post_Thumbnail_id_operator:{

	},
	Post_Thumbnail_where_and:{
		alt:"Post_Thumbnail_alt_operator",
		updatedAt:"Post_Thumbnail_updatedAt_operator",
		createdAt:"Post_Thumbnail_createdAt_operator",
		url:"Post_Thumbnail_url_operator",
		filename:"Post_Thumbnail_filename_operator",
		mimeType:"Post_Thumbnail_mimeType_operator",
		filesize:"Post_Thumbnail_filesize_operator",
		width:"Post_Thumbnail_width_operator",
		height:"Post_Thumbnail_height_operator",
		focalX:"Post_Thumbnail_focalX_operator",
		focalY:"Post_Thumbnail_focalY_operator",
		sizes__small__url:"Post_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Post_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Post_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Post_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Post_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Post_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Post_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Post_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Post_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Post_Thumbnail_id_operator",
		AND:"Post_Thumbnail_where_and",
		OR:"Post_Thumbnail_where_or"
	},
	Post_Thumbnail_where_or:{
		alt:"Post_Thumbnail_alt_operator",
		updatedAt:"Post_Thumbnail_updatedAt_operator",
		createdAt:"Post_Thumbnail_createdAt_operator",
		url:"Post_Thumbnail_url_operator",
		filename:"Post_Thumbnail_filename_operator",
		mimeType:"Post_Thumbnail_mimeType_operator",
		filesize:"Post_Thumbnail_filesize_operator",
		width:"Post_Thumbnail_width_operator",
		height:"Post_Thumbnail_height_operator",
		focalX:"Post_Thumbnail_focalX_operator",
		focalY:"Post_Thumbnail_focalY_operator",
		sizes__small__url:"Post_Thumbnail_sizes__small__url_operator",
		sizes__small__width:"Post_Thumbnail_sizes__small__width_operator",
		sizes__small__height:"Post_Thumbnail_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Thumbnail_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Thumbnail_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Thumbnail_sizes__small__filename_operator",
		sizes__medium__url:"Post_Thumbnail_sizes__medium__url_operator",
		sizes__medium__width:"Post_Thumbnail_sizes__medium__width_operator",
		sizes__medium__height:"Post_Thumbnail_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Thumbnail_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Thumbnail_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Thumbnail_sizes__medium__filename_operator",
		sizes__large__url:"Post_Thumbnail_sizes__large__url_operator",
		sizes__large__width:"Post_Thumbnail_sizes__large__width_operator",
		sizes__large__height:"Post_Thumbnail_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Thumbnail_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Thumbnail_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Thumbnail_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Thumbnail_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Thumbnail_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Thumbnail_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Thumbnail_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Thumbnail_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Thumbnail_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Thumbnail_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Thumbnail_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Thumbnail_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Thumbnail_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Thumbnail_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Thumbnail_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Thumbnail_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Thumbnail_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Thumbnail_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Thumbnail_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Thumbnail_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Thumbnail_sizes__large_jpeg__filename_operator",
		id:"Post_Thumbnail_id_operator",
		AND:"Post_Thumbnail_where_and",
		OR:"Post_Thumbnail_where_or"
	},
	Post_Banner_where:{
		alt:"Post_Banner_alt_operator",
		updatedAt:"Post_Banner_updatedAt_operator",
		createdAt:"Post_Banner_createdAt_operator",
		url:"Post_Banner_url_operator",
		filename:"Post_Banner_filename_operator",
		mimeType:"Post_Banner_mimeType_operator",
		filesize:"Post_Banner_filesize_operator",
		width:"Post_Banner_width_operator",
		height:"Post_Banner_height_operator",
		focalX:"Post_Banner_focalX_operator",
		focalY:"Post_Banner_focalY_operator",
		sizes__small__url:"Post_Banner_sizes__small__url_operator",
		sizes__small__width:"Post_Banner_sizes__small__width_operator",
		sizes__small__height:"Post_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Post_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Post_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Post_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Post_Banner_sizes__large__url_operator",
		sizes__large__width:"Post_Banner_sizes__large__width_operator",
		sizes__large__height:"Post_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Banner_sizes__large_jpeg__filename_operator",
		id:"Post_Banner_id_operator",
		AND:"Post_Banner_where_and",
		OR:"Post_Banner_where_or"
	},
	Post_Banner_alt_operator:{

	},
	Post_Banner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_Banner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_Banner_url_operator:{

	},
	Post_Banner_filename_operator:{

	},
	Post_Banner_mimeType_operator:{

	},
	Post_Banner_filesize_operator:{

	},
	Post_Banner_width_operator:{

	},
	Post_Banner_height_operator:{

	},
	Post_Banner_focalX_operator:{

	},
	Post_Banner_focalY_operator:{

	},
	Post_Banner_sizes__small__url_operator:{

	},
	Post_Banner_sizes__small__width_operator:{

	},
	Post_Banner_sizes__small__height_operator:{

	},
	Post_Banner_sizes__small__mimeType_operator:{

	},
	Post_Banner_sizes__small__filesize_operator:{

	},
	Post_Banner_sizes__small__filename_operator:{

	},
	Post_Banner_sizes__medium__url_operator:{

	},
	Post_Banner_sizes__medium__width_operator:{

	},
	Post_Banner_sizes__medium__height_operator:{

	},
	Post_Banner_sizes__medium__mimeType_operator:{

	},
	Post_Banner_sizes__medium__filesize_operator:{

	},
	Post_Banner_sizes__medium__filename_operator:{

	},
	Post_Banner_sizes__large__url_operator:{

	},
	Post_Banner_sizes__large__width_operator:{

	},
	Post_Banner_sizes__large__height_operator:{

	},
	Post_Banner_sizes__large__mimeType_operator:{

	},
	Post_Banner_sizes__large__filesize_operator:{

	},
	Post_Banner_sizes__large__filename_operator:{

	},
	Post_Banner_sizes__small_jpeg__url_operator:{

	},
	Post_Banner_sizes__small_jpeg__width_operator:{

	},
	Post_Banner_sizes__small_jpeg__height_operator:{

	},
	Post_Banner_sizes__small_jpeg__mimeType_operator:{

	},
	Post_Banner_sizes__small_jpeg__filesize_operator:{

	},
	Post_Banner_sizes__small_jpeg__filename_operator:{

	},
	Post_Banner_sizes__medium_jpeg__url_operator:{

	},
	Post_Banner_sizes__medium_jpeg__width_operator:{

	},
	Post_Banner_sizes__medium_jpeg__height_operator:{

	},
	Post_Banner_sizes__medium_jpeg__mimeType_operator:{

	},
	Post_Banner_sizes__medium_jpeg__filesize_operator:{

	},
	Post_Banner_sizes__medium_jpeg__filename_operator:{

	},
	Post_Banner_sizes__large_jpeg__url_operator:{

	},
	Post_Banner_sizes__large_jpeg__width_operator:{

	},
	Post_Banner_sizes__large_jpeg__height_operator:{

	},
	Post_Banner_sizes__large_jpeg__mimeType_operator:{

	},
	Post_Banner_sizes__large_jpeg__filesize_operator:{

	},
	Post_Banner_sizes__large_jpeg__filename_operator:{

	},
	Post_Banner_id_operator:{

	},
	Post_Banner_where_and:{
		alt:"Post_Banner_alt_operator",
		updatedAt:"Post_Banner_updatedAt_operator",
		createdAt:"Post_Banner_createdAt_operator",
		url:"Post_Banner_url_operator",
		filename:"Post_Banner_filename_operator",
		mimeType:"Post_Banner_mimeType_operator",
		filesize:"Post_Banner_filesize_operator",
		width:"Post_Banner_width_operator",
		height:"Post_Banner_height_operator",
		focalX:"Post_Banner_focalX_operator",
		focalY:"Post_Banner_focalY_operator",
		sizes__small__url:"Post_Banner_sizes__small__url_operator",
		sizes__small__width:"Post_Banner_sizes__small__width_operator",
		sizes__small__height:"Post_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Post_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Post_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Post_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Post_Banner_sizes__large__url_operator",
		sizes__large__width:"Post_Banner_sizes__large__width_operator",
		sizes__large__height:"Post_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Banner_sizes__large_jpeg__filename_operator",
		id:"Post_Banner_id_operator",
		AND:"Post_Banner_where_and",
		OR:"Post_Banner_where_or"
	},
	Post_Banner_where_or:{
		alt:"Post_Banner_alt_operator",
		updatedAt:"Post_Banner_updatedAt_operator",
		createdAt:"Post_Banner_createdAt_operator",
		url:"Post_Banner_url_operator",
		filename:"Post_Banner_filename_operator",
		mimeType:"Post_Banner_mimeType_operator",
		filesize:"Post_Banner_filesize_operator",
		width:"Post_Banner_width_operator",
		height:"Post_Banner_height_operator",
		focalX:"Post_Banner_focalX_operator",
		focalY:"Post_Banner_focalY_operator",
		sizes__small__url:"Post_Banner_sizes__small__url_operator",
		sizes__small__width:"Post_Banner_sizes__small__width_operator",
		sizes__small__height:"Post_Banner_sizes__small__height_operator",
		sizes__small__mimeType:"Post_Banner_sizes__small__mimeType_operator",
		sizes__small__filesize:"Post_Banner_sizes__small__filesize_operator",
		sizes__small__filename:"Post_Banner_sizes__small__filename_operator",
		sizes__medium__url:"Post_Banner_sizes__medium__url_operator",
		sizes__medium__width:"Post_Banner_sizes__medium__width_operator",
		sizes__medium__height:"Post_Banner_sizes__medium__height_operator",
		sizes__medium__mimeType:"Post_Banner_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Post_Banner_sizes__medium__filesize_operator",
		sizes__medium__filename:"Post_Banner_sizes__medium__filename_operator",
		sizes__large__url:"Post_Banner_sizes__large__url_operator",
		sizes__large__width:"Post_Banner_sizes__large__width_operator",
		sizes__large__height:"Post_Banner_sizes__large__height_operator",
		sizes__large__mimeType:"Post_Banner_sizes__large__mimeType_operator",
		sizes__large__filesize:"Post_Banner_sizes__large__filesize_operator",
		sizes__large__filename:"Post_Banner_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Post_Banner_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Post_Banner_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Post_Banner_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Post_Banner_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Post_Banner_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Post_Banner_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Post_Banner_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Post_Banner_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Post_Banner_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Post_Banner_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Post_Banner_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Post_Banner_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Post_Banner_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Post_Banner_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Post_Banner_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Post_Banner_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Post_Banner_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Post_Banner_sizes__large_jpeg__filename_operator",
		id:"Post_Banner_id_operator",
		AND:"Post_Banner_where_and",
		OR:"Post_Banner_where_or"
	},
	Post_Criteria:{
		audiences:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		dates:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		categories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		tags:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Post_where:{
		title:"Post_title_operator",
		region:"Post_region_operator",
		authur:"Post_authur_operator",
		publishDateTime:"Post_publishDateTime_operator",
		permalink:"Post_permalink_operator",
		status:"Post_status_operator",
		legacyContent:"Post_legacyContent_operator",
		showLegacyContent:"Post_showLegacyContent_operator",
		blogCategories:"Post_blogCategories_operator",
		thumbnail:"Post_thumbnail_operator",
		banner:"Post_banner_operator",
		criteria__audiences:"Post_criteria__audiences_operator",
		criteria__dates:"Post_criteria__dates_operator",
		criteria__categories:"Post_criteria__categories_operator",
		criteria__tags:"Post_criteria__tags_operator",
		location:"Post_location_operator",
		views:"Post_views_operator",
		homePageInternalClick:"Post_homePageInternalClick_operator",
		homePageImpression:"Post_homePageImpression_operator",
		blogListInternalClick:"Post_blogListInternalClick_operator",
		blogListImpression:"Post_blogListImpression_operator",
		blogDetailInternalClick:"Post_blogDetailInternalClick_operator",
		blogDetailImpression:"Post_blogDetailImpression_operator",
		relatedEventInternalClick:"Post_relatedEventInternalClick_operator",
		relatedEventImpression:"Post_relatedEventImpression_operator",
		latestEventInternalClick:"Post_latestEventInternalClick_operator",
		latestEventImpression:"Post_latestEventImpression_operator",
		internalClick:"Post_internalClick_operator",
		totalImpression:"Post_totalImpression_operator",
		externalClick:"Post_externalClick_operator",
		outboundClick:"Post_outboundClick_operator",
		legacyGallery:"Post_legacyGallery_operator",
		updatedAt:"Post_updatedAt_operator",
		createdAt:"Post_createdAt_operator",
		id:"Post_id_operator",
		AND:"Post_where_and",
		OR:"Post_where_or"
	},
	Post_title_operator:{

	},
	Post_region_operator:{
		equals:"Post_region_Input",
		not_equals:"Post_region_Input",
		in:"Post_region_Input",
		not_in:"Post_region_Input",
		all:"Post_region_Input"
	},
	Post_region_Input: "enum" as const,
	Post_authur_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_publishDateTime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_permalink_operator:{

	},
	Post_status_operator:{

	},
	Post_legacyContent_operator:{

	},
	Post_showLegacyContent_operator:{

	},
	Post_blogCategories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_thumbnail_operator:{

	},
	Post_banner_operator:{

	},
	Post_criteria__audiences_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_criteria__dates_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_criteria__categories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_criteria__tags_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_location_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_views_operator:{

	},
	Post_homePageInternalClick_operator:{

	},
	Post_homePageImpression_operator:{

	},
	Post_blogListInternalClick_operator:{

	},
	Post_blogListImpression_operator:{

	},
	Post_blogDetailInternalClick_operator:{

	},
	Post_blogDetailImpression_operator:{

	},
	Post_relatedEventInternalClick_operator:{

	},
	Post_relatedEventImpression_operator:{

	},
	Post_latestEventInternalClick_operator:{

	},
	Post_latestEventImpression_operator:{

	},
	Post_internalClick_operator:{

	},
	Post_totalImpression_operator:{

	},
	Post_externalClick_operator:{

	},
	Post_outboundClick_operator:{

	},
	Post_legacyGallery_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Post_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Post_id_operator:{

	},
	Post_where_and:{
		title:"Post_title_operator",
		region:"Post_region_operator",
		authur:"Post_authur_operator",
		publishDateTime:"Post_publishDateTime_operator",
		permalink:"Post_permalink_operator",
		status:"Post_status_operator",
		legacyContent:"Post_legacyContent_operator",
		showLegacyContent:"Post_showLegacyContent_operator",
		blogCategories:"Post_blogCategories_operator",
		thumbnail:"Post_thumbnail_operator",
		banner:"Post_banner_operator",
		criteria__audiences:"Post_criteria__audiences_operator",
		criteria__dates:"Post_criteria__dates_operator",
		criteria__categories:"Post_criteria__categories_operator",
		criteria__tags:"Post_criteria__tags_operator",
		location:"Post_location_operator",
		views:"Post_views_operator",
		homePageInternalClick:"Post_homePageInternalClick_operator",
		homePageImpression:"Post_homePageImpression_operator",
		blogListInternalClick:"Post_blogListInternalClick_operator",
		blogListImpression:"Post_blogListImpression_operator",
		blogDetailInternalClick:"Post_blogDetailInternalClick_operator",
		blogDetailImpression:"Post_blogDetailImpression_operator",
		relatedEventInternalClick:"Post_relatedEventInternalClick_operator",
		relatedEventImpression:"Post_relatedEventImpression_operator",
		latestEventInternalClick:"Post_latestEventInternalClick_operator",
		latestEventImpression:"Post_latestEventImpression_operator",
		internalClick:"Post_internalClick_operator",
		totalImpression:"Post_totalImpression_operator",
		externalClick:"Post_externalClick_operator",
		outboundClick:"Post_outboundClick_operator",
		legacyGallery:"Post_legacyGallery_operator",
		updatedAt:"Post_updatedAt_operator",
		createdAt:"Post_createdAt_operator",
		id:"Post_id_operator",
		AND:"Post_where_and",
		OR:"Post_where_or"
	},
	Post_where_or:{
		title:"Post_title_operator",
		region:"Post_region_operator",
		authur:"Post_authur_operator",
		publishDateTime:"Post_publishDateTime_operator",
		permalink:"Post_permalink_operator",
		status:"Post_status_operator",
		legacyContent:"Post_legacyContent_operator",
		showLegacyContent:"Post_showLegacyContent_operator",
		blogCategories:"Post_blogCategories_operator",
		thumbnail:"Post_thumbnail_operator",
		banner:"Post_banner_operator",
		criteria__audiences:"Post_criteria__audiences_operator",
		criteria__dates:"Post_criteria__dates_operator",
		criteria__categories:"Post_criteria__categories_operator",
		criteria__tags:"Post_criteria__tags_operator",
		location:"Post_location_operator",
		views:"Post_views_operator",
		homePageInternalClick:"Post_homePageInternalClick_operator",
		homePageImpression:"Post_homePageImpression_operator",
		blogListInternalClick:"Post_blogListInternalClick_operator",
		blogListImpression:"Post_blogListImpression_operator",
		blogDetailInternalClick:"Post_blogDetailInternalClick_operator",
		blogDetailImpression:"Post_blogDetailImpression_operator",
		relatedEventInternalClick:"Post_relatedEventInternalClick_operator",
		relatedEventImpression:"Post_relatedEventImpression_operator",
		latestEventInternalClick:"Post_latestEventInternalClick_operator",
		latestEventImpression:"Post_latestEventImpression_operator",
		internalClick:"Post_internalClick_operator",
		totalImpression:"Post_totalImpression_operator",
		externalClick:"Post_externalClick_operator",
		outboundClick:"Post_outboundClick_operator",
		legacyGallery:"Post_legacyGallery_operator",
		updatedAt:"Post_updatedAt_operator",
		createdAt:"Post_createdAt_operator",
		id:"Post_id_operator",
		AND:"Post_where_and",
		OR:"Post_where_or"
	},
	PrivateMedia_where:{
		alt:"PrivateMedia_alt_operator",
		updatedAt:"PrivateMedia_updatedAt_operator",
		createdAt:"PrivateMedia_createdAt_operator",
		url:"PrivateMedia_url_operator",
		filename:"PrivateMedia_filename_operator",
		mimeType:"PrivateMedia_mimeType_operator",
		filesize:"PrivateMedia_filesize_operator",
		width:"PrivateMedia_width_operator",
		height:"PrivateMedia_height_operator",
		focalX:"PrivateMedia_focalX_operator",
		focalY:"PrivateMedia_focalY_operator",
		sizes__thumbnail__url:"PrivateMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"PrivateMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"PrivateMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"PrivateMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"PrivateMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"PrivateMedia_sizes__thumbnail__filename_operator",
		sizes__portrait__url:"PrivateMedia_sizes__portrait__url_operator",
		sizes__portrait__width:"PrivateMedia_sizes__portrait__width_operator",
		sizes__portrait__height:"PrivateMedia_sizes__portrait__height_operator",
		sizes__portrait__mimeType:"PrivateMedia_sizes__portrait__mimeType_operator",
		sizes__portrait__filesize:"PrivateMedia_sizes__portrait__filesize_operator",
		sizes__portrait__filename:"PrivateMedia_sizes__portrait__filename_operator",
		sizes__hero__url:"PrivateMedia_sizes__hero__url_operator",
		sizes__hero__width:"PrivateMedia_sizes__hero__width_operator",
		sizes__hero__height:"PrivateMedia_sizes__hero__height_operator",
		sizes__hero__mimeType:"PrivateMedia_sizes__hero__mimeType_operator",
		sizes__hero__filesize:"PrivateMedia_sizes__hero__filesize_operator",
		sizes__hero__filename:"PrivateMedia_sizes__hero__filename_operator",
		sizes__thumbnail_jpeg__url:"PrivateMedia_sizes__thumbnail_jpeg__url_operator",
		sizes__thumbnail_jpeg__width:"PrivateMedia_sizes__thumbnail_jpeg__width_operator",
		sizes__thumbnail_jpeg__height:"PrivateMedia_sizes__thumbnail_jpeg__height_operator",
		sizes__thumbnail_jpeg__mimeType:"PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator",
		sizes__thumbnail_jpeg__filesize:"PrivateMedia_sizes__thumbnail_jpeg__filesize_operator",
		sizes__thumbnail_jpeg__filename:"PrivateMedia_sizes__thumbnail_jpeg__filename_operator",
		sizes__portrait_jpeg__url:"PrivateMedia_sizes__portrait_jpeg__url_operator",
		sizes__portrait_jpeg__width:"PrivateMedia_sizes__portrait_jpeg__width_operator",
		sizes__portrait_jpeg__height:"PrivateMedia_sizes__portrait_jpeg__height_operator",
		sizes__portrait_jpeg__mimeType:"PrivateMedia_sizes__portrait_jpeg__mimeType_operator",
		sizes__portrait_jpeg__filesize:"PrivateMedia_sizes__portrait_jpeg__filesize_operator",
		sizes__portrait_jpeg__filename:"PrivateMedia_sizes__portrait_jpeg__filename_operator",
		sizes__hero_jpeg__url:"PrivateMedia_sizes__hero_jpeg__url_operator",
		sizes__hero_jpeg__width:"PrivateMedia_sizes__hero_jpeg__width_operator",
		sizes__hero_jpeg__height:"PrivateMedia_sizes__hero_jpeg__height_operator",
		sizes__hero_jpeg__mimeType:"PrivateMedia_sizes__hero_jpeg__mimeType_operator",
		sizes__hero_jpeg__filesize:"PrivateMedia_sizes__hero_jpeg__filesize_operator",
		sizes__hero_jpeg__filename:"PrivateMedia_sizes__hero_jpeg__filename_operator",
		id:"PrivateMedia_id_operator",
		AND:"PrivateMedia_where_and",
		OR:"PrivateMedia_where_or"
	},
	PrivateMedia_alt_operator:{

	},
	PrivateMedia_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PrivateMedia_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PrivateMedia_url_operator:{

	},
	PrivateMedia_filename_operator:{

	},
	PrivateMedia_mimeType_operator:{

	},
	PrivateMedia_filesize_operator:{

	},
	PrivateMedia_width_operator:{

	},
	PrivateMedia_height_operator:{

	},
	PrivateMedia_focalX_operator:{

	},
	PrivateMedia_focalY_operator:{

	},
	PrivateMedia_sizes__thumbnail__url_operator:{

	},
	PrivateMedia_sizes__thumbnail__width_operator:{

	},
	PrivateMedia_sizes__thumbnail__height_operator:{

	},
	PrivateMedia_sizes__thumbnail__mimeType_operator:{

	},
	PrivateMedia_sizes__thumbnail__filesize_operator:{

	},
	PrivateMedia_sizes__thumbnail__filename_operator:{

	},
	PrivateMedia_sizes__portrait__url_operator:{

	},
	PrivateMedia_sizes__portrait__width_operator:{

	},
	PrivateMedia_sizes__portrait__height_operator:{

	},
	PrivateMedia_sizes__portrait__mimeType_operator:{

	},
	PrivateMedia_sizes__portrait__filesize_operator:{

	},
	PrivateMedia_sizes__portrait__filename_operator:{

	},
	PrivateMedia_sizes__hero__url_operator:{

	},
	PrivateMedia_sizes__hero__width_operator:{

	},
	PrivateMedia_sizes__hero__height_operator:{

	},
	PrivateMedia_sizes__hero__mimeType_operator:{

	},
	PrivateMedia_sizes__hero__filesize_operator:{

	},
	PrivateMedia_sizes__hero__filename_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__url_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__width_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__height_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__filesize_operator:{

	},
	PrivateMedia_sizes__thumbnail_jpeg__filename_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__url_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__width_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__height_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__mimeType_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__filesize_operator:{

	},
	PrivateMedia_sizes__portrait_jpeg__filename_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__url_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__width_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__height_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__mimeType_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__filesize_operator:{

	},
	PrivateMedia_sizes__hero_jpeg__filename_operator:{

	},
	PrivateMedia_id_operator:{

	},
	PrivateMedia_where_and:{
		alt:"PrivateMedia_alt_operator",
		updatedAt:"PrivateMedia_updatedAt_operator",
		createdAt:"PrivateMedia_createdAt_operator",
		url:"PrivateMedia_url_operator",
		filename:"PrivateMedia_filename_operator",
		mimeType:"PrivateMedia_mimeType_operator",
		filesize:"PrivateMedia_filesize_operator",
		width:"PrivateMedia_width_operator",
		height:"PrivateMedia_height_operator",
		focalX:"PrivateMedia_focalX_operator",
		focalY:"PrivateMedia_focalY_operator",
		sizes__thumbnail__url:"PrivateMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"PrivateMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"PrivateMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"PrivateMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"PrivateMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"PrivateMedia_sizes__thumbnail__filename_operator",
		sizes__portrait__url:"PrivateMedia_sizes__portrait__url_operator",
		sizes__portrait__width:"PrivateMedia_sizes__portrait__width_operator",
		sizes__portrait__height:"PrivateMedia_sizes__portrait__height_operator",
		sizes__portrait__mimeType:"PrivateMedia_sizes__portrait__mimeType_operator",
		sizes__portrait__filesize:"PrivateMedia_sizes__portrait__filesize_operator",
		sizes__portrait__filename:"PrivateMedia_sizes__portrait__filename_operator",
		sizes__hero__url:"PrivateMedia_sizes__hero__url_operator",
		sizes__hero__width:"PrivateMedia_sizes__hero__width_operator",
		sizes__hero__height:"PrivateMedia_sizes__hero__height_operator",
		sizes__hero__mimeType:"PrivateMedia_sizes__hero__mimeType_operator",
		sizes__hero__filesize:"PrivateMedia_sizes__hero__filesize_operator",
		sizes__hero__filename:"PrivateMedia_sizes__hero__filename_operator",
		sizes__thumbnail_jpeg__url:"PrivateMedia_sizes__thumbnail_jpeg__url_operator",
		sizes__thumbnail_jpeg__width:"PrivateMedia_sizes__thumbnail_jpeg__width_operator",
		sizes__thumbnail_jpeg__height:"PrivateMedia_sizes__thumbnail_jpeg__height_operator",
		sizes__thumbnail_jpeg__mimeType:"PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator",
		sizes__thumbnail_jpeg__filesize:"PrivateMedia_sizes__thumbnail_jpeg__filesize_operator",
		sizes__thumbnail_jpeg__filename:"PrivateMedia_sizes__thumbnail_jpeg__filename_operator",
		sizes__portrait_jpeg__url:"PrivateMedia_sizes__portrait_jpeg__url_operator",
		sizes__portrait_jpeg__width:"PrivateMedia_sizes__portrait_jpeg__width_operator",
		sizes__portrait_jpeg__height:"PrivateMedia_sizes__portrait_jpeg__height_operator",
		sizes__portrait_jpeg__mimeType:"PrivateMedia_sizes__portrait_jpeg__mimeType_operator",
		sizes__portrait_jpeg__filesize:"PrivateMedia_sizes__portrait_jpeg__filesize_operator",
		sizes__portrait_jpeg__filename:"PrivateMedia_sizes__portrait_jpeg__filename_operator",
		sizes__hero_jpeg__url:"PrivateMedia_sizes__hero_jpeg__url_operator",
		sizes__hero_jpeg__width:"PrivateMedia_sizes__hero_jpeg__width_operator",
		sizes__hero_jpeg__height:"PrivateMedia_sizes__hero_jpeg__height_operator",
		sizes__hero_jpeg__mimeType:"PrivateMedia_sizes__hero_jpeg__mimeType_operator",
		sizes__hero_jpeg__filesize:"PrivateMedia_sizes__hero_jpeg__filesize_operator",
		sizes__hero_jpeg__filename:"PrivateMedia_sizes__hero_jpeg__filename_operator",
		id:"PrivateMedia_id_operator",
		AND:"PrivateMedia_where_and",
		OR:"PrivateMedia_where_or"
	},
	PrivateMedia_where_or:{
		alt:"PrivateMedia_alt_operator",
		updatedAt:"PrivateMedia_updatedAt_operator",
		createdAt:"PrivateMedia_createdAt_operator",
		url:"PrivateMedia_url_operator",
		filename:"PrivateMedia_filename_operator",
		mimeType:"PrivateMedia_mimeType_operator",
		filesize:"PrivateMedia_filesize_operator",
		width:"PrivateMedia_width_operator",
		height:"PrivateMedia_height_operator",
		focalX:"PrivateMedia_focalX_operator",
		focalY:"PrivateMedia_focalY_operator",
		sizes__thumbnail__url:"PrivateMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"PrivateMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"PrivateMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"PrivateMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"PrivateMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"PrivateMedia_sizes__thumbnail__filename_operator",
		sizes__portrait__url:"PrivateMedia_sizes__portrait__url_operator",
		sizes__portrait__width:"PrivateMedia_sizes__portrait__width_operator",
		sizes__portrait__height:"PrivateMedia_sizes__portrait__height_operator",
		sizes__portrait__mimeType:"PrivateMedia_sizes__portrait__mimeType_operator",
		sizes__portrait__filesize:"PrivateMedia_sizes__portrait__filesize_operator",
		sizes__portrait__filename:"PrivateMedia_sizes__portrait__filename_operator",
		sizes__hero__url:"PrivateMedia_sizes__hero__url_operator",
		sizes__hero__width:"PrivateMedia_sizes__hero__width_operator",
		sizes__hero__height:"PrivateMedia_sizes__hero__height_operator",
		sizes__hero__mimeType:"PrivateMedia_sizes__hero__mimeType_operator",
		sizes__hero__filesize:"PrivateMedia_sizes__hero__filesize_operator",
		sizes__hero__filename:"PrivateMedia_sizes__hero__filename_operator",
		sizes__thumbnail_jpeg__url:"PrivateMedia_sizes__thumbnail_jpeg__url_operator",
		sizes__thumbnail_jpeg__width:"PrivateMedia_sizes__thumbnail_jpeg__width_operator",
		sizes__thumbnail_jpeg__height:"PrivateMedia_sizes__thumbnail_jpeg__height_operator",
		sizes__thumbnail_jpeg__mimeType:"PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator",
		sizes__thumbnail_jpeg__filesize:"PrivateMedia_sizes__thumbnail_jpeg__filesize_operator",
		sizes__thumbnail_jpeg__filename:"PrivateMedia_sizes__thumbnail_jpeg__filename_operator",
		sizes__portrait_jpeg__url:"PrivateMedia_sizes__portrait_jpeg__url_operator",
		sizes__portrait_jpeg__width:"PrivateMedia_sizes__portrait_jpeg__width_operator",
		sizes__portrait_jpeg__height:"PrivateMedia_sizes__portrait_jpeg__height_operator",
		sizes__portrait_jpeg__mimeType:"PrivateMedia_sizes__portrait_jpeg__mimeType_operator",
		sizes__portrait_jpeg__filesize:"PrivateMedia_sizes__portrait_jpeg__filesize_operator",
		sizes__portrait_jpeg__filename:"PrivateMedia_sizes__portrait_jpeg__filename_operator",
		sizes__hero_jpeg__url:"PrivateMedia_sizes__hero_jpeg__url_operator",
		sizes__hero_jpeg__width:"PrivateMedia_sizes__hero_jpeg__width_operator",
		sizes__hero_jpeg__height:"PrivateMedia_sizes__hero_jpeg__height_operator",
		sizes__hero_jpeg__mimeType:"PrivateMedia_sizes__hero_jpeg__mimeType_operator",
		sizes__hero_jpeg__filesize:"PrivateMedia_sizes__hero_jpeg__filesize_operator",
		sizes__hero_jpeg__filename:"PrivateMedia_sizes__hero_jpeg__filename_operator",
		id:"PrivateMedia_id_operator",
		AND:"PrivateMedia_where_and",
		OR:"PrivateMedia_where_or"
	},
	Media_where:{
		alt:"Media_alt_operator",
		updatedAt:"Media_updatedAt_operator",
		createdAt:"Media_createdAt_operator",
		url:"Media_url_operator",
		filename:"Media_filename_operator",
		mimeType:"Media_mimeType_operator",
		filesize:"Media_filesize_operator",
		width:"Media_width_operator",
		height:"Media_height_operator",
		focalX:"Media_focalX_operator",
		focalY:"Media_focalY_operator",
		sizes__small__url:"Media_sizes__small__url_operator",
		sizes__small__width:"Media_sizes__small__width_operator",
		sizes__small__height:"Media_sizes__small__height_operator",
		sizes__small__mimeType:"Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Media_sizes__small__filesize_operator",
		sizes__small__filename:"Media_sizes__small__filename_operator",
		sizes__medium__url:"Media_sizes__medium__url_operator",
		sizes__medium__width:"Media_sizes__medium__width_operator",
		sizes__medium__height:"Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Media_sizes__medium__filename_operator",
		sizes__large__url:"Media_sizes__large__url_operator",
		sizes__large__width:"Media_sizes__large__width_operator",
		sizes__large__height:"Media_sizes__large__height_operator",
		sizes__large__mimeType:"Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Media_sizes__large__filesize_operator",
		sizes__large__filename:"Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Media_sizes__large_jpeg__filename_operator",
		id:"Media_id_operator",
		AND:"Media_where_and",
		OR:"Media_where_or"
	},
	Media_alt_operator:{

	},
	Media_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Media_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Media_url_operator:{

	},
	Media_filename_operator:{

	},
	Media_mimeType_operator:{

	},
	Media_filesize_operator:{

	},
	Media_width_operator:{

	},
	Media_height_operator:{

	},
	Media_focalX_operator:{

	},
	Media_focalY_operator:{

	},
	Media_sizes__small__url_operator:{

	},
	Media_sizes__small__width_operator:{

	},
	Media_sizes__small__height_operator:{

	},
	Media_sizes__small__mimeType_operator:{

	},
	Media_sizes__small__filesize_operator:{

	},
	Media_sizes__small__filename_operator:{

	},
	Media_sizes__medium__url_operator:{

	},
	Media_sizes__medium__width_operator:{

	},
	Media_sizes__medium__height_operator:{

	},
	Media_sizes__medium__mimeType_operator:{

	},
	Media_sizes__medium__filesize_operator:{

	},
	Media_sizes__medium__filename_operator:{

	},
	Media_sizes__large__url_operator:{

	},
	Media_sizes__large__width_operator:{

	},
	Media_sizes__large__height_operator:{

	},
	Media_sizes__large__mimeType_operator:{

	},
	Media_sizes__large__filesize_operator:{

	},
	Media_sizes__large__filename_operator:{

	},
	Media_sizes__small_jpeg__url_operator:{

	},
	Media_sizes__small_jpeg__width_operator:{

	},
	Media_sizes__small_jpeg__height_operator:{

	},
	Media_sizes__small_jpeg__mimeType_operator:{

	},
	Media_sizes__small_jpeg__filesize_operator:{

	},
	Media_sizes__small_jpeg__filename_operator:{

	},
	Media_sizes__medium_jpeg__url_operator:{

	},
	Media_sizes__medium_jpeg__width_operator:{

	},
	Media_sizes__medium_jpeg__height_operator:{

	},
	Media_sizes__medium_jpeg__mimeType_operator:{

	},
	Media_sizes__medium_jpeg__filesize_operator:{

	},
	Media_sizes__medium_jpeg__filename_operator:{

	},
	Media_sizes__large_jpeg__url_operator:{

	},
	Media_sizes__large_jpeg__width_operator:{

	},
	Media_sizes__large_jpeg__height_operator:{

	},
	Media_sizes__large_jpeg__mimeType_operator:{

	},
	Media_sizes__large_jpeg__filesize_operator:{

	},
	Media_sizes__large_jpeg__filename_operator:{

	},
	Media_id_operator:{

	},
	Media_where_and:{
		alt:"Media_alt_operator",
		updatedAt:"Media_updatedAt_operator",
		createdAt:"Media_createdAt_operator",
		url:"Media_url_operator",
		filename:"Media_filename_operator",
		mimeType:"Media_mimeType_operator",
		filesize:"Media_filesize_operator",
		width:"Media_width_operator",
		height:"Media_height_operator",
		focalX:"Media_focalX_operator",
		focalY:"Media_focalY_operator",
		sizes__small__url:"Media_sizes__small__url_operator",
		sizes__small__width:"Media_sizes__small__width_operator",
		sizes__small__height:"Media_sizes__small__height_operator",
		sizes__small__mimeType:"Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Media_sizes__small__filesize_operator",
		sizes__small__filename:"Media_sizes__small__filename_operator",
		sizes__medium__url:"Media_sizes__medium__url_operator",
		sizes__medium__width:"Media_sizes__medium__width_operator",
		sizes__medium__height:"Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Media_sizes__medium__filename_operator",
		sizes__large__url:"Media_sizes__large__url_operator",
		sizes__large__width:"Media_sizes__large__width_operator",
		sizes__large__height:"Media_sizes__large__height_operator",
		sizes__large__mimeType:"Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Media_sizes__large__filesize_operator",
		sizes__large__filename:"Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Media_sizes__large_jpeg__filename_operator",
		id:"Media_id_operator",
		AND:"Media_where_and",
		OR:"Media_where_or"
	},
	Media_where_or:{
		alt:"Media_alt_operator",
		updatedAt:"Media_updatedAt_operator",
		createdAt:"Media_createdAt_operator",
		url:"Media_url_operator",
		filename:"Media_filename_operator",
		mimeType:"Media_mimeType_operator",
		filesize:"Media_filesize_operator",
		width:"Media_width_operator",
		height:"Media_height_operator",
		focalX:"Media_focalX_operator",
		focalY:"Media_focalY_operator",
		sizes__small__url:"Media_sizes__small__url_operator",
		sizes__small__width:"Media_sizes__small__width_operator",
		sizes__small__height:"Media_sizes__small__height_operator",
		sizes__small__mimeType:"Media_sizes__small__mimeType_operator",
		sizes__small__filesize:"Media_sizes__small__filesize_operator",
		sizes__small__filename:"Media_sizes__small__filename_operator",
		sizes__medium__url:"Media_sizes__medium__url_operator",
		sizes__medium__width:"Media_sizes__medium__width_operator",
		sizes__medium__height:"Media_sizes__medium__height_operator",
		sizes__medium__mimeType:"Media_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Media_sizes__medium__filesize_operator",
		sizes__medium__filename:"Media_sizes__medium__filename_operator",
		sizes__large__url:"Media_sizes__large__url_operator",
		sizes__large__width:"Media_sizes__large__width_operator",
		sizes__large__height:"Media_sizes__large__height_operator",
		sizes__large__mimeType:"Media_sizes__large__mimeType_operator",
		sizes__large__filesize:"Media_sizes__large__filesize_operator",
		sizes__large__filename:"Media_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Media_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Media_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Media_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Media_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Media_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Media_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Media_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Media_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Media_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Media_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Media_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Media_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Media_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Media_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Media_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Media_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Media_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Media_sizes__large_jpeg__filename_operator",
		id:"Media_id_operator",
		AND:"Media_where_and",
		OR:"Media_where_or"
	},
	BackupMedia:{
		creator:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	BackupMedia_where:{
		alt:"BackupMedia_alt_operator",
		creator:"BackupMedia_creator_operator",
		updatedAt:"BackupMedia_updatedAt_operator",
		createdAt:"BackupMedia_createdAt_operator",
		url:"BackupMedia_url_operator",
		filename:"BackupMedia_filename_operator",
		mimeType:"BackupMedia_mimeType_operator",
		filesize:"BackupMedia_filesize_operator",
		width:"BackupMedia_width_operator",
		height:"BackupMedia_height_operator",
		focalX:"BackupMedia_focalX_operator",
		focalY:"BackupMedia_focalY_operator",
		sizes__thumbnail__url:"BackupMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"BackupMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"BackupMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"BackupMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"BackupMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"BackupMedia_sizes__thumbnail__filename_operator",
		sizes__sixteenByNineMedium__url:"BackupMedia_sizes__sixteenByNineMedium__url_operator",
		sizes__sixteenByNineMedium__width:"BackupMedia_sizes__sixteenByNineMedium__width_operator",
		sizes__sixteenByNineMedium__height:"BackupMedia_sizes__sixteenByNineMedium__height_operator",
		sizes__sixteenByNineMedium__mimeType:"BackupMedia_sizes__sixteenByNineMedium__mimeType_operator",
		sizes__sixteenByNineMedium__filesize:"BackupMedia_sizes__sixteenByNineMedium__filesize_operator",
		sizes__sixteenByNineMedium__filename:"BackupMedia_sizes__sixteenByNineMedium__filename_operator",
		id:"BackupMedia_id_operator",
		AND:"BackupMedia_where_and",
		OR:"BackupMedia_where_or"
	},
	BackupMedia_alt_operator:{

	},
	BackupMedia_creator_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	BackupMedia_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BackupMedia_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BackupMedia_url_operator:{

	},
	BackupMedia_filename_operator:{

	},
	BackupMedia_mimeType_operator:{

	},
	BackupMedia_filesize_operator:{

	},
	BackupMedia_width_operator:{

	},
	BackupMedia_height_operator:{

	},
	BackupMedia_focalX_operator:{

	},
	BackupMedia_focalY_operator:{

	},
	BackupMedia_sizes__thumbnail__url_operator:{

	},
	BackupMedia_sizes__thumbnail__width_operator:{

	},
	BackupMedia_sizes__thumbnail__height_operator:{

	},
	BackupMedia_sizes__thumbnail__mimeType_operator:{

	},
	BackupMedia_sizes__thumbnail__filesize_operator:{

	},
	BackupMedia_sizes__thumbnail__filename_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__url_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__width_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__height_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__mimeType_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__filesize_operator:{

	},
	BackupMedia_sizes__sixteenByNineMedium__filename_operator:{

	},
	BackupMedia_id_operator:{

	},
	BackupMedia_where_and:{
		alt:"BackupMedia_alt_operator",
		creator:"BackupMedia_creator_operator",
		updatedAt:"BackupMedia_updatedAt_operator",
		createdAt:"BackupMedia_createdAt_operator",
		url:"BackupMedia_url_operator",
		filename:"BackupMedia_filename_operator",
		mimeType:"BackupMedia_mimeType_operator",
		filesize:"BackupMedia_filesize_operator",
		width:"BackupMedia_width_operator",
		height:"BackupMedia_height_operator",
		focalX:"BackupMedia_focalX_operator",
		focalY:"BackupMedia_focalY_operator",
		sizes__thumbnail__url:"BackupMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"BackupMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"BackupMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"BackupMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"BackupMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"BackupMedia_sizes__thumbnail__filename_operator",
		sizes__sixteenByNineMedium__url:"BackupMedia_sizes__sixteenByNineMedium__url_operator",
		sizes__sixteenByNineMedium__width:"BackupMedia_sizes__sixteenByNineMedium__width_operator",
		sizes__sixteenByNineMedium__height:"BackupMedia_sizes__sixteenByNineMedium__height_operator",
		sizes__sixteenByNineMedium__mimeType:"BackupMedia_sizes__sixteenByNineMedium__mimeType_operator",
		sizes__sixteenByNineMedium__filesize:"BackupMedia_sizes__sixteenByNineMedium__filesize_operator",
		sizes__sixteenByNineMedium__filename:"BackupMedia_sizes__sixteenByNineMedium__filename_operator",
		id:"BackupMedia_id_operator",
		AND:"BackupMedia_where_and",
		OR:"BackupMedia_where_or"
	},
	BackupMedia_where_or:{
		alt:"BackupMedia_alt_operator",
		creator:"BackupMedia_creator_operator",
		updatedAt:"BackupMedia_updatedAt_operator",
		createdAt:"BackupMedia_createdAt_operator",
		url:"BackupMedia_url_operator",
		filename:"BackupMedia_filename_operator",
		mimeType:"BackupMedia_mimeType_operator",
		filesize:"BackupMedia_filesize_operator",
		width:"BackupMedia_width_operator",
		height:"BackupMedia_height_operator",
		focalX:"BackupMedia_focalX_operator",
		focalY:"BackupMedia_focalY_operator",
		sizes__thumbnail__url:"BackupMedia_sizes__thumbnail__url_operator",
		sizes__thumbnail__width:"BackupMedia_sizes__thumbnail__width_operator",
		sizes__thumbnail__height:"BackupMedia_sizes__thumbnail__height_operator",
		sizes__thumbnail__mimeType:"BackupMedia_sizes__thumbnail__mimeType_operator",
		sizes__thumbnail__filesize:"BackupMedia_sizes__thumbnail__filesize_operator",
		sizes__thumbnail__filename:"BackupMedia_sizes__thumbnail__filename_operator",
		sizes__sixteenByNineMedium__url:"BackupMedia_sizes__sixteenByNineMedium__url_operator",
		sizes__sixteenByNineMedium__width:"BackupMedia_sizes__sixteenByNineMedium__width_operator",
		sizes__sixteenByNineMedium__height:"BackupMedia_sizes__sixteenByNineMedium__height_operator",
		sizes__sixteenByNineMedium__mimeType:"BackupMedia_sizes__sixteenByNineMedium__mimeType_operator",
		sizes__sixteenByNineMedium__filesize:"BackupMedia_sizes__sixteenByNineMedium__filesize_operator",
		sizes__sixteenByNineMedium__filename:"BackupMedia_sizes__sixteenByNineMedium__filename_operator",
		id:"BackupMedia_id_operator",
		AND:"BackupMedia_where_and",
		OR:"BackupMedia_where_or"
	},
	EventRanking_template: "enum" as const,
	EventRanking_List:{
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EventRanking_region: "enum" as const,
	EventRanking_where:{
		name:"EventRanking_name_operator",
		visible:"EventRanking_visible_operator",
		maxItems:"EventRanking_maxItems_operator",
		template:"EventRanking_template_operator",
		list__item:"EventRanking_list__item_operator",
		list__id:"EventRanking_list__id_operator",
		region:"EventRanking_region_operator",
		updatedAt:"EventRanking_updatedAt_operator",
		createdAt:"EventRanking_createdAt_operator",
		id:"EventRanking_id_operator",
		AND:"EventRanking_where_and",
		OR:"EventRanking_where_or"
	},
	EventRanking_name_operator:{

	},
	EventRanking_visible_operator:{

	},
	EventRanking_maxItems_operator:{

	},
	EventRanking_template_operator:{
		equals:"EventRanking_template_Input",
		not_equals:"EventRanking_template_Input",
		in:"EventRanking_template_Input",
		not_in:"EventRanking_template_Input",
		all:"EventRanking_template_Input"
	},
	EventRanking_template_Input: "enum" as const,
	EventRanking_list__item_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EventRanking_list__id_operator:{

	},
	EventRanking_region_operator:{
		equals:"EventRanking_region_Input",
		not_equals:"EventRanking_region_Input",
		in:"EventRanking_region_Input",
		not_in:"EventRanking_region_Input",
		all:"EventRanking_region_Input"
	},
	EventRanking_region_Input: "enum" as const,
	EventRanking_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventRanking_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventRanking_id_operator:{

	},
	EventRanking_where_and:{
		name:"EventRanking_name_operator",
		visible:"EventRanking_visible_operator",
		maxItems:"EventRanking_maxItems_operator",
		template:"EventRanking_template_operator",
		list__item:"EventRanking_list__item_operator",
		list__id:"EventRanking_list__id_operator",
		region:"EventRanking_region_operator",
		updatedAt:"EventRanking_updatedAt_operator",
		createdAt:"EventRanking_createdAt_operator",
		id:"EventRanking_id_operator",
		AND:"EventRanking_where_and",
		OR:"EventRanking_where_or"
	},
	EventRanking_where_or:{
		name:"EventRanking_name_operator",
		visible:"EventRanking_visible_operator",
		maxItems:"EventRanking_maxItems_operator",
		template:"EventRanking_template_operator",
		list__item:"EventRanking_list__item_operator",
		list__id:"EventRanking_list__id_operator",
		region:"EventRanking_region_operator",
		updatedAt:"EventRanking_updatedAt_operator",
		createdAt:"EventRanking_createdAt_operator",
		id:"EventRanking_id_operator",
		AND:"EventRanking_where_and",
		OR:"EventRanking_where_or"
	},
	LocationRanking_template: "enum" as const,
	LocationRanking_List:{
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	LocationRanking_region: "enum" as const,
	LocationRanking_where:{
		name:"LocationRanking_name_operator",
		visible:"LocationRanking_visible_operator",
		maxItems:"LocationRanking_maxItems_operator",
		template:"LocationRanking_template_operator",
		list__item:"LocationRanking_list__item_operator",
		list__id:"LocationRanking_list__id_operator",
		region:"LocationRanking_region_operator",
		updatedAt:"LocationRanking_updatedAt_operator",
		createdAt:"LocationRanking_createdAt_operator",
		id:"LocationRanking_id_operator",
		AND:"LocationRanking_where_and",
		OR:"LocationRanking_where_or"
	},
	LocationRanking_name_operator:{

	},
	LocationRanking_visible_operator:{

	},
	LocationRanking_maxItems_operator:{

	},
	LocationRanking_template_operator:{
		equals:"LocationRanking_template_Input",
		not_equals:"LocationRanking_template_Input",
		in:"LocationRanking_template_Input",
		not_in:"LocationRanking_template_Input",
		all:"LocationRanking_template_Input"
	},
	LocationRanking_template_Input: "enum" as const,
	LocationRanking_list__item_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	LocationRanking_list__id_operator:{

	},
	LocationRanking_region_operator:{
		equals:"LocationRanking_region_Input",
		not_equals:"LocationRanking_region_Input",
		in:"LocationRanking_region_Input",
		not_in:"LocationRanking_region_Input",
		all:"LocationRanking_region_Input"
	},
	LocationRanking_region_Input: "enum" as const,
	LocationRanking_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	LocationRanking_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	LocationRanking_id_operator:{

	},
	LocationRanking_where_and:{
		name:"LocationRanking_name_operator",
		visible:"LocationRanking_visible_operator",
		maxItems:"LocationRanking_maxItems_operator",
		template:"LocationRanking_template_operator",
		list__item:"LocationRanking_list__item_operator",
		list__id:"LocationRanking_list__id_operator",
		region:"LocationRanking_region_operator",
		updatedAt:"LocationRanking_updatedAt_operator",
		createdAt:"LocationRanking_createdAt_operator",
		id:"LocationRanking_id_operator",
		AND:"LocationRanking_where_and",
		OR:"LocationRanking_where_or"
	},
	LocationRanking_where_or:{
		name:"LocationRanking_name_operator",
		visible:"LocationRanking_visible_operator",
		maxItems:"LocationRanking_maxItems_operator",
		template:"LocationRanking_template_operator",
		list__item:"LocationRanking_list__item_operator",
		list__id:"LocationRanking_list__id_operator",
		region:"LocationRanking_region_operator",
		updatedAt:"LocationRanking_updatedAt_operator",
		createdAt:"LocationRanking_createdAt_operator",
		id:"LocationRanking_id_operator",
		AND:"LocationRanking_where_and",
		OR:"LocationRanking_where_or"
	},
	AccountSuspendRequest:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		handledBy:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	AccountSuspendRequest_where:{
		user:"AccountSuspendRequest_user_operator",
		handledBy:"AccountSuspendRequest_handledBy_operator",
		updatedAt:"AccountSuspendRequest_updatedAt_operator",
		createdAt:"AccountSuspendRequest_createdAt_operator",
		id:"AccountSuspendRequest_id_operator",
		AND:"AccountSuspendRequest_where_and",
		OR:"AccountSuspendRequest_where_or"
	},
	AccountSuspendRequest_user_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	AccountSuspendRequest_handledBy_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	AccountSuspendRequest_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	AccountSuspendRequest_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	AccountSuspendRequest_id_operator:{

	},
	AccountSuspendRequest_where_and:{
		user:"AccountSuspendRequest_user_operator",
		handledBy:"AccountSuspendRequest_handledBy_operator",
		updatedAt:"AccountSuspendRequest_updatedAt_operator",
		createdAt:"AccountSuspendRequest_createdAt_operator",
		id:"AccountSuspendRequest_id_operator",
		AND:"AccountSuspendRequest_where_and",
		OR:"AccountSuspendRequest_where_or"
	},
	AccountSuspendRequest_where_or:{
		user:"AccountSuspendRequest_user_operator",
		handledBy:"AccountSuspendRequest_handledBy_operator",
		updatedAt:"AccountSuspendRequest_updatedAt_operator",
		createdAt:"AccountSuspendRequest_createdAt_operator",
		id:"AccountSuspendRequest_id_operator",
		AND:"AccountSuspendRequest_where_and",
		OR:"AccountSuspendRequest_where_or"
	},
	Bookmark:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Bookmark_User_RelationTo: "enum" as const,
	Bookmark_Item_RelationTo: "enum" as const,
	Bookmark_where:{
		user:"Bookmark_user_Relation",
		item:"Bookmark_item_Relation",
		updatedAt:"Bookmark_updatedAt_operator",
		createdAt:"Bookmark_createdAt_operator",
		id:"Bookmark_id_operator",
		AND:"Bookmark_where_and",
		OR:"Bookmark_where_or"
	},
	Bookmark_user_Relation:{
		relationTo:"Bookmark_user_Relation_RelationTo",
		value:"JSON"
	},
	Bookmark_user_Relation_RelationTo: "enum" as const,
	Bookmark_item_Relation:{
		relationTo:"Bookmark_item_Relation_RelationTo",
		value:"JSON"
	},
	Bookmark_item_Relation_RelationTo: "enum" as const,
	Bookmark_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Bookmark_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Bookmark_id_operator:{

	},
	Bookmark_where_and:{
		user:"Bookmark_user_Relation",
		item:"Bookmark_item_Relation",
		updatedAt:"Bookmark_updatedAt_operator",
		createdAt:"Bookmark_createdAt_operator",
		id:"Bookmark_id_operator",
		AND:"Bookmark_where_and",
		OR:"Bookmark_where_or"
	},
	Bookmark_where_or:{
		user:"Bookmark_user_Relation",
		item:"Bookmark_item_Relation",
		updatedAt:"Bookmark_updatedAt_operator",
		createdAt:"Bookmark_createdAt_operator",
		id:"Bookmark_id_operator",
		AND:"Bookmark_where_and",
		OR:"Bookmark_where_or"
	},
	ViewRecord:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	ViewRecord_User_RelationTo: "enum" as const,
	ViewRecord_Item_RelationTo: "enum" as const,
	ViewRecord_where:{
		user:"ViewRecord_user_Relation",
		item:"ViewRecord_item_Relation",
		updatedAt:"ViewRecord_updatedAt_operator",
		createdAt:"ViewRecord_createdAt_operator",
		id:"ViewRecord_id_operator",
		AND:"ViewRecord_where_and",
		OR:"ViewRecord_where_or"
	},
	ViewRecord_user_Relation:{
		relationTo:"ViewRecord_user_Relation_RelationTo",
		value:"JSON"
	},
	ViewRecord_user_Relation_RelationTo: "enum" as const,
	ViewRecord_item_Relation:{
		relationTo:"ViewRecord_item_Relation_RelationTo",
		value:"JSON"
	},
	ViewRecord_item_Relation_RelationTo: "enum" as const,
	ViewRecord_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	ViewRecord_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	ViewRecord_id_operator:{

	},
	ViewRecord_where_and:{
		user:"ViewRecord_user_Relation",
		item:"ViewRecord_item_Relation",
		updatedAt:"ViewRecord_updatedAt_operator",
		createdAt:"ViewRecord_createdAt_operator",
		id:"ViewRecord_id_operator",
		AND:"ViewRecord_where_and",
		OR:"ViewRecord_where_or"
	},
	ViewRecord_where_or:{
		user:"ViewRecord_user_Relation",
		item:"ViewRecord_item_Relation",
		updatedAt:"ViewRecord_updatedAt_operator",
		createdAt:"ViewRecord_createdAt_operator",
		id:"ViewRecord_id_operator",
		AND:"ViewRecord_where_and",
		OR:"ViewRecord_where_or"
	},
	EventCategory_where:{
		name:"EventCategory_name_operator",
		value:"EventCategory_value_operator",
		tags:"EventCategory_tags_operator",
		icon:"EventCategory_icon_operator",
		region:"EventCategory_region_operator",
		updatedAt:"EventCategory_updatedAt_operator",
		createdAt:"EventCategory_createdAt_operator",
		id:"EventCategory_id_operator",
		AND:"EventCategory_where_and",
		OR:"EventCategory_where_or"
	},
	EventCategory_name_operator:{

	},
	EventCategory_value_operator:{

	},
	EventCategory_tags_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EventCategory_icon_operator:{

	},
	EventCategory_region_operator:{
		equals:"EventCategory_region_Input",
		not_equals:"EventCategory_region_Input",
		in:"EventCategory_region_Input",
		not_in:"EventCategory_region_Input",
		all:"EventCategory_region_Input"
	},
	EventCategory_region_Input: "enum" as const,
	EventCategory_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCategory_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCategory_id_operator:{

	},
	EventCategory_where_and:{
		name:"EventCategory_name_operator",
		value:"EventCategory_value_operator",
		tags:"EventCategory_tags_operator",
		icon:"EventCategory_icon_operator",
		region:"EventCategory_region_operator",
		updatedAt:"EventCategory_updatedAt_operator",
		createdAt:"EventCategory_createdAt_operator",
		id:"EventCategory_id_operator",
		AND:"EventCategory_where_and",
		OR:"EventCategory_where_or"
	},
	EventCategory_where_or:{
		name:"EventCategory_name_operator",
		value:"EventCategory_value_operator",
		tags:"EventCategory_tags_operator",
		icon:"EventCategory_icon_operator",
		region:"EventCategory_region_operator",
		updatedAt:"EventCategory_updatedAt_operator",
		createdAt:"EventCategory_createdAt_operator",
		id:"EventCategory_id_operator",
		AND:"EventCategory_where_and",
		OR:"EventCategory_where_or"
	},
	Comment:{
		commenter:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		image:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Comment_Image_where"
		},
		target:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		parent:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		reviewedBy:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Comment_Image_where:{
		alt:"Comment_Image_alt_operator",
		updatedAt:"Comment_Image_updatedAt_operator",
		createdAt:"Comment_Image_createdAt_operator",
		url:"Comment_Image_url_operator",
		filename:"Comment_Image_filename_operator",
		mimeType:"Comment_Image_mimeType_operator",
		filesize:"Comment_Image_filesize_operator",
		width:"Comment_Image_width_operator",
		height:"Comment_Image_height_operator",
		focalX:"Comment_Image_focalX_operator",
		focalY:"Comment_Image_focalY_operator",
		sizes__small__url:"Comment_Image_sizes__small__url_operator",
		sizes__small__width:"Comment_Image_sizes__small__width_operator",
		sizes__small__height:"Comment_Image_sizes__small__height_operator",
		sizes__small__mimeType:"Comment_Image_sizes__small__mimeType_operator",
		sizes__small__filesize:"Comment_Image_sizes__small__filesize_operator",
		sizes__small__filename:"Comment_Image_sizes__small__filename_operator",
		sizes__medium__url:"Comment_Image_sizes__medium__url_operator",
		sizes__medium__width:"Comment_Image_sizes__medium__width_operator",
		sizes__medium__height:"Comment_Image_sizes__medium__height_operator",
		sizes__medium__mimeType:"Comment_Image_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Comment_Image_sizes__medium__filesize_operator",
		sizes__medium__filename:"Comment_Image_sizes__medium__filename_operator",
		sizes__large__url:"Comment_Image_sizes__large__url_operator",
		sizes__large__width:"Comment_Image_sizes__large__width_operator",
		sizes__large__height:"Comment_Image_sizes__large__height_operator",
		sizes__large__mimeType:"Comment_Image_sizes__large__mimeType_operator",
		sizes__large__filesize:"Comment_Image_sizes__large__filesize_operator",
		sizes__large__filename:"Comment_Image_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Comment_Image_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Comment_Image_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Comment_Image_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Comment_Image_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Comment_Image_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Comment_Image_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Comment_Image_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Comment_Image_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Comment_Image_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Comment_Image_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Comment_Image_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Comment_Image_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Comment_Image_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Comment_Image_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Comment_Image_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Comment_Image_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Comment_Image_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Comment_Image_sizes__large_jpeg__filename_operator",
		id:"Comment_Image_id_operator",
		AND:"Comment_Image_where_and",
		OR:"Comment_Image_where_or"
	},
	Comment_Image_alt_operator:{

	},
	Comment_Image_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Comment_Image_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Comment_Image_url_operator:{

	},
	Comment_Image_filename_operator:{

	},
	Comment_Image_mimeType_operator:{

	},
	Comment_Image_filesize_operator:{

	},
	Comment_Image_width_operator:{

	},
	Comment_Image_height_operator:{

	},
	Comment_Image_focalX_operator:{

	},
	Comment_Image_focalY_operator:{

	},
	Comment_Image_sizes__small__url_operator:{

	},
	Comment_Image_sizes__small__width_operator:{

	},
	Comment_Image_sizes__small__height_operator:{

	},
	Comment_Image_sizes__small__mimeType_operator:{

	},
	Comment_Image_sizes__small__filesize_operator:{

	},
	Comment_Image_sizes__small__filename_operator:{

	},
	Comment_Image_sizes__medium__url_operator:{

	},
	Comment_Image_sizes__medium__width_operator:{

	},
	Comment_Image_sizes__medium__height_operator:{

	},
	Comment_Image_sizes__medium__mimeType_operator:{

	},
	Comment_Image_sizes__medium__filesize_operator:{

	},
	Comment_Image_sizes__medium__filename_operator:{

	},
	Comment_Image_sizes__large__url_operator:{

	},
	Comment_Image_sizes__large__width_operator:{

	},
	Comment_Image_sizes__large__height_operator:{

	},
	Comment_Image_sizes__large__mimeType_operator:{

	},
	Comment_Image_sizes__large__filesize_operator:{

	},
	Comment_Image_sizes__large__filename_operator:{

	},
	Comment_Image_sizes__small_jpeg__url_operator:{

	},
	Comment_Image_sizes__small_jpeg__width_operator:{

	},
	Comment_Image_sizes__small_jpeg__height_operator:{

	},
	Comment_Image_sizes__small_jpeg__mimeType_operator:{

	},
	Comment_Image_sizes__small_jpeg__filesize_operator:{

	},
	Comment_Image_sizes__small_jpeg__filename_operator:{

	},
	Comment_Image_sizes__medium_jpeg__url_operator:{

	},
	Comment_Image_sizes__medium_jpeg__width_operator:{

	},
	Comment_Image_sizes__medium_jpeg__height_operator:{

	},
	Comment_Image_sizes__medium_jpeg__mimeType_operator:{

	},
	Comment_Image_sizes__medium_jpeg__filesize_operator:{

	},
	Comment_Image_sizes__medium_jpeg__filename_operator:{

	},
	Comment_Image_sizes__large_jpeg__url_operator:{

	},
	Comment_Image_sizes__large_jpeg__width_operator:{

	},
	Comment_Image_sizes__large_jpeg__height_operator:{

	},
	Comment_Image_sizes__large_jpeg__mimeType_operator:{

	},
	Comment_Image_sizes__large_jpeg__filesize_operator:{

	},
	Comment_Image_sizes__large_jpeg__filename_operator:{

	},
	Comment_Image_id_operator:{

	},
	Comment_Image_where_and:{
		alt:"Comment_Image_alt_operator",
		updatedAt:"Comment_Image_updatedAt_operator",
		createdAt:"Comment_Image_createdAt_operator",
		url:"Comment_Image_url_operator",
		filename:"Comment_Image_filename_operator",
		mimeType:"Comment_Image_mimeType_operator",
		filesize:"Comment_Image_filesize_operator",
		width:"Comment_Image_width_operator",
		height:"Comment_Image_height_operator",
		focalX:"Comment_Image_focalX_operator",
		focalY:"Comment_Image_focalY_operator",
		sizes__small__url:"Comment_Image_sizes__small__url_operator",
		sizes__small__width:"Comment_Image_sizes__small__width_operator",
		sizes__small__height:"Comment_Image_sizes__small__height_operator",
		sizes__small__mimeType:"Comment_Image_sizes__small__mimeType_operator",
		sizes__small__filesize:"Comment_Image_sizes__small__filesize_operator",
		sizes__small__filename:"Comment_Image_sizes__small__filename_operator",
		sizes__medium__url:"Comment_Image_sizes__medium__url_operator",
		sizes__medium__width:"Comment_Image_sizes__medium__width_operator",
		sizes__medium__height:"Comment_Image_sizes__medium__height_operator",
		sizes__medium__mimeType:"Comment_Image_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Comment_Image_sizes__medium__filesize_operator",
		sizes__medium__filename:"Comment_Image_sizes__medium__filename_operator",
		sizes__large__url:"Comment_Image_sizes__large__url_operator",
		sizes__large__width:"Comment_Image_sizes__large__width_operator",
		sizes__large__height:"Comment_Image_sizes__large__height_operator",
		sizes__large__mimeType:"Comment_Image_sizes__large__mimeType_operator",
		sizes__large__filesize:"Comment_Image_sizes__large__filesize_operator",
		sizes__large__filename:"Comment_Image_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Comment_Image_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Comment_Image_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Comment_Image_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Comment_Image_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Comment_Image_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Comment_Image_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Comment_Image_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Comment_Image_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Comment_Image_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Comment_Image_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Comment_Image_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Comment_Image_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Comment_Image_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Comment_Image_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Comment_Image_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Comment_Image_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Comment_Image_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Comment_Image_sizes__large_jpeg__filename_operator",
		id:"Comment_Image_id_operator",
		AND:"Comment_Image_where_and",
		OR:"Comment_Image_where_or"
	},
	Comment_Image_where_or:{
		alt:"Comment_Image_alt_operator",
		updatedAt:"Comment_Image_updatedAt_operator",
		createdAt:"Comment_Image_createdAt_operator",
		url:"Comment_Image_url_operator",
		filename:"Comment_Image_filename_operator",
		mimeType:"Comment_Image_mimeType_operator",
		filesize:"Comment_Image_filesize_operator",
		width:"Comment_Image_width_operator",
		height:"Comment_Image_height_operator",
		focalX:"Comment_Image_focalX_operator",
		focalY:"Comment_Image_focalY_operator",
		sizes__small__url:"Comment_Image_sizes__small__url_operator",
		sizes__small__width:"Comment_Image_sizes__small__width_operator",
		sizes__small__height:"Comment_Image_sizes__small__height_operator",
		sizes__small__mimeType:"Comment_Image_sizes__small__mimeType_operator",
		sizes__small__filesize:"Comment_Image_sizes__small__filesize_operator",
		sizes__small__filename:"Comment_Image_sizes__small__filename_operator",
		sizes__medium__url:"Comment_Image_sizes__medium__url_operator",
		sizes__medium__width:"Comment_Image_sizes__medium__width_operator",
		sizes__medium__height:"Comment_Image_sizes__medium__height_operator",
		sizes__medium__mimeType:"Comment_Image_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Comment_Image_sizes__medium__filesize_operator",
		sizes__medium__filename:"Comment_Image_sizes__medium__filename_operator",
		sizes__large__url:"Comment_Image_sizes__large__url_operator",
		sizes__large__width:"Comment_Image_sizes__large__width_operator",
		sizes__large__height:"Comment_Image_sizes__large__height_operator",
		sizes__large__mimeType:"Comment_Image_sizes__large__mimeType_operator",
		sizes__large__filesize:"Comment_Image_sizes__large__filesize_operator",
		sizes__large__filename:"Comment_Image_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Comment_Image_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Comment_Image_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Comment_Image_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Comment_Image_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Comment_Image_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Comment_Image_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Comment_Image_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Comment_Image_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Comment_Image_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Comment_Image_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Comment_Image_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Comment_Image_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Comment_Image_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Comment_Image_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Comment_Image_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Comment_Image_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Comment_Image_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Comment_Image_sizes__large_jpeg__filename_operator",
		id:"Comment_Image_id_operator",
		AND:"Comment_Image_where_and",
		OR:"Comment_Image_where_or"
	},
	Comment_Target_RelationTo: "enum" as const,
	Comment_Parent_RelationTo: "enum" as const,
	Comment_where:{
		commenter:"Comment_commenter_operator",
		content:"Comment_content_operator",
		image:"Comment_image_operator",
		target:"Comment_target_Relation",
		parent:"Comment_parent_Relation",
		isReviewed:"Comment_isReviewed_operator",
		reviewedBy:"Comment_reviewedBy_operator",
		banned:"Comment_banned_operator",
		type:"Comment_type_operator",
		updatedAt:"Comment_updatedAt_operator",
		createdAt:"Comment_createdAt_operator",
		id:"Comment_id_operator",
		AND:"Comment_where_and",
		OR:"Comment_where_or"
	},
	Comment_commenter_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Comment_content_operator:{

	},
	Comment_image_operator:{

	},
	Comment_target_Relation:{
		relationTo:"Comment_target_Relation_RelationTo",
		value:"JSON"
	},
	Comment_target_Relation_RelationTo: "enum" as const,
	Comment_parent_Relation:{
		relationTo:"Comment_parent_Relation_RelationTo",
		value:"JSON"
	},
	Comment_parent_Relation_RelationTo: "enum" as const,
	Comment_isReviewed_operator:{

	},
	Comment_reviewedBy_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Comment_banned_operator:{

	},
	Comment_type_operator:{

	},
	Comment_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Comment_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Comment_id_operator:{

	},
	Comment_where_and:{
		commenter:"Comment_commenter_operator",
		content:"Comment_content_operator",
		image:"Comment_image_operator",
		target:"Comment_target_Relation",
		parent:"Comment_parent_Relation",
		isReviewed:"Comment_isReviewed_operator",
		reviewedBy:"Comment_reviewedBy_operator",
		banned:"Comment_banned_operator",
		type:"Comment_type_operator",
		updatedAt:"Comment_updatedAt_operator",
		createdAt:"Comment_createdAt_operator",
		id:"Comment_id_operator",
		AND:"Comment_where_and",
		OR:"Comment_where_or"
	},
	Comment_where_or:{
		commenter:"Comment_commenter_operator",
		content:"Comment_content_operator",
		image:"Comment_image_operator",
		target:"Comment_target_Relation",
		parent:"Comment_parent_Relation",
		isReviewed:"Comment_isReviewed_operator",
		reviewedBy:"Comment_reviewedBy_operator",
		banned:"Comment_banned_operator",
		type:"Comment_type_operator",
		updatedAt:"Comment_updatedAt_operator",
		createdAt:"Comment_createdAt_operator",
		id:"Comment_id_operator",
		AND:"Comment_where_and",
		OR:"Comment_where_or"
	},
	EventCalendar:{
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EventCalendar_region: "enum" as const,
	EventCalendar_where:{
		date:"EventCalendar_date_operator",
		item:"EventCalendar_item_operator",
		title:"EventCalendar_title_operator",
		region:"EventCalendar_region_operator",
		updatedAt:"EventCalendar_updatedAt_operator",
		createdAt:"EventCalendar_createdAt_operator",
		id:"EventCalendar_id_operator",
		AND:"EventCalendar_where_and",
		OR:"EventCalendar_where_or"
	},
	EventCalendar_date_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCalendar_item_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EventCalendar_title_operator:{

	},
	EventCalendar_region_operator:{
		equals:"EventCalendar_region_Input",
		not_equals:"EventCalendar_region_Input",
		in:"EventCalendar_region_Input",
		not_in:"EventCalendar_region_Input",
		all:"EventCalendar_region_Input"
	},
	EventCalendar_region_Input: "enum" as const,
	EventCalendar_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCalendar_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventCalendar_id_operator:{

	},
	EventCalendar_where_and:{
		date:"EventCalendar_date_operator",
		item:"EventCalendar_item_operator",
		title:"EventCalendar_title_operator",
		region:"EventCalendar_region_operator",
		updatedAt:"EventCalendar_updatedAt_operator",
		createdAt:"EventCalendar_createdAt_operator",
		id:"EventCalendar_id_operator",
		AND:"EventCalendar_where_and",
		OR:"EventCalendar_where_or"
	},
	EventCalendar_where_or:{
		date:"EventCalendar_date_operator",
		item:"EventCalendar_item_operator",
		title:"EventCalendar_title_operator",
		region:"EventCalendar_region_operator",
		updatedAt:"EventCalendar_updatedAt_operator",
		createdAt:"EventCalendar_createdAt_operator",
		id:"EventCalendar_id_operator",
		AND:"EventCalendar_where_and",
		OR:"EventCalendar_where_or"
	},
	Subscription:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		organiser:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Subscription_User_RelationTo: "enum" as const,
	Subscription_Organiser_RelationTo: "enum" as const,
	Subscription_where:{
		user:"Subscription_user_Relation",
		organiser:"Subscription_organiser_Relation",
		updatedAt:"Subscription_updatedAt_operator",
		createdAt:"Subscription_createdAt_operator",
		id:"Subscription_id_operator",
		AND:"Subscription_where_and",
		OR:"Subscription_where_or"
	},
	Subscription_user_Relation:{
		relationTo:"Subscription_user_Relation_RelationTo",
		value:"JSON"
	},
	Subscription_user_Relation_RelationTo: "enum" as const,
	Subscription_organiser_Relation:{
		relationTo:"Subscription_organiser_Relation_RelationTo",
		value:"JSON"
	},
	Subscription_organiser_Relation_RelationTo: "enum" as const,
	Subscription_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Subscription_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Subscription_id_operator:{

	},
	Subscription_where_and:{
		user:"Subscription_user_Relation",
		organiser:"Subscription_organiser_Relation",
		updatedAt:"Subscription_updatedAt_operator",
		createdAt:"Subscription_createdAt_operator",
		id:"Subscription_id_operator",
		AND:"Subscription_where_and",
		OR:"Subscription_where_or"
	},
	Subscription_where_or:{
		user:"Subscription_user_Relation",
		organiser:"Subscription_organiser_Relation",
		updatedAt:"Subscription_updatedAt_operator",
		createdAt:"Subscription_createdAt_operator",
		id:"Subscription_id_operator",
		AND:"Subscription_where_and",
		OR:"Subscription_where_or"
	},
	EditorChoice:{
		editorialPicks:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EditorChoice_List:{
		item:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EditorChoice_region: "enum" as const,
	EditorChoice_where:{
		name:"EditorChoice_name_operator",
		visible:"EditorChoice_visible_operator",
		editorialPicks:"EditorChoice_editorialPicks_operator",
		list__item:"EditorChoice_list__item_operator",
		list__id:"EditorChoice_list__id_operator",
		region:"EditorChoice_region_operator",
		updatedAt:"EditorChoice_updatedAt_operator",
		createdAt:"EditorChoice_createdAt_operator",
		id:"EditorChoice_id_operator",
		AND:"EditorChoice_where_and",
		OR:"EditorChoice_where_or"
	},
	EditorChoice_name_operator:{

	},
	EditorChoice_visible_operator:{

	},
	EditorChoice_editorialPicks_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EditorChoice_list__item_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EditorChoice_list__id_operator:{

	},
	EditorChoice_region_operator:{
		equals:"EditorChoice_region_Input",
		not_equals:"EditorChoice_region_Input",
		in:"EditorChoice_region_Input",
		not_in:"EditorChoice_region_Input",
		all:"EditorChoice_region_Input"
	},
	EditorChoice_region_Input: "enum" as const,
	EditorChoice_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EditorChoice_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EditorChoice_id_operator:{

	},
	EditorChoice_where_and:{
		name:"EditorChoice_name_operator",
		visible:"EditorChoice_visible_operator",
		editorialPicks:"EditorChoice_editorialPicks_operator",
		list__item:"EditorChoice_list__item_operator",
		list__id:"EditorChoice_list__id_operator",
		region:"EditorChoice_region_operator",
		updatedAt:"EditorChoice_updatedAt_operator",
		createdAt:"EditorChoice_createdAt_operator",
		id:"EditorChoice_id_operator",
		AND:"EditorChoice_where_and",
		OR:"EditorChoice_where_or"
	},
	EditorChoice_where_or:{
		name:"EditorChoice_name_operator",
		visible:"EditorChoice_visible_operator",
		editorialPicks:"EditorChoice_editorialPicks_operator",
		list__item:"EditorChoice_list__item_operator",
		list__id:"EditorChoice_list__id_operator",
		region:"EditorChoice_region_operator",
		updatedAt:"EditorChoice_updatedAt_operator",
		createdAt:"EditorChoice_createdAt_operator",
		id:"EditorChoice_id_operator",
		AND:"EditorChoice_where_and",
		OR:"EditorChoice_where_or"
	},
	BannerSection_Banners:{
		desktop:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"BannerSection_Banners_Desktop_where"
		}
	},
	BannerSection_Banners_Desktop_where:{
		alt:"BannerSection_Banners_Desktop_alt_operator",
		updatedAt:"BannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"BannerSection_Banners_Desktop_createdAt_operator",
		url:"BannerSection_Banners_Desktop_url_operator",
		filename:"BannerSection_Banners_Desktop_filename_operator",
		mimeType:"BannerSection_Banners_Desktop_mimeType_operator",
		filesize:"BannerSection_Banners_Desktop_filesize_operator",
		width:"BannerSection_Banners_Desktop_width_operator",
		height:"BannerSection_Banners_Desktop_height_operator",
		focalX:"BannerSection_Banners_Desktop_focalX_operator",
		focalY:"BannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"BannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"BannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"BannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"BannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"BannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"BannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"BannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"BannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"BannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"BannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"BannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"BannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"BannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"BannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"BannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"BannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"BannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"BannerSection_Banners_Desktop_id_operator",
		AND:"BannerSection_Banners_Desktop_where_and",
		OR:"BannerSection_Banners_Desktop_where_or"
	},
	BannerSection_Banners_Desktop_alt_operator:{

	},
	BannerSection_Banners_Desktop_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_Banners_Desktop_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_Banners_Desktop_url_operator:{

	},
	BannerSection_Banners_Desktop_filename_operator:{

	},
	BannerSection_Banners_Desktop_mimeType_operator:{

	},
	BannerSection_Banners_Desktop_filesize_operator:{

	},
	BannerSection_Banners_Desktop_width_operator:{

	},
	BannerSection_Banners_Desktop_height_operator:{

	},
	BannerSection_Banners_Desktop_focalX_operator:{

	},
	BannerSection_Banners_Desktop_focalY_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small__filename_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium__filename_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large__filename_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator:{

	},
	BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator:{

	},
	BannerSection_Banners_Desktop_id_operator:{

	},
	BannerSection_Banners_Desktop_where_and:{
		alt:"BannerSection_Banners_Desktop_alt_operator",
		updatedAt:"BannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"BannerSection_Banners_Desktop_createdAt_operator",
		url:"BannerSection_Banners_Desktop_url_operator",
		filename:"BannerSection_Banners_Desktop_filename_operator",
		mimeType:"BannerSection_Banners_Desktop_mimeType_operator",
		filesize:"BannerSection_Banners_Desktop_filesize_operator",
		width:"BannerSection_Banners_Desktop_width_operator",
		height:"BannerSection_Banners_Desktop_height_operator",
		focalX:"BannerSection_Banners_Desktop_focalX_operator",
		focalY:"BannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"BannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"BannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"BannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"BannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"BannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"BannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"BannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"BannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"BannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"BannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"BannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"BannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"BannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"BannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"BannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"BannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"BannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"BannerSection_Banners_Desktop_id_operator",
		AND:"BannerSection_Banners_Desktop_where_and",
		OR:"BannerSection_Banners_Desktop_where_or"
	},
	BannerSection_Banners_Desktop_where_or:{
		alt:"BannerSection_Banners_Desktop_alt_operator",
		updatedAt:"BannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"BannerSection_Banners_Desktop_createdAt_operator",
		url:"BannerSection_Banners_Desktop_url_operator",
		filename:"BannerSection_Banners_Desktop_filename_operator",
		mimeType:"BannerSection_Banners_Desktop_mimeType_operator",
		filesize:"BannerSection_Banners_Desktop_filesize_operator",
		width:"BannerSection_Banners_Desktop_width_operator",
		height:"BannerSection_Banners_Desktop_height_operator",
		focalX:"BannerSection_Banners_Desktop_focalX_operator",
		focalY:"BannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"BannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"BannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"BannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"BannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"BannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"BannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"BannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"BannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"BannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"BannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"BannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"BannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"BannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"BannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"BannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"BannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"BannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"BannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"BannerSection_Banners_Desktop_id_operator",
		AND:"BannerSection_Banners_Desktop_where_and",
		OR:"BannerSection_Banners_Desktop_where_or"
	},
	BannerSection_Banners_region: "enum" as const,
	BannerSection_where:{
		name:"BannerSection_name_operator",
		slug:"BannerSection_slug_operator",
		banners__name:"BannerSection_banners__name_operator",
		banners__status:"BannerSection_banners__status_operator",
		banners__desktop:"BannerSection_banners__desktop_operator",
		banners__bannerStartDate:"BannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"BannerSection_banners__bannerEndDate_operator",
		banners__region:"BannerSection_banners__region_operator",
		banners__id:"BannerSection_banners__id_operator",
		updatedAt:"BannerSection_updatedAt_operator",
		createdAt:"BannerSection_createdAt_operator",
		id:"BannerSection_id_operator",
		AND:"BannerSection_where_and",
		OR:"BannerSection_where_or"
	},
	BannerSection_name_operator:{

	},
	BannerSection_slug_operator:{

	},
	BannerSection_banners__name_operator:{

	},
	BannerSection_banners__status_operator:{

	},
	BannerSection_banners__desktop_operator:{

	},
	BannerSection_banners__bannerStartDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_banners__bannerEndDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_banners__region_operator:{
		equals:"BannerSection_banners__region_Input",
		not_equals:"BannerSection_banners__region_Input",
		in:"BannerSection_banners__region_Input",
		not_in:"BannerSection_banners__region_Input",
		all:"BannerSection_banners__region_Input"
	},
	BannerSection_banners__region_Input: "enum" as const,
	BannerSection_banners__id_operator:{

	},
	BannerSection_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	BannerSection_id_operator:{

	},
	BannerSection_where_and:{
		name:"BannerSection_name_operator",
		slug:"BannerSection_slug_operator",
		banners__name:"BannerSection_banners__name_operator",
		banners__status:"BannerSection_banners__status_operator",
		banners__desktop:"BannerSection_banners__desktop_operator",
		banners__bannerStartDate:"BannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"BannerSection_banners__bannerEndDate_operator",
		banners__region:"BannerSection_banners__region_operator",
		banners__id:"BannerSection_banners__id_operator",
		updatedAt:"BannerSection_updatedAt_operator",
		createdAt:"BannerSection_createdAt_operator",
		id:"BannerSection_id_operator",
		AND:"BannerSection_where_and",
		OR:"BannerSection_where_or"
	},
	BannerSection_where_or:{
		name:"BannerSection_name_operator",
		slug:"BannerSection_slug_operator",
		banners__name:"BannerSection_banners__name_operator",
		banners__status:"BannerSection_banners__status_operator",
		banners__desktop:"BannerSection_banners__desktop_operator",
		banners__bannerStartDate:"BannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"BannerSection_banners__bannerEndDate_operator",
		banners__region:"BannerSection_banners__region_operator",
		banners__id:"BannerSection_banners__id_operator",
		updatedAt:"BannerSection_updatedAt_operator",
		createdAt:"BannerSection_createdAt_operator",
		id:"BannerSection_id_operator",
		AND:"BannerSection_where_and",
		OR:"BannerSection_where_or"
	},
	MacaoBannerSection_Banners:{
		desktop:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"MacaoBannerSection_Banners_Desktop_where"
		}
	},
	MacaoBannerSection_Banners_Desktop_where:{
		alt:"MacaoBannerSection_Banners_Desktop_alt_operator",
		updatedAt:"MacaoBannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"MacaoBannerSection_Banners_Desktop_createdAt_operator",
		url:"MacaoBannerSection_Banners_Desktop_url_operator",
		filename:"MacaoBannerSection_Banners_Desktop_filename_operator",
		mimeType:"MacaoBannerSection_Banners_Desktop_mimeType_operator",
		filesize:"MacaoBannerSection_Banners_Desktop_filesize_operator",
		width:"MacaoBannerSection_Banners_Desktop_width_operator",
		height:"MacaoBannerSection_Banners_Desktop_height_operator",
		focalX:"MacaoBannerSection_Banners_Desktop_focalX_operator",
		focalY:"MacaoBannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"MacaoBannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"MacaoBannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"MacaoBannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"MacaoBannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"MacaoBannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"MacaoBannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"MacaoBannerSection_Banners_Desktop_id_operator",
		AND:"MacaoBannerSection_Banners_Desktop_where_and",
		OR:"MacaoBannerSection_Banners_Desktop_where_or"
	},
	MacaoBannerSection_Banners_Desktop_alt_operator:{

	},
	MacaoBannerSection_Banners_Desktop_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_Banners_Desktop_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_Banners_Desktop_url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_focalX_operator:{

	},
	MacaoBannerSection_Banners_Desktop_focalY_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator:{

	},
	MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator:{

	},
	MacaoBannerSection_Banners_Desktop_id_operator:{

	},
	MacaoBannerSection_Banners_Desktop_where_and:{
		alt:"MacaoBannerSection_Banners_Desktop_alt_operator",
		updatedAt:"MacaoBannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"MacaoBannerSection_Banners_Desktop_createdAt_operator",
		url:"MacaoBannerSection_Banners_Desktop_url_operator",
		filename:"MacaoBannerSection_Banners_Desktop_filename_operator",
		mimeType:"MacaoBannerSection_Banners_Desktop_mimeType_operator",
		filesize:"MacaoBannerSection_Banners_Desktop_filesize_operator",
		width:"MacaoBannerSection_Banners_Desktop_width_operator",
		height:"MacaoBannerSection_Banners_Desktop_height_operator",
		focalX:"MacaoBannerSection_Banners_Desktop_focalX_operator",
		focalY:"MacaoBannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"MacaoBannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"MacaoBannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"MacaoBannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"MacaoBannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"MacaoBannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"MacaoBannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"MacaoBannerSection_Banners_Desktop_id_operator",
		AND:"MacaoBannerSection_Banners_Desktop_where_and",
		OR:"MacaoBannerSection_Banners_Desktop_where_or"
	},
	MacaoBannerSection_Banners_Desktop_where_or:{
		alt:"MacaoBannerSection_Banners_Desktop_alt_operator",
		updatedAt:"MacaoBannerSection_Banners_Desktop_updatedAt_operator",
		createdAt:"MacaoBannerSection_Banners_Desktop_createdAt_operator",
		url:"MacaoBannerSection_Banners_Desktop_url_operator",
		filename:"MacaoBannerSection_Banners_Desktop_filename_operator",
		mimeType:"MacaoBannerSection_Banners_Desktop_mimeType_operator",
		filesize:"MacaoBannerSection_Banners_Desktop_filesize_operator",
		width:"MacaoBannerSection_Banners_Desktop_width_operator",
		height:"MacaoBannerSection_Banners_Desktop_height_operator",
		focalX:"MacaoBannerSection_Banners_Desktop_focalX_operator",
		focalY:"MacaoBannerSection_Banners_Desktop_focalY_operator",
		sizes__small__url:"MacaoBannerSection_Banners_Desktop_sizes__small__url_operator",
		sizes__small__width:"MacaoBannerSection_Banners_Desktop_sizes__small__width_operator",
		sizes__small__height:"MacaoBannerSection_Banners_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"MacaoBannerSection_Banners_Desktop_sizes__large__url_operator",
		sizes__large__width:"MacaoBannerSection_Banners_Desktop_sizes__large__width_operator",
		sizes__large__height:"MacaoBannerSection_Banners_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator",
		id:"MacaoBannerSection_Banners_Desktop_id_operator",
		AND:"MacaoBannerSection_Banners_Desktop_where_and",
		OR:"MacaoBannerSection_Banners_Desktop_where_or"
	},
	MacaoBannerSection_Banners_region: "enum" as const,
	MacaoBannerSection_where:{
		name:"MacaoBannerSection_name_operator",
		slug:"MacaoBannerSection_slug_operator",
		banners__name:"MacaoBannerSection_banners__name_operator",
		banners__status:"MacaoBannerSection_banners__status_operator",
		banners__desktop:"MacaoBannerSection_banners__desktop_operator",
		banners__bannerStartDate:"MacaoBannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"MacaoBannerSection_banners__bannerEndDate_operator",
		banners__region:"MacaoBannerSection_banners__region_operator",
		banners__id:"MacaoBannerSection_banners__id_operator",
		updatedAt:"MacaoBannerSection_updatedAt_operator",
		createdAt:"MacaoBannerSection_createdAt_operator",
		id:"MacaoBannerSection_id_operator",
		AND:"MacaoBannerSection_where_and",
		OR:"MacaoBannerSection_where_or"
	},
	MacaoBannerSection_name_operator:{

	},
	MacaoBannerSection_slug_operator:{

	},
	MacaoBannerSection_banners__name_operator:{

	},
	MacaoBannerSection_banners__status_operator:{

	},
	MacaoBannerSection_banners__desktop_operator:{

	},
	MacaoBannerSection_banners__bannerStartDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_banners__bannerEndDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_banners__region_operator:{
		equals:"MacaoBannerSection_banners__region_Input",
		not_equals:"MacaoBannerSection_banners__region_Input",
		in:"MacaoBannerSection_banners__region_Input",
		not_in:"MacaoBannerSection_banners__region_Input",
		all:"MacaoBannerSection_banners__region_Input"
	},
	MacaoBannerSection_banners__region_Input: "enum" as const,
	MacaoBannerSection_banners__id_operator:{

	},
	MacaoBannerSection_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	MacaoBannerSection_id_operator:{

	},
	MacaoBannerSection_where_and:{
		name:"MacaoBannerSection_name_operator",
		slug:"MacaoBannerSection_slug_operator",
		banners__name:"MacaoBannerSection_banners__name_operator",
		banners__status:"MacaoBannerSection_banners__status_operator",
		banners__desktop:"MacaoBannerSection_banners__desktop_operator",
		banners__bannerStartDate:"MacaoBannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"MacaoBannerSection_banners__bannerEndDate_operator",
		banners__region:"MacaoBannerSection_banners__region_operator",
		banners__id:"MacaoBannerSection_banners__id_operator",
		updatedAt:"MacaoBannerSection_updatedAt_operator",
		createdAt:"MacaoBannerSection_createdAt_operator",
		id:"MacaoBannerSection_id_operator",
		AND:"MacaoBannerSection_where_and",
		OR:"MacaoBannerSection_where_or"
	},
	MacaoBannerSection_where_or:{
		name:"MacaoBannerSection_name_operator",
		slug:"MacaoBannerSection_slug_operator",
		banners__name:"MacaoBannerSection_banners__name_operator",
		banners__status:"MacaoBannerSection_banners__status_operator",
		banners__desktop:"MacaoBannerSection_banners__desktop_operator",
		banners__bannerStartDate:"MacaoBannerSection_banners__bannerStartDate_operator",
		banners__bannerEndDate:"MacaoBannerSection_banners__bannerEndDate_operator",
		banners__region:"MacaoBannerSection_banners__region_operator",
		banners__id:"MacaoBannerSection_banners__id_operator",
		updatedAt:"MacaoBannerSection_updatedAt_operator",
		createdAt:"MacaoBannerSection_createdAt_operator",
		id:"MacaoBannerSection_id_operator",
		AND:"MacaoBannerSection_where_and",
		OR:"MacaoBannerSection_where_or"
	},
	Banner:{
		desktop:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Banner_Desktop_where"
		},
		mobile:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType",
			where:"Banner_Mobile_where"
		}
	},
	Banner_Desktop_where:{
		alt:"Banner_Desktop_alt_operator",
		updatedAt:"Banner_Desktop_updatedAt_operator",
		createdAt:"Banner_Desktop_createdAt_operator",
		url:"Banner_Desktop_url_operator",
		filename:"Banner_Desktop_filename_operator",
		mimeType:"Banner_Desktop_mimeType_operator",
		filesize:"Banner_Desktop_filesize_operator",
		width:"Banner_Desktop_width_operator",
		height:"Banner_Desktop_height_operator",
		focalX:"Banner_Desktop_focalX_operator",
		focalY:"Banner_Desktop_focalY_operator",
		sizes__small__url:"Banner_Desktop_sizes__small__url_operator",
		sizes__small__width:"Banner_Desktop_sizes__small__width_operator",
		sizes__small__height:"Banner_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Desktop_sizes__large__url_operator",
		sizes__large__width:"Banner_Desktop_sizes__large__width_operator",
		sizes__large__height:"Banner_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Desktop_sizes__large_jpeg__filename_operator",
		id:"Banner_Desktop_id_operator",
		AND:"Banner_Desktop_where_and",
		OR:"Banner_Desktop_where_or"
	},
	Banner_Desktop_alt_operator:{

	},
	Banner_Desktop_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_Desktop_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_Desktop_url_operator:{

	},
	Banner_Desktop_filename_operator:{

	},
	Banner_Desktop_mimeType_operator:{

	},
	Banner_Desktop_filesize_operator:{

	},
	Banner_Desktop_width_operator:{

	},
	Banner_Desktop_height_operator:{

	},
	Banner_Desktop_focalX_operator:{

	},
	Banner_Desktop_focalY_operator:{

	},
	Banner_Desktop_sizes__small__url_operator:{

	},
	Banner_Desktop_sizes__small__width_operator:{

	},
	Banner_Desktop_sizes__small__height_operator:{

	},
	Banner_Desktop_sizes__small__mimeType_operator:{

	},
	Banner_Desktop_sizes__small__filesize_operator:{

	},
	Banner_Desktop_sizes__small__filename_operator:{

	},
	Banner_Desktop_sizes__medium__url_operator:{

	},
	Banner_Desktop_sizes__medium__width_operator:{

	},
	Banner_Desktop_sizes__medium__height_operator:{

	},
	Banner_Desktop_sizes__medium__mimeType_operator:{

	},
	Banner_Desktop_sizes__medium__filesize_operator:{

	},
	Banner_Desktop_sizes__medium__filename_operator:{

	},
	Banner_Desktop_sizes__large__url_operator:{

	},
	Banner_Desktop_sizes__large__width_operator:{

	},
	Banner_Desktop_sizes__large__height_operator:{

	},
	Banner_Desktop_sizes__large__mimeType_operator:{

	},
	Banner_Desktop_sizes__large__filesize_operator:{

	},
	Banner_Desktop_sizes__large__filename_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__url_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__width_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__height_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__mimeType_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__filesize_operator:{

	},
	Banner_Desktop_sizes__small_jpeg__filename_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__url_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__width_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__height_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__mimeType_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__filesize_operator:{

	},
	Banner_Desktop_sizes__medium_jpeg__filename_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__url_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__width_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__height_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__mimeType_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__filesize_operator:{

	},
	Banner_Desktop_sizes__large_jpeg__filename_operator:{

	},
	Banner_Desktop_id_operator:{

	},
	Banner_Desktop_where_and:{
		alt:"Banner_Desktop_alt_operator",
		updatedAt:"Banner_Desktop_updatedAt_operator",
		createdAt:"Banner_Desktop_createdAt_operator",
		url:"Banner_Desktop_url_operator",
		filename:"Banner_Desktop_filename_operator",
		mimeType:"Banner_Desktop_mimeType_operator",
		filesize:"Banner_Desktop_filesize_operator",
		width:"Banner_Desktop_width_operator",
		height:"Banner_Desktop_height_operator",
		focalX:"Banner_Desktop_focalX_operator",
		focalY:"Banner_Desktop_focalY_operator",
		sizes__small__url:"Banner_Desktop_sizes__small__url_operator",
		sizes__small__width:"Banner_Desktop_sizes__small__width_operator",
		sizes__small__height:"Banner_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Desktop_sizes__large__url_operator",
		sizes__large__width:"Banner_Desktop_sizes__large__width_operator",
		sizes__large__height:"Banner_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Desktop_sizes__large_jpeg__filename_operator",
		id:"Banner_Desktop_id_operator",
		AND:"Banner_Desktop_where_and",
		OR:"Banner_Desktop_where_or"
	},
	Banner_Desktop_where_or:{
		alt:"Banner_Desktop_alt_operator",
		updatedAt:"Banner_Desktop_updatedAt_operator",
		createdAt:"Banner_Desktop_createdAt_operator",
		url:"Banner_Desktop_url_operator",
		filename:"Banner_Desktop_filename_operator",
		mimeType:"Banner_Desktop_mimeType_operator",
		filesize:"Banner_Desktop_filesize_operator",
		width:"Banner_Desktop_width_operator",
		height:"Banner_Desktop_height_operator",
		focalX:"Banner_Desktop_focalX_operator",
		focalY:"Banner_Desktop_focalY_operator",
		sizes__small__url:"Banner_Desktop_sizes__small__url_operator",
		sizes__small__width:"Banner_Desktop_sizes__small__width_operator",
		sizes__small__height:"Banner_Desktop_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Desktop_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Desktop_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Desktop_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Desktop_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Desktop_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Desktop_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Desktop_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Desktop_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Desktop_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Desktop_sizes__large__url_operator",
		sizes__large__width:"Banner_Desktop_sizes__large__width_operator",
		sizes__large__height:"Banner_Desktop_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Desktop_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Desktop_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Desktop_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Desktop_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Desktop_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Desktop_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Desktop_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Desktop_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Desktop_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Desktop_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Desktop_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Desktop_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Desktop_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Desktop_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Desktop_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Desktop_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Desktop_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Desktop_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Desktop_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Desktop_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Desktop_sizes__large_jpeg__filename_operator",
		id:"Banner_Desktop_id_operator",
		AND:"Banner_Desktop_where_and",
		OR:"Banner_Desktop_where_or"
	},
	Banner_Mobile_where:{
		alt:"Banner_Mobile_alt_operator",
		updatedAt:"Banner_Mobile_updatedAt_operator",
		createdAt:"Banner_Mobile_createdAt_operator",
		url:"Banner_Mobile_url_operator",
		filename:"Banner_Mobile_filename_operator",
		mimeType:"Banner_Mobile_mimeType_operator",
		filesize:"Banner_Mobile_filesize_operator",
		width:"Banner_Mobile_width_operator",
		height:"Banner_Mobile_height_operator",
		focalX:"Banner_Mobile_focalX_operator",
		focalY:"Banner_Mobile_focalY_operator",
		sizes__small__url:"Banner_Mobile_sizes__small__url_operator",
		sizes__small__width:"Banner_Mobile_sizes__small__width_operator",
		sizes__small__height:"Banner_Mobile_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Mobile_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Mobile_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Mobile_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Mobile_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Mobile_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Mobile_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Mobile_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Mobile_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Mobile_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Mobile_sizes__large__url_operator",
		sizes__large__width:"Banner_Mobile_sizes__large__width_operator",
		sizes__large__height:"Banner_Mobile_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Mobile_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Mobile_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Mobile_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Mobile_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Mobile_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Mobile_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Mobile_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Mobile_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Mobile_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Mobile_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Mobile_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Mobile_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Mobile_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Mobile_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Mobile_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Mobile_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Mobile_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Mobile_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Mobile_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Mobile_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Mobile_sizes__large_jpeg__filename_operator",
		id:"Banner_Mobile_id_operator",
		AND:"Banner_Mobile_where_and",
		OR:"Banner_Mobile_where_or"
	},
	Banner_Mobile_alt_operator:{

	},
	Banner_Mobile_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_Mobile_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_Mobile_url_operator:{

	},
	Banner_Mobile_filename_operator:{

	},
	Banner_Mobile_mimeType_operator:{

	},
	Banner_Mobile_filesize_operator:{

	},
	Banner_Mobile_width_operator:{

	},
	Banner_Mobile_height_operator:{

	},
	Banner_Mobile_focalX_operator:{

	},
	Banner_Mobile_focalY_operator:{

	},
	Banner_Mobile_sizes__small__url_operator:{

	},
	Banner_Mobile_sizes__small__width_operator:{

	},
	Banner_Mobile_sizes__small__height_operator:{

	},
	Banner_Mobile_sizes__small__mimeType_operator:{

	},
	Banner_Mobile_sizes__small__filesize_operator:{

	},
	Banner_Mobile_sizes__small__filename_operator:{

	},
	Banner_Mobile_sizes__medium__url_operator:{

	},
	Banner_Mobile_sizes__medium__width_operator:{

	},
	Banner_Mobile_sizes__medium__height_operator:{

	},
	Banner_Mobile_sizes__medium__mimeType_operator:{

	},
	Banner_Mobile_sizes__medium__filesize_operator:{

	},
	Banner_Mobile_sizes__medium__filename_operator:{

	},
	Banner_Mobile_sizes__large__url_operator:{

	},
	Banner_Mobile_sizes__large__width_operator:{

	},
	Banner_Mobile_sizes__large__height_operator:{

	},
	Banner_Mobile_sizes__large__mimeType_operator:{

	},
	Banner_Mobile_sizes__large__filesize_operator:{

	},
	Banner_Mobile_sizes__large__filename_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__url_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__width_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__height_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__mimeType_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__filesize_operator:{

	},
	Banner_Mobile_sizes__small_jpeg__filename_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__url_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__width_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__height_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__mimeType_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__filesize_operator:{

	},
	Banner_Mobile_sizes__medium_jpeg__filename_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__url_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__width_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__height_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__mimeType_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__filesize_operator:{

	},
	Banner_Mobile_sizes__large_jpeg__filename_operator:{

	},
	Banner_Mobile_id_operator:{

	},
	Banner_Mobile_where_and:{
		alt:"Banner_Mobile_alt_operator",
		updatedAt:"Banner_Mobile_updatedAt_operator",
		createdAt:"Banner_Mobile_createdAt_operator",
		url:"Banner_Mobile_url_operator",
		filename:"Banner_Mobile_filename_operator",
		mimeType:"Banner_Mobile_mimeType_operator",
		filesize:"Banner_Mobile_filesize_operator",
		width:"Banner_Mobile_width_operator",
		height:"Banner_Mobile_height_operator",
		focalX:"Banner_Mobile_focalX_operator",
		focalY:"Banner_Mobile_focalY_operator",
		sizes__small__url:"Banner_Mobile_sizes__small__url_operator",
		sizes__small__width:"Banner_Mobile_sizes__small__width_operator",
		sizes__small__height:"Banner_Mobile_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Mobile_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Mobile_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Mobile_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Mobile_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Mobile_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Mobile_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Mobile_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Mobile_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Mobile_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Mobile_sizes__large__url_operator",
		sizes__large__width:"Banner_Mobile_sizes__large__width_operator",
		sizes__large__height:"Banner_Mobile_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Mobile_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Mobile_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Mobile_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Mobile_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Mobile_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Mobile_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Mobile_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Mobile_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Mobile_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Mobile_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Mobile_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Mobile_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Mobile_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Mobile_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Mobile_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Mobile_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Mobile_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Mobile_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Mobile_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Mobile_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Mobile_sizes__large_jpeg__filename_operator",
		id:"Banner_Mobile_id_operator",
		AND:"Banner_Mobile_where_and",
		OR:"Banner_Mobile_where_or"
	},
	Banner_Mobile_where_or:{
		alt:"Banner_Mobile_alt_operator",
		updatedAt:"Banner_Mobile_updatedAt_operator",
		createdAt:"Banner_Mobile_createdAt_operator",
		url:"Banner_Mobile_url_operator",
		filename:"Banner_Mobile_filename_operator",
		mimeType:"Banner_Mobile_mimeType_operator",
		filesize:"Banner_Mobile_filesize_operator",
		width:"Banner_Mobile_width_operator",
		height:"Banner_Mobile_height_operator",
		focalX:"Banner_Mobile_focalX_operator",
		focalY:"Banner_Mobile_focalY_operator",
		sizes__small__url:"Banner_Mobile_sizes__small__url_operator",
		sizes__small__width:"Banner_Mobile_sizes__small__width_operator",
		sizes__small__height:"Banner_Mobile_sizes__small__height_operator",
		sizes__small__mimeType:"Banner_Mobile_sizes__small__mimeType_operator",
		sizes__small__filesize:"Banner_Mobile_sizes__small__filesize_operator",
		sizes__small__filename:"Banner_Mobile_sizes__small__filename_operator",
		sizes__medium__url:"Banner_Mobile_sizes__medium__url_operator",
		sizes__medium__width:"Banner_Mobile_sizes__medium__width_operator",
		sizes__medium__height:"Banner_Mobile_sizes__medium__height_operator",
		sizes__medium__mimeType:"Banner_Mobile_sizes__medium__mimeType_operator",
		sizes__medium__filesize:"Banner_Mobile_sizes__medium__filesize_operator",
		sizes__medium__filename:"Banner_Mobile_sizes__medium__filename_operator",
		sizes__large__url:"Banner_Mobile_sizes__large__url_operator",
		sizes__large__width:"Banner_Mobile_sizes__large__width_operator",
		sizes__large__height:"Banner_Mobile_sizes__large__height_operator",
		sizes__large__mimeType:"Banner_Mobile_sizes__large__mimeType_operator",
		sizes__large__filesize:"Banner_Mobile_sizes__large__filesize_operator",
		sizes__large__filename:"Banner_Mobile_sizes__large__filename_operator",
		sizes__small_jpeg__url:"Banner_Mobile_sizes__small_jpeg__url_operator",
		sizes__small_jpeg__width:"Banner_Mobile_sizes__small_jpeg__width_operator",
		sizes__small_jpeg__height:"Banner_Mobile_sizes__small_jpeg__height_operator",
		sizes__small_jpeg__mimeType:"Banner_Mobile_sizes__small_jpeg__mimeType_operator",
		sizes__small_jpeg__filesize:"Banner_Mobile_sizes__small_jpeg__filesize_operator",
		sizes__small_jpeg__filename:"Banner_Mobile_sizes__small_jpeg__filename_operator",
		sizes__medium_jpeg__url:"Banner_Mobile_sizes__medium_jpeg__url_operator",
		sizes__medium_jpeg__width:"Banner_Mobile_sizes__medium_jpeg__width_operator",
		sizes__medium_jpeg__height:"Banner_Mobile_sizes__medium_jpeg__height_operator",
		sizes__medium_jpeg__mimeType:"Banner_Mobile_sizes__medium_jpeg__mimeType_operator",
		sizes__medium_jpeg__filesize:"Banner_Mobile_sizes__medium_jpeg__filesize_operator",
		sizes__medium_jpeg__filename:"Banner_Mobile_sizes__medium_jpeg__filename_operator",
		sizes__large_jpeg__url:"Banner_Mobile_sizes__large_jpeg__url_operator",
		sizes__large_jpeg__width:"Banner_Mobile_sizes__large_jpeg__width_operator",
		sizes__large_jpeg__height:"Banner_Mobile_sizes__large_jpeg__height_operator",
		sizes__large_jpeg__mimeType:"Banner_Mobile_sizes__large_jpeg__mimeType_operator",
		sizes__large_jpeg__filesize:"Banner_Mobile_sizes__large_jpeg__filesize_operator",
		sizes__large_jpeg__filename:"Banner_Mobile_sizes__large_jpeg__filename_operator",
		id:"Banner_Mobile_id_operator",
		AND:"Banner_Mobile_where_and",
		OR:"Banner_Mobile_where_or"
	},
	Banner_Location:{
		eventRankingList:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		locationRankingList:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		categories:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Banner_Location_page: "enum" as const,
	Banner_type: "enum" as const,
	Banner_status: "enum" as const,
	Banner_Advertise:{
		campaign:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Campaign:{
		creator:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Campaign_Creator_RelationTo: "enum" as const,
	Banner_region: "enum" as const,
	Banner_where:{
		name:"Banner_name_operator",
		desktop:"Banner_desktop_operator",
		mobile:"Banner_mobile_operator",
		bannerStartDate:"Banner_bannerStartDate_operator",
		bannerEndDate:"Banner_bannerEndDate_operator",
		location__page:"Banner_location__page_operator",
		location__eventRankingList:"Banner_location__eventRankingList_operator",
		location__locationRankingList:"Banner_location__locationRankingList_operator",
		location__categories:"Banner_location__categories_operator",
		location__time:"Banner_location__time_operator",
		location__district:"Banner_location__district_operator",
		location__audience:"Banner_location__audience_operator",
		location__multipleCriteriaSearch:"Banner_location__multipleCriteriaSearch_operator",
		link:"Banner_link_operator",
		external:"Banner_external_operator",
		type:"Banner_type_operator",
		status:"Banner_status_operator",
		advertise__campaign:"Banner_advertise__campaign_operator",
		advertise__invoiceDescription:"Banner_advertise__invoiceDescription_operator",
		advertise__standardPrice:"Banner_advertise__standardPrice_operator",
		advertise__discount:"Banner_advertise__discount_operator",
		advertise__timableCost:"Banner_advertise__timableCost_operator",
		advertise__remark:"Banner_advertise__remark_operator",
		advertise__public:"Banner_advertise__public_operator",
		_locations:"Banner__locations_operator",
		region:"Banner_region_operator",
		homePageClick:"Banner_homePageClick_operator",
		homePageImpression:"Banner_homePageImpression_operator",
		latestEventClick:"Banner_latestEventClick_operator",
		latestEventImpression:"Banner_latestEventImpression_operator",
		editorChoiceClick:"Banner_editorChoiceClick_operator",
		editorChoiceImpression:"Banner_editorChoiceImpression_operator",
		eventListClick:"Banner_eventListClick_operator",
		eventListImpression:"Banner_eventListImpression_operator",
		topTenClick:"Banner_topTenClick_operator",
		topTenImpression:"Banner_topTenImpression_operator",
		miscClick:"Banner_miscClick_operator",
		miscImpression:"Banner_miscImpression_operator",
		clickTotal:"Banner_clickTotal_operator",
		totalImpression:"Banner_totalImpression_operator",
		updatedAt:"Banner_updatedAt_operator",
		createdAt:"Banner_createdAt_operator",
		id:"Banner_id_operator",
		AND:"Banner_where_and",
		OR:"Banner_where_or"
	},
	Banner_name_operator:{

	},
	Banner_desktop_operator:{

	},
	Banner_mobile_operator:{

	},
	Banner_bannerStartDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_bannerEndDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_location__page_operator:{
		equals:"Banner_location__page_Input",
		not_equals:"Banner_location__page_Input",
		in:"Banner_location__page_Input",
		not_in:"Banner_location__page_Input",
		all:"Banner_location__page_Input"
	},
	Banner_location__page_Input: "enum" as const,
	Banner_location__eventRankingList_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Banner_location__locationRankingList_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Banner_location__categories_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Banner_location__time_operator:{

	},
	Banner_location__district_operator:{

	},
	Banner_location__audience_operator:{

	},
	Banner_location__multipleCriteriaSearch_operator:{

	},
	Banner_link_operator:{

	},
	Banner_external_operator:{

	},
	Banner_type_operator:{
		equals:"Banner_type_Input",
		not_equals:"Banner_type_Input",
		in:"Banner_type_Input",
		not_in:"Banner_type_Input",
		all:"Banner_type_Input"
	},
	Banner_type_Input: "enum" as const,
	Banner_status_operator:{
		equals:"Banner_status_Input",
		not_equals:"Banner_status_Input",
		in:"Banner_status_Input",
		not_in:"Banner_status_Input",
		all:"Banner_status_Input"
	},
	Banner_status_Input: "enum" as const,
	Banner_advertise__campaign_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Banner_advertise__invoiceDescription_operator:{

	},
	Banner_advertise__standardPrice_operator:{

	},
	Banner_advertise__discount_operator:{

	},
	Banner_advertise__timableCost_operator:{

	},
	Banner_advertise__remark_operator:{

	},
	Banner_advertise__public_operator:{

	},
	Banner__locations_operator:{

	},
	Banner_region_operator:{
		equals:"Banner_region_Input",
		not_equals:"Banner_region_Input",
		in:"Banner_region_Input",
		not_in:"Banner_region_Input",
		all:"Banner_region_Input"
	},
	Banner_region_Input: "enum" as const,
	Banner_homePageClick_operator:{

	},
	Banner_homePageImpression_operator:{

	},
	Banner_latestEventClick_operator:{

	},
	Banner_latestEventImpression_operator:{

	},
	Banner_editorChoiceClick_operator:{

	},
	Banner_editorChoiceImpression_operator:{

	},
	Banner_eventListClick_operator:{

	},
	Banner_eventListImpression_operator:{

	},
	Banner_topTenClick_operator:{

	},
	Banner_topTenImpression_operator:{

	},
	Banner_miscClick_operator:{

	},
	Banner_miscImpression_operator:{

	},
	Banner_clickTotal_operator:{

	},
	Banner_totalImpression_operator:{

	},
	Banner_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Banner_id_operator:{

	},
	Banner_where_and:{
		name:"Banner_name_operator",
		desktop:"Banner_desktop_operator",
		mobile:"Banner_mobile_operator",
		bannerStartDate:"Banner_bannerStartDate_operator",
		bannerEndDate:"Banner_bannerEndDate_operator",
		location__page:"Banner_location__page_operator",
		location__eventRankingList:"Banner_location__eventRankingList_operator",
		location__locationRankingList:"Banner_location__locationRankingList_operator",
		location__categories:"Banner_location__categories_operator",
		location__time:"Banner_location__time_operator",
		location__district:"Banner_location__district_operator",
		location__audience:"Banner_location__audience_operator",
		location__multipleCriteriaSearch:"Banner_location__multipleCriteriaSearch_operator",
		link:"Banner_link_operator",
		external:"Banner_external_operator",
		type:"Banner_type_operator",
		status:"Banner_status_operator",
		advertise__campaign:"Banner_advertise__campaign_operator",
		advertise__invoiceDescription:"Banner_advertise__invoiceDescription_operator",
		advertise__standardPrice:"Banner_advertise__standardPrice_operator",
		advertise__discount:"Banner_advertise__discount_operator",
		advertise__timableCost:"Banner_advertise__timableCost_operator",
		advertise__remark:"Banner_advertise__remark_operator",
		advertise__public:"Banner_advertise__public_operator",
		_locations:"Banner__locations_operator",
		region:"Banner_region_operator",
		homePageClick:"Banner_homePageClick_operator",
		homePageImpression:"Banner_homePageImpression_operator",
		latestEventClick:"Banner_latestEventClick_operator",
		latestEventImpression:"Banner_latestEventImpression_operator",
		editorChoiceClick:"Banner_editorChoiceClick_operator",
		editorChoiceImpression:"Banner_editorChoiceImpression_operator",
		eventListClick:"Banner_eventListClick_operator",
		eventListImpression:"Banner_eventListImpression_operator",
		topTenClick:"Banner_topTenClick_operator",
		topTenImpression:"Banner_topTenImpression_operator",
		miscClick:"Banner_miscClick_operator",
		miscImpression:"Banner_miscImpression_operator",
		clickTotal:"Banner_clickTotal_operator",
		totalImpression:"Banner_totalImpression_operator",
		updatedAt:"Banner_updatedAt_operator",
		createdAt:"Banner_createdAt_operator",
		id:"Banner_id_operator",
		AND:"Banner_where_and",
		OR:"Banner_where_or"
	},
	Banner_where_or:{
		name:"Banner_name_operator",
		desktop:"Banner_desktop_operator",
		mobile:"Banner_mobile_operator",
		bannerStartDate:"Banner_bannerStartDate_operator",
		bannerEndDate:"Banner_bannerEndDate_operator",
		location__page:"Banner_location__page_operator",
		location__eventRankingList:"Banner_location__eventRankingList_operator",
		location__locationRankingList:"Banner_location__locationRankingList_operator",
		location__categories:"Banner_location__categories_operator",
		location__time:"Banner_location__time_operator",
		location__district:"Banner_location__district_operator",
		location__audience:"Banner_location__audience_operator",
		location__multipleCriteriaSearch:"Banner_location__multipleCriteriaSearch_operator",
		link:"Banner_link_operator",
		external:"Banner_external_operator",
		type:"Banner_type_operator",
		status:"Banner_status_operator",
		advertise__campaign:"Banner_advertise__campaign_operator",
		advertise__invoiceDescription:"Banner_advertise__invoiceDescription_operator",
		advertise__standardPrice:"Banner_advertise__standardPrice_operator",
		advertise__discount:"Banner_advertise__discount_operator",
		advertise__timableCost:"Banner_advertise__timableCost_operator",
		advertise__remark:"Banner_advertise__remark_operator",
		advertise__public:"Banner_advertise__public_operator",
		_locations:"Banner__locations_operator",
		region:"Banner_region_operator",
		homePageClick:"Banner_homePageClick_operator",
		homePageImpression:"Banner_homePageImpression_operator",
		latestEventClick:"Banner_latestEventClick_operator",
		latestEventImpression:"Banner_latestEventImpression_operator",
		editorChoiceClick:"Banner_editorChoiceClick_operator",
		editorChoiceImpression:"Banner_editorChoiceImpression_operator",
		eventListClick:"Banner_eventListClick_operator",
		eventListImpression:"Banner_eventListImpression_operator",
		topTenClick:"Banner_topTenClick_operator",
		topTenImpression:"Banner_topTenImpression_operator",
		miscClick:"Banner_miscClick_operator",
		miscImpression:"Banner_miscImpression_operator",
		clickTotal:"Banner_clickTotal_operator",
		totalImpression:"Banner_totalImpression_operator",
		updatedAt:"Banner_updatedAt_operator",
		createdAt:"Banner_createdAt_operator",
		id:"Banner_id_operator",
		AND:"Banner_where_and",
		OR:"Banner_where_or"
	},
	Campaign_where:{
		creator:"Campaign_creator_Relation",
		invoiceNumber:"Campaign_invoiceNumber_operator",
		quotationNumber:"Campaign_quotationNumber_operator",
		campaignName:"Campaign_campaignName_operator",
		client:"Campaign_client_operator",
		contactPerson:"Campaign_contactPerson_operator",
		email:"Campaign_email_operator",
		phone:"Campaign_phone_operator",
		agentName:"Campaign_agentName_operator",
		agentCommission:"Campaign_agentCommission_operator",
		agentOrderNumber:"Campaign_agentOrderNumber_operator",
		_firstPublishedTime:"Campaign__firstPublishedTime_operator",
		updatedAt:"Campaign_updatedAt_operator",
		createdAt:"Campaign_createdAt_operator",
		id:"Campaign_id_operator",
		AND:"Campaign_where_and",
		OR:"Campaign_where_or"
	},
	Campaign_creator_Relation:{
		relationTo:"Campaign_creator_Relation_RelationTo",
		value:"JSON"
	},
	Campaign_creator_Relation_RelationTo: "enum" as const,
	Campaign_invoiceNumber_operator:{

	},
	Campaign_quotationNumber_operator:{

	},
	Campaign_campaignName_operator:{

	},
	Campaign_client_operator:{

	},
	Campaign_contactPerson_operator:{

	},
	Campaign_email_operator:{

	},
	Campaign_phone_operator:{

	},
	Campaign_agentName_operator:{

	},
	Campaign_agentCommission_operator:{

	},
	Campaign_agentOrderNumber_operator:{

	},
	Campaign__firstPublishedTime_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Campaign_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Campaign_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Campaign_id_operator:{

	},
	Campaign_where_and:{
		creator:"Campaign_creator_Relation",
		invoiceNumber:"Campaign_invoiceNumber_operator",
		quotationNumber:"Campaign_quotationNumber_operator",
		campaignName:"Campaign_campaignName_operator",
		client:"Campaign_client_operator",
		contactPerson:"Campaign_contactPerson_operator",
		email:"Campaign_email_operator",
		phone:"Campaign_phone_operator",
		agentName:"Campaign_agentName_operator",
		agentCommission:"Campaign_agentCommission_operator",
		agentOrderNumber:"Campaign_agentOrderNumber_operator",
		_firstPublishedTime:"Campaign__firstPublishedTime_operator",
		updatedAt:"Campaign_updatedAt_operator",
		createdAt:"Campaign_createdAt_operator",
		id:"Campaign_id_operator",
		AND:"Campaign_where_and",
		OR:"Campaign_where_or"
	},
	Campaign_where_or:{
		creator:"Campaign_creator_Relation",
		invoiceNumber:"Campaign_invoiceNumber_operator",
		quotationNumber:"Campaign_quotationNumber_operator",
		campaignName:"Campaign_campaignName_operator",
		client:"Campaign_client_operator",
		contactPerson:"Campaign_contactPerson_operator",
		email:"Campaign_email_operator",
		phone:"Campaign_phone_operator",
		agentName:"Campaign_agentName_operator",
		agentCommission:"Campaign_agentCommission_operator",
		agentOrderNumber:"Campaign_agentOrderNumber_operator",
		_firstPublishedTime:"Campaign__firstPublishedTime_operator",
		updatedAt:"Campaign_updatedAt_operator",
		createdAt:"Campaign_createdAt_operator",
		id:"Campaign_id_operator",
		AND:"Campaign_where_and",
		OR:"Campaign_where_or"
	},
	Advertise_Advertise:{
		event:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		location:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Advertise_Advertise_type: "enum" as const,
	Advertise_Advertise_displayPosition: "enum" as const,
	Advertise_type: "enum" as const,
	Advertise_where:{
		advertise__type:"Advertise_advertise__type_operator",
		advertise__event:"Advertise_advertise__event_operator",
		advertise__keyword:"Advertise_advertise__keyword_operator",
		advertise__location:"Advertise_advertise__location_operator",
		advertise__displayPosition:"Advertise_advertise__displayPosition_operator",
		advertise__rankingListRemark:"Advertise_advertise__rankingListRemark_operator",
		advertiseStartDate:"Advertise_advertiseStartDate_operator",
		advertiseEndDate:"Advertise_advertiseEndDate_operator",
		type:"Advertise_type_operator",
		invoiceDescription:"Advertise_invoiceDescription_operator",
		standardPrice:"Advertise_standardPrice_operator",
		discount:"Advertise_discount_operator",
		discountPercentage:"Advertise_discountPercentage_operator",
		_calculateByDiscount:"Advertise__calculateByDiscount_operator",
		timableCost:"Advertise_timableCost_operator",
		remark:"Advertise_remark_operator",
		public:"Advertise_public_operator",
		campaignName:"Advertise_campaignName_operator",
		campaignId:"Advertise_campaignId_operator",
		status:"Advertise_status_operator",
		updatedAt:"Advertise_updatedAt_operator",
		createdAt:"Advertise_createdAt_operator",
		id:"Advertise_id_operator",
		AND:"Advertise_where_and",
		OR:"Advertise_where_or"
	},
	Advertise_advertise__type_operator:{
		equals:"Advertise_advertise__type_Input",
		not_equals:"Advertise_advertise__type_Input",
		in:"Advertise_advertise__type_Input",
		not_in:"Advertise_advertise__type_Input",
		all:"Advertise_advertise__type_Input"
	},
	Advertise_advertise__type_Input: "enum" as const,
	Advertise_advertise__event_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Advertise_advertise__keyword_operator:{

	},
	Advertise_advertise__location_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Advertise_advertise__displayPosition_operator:{
		equals:"Advertise_advertise__displayPosition_Input",
		not_equals:"Advertise_advertise__displayPosition_Input",
		like:"Advertise_advertise__displayPosition_Input",
		contains:"Advertise_advertise__displayPosition_Input"
	},
	Advertise_advertise__displayPosition_Input: "enum" as const,
	Advertise_advertise__rankingListRemark_operator:{

	},
	Advertise_advertiseStartDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Advertise_advertiseEndDate_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Advertise_type_operator:{
		equals:"Advertise_type_Input",
		not_equals:"Advertise_type_Input",
		in:"Advertise_type_Input",
		not_in:"Advertise_type_Input",
		all:"Advertise_type_Input"
	},
	Advertise_type_Input: "enum" as const,
	Advertise_invoiceDescription_operator:{

	},
	Advertise_standardPrice_operator:{

	},
	Advertise_discount_operator:{

	},
	Advertise_discountPercentage_operator:{

	},
	Advertise__calculateByDiscount_operator:{

	},
	Advertise_timableCost_operator:{

	},
	Advertise_remark_operator:{

	},
	Advertise_public_operator:{

	},
	Advertise_campaignName_operator:{

	},
	Advertise_campaignId_operator:{

	},
	Advertise_status_operator:{

	},
	Advertise_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Advertise_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Advertise_id_operator:{

	},
	Advertise_where_and:{
		advertise__type:"Advertise_advertise__type_operator",
		advertise__event:"Advertise_advertise__event_operator",
		advertise__keyword:"Advertise_advertise__keyword_operator",
		advertise__location:"Advertise_advertise__location_operator",
		advertise__displayPosition:"Advertise_advertise__displayPosition_operator",
		advertise__rankingListRemark:"Advertise_advertise__rankingListRemark_operator",
		advertiseStartDate:"Advertise_advertiseStartDate_operator",
		advertiseEndDate:"Advertise_advertiseEndDate_operator",
		type:"Advertise_type_operator",
		invoiceDescription:"Advertise_invoiceDescription_operator",
		standardPrice:"Advertise_standardPrice_operator",
		discount:"Advertise_discount_operator",
		discountPercentage:"Advertise_discountPercentage_operator",
		_calculateByDiscount:"Advertise__calculateByDiscount_operator",
		timableCost:"Advertise_timableCost_operator",
		remark:"Advertise_remark_operator",
		public:"Advertise_public_operator",
		campaignName:"Advertise_campaignName_operator",
		campaignId:"Advertise_campaignId_operator",
		status:"Advertise_status_operator",
		updatedAt:"Advertise_updatedAt_operator",
		createdAt:"Advertise_createdAt_operator",
		id:"Advertise_id_operator",
		AND:"Advertise_where_and",
		OR:"Advertise_where_or"
	},
	Advertise_where_or:{
		advertise__type:"Advertise_advertise__type_operator",
		advertise__event:"Advertise_advertise__event_operator",
		advertise__keyword:"Advertise_advertise__keyword_operator",
		advertise__location:"Advertise_advertise__location_operator",
		advertise__displayPosition:"Advertise_advertise__displayPosition_operator",
		advertise__rankingListRemark:"Advertise_advertise__rankingListRemark_operator",
		advertiseStartDate:"Advertise_advertiseStartDate_operator",
		advertiseEndDate:"Advertise_advertiseEndDate_operator",
		type:"Advertise_type_operator",
		invoiceDescription:"Advertise_invoiceDescription_operator",
		standardPrice:"Advertise_standardPrice_operator",
		discount:"Advertise_discount_operator",
		discountPercentage:"Advertise_discountPercentage_operator",
		_calculateByDiscount:"Advertise__calculateByDiscount_operator",
		timableCost:"Advertise_timableCost_operator",
		remark:"Advertise_remark_operator",
		public:"Advertise_public_operator",
		campaignName:"Advertise_campaignName_operator",
		campaignId:"Advertise_campaignId_operator",
		status:"Advertise_status_operator",
		updatedAt:"Advertise_updatedAt_operator",
		createdAt:"Advertise_createdAt_operator",
		id:"Advertise_id_operator",
		AND:"Advertise_where_and",
		OR:"Advertise_where_or"
	},
	Device:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Device_User_RelationTo: "enum" as const,
	Device_where:{
		user:"Device_user_Relation",
		udid:"Device_udid_operator",
		pushToken:"Device_pushToken_operator",
		updatedAt:"Device_updatedAt_operator",
		createdAt:"Device_createdAt_operator",
		id:"Device_id_operator",
		AND:"Device_where_and",
		OR:"Device_where_or"
	},
	Device_user_Relation:{
		relationTo:"Device_user_Relation_RelationTo",
		value:"JSON"
	},
	Device_user_Relation_RelationTo: "enum" as const,
	Device_udid_operator:{

	},
	Device_pushToken_operator:{

	},
	Device_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Device_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Device_id_operator:{

	},
	Device_where_and:{
		user:"Device_user_Relation",
		udid:"Device_udid_operator",
		pushToken:"Device_pushToken_operator",
		updatedAt:"Device_updatedAt_operator",
		createdAt:"Device_createdAt_operator",
		id:"Device_id_operator",
		AND:"Device_where_and",
		OR:"Device_where_or"
	},
	Device_where_or:{
		user:"Device_user_Relation",
		udid:"Device_udid_operator",
		pushToken:"Device_pushToken_operator",
		updatedAt:"Device_updatedAt_operator",
		createdAt:"Device_createdAt_operator",
		id:"Device_id_operator",
		AND:"Device_where_and",
		OR:"Device_where_or"
	},
	Notification:{
		receiver:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		meta:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		sender:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	Notification_type: "enum" as const,
	Notification_Meta_RelationTo: "enum" as const,
	Notification_where:{
		receiver:"Notification_receiver_operator",
		type:"Notification_type_operator",
		meta:"Notification_meta_Relation",
		message:"Notification_message_operator",
		sender:"Notification_sender_operator",
		readed:"Notification_readed_operator",
		deletedByUser:"Notification_deletedByUser_operator",
		updatedAt:"Notification_updatedAt_operator",
		createdAt:"Notification_createdAt_operator",
		id:"Notification_id_operator",
		AND:"Notification_where_and",
		OR:"Notification_where_or"
	},
	Notification_receiver_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Notification_type_operator:{
		equals:"Notification_type_Input",
		not_equals:"Notification_type_Input",
		in:"Notification_type_Input",
		not_in:"Notification_type_Input",
		all:"Notification_type_Input"
	},
	Notification_type_Input: "enum" as const,
	Notification_meta_Relation:{
		relationTo:"Notification_meta_Relation_RelationTo",
		value:"JSON"
	},
	Notification_meta_Relation_RelationTo: "enum" as const,
	Notification_message_operator:{

	},
	Notification_sender_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	Notification_readed_operator:{

	},
	Notification_deletedByUser_operator:{

	},
	Notification_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Notification_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Notification_id_operator:{

	},
	Notification_where_and:{
		receiver:"Notification_receiver_operator",
		type:"Notification_type_operator",
		meta:"Notification_meta_Relation",
		message:"Notification_message_operator",
		sender:"Notification_sender_operator",
		readed:"Notification_readed_operator",
		deletedByUser:"Notification_deletedByUser_operator",
		updatedAt:"Notification_updatedAt_operator",
		createdAt:"Notification_createdAt_operator",
		id:"Notification_id_operator",
		AND:"Notification_where_and",
		OR:"Notification_where_or"
	},
	Notification_where_or:{
		receiver:"Notification_receiver_operator",
		type:"Notification_type_operator",
		meta:"Notification_meta_Relation",
		message:"Notification_message_operator",
		sender:"Notification_sender_operator",
		readed:"Notification_readed_operator",
		deletedByUser:"Notification_deletedByUser_operator",
		updatedAt:"Notification_updatedAt_operator",
		createdAt:"Notification_createdAt_operator",
		id:"Notification_id_operator",
		AND:"Notification_where_and",
		OR:"Notification_where_or"
	},
	Newsletter_region: "enum" as const,
	Newsletter_where:{
		email:"Newsletter_email_operator",
		region:"Newsletter_region_operator",
		updatedAt:"Newsletter_updatedAt_operator",
		createdAt:"Newsletter_createdAt_operator",
		id:"Newsletter_id_operator",
		AND:"Newsletter_where_and",
		OR:"Newsletter_where_or"
	},
	Newsletter_email_operator:{

	},
	Newsletter_region_operator:{
		equals:"Newsletter_region_Input",
		not_equals:"Newsletter_region_Input",
		in:"Newsletter_region_Input",
		not_in:"Newsletter_region_Input",
		all:"Newsletter_region_Input"
	},
	Newsletter_region_Input: "enum" as const,
	Newsletter_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Newsletter_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Newsletter_id_operator:{

	},
	Newsletter_where_and:{
		email:"Newsletter_email_operator",
		region:"Newsletter_region_operator",
		updatedAt:"Newsletter_updatedAt_operator",
		createdAt:"Newsletter_createdAt_operator",
		id:"Newsletter_id_operator",
		AND:"Newsletter_where_and",
		OR:"Newsletter_where_or"
	},
	Newsletter_where_or:{
		email:"Newsletter_email_operator",
		region:"Newsletter_region_operator",
		updatedAt:"Newsletter_updatedAt_operator",
		createdAt:"Newsletter_createdAt_operator",
		id:"Newsletter_id_operator",
		AND:"Newsletter_where_and",
		OR:"Newsletter_where_or"
	},
	RedirectSetting_where:{
		from:"RedirectSetting_from_operator",
		to:"RedirectSetting_to_operator",
		updatedAt:"RedirectSetting_updatedAt_operator",
		createdAt:"RedirectSetting_createdAt_operator",
		id:"RedirectSetting_id_operator",
		AND:"RedirectSetting_where_and",
		OR:"RedirectSetting_where_or"
	},
	RedirectSetting_from_operator:{

	},
	RedirectSetting_to_operator:{

	},
	RedirectSetting_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	RedirectSetting_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	RedirectSetting_id_operator:{

	},
	RedirectSetting_where_and:{
		from:"RedirectSetting_from_operator",
		to:"RedirectSetting_to_operator",
		updatedAt:"RedirectSetting_updatedAt_operator",
		createdAt:"RedirectSetting_createdAt_operator",
		id:"RedirectSetting_id_operator",
		AND:"RedirectSetting_where_and",
		OR:"RedirectSetting_where_or"
	},
	RedirectSetting_where_or:{
		from:"RedirectSetting_from_operator",
		to:"RedirectSetting_to_operator",
		updatedAt:"RedirectSetting_updatedAt_operator",
		createdAt:"RedirectSetting_createdAt_operator",
		id:"RedirectSetting_id_operator",
		AND:"RedirectSetting_where_and",
		OR:"RedirectSetting_where_or"
	},
	Static_region: "enum" as const,
	Static_where:{
		name:"Static_name_operator",
		key:"Static_key_operator",
		region:"Static_region_operator",
		updatedAt:"Static_updatedAt_operator",
		createdAt:"Static_createdAt_operator",
		id:"Static_id_operator",
		AND:"Static_where_and",
		OR:"Static_where_or"
	},
	Static_name_operator:{

	},
	Static_key_operator:{

	},
	Static_region_operator:{
		equals:"Static_region_Input",
		not_equals:"Static_region_Input",
		in:"Static_region_Input",
		not_in:"Static_region_Input",
		all:"Static_region_Input"
	},
	Static_region_Input: "enum" as const,
	Static_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Static_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	Static_id_operator:{

	},
	Static_where_and:{
		name:"Static_name_operator",
		key:"Static_key_operator",
		region:"Static_region_operator",
		updatedAt:"Static_updatedAt_operator",
		createdAt:"Static_createdAt_operator",
		id:"Static_id_operator",
		AND:"Static_where_and",
		OR:"Static_where_or"
	},
	Static_where_or:{
		name:"Static_name_operator",
		key:"Static_key_operator",
		region:"Static_region_operator",
		updatedAt:"Static_updatedAt_operator",
		createdAt:"Static_createdAt_operator",
		id:"Static_id_operator",
		AND:"Static_where_and",
		OR:"Static_where_or"
	},
	CriteriaSetting_Criteria:{
		date:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		audience:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		category:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		},
		tag:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	CriteriaSetting_Criteria_region: "enum" as const,
	CriteriaSetting_where:{
		criteria__date:"CriteriaSetting_criteria__date_operator",
		criteria__audience:"CriteriaSetting_criteria__audience_operator",
		criteria__category:"CriteriaSetting_criteria__category_operator",
		criteria__tag:"CriteriaSetting_criteria__tag_operator",
		criteria__region:"CriteriaSetting_criteria__region_operator",
		updatedAt:"CriteriaSetting_updatedAt_operator",
		createdAt:"CriteriaSetting_createdAt_operator",
		id:"CriteriaSetting_id_operator",
		AND:"CriteriaSetting_where_and",
		OR:"CriteriaSetting_where_or"
	},
	CriteriaSetting_criteria__date_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	CriteriaSetting_criteria__audience_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	CriteriaSetting_criteria__category_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	CriteriaSetting_criteria__tag_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	CriteriaSetting_criteria__region_operator:{
		equals:"CriteriaSetting_criteria__region_Input",
		not_equals:"CriteriaSetting_criteria__region_Input",
		in:"CriteriaSetting_criteria__region_Input",
		not_in:"CriteriaSetting_criteria__region_Input",
		all:"CriteriaSetting_criteria__region_Input"
	},
	CriteriaSetting_criteria__region_Input: "enum" as const,
	CriteriaSetting_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	CriteriaSetting_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	CriteriaSetting_id_operator:{

	},
	CriteriaSetting_where_and:{
		criteria__date:"CriteriaSetting_criteria__date_operator",
		criteria__audience:"CriteriaSetting_criteria__audience_operator",
		criteria__category:"CriteriaSetting_criteria__category_operator",
		criteria__tag:"CriteriaSetting_criteria__tag_operator",
		criteria__region:"CriteriaSetting_criteria__region_operator",
		updatedAt:"CriteriaSetting_updatedAt_operator",
		createdAt:"CriteriaSetting_createdAt_operator",
		id:"CriteriaSetting_id_operator",
		AND:"CriteriaSetting_where_and",
		OR:"CriteriaSetting_where_or"
	},
	CriteriaSetting_where_or:{
		criteria__date:"CriteriaSetting_criteria__date_operator",
		criteria__audience:"CriteriaSetting_criteria__audience_operator",
		criteria__category:"CriteriaSetting_criteria__category_operator",
		criteria__tag:"CriteriaSetting_criteria__tag_operator",
		criteria__region:"CriteriaSetting_criteria__region_operator",
		updatedAt:"CriteriaSetting_updatedAt_operator",
		createdAt:"CriteriaSetting_createdAt_operator",
		id:"CriteriaSetting_id_operator",
		AND:"CriteriaSetting_where_and",
		OR:"CriteriaSetting_where_or"
	},
	OrganiserSetting:{
		organiserList:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	OrganiserSetting_region: "enum" as const,
	OrganiserSetting_where:{
		name:"OrganiserSetting_name_operator",
		organiserList:"OrganiserSetting_organiserList_operator",
		region:"OrganiserSetting_region_operator",
		updatedAt:"OrganiserSetting_updatedAt_operator",
		createdAt:"OrganiserSetting_createdAt_operator",
		id:"OrganiserSetting_id_operator",
		AND:"OrganiserSetting_where_and",
		OR:"OrganiserSetting_where_or"
	},
	OrganiserSetting_name_operator:{

	},
	OrganiserSetting_organiserList_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	OrganiserSetting_region_operator:{
		equals:"OrganiserSetting_region_Input",
		not_equals:"OrganiserSetting_region_Input",
		in:"OrganiserSetting_region_Input",
		not_in:"OrganiserSetting_region_Input",
		all:"OrganiserSetting_region_Input"
	},
	OrganiserSetting_region_Input: "enum" as const,
	OrganiserSetting_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	OrganiserSetting_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	OrganiserSetting_id_operator:{

	},
	OrganiserSetting_where_and:{
		name:"OrganiserSetting_name_operator",
		organiserList:"OrganiserSetting_organiserList_operator",
		region:"OrganiserSetting_region_operator",
		updatedAt:"OrganiserSetting_updatedAt_operator",
		createdAt:"OrganiserSetting_createdAt_operator",
		id:"OrganiserSetting_id_operator",
		AND:"OrganiserSetting_where_and",
		OR:"OrganiserSetting_where_or"
	},
	OrganiserSetting_where_or:{
		name:"OrganiserSetting_name_operator",
		organiserList:"OrganiserSetting_organiserList_operator",
		region:"OrganiserSetting_region_operator",
		updatedAt:"OrganiserSetting_updatedAt_operator",
		createdAt:"OrganiserSetting_createdAt_operator",
		id:"OrganiserSetting_id_operator",
		AND:"OrganiserSetting_where_and",
		OR:"OrganiserSetting_where_or"
	},
	PostSetting:{
		featuredPosts:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	PostSetting_region: "enum" as const,
	PostSetting_where:{
		name:"PostSetting_name_operator",
		featuredPosts:"PostSetting_featuredPosts_operator",
		region:"PostSetting_region_operator",
		updatedAt:"PostSetting_updatedAt_operator",
		createdAt:"PostSetting_createdAt_operator",
		id:"PostSetting_id_operator",
		AND:"PostSetting_where_and",
		OR:"PostSetting_where_or"
	},
	PostSetting_name_operator:{

	},
	PostSetting_featuredPosts_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	PostSetting_region_operator:{
		equals:"PostSetting_region_Input",
		not_equals:"PostSetting_region_Input",
		in:"PostSetting_region_Input",
		not_in:"PostSetting_region_Input",
		all:"PostSetting_region_Input"
	},
	PostSetting_region_Input: "enum" as const,
	PostSetting_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PostSetting_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PostSetting_id_operator:{

	},
	PostSetting_where_and:{
		name:"PostSetting_name_operator",
		featuredPosts:"PostSetting_featuredPosts_operator",
		region:"PostSetting_region_operator",
		updatedAt:"PostSetting_updatedAt_operator",
		createdAt:"PostSetting_createdAt_operator",
		id:"PostSetting_id_operator",
		AND:"PostSetting_where_and",
		OR:"PostSetting_where_or"
	},
	PostSetting_where_or:{
		name:"PostSetting_name_operator",
		featuredPosts:"PostSetting_featuredPosts_operator",
		region:"PostSetting_region_operator",
		updatedAt:"PostSetting_updatedAt_operator",
		createdAt:"PostSetting_createdAt_operator",
		id:"PostSetting_id_operator",
		AND:"PostSetting_where_and",
		OR:"PostSetting_where_or"
	},
	HomepageSetting_region: "enum" as const,
	ComingWeekEventAndLocationRankingBlock:{
		rankings:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	ComingWeekEventAndLocationRankingBlock_appearance: "enum" as const,
	ComingWeekEventAndLocationRankingBlock_byRegion: "enum" as const,
	EventRankingBlock:{
		ranking:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EventRankingBlock_appearance: "enum" as const,
	LocationRankingBlock:{
		ranking:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	LocationRankingBlock_appearance: "enum" as const,
	TimableRankingBlock:{
		ranking:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	HomepageSetting_where:{
		name:"HomepageSetting_name_operator",
		region:"HomepageSetting_region_operator",
		updatedAt:"HomepageSetting_updatedAt_operator",
		createdAt:"HomepageSetting_createdAt_operator",
		id:"HomepageSetting_id_operator",
		AND:"HomepageSetting_where_and",
		OR:"HomepageSetting_where_or"
	},
	HomepageSetting_name_operator:{

	},
	HomepageSetting_region_operator:{
		equals:"HomepageSetting_region_Input",
		not_equals:"HomepageSetting_region_Input",
		in:"HomepageSetting_region_Input",
		not_in:"HomepageSetting_region_Input",
		all:"HomepageSetting_region_Input"
	},
	HomepageSetting_region_Input: "enum" as const,
	HomepageSetting_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	HomepageSetting_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	HomepageSetting_id_operator:{

	},
	HomepageSetting_where_and:{
		name:"HomepageSetting_name_operator",
		region:"HomepageSetting_region_operator",
		updatedAt:"HomepageSetting_updatedAt_operator",
		createdAt:"HomepageSetting_createdAt_operator",
		id:"HomepageSetting_id_operator",
		AND:"HomepageSetting_where_and",
		OR:"HomepageSetting_where_or"
	},
	HomepageSetting_where_or:{
		name:"HomepageSetting_name_operator",
		region:"HomepageSetting_region_operator",
		updatedAt:"HomepageSetting_updatedAt_operator",
		createdAt:"HomepageSetting_createdAt_operator",
		id:"HomepageSetting_id_operator",
		AND:"HomepageSetting_where_and",
		OR:"HomepageSetting_where_or"
	},
	EventView:{
		eventId:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	EventView_where:{
		date:"EventView_date_operator",
		eventId:"EventView_eventId_operator",
		viewCount:"EventView_viewCount_operator",
		updatedAt:"EventView_updatedAt_operator",
		createdAt:"EventView_createdAt_operator",
		id:"EventView_id_operator",
		AND:"EventView_where_and",
		OR:"EventView_where_or"
	},
	EventView_date_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventView_eventId_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	EventView_viewCount_operator:{

	},
	EventView_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventView_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	EventView_id_operator:{

	},
	EventView_where_and:{
		date:"EventView_date_operator",
		eventId:"EventView_eventId_operator",
		viewCount:"EventView_viewCount_operator",
		updatedAt:"EventView_updatedAt_operator",
		createdAt:"EventView_createdAt_operator",
		id:"EventView_id_operator",
		AND:"EventView_where_and",
		OR:"EventView_where_or"
	},
	EventView_where_or:{
		date:"EventView_date_operator",
		eventId:"EventView_eventId_operator",
		viewCount:"EventView_viewCount_operator",
		updatedAt:"EventView_updatedAt_operator",
		createdAt:"EventView_createdAt_operator",
		id:"EventView_id_operator",
		AND:"EventView_where_and",
		OR:"EventView_where_or"
	},
	PostView:{
		postId:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	PostView_where:{
		date:"PostView_date_operator",
		postId:"PostView_postId_operator",
		viewCount:"PostView_viewCount_operator",
		updatedAt:"PostView_updatedAt_operator",
		createdAt:"PostView_createdAt_operator",
		id:"PostView_id_operator",
		AND:"PostView_where_and",
		OR:"PostView_where_or"
	},
	PostView_date_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PostView_postId_operator:{
		equals:"JSON",
		not_equals:"JSON",
		in:"JSON",
		not_in:"JSON",
		all:"JSON"
	},
	PostView_viewCount_operator:{

	},
	PostView_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PostView_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PostView_id_operator:{

	},
	PostView_where_and:{
		date:"PostView_date_operator",
		postId:"PostView_postId_operator",
		viewCount:"PostView_viewCount_operator",
		updatedAt:"PostView_updatedAt_operator",
		createdAt:"PostView_createdAt_operator",
		id:"PostView_id_operator",
		AND:"PostView_where_and",
		OR:"PostView_where_or"
	},
	PostView_where_or:{
		date:"PostView_date_operator",
		postId:"PostView_postId_operator",
		viewCount:"PostView_viewCount_operator",
		updatedAt:"PostView_updatedAt_operator",
		createdAt:"PostView_createdAt_operator",
		id:"PostView_id_operator",
		AND:"PostView_where_and",
		OR:"PostView_where_or"
	},
	PayloadPreference:{
		user:{
			locale:"LocaleInputType",
			fallbackLocale:"FallbackLocaleInputType"
		}
	},
	PayloadPreference_User_RelationTo: "enum" as const,
	PayloadPreference_where:{
		user:"PayloadPreference_user_Relation",
		key:"PayloadPreference_key_operator",
		value:"PayloadPreference_value_operator",
		updatedAt:"PayloadPreference_updatedAt_operator",
		createdAt:"PayloadPreference_createdAt_operator",
		id:"PayloadPreference_id_operator",
		AND:"PayloadPreference_where_and",
		OR:"PayloadPreference_where_or"
	},
	PayloadPreference_user_Relation:{
		relationTo:"PayloadPreference_user_Relation_RelationTo",
		value:"JSON"
	},
	PayloadPreference_user_Relation_RelationTo: "enum" as const,
	PayloadPreference_key_operator:{

	},
	PayloadPreference_value_operator:{
		equals:"JSON",
		not_equals:"JSON",
		like:"JSON",
		contains:"JSON",
		within:"JSON",
		intersects:"JSON"
	},
	PayloadPreference_updatedAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PayloadPreference_createdAt_operator:{
		equals:"DateTime",
		not_equals:"DateTime",
		greater_than_equal:"DateTime",
		greater_than:"DateTime",
		less_than_equal:"DateTime",
		less_than:"DateTime",
		like:"DateTime"
	},
	PayloadPreference_id_operator:{

	},
	PayloadPreference_where_and:{
		user:"PayloadPreference_user_Relation",
		key:"PayloadPreference_key_operator",
		value:"PayloadPreference_value_operator",
		updatedAt:"PayloadPreference_updatedAt_operator",
		createdAt:"PayloadPreference_createdAt_operator",
		id:"PayloadPreference_id_operator",
		AND:"PayloadPreference_where_and",
		OR:"PayloadPreference_where_or"
	},
	PayloadPreference_where_or:{
		user:"PayloadPreference_user_Relation",
		key:"PayloadPreference_key_operator",
		value:"PayloadPreference_value_operator",
		updatedAt:"PayloadPreference_updatedAt_operator",
		createdAt:"PayloadPreference_createdAt_operator",
		id:"PayloadPreference_id_operator",
		AND:"PayloadPreference_where_and",
		OR:"PayloadPreference_where_or"
	},
	Mutation:{
		createUser:{
			data:"mutationUserInput",
			locale:"LocaleInputType"
		},
		updateUser:{
			data:"mutationUserUpdateInput",
			locale:"LocaleInputType"
		},
		deleteUser:{

		},
		unlockUser:{

		},
		loginUser:{

		},
		forgotPasswordUser:{

		},
		resetPasswordUser:{

		},
		verifyEmailUser:{

		},
		createBlogCategory:{
			data:"mutationBlogCategoryInput",
			locale:"LocaleInputType"
		},
		updateBlogCategory:{
			data:"mutationBlogCategoryUpdateInput",
			locale:"LocaleInputType"
		},
		deleteBlogCategory:{

		},
		createTag:{
			data:"mutationTagInput",
			locale:"LocaleInputType"
		},
		updateTag:{
			data:"mutationTagUpdateInput",
			locale:"LocaleInputType"
		},
		deleteTag:{

		},
		createSpecialTag:{
			data:"mutationSpecialTagInput",
			locale:"LocaleInputType"
		},
		updateSpecialTag:{
			data:"mutationSpecialTagUpdateInput",
			locale:"LocaleInputType"
		},
		deleteSpecialTag:{

		},
		createDate:{
			data:"mutationDateInput",
			locale:"LocaleInputType"
		},
		updateDate:{
			data:"mutationDateUpdateInput",
			locale:"LocaleInputType"
		},
		deleteDate:{

		},
		createDistrict:{
			data:"mutationDistrictInput",
			locale:"LocaleInputType"
		},
		updateDistrict:{
			data:"mutationDistrictUpdateInput",
			locale:"LocaleInputType"
		},
		deleteDistrict:{

		},
		createAudience:{
			data:"mutationAudienceInput",
			locale:"LocaleInputType"
		},
		updateAudience:{
			data:"mutationAudienceUpdateInput",
			locale:"LocaleInputType"
		},
		deleteAudience:{

		},
		createEvent:{
			data:"mutationEventInput",
			locale:"LocaleInputType"
		},
		updateEvent:{
			data:"mutationEventUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEvent:{

		},
		createLocation:{
			data:"mutationLocationInput",
			locale:"LocaleInputType"
		},
		updateLocation:{
			data:"mutationLocationUpdateInput",
			locale:"LocaleInputType"
		},
		deleteLocation:{

		},
		createPost:{
			data:"mutationPostInput",
			locale:"LocaleInputType"
		},
		updatePost:{
			data:"mutationPostUpdateInput",
			locale:"LocaleInputType"
		},
		deletePost:{

		},
		createPrivateMedia:{
			data:"mutationPrivateMediaInput",
			locale:"LocaleInputType"
		},
		updatePrivateMedia:{
			data:"mutationPrivateMediaUpdateInput",
			locale:"LocaleInputType"
		},
		deletePrivateMedia:{

		},
		createMedia:{
			data:"mutationMediaInput",
			locale:"LocaleInputType"
		},
		updateMedia:{
			data:"mutationMediaUpdateInput",
			locale:"LocaleInputType"
		},
		deleteMedia:{

		},
		createBackupMedia:{
			data:"mutationBackupMediaInput",
			locale:"LocaleInputType"
		},
		updateBackupMedia:{
			data:"mutationBackupMediaUpdateInput",
			locale:"LocaleInputType"
		},
		deleteBackupMedia:{

		},
		createEventRanking:{
			data:"mutationEventRankingInput",
			locale:"LocaleInputType"
		},
		updateEventRanking:{
			data:"mutationEventRankingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEventRanking:{

		},
		createLocationRanking:{
			data:"mutationLocationRankingInput",
			locale:"LocaleInputType"
		},
		updateLocationRanking:{
			data:"mutationLocationRankingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteLocationRanking:{

		},
		createAccountSuspendRequest:{
			data:"mutationAccountSuspendRequestInput",
			locale:"LocaleInputType"
		},
		updateAccountSuspendRequest:{
			data:"mutationAccountSuspendRequestUpdateInput",
			locale:"LocaleInputType"
		},
		deleteAccountSuspendRequest:{

		},
		createBookmark:{
			data:"mutationBookmarkInput",
			locale:"LocaleInputType"
		},
		updateBookmark:{
			data:"mutationBookmarkUpdateInput",
			locale:"LocaleInputType"
		},
		deleteBookmark:{

		},
		createViewRecord:{
			data:"mutationViewRecordInput",
			locale:"LocaleInputType"
		},
		updateViewRecord:{
			data:"mutationViewRecordUpdateInput",
			locale:"LocaleInputType"
		},
		deleteViewRecord:{

		},
		createEventCategory:{
			data:"mutationEventCategoryInput",
			locale:"LocaleInputType"
		},
		updateEventCategory:{
			data:"mutationEventCategoryUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEventCategory:{

		},
		createComment:{
			data:"mutationCommentInput",
			locale:"LocaleInputType"
		},
		updateComment:{
			data:"mutationCommentUpdateInput",
			locale:"LocaleInputType"
		},
		deleteComment:{

		},
		createEventCalendar:{
			data:"mutationEventCalendarInput",
			locale:"LocaleInputType"
		},
		updateEventCalendar:{
			data:"mutationEventCalendarUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEventCalendar:{

		},
		createSubscription:{
			data:"mutationSubscriptionInput",
			locale:"LocaleInputType"
		},
		updateSubscription:{
			data:"mutationSubscriptionUpdateInput",
			locale:"LocaleInputType"
		},
		deleteSubscription:{

		},
		createEditorChoice:{
			data:"mutationEditorChoiceInput",
			locale:"LocaleInputType"
		},
		updateEditorChoice:{
			data:"mutationEditorChoiceUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEditorChoice:{

		},
		createBannerSection:{
			data:"mutationBannerSectionInput",
			locale:"LocaleInputType"
		},
		updateBannerSection:{
			data:"mutationBannerSectionUpdateInput",
			locale:"LocaleInputType"
		},
		deleteBannerSection:{

		},
		createMacaoBannerSection:{
			data:"mutationMacaoBannerSectionInput",
			locale:"LocaleInputType"
		},
		updateMacaoBannerSection:{
			data:"mutationMacaoBannerSectionUpdateInput",
			locale:"LocaleInputType"
		},
		deleteMacaoBannerSection:{

		},
		createBanner:{
			data:"mutationBannerInput",
			locale:"LocaleInputType"
		},
		updateBanner:{
			data:"mutationBannerUpdateInput",
			locale:"LocaleInputType"
		},
		deleteBanner:{

		},
		createCampaign:{
			data:"mutationCampaignInput",
			locale:"LocaleInputType"
		},
		updateCampaign:{
			data:"mutationCampaignUpdateInput",
			locale:"LocaleInputType"
		},
		deleteCampaign:{

		},
		createAdvertise:{
			data:"mutationAdvertiseInput",
			locale:"LocaleInputType"
		},
		updateAdvertise:{
			data:"mutationAdvertiseUpdateInput",
			locale:"LocaleInputType"
		},
		deleteAdvertise:{

		},
		createDevice:{
			data:"mutationDeviceInput",
			locale:"LocaleInputType"
		},
		updateDevice:{
			data:"mutationDeviceUpdateInput",
			locale:"LocaleInputType"
		},
		deleteDevice:{

		},
		createNotification:{
			data:"mutationNotificationInput",
			locale:"LocaleInputType"
		},
		updateNotification:{
			data:"mutationNotificationUpdateInput",
			locale:"LocaleInputType"
		},
		deleteNotification:{

		},
		createNewsletter:{
			data:"mutationNewsletterInput",
			locale:"LocaleInputType"
		},
		updateNewsletter:{
			data:"mutationNewsletterUpdateInput",
			locale:"LocaleInputType"
		},
		deleteNewsletter:{

		},
		createRedirectSetting:{
			data:"mutationRedirectSettingInput",
			locale:"LocaleInputType"
		},
		updateRedirectSetting:{
			data:"mutationRedirectSettingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteRedirectSetting:{

		},
		createStatic:{
			data:"mutationStaticInput",
			locale:"LocaleInputType"
		},
		updateStatic:{
			data:"mutationStaticUpdateInput",
			locale:"LocaleInputType"
		},
		deleteStatic:{

		},
		createCriteriaSetting:{
			data:"mutationCriteriaSettingInput",
			locale:"LocaleInputType"
		},
		updateCriteriaSetting:{
			data:"mutationCriteriaSettingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteCriteriaSetting:{

		},
		createOrganiserSetting:{
			data:"mutationOrganiserSettingInput",
			locale:"LocaleInputType"
		},
		updateOrganiserSetting:{
			data:"mutationOrganiserSettingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteOrganiserSetting:{

		},
		createPostSetting:{
			data:"mutationPostSettingInput",
			locale:"LocaleInputType"
		},
		updatePostSetting:{
			data:"mutationPostSettingUpdateInput",
			locale:"LocaleInputType"
		},
		deletePostSetting:{

		},
		createHomepageSetting:{
			data:"mutationHomepageSettingInput",
			locale:"LocaleInputType"
		},
		updateHomepageSetting:{
			data:"mutationHomepageSettingUpdateInput",
			locale:"LocaleInputType"
		},
		deleteHomepageSetting:{

		},
		createEventView:{
			data:"mutationEventViewInput",
			locale:"LocaleInputType"
		},
		updateEventView:{
			data:"mutationEventViewUpdateInput",
			locale:"LocaleInputType"
		},
		deleteEventView:{

		},
		createPostView:{
			data:"mutationPostViewInput",
			locale:"LocaleInputType"
		},
		updatePostView:{
			data:"mutationPostViewUpdateInput",
			locale:"LocaleInputType"
		},
		deletePostView:{

		},
		createPayloadPreference:{
			data:"mutationPayloadPreferenceInput",
			locale:"LocaleInputType"
		},
		updatePayloadPreference:{
			data:"mutationPayloadPreferenceUpdateInput",
			locale:"LocaleInputType"
		},
		deletePayloadPreference:{

		},
		AddEventView:{

		},
		ItemBookmark:{

		},
		ItemUnbookmark:{

		},
		readItem:{
			relationTo:"ViewRecord_ItemRelationshipInputRelationTo",
			value:"JSON"
		},
		AddPostView:{

		},
		LoginUser:{

		},
		RegisterUser:{
			data:"mutationUserInput",
			locale:"LocaleInputType"
		},
		ActiveUser:{

		},
		ReActivePasswordUser:{

		},
		SSOLoginUser:{

		},
		UpdateUserPassword:{

		},
		SubscribeOrganiser:{

		},
		UnSubscribeOrganiser:{

		},
		PostMessage:{

		},
		AddBannerView:{

		},
		AddDevice:{

		},
		RemoveDevice:{

		},
		UpdateNotifications:{

		},
		DeleteNotification:{

		},
		CreateNewsletter:{

		}
	},
	mutationUserInput:{
		region:"User_region_MutationInput",
		roles:"User_roles_MutationInput",
		member:"mutationUser_MemberInput",
		organiser:"mutationUser_OrganiserInput",
		sso:"mutationUser_SsoInput",
		status:"User_status_MutationInput"
	},
	User_region_MutationInput: "enum" as const,
	User_roles_MutationInput: "enum" as const,
	mutationUser_MemberInput:{
		region:"User_Member_region_MutationInput",
		language:"User_Member_language_MutationInput",
		gender:"User_Member_gender_MutationInput"
	},
	User_Member_region_MutationInput: "enum" as const,
	User_Member_language_MutationInput: "enum" as const,
	User_Member_gender_MutationInput: "enum" as const,
	mutationUser_OrganiserInput:{
		language:"User_Organiser_language_MutationInput"
	},
	User_Organiser_language_MutationInput: "enum" as const,
	mutationUser_SsoInput:{

	},
	User_status_MutationInput: "enum" as const,
	mutationUserUpdateInput:{
		region:"UserUpdate_region_MutationInput",
		roles:"UserUpdate_roles_MutationInput",
		member:"mutationUserUpdate_MemberInput",
		organiser:"mutationUserUpdate_OrganiserInput",
		sso:"mutationUserUpdate_SsoInput",
		status:"UserUpdate_status_MutationInput"
	},
	UserUpdate_region_MutationInput: "enum" as const,
	UserUpdate_roles_MutationInput: "enum" as const,
	mutationUserUpdate_MemberInput:{
		region:"UserUpdate_Member_region_MutationInput",
		language:"UserUpdate_Member_language_MutationInput",
		gender:"UserUpdate_Member_gender_MutationInput"
	},
	UserUpdate_Member_region_MutationInput: "enum" as const,
	UserUpdate_Member_language_MutationInput: "enum" as const,
	UserUpdate_Member_gender_MutationInput: "enum" as const,
	mutationUserUpdate_OrganiserInput:{
		language:"UserUpdate_Organiser_language_MutationInput"
	},
	UserUpdate_Organiser_language_MutationInput: "enum" as const,
	mutationUserUpdate_SsoInput:{

	},
	UserUpdate_status_MutationInput: "enum" as const,
	mutationBlogCategoryInput:{
		region:"BlogCategory_region_MutationInput"
	},
	BlogCategory_region_MutationInput: "enum" as const,
	mutationBlogCategoryUpdateInput:{
		region:"BlogCategoryUpdate_region_MutationInput"
	},
	BlogCategoryUpdate_region_MutationInput: "enum" as const,
	mutationTagInput:{
		region:"Tag_region_MutationInput"
	},
	Tag_region_MutationInput: "enum" as const,
	mutationTagUpdateInput:{
		region:"TagUpdate_region_MutationInput"
	},
	TagUpdate_region_MutationInput: "enum" as const,
	mutationSpecialTagInput:{
		region:"SpecialTag_region_MutationInput"
	},
	SpecialTag_region_MutationInput: "enum" as const,
	mutationSpecialTagUpdateInput:{
		region:"SpecialTagUpdate_region_MutationInput"
	},
	SpecialTagUpdate_region_MutationInput: "enum" as const,
	mutationDateInput:{
		type:"Date_type_MutationInput",
		region:"Date_region_MutationInput"
	},
	Date_type_MutationInput: "enum" as const,
	Date_region_MutationInput: "enum" as const,
	mutationDateUpdateInput:{
		type:"DateUpdate_type_MutationInput",
		region:"DateUpdate_region_MutationInput"
	},
	DateUpdate_type_MutationInput: "enum" as const,
	DateUpdate_region_MutationInput: "enum" as const,
	mutationDistrictInput:{
		region:"District_region_MutationInput"
	},
	District_region_MutationInput: "enum" as const,
	mutationDistrictUpdateInput:{
		region:"DistrictUpdate_region_MutationInput"
	},
	DistrictUpdate_region_MutationInput: "enum" as const,
	mutationAudienceInput:{
		region:"Audience_region_MutationInput"
	},
	Audience_region_MutationInput: "enum" as const,
	mutationAudienceUpdateInput:{
		region:"AudienceUpdate_region_MutationInput"
	},
	AudienceUpdate_region_MutationInput: "enum" as const,
	mutationEventInput:{
		region:"Event_region_MutationInput",
		level:"Event_level_MutationInput",
		sections:"mutationEvent_SectionsInput",
		ticketing:"mutationEvent_TicketingInput",
		content:"JSON",
		criteria:"mutationEvent_CriteriaInput",
		contact:"mutationEvent_ContactInput",
		fees:"mutationEvent_FeesInput"
	},
	Event_region_MutationInput: "enum" as const,
	Event_level_MutationInput: "enum" as const,
	mutationEvent_SectionsInput:{
		recurrance:"mutationEvent_Sections_RecurranceInput"
	},
	mutationEvent_Sections_RecurranceInput:{
		type:"Event_Sections_Recurrance_type_MutationInput",
		weekday:"Event_Sections_Recurrance_weekday_MutationInput"
	},
	Event_Sections_Recurrance_type_MutationInput: "enum" as const,
	Event_Sections_Recurrance_weekday_MutationInput: "enum" as const,
	mutationEvent_TicketingInput:{
		type:"Event_Ticketing_type_MutationInput",
		ticketingType:"Event_Ticketing_ticketingType_MutationInput",
		promotionType:"Event_Ticketing_promotionType_MutationInput"
	},
	Event_Ticketing_type_MutationInput: "enum" as const,
	Event_Ticketing_ticketingType_MutationInput: "enum" as const,
	Event_Ticketing_promotionType_MutationInput: "enum" as const,
	mutationEvent_CriteriaInput:{

	},
	mutationEvent_ContactInput:{

	},
	mutationEvent_FeesInput:{

	},
	mutationEventUpdateInput:{
		region:"EventUpdate_region_MutationInput",
		level:"EventUpdate_level_MutationInput",
		sections:"mutationEventUpdate_SectionsInput",
		ticketing:"mutationEventUpdate_TicketingInput",
		content:"JSON",
		criteria:"mutationEventUpdate_CriteriaInput",
		contact:"mutationEventUpdate_ContactInput",
		fees:"mutationEventUpdate_FeesInput"
	},
	EventUpdate_region_MutationInput: "enum" as const,
	EventUpdate_level_MutationInput: "enum" as const,
	mutationEventUpdate_SectionsInput:{
		recurrance:"mutationEventUpdate_Sections_RecurranceInput"
	},
	mutationEventUpdate_Sections_RecurranceInput:{
		type:"EventUpdate_Sections_Recurrance_type_MutationInput",
		weekday:"EventUpdate_Sections_Recurrance_weekday_MutationInput"
	},
	EventUpdate_Sections_Recurrance_type_MutationInput: "enum" as const,
	EventUpdate_Sections_Recurrance_weekday_MutationInput: "enum" as const,
	mutationEventUpdate_TicketingInput:{
		type:"EventUpdate_Ticketing_type_MutationInput",
		ticketingType:"EventUpdate_Ticketing_ticketingType_MutationInput",
		promotionType:"EventUpdate_Ticketing_promotionType_MutationInput"
	},
	EventUpdate_Ticketing_type_MutationInput: "enum" as const,
	EventUpdate_Ticketing_ticketingType_MutationInput: "enum" as const,
	EventUpdate_Ticketing_promotionType_MutationInput: "enum" as const,
	mutationEventUpdate_CriteriaInput:{

	},
	mutationEventUpdate_ContactInput:{

	},
	mutationEventUpdate_FeesInput:{

	},
	mutationLocationInput:{
		region:"Location_region_MutationInput",
		level:"Location_level_MutationInput",
		type:"Location_type_MutationInput",
		category:"Location_category_MutationInput",
		contact:"mutationLocation_ContactInput",
		content:"JSON",
		criteria:"mutationLocation_CriteriaInput",
		sections:"mutationLocation_SectionsInput"
	},
	Location_region_MutationInput: "enum" as const,
	Location_level_MutationInput: "enum" as const,
	Location_type_MutationInput: "enum" as const,
	Location_category_MutationInput: "enum" as const,
	mutationLocation_ContactInput:{

	},
	mutationLocation_CriteriaInput:{

	},
	mutationLocation_SectionsInput:{
		recurrance:"mutationLocation_Sections_RecurranceInput"
	},
	mutationLocation_Sections_RecurranceInput:{
		type:"Location_Sections_Recurrance_type_MutationInput",
		weekday:"Location_Sections_Recurrance_weekday_MutationInput"
	},
	Location_Sections_Recurrance_type_MutationInput: "enum" as const,
	Location_Sections_Recurrance_weekday_MutationInput: "enum" as const,
	mutationLocationUpdateInput:{
		region:"LocationUpdate_region_MutationInput",
		level:"LocationUpdate_level_MutationInput",
		type:"LocationUpdate_type_MutationInput",
		category:"LocationUpdate_category_MutationInput",
		contact:"mutationLocationUpdate_ContactInput",
		content:"JSON",
		criteria:"mutationLocationUpdate_CriteriaInput",
		sections:"mutationLocationUpdate_SectionsInput"
	},
	LocationUpdate_region_MutationInput: "enum" as const,
	LocationUpdate_level_MutationInput: "enum" as const,
	LocationUpdate_type_MutationInput: "enum" as const,
	LocationUpdate_category_MutationInput: "enum" as const,
	mutationLocationUpdate_ContactInput:{

	},
	mutationLocationUpdate_CriteriaInput:{

	},
	mutationLocationUpdate_SectionsInput:{
		recurrance:"mutationLocationUpdate_Sections_RecurranceInput"
	},
	mutationLocationUpdate_Sections_RecurranceInput:{
		type:"LocationUpdate_Sections_Recurrance_type_MutationInput",
		weekday:"LocationUpdate_Sections_Recurrance_weekday_MutationInput"
	},
	LocationUpdate_Sections_Recurrance_type_MutationInput: "enum" as const,
	LocationUpdate_Sections_Recurrance_weekday_MutationInput: "enum" as const,
	mutationPostInput:{
		region:"Post_region_MutationInput",
		content:"JSON",
		criteria:"mutationPost_CriteriaInput"
	},
	Post_region_MutationInput: "enum" as const,
	mutationPost_CriteriaInput:{

	},
	mutationPostUpdateInput:{
		region:"PostUpdate_region_MutationInput",
		content:"JSON",
		criteria:"mutationPostUpdate_CriteriaInput"
	},
	PostUpdate_region_MutationInput: "enum" as const,
	mutationPostUpdate_CriteriaInput:{

	},
	mutationPrivateMediaInput:{
		sizes:"mutationPrivateMedia_SizesInput"
	},
	mutationPrivateMedia_SizesInput:{
		thumbnail:"mutationPrivateMedia_Sizes_ThumbnailInput",
		portrait:"mutationPrivateMedia_Sizes_PortraitInput",
		hero:"mutationPrivateMedia_Sizes_HeroInput",
		thumbnail_jpeg:"mutationPrivateMedia_Sizes_Thumbnail_jpegInput",
		portrait_jpeg:"mutationPrivateMedia_Sizes_Portrait_jpegInput",
		hero_jpeg:"mutationPrivateMedia_Sizes_Hero_jpegInput"
	},
	mutationPrivateMedia_Sizes_ThumbnailInput:{

	},
	mutationPrivateMedia_Sizes_PortraitInput:{

	},
	mutationPrivateMedia_Sizes_HeroInput:{

	},
	mutationPrivateMedia_Sizes_Thumbnail_jpegInput:{

	},
	mutationPrivateMedia_Sizes_Portrait_jpegInput:{

	},
	mutationPrivateMedia_Sizes_Hero_jpegInput:{

	},
	mutationPrivateMediaUpdateInput:{
		sizes:"mutationPrivateMediaUpdate_SizesInput"
	},
	mutationPrivateMediaUpdate_SizesInput:{
		thumbnail:"mutationPrivateMediaUpdate_Sizes_ThumbnailInput",
		portrait:"mutationPrivateMediaUpdate_Sizes_PortraitInput",
		hero:"mutationPrivateMediaUpdate_Sizes_HeroInput",
		thumbnail_jpeg:"mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput",
		portrait_jpeg:"mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput",
		hero_jpeg:"mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"
	},
	mutationPrivateMediaUpdate_Sizes_ThumbnailInput:{

	},
	mutationPrivateMediaUpdate_Sizes_PortraitInput:{

	},
	mutationPrivateMediaUpdate_Sizes_HeroInput:{

	},
	mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput:{

	},
	mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput:{

	},
	mutationPrivateMediaUpdate_Sizes_Hero_jpegInput:{

	},
	mutationMediaInput:{
		sizes:"mutationMedia_SizesInput"
	},
	mutationMedia_SizesInput:{
		small:"mutationMedia_Sizes_SmallInput",
		medium:"mutationMedia_Sizes_MediumInput",
		large:"mutationMedia_Sizes_LargeInput",
		small_jpeg:"mutationMedia_Sizes_Small_jpegInput",
		medium_jpeg:"mutationMedia_Sizes_Medium_jpegInput",
		large_jpeg:"mutationMedia_Sizes_Large_jpegInput"
	},
	mutationMedia_Sizes_SmallInput:{

	},
	mutationMedia_Sizes_MediumInput:{

	},
	mutationMedia_Sizes_LargeInput:{

	},
	mutationMedia_Sizes_Small_jpegInput:{

	},
	mutationMedia_Sizes_Medium_jpegInput:{

	},
	mutationMedia_Sizes_Large_jpegInput:{

	},
	mutationMediaUpdateInput:{
		sizes:"mutationMediaUpdate_SizesInput"
	},
	mutationMediaUpdate_SizesInput:{
		small:"mutationMediaUpdate_Sizes_SmallInput",
		medium:"mutationMediaUpdate_Sizes_MediumInput",
		large:"mutationMediaUpdate_Sizes_LargeInput",
		small_jpeg:"mutationMediaUpdate_Sizes_Small_jpegInput",
		medium_jpeg:"mutationMediaUpdate_Sizes_Medium_jpegInput",
		large_jpeg:"mutationMediaUpdate_Sizes_Large_jpegInput"
	},
	mutationMediaUpdate_Sizes_SmallInput:{

	},
	mutationMediaUpdate_Sizes_MediumInput:{

	},
	mutationMediaUpdate_Sizes_LargeInput:{

	},
	mutationMediaUpdate_Sizes_Small_jpegInput:{

	},
	mutationMediaUpdate_Sizes_Medium_jpegInput:{

	},
	mutationMediaUpdate_Sizes_Large_jpegInput:{

	},
	mutationBackupMediaInput:{
		sizes:"mutationBackupMedia_SizesInput"
	},
	mutationBackupMedia_SizesInput:{
		thumbnail:"mutationBackupMedia_Sizes_ThumbnailInput",
		sixteenByNineMedium:"mutationBackupMedia_Sizes_SixteenByNineMediumInput"
	},
	mutationBackupMedia_Sizes_ThumbnailInput:{

	},
	mutationBackupMedia_Sizes_SixteenByNineMediumInput:{

	},
	mutationBackupMediaUpdateInput:{
		sizes:"mutationBackupMediaUpdate_SizesInput"
	},
	mutationBackupMediaUpdate_SizesInput:{
		thumbnail:"mutationBackupMediaUpdate_Sizes_ThumbnailInput",
		sixteenByNineMedium:"mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"
	},
	mutationBackupMediaUpdate_Sizes_ThumbnailInput:{

	},
	mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput:{

	},
	mutationEventRankingInput:{
		template:"EventRanking_template_MutationInput",
		list:"mutationEventRanking_ListInput",
		region:"EventRanking_region_MutationInput"
	},
	EventRanking_template_MutationInput: "enum" as const,
	mutationEventRanking_ListInput:{

	},
	EventRanking_region_MutationInput: "enum" as const,
	mutationEventRankingUpdateInput:{
		template:"EventRankingUpdate_template_MutationInput",
		list:"mutationEventRankingUpdate_ListInput",
		region:"EventRankingUpdate_region_MutationInput"
	},
	EventRankingUpdate_template_MutationInput: "enum" as const,
	mutationEventRankingUpdate_ListInput:{

	},
	EventRankingUpdate_region_MutationInput: "enum" as const,
	mutationLocationRankingInput:{
		template:"LocationRanking_template_MutationInput",
		list:"mutationLocationRanking_ListInput",
		region:"LocationRanking_region_MutationInput"
	},
	LocationRanking_template_MutationInput: "enum" as const,
	mutationLocationRanking_ListInput:{

	},
	LocationRanking_region_MutationInput: "enum" as const,
	mutationLocationRankingUpdateInput:{
		template:"LocationRankingUpdate_template_MutationInput",
		list:"mutationLocationRankingUpdate_ListInput",
		region:"LocationRankingUpdate_region_MutationInput"
	},
	LocationRankingUpdate_template_MutationInput: "enum" as const,
	mutationLocationRankingUpdate_ListInput:{

	},
	LocationRankingUpdate_region_MutationInput: "enum" as const,
	mutationAccountSuspendRequestInput:{

	},
	mutationAccountSuspendRequestUpdateInput:{

	},
	mutationBookmarkInput:{
		user:"Bookmark_UserRelationshipInput",
		item:"Bookmark_ItemRelationshipInput"
	},
	Bookmark_UserRelationshipInput:{
		relationTo:"Bookmark_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	Bookmark_UserRelationshipInputRelationTo: "enum" as const,
	Bookmark_ItemRelationshipInput:{
		relationTo:"Bookmark_ItemRelationshipInputRelationTo",
		value:"JSON"
	},
	Bookmark_ItemRelationshipInputRelationTo: "enum" as const,
	mutationBookmarkUpdateInput:{
		user:"BookmarkUpdate_UserRelationshipInput",
		item:"BookmarkUpdate_ItemRelationshipInput"
	},
	BookmarkUpdate_UserRelationshipInput:{
		relationTo:"BookmarkUpdate_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	BookmarkUpdate_UserRelationshipInputRelationTo: "enum" as const,
	BookmarkUpdate_ItemRelationshipInput:{
		relationTo:"BookmarkUpdate_ItemRelationshipInputRelationTo",
		value:"JSON"
	},
	BookmarkUpdate_ItemRelationshipInputRelationTo: "enum" as const,
	mutationViewRecordInput:{
		user:"ViewRecord_UserRelationshipInput",
		item:"ViewRecord_ItemRelationshipInput"
	},
	ViewRecord_UserRelationshipInput:{
		relationTo:"ViewRecord_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	ViewRecord_UserRelationshipInputRelationTo: "enum" as const,
	ViewRecord_ItemRelationshipInput:{
		relationTo:"ViewRecord_ItemRelationshipInputRelationTo",
		value:"JSON"
	},
	ViewRecord_ItemRelationshipInputRelationTo: "enum" as const,
	mutationViewRecordUpdateInput:{
		user:"ViewRecordUpdate_UserRelationshipInput",
		item:"ViewRecordUpdate_ItemRelationshipInput"
	},
	ViewRecordUpdate_UserRelationshipInput:{
		relationTo:"ViewRecordUpdate_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	ViewRecordUpdate_UserRelationshipInputRelationTo: "enum" as const,
	ViewRecordUpdate_ItemRelationshipInput:{
		relationTo:"ViewRecordUpdate_ItemRelationshipInputRelationTo",
		value:"JSON"
	},
	ViewRecordUpdate_ItemRelationshipInputRelationTo: "enum" as const,
	mutationEventCategoryInput:{
		region:"EventCategory_region_MutationInput"
	},
	EventCategory_region_MutationInput: "enum" as const,
	mutationEventCategoryUpdateInput:{
		region:"EventCategoryUpdate_region_MutationInput"
	},
	EventCategoryUpdate_region_MutationInput: "enum" as const,
	mutationCommentInput:{
		target:"Comment_TargetRelationshipInput",
		parent:"Comment_ParentRelationshipInput"
	},
	Comment_TargetRelationshipInput:{
		relationTo:"Comment_TargetRelationshipInputRelationTo",
		value:"JSON"
	},
	Comment_TargetRelationshipInputRelationTo: "enum" as const,
	Comment_ParentRelationshipInput:{
		relationTo:"Comment_ParentRelationshipInputRelationTo",
		value:"JSON"
	},
	Comment_ParentRelationshipInputRelationTo: "enum" as const,
	mutationCommentUpdateInput:{
		target:"CommentUpdate_TargetRelationshipInput",
		parent:"CommentUpdate_ParentRelationshipInput"
	},
	CommentUpdate_TargetRelationshipInput:{
		relationTo:"CommentUpdate_TargetRelationshipInputRelationTo",
		value:"JSON"
	},
	CommentUpdate_TargetRelationshipInputRelationTo: "enum" as const,
	CommentUpdate_ParentRelationshipInput:{
		relationTo:"CommentUpdate_ParentRelationshipInputRelationTo",
		value:"JSON"
	},
	CommentUpdate_ParentRelationshipInputRelationTo: "enum" as const,
	mutationEventCalendarInput:{
		region:"EventCalendar_region_MutationInput"
	},
	EventCalendar_region_MutationInput: "enum" as const,
	mutationEventCalendarUpdateInput:{
		region:"EventCalendarUpdate_region_MutationInput"
	},
	EventCalendarUpdate_region_MutationInput: "enum" as const,
	mutationSubscriptionInput:{
		user:"Subscription_UserRelationshipInput",
		organiser:"Subscription_OrganiserRelationshipInput"
	},
	Subscription_UserRelationshipInput:{
		relationTo:"Subscription_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	Subscription_UserRelationshipInputRelationTo: "enum" as const,
	Subscription_OrganiserRelationshipInput:{
		relationTo:"Subscription_OrganiserRelationshipInputRelationTo",
		value:"JSON"
	},
	Subscription_OrganiserRelationshipInputRelationTo: "enum" as const,
	mutationSubscriptionUpdateInput:{
		user:"SubscriptionUpdate_UserRelationshipInput",
		organiser:"SubscriptionUpdate_OrganiserRelationshipInput"
	},
	SubscriptionUpdate_UserRelationshipInput:{
		relationTo:"SubscriptionUpdate_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	SubscriptionUpdate_UserRelationshipInputRelationTo: "enum" as const,
	SubscriptionUpdate_OrganiserRelationshipInput:{
		relationTo:"SubscriptionUpdate_OrganiserRelationshipInputRelationTo",
		value:"JSON"
	},
	SubscriptionUpdate_OrganiserRelationshipInputRelationTo: "enum" as const,
	mutationEditorChoiceInput:{
		list:"mutationEditorChoice_ListInput",
		region:"EditorChoice_region_MutationInput"
	},
	mutationEditorChoice_ListInput:{

	},
	EditorChoice_region_MutationInput: "enum" as const,
	mutationEditorChoiceUpdateInput:{
		list:"mutationEditorChoiceUpdate_ListInput",
		region:"EditorChoiceUpdate_region_MutationInput"
	},
	mutationEditorChoiceUpdate_ListInput:{

	},
	EditorChoiceUpdate_region_MutationInput: "enum" as const,
	mutationBannerSectionInput:{
		banners:"mutationBannerSection_BannersInput"
	},
	mutationBannerSection_BannersInput:{
		region:"BannerSection_Banners_region_MutationInput"
	},
	BannerSection_Banners_region_MutationInput: "enum" as const,
	mutationBannerSectionUpdateInput:{
		banners:"mutationBannerSectionUpdate_BannersInput"
	},
	mutationBannerSectionUpdate_BannersInput:{
		region:"BannerSectionUpdate_Banners_region_MutationInput"
	},
	BannerSectionUpdate_Banners_region_MutationInput: "enum" as const,
	mutationMacaoBannerSectionInput:{
		banners:"mutationMacaoBannerSection_BannersInput"
	},
	mutationMacaoBannerSection_BannersInput:{
		region:"MacaoBannerSection_Banners_region_MutationInput"
	},
	MacaoBannerSection_Banners_region_MutationInput: "enum" as const,
	mutationMacaoBannerSectionUpdateInput:{
		banners:"mutationMacaoBannerSectionUpdate_BannersInput"
	},
	mutationMacaoBannerSectionUpdate_BannersInput:{
		region:"MacaoBannerSectionUpdate_Banners_region_MutationInput"
	},
	MacaoBannerSectionUpdate_Banners_region_MutationInput: "enum" as const,
	mutationBannerInput:{
		location:"mutationBanner_LocationInput",
		type:"Banner_type_MutationInput",
		status:"Banner_status_MutationInput",
		advertise:"mutationBanner_AdvertiseInput",
		region:"Banner_region_MutationInput"
	},
	mutationBanner_LocationInput:{
		page:"Banner_Location_page_MutationInput"
	},
	Banner_Location_page_MutationInput: "enum" as const,
	Banner_type_MutationInput: "enum" as const,
	Banner_status_MutationInput: "enum" as const,
	mutationBanner_AdvertiseInput:{

	},
	Banner_region_MutationInput: "enum" as const,
	mutationBannerUpdateInput:{
		location:"mutationBannerUpdate_LocationInput",
		type:"BannerUpdate_type_MutationInput",
		status:"BannerUpdate_status_MutationInput",
		advertise:"mutationBannerUpdate_AdvertiseInput",
		region:"BannerUpdate_region_MutationInput"
	},
	mutationBannerUpdate_LocationInput:{
		page:"BannerUpdate_Location_page_MutationInput"
	},
	BannerUpdate_Location_page_MutationInput: "enum" as const,
	BannerUpdate_type_MutationInput: "enum" as const,
	BannerUpdate_status_MutationInput: "enum" as const,
	mutationBannerUpdate_AdvertiseInput:{

	},
	BannerUpdate_region_MutationInput: "enum" as const,
	mutationCampaignInput:{
		creator:"Campaign_CreatorRelationshipInput"
	},
	Campaign_CreatorRelationshipInput:{
		relationTo:"Campaign_CreatorRelationshipInputRelationTo",
		value:"JSON"
	},
	Campaign_CreatorRelationshipInputRelationTo: "enum" as const,
	mutationCampaignUpdateInput:{
		creator:"CampaignUpdate_CreatorRelationshipInput"
	},
	CampaignUpdate_CreatorRelationshipInput:{
		relationTo:"CampaignUpdate_CreatorRelationshipInputRelationTo",
		value:"JSON"
	},
	CampaignUpdate_CreatorRelationshipInputRelationTo: "enum" as const,
	mutationAdvertiseInput:{
		advertise:"mutationAdvertise_AdvertiseInput",
		type:"Advertise_type_MutationInput"
	},
	mutationAdvertise_AdvertiseInput:{
		type:"Advertise_Advertise_type_MutationInput"
	},
	Advertise_Advertise_type_MutationInput: "enum" as const,
	Advertise_type_MutationInput: "enum" as const,
	mutationAdvertiseUpdateInput:{
		advertise:"mutationAdvertiseUpdate_AdvertiseInput",
		type:"AdvertiseUpdate_type_MutationInput"
	},
	mutationAdvertiseUpdate_AdvertiseInput:{
		type:"AdvertiseUpdate_Advertise_type_MutationInput"
	},
	AdvertiseUpdate_Advertise_type_MutationInput: "enum" as const,
	AdvertiseUpdate_type_MutationInput: "enum" as const,
	mutationDeviceInput:{
		user:"Device_UserRelationshipInput"
	},
	Device_UserRelationshipInput:{
		relationTo:"Device_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	Device_UserRelationshipInputRelationTo: "enum" as const,
	mutationDeviceUpdateInput:{
		user:"DeviceUpdate_UserRelationshipInput"
	},
	DeviceUpdate_UserRelationshipInput:{
		relationTo:"DeviceUpdate_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	DeviceUpdate_UserRelationshipInputRelationTo: "enum" as const,
	mutationNotificationInput:{
		type:"Notification_type_MutationInput",
		meta:"Notification_MetaRelationshipInput"
	},
	Notification_type_MutationInput: "enum" as const,
	Notification_MetaRelationshipInput:{
		relationTo:"Notification_MetaRelationshipInputRelationTo",
		value:"JSON"
	},
	Notification_MetaRelationshipInputRelationTo: "enum" as const,
	mutationNotificationUpdateInput:{
		type:"NotificationUpdate_type_MutationInput",
		meta:"NotificationUpdate_MetaRelationshipInput"
	},
	NotificationUpdate_type_MutationInput: "enum" as const,
	NotificationUpdate_MetaRelationshipInput:{
		relationTo:"NotificationUpdate_MetaRelationshipInputRelationTo",
		value:"JSON"
	},
	NotificationUpdate_MetaRelationshipInputRelationTo: "enum" as const,
	mutationNewsletterInput:{
		region:"Newsletter_region_MutationInput"
	},
	Newsletter_region_MutationInput: "enum" as const,
	mutationNewsletterUpdateInput:{
		region:"NewsletterUpdate_region_MutationInput"
	},
	NewsletterUpdate_region_MutationInput: "enum" as const,
	mutationRedirectSettingInput:{

	},
	mutationRedirectSettingUpdateInput:{

	},
	mutationStaticInput:{
		content:"JSON",
		region:"Static_region_MutationInput"
	},
	Static_region_MutationInput: "enum" as const,
	mutationStaticUpdateInput:{
		content:"JSON",
		region:"StaticUpdate_region_MutationInput"
	},
	StaticUpdate_region_MutationInput: "enum" as const,
	mutationCriteriaSettingInput:{
		criteria:"mutationCriteriaSetting_CriteriaInput"
	},
	mutationCriteriaSetting_CriteriaInput:{
		region:"CriteriaSetting_Criteria_region_MutationInput"
	},
	CriteriaSetting_Criteria_region_MutationInput: "enum" as const,
	mutationCriteriaSettingUpdateInput:{
		criteria:"mutationCriteriaSettingUpdate_CriteriaInput"
	},
	mutationCriteriaSettingUpdate_CriteriaInput:{
		region:"CriteriaSettingUpdate_Criteria_region_MutationInput"
	},
	CriteriaSettingUpdate_Criteria_region_MutationInput: "enum" as const,
	mutationOrganiserSettingInput:{
		region:"OrganiserSetting_region_MutationInput"
	},
	OrganiserSetting_region_MutationInput: "enum" as const,
	mutationOrganiserSettingUpdateInput:{
		region:"OrganiserSettingUpdate_region_MutationInput"
	},
	OrganiserSettingUpdate_region_MutationInput: "enum" as const,
	mutationPostSettingInput:{
		region:"PostSetting_region_MutationInput"
	},
	PostSetting_region_MutationInput: "enum" as const,
	mutationPostSettingUpdateInput:{
		region:"PostSettingUpdate_region_MutationInput"
	},
	PostSettingUpdate_region_MutationInput: "enum" as const,
	mutationHomepageSettingInput:{
		region:"HomepageSetting_region_MutationInput",
		sections:"JSON"
	},
	HomepageSetting_region_MutationInput: "enum" as const,
	mutationHomepageSettingUpdateInput:{
		region:"HomepageSettingUpdate_region_MutationInput",
		sections:"JSON"
	},
	HomepageSettingUpdate_region_MutationInput: "enum" as const,
	mutationEventViewInput:{

	},
	mutationEventViewUpdateInput:{

	},
	mutationPostViewInput:{

	},
	mutationPostViewUpdateInput:{

	},
	mutationPayloadPreferenceInput:{
		user:"PayloadPreference_UserRelationshipInput",
		value:"JSON"
	},
	PayloadPreference_UserRelationshipInput:{
		relationTo:"PayloadPreference_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	PayloadPreference_UserRelationshipInputRelationTo: "enum" as const,
	mutationPayloadPreferenceUpdateInput:{
		user:"PayloadPreferenceUpdate_UserRelationshipInput",
		value:"JSON"
	},
	PayloadPreferenceUpdate_UserRelationshipInput:{
		relationTo:"PayloadPreferenceUpdate_UserRelationshipInputRelationTo",
		value:"JSON"
	},
	PayloadPreferenceUpdate_UserRelationshipInputRelationTo: "enum" as const
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		User:"User",
		Users:"Users",
		countUsers:"countUsers",
		docAccessUser:"usersDocAccess",
		meUser:"usersMe",
		initializedUser:"Boolean",
		BlogCategory:"BlogCategory",
		BlogCategories:"BlogCategories",
		countBlogCategories:"countBlogCategories",
		docAccessBlogCategory:"blog_categoriesDocAccess",
		Tag:"Tag",
		Tags:"Tags",
		countTags:"countTags",
		docAccessTag:"tagsDocAccess",
		SpecialTag:"SpecialTag",
		SpecialTags:"SpecialTags",
		countSpecialTags:"countSpecialTags",
		docAccessSpecialTag:"specialTagsDocAccess",
		Date:"Date",
		Dates:"Dates",
		countDates:"countDates",
		docAccessDate:"dateDocAccess",
		District:"District",
		Districts:"Districts",
		countDistricts:"countDistricts",
		docAccessDistrict:"districtsDocAccess",
		Audience:"Audience",
		Audiences:"Audiences",
		countAudiences:"countAudiences",
		docAccessAudience:"audienceDocAccess",
		Event:"Event",
		Events:"Events",
		countEvents:"countEvents",
		docAccessEvent:"eventsDocAccess",
		Location:"Location",
		Locations:"Locations",
		countLocations:"countLocations",
		docAccessLocation:"locationsDocAccess",
		Post:"Post",
		Posts:"Posts",
		countPosts:"countPosts",
		docAccessPost:"postsDocAccess",
		PrivateMedia:"PrivateMedia",
		PrivateMedias:"PrivateMedias",
		countPrivateMedias:"countPrivateMedias",
		docAccessPrivateMedia:"private_mediaDocAccess",
		Media:"Media",
		allMedia:"allMedia",
		countallMedia:"countallMedia",
		docAccessMedia:"mediaDocAccess",
		BackupMedia:"BackupMedia",
		BackupMedias:"BackupMedias",
		countBackupMedias:"countBackupMedias",
		docAccessBackupMedia:"backupMediaDocAccess",
		EventRanking:"EventRanking",
		EventRankings:"EventRankings",
		countEventRankings:"countEventRankings",
		docAccessEventRanking:"ranking_eventsDocAccess",
		LocationRanking:"LocationRanking",
		LocationRankings:"LocationRankings",
		countLocationRankings:"countLocationRankings",
		docAccessLocationRanking:"ranking_locationsDocAccess",
		AccountSuspendRequest:"AccountSuspendRequest",
		AccountSuspendRequests:"AccountSuspendRequests",
		countAccountSuspendRequests:"countAccountSuspendRequests",
		docAccessAccountSuspendRequest:"account_suspend_requestsDocAccess",
		Bookmark:"Bookmark",
		Bookmarks:"Bookmarks",
		countBookmarks:"countBookmarks",
		docAccessBookmark:"bookmarksDocAccess",
		ViewRecord:"ViewRecord",
		ViewRecords:"ViewRecords",
		countViewRecords:"countViewRecords",
		docAccessViewRecord:"view_recordsDocAccess",
		EventCategory:"EventCategory",
		EventCategories:"EventCategories",
		countEventCategories:"countEventCategories",
		docAccessEventCategory:"categoryDocAccess",
		Comment:"Comment",
		Comments:"Comments",
		countComments:"countComments",
		docAccessComment:"commentsDocAccess",
		EventCalendar:"EventCalendar",
		EventCalendars:"EventCalendars",
		countEventCalendars:"countEventCalendars",
		docAccessEventCalendar:"event_calendarDocAccess",
		Subscription:"Subscription",
		Subscriptions:"Subscriptions",
		countSubscriptions:"countSubscriptions",
		docAccessSubscription:"subscriptionsDocAccess",
		EditorChoice:"EditorChoice",
		EditorChoices:"EditorChoices",
		countEditorChoices:"countEditorChoices",
		docAccessEditorChoice:"editor_choicesDocAccess",
		BannerSection:"BannerSection",
		BannerSections:"BannerSections",
		countBannerSections:"countBannerSections",
		docAccessBannerSection:"banner_sectionDocAccess",
		MacaoBannerSection:"MacaoBannerSection",
		MacaoBannerSections:"MacaoBannerSections",
		countMacaoBannerSections:"countMacaoBannerSections",
		docAccessMacaoBannerSection:"macao_banner_sectionDocAccess",
		Banner:"Banner",
		Banners:"Banners",
		countBanners:"countBanners",
		docAccessBanner:"bannersDocAccess",
		Campaign:"Campaign",
		Campaigns:"Campaigns",
		countCampaigns:"countCampaigns",
		docAccessCampaign:"campaignsDocAccess",
		Advertise:"Advertise",
		Advertises:"Advertises",
		countAdvertises:"countAdvertises",
		docAccessAdvertise:"advertisesDocAccess",
		Device:"Device",
		Devices:"Devices",
		countDevices:"countDevices",
		docAccessDevice:"devicesDocAccess",
		Notification:"Notification",
		Notifications:"Notifications",
		countNotifications:"countNotifications",
		docAccessNotification:"notificationsDocAccess",
		Newsletter:"Newsletter",
		Newsletters:"Newsletters",
		countNewsletters:"countNewsletters",
		docAccessNewsletter:"newsletterDocAccess",
		RedirectSetting:"RedirectSetting",
		RedirectSettings:"RedirectSettings",
		countRedirectSettings:"countRedirectSettings",
		docAccessRedirectSetting:"redirectSettingDocAccess",
		Static:"Static",
		Statics:"Statics",
		countStatics:"countStatics",
		docAccessStatic:"staticsDocAccess",
		CriteriaSetting:"CriteriaSetting",
		CriteriaSettings:"CriteriaSettings",
		countCriteriaSettings:"countCriteriaSettings",
		docAccessCriteriaSetting:"criteria_settingDocAccess",
		OrganiserSetting:"OrganiserSetting",
		OrganiserSettings:"OrganiserSettings",
		countOrganiserSettings:"countOrganiserSettings",
		docAccessOrganiserSetting:"organiserSettingDocAccess",
		PostSetting:"PostSetting",
		PostSettings:"PostSettings",
		countPostSettings:"countPostSettings",
		docAccessPostSetting:"post_settingDocAccess",
		HomepageSetting:"HomepageSetting",
		HomepageSettings:"HomepageSettings",
		countHomepageSettings:"countHomepageSettings",
		docAccessHomepageSetting:"homepageSettingDocAccess",
		EventView:"EventView",
		EventViews:"EventViews",
		countEventViews:"countEventViews",
		docAccessEventView:"event_viewsDocAccess",
		PostView:"PostView",
		PostViews:"PostViews",
		countPostViews:"countPostViews",
		docAccessPostView:"post_viewsDocAccess",
		PayloadPreference:"PayloadPreference",
		PayloadPreferences:"PayloadPreferences",
		countPayloadPreferences:"countPayloadPreferences",
		docAccessPayloadPreference:"payload_preferencesDocAccess",
		Access:"Access",
		GetEvents:"Events",
		GetFilterEvents:"Events",
		GetFilterPastEvents:"Events",
		GetEventMaxUsedTag:"GetEventMaxUsedTag",
		BookmarkEvents:"Events",
		GetBookmarks:"Bookmarks",
		BookmarkItems:"Bookmarks",
		ViewedEvents:"Events",
		NonFeaturedPosts:"Posts",
		CheckEmailUniqueness:"Boolean",
		GetOrganisers:"Users",
		GetOrganiser:"User",
		GetSubscriptions:"Subscriptions",
		GetFollowed:"Subscriptions",
		GetComments:"Comments",
		CommentsCount:"Comments",
		GetBanners:"Banners",
		GetBannerSection:"Banners",
		GetNotifications:"Notifications",
		GetCriteriaSettings:"CriteriaSettings",
		GetFilterLocations:"Locations",
		GetContainEventTagLocations:"Locations",
		GetEventRankings:"EventRankings"
	},
	User:{
		id:"String",
		region:"User_region",
		name:"String",
		displayEmail:"String",
		legacyUsername:"String",
		roles:"User_roles",
		member:"User_Member",
		organiser:"User_Organiser",
		emailVerified:"Boolean",
		sso:"User_Sso",
		oldPassword:"String",
		status:"User_status",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		email:"EmailAddress",
		resetPasswordToken:"String",
		resetPasswordExpiration:"DateTime",
		salt:"String",
		hash:"String",
		loginAttempts:"Float",
		lockUntil:"DateTime",
		password:"String"
	},
	User_Member:{
		region:"User_Member_region",
		language:"User_Member_language",
		phone:"String",
		avatar:"Media",
		birthday:"DateTime",
		gender:"User_Member_gender",
		categories:"EventCategory"
	},
	Media:{
		id:"String",
		alt:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		url:"String",
		filename:"String",
		mimeType:"String",
		filesize:"Float",
		width:"Float",
		height:"Float",
		focalX:"Float",
		focalY:"Float",
		sizes:"Media_Sizes"
	},
	DateTime: `scalar.DateTime` as const,
	Media_Sizes:{
		small:"Media_Sizes_Small",
		medium:"Media_Sizes_Medium",
		large:"Media_Sizes_Large",
		small_jpeg:"Media_Sizes_Small_jpeg",
		medium_jpeg:"Media_Sizes_Medium_jpeg",
		large_jpeg:"Media_Sizes_Large_jpeg"
	},
	Media_Sizes_Small:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	Media_Sizes_Medium:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	Media_Sizes_Large:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	Media_Sizes_Small_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	Media_Sizes_Medium_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	Media_Sizes_Large_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	EventCategory:{
		id:"String",
		name:"String",
		value:"String",
		tags:"Tag",
		icon:"Media",
		region:"EventCategory_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Tag:{
		id:"String",
		name:"String",
		value:"String",
		region:"Tag_region",
		count:"Float",
		skipCountCheck:"Boolean",
		removeNextCron:"Boolean"
	},
	User_Organiser:{
		logo:"Media",
		banner:"Media",
		description:"String",
		foundedIn:"DateTime",
		address:"String",
		website:"String",
		facebook:"String",
		instagram:"String",
		language:"User_Organiser_language",
		phone:"String",
		followers:"Float"
	},
	User_Sso:{
		googleId:"String",
		facebookId:"String",
		appleId:"String"
	},
	EmailAddress: `scalar.EmailAddress` as const,
	Users:{
		docs:"User",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	JSON: `scalar.JSON` as const,
	countUsers:{
		totalDocs:"Int"
	},
	usersDocAccess:{
		fields:"UsersDocAccessFields",
		create:"UsersCreateDocAccess",
		read:"UsersReadDocAccess",
		update:"UsersUpdateDocAccess",
		delete:"UsersDeleteDocAccess",
		unlock:"UsersUnlockDocAccess"
	},
	UsersDocAccessFields:{
		region:"UsersDocAccessFields_region",
		name:"UsersDocAccessFields_name",
		displayEmail:"UsersDocAccessFields_displayEmail",
		legacyUsername:"UsersDocAccessFields_legacyUsername",
		roles:"UsersDocAccessFields_roles",
		member:"UsersDocAccessFields_member",
		organiser:"UsersDocAccessFields_organiser",
		emailVerified:"UsersDocAccessFields_emailVerified",
		sso:"UsersDocAccessFields_sso",
		oldPassword:"UsersDocAccessFields_oldPassword",
		status:"UsersDocAccessFields_status",
		updatedAt:"UsersDocAccessFields_updatedAt",
		createdAt:"UsersDocAccessFields_createdAt",
		email:"UsersDocAccessFields_email",
		password:"UsersDocAccessFields_password"
	},
	UsersDocAccessFields_region:{
		create:"UsersDocAccessFields_region_Create",
		read:"UsersDocAccessFields_region_Read",
		update:"UsersDocAccessFields_region_Update",
		delete:"UsersDocAccessFields_region_Delete"
	},
	UsersDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_name:{
		create:"UsersDocAccessFields_name_Create",
		read:"UsersDocAccessFields_name_Read",
		update:"UsersDocAccessFields_name_Update",
		delete:"UsersDocAccessFields_name_Delete"
	},
	UsersDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_displayEmail:{
		create:"UsersDocAccessFields_displayEmail_Create",
		read:"UsersDocAccessFields_displayEmail_Read",
		update:"UsersDocAccessFields_displayEmail_Update",
		delete:"UsersDocAccessFields_displayEmail_Delete"
	},
	UsersDocAccessFields_displayEmail_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_displayEmail_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_displayEmail_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_displayEmail_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_legacyUsername:{
		create:"UsersDocAccessFields_legacyUsername_Create",
		read:"UsersDocAccessFields_legacyUsername_Read",
		update:"UsersDocAccessFields_legacyUsername_Update",
		delete:"UsersDocAccessFields_legacyUsername_Delete"
	},
	UsersDocAccessFields_legacyUsername_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_legacyUsername_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_legacyUsername_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_legacyUsername_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_roles:{
		create:"UsersDocAccessFields_roles_Create",
		read:"UsersDocAccessFields_roles_Read",
		update:"UsersDocAccessFields_roles_Update",
		delete:"UsersDocAccessFields_roles_Delete"
	},
	UsersDocAccessFields_roles_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_roles_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_roles_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_roles_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member:{
		create:"UsersDocAccessFields_member_Create",
		read:"UsersDocAccessFields_member_Read",
		update:"UsersDocAccessFields_member_Update",
		delete:"UsersDocAccessFields_member_Delete",
		fields:"UsersDocAccessFields_member_Fields"
	},
	UsersDocAccessFields_member_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_Fields:{
		region:"UsersDocAccessFields_member_region",
		language:"UsersDocAccessFields_member_language",
		phone:"UsersDocAccessFields_member_phone",
		avatar:"UsersDocAccessFields_member_avatar",
		birthday:"UsersDocAccessFields_member_birthday",
		gender:"UsersDocAccessFields_member_gender",
		categories:"UsersDocAccessFields_member_categories"
	},
	UsersDocAccessFields_member_region:{
		create:"UsersDocAccessFields_member_region_Create",
		read:"UsersDocAccessFields_member_region_Read",
		update:"UsersDocAccessFields_member_region_Update",
		delete:"UsersDocAccessFields_member_region_Delete"
	},
	UsersDocAccessFields_member_region_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_region_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_region_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_region_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_language:{
		create:"UsersDocAccessFields_member_language_Create",
		read:"UsersDocAccessFields_member_language_Read",
		update:"UsersDocAccessFields_member_language_Update",
		delete:"UsersDocAccessFields_member_language_Delete"
	},
	UsersDocAccessFields_member_language_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_language_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_language_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_language_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_phone:{
		create:"UsersDocAccessFields_member_phone_Create",
		read:"UsersDocAccessFields_member_phone_Read",
		update:"UsersDocAccessFields_member_phone_Update",
		delete:"UsersDocAccessFields_member_phone_Delete"
	},
	UsersDocAccessFields_member_phone_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_phone_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_phone_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_phone_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_avatar:{
		create:"UsersDocAccessFields_member_avatar_Create",
		read:"UsersDocAccessFields_member_avatar_Read",
		update:"UsersDocAccessFields_member_avatar_Update",
		delete:"UsersDocAccessFields_member_avatar_Delete"
	},
	UsersDocAccessFields_member_avatar_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_avatar_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_avatar_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_avatar_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_birthday:{
		create:"UsersDocAccessFields_member_birthday_Create",
		read:"UsersDocAccessFields_member_birthday_Read",
		update:"UsersDocAccessFields_member_birthday_Update",
		delete:"UsersDocAccessFields_member_birthday_Delete"
	},
	UsersDocAccessFields_member_birthday_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_birthday_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_birthday_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_birthday_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_gender:{
		create:"UsersDocAccessFields_member_gender_Create",
		read:"UsersDocAccessFields_member_gender_Read",
		update:"UsersDocAccessFields_member_gender_Update",
		delete:"UsersDocAccessFields_member_gender_Delete"
	},
	UsersDocAccessFields_member_gender_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_gender_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_gender_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_gender_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_categories:{
		create:"UsersDocAccessFields_member_categories_Create",
		read:"UsersDocAccessFields_member_categories_Read",
		update:"UsersDocAccessFields_member_categories_Update",
		delete:"UsersDocAccessFields_member_categories_Delete"
	},
	UsersDocAccessFields_member_categories_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_categories_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_categories_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_member_categories_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser:{
		create:"UsersDocAccessFields_organiser_Create",
		read:"UsersDocAccessFields_organiser_Read",
		update:"UsersDocAccessFields_organiser_Update",
		delete:"UsersDocAccessFields_organiser_Delete",
		fields:"UsersDocAccessFields_organiser_Fields"
	},
	UsersDocAccessFields_organiser_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_Fields:{
		logo:"UsersDocAccessFields_organiser_logo",
		banner:"UsersDocAccessFields_organiser_banner",
		description:"UsersDocAccessFields_organiser_description",
		foundedIn:"UsersDocAccessFields_organiser_foundedIn",
		address:"UsersDocAccessFields_organiser_address",
		website:"UsersDocAccessFields_organiser_website",
		facebook:"UsersDocAccessFields_organiser_facebook",
		instagram:"UsersDocAccessFields_organiser_instagram",
		language:"UsersDocAccessFields_organiser_language",
		phone:"UsersDocAccessFields_organiser_phone",
		followers:"UsersDocAccessFields_organiser_followers"
	},
	UsersDocAccessFields_organiser_logo:{
		create:"UsersDocAccessFields_organiser_logo_Create",
		read:"UsersDocAccessFields_organiser_logo_Read",
		update:"UsersDocAccessFields_organiser_logo_Update",
		delete:"UsersDocAccessFields_organiser_logo_Delete"
	},
	UsersDocAccessFields_organiser_logo_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_logo_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_logo_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_logo_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_banner:{
		create:"UsersDocAccessFields_organiser_banner_Create",
		read:"UsersDocAccessFields_organiser_banner_Read",
		update:"UsersDocAccessFields_organiser_banner_Update",
		delete:"UsersDocAccessFields_organiser_banner_Delete"
	},
	UsersDocAccessFields_organiser_banner_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_banner_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_banner_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_banner_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_description:{
		create:"UsersDocAccessFields_organiser_description_Create",
		read:"UsersDocAccessFields_organiser_description_Read",
		update:"UsersDocAccessFields_organiser_description_Update",
		delete:"UsersDocAccessFields_organiser_description_Delete"
	},
	UsersDocAccessFields_organiser_description_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_description_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_description_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_description_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_foundedIn:{
		create:"UsersDocAccessFields_organiser_foundedIn_Create",
		read:"UsersDocAccessFields_organiser_foundedIn_Read",
		update:"UsersDocAccessFields_organiser_foundedIn_Update",
		delete:"UsersDocAccessFields_organiser_foundedIn_Delete"
	},
	UsersDocAccessFields_organiser_foundedIn_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_foundedIn_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_foundedIn_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_foundedIn_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_address:{
		create:"UsersDocAccessFields_organiser_address_Create",
		read:"UsersDocAccessFields_organiser_address_Read",
		update:"UsersDocAccessFields_organiser_address_Update",
		delete:"UsersDocAccessFields_organiser_address_Delete"
	},
	UsersDocAccessFields_organiser_address_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_address_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_address_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_address_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_website:{
		create:"UsersDocAccessFields_organiser_website_Create",
		read:"UsersDocAccessFields_organiser_website_Read",
		update:"UsersDocAccessFields_organiser_website_Update",
		delete:"UsersDocAccessFields_organiser_website_Delete"
	},
	UsersDocAccessFields_organiser_website_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_website_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_website_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_website_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_facebook:{
		create:"UsersDocAccessFields_organiser_facebook_Create",
		read:"UsersDocAccessFields_organiser_facebook_Read",
		update:"UsersDocAccessFields_organiser_facebook_Update",
		delete:"UsersDocAccessFields_organiser_facebook_Delete"
	},
	UsersDocAccessFields_organiser_facebook_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_facebook_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_facebook_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_facebook_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_instagram:{
		create:"UsersDocAccessFields_organiser_instagram_Create",
		read:"UsersDocAccessFields_organiser_instagram_Read",
		update:"UsersDocAccessFields_organiser_instagram_Update",
		delete:"UsersDocAccessFields_organiser_instagram_Delete"
	},
	UsersDocAccessFields_organiser_instagram_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_instagram_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_instagram_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_instagram_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_language:{
		create:"UsersDocAccessFields_organiser_language_Create",
		read:"UsersDocAccessFields_organiser_language_Read",
		update:"UsersDocAccessFields_organiser_language_Update",
		delete:"UsersDocAccessFields_organiser_language_Delete"
	},
	UsersDocAccessFields_organiser_language_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_language_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_language_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_language_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_phone:{
		create:"UsersDocAccessFields_organiser_phone_Create",
		read:"UsersDocAccessFields_organiser_phone_Read",
		update:"UsersDocAccessFields_organiser_phone_Update",
		delete:"UsersDocAccessFields_organiser_phone_Delete"
	},
	UsersDocAccessFields_organiser_phone_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_phone_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_phone_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_phone_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_followers:{
		create:"UsersDocAccessFields_organiser_followers_Create",
		read:"UsersDocAccessFields_organiser_followers_Read",
		update:"UsersDocAccessFields_organiser_followers_Update",
		delete:"UsersDocAccessFields_organiser_followers_Delete"
	},
	UsersDocAccessFields_organiser_followers_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_followers_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_followers_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_organiser_followers_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_emailVerified:{
		create:"UsersDocAccessFields_emailVerified_Create",
		read:"UsersDocAccessFields_emailVerified_Read",
		update:"UsersDocAccessFields_emailVerified_Update",
		delete:"UsersDocAccessFields_emailVerified_Delete"
	},
	UsersDocAccessFields_emailVerified_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_emailVerified_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_emailVerified_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_emailVerified_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso:{
		create:"UsersDocAccessFields_sso_Create",
		read:"UsersDocAccessFields_sso_Read",
		update:"UsersDocAccessFields_sso_Update",
		delete:"UsersDocAccessFields_sso_Delete",
		fields:"UsersDocAccessFields_sso_Fields"
	},
	UsersDocAccessFields_sso_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_Fields:{
		googleId:"UsersDocAccessFields_sso_googleId",
		facebookId:"UsersDocAccessFields_sso_facebookId",
		appleId:"UsersDocAccessFields_sso_appleId"
	},
	UsersDocAccessFields_sso_googleId:{
		create:"UsersDocAccessFields_sso_googleId_Create",
		read:"UsersDocAccessFields_sso_googleId_Read",
		update:"UsersDocAccessFields_sso_googleId_Update",
		delete:"UsersDocAccessFields_sso_googleId_Delete"
	},
	UsersDocAccessFields_sso_googleId_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_googleId_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_googleId_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_googleId_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_facebookId:{
		create:"UsersDocAccessFields_sso_facebookId_Create",
		read:"UsersDocAccessFields_sso_facebookId_Read",
		update:"UsersDocAccessFields_sso_facebookId_Update",
		delete:"UsersDocAccessFields_sso_facebookId_Delete"
	},
	UsersDocAccessFields_sso_facebookId_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_facebookId_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_facebookId_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_facebookId_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_appleId:{
		create:"UsersDocAccessFields_sso_appleId_Create",
		read:"UsersDocAccessFields_sso_appleId_Read",
		update:"UsersDocAccessFields_sso_appleId_Update",
		delete:"UsersDocAccessFields_sso_appleId_Delete"
	},
	UsersDocAccessFields_sso_appleId_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_appleId_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_appleId_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_sso_appleId_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_oldPassword:{
		create:"UsersDocAccessFields_oldPassword_Create",
		read:"UsersDocAccessFields_oldPassword_Read",
		update:"UsersDocAccessFields_oldPassword_Update",
		delete:"UsersDocAccessFields_oldPassword_Delete"
	},
	UsersDocAccessFields_oldPassword_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_oldPassword_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_oldPassword_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_oldPassword_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_status:{
		create:"UsersDocAccessFields_status_Create",
		read:"UsersDocAccessFields_status_Read",
		update:"UsersDocAccessFields_status_Update",
		delete:"UsersDocAccessFields_status_Delete"
	},
	UsersDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_updatedAt:{
		create:"UsersDocAccessFields_updatedAt_Create",
		read:"UsersDocAccessFields_updatedAt_Read",
		update:"UsersDocAccessFields_updatedAt_Update",
		delete:"UsersDocAccessFields_updatedAt_Delete"
	},
	UsersDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_createdAt:{
		create:"UsersDocAccessFields_createdAt_Create",
		read:"UsersDocAccessFields_createdAt_Read",
		update:"UsersDocAccessFields_createdAt_Update",
		delete:"UsersDocAccessFields_createdAt_Delete"
	},
	UsersDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_email:{
		create:"UsersDocAccessFields_email_Create",
		read:"UsersDocAccessFields_email_Read",
		update:"UsersDocAccessFields_email_Update",
		delete:"UsersDocAccessFields_email_Delete"
	},
	UsersDocAccessFields_email_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_email_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_email_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_email_Delete:{
		permission:"Boolean"
	},
	UsersDocAccessFields_password:{
		create:"UsersDocAccessFields_password_Create",
		read:"UsersDocAccessFields_password_Read",
		update:"UsersDocAccessFields_password_Update",
		delete:"UsersDocAccessFields_password_Delete"
	},
	UsersDocAccessFields_password_Create:{
		permission:"Boolean"
	},
	UsersDocAccessFields_password_Read:{
		permission:"Boolean"
	},
	UsersDocAccessFields_password_Update:{
		permission:"Boolean"
	},
	UsersDocAccessFields_password_Delete:{
		permission:"Boolean"
	},
	UsersCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	JSONObject: `scalar.JSONObject` as const,
	UsersReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersUnlockDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	usersMe:{
		collection:"String",
		exp:"Int",
		strategy:"String",
		token:"String",
		user:"User"
	},
	BlogCategory:{
		id:"String",
		name:"String",
		value:"String",
		icon:"Media",
		region:"BlogCategory_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	BlogCategories:{
		docs:"BlogCategory",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countBlogCategories:{
		totalDocs:"Int"
	},
	blog_categoriesDocAccess:{
		fields:"BlogCategoriesDocAccessFields",
		create:"BlogCategoriesCreateDocAccess",
		read:"BlogCategoriesReadDocAccess",
		update:"BlogCategoriesUpdateDocAccess",
		delete:"BlogCategoriesDeleteDocAccess"
	},
	BlogCategoriesDocAccessFields:{
		name:"BlogCategoriesDocAccessFields_name",
		value:"BlogCategoriesDocAccessFields_value",
		icon:"BlogCategoriesDocAccessFields_icon",
		region:"BlogCategoriesDocAccessFields_region",
		updatedAt:"BlogCategoriesDocAccessFields_updatedAt",
		createdAt:"BlogCategoriesDocAccessFields_createdAt"
	},
	BlogCategoriesDocAccessFields_name:{
		create:"BlogCategoriesDocAccessFields_name_Create",
		read:"BlogCategoriesDocAccessFields_name_Read",
		update:"BlogCategoriesDocAccessFields_name_Update",
		delete:"BlogCategoriesDocAccessFields_name_Delete"
	},
	BlogCategoriesDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_value:{
		create:"BlogCategoriesDocAccessFields_value_Create",
		read:"BlogCategoriesDocAccessFields_value_Read",
		update:"BlogCategoriesDocAccessFields_value_Update",
		delete:"BlogCategoriesDocAccessFields_value_Delete"
	},
	BlogCategoriesDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_icon:{
		create:"BlogCategoriesDocAccessFields_icon_Create",
		read:"BlogCategoriesDocAccessFields_icon_Read",
		update:"BlogCategoriesDocAccessFields_icon_Update",
		delete:"BlogCategoriesDocAccessFields_icon_Delete"
	},
	BlogCategoriesDocAccessFields_icon_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_icon_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_icon_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_icon_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_region:{
		create:"BlogCategoriesDocAccessFields_region_Create",
		read:"BlogCategoriesDocAccessFields_region_Read",
		update:"BlogCategoriesDocAccessFields_region_Update",
		delete:"BlogCategoriesDocAccessFields_region_Delete"
	},
	BlogCategoriesDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_updatedAt:{
		create:"BlogCategoriesDocAccessFields_updatedAt_Create",
		read:"BlogCategoriesDocAccessFields_updatedAt_Read",
		update:"BlogCategoriesDocAccessFields_updatedAt_Update",
		delete:"BlogCategoriesDocAccessFields_updatedAt_Delete"
	},
	BlogCategoriesDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_createdAt:{
		create:"BlogCategoriesDocAccessFields_createdAt_Create",
		read:"BlogCategoriesDocAccessFields_createdAt_Read",
		update:"BlogCategoriesDocAccessFields_createdAt_Update",
		delete:"BlogCategoriesDocAccessFields_createdAt_Delete"
	},
	BlogCategoriesDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	BlogCategoriesDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Tags:{
		docs:"Tag",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countTags:{
		totalDocs:"Int"
	},
	tagsDocAccess:{
		fields:"TagsDocAccessFields",
		create:"TagsCreateDocAccess",
		read:"TagsReadDocAccess",
		update:"TagsUpdateDocAccess",
		delete:"TagsDeleteDocAccess"
	},
	TagsDocAccessFields:{
		name:"TagsDocAccessFields_name",
		value:"TagsDocAccessFields_value",
		region:"TagsDocAccessFields_region",
		count:"TagsDocAccessFields_count",
		skipCountCheck:"TagsDocAccessFields_skipCountCheck",
		removeNextCron:"TagsDocAccessFields_removeNextCron"
	},
	TagsDocAccessFields_name:{
		create:"TagsDocAccessFields_name_Create",
		read:"TagsDocAccessFields_name_Read",
		update:"TagsDocAccessFields_name_Update",
		delete:"TagsDocAccessFields_name_Delete"
	},
	TagsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	TagsDocAccessFields_value:{
		create:"TagsDocAccessFields_value_Create",
		read:"TagsDocAccessFields_value_Read",
		update:"TagsDocAccessFields_value_Update",
		delete:"TagsDocAccessFields_value_Delete"
	},
	TagsDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	TagsDocAccessFields_region:{
		create:"TagsDocAccessFields_region_Create",
		read:"TagsDocAccessFields_region_Read",
		update:"TagsDocAccessFields_region_Update",
		delete:"TagsDocAccessFields_region_Delete"
	},
	TagsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	TagsDocAccessFields_count:{
		create:"TagsDocAccessFields_count_Create",
		read:"TagsDocAccessFields_count_Read",
		update:"TagsDocAccessFields_count_Update",
		delete:"TagsDocAccessFields_count_Delete"
	},
	TagsDocAccessFields_count_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_count_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_count_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_count_Delete:{
		permission:"Boolean"
	},
	TagsDocAccessFields_skipCountCheck:{
		create:"TagsDocAccessFields_skipCountCheck_Create",
		read:"TagsDocAccessFields_skipCountCheck_Read",
		update:"TagsDocAccessFields_skipCountCheck_Update",
		delete:"TagsDocAccessFields_skipCountCheck_Delete"
	},
	TagsDocAccessFields_skipCountCheck_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_skipCountCheck_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_skipCountCheck_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_skipCountCheck_Delete:{
		permission:"Boolean"
	},
	TagsDocAccessFields_removeNextCron:{
		create:"TagsDocAccessFields_removeNextCron_Create",
		read:"TagsDocAccessFields_removeNextCron_Read",
		update:"TagsDocAccessFields_removeNextCron_Update",
		delete:"TagsDocAccessFields_removeNextCron_Delete"
	},
	TagsDocAccessFields_removeNextCron_Create:{
		permission:"Boolean"
	},
	TagsDocAccessFields_removeNextCron_Read:{
		permission:"Boolean"
	},
	TagsDocAccessFields_removeNextCron_Update:{
		permission:"Boolean"
	},
	TagsDocAccessFields_removeNextCron_Delete:{
		permission:"Boolean"
	},
	TagsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTag:{
		id:"String",
		name:"String",
		value:"String",
		region:"SpecialTag_region"
	},
	SpecialTags:{
		docs:"SpecialTag",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countSpecialTags:{
		totalDocs:"Int"
	},
	specialTagsDocAccess:{
		fields:"SpecialTagsDocAccessFields",
		create:"SpecialTagsCreateDocAccess",
		read:"SpecialTagsReadDocAccess",
		update:"SpecialTagsUpdateDocAccess",
		delete:"SpecialTagsDeleteDocAccess"
	},
	SpecialTagsDocAccessFields:{
		name:"SpecialTagsDocAccessFields_name",
		value:"SpecialTagsDocAccessFields_value",
		region:"SpecialTagsDocAccessFields_region"
	},
	SpecialTagsDocAccessFields_name:{
		create:"SpecialTagsDocAccessFields_name_Create",
		read:"SpecialTagsDocAccessFields_name_Read",
		update:"SpecialTagsDocAccessFields_name_Update",
		delete:"SpecialTagsDocAccessFields_name_Delete"
	},
	SpecialTagsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_value:{
		create:"SpecialTagsDocAccessFields_value_Create",
		read:"SpecialTagsDocAccessFields_value_Read",
		update:"SpecialTagsDocAccessFields_value_Update",
		delete:"SpecialTagsDocAccessFields_value_Delete"
	},
	SpecialTagsDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_region:{
		create:"SpecialTagsDocAccessFields_region_Create",
		read:"SpecialTagsDocAccessFields_region_Read",
		update:"SpecialTagsDocAccessFields_region_Update",
		delete:"SpecialTagsDocAccessFields_region_Delete"
	},
	SpecialTagsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	SpecialTagsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	SpecialTagsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Date:{
		id:"String",
		name:"String",
		value:"String",
		type:"Date_type",
		start:"DateTime",
		end:"DateTime",
		_title:"String",
		region:"Date_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Dates:{
		docs:"Date",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countDates:{
		totalDocs:"Int"
	},
	dateDocAccess:{
		fields:"DateDocAccessFields",
		create:"DateCreateDocAccess",
		read:"DateReadDocAccess",
		update:"DateUpdateDocAccess",
		delete:"DateDeleteDocAccess"
	},
	DateDocAccessFields:{
		name:"DateDocAccessFields_name",
		value:"DateDocAccessFields_value",
		type:"DateDocAccessFields_type",
		start:"DateDocAccessFields_start",
		end:"DateDocAccessFields_end",
		_title:"DateDocAccessFields__title",
		region:"DateDocAccessFields_region",
		updatedAt:"DateDocAccessFields_updatedAt",
		createdAt:"DateDocAccessFields_createdAt"
	},
	DateDocAccessFields_name:{
		create:"DateDocAccessFields_name_Create",
		read:"DateDocAccessFields_name_Read",
		update:"DateDocAccessFields_name_Update",
		delete:"DateDocAccessFields_name_Delete"
	},
	DateDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_value:{
		create:"DateDocAccessFields_value_Create",
		read:"DateDocAccessFields_value_Read",
		update:"DateDocAccessFields_value_Update",
		delete:"DateDocAccessFields_value_Delete"
	},
	DateDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_type:{
		create:"DateDocAccessFields_type_Create",
		read:"DateDocAccessFields_type_Read",
		update:"DateDocAccessFields_type_Update",
		delete:"DateDocAccessFields_type_Delete"
	},
	DateDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_start:{
		create:"DateDocAccessFields_start_Create",
		read:"DateDocAccessFields_start_Read",
		update:"DateDocAccessFields_start_Update",
		delete:"DateDocAccessFields_start_Delete"
	},
	DateDocAccessFields_start_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_start_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_start_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_start_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_end:{
		create:"DateDocAccessFields_end_Create",
		read:"DateDocAccessFields_end_Read",
		update:"DateDocAccessFields_end_Update",
		delete:"DateDocAccessFields_end_Delete"
	},
	DateDocAccessFields_end_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_end_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_end_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_end_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields__title:{
		create:"DateDocAccessFields__title_Create",
		read:"DateDocAccessFields__title_Read",
		update:"DateDocAccessFields__title_Update",
		delete:"DateDocAccessFields__title_Delete"
	},
	DateDocAccessFields__title_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields__title_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields__title_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields__title_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_region:{
		create:"DateDocAccessFields_region_Create",
		read:"DateDocAccessFields_region_Read",
		update:"DateDocAccessFields_region_Update",
		delete:"DateDocAccessFields_region_Delete"
	},
	DateDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_updatedAt:{
		create:"DateDocAccessFields_updatedAt_Create",
		read:"DateDocAccessFields_updatedAt_Read",
		update:"DateDocAccessFields_updatedAt_Update",
		delete:"DateDocAccessFields_updatedAt_Delete"
	},
	DateDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DateDocAccessFields_createdAt:{
		create:"DateDocAccessFields_createdAt_Create",
		read:"DateDocAccessFields_createdAt_Read",
		update:"DateDocAccessFields_createdAt_Update",
		delete:"DateDocAccessFields_createdAt_Delete"
	},
	DateDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	DateDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	DateDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	DateDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DateCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	District:{
		id:"String",
		name:"String",
		level:"Float",
		children:"District",
		value:"String",
		lat:"Float",
		long:"Float",
		radius:"Float",
		region:"District_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Districts:{
		docs:"District",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countDistricts:{
		totalDocs:"Int"
	},
	districtsDocAccess:{
		fields:"DistrictsDocAccessFields",
		create:"DistrictsCreateDocAccess",
		read:"DistrictsReadDocAccess",
		update:"DistrictsUpdateDocAccess",
		delete:"DistrictsDeleteDocAccess"
	},
	DistrictsDocAccessFields:{
		name:"DistrictsDocAccessFields_name",
		level:"DistrictsDocAccessFields_level",
		children:"DistrictsDocAccessFields_children",
		value:"DistrictsDocAccessFields_value",
		lat:"DistrictsDocAccessFields_lat",
		long:"DistrictsDocAccessFields_long",
		radius:"DistrictsDocAccessFields_radius",
		region:"DistrictsDocAccessFields_region",
		updatedAt:"DistrictsDocAccessFields_updatedAt",
		createdAt:"DistrictsDocAccessFields_createdAt"
	},
	DistrictsDocAccessFields_name:{
		create:"DistrictsDocAccessFields_name_Create",
		read:"DistrictsDocAccessFields_name_Read",
		update:"DistrictsDocAccessFields_name_Update",
		delete:"DistrictsDocAccessFields_name_Delete"
	},
	DistrictsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_level:{
		create:"DistrictsDocAccessFields_level_Create",
		read:"DistrictsDocAccessFields_level_Read",
		update:"DistrictsDocAccessFields_level_Update",
		delete:"DistrictsDocAccessFields_level_Delete"
	},
	DistrictsDocAccessFields_level_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_level_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_level_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_level_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_children:{
		create:"DistrictsDocAccessFields_children_Create",
		read:"DistrictsDocAccessFields_children_Read",
		update:"DistrictsDocAccessFields_children_Update",
		delete:"DistrictsDocAccessFields_children_Delete"
	},
	DistrictsDocAccessFields_children_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_children_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_children_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_children_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_value:{
		create:"DistrictsDocAccessFields_value_Create",
		read:"DistrictsDocAccessFields_value_Read",
		update:"DistrictsDocAccessFields_value_Update",
		delete:"DistrictsDocAccessFields_value_Delete"
	},
	DistrictsDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_lat:{
		create:"DistrictsDocAccessFields_lat_Create",
		read:"DistrictsDocAccessFields_lat_Read",
		update:"DistrictsDocAccessFields_lat_Update",
		delete:"DistrictsDocAccessFields_lat_Delete"
	},
	DistrictsDocAccessFields_lat_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_lat_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_lat_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_lat_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_long:{
		create:"DistrictsDocAccessFields_long_Create",
		read:"DistrictsDocAccessFields_long_Read",
		update:"DistrictsDocAccessFields_long_Update",
		delete:"DistrictsDocAccessFields_long_Delete"
	},
	DistrictsDocAccessFields_long_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_long_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_long_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_long_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_radius:{
		create:"DistrictsDocAccessFields_radius_Create",
		read:"DistrictsDocAccessFields_radius_Read",
		update:"DistrictsDocAccessFields_radius_Update",
		delete:"DistrictsDocAccessFields_radius_Delete"
	},
	DistrictsDocAccessFields_radius_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_radius_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_radius_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_radius_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_region:{
		create:"DistrictsDocAccessFields_region_Create",
		read:"DistrictsDocAccessFields_region_Read",
		update:"DistrictsDocAccessFields_region_Update",
		delete:"DistrictsDocAccessFields_region_Delete"
	},
	DistrictsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_updatedAt:{
		create:"DistrictsDocAccessFields_updatedAt_Create",
		read:"DistrictsDocAccessFields_updatedAt_Read",
		update:"DistrictsDocAccessFields_updatedAt_Update",
		delete:"DistrictsDocAccessFields_updatedAt_Delete"
	},
	DistrictsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_createdAt:{
		create:"DistrictsDocAccessFields_createdAt_Create",
		read:"DistrictsDocAccessFields_createdAt_Read",
		update:"DistrictsDocAccessFields_createdAt_Update",
		delete:"DistrictsDocAccessFields_createdAt_Delete"
	},
	DistrictsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	DistrictsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DistrictsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Audience:{
		id:"String",
		name:"String",
		value:"String",
		icon:"Media",
		region:"Audience_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Audiences:{
		docs:"Audience",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countAudiences:{
		totalDocs:"Int"
	},
	audienceDocAccess:{
		fields:"AudienceDocAccessFields",
		create:"AudienceCreateDocAccess",
		read:"AudienceReadDocAccess",
		update:"AudienceUpdateDocAccess",
		delete:"AudienceDeleteDocAccess"
	},
	AudienceDocAccessFields:{
		name:"AudienceDocAccessFields_name",
		value:"AudienceDocAccessFields_value",
		icon:"AudienceDocAccessFields_icon",
		region:"AudienceDocAccessFields_region",
		updatedAt:"AudienceDocAccessFields_updatedAt",
		createdAt:"AudienceDocAccessFields_createdAt"
	},
	AudienceDocAccessFields_name:{
		create:"AudienceDocAccessFields_name_Create",
		read:"AudienceDocAccessFields_name_Read",
		update:"AudienceDocAccessFields_name_Update",
		delete:"AudienceDocAccessFields_name_Delete"
	},
	AudienceDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_value:{
		create:"AudienceDocAccessFields_value_Create",
		read:"AudienceDocAccessFields_value_Read",
		update:"AudienceDocAccessFields_value_Update",
		delete:"AudienceDocAccessFields_value_Delete"
	},
	AudienceDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_icon:{
		create:"AudienceDocAccessFields_icon_Create",
		read:"AudienceDocAccessFields_icon_Read",
		update:"AudienceDocAccessFields_icon_Update",
		delete:"AudienceDocAccessFields_icon_Delete"
	},
	AudienceDocAccessFields_icon_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_icon_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_icon_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_icon_Delete:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_region:{
		create:"AudienceDocAccessFields_region_Create",
		read:"AudienceDocAccessFields_region_Read",
		update:"AudienceDocAccessFields_region_Update",
		delete:"AudienceDocAccessFields_region_Delete"
	},
	AudienceDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_updatedAt:{
		create:"AudienceDocAccessFields_updatedAt_Create",
		read:"AudienceDocAccessFields_updatedAt_Read",
		update:"AudienceDocAccessFields_updatedAt_Update",
		delete:"AudienceDocAccessFields_updatedAt_Delete"
	},
	AudienceDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_createdAt:{
		create:"AudienceDocAccessFields_createdAt_Create",
		read:"AudienceDocAccessFields_createdAt_Read",
		update:"AudienceDocAccessFields_createdAt_Update",
		delete:"AudienceDocAccessFields_createdAt_Delete"
	},
	AudienceDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	AudienceDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AudienceCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Event:{
		id:"String",
		region:"Event_region",
		creator:"User",
		permalink:"String",
		status:"String",
		level:"Event_level",
		parent:"Event",
		name:"String",
		thumbnail:"Media",
		banner:"Media",
		mobileBanner:"Media",
		sections:"Event_Sections",
		ticketing:"Event_Ticketing",
		content:"Event_Content",
		legacyContent:"String",
		showLegacyContent:"Boolean",
		criteria:"Event_Criteria",
		walkIn:"Boolean",
		contact:"Event_Contact",
		views:"Float",
		free:"Boolean",
		fees:"Event_Fees",
		homePageInternalClick:"Float",
		homePageImpression:"Float",
		eventSearchInternalClick:"Float",
		eventListImpression:"Float",
		latestEventInternalClick:"Float",
		latestEventImpression:"Float",
		topTenEventRankingInternalClick:"Float",
		topTenImpression:"Float",
		editorChoiceInternalClick:"Float",
		editorChoiceImpression:"Float",
		otherEventRankingInternalClick:"Float",
		otherEventRankingImpression:"Float",
		miscInternalClick:"Float",
		miscImpression:"Float",
		internalClick:"Float",
		totalImpression:"Float",
		externalClick:"Float",
		outboundClick:"Float",
		_viewsCountByMonth:"Float",
		legacyGallery:"Media",
		earliestStartDate:"DateTime",
		latestEndDate:"DateTime",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Event_Sections:{
		startDatetime:"DateTime",
		endDatetime:"DateTime",
		fullDay:"Boolean",
		repeat:"Boolean",
		toThisDay:"DateTime",
		recurrance:"Event_Sections_Recurrance",
		sameLocation:"Boolean",
		location:"Location",
		district:"District",
		coordinate:"Float",
		linkAddress:"Boolean",
		address:"String",
		addressCoordinate:"Float",
		id:"String"
	},
	Event_Sections_Recurrance:{
		type:"Event_Sections_Recurrance_type",
		weekday:"Event_Sections_Recurrance_weekday"
	},
	Location:{
		id:"String",
		region:"Location_region",
		creator:"User",
		permalink:"String",
		status:"String",
		level:"Location_level",
		parent:"Location",
		name:"String",
		thumbnail:"Media",
		banner:"Media",
		type:"Location_type",
		category:"Location_category",
		district:"District",
		coordinate:"Float",
		linkAddress:"Boolean",
		address:"String",
		addressCoordinate:"Float",
		permanant:"Boolean",
		contact:"Location_Contact",
		content:"Location_Content",
		legacyContent:"String",
		showLegacyContent:"Boolean",
		criteria:"Location_Criteria",
		sections:"Location_Sections",
		views:"Float",
		_title:"String",
		legacyGallery:"Media",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Location_Contact:{
		code:"Float",
		phone:"String",
		page:"String",
		email:"EmailAddress"
	},
	Location_Content:{
		"...on RichText":"RichText",
		"...on Gallery":"Gallery",
		"...on Image":"Image",
		"...on Video":"Video"
	},
	RichText:{
		richText:"JSON",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	Gallery:{
		gallery:"Gallery_Gallery",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	Gallery_Gallery:{
		item:"Media",
		caption:"String",
		id:"String"
	},
	Image:{
		media:"Media",
		caption:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	Video:{
		url:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	Location_Criteria:{
		audiences:"Audience",
		dates:"Date",
		categories:"EventCategory",
		tags:"Tag"
	},
	Location_Sections:{
		startDatetime:"DateTime",
		endDatetime:"DateTime",
		fullDay:"Boolean",
		repeat:"Boolean",
		recurrance:"Location_Sections_Recurrance",
		id:"String"
	},
	Location_Sections_Recurrance:{
		type:"Location_Sections_Recurrance_type",
		weekday:"Location_Sections_Recurrance_weekday"
	},
	Event_Ticketing:{
		startDatetime:"DateTime",
		endDatetime:"DateTime",
		fullDay:"Boolean",
		type:"Event_Ticketing_type",
		ticketingType:"Event_Ticketing_ticketingType",
		promotionType:"Event_Ticketing_promotionType",
		location:"Location",
		website:"String",
		description:"String",
		id:"String"
	},
	Event_Content:{
		"...on RichText":"RichText",
		"...on Gallery":"Gallery",
		"...on Image":"Image",
		"...on Video":"Video"
	},
	Event_Criteria:{
		categories:"EventCategory",
		audiences:"Audience",
		tags:"Tag",
		specialTags:"SpecialTag"
	},
	Event_Contact:{
		code:"Float",
		phone:"String",
		page:"String",
		email:"EmailAddress"
	},
	Event_Fees:{
		fee:"Float",
		remark:"String",
		id:"String"
	},
	Events:{
		docs:"Event",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEvents:{
		totalDocs:"Int"
	},
	eventsDocAccess:{
		fields:"EventsDocAccessFields",
		create:"EventsCreateDocAccess",
		read:"EventsReadDocAccess",
		update:"EventsUpdateDocAccess",
		delete:"EventsDeleteDocAccess"
	},
	EventsDocAccessFields:{
		region:"EventsDocAccessFields_region",
		creator:"EventsDocAccessFields_creator",
		permalink:"EventsDocAccessFields_permalink",
		status:"EventsDocAccessFields_status",
		level:"EventsDocAccessFields_level",
		parent:"EventsDocAccessFields_parent",
		name:"EventsDocAccessFields_name",
		thumbnail:"EventsDocAccessFields_thumbnail",
		banner:"EventsDocAccessFields_banner",
		mobileBanner:"EventsDocAccessFields_mobileBanner",
		sections:"EventsDocAccessFields_sections",
		ticketing:"EventsDocAccessFields_ticketing",
		content:"EventsDocAccessFields_content",
		legacyContent:"EventsDocAccessFields_legacyContent",
		showLegacyContent:"EventsDocAccessFields_showLegacyContent",
		criteria:"EventsDocAccessFields_criteria",
		walkIn:"EventsDocAccessFields_walkIn",
		contact:"EventsDocAccessFields_contact",
		views:"EventsDocAccessFields_views",
		free:"EventsDocAccessFields_free",
		fees:"EventsDocAccessFields_fees",
		homePageInternalClick:"EventsDocAccessFields_homePageInternalClick",
		homePageImpression:"EventsDocAccessFields_homePageImpression",
		eventSearchInternalClick:"EventsDocAccessFields_eventSearchInternalClick",
		eventListImpression:"EventsDocAccessFields_eventListImpression",
		latestEventInternalClick:"EventsDocAccessFields_latestEventInternalClick",
		latestEventImpression:"EventsDocAccessFields_latestEventImpression",
		topTenEventRankingInternalClick:"EventsDocAccessFields_topTenEventRankingInternalClick",
		topTenImpression:"EventsDocAccessFields_topTenImpression",
		editorChoiceInternalClick:"EventsDocAccessFields_editorChoiceInternalClick",
		editorChoiceImpression:"EventsDocAccessFields_editorChoiceImpression",
		otherEventRankingInternalClick:"EventsDocAccessFields_otherEventRankingInternalClick",
		otherEventRankingImpression:"EventsDocAccessFields_otherEventRankingImpression",
		miscInternalClick:"EventsDocAccessFields_miscInternalClick",
		miscImpression:"EventsDocAccessFields_miscImpression",
		internalClick:"EventsDocAccessFields_internalClick",
		totalImpression:"EventsDocAccessFields_totalImpression",
		externalClick:"EventsDocAccessFields_externalClick",
		outboundClick:"EventsDocAccessFields_outboundClick",
		_viewsCountByMonth:"EventsDocAccessFields__viewsCountByMonth",
		legacyGallery:"EventsDocAccessFields_legacyGallery",
		earliestStartDate:"EventsDocAccessFields_earliestStartDate",
		latestEndDate:"EventsDocAccessFields_latestEndDate",
		updatedAt:"EventsDocAccessFields_updatedAt",
		createdAt:"EventsDocAccessFields_createdAt"
	},
	EventsDocAccessFields_region:{
		create:"EventsDocAccessFields_region_Create",
		read:"EventsDocAccessFields_region_Read",
		update:"EventsDocAccessFields_region_Update",
		delete:"EventsDocAccessFields_region_Delete"
	},
	EventsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_creator:{
		create:"EventsDocAccessFields_creator_Create",
		read:"EventsDocAccessFields_creator_Read",
		update:"EventsDocAccessFields_creator_Update",
		delete:"EventsDocAccessFields_creator_Delete"
	},
	EventsDocAccessFields_creator_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_creator_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_creator_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_creator_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_permalink:{
		create:"EventsDocAccessFields_permalink_Create",
		read:"EventsDocAccessFields_permalink_Read",
		update:"EventsDocAccessFields_permalink_Update",
		delete:"EventsDocAccessFields_permalink_Delete"
	},
	EventsDocAccessFields_permalink_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_permalink_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_permalink_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_permalink_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_status:{
		create:"EventsDocAccessFields_status_Create",
		read:"EventsDocAccessFields_status_Read",
		update:"EventsDocAccessFields_status_Update",
		delete:"EventsDocAccessFields_status_Delete"
	},
	EventsDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_level:{
		create:"EventsDocAccessFields_level_Create",
		read:"EventsDocAccessFields_level_Read",
		update:"EventsDocAccessFields_level_Update",
		delete:"EventsDocAccessFields_level_Delete"
	},
	EventsDocAccessFields_level_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_level_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_level_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_level_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_parent:{
		create:"EventsDocAccessFields_parent_Create",
		read:"EventsDocAccessFields_parent_Read",
		update:"EventsDocAccessFields_parent_Update",
		delete:"EventsDocAccessFields_parent_Delete"
	},
	EventsDocAccessFields_parent_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_parent_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_parent_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_parent_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_name:{
		create:"EventsDocAccessFields_name_Create",
		read:"EventsDocAccessFields_name_Read",
		update:"EventsDocAccessFields_name_Update",
		delete:"EventsDocAccessFields_name_Delete"
	},
	EventsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_thumbnail:{
		create:"EventsDocAccessFields_thumbnail_Create",
		read:"EventsDocAccessFields_thumbnail_Read",
		update:"EventsDocAccessFields_thumbnail_Update",
		delete:"EventsDocAccessFields_thumbnail_Delete"
	},
	EventsDocAccessFields_thumbnail_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_thumbnail_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_thumbnail_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_banner:{
		create:"EventsDocAccessFields_banner_Create",
		read:"EventsDocAccessFields_banner_Read",
		update:"EventsDocAccessFields_banner_Update",
		delete:"EventsDocAccessFields_banner_Delete"
	},
	EventsDocAccessFields_banner_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_banner_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_banner_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_banner_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_mobileBanner:{
		create:"EventsDocAccessFields_mobileBanner_Create",
		read:"EventsDocAccessFields_mobileBanner_Read",
		update:"EventsDocAccessFields_mobileBanner_Update",
		delete:"EventsDocAccessFields_mobileBanner_Delete"
	},
	EventsDocAccessFields_mobileBanner_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_mobileBanner_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_mobileBanner_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_mobileBanner_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections:{
		create:"EventsDocAccessFields_sections_Create",
		read:"EventsDocAccessFields_sections_Read",
		update:"EventsDocAccessFields_sections_Update",
		delete:"EventsDocAccessFields_sections_Delete",
		fields:"EventsDocAccessFields_sections_Fields"
	},
	EventsDocAccessFields_sections_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_Fields:{
		startDatetime:"EventsDocAccessFields_sections_startDatetime",
		endDatetime:"EventsDocAccessFields_sections_endDatetime",
		fullDay:"EventsDocAccessFields_sections_fullDay",
		repeat:"EventsDocAccessFields_sections_repeat",
		toThisDay:"EventsDocAccessFields_sections_toThisDay",
		recurrance:"EventsDocAccessFields_sections_recurrance",
		sameLocation:"EventsDocAccessFields_sections_sameLocation",
		location:"EventsDocAccessFields_sections_location",
		district:"EventsDocAccessFields_sections_district",
		coordinate:"EventsDocAccessFields_sections_coordinate",
		linkAddress:"EventsDocAccessFields_sections_linkAddress",
		address:"EventsDocAccessFields_sections_address",
		id:"EventsDocAccessFields_sections_id"
	},
	EventsDocAccessFields_sections_startDatetime:{
		create:"EventsDocAccessFields_sections_startDatetime_Create",
		read:"EventsDocAccessFields_sections_startDatetime_Read",
		update:"EventsDocAccessFields_sections_startDatetime_Update",
		delete:"EventsDocAccessFields_sections_startDatetime_Delete"
	},
	EventsDocAccessFields_sections_startDatetime_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_startDatetime_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_startDatetime_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_startDatetime_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_endDatetime:{
		create:"EventsDocAccessFields_sections_endDatetime_Create",
		read:"EventsDocAccessFields_sections_endDatetime_Read",
		update:"EventsDocAccessFields_sections_endDatetime_Update",
		delete:"EventsDocAccessFields_sections_endDatetime_Delete"
	},
	EventsDocAccessFields_sections_endDatetime_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_endDatetime_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_endDatetime_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_endDatetime_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_fullDay:{
		create:"EventsDocAccessFields_sections_fullDay_Create",
		read:"EventsDocAccessFields_sections_fullDay_Read",
		update:"EventsDocAccessFields_sections_fullDay_Update",
		delete:"EventsDocAccessFields_sections_fullDay_Delete"
	},
	EventsDocAccessFields_sections_fullDay_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_fullDay_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_fullDay_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_fullDay_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_repeat:{
		create:"EventsDocAccessFields_sections_repeat_Create",
		read:"EventsDocAccessFields_sections_repeat_Read",
		update:"EventsDocAccessFields_sections_repeat_Update",
		delete:"EventsDocAccessFields_sections_repeat_Delete"
	},
	EventsDocAccessFields_sections_repeat_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_repeat_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_repeat_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_repeat_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_toThisDay:{
		create:"EventsDocAccessFields_sections_toThisDay_Create",
		read:"EventsDocAccessFields_sections_toThisDay_Read",
		update:"EventsDocAccessFields_sections_toThisDay_Update",
		delete:"EventsDocAccessFields_sections_toThisDay_Delete"
	},
	EventsDocAccessFields_sections_toThisDay_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_toThisDay_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_toThisDay_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_toThisDay_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance:{
		create:"EventsDocAccessFields_sections_recurrance_Create",
		read:"EventsDocAccessFields_sections_recurrance_Read",
		update:"EventsDocAccessFields_sections_recurrance_Update",
		delete:"EventsDocAccessFields_sections_recurrance_Delete",
		fields:"EventsDocAccessFields_sections_recurrance_Fields"
	},
	EventsDocAccessFields_sections_recurrance_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_Fields:{
		type:"EventsDocAccessFields_sections_recurrance_type",
		weekday:"EventsDocAccessFields_sections_recurrance_weekday"
	},
	EventsDocAccessFields_sections_recurrance_type:{
		create:"EventsDocAccessFields_sections_recurrance_type_Create",
		read:"EventsDocAccessFields_sections_recurrance_type_Read",
		update:"EventsDocAccessFields_sections_recurrance_type_Update",
		delete:"EventsDocAccessFields_sections_recurrance_type_Delete"
	},
	EventsDocAccessFields_sections_recurrance_type_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_type_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_type_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_type_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_weekday:{
		create:"EventsDocAccessFields_sections_recurrance_weekday_Create",
		read:"EventsDocAccessFields_sections_recurrance_weekday_Read",
		update:"EventsDocAccessFields_sections_recurrance_weekday_Update",
		delete:"EventsDocAccessFields_sections_recurrance_weekday_Delete"
	},
	EventsDocAccessFields_sections_recurrance_weekday_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_weekday_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_weekday_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_recurrance_weekday_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_sameLocation:{
		create:"EventsDocAccessFields_sections_sameLocation_Create",
		read:"EventsDocAccessFields_sections_sameLocation_Read",
		update:"EventsDocAccessFields_sections_sameLocation_Update",
		delete:"EventsDocAccessFields_sections_sameLocation_Delete"
	},
	EventsDocAccessFields_sections_sameLocation_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_sameLocation_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_sameLocation_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_sameLocation_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_location:{
		create:"EventsDocAccessFields_sections_location_Create",
		read:"EventsDocAccessFields_sections_location_Read",
		update:"EventsDocAccessFields_sections_location_Update",
		delete:"EventsDocAccessFields_sections_location_Delete"
	},
	EventsDocAccessFields_sections_location_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_location_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_location_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_location_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_district:{
		create:"EventsDocAccessFields_sections_district_Create",
		read:"EventsDocAccessFields_sections_district_Read",
		update:"EventsDocAccessFields_sections_district_Update",
		delete:"EventsDocAccessFields_sections_district_Delete"
	},
	EventsDocAccessFields_sections_district_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_district_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_district_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_district_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_coordinate:{
		create:"EventsDocAccessFields_sections_coordinate_Create",
		read:"EventsDocAccessFields_sections_coordinate_Read",
		update:"EventsDocAccessFields_sections_coordinate_Update",
		delete:"EventsDocAccessFields_sections_coordinate_Delete"
	},
	EventsDocAccessFields_sections_coordinate_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_coordinate_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_coordinate_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_coordinate_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_linkAddress:{
		create:"EventsDocAccessFields_sections_linkAddress_Create",
		read:"EventsDocAccessFields_sections_linkAddress_Read",
		update:"EventsDocAccessFields_sections_linkAddress_Update",
		delete:"EventsDocAccessFields_sections_linkAddress_Delete"
	},
	EventsDocAccessFields_sections_linkAddress_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_linkAddress_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_linkAddress_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_linkAddress_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_address:{
		create:"EventsDocAccessFields_sections_address_Create",
		read:"EventsDocAccessFields_sections_address_Read",
		update:"EventsDocAccessFields_sections_address_Update",
		delete:"EventsDocAccessFields_sections_address_Delete"
	},
	EventsDocAccessFields_sections_address_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_address_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_address_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_address_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_id:{
		create:"EventsDocAccessFields_sections_id_Create",
		read:"EventsDocAccessFields_sections_id_Read",
		update:"EventsDocAccessFields_sections_id_Update",
		delete:"EventsDocAccessFields_sections_id_Delete"
	},
	EventsDocAccessFields_sections_id_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_id_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_id_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_sections_id_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing:{
		create:"EventsDocAccessFields_ticketing_Create",
		read:"EventsDocAccessFields_ticketing_Read",
		update:"EventsDocAccessFields_ticketing_Update",
		delete:"EventsDocAccessFields_ticketing_Delete",
		fields:"EventsDocAccessFields_ticketing_Fields"
	},
	EventsDocAccessFields_ticketing_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_Fields:{
		startDatetime:"EventsDocAccessFields_ticketing_startDatetime",
		endDatetime:"EventsDocAccessFields_ticketing_endDatetime",
		fullDay:"EventsDocAccessFields_ticketing_fullDay",
		type:"EventsDocAccessFields_ticketing_type",
		ticketingType:"EventsDocAccessFields_ticketing_ticketingType",
		promotionType:"EventsDocAccessFields_ticketing_promotionType",
		location:"EventsDocAccessFields_ticketing_location",
		website:"EventsDocAccessFields_ticketing_website",
		description:"EventsDocAccessFields_ticketing_description",
		id:"EventsDocAccessFields_ticketing_id"
	},
	EventsDocAccessFields_ticketing_startDatetime:{
		create:"EventsDocAccessFields_ticketing_startDatetime_Create",
		read:"EventsDocAccessFields_ticketing_startDatetime_Read",
		update:"EventsDocAccessFields_ticketing_startDatetime_Update",
		delete:"EventsDocAccessFields_ticketing_startDatetime_Delete"
	},
	EventsDocAccessFields_ticketing_startDatetime_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_startDatetime_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_startDatetime_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_startDatetime_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_endDatetime:{
		create:"EventsDocAccessFields_ticketing_endDatetime_Create",
		read:"EventsDocAccessFields_ticketing_endDatetime_Read",
		update:"EventsDocAccessFields_ticketing_endDatetime_Update",
		delete:"EventsDocAccessFields_ticketing_endDatetime_Delete"
	},
	EventsDocAccessFields_ticketing_endDatetime_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_endDatetime_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_endDatetime_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_endDatetime_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_fullDay:{
		create:"EventsDocAccessFields_ticketing_fullDay_Create",
		read:"EventsDocAccessFields_ticketing_fullDay_Read",
		update:"EventsDocAccessFields_ticketing_fullDay_Update",
		delete:"EventsDocAccessFields_ticketing_fullDay_Delete"
	},
	EventsDocAccessFields_ticketing_fullDay_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_fullDay_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_fullDay_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_fullDay_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_type:{
		create:"EventsDocAccessFields_ticketing_type_Create",
		read:"EventsDocAccessFields_ticketing_type_Read",
		update:"EventsDocAccessFields_ticketing_type_Update",
		delete:"EventsDocAccessFields_ticketing_type_Delete"
	},
	EventsDocAccessFields_ticketing_type_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_type_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_type_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_type_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_ticketingType:{
		create:"EventsDocAccessFields_ticketing_ticketingType_Create",
		read:"EventsDocAccessFields_ticketing_ticketingType_Read",
		update:"EventsDocAccessFields_ticketing_ticketingType_Update",
		delete:"EventsDocAccessFields_ticketing_ticketingType_Delete"
	},
	EventsDocAccessFields_ticketing_ticketingType_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_ticketingType_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_ticketingType_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_ticketingType_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_promotionType:{
		create:"EventsDocAccessFields_ticketing_promotionType_Create",
		read:"EventsDocAccessFields_ticketing_promotionType_Read",
		update:"EventsDocAccessFields_ticketing_promotionType_Update",
		delete:"EventsDocAccessFields_ticketing_promotionType_Delete"
	},
	EventsDocAccessFields_ticketing_promotionType_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_promotionType_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_promotionType_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_promotionType_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_location:{
		create:"EventsDocAccessFields_ticketing_location_Create",
		read:"EventsDocAccessFields_ticketing_location_Read",
		update:"EventsDocAccessFields_ticketing_location_Update",
		delete:"EventsDocAccessFields_ticketing_location_Delete"
	},
	EventsDocAccessFields_ticketing_location_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_location_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_location_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_location_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_website:{
		create:"EventsDocAccessFields_ticketing_website_Create",
		read:"EventsDocAccessFields_ticketing_website_Read",
		update:"EventsDocAccessFields_ticketing_website_Update",
		delete:"EventsDocAccessFields_ticketing_website_Delete"
	},
	EventsDocAccessFields_ticketing_website_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_website_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_website_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_website_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_description:{
		create:"EventsDocAccessFields_ticketing_description_Create",
		read:"EventsDocAccessFields_ticketing_description_Read",
		update:"EventsDocAccessFields_ticketing_description_Update",
		delete:"EventsDocAccessFields_ticketing_description_Delete"
	},
	EventsDocAccessFields_ticketing_description_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_description_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_description_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_description_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_id:{
		create:"EventsDocAccessFields_ticketing_id_Create",
		read:"EventsDocAccessFields_ticketing_id_Read",
		update:"EventsDocAccessFields_ticketing_id_Update",
		delete:"EventsDocAccessFields_ticketing_id_Delete"
	},
	EventsDocAccessFields_ticketing_id_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_id_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_id_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_ticketing_id_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_content:{
		create:"EventsDocAccessFields_content_Create",
		read:"EventsDocAccessFields_content_Read",
		update:"EventsDocAccessFields_content_Update",
		delete:"EventsDocAccessFields_content_Delete"
	},
	EventsDocAccessFields_content_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_content_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_content_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_content_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyContent:{
		create:"EventsDocAccessFields_legacyContent_Create",
		read:"EventsDocAccessFields_legacyContent_Read",
		update:"EventsDocAccessFields_legacyContent_Update",
		delete:"EventsDocAccessFields_legacyContent_Delete"
	},
	EventsDocAccessFields_legacyContent_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyContent_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyContent_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_showLegacyContent:{
		create:"EventsDocAccessFields_showLegacyContent_Create",
		read:"EventsDocAccessFields_showLegacyContent_Read",
		update:"EventsDocAccessFields_showLegacyContent_Update",
		delete:"EventsDocAccessFields_showLegacyContent_Delete"
	},
	EventsDocAccessFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria:{
		create:"EventsDocAccessFields_criteria_Create",
		read:"EventsDocAccessFields_criteria_Read",
		update:"EventsDocAccessFields_criteria_Update",
		delete:"EventsDocAccessFields_criteria_Delete",
		fields:"EventsDocAccessFields_criteria_Fields"
	},
	EventsDocAccessFields_criteria_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_Fields:{
		categories:"EventsDocAccessFields_criteria_categories",
		audiences:"EventsDocAccessFields_criteria_audiences",
		tags:"EventsDocAccessFields_criteria_tags",
		specialTags:"EventsDocAccessFields_criteria_specialTags"
	},
	EventsDocAccessFields_criteria_categories:{
		create:"EventsDocAccessFields_criteria_categories_Create",
		read:"EventsDocAccessFields_criteria_categories_Read",
		update:"EventsDocAccessFields_criteria_categories_Update",
		delete:"EventsDocAccessFields_criteria_categories_Delete"
	},
	EventsDocAccessFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_audiences:{
		create:"EventsDocAccessFields_criteria_audiences_Create",
		read:"EventsDocAccessFields_criteria_audiences_Read",
		update:"EventsDocAccessFields_criteria_audiences_Update",
		delete:"EventsDocAccessFields_criteria_audiences_Delete"
	},
	EventsDocAccessFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_tags:{
		create:"EventsDocAccessFields_criteria_tags_Create",
		read:"EventsDocAccessFields_criteria_tags_Read",
		update:"EventsDocAccessFields_criteria_tags_Update",
		delete:"EventsDocAccessFields_criteria_tags_Delete"
	},
	EventsDocAccessFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_specialTags:{
		create:"EventsDocAccessFields_criteria_specialTags_Create",
		read:"EventsDocAccessFields_criteria_specialTags_Read",
		update:"EventsDocAccessFields_criteria_specialTags_Update",
		delete:"EventsDocAccessFields_criteria_specialTags_Delete"
	},
	EventsDocAccessFields_criteria_specialTags_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_specialTags_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_specialTags_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_criteria_specialTags_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_walkIn:{
		create:"EventsDocAccessFields_walkIn_Create",
		read:"EventsDocAccessFields_walkIn_Read",
		update:"EventsDocAccessFields_walkIn_Update",
		delete:"EventsDocAccessFields_walkIn_Delete"
	},
	EventsDocAccessFields_walkIn_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_walkIn_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_walkIn_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_walkIn_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact:{
		create:"EventsDocAccessFields_contact_Create",
		read:"EventsDocAccessFields_contact_Read",
		update:"EventsDocAccessFields_contact_Update",
		delete:"EventsDocAccessFields_contact_Delete",
		fields:"EventsDocAccessFields_contact_Fields"
	},
	EventsDocAccessFields_contact_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_Fields:{
		code:"EventsDocAccessFields_contact_code",
		phone:"EventsDocAccessFields_contact_phone",
		page:"EventsDocAccessFields_contact_page",
		email:"EventsDocAccessFields_contact_email"
	},
	EventsDocAccessFields_contact_code:{
		create:"EventsDocAccessFields_contact_code_Create",
		read:"EventsDocAccessFields_contact_code_Read",
		update:"EventsDocAccessFields_contact_code_Update",
		delete:"EventsDocAccessFields_contact_code_Delete"
	},
	EventsDocAccessFields_contact_code_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_code_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_code_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_code_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_phone:{
		create:"EventsDocAccessFields_contact_phone_Create",
		read:"EventsDocAccessFields_contact_phone_Read",
		update:"EventsDocAccessFields_contact_phone_Update",
		delete:"EventsDocAccessFields_contact_phone_Delete"
	},
	EventsDocAccessFields_contact_phone_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_phone_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_phone_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_phone_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_page:{
		create:"EventsDocAccessFields_contact_page_Create",
		read:"EventsDocAccessFields_contact_page_Read",
		update:"EventsDocAccessFields_contact_page_Update",
		delete:"EventsDocAccessFields_contact_page_Delete"
	},
	EventsDocAccessFields_contact_page_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_page_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_page_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_page_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_email:{
		create:"EventsDocAccessFields_contact_email_Create",
		read:"EventsDocAccessFields_contact_email_Read",
		update:"EventsDocAccessFields_contact_email_Update",
		delete:"EventsDocAccessFields_contact_email_Delete"
	},
	EventsDocAccessFields_contact_email_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_email_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_email_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_contact_email_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_views:{
		create:"EventsDocAccessFields_views_Create",
		read:"EventsDocAccessFields_views_Read",
		update:"EventsDocAccessFields_views_Update",
		delete:"EventsDocAccessFields_views_Delete"
	},
	EventsDocAccessFields_views_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_views_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_views_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_views_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_free:{
		create:"EventsDocAccessFields_free_Create",
		read:"EventsDocAccessFields_free_Read",
		update:"EventsDocAccessFields_free_Update",
		delete:"EventsDocAccessFields_free_Delete"
	},
	EventsDocAccessFields_free_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_free_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_free_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_free_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees:{
		create:"EventsDocAccessFields_fees_Create",
		read:"EventsDocAccessFields_fees_Read",
		update:"EventsDocAccessFields_fees_Update",
		delete:"EventsDocAccessFields_fees_Delete",
		fields:"EventsDocAccessFields_fees_Fields"
	},
	EventsDocAccessFields_fees_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_Fields:{
		fee:"EventsDocAccessFields_fees_fee",
		remark:"EventsDocAccessFields_fees_remark",
		id:"EventsDocAccessFields_fees_id"
	},
	EventsDocAccessFields_fees_fee:{
		create:"EventsDocAccessFields_fees_fee_Create",
		read:"EventsDocAccessFields_fees_fee_Read",
		update:"EventsDocAccessFields_fees_fee_Update",
		delete:"EventsDocAccessFields_fees_fee_Delete"
	},
	EventsDocAccessFields_fees_fee_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_fee_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_fee_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_fee_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_remark:{
		create:"EventsDocAccessFields_fees_remark_Create",
		read:"EventsDocAccessFields_fees_remark_Read",
		update:"EventsDocAccessFields_fees_remark_Update",
		delete:"EventsDocAccessFields_fees_remark_Delete"
	},
	EventsDocAccessFields_fees_remark_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_remark_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_remark_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_remark_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_id:{
		create:"EventsDocAccessFields_fees_id_Create",
		read:"EventsDocAccessFields_fees_id_Read",
		update:"EventsDocAccessFields_fees_id_Update",
		delete:"EventsDocAccessFields_fees_id_Delete"
	},
	EventsDocAccessFields_fees_id_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_id_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_id_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_fees_id_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageInternalClick:{
		create:"EventsDocAccessFields_homePageInternalClick_Create",
		read:"EventsDocAccessFields_homePageInternalClick_Read",
		update:"EventsDocAccessFields_homePageInternalClick_Update",
		delete:"EventsDocAccessFields_homePageInternalClick_Delete"
	},
	EventsDocAccessFields_homePageInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageImpression:{
		create:"EventsDocAccessFields_homePageImpression_Create",
		read:"EventsDocAccessFields_homePageImpression_Read",
		update:"EventsDocAccessFields_homePageImpression_Update",
		delete:"EventsDocAccessFields_homePageImpression_Delete"
	},
	EventsDocAccessFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventSearchInternalClick:{
		create:"EventsDocAccessFields_eventSearchInternalClick_Create",
		read:"EventsDocAccessFields_eventSearchInternalClick_Read",
		update:"EventsDocAccessFields_eventSearchInternalClick_Update",
		delete:"EventsDocAccessFields_eventSearchInternalClick_Delete"
	},
	EventsDocAccessFields_eventSearchInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventSearchInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventSearchInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventSearchInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventListImpression:{
		create:"EventsDocAccessFields_eventListImpression_Create",
		read:"EventsDocAccessFields_eventListImpression_Read",
		update:"EventsDocAccessFields_eventListImpression_Update",
		delete:"EventsDocAccessFields_eventListImpression_Delete"
	},
	EventsDocAccessFields_eventListImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventListImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventListImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_eventListImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventInternalClick:{
		create:"EventsDocAccessFields_latestEventInternalClick_Create",
		read:"EventsDocAccessFields_latestEventInternalClick_Read",
		update:"EventsDocAccessFields_latestEventInternalClick_Update",
		delete:"EventsDocAccessFields_latestEventInternalClick_Delete"
	},
	EventsDocAccessFields_latestEventInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventImpression:{
		create:"EventsDocAccessFields_latestEventImpression_Create",
		read:"EventsDocAccessFields_latestEventImpression_Read",
		update:"EventsDocAccessFields_latestEventImpression_Update",
		delete:"EventsDocAccessFields_latestEventImpression_Delete"
	},
	EventsDocAccessFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenEventRankingInternalClick:{
		create:"EventsDocAccessFields_topTenEventRankingInternalClick_Create",
		read:"EventsDocAccessFields_topTenEventRankingInternalClick_Read",
		update:"EventsDocAccessFields_topTenEventRankingInternalClick_Update",
		delete:"EventsDocAccessFields_topTenEventRankingInternalClick_Delete"
	},
	EventsDocAccessFields_topTenEventRankingInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenEventRankingInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenEventRankingInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenEventRankingInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenImpression:{
		create:"EventsDocAccessFields_topTenImpression_Create",
		read:"EventsDocAccessFields_topTenImpression_Read",
		update:"EventsDocAccessFields_topTenImpression_Update",
		delete:"EventsDocAccessFields_topTenImpression_Delete"
	},
	EventsDocAccessFields_topTenImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_topTenImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceInternalClick:{
		create:"EventsDocAccessFields_editorChoiceInternalClick_Create",
		read:"EventsDocAccessFields_editorChoiceInternalClick_Read",
		update:"EventsDocAccessFields_editorChoiceInternalClick_Update",
		delete:"EventsDocAccessFields_editorChoiceInternalClick_Delete"
	},
	EventsDocAccessFields_editorChoiceInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceImpression:{
		create:"EventsDocAccessFields_editorChoiceImpression_Create",
		read:"EventsDocAccessFields_editorChoiceImpression_Read",
		update:"EventsDocAccessFields_editorChoiceImpression_Update",
		delete:"EventsDocAccessFields_editorChoiceImpression_Delete"
	},
	EventsDocAccessFields_editorChoiceImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_editorChoiceImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingInternalClick:{
		create:"EventsDocAccessFields_otherEventRankingInternalClick_Create",
		read:"EventsDocAccessFields_otherEventRankingInternalClick_Read",
		update:"EventsDocAccessFields_otherEventRankingInternalClick_Update",
		delete:"EventsDocAccessFields_otherEventRankingInternalClick_Delete"
	},
	EventsDocAccessFields_otherEventRankingInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingImpression:{
		create:"EventsDocAccessFields_otherEventRankingImpression_Create",
		read:"EventsDocAccessFields_otherEventRankingImpression_Read",
		update:"EventsDocAccessFields_otherEventRankingImpression_Update",
		delete:"EventsDocAccessFields_otherEventRankingImpression_Delete"
	},
	EventsDocAccessFields_otherEventRankingImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_otherEventRankingImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscInternalClick:{
		create:"EventsDocAccessFields_miscInternalClick_Create",
		read:"EventsDocAccessFields_miscInternalClick_Read",
		update:"EventsDocAccessFields_miscInternalClick_Update",
		delete:"EventsDocAccessFields_miscInternalClick_Delete"
	},
	EventsDocAccessFields_miscInternalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscInternalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscInternalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscImpression:{
		create:"EventsDocAccessFields_miscImpression_Create",
		read:"EventsDocAccessFields_miscImpression_Read",
		update:"EventsDocAccessFields_miscImpression_Update",
		delete:"EventsDocAccessFields_miscImpression_Delete"
	},
	EventsDocAccessFields_miscImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_miscImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_internalClick:{
		create:"EventsDocAccessFields_internalClick_Create",
		read:"EventsDocAccessFields_internalClick_Read",
		update:"EventsDocAccessFields_internalClick_Update",
		delete:"EventsDocAccessFields_internalClick_Delete"
	},
	EventsDocAccessFields_internalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_internalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_internalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_internalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_totalImpression:{
		create:"EventsDocAccessFields_totalImpression_Create",
		read:"EventsDocAccessFields_totalImpression_Read",
		update:"EventsDocAccessFields_totalImpression_Update",
		delete:"EventsDocAccessFields_totalImpression_Delete"
	},
	EventsDocAccessFields_totalImpression_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_totalImpression_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_totalImpression_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_externalClick:{
		create:"EventsDocAccessFields_externalClick_Create",
		read:"EventsDocAccessFields_externalClick_Read",
		update:"EventsDocAccessFields_externalClick_Update",
		delete:"EventsDocAccessFields_externalClick_Delete"
	},
	EventsDocAccessFields_externalClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_externalClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_externalClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_externalClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_outboundClick:{
		create:"EventsDocAccessFields_outboundClick_Create",
		read:"EventsDocAccessFields_outboundClick_Read",
		update:"EventsDocAccessFields_outboundClick_Update",
		delete:"EventsDocAccessFields_outboundClick_Delete"
	},
	EventsDocAccessFields_outboundClick_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_outboundClick_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_outboundClick_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_outboundClick_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields__viewsCountByMonth:{
		create:"EventsDocAccessFields__viewsCountByMonth_Create",
		read:"EventsDocAccessFields__viewsCountByMonth_Read",
		update:"EventsDocAccessFields__viewsCountByMonth_Update",
		delete:"EventsDocAccessFields__viewsCountByMonth_Delete"
	},
	EventsDocAccessFields__viewsCountByMonth_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields__viewsCountByMonth_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields__viewsCountByMonth_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields__viewsCountByMonth_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyGallery:{
		create:"EventsDocAccessFields_legacyGallery_Create",
		read:"EventsDocAccessFields_legacyGallery_Read",
		update:"EventsDocAccessFields_legacyGallery_Update",
		delete:"EventsDocAccessFields_legacyGallery_Delete"
	},
	EventsDocAccessFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_earliestStartDate:{
		create:"EventsDocAccessFields_earliestStartDate_Create",
		read:"EventsDocAccessFields_earliestStartDate_Read",
		update:"EventsDocAccessFields_earliestStartDate_Update",
		delete:"EventsDocAccessFields_earliestStartDate_Delete"
	},
	EventsDocAccessFields_earliestStartDate_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_earliestStartDate_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_earliestStartDate_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_earliestStartDate_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEndDate:{
		create:"EventsDocAccessFields_latestEndDate_Create",
		read:"EventsDocAccessFields_latestEndDate_Read",
		update:"EventsDocAccessFields_latestEndDate_Update",
		delete:"EventsDocAccessFields_latestEndDate_Delete"
	},
	EventsDocAccessFields_latestEndDate_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEndDate_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEndDate_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_latestEndDate_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_updatedAt:{
		create:"EventsDocAccessFields_updatedAt_Create",
		read:"EventsDocAccessFields_updatedAt_Read",
		update:"EventsDocAccessFields_updatedAt_Update",
		delete:"EventsDocAccessFields_updatedAt_Delete"
	},
	EventsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventsDocAccessFields_createdAt:{
		create:"EventsDocAccessFields_createdAt_Create",
		read:"EventsDocAccessFields_createdAt_Read",
		update:"EventsDocAccessFields_createdAt_Update",
		delete:"EventsDocAccessFields_createdAt_Delete"
	},
	EventsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Locations:{
		docs:"Location",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countLocations:{
		totalDocs:"Int"
	},
	locationsDocAccess:{
		fields:"LocationsDocAccessFields",
		create:"LocationsCreateDocAccess",
		read:"LocationsReadDocAccess",
		update:"LocationsUpdateDocAccess",
		delete:"LocationsDeleteDocAccess"
	},
	LocationsDocAccessFields:{
		region:"LocationsDocAccessFields_region",
		creator:"LocationsDocAccessFields_creator",
		permalink:"LocationsDocAccessFields_permalink",
		status:"LocationsDocAccessFields_status",
		level:"LocationsDocAccessFields_level",
		parent:"LocationsDocAccessFields_parent",
		name:"LocationsDocAccessFields_name",
		thumbnail:"LocationsDocAccessFields_thumbnail",
		banner:"LocationsDocAccessFields_banner",
		type:"LocationsDocAccessFields_type",
		category:"LocationsDocAccessFields_category",
		district:"LocationsDocAccessFields_district",
		coordinate:"LocationsDocAccessFields_coordinate",
		linkAddress:"LocationsDocAccessFields_linkAddress",
		address:"LocationsDocAccessFields_address",
		contact:"LocationsDocAccessFields_contact",
		content:"LocationsDocAccessFields_content",
		legacyContent:"LocationsDocAccessFields_legacyContent",
		showLegacyContent:"LocationsDocAccessFields_showLegacyContent",
		criteria:"LocationsDocAccessFields_criteria",
		sections:"LocationsDocAccessFields_sections",
		_title:"LocationsDocAccessFields__title",
		legacyGallery:"LocationsDocAccessFields_legacyGallery",
		updatedAt:"LocationsDocAccessFields_updatedAt",
		createdAt:"LocationsDocAccessFields_createdAt"
	},
	LocationsDocAccessFields_region:{
		create:"LocationsDocAccessFields_region_Create",
		read:"LocationsDocAccessFields_region_Read",
		update:"LocationsDocAccessFields_region_Update",
		delete:"LocationsDocAccessFields_region_Delete"
	},
	LocationsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_creator:{
		create:"LocationsDocAccessFields_creator_Create",
		read:"LocationsDocAccessFields_creator_Read",
		update:"LocationsDocAccessFields_creator_Update",
		delete:"LocationsDocAccessFields_creator_Delete"
	},
	LocationsDocAccessFields_creator_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_creator_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_creator_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_creator_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_permalink:{
		create:"LocationsDocAccessFields_permalink_Create",
		read:"LocationsDocAccessFields_permalink_Read",
		update:"LocationsDocAccessFields_permalink_Update",
		delete:"LocationsDocAccessFields_permalink_Delete"
	},
	LocationsDocAccessFields_permalink_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_permalink_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_permalink_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_permalink_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_status:{
		create:"LocationsDocAccessFields_status_Create",
		read:"LocationsDocAccessFields_status_Read",
		update:"LocationsDocAccessFields_status_Update",
		delete:"LocationsDocAccessFields_status_Delete"
	},
	LocationsDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_level:{
		create:"LocationsDocAccessFields_level_Create",
		read:"LocationsDocAccessFields_level_Read",
		update:"LocationsDocAccessFields_level_Update",
		delete:"LocationsDocAccessFields_level_Delete"
	},
	LocationsDocAccessFields_level_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_level_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_level_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_level_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_parent:{
		create:"LocationsDocAccessFields_parent_Create",
		read:"LocationsDocAccessFields_parent_Read",
		update:"LocationsDocAccessFields_parent_Update",
		delete:"LocationsDocAccessFields_parent_Delete"
	},
	LocationsDocAccessFields_parent_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_parent_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_parent_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_parent_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_name:{
		create:"LocationsDocAccessFields_name_Create",
		read:"LocationsDocAccessFields_name_Read",
		update:"LocationsDocAccessFields_name_Update",
		delete:"LocationsDocAccessFields_name_Delete"
	},
	LocationsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_thumbnail:{
		create:"LocationsDocAccessFields_thumbnail_Create",
		read:"LocationsDocAccessFields_thumbnail_Read",
		update:"LocationsDocAccessFields_thumbnail_Update",
		delete:"LocationsDocAccessFields_thumbnail_Delete"
	},
	LocationsDocAccessFields_thumbnail_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_thumbnail_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_thumbnail_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_banner:{
		create:"LocationsDocAccessFields_banner_Create",
		read:"LocationsDocAccessFields_banner_Read",
		update:"LocationsDocAccessFields_banner_Update",
		delete:"LocationsDocAccessFields_banner_Delete"
	},
	LocationsDocAccessFields_banner_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_banner_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_banner_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_banner_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_type:{
		create:"LocationsDocAccessFields_type_Create",
		read:"LocationsDocAccessFields_type_Read",
		update:"LocationsDocAccessFields_type_Update",
		delete:"LocationsDocAccessFields_type_Delete"
	},
	LocationsDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_category:{
		create:"LocationsDocAccessFields_category_Create",
		read:"LocationsDocAccessFields_category_Read",
		update:"LocationsDocAccessFields_category_Update",
		delete:"LocationsDocAccessFields_category_Delete"
	},
	LocationsDocAccessFields_category_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_category_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_category_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_category_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_district:{
		create:"LocationsDocAccessFields_district_Create",
		read:"LocationsDocAccessFields_district_Read",
		update:"LocationsDocAccessFields_district_Update",
		delete:"LocationsDocAccessFields_district_Delete"
	},
	LocationsDocAccessFields_district_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_district_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_district_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_district_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_coordinate:{
		create:"LocationsDocAccessFields_coordinate_Create",
		read:"LocationsDocAccessFields_coordinate_Read",
		update:"LocationsDocAccessFields_coordinate_Update",
		delete:"LocationsDocAccessFields_coordinate_Delete"
	},
	LocationsDocAccessFields_coordinate_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_coordinate_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_coordinate_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_coordinate_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_linkAddress:{
		create:"LocationsDocAccessFields_linkAddress_Create",
		read:"LocationsDocAccessFields_linkAddress_Read",
		update:"LocationsDocAccessFields_linkAddress_Update",
		delete:"LocationsDocAccessFields_linkAddress_Delete"
	},
	LocationsDocAccessFields_linkAddress_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_linkAddress_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_linkAddress_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_linkAddress_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_address:{
		create:"LocationsDocAccessFields_address_Create",
		read:"LocationsDocAccessFields_address_Read",
		update:"LocationsDocAccessFields_address_Update",
		delete:"LocationsDocAccessFields_address_Delete"
	},
	LocationsDocAccessFields_address_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_address_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_address_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_address_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact:{
		create:"LocationsDocAccessFields_contact_Create",
		read:"LocationsDocAccessFields_contact_Read",
		update:"LocationsDocAccessFields_contact_Update",
		delete:"LocationsDocAccessFields_contact_Delete",
		fields:"LocationsDocAccessFields_contact_Fields"
	},
	LocationsDocAccessFields_contact_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_Fields:{
		code:"LocationsDocAccessFields_contact_code",
		phone:"LocationsDocAccessFields_contact_phone",
		page:"LocationsDocAccessFields_contact_page",
		email:"LocationsDocAccessFields_contact_email"
	},
	LocationsDocAccessFields_contact_code:{
		create:"LocationsDocAccessFields_contact_code_Create",
		read:"LocationsDocAccessFields_contact_code_Read",
		update:"LocationsDocAccessFields_contact_code_Update",
		delete:"LocationsDocAccessFields_contact_code_Delete"
	},
	LocationsDocAccessFields_contact_code_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_code_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_code_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_code_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_phone:{
		create:"LocationsDocAccessFields_contact_phone_Create",
		read:"LocationsDocAccessFields_contact_phone_Read",
		update:"LocationsDocAccessFields_contact_phone_Update",
		delete:"LocationsDocAccessFields_contact_phone_Delete"
	},
	LocationsDocAccessFields_contact_phone_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_phone_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_phone_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_phone_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_page:{
		create:"LocationsDocAccessFields_contact_page_Create",
		read:"LocationsDocAccessFields_contact_page_Read",
		update:"LocationsDocAccessFields_contact_page_Update",
		delete:"LocationsDocAccessFields_contact_page_Delete"
	},
	LocationsDocAccessFields_contact_page_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_page_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_page_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_page_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_email:{
		create:"LocationsDocAccessFields_contact_email_Create",
		read:"LocationsDocAccessFields_contact_email_Read",
		update:"LocationsDocAccessFields_contact_email_Update",
		delete:"LocationsDocAccessFields_contact_email_Delete"
	},
	LocationsDocAccessFields_contact_email_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_email_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_email_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_contact_email_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_content:{
		create:"LocationsDocAccessFields_content_Create",
		read:"LocationsDocAccessFields_content_Read",
		update:"LocationsDocAccessFields_content_Update",
		delete:"LocationsDocAccessFields_content_Delete"
	},
	LocationsDocAccessFields_content_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_content_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_content_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_content_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyContent:{
		create:"LocationsDocAccessFields_legacyContent_Create",
		read:"LocationsDocAccessFields_legacyContent_Read",
		update:"LocationsDocAccessFields_legacyContent_Update",
		delete:"LocationsDocAccessFields_legacyContent_Delete"
	},
	LocationsDocAccessFields_legacyContent_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyContent_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyContent_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_showLegacyContent:{
		create:"LocationsDocAccessFields_showLegacyContent_Create",
		read:"LocationsDocAccessFields_showLegacyContent_Read",
		update:"LocationsDocAccessFields_showLegacyContent_Update",
		delete:"LocationsDocAccessFields_showLegacyContent_Delete"
	},
	LocationsDocAccessFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria:{
		create:"LocationsDocAccessFields_criteria_Create",
		read:"LocationsDocAccessFields_criteria_Read",
		update:"LocationsDocAccessFields_criteria_Update",
		delete:"LocationsDocAccessFields_criteria_Delete",
		fields:"LocationsDocAccessFields_criteria_Fields"
	},
	LocationsDocAccessFields_criteria_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_Fields:{
		audiences:"LocationsDocAccessFields_criteria_audiences",
		dates:"LocationsDocAccessFields_criteria_dates",
		categories:"LocationsDocAccessFields_criteria_categories",
		tags:"LocationsDocAccessFields_criteria_tags"
	},
	LocationsDocAccessFields_criteria_audiences:{
		create:"LocationsDocAccessFields_criteria_audiences_Create",
		read:"LocationsDocAccessFields_criteria_audiences_Read",
		update:"LocationsDocAccessFields_criteria_audiences_Update",
		delete:"LocationsDocAccessFields_criteria_audiences_Delete"
	},
	LocationsDocAccessFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_dates:{
		create:"LocationsDocAccessFields_criteria_dates_Create",
		read:"LocationsDocAccessFields_criteria_dates_Read",
		update:"LocationsDocAccessFields_criteria_dates_Update",
		delete:"LocationsDocAccessFields_criteria_dates_Delete"
	},
	LocationsDocAccessFields_criteria_dates_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_dates_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_dates_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_dates_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_categories:{
		create:"LocationsDocAccessFields_criteria_categories_Create",
		read:"LocationsDocAccessFields_criteria_categories_Read",
		update:"LocationsDocAccessFields_criteria_categories_Update",
		delete:"LocationsDocAccessFields_criteria_categories_Delete"
	},
	LocationsDocAccessFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_tags:{
		create:"LocationsDocAccessFields_criteria_tags_Create",
		read:"LocationsDocAccessFields_criteria_tags_Read",
		update:"LocationsDocAccessFields_criteria_tags_Update",
		delete:"LocationsDocAccessFields_criteria_tags_Delete"
	},
	LocationsDocAccessFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections:{
		create:"LocationsDocAccessFields_sections_Create",
		read:"LocationsDocAccessFields_sections_Read",
		update:"LocationsDocAccessFields_sections_Update",
		delete:"LocationsDocAccessFields_sections_Delete",
		fields:"LocationsDocAccessFields_sections_Fields"
	},
	LocationsDocAccessFields_sections_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_Fields:{
		startDatetime:"LocationsDocAccessFields_sections_startDatetime",
		endDatetime:"LocationsDocAccessFields_sections_endDatetime",
		fullDay:"LocationsDocAccessFields_sections_fullDay",
		repeat:"LocationsDocAccessFields_sections_repeat",
		recurrance:"LocationsDocAccessFields_sections_recurrance",
		id:"LocationsDocAccessFields_sections_id"
	},
	LocationsDocAccessFields_sections_startDatetime:{
		create:"LocationsDocAccessFields_sections_startDatetime_Create",
		read:"LocationsDocAccessFields_sections_startDatetime_Read",
		update:"LocationsDocAccessFields_sections_startDatetime_Update",
		delete:"LocationsDocAccessFields_sections_startDatetime_Delete"
	},
	LocationsDocAccessFields_sections_startDatetime_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_startDatetime_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_startDatetime_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_startDatetime_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_endDatetime:{
		create:"LocationsDocAccessFields_sections_endDatetime_Create",
		read:"LocationsDocAccessFields_sections_endDatetime_Read",
		update:"LocationsDocAccessFields_sections_endDatetime_Update",
		delete:"LocationsDocAccessFields_sections_endDatetime_Delete"
	},
	LocationsDocAccessFields_sections_endDatetime_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_endDatetime_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_endDatetime_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_endDatetime_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_fullDay:{
		create:"LocationsDocAccessFields_sections_fullDay_Create",
		read:"LocationsDocAccessFields_sections_fullDay_Read",
		update:"LocationsDocAccessFields_sections_fullDay_Update",
		delete:"LocationsDocAccessFields_sections_fullDay_Delete"
	},
	LocationsDocAccessFields_sections_fullDay_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_fullDay_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_fullDay_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_fullDay_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_repeat:{
		create:"LocationsDocAccessFields_sections_repeat_Create",
		read:"LocationsDocAccessFields_sections_repeat_Read",
		update:"LocationsDocAccessFields_sections_repeat_Update",
		delete:"LocationsDocAccessFields_sections_repeat_Delete"
	},
	LocationsDocAccessFields_sections_repeat_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_repeat_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_repeat_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_repeat_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance:{
		create:"LocationsDocAccessFields_sections_recurrance_Create",
		read:"LocationsDocAccessFields_sections_recurrance_Read",
		update:"LocationsDocAccessFields_sections_recurrance_Update",
		delete:"LocationsDocAccessFields_sections_recurrance_Delete",
		fields:"LocationsDocAccessFields_sections_recurrance_Fields"
	},
	LocationsDocAccessFields_sections_recurrance_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_Fields:{
		type:"LocationsDocAccessFields_sections_recurrance_type",
		weekday:"LocationsDocAccessFields_sections_recurrance_weekday"
	},
	LocationsDocAccessFields_sections_recurrance_type:{
		create:"LocationsDocAccessFields_sections_recurrance_type_Create",
		read:"LocationsDocAccessFields_sections_recurrance_type_Read",
		update:"LocationsDocAccessFields_sections_recurrance_type_Update",
		delete:"LocationsDocAccessFields_sections_recurrance_type_Delete"
	},
	LocationsDocAccessFields_sections_recurrance_type_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_type_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_type_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_type_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_weekday:{
		create:"LocationsDocAccessFields_sections_recurrance_weekday_Create",
		read:"LocationsDocAccessFields_sections_recurrance_weekday_Read",
		update:"LocationsDocAccessFields_sections_recurrance_weekday_Update",
		delete:"LocationsDocAccessFields_sections_recurrance_weekday_Delete"
	},
	LocationsDocAccessFields_sections_recurrance_weekday_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_weekday_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_weekday_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_recurrance_weekday_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_id:{
		create:"LocationsDocAccessFields_sections_id_Create",
		read:"LocationsDocAccessFields_sections_id_Read",
		update:"LocationsDocAccessFields_sections_id_Update",
		delete:"LocationsDocAccessFields_sections_id_Delete"
	},
	LocationsDocAccessFields_sections_id_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_id_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_id_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_sections_id_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields__title:{
		create:"LocationsDocAccessFields__title_Create",
		read:"LocationsDocAccessFields__title_Read",
		update:"LocationsDocAccessFields__title_Update",
		delete:"LocationsDocAccessFields__title_Delete"
	},
	LocationsDocAccessFields__title_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields__title_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields__title_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields__title_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyGallery:{
		create:"LocationsDocAccessFields_legacyGallery_Create",
		read:"LocationsDocAccessFields_legacyGallery_Read",
		update:"LocationsDocAccessFields_legacyGallery_Update",
		delete:"LocationsDocAccessFields_legacyGallery_Delete"
	},
	LocationsDocAccessFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_updatedAt:{
		create:"LocationsDocAccessFields_updatedAt_Create",
		read:"LocationsDocAccessFields_updatedAt_Read",
		update:"LocationsDocAccessFields_updatedAt_Update",
		delete:"LocationsDocAccessFields_updatedAt_Delete"
	},
	LocationsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_createdAt:{
		create:"LocationsDocAccessFields_createdAt_Create",
		read:"LocationsDocAccessFields_createdAt_Read",
		update:"LocationsDocAccessFields_createdAt_Update",
		delete:"LocationsDocAccessFields_createdAt_Delete"
	},
	LocationsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	LocationsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	LocationsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Post:{
		id:"String",
		title:"String",
		region:"Post_region",
		authur:"User",
		publishDateTime:"DateTime",
		permalink:"String",
		status:"String",
		content:"Post_Content",
		legacyContent:"String",
		showLegacyContent:"Boolean",
		blogCategories:"BlogCategory",
		thumbnail:"Media",
		banner:"Media",
		criteria:"Post_Criteria",
		location:"Location",
		views:"Float",
		homePageInternalClick:"Float",
		homePageImpression:"Float",
		blogListInternalClick:"Float",
		blogListImpression:"Float",
		blogDetailInternalClick:"Float",
		blogDetailImpression:"Float",
		relatedEventInternalClick:"Float",
		relatedEventImpression:"Float",
		latestEventInternalClick:"Float",
		latestEventImpression:"Float",
		internalClick:"Float",
		totalImpression:"Float",
		externalClick:"Float",
		outboundClick:"Float",
		legacyGallery:"Media",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Post_Content:{
		"...on RichText":"RichText",
		"...on Gallery":"Gallery",
		"...on Image":"Image",
		"...on Video":"Video"
	},
	Post_Criteria:{
		audiences:"Audience",
		dates:"Date",
		categories:"EventCategory",
		tags:"Tag"
	},
	Posts:{
		docs:"Post",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countPosts:{
		totalDocs:"Int"
	},
	postsDocAccess:{
		fields:"PostsDocAccessFields",
		create:"PostsCreateDocAccess",
		read:"PostsReadDocAccess",
		update:"PostsUpdateDocAccess",
		delete:"PostsDeleteDocAccess"
	},
	PostsDocAccessFields:{
		title:"PostsDocAccessFields_title",
		region:"PostsDocAccessFields_region",
		authur:"PostsDocAccessFields_authur",
		publishDateTime:"PostsDocAccessFields_publishDateTime",
		permalink:"PostsDocAccessFields_permalink",
		status:"PostsDocAccessFields_status",
		content:"PostsDocAccessFields_content",
		legacyContent:"PostsDocAccessFields_legacyContent",
		showLegacyContent:"PostsDocAccessFields_showLegacyContent",
		blogCategories:"PostsDocAccessFields_blogCategories",
		thumbnail:"PostsDocAccessFields_thumbnail",
		banner:"PostsDocAccessFields_banner",
		criteria:"PostsDocAccessFields_criteria",
		location:"PostsDocAccessFields_location",
		homePageInternalClick:"PostsDocAccessFields_homePageInternalClick",
		homePageImpression:"PostsDocAccessFields_homePageImpression",
		blogListInternalClick:"PostsDocAccessFields_blogListInternalClick",
		blogListImpression:"PostsDocAccessFields_blogListImpression",
		blogDetailInternalClick:"PostsDocAccessFields_blogDetailInternalClick",
		blogDetailImpression:"PostsDocAccessFields_blogDetailImpression",
		relatedEventInternalClick:"PostsDocAccessFields_relatedEventInternalClick",
		relatedEventImpression:"PostsDocAccessFields_relatedEventImpression",
		latestEventInternalClick:"PostsDocAccessFields_latestEventInternalClick",
		latestEventImpression:"PostsDocAccessFields_latestEventImpression",
		internalClick:"PostsDocAccessFields_internalClick",
		totalImpression:"PostsDocAccessFields_totalImpression",
		externalClick:"PostsDocAccessFields_externalClick",
		outboundClick:"PostsDocAccessFields_outboundClick",
		legacyGallery:"PostsDocAccessFields_legacyGallery",
		updatedAt:"PostsDocAccessFields_updatedAt",
		createdAt:"PostsDocAccessFields_createdAt"
	},
	PostsDocAccessFields_title:{
		create:"PostsDocAccessFields_title_Create",
		read:"PostsDocAccessFields_title_Read",
		update:"PostsDocAccessFields_title_Update",
		delete:"PostsDocAccessFields_title_Delete"
	},
	PostsDocAccessFields_title_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_title_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_title_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_title_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_region:{
		create:"PostsDocAccessFields_region_Create",
		read:"PostsDocAccessFields_region_Read",
		update:"PostsDocAccessFields_region_Update",
		delete:"PostsDocAccessFields_region_Delete"
	},
	PostsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_authur:{
		create:"PostsDocAccessFields_authur_Create",
		read:"PostsDocAccessFields_authur_Read",
		update:"PostsDocAccessFields_authur_Update",
		delete:"PostsDocAccessFields_authur_Delete"
	},
	PostsDocAccessFields_authur_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_authur_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_authur_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_authur_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_publishDateTime:{
		create:"PostsDocAccessFields_publishDateTime_Create",
		read:"PostsDocAccessFields_publishDateTime_Read",
		update:"PostsDocAccessFields_publishDateTime_Update",
		delete:"PostsDocAccessFields_publishDateTime_Delete"
	},
	PostsDocAccessFields_publishDateTime_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_publishDateTime_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_publishDateTime_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_publishDateTime_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_permalink:{
		create:"PostsDocAccessFields_permalink_Create",
		read:"PostsDocAccessFields_permalink_Read",
		update:"PostsDocAccessFields_permalink_Update",
		delete:"PostsDocAccessFields_permalink_Delete"
	},
	PostsDocAccessFields_permalink_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_permalink_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_permalink_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_permalink_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_status:{
		create:"PostsDocAccessFields_status_Create",
		read:"PostsDocAccessFields_status_Read",
		update:"PostsDocAccessFields_status_Update",
		delete:"PostsDocAccessFields_status_Delete"
	},
	PostsDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_content:{
		create:"PostsDocAccessFields_content_Create",
		read:"PostsDocAccessFields_content_Read",
		update:"PostsDocAccessFields_content_Update",
		delete:"PostsDocAccessFields_content_Delete"
	},
	PostsDocAccessFields_content_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_content_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_content_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_content_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyContent:{
		create:"PostsDocAccessFields_legacyContent_Create",
		read:"PostsDocAccessFields_legacyContent_Read",
		update:"PostsDocAccessFields_legacyContent_Update",
		delete:"PostsDocAccessFields_legacyContent_Delete"
	},
	PostsDocAccessFields_legacyContent_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyContent_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyContent_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_showLegacyContent:{
		create:"PostsDocAccessFields_showLegacyContent_Create",
		read:"PostsDocAccessFields_showLegacyContent_Read",
		update:"PostsDocAccessFields_showLegacyContent_Update",
		delete:"PostsDocAccessFields_showLegacyContent_Delete"
	},
	PostsDocAccessFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogCategories:{
		create:"PostsDocAccessFields_blogCategories_Create",
		read:"PostsDocAccessFields_blogCategories_Read",
		update:"PostsDocAccessFields_blogCategories_Update",
		delete:"PostsDocAccessFields_blogCategories_Delete"
	},
	PostsDocAccessFields_blogCategories_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogCategories_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogCategories_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogCategories_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_thumbnail:{
		create:"PostsDocAccessFields_thumbnail_Create",
		read:"PostsDocAccessFields_thumbnail_Read",
		update:"PostsDocAccessFields_thumbnail_Update",
		delete:"PostsDocAccessFields_thumbnail_Delete"
	},
	PostsDocAccessFields_thumbnail_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_thumbnail_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_thumbnail_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_banner:{
		create:"PostsDocAccessFields_banner_Create",
		read:"PostsDocAccessFields_banner_Read",
		update:"PostsDocAccessFields_banner_Update",
		delete:"PostsDocAccessFields_banner_Delete"
	},
	PostsDocAccessFields_banner_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_banner_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_banner_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_banner_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria:{
		create:"PostsDocAccessFields_criteria_Create",
		read:"PostsDocAccessFields_criteria_Read",
		update:"PostsDocAccessFields_criteria_Update",
		delete:"PostsDocAccessFields_criteria_Delete",
		fields:"PostsDocAccessFields_criteria_Fields"
	},
	PostsDocAccessFields_criteria_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_Fields:{
		audiences:"PostsDocAccessFields_criteria_audiences",
		dates:"PostsDocAccessFields_criteria_dates",
		categories:"PostsDocAccessFields_criteria_categories",
		tags:"PostsDocAccessFields_criteria_tags"
	},
	PostsDocAccessFields_criteria_audiences:{
		create:"PostsDocAccessFields_criteria_audiences_Create",
		read:"PostsDocAccessFields_criteria_audiences_Read",
		update:"PostsDocAccessFields_criteria_audiences_Update",
		delete:"PostsDocAccessFields_criteria_audiences_Delete"
	},
	PostsDocAccessFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_dates:{
		create:"PostsDocAccessFields_criteria_dates_Create",
		read:"PostsDocAccessFields_criteria_dates_Read",
		update:"PostsDocAccessFields_criteria_dates_Update",
		delete:"PostsDocAccessFields_criteria_dates_Delete"
	},
	PostsDocAccessFields_criteria_dates_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_dates_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_dates_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_dates_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_categories:{
		create:"PostsDocAccessFields_criteria_categories_Create",
		read:"PostsDocAccessFields_criteria_categories_Read",
		update:"PostsDocAccessFields_criteria_categories_Update",
		delete:"PostsDocAccessFields_criteria_categories_Delete"
	},
	PostsDocAccessFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_tags:{
		create:"PostsDocAccessFields_criteria_tags_Create",
		read:"PostsDocAccessFields_criteria_tags_Read",
		update:"PostsDocAccessFields_criteria_tags_Update",
		delete:"PostsDocAccessFields_criteria_tags_Delete"
	},
	PostsDocAccessFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_location:{
		create:"PostsDocAccessFields_location_Create",
		read:"PostsDocAccessFields_location_Read",
		update:"PostsDocAccessFields_location_Update",
		delete:"PostsDocAccessFields_location_Delete"
	},
	PostsDocAccessFields_location_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_location_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_location_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_location_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageInternalClick:{
		create:"PostsDocAccessFields_homePageInternalClick_Create",
		read:"PostsDocAccessFields_homePageInternalClick_Read",
		update:"PostsDocAccessFields_homePageInternalClick_Update",
		delete:"PostsDocAccessFields_homePageInternalClick_Delete"
	},
	PostsDocAccessFields_homePageInternalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageInternalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageInternalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageImpression:{
		create:"PostsDocAccessFields_homePageImpression_Create",
		read:"PostsDocAccessFields_homePageImpression_Read",
		update:"PostsDocAccessFields_homePageImpression_Update",
		delete:"PostsDocAccessFields_homePageImpression_Delete"
	},
	PostsDocAccessFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListInternalClick:{
		create:"PostsDocAccessFields_blogListInternalClick_Create",
		read:"PostsDocAccessFields_blogListInternalClick_Read",
		update:"PostsDocAccessFields_blogListInternalClick_Update",
		delete:"PostsDocAccessFields_blogListInternalClick_Delete"
	},
	PostsDocAccessFields_blogListInternalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListInternalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListInternalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListImpression:{
		create:"PostsDocAccessFields_blogListImpression_Create",
		read:"PostsDocAccessFields_blogListImpression_Read",
		update:"PostsDocAccessFields_blogListImpression_Update",
		delete:"PostsDocAccessFields_blogListImpression_Delete"
	},
	PostsDocAccessFields_blogListImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogListImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailInternalClick:{
		create:"PostsDocAccessFields_blogDetailInternalClick_Create",
		read:"PostsDocAccessFields_blogDetailInternalClick_Read",
		update:"PostsDocAccessFields_blogDetailInternalClick_Update",
		delete:"PostsDocAccessFields_blogDetailInternalClick_Delete"
	},
	PostsDocAccessFields_blogDetailInternalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailInternalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailInternalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailImpression:{
		create:"PostsDocAccessFields_blogDetailImpression_Create",
		read:"PostsDocAccessFields_blogDetailImpression_Read",
		update:"PostsDocAccessFields_blogDetailImpression_Update",
		delete:"PostsDocAccessFields_blogDetailImpression_Delete"
	},
	PostsDocAccessFields_blogDetailImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_blogDetailImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventInternalClick:{
		create:"PostsDocAccessFields_relatedEventInternalClick_Create",
		read:"PostsDocAccessFields_relatedEventInternalClick_Read",
		update:"PostsDocAccessFields_relatedEventInternalClick_Update",
		delete:"PostsDocAccessFields_relatedEventInternalClick_Delete"
	},
	PostsDocAccessFields_relatedEventInternalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventInternalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventInternalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventImpression:{
		create:"PostsDocAccessFields_relatedEventImpression_Create",
		read:"PostsDocAccessFields_relatedEventImpression_Read",
		update:"PostsDocAccessFields_relatedEventImpression_Update",
		delete:"PostsDocAccessFields_relatedEventImpression_Delete"
	},
	PostsDocAccessFields_relatedEventImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_relatedEventImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventInternalClick:{
		create:"PostsDocAccessFields_latestEventInternalClick_Create",
		read:"PostsDocAccessFields_latestEventInternalClick_Read",
		update:"PostsDocAccessFields_latestEventInternalClick_Update",
		delete:"PostsDocAccessFields_latestEventInternalClick_Delete"
	},
	PostsDocAccessFields_latestEventInternalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventInternalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventInternalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventImpression:{
		create:"PostsDocAccessFields_latestEventImpression_Create",
		read:"PostsDocAccessFields_latestEventImpression_Read",
		update:"PostsDocAccessFields_latestEventImpression_Update",
		delete:"PostsDocAccessFields_latestEventImpression_Delete"
	},
	PostsDocAccessFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_internalClick:{
		create:"PostsDocAccessFields_internalClick_Create",
		read:"PostsDocAccessFields_internalClick_Read",
		update:"PostsDocAccessFields_internalClick_Update",
		delete:"PostsDocAccessFields_internalClick_Delete"
	},
	PostsDocAccessFields_internalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_internalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_internalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_internalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_totalImpression:{
		create:"PostsDocAccessFields_totalImpression_Create",
		read:"PostsDocAccessFields_totalImpression_Read",
		update:"PostsDocAccessFields_totalImpression_Update",
		delete:"PostsDocAccessFields_totalImpression_Delete"
	},
	PostsDocAccessFields_totalImpression_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_totalImpression_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_totalImpression_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_externalClick:{
		create:"PostsDocAccessFields_externalClick_Create",
		read:"PostsDocAccessFields_externalClick_Read",
		update:"PostsDocAccessFields_externalClick_Update",
		delete:"PostsDocAccessFields_externalClick_Delete"
	},
	PostsDocAccessFields_externalClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_externalClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_externalClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_externalClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_outboundClick:{
		create:"PostsDocAccessFields_outboundClick_Create",
		read:"PostsDocAccessFields_outboundClick_Read",
		update:"PostsDocAccessFields_outboundClick_Update",
		delete:"PostsDocAccessFields_outboundClick_Delete"
	},
	PostsDocAccessFields_outboundClick_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_outboundClick_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_outboundClick_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_outboundClick_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyGallery:{
		create:"PostsDocAccessFields_legacyGallery_Create",
		read:"PostsDocAccessFields_legacyGallery_Read",
		update:"PostsDocAccessFields_legacyGallery_Update",
		delete:"PostsDocAccessFields_legacyGallery_Delete"
	},
	PostsDocAccessFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_updatedAt:{
		create:"PostsDocAccessFields_updatedAt_Create",
		read:"PostsDocAccessFields_updatedAt_Read",
		update:"PostsDocAccessFields_updatedAt_Update",
		delete:"PostsDocAccessFields_updatedAt_Delete"
	},
	PostsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostsDocAccessFields_createdAt:{
		create:"PostsDocAccessFields_createdAt_Create",
		read:"PostsDocAccessFields_createdAt_Read",
		update:"PostsDocAccessFields_createdAt_Update",
		delete:"PostsDocAccessFields_createdAt_Delete"
	},
	PostsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMedia:{
		id:"String",
		alt:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		url:"String",
		filename:"String",
		mimeType:"String",
		filesize:"Float",
		width:"Float",
		height:"Float",
		focalX:"Float",
		focalY:"Float",
		sizes:"PrivateMedia_Sizes"
	},
	PrivateMedia_Sizes:{
		thumbnail:"PrivateMedia_Sizes_Thumbnail",
		portrait:"PrivateMedia_Sizes_Portrait",
		hero:"PrivateMedia_Sizes_Hero",
		thumbnail_jpeg:"PrivateMedia_Sizes_Thumbnail_jpeg",
		portrait_jpeg:"PrivateMedia_Sizes_Portrait_jpeg",
		hero_jpeg:"PrivateMedia_Sizes_Hero_jpeg"
	},
	PrivateMedia_Sizes_Thumbnail:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedia_Sizes_Portrait:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedia_Sizes_Hero:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedia_Sizes_Thumbnail_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedia_Sizes_Portrait_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedia_Sizes_Hero_jpeg:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	PrivateMedias:{
		docs:"PrivateMedia",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countPrivateMedias:{
		totalDocs:"Int"
	},
	private_mediaDocAccess:{
		fields:"PrivateMediaDocAccessFields",
		create:"PrivateMediaCreateDocAccess",
		read:"PrivateMediaReadDocAccess",
		update:"PrivateMediaUpdateDocAccess",
		delete:"PrivateMediaDeleteDocAccess"
	},
	PrivateMediaDocAccessFields:{
		alt:"PrivateMediaDocAccessFields_alt",
		updatedAt:"PrivateMediaDocAccessFields_updatedAt",
		createdAt:"PrivateMediaDocAccessFields_createdAt",
		url:"PrivateMediaDocAccessFields_url",
		filename:"PrivateMediaDocAccessFields_filename",
		mimeType:"PrivateMediaDocAccessFields_mimeType",
		filesize:"PrivateMediaDocAccessFields_filesize",
		width:"PrivateMediaDocAccessFields_width",
		height:"PrivateMediaDocAccessFields_height",
		focalX:"PrivateMediaDocAccessFields_focalX",
		focalY:"PrivateMediaDocAccessFields_focalY",
		sizes:"PrivateMediaDocAccessFields_sizes"
	},
	PrivateMediaDocAccessFields_alt:{
		create:"PrivateMediaDocAccessFields_alt_Create",
		read:"PrivateMediaDocAccessFields_alt_Read",
		update:"PrivateMediaDocAccessFields_alt_Update",
		delete:"PrivateMediaDocAccessFields_alt_Delete"
	},
	PrivateMediaDocAccessFields_alt_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_alt_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_alt_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_alt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_updatedAt:{
		create:"PrivateMediaDocAccessFields_updatedAt_Create",
		read:"PrivateMediaDocAccessFields_updatedAt_Read",
		update:"PrivateMediaDocAccessFields_updatedAt_Update",
		delete:"PrivateMediaDocAccessFields_updatedAt_Delete"
	},
	PrivateMediaDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_createdAt:{
		create:"PrivateMediaDocAccessFields_createdAt_Create",
		read:"PrivateMediaDocAccessFields_createdAt_Read",
		update:"PrivateMediaDocAccessFields_createdAt_Update",
		delete:"PrivateMediaDocAccessFields_createdAt_Delete"
	},
	PrivateMediaDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_url:{
		create:"PrivateMediaDocAccessFields_url_Create",
		read:"PrivateMediaDocAccessFields_url_Read",
		update:"PrivateMediaDocAccessFields_url_Update",
		delete:"PrivateMediaDocAccessFields_url_Delete"
	},
	PrivateMediaDocAccessFields_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filename:{
		create:"PrivateMediaDocAccessFields_filename_Create",
		read:"PrivateMediaDocAccessFields_filename_Read",
		update:"PrivateMediaDocAccessFields_filename_Update",
		delete:"PrivateMediaDocAccessFields_filename_Delete"
	},
	PrivateMediaDocAccessFields_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_mimeType:{
		create:"PrivateMediaDocAccessFields_mimeType_Create",
		read:"PrivateMediaDocAccessFields_mimeType_Read",
		update:"PrivateMediaDocAccessFields_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filesize:{
		create:"PrivateMediaDocAccessFields_filesize_Create",
		read:"PrivateMediaDocAccessFields_filesize_Read",
		update:"PrivateMediaDocAccessFields_filesize_Update",
		delete:"PrivateMediaDocAccessFields_filesize_Delete"
	},
	PrivateMediaDocAccessFields_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_width:{
		create:"PrivateMediaDocAccessFields_width_Create",
		read:"PrivateMediaDocAccessFields_width_Read",
		update:"PrivateMediaDocAccessFields_width_Update",
		delete:"PrivateMediaDocAccessFields_width_Delete"
	},
	PrivateMediaDocAccessFields_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_height:{
		create:"PrivateMediaDocAccessFields_height_Create",
		read:"PrivateMediaDocAccessFields_height_Read",
		update:"PrivateMediaDocAccessFields_height_Update",
		delete:"PrivateMediaDocAccessFields_height_Delete"
	},
	PrivateMediaDocAccessFields_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalX:{
		create:"PrivateMediaDocAccessFields_focalX_Create",
		read:"PrivateMediaDocAccessFields_focalX_Read",
		update:"PrivateMediaDocAccessFields_focalX_Update",
		delete:"PrivateMediaDocAccessFields_focalX_Delete"
	},
	PrivateMediaDocAccessFields_focalX_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalX_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalX_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalX_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalY:{
		create:"PrivateMediaDocAccessFields_focalY_Create",
		read:"PrivateMediaDocAccessFields_focalY_Read",
		update:"PrivateMediaDocAccessFields_focalY_Update",
		delete:"PrivateMediaDocAccessFields_focalY_Delete"
	},
	PrivateMediaDocAccessFields_focalY_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalY_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalY_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_focalY_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes:{
		create:"PrivateMediaDocAccessFields_sizes_Create",
		read:"PrivateMediaDocAccessFields_sizes_Read",
		update:"PrivateMediaDocAccessFields_sizes_Update",
		delete:"PrivateMediaDocAccessFields_sizes_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_Fields"
	},
	PrivateMediaDocAccessFields_sizes_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_Fields:{
		thumbnail:"PrivateMediaDocAccessFields_sizes_thumbnail",
		portrait:"PrivateMediaDocAccessFields_sizes_portrait",
		hero:"PrivateMediaDocAccessFields_sizes_hero",
		thumbnail_jpeg:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg",
		portrait_jpeg:"PrivateMediaDocAccessFields_sizes_portrait_jpeg",
		hero_jpeg:"PrivateMediaDocAccessFields_sizes_hero_jpeg"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_thumbnail_Fields"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_thumbnail_url",
		width:"PrivateMediaDocAccessFields_sizes_thumbnail_width",
		height:"PrivateMediaDocAccessFields_sizes_thumbnail_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_thumbnail_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_thumbnail_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_thumbnail_filename"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_url:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_width:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_height:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filename:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_portrait_Fields"
	},
	PrivateMediaDocAccessFields_sizes_portrait_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_portrait_url",
		width:"PrivateMediaDocAccessFields_sizes_portrait_width",
		height:"PrivateMediaDocAccessFields_sizes_portrait_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_portrait_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_portrait_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_portrait_filename"
	},
	PrivateMediaDocAccessFields_sizes_portrait_url:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_width:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_height:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filename:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero:{
		create:"PrivateMediaDocAccessFields_sizes_hero_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_hero_Fields"
	},
	PrivateMediaDocAccessFields_sizes_hero_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_hero_url",
		width:"PrivateMediaDocAccessFields_sizes_hero_width",
		height:"PrivateMediaDocAccessFields_sizes_hero_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_hero_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_hero_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_hero_filename"
	},
	PrivateMediaDocAccessFields_sizes_hero_url:{
		create:"PrivateMediaDocAccessFields_sizes_hero_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_width:{
		create:"PrivateMediaDocAccessFields_sizes_hero_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_height:{
		create:"PrivateMediaDocAccessFields_sizes_hero_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_hero_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_hero_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filename:{
		create:"PrivateMediaDocAccessFields_sizes_hero_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url",
		width:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width",
		height:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename:{
		create:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_url",
		width:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_width",
		height:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_url:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_width:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_height:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename:{
		create:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete",
		fields:"PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields:{
		url:"PrivateMediaDocAccessFields_sizes_hero_jpeg_url",
		width:"PrivateMediaDocAccessFields_sizes_hero_jpeg_width",
		height:"PrivateMediaDocAccessFields_sizes_hero_jpeg_height",
		mimeType:"PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType",
		filesize:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize",
		filename:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_url:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_width:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_height:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filename:{
		create:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create",
		read:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read",
		update:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update",
		delete:"PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	allMedia:{
		docs:"Media",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countallMedia:{
		totalDocs:"Int"
	},
	mediaDocAccess:{
		fields:"MediaDocAccessFields",
		create:"MediaCreateDocAccess",
		read:"MediaReadDocAccess",
		update:"MediaUpdateDocAccess",
		delete:"MediaDeleteDocAccess"
	},
	MediaDocAccessFields:{
		alt:"MediaDocAccessFields_alt",
		updatedAt:"MediaDocAccessFields_updatedAt",
		createdAt:"MediaDocAccessFields_createdAt",
		url:"MediaDocAccessFields_url",
		filename:"MediaDocAccessFields_filename",
		mimeType:"MediaDocAccessFields_mimeType",
		filesize:"MediaDocAccessFields_filesize",
		width:"MediaDocAccessFields_width",
		height:"MediaDocAccessFields_height",
		focalX:"MediaDocAccessFields_focalX",
		focalY:"MediaDocAccessFields_focalY",
		sizes:"MediaDocAccessFields_sizes"
	},
	MediaDocAccessFields_alt:{
		create:"MediaDocAccessFields_alt_Create",
		read:"MediaDocAccessFields_alt_Read",
		update:"MediaDocAccessFields_alt_Update",
		delete:"MediaDocAccessFields_alt_Delete"
	},
	MediaDocAccessFields_alt_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_alt_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_alt_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_alt_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_updatedAt:{
		create:"MediaDocAccessFields_updatedAt_Create",
		read:"MediaDocAccessFields_updatedAt_Read",
		update:"MediaDocAccessFields_updatedAt_Update",
		delete:"MediaDocAccessFields_updatedAt_Delete"
	},
	MediaDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_createdAt:{
		create:"MediaDocAccessFields_createdAt_Create",
		read:"MediaDocAccessFields_createdAt_Read",
		update:"MediaDocAccessFields_createdAt_Update",
		delete:"MediaDocAccessFields_createdAt_Delete"
	},
	MediaDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_url:{
		create:"MediaDocAccessFields_url_Create",
		read:"MediaDocAccessFields_url_Read",
		update:"MediaDocAccessFields_url_Update",
		delete:"MediaDocAccessFields_url_Delete"
	},
	MediaDocAccessFields_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filename:{
		create:"MediaDocAccessFields_filename_Create",
		read:"MediaDocAccessFields_filename_Read",
		update:"MediaDocAccessFields_filename_Update",
		delete:"MediaDocAccessFields_filename_Delete"
	},
	MediaDocAccessFields_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_mimeType:{
		create:"MediaDocAccessFields_mimeType_Create",
		read:"MediaDocAccessFields_mimeType_Read",
		update:"MediaDocAccessFields_mimeType_Update",
		delete:"MediaDocAccessFields_mimeType_Delete"
	},
	MediaDocAccessFields_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filesize:{
		create:"MediaDocAccessFields_filesize_Create",
		read:"MediaDocAccessFields_filesize_Read",
		update:"MediaDocAccessFields_filesize_Update",
		delete:"MediaDocAccessFields_filesize_Delete"
	},
	MediaDocAccessFields_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_width:{
		create:"MediaDocAccessFields_width_Create",
		read:"MediaDocAccessFields_width_Read",
		update:"MediaDocAccessFields_width_Update",
		delete:"MediaDocAccessFields_width_Delete"
	},
	MediaDocAccessFields_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_height:{
		create:"MediaDocAccessFields_height_Create",
		read:"MediaDocAccessFields_height_Read",
		update:"MediaDocAccessFields_height_Update",
		delete:"MediaDocAccessFields_height_Delete"
	},
	MediaDocAccessFields_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalX:{
		create:"MediaDocAccessFields_focalX_Create",
		read:"MediaDocAccessFields_focalX_Read",
		update:"MediaDocAccessFields_focalX_Update",
		delete:"MediaDocAccessFields_focalX_Delete"
	},
	MediaDocAccessFields_focalX_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalX_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalX_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalX_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalY:{
		create:"MediaDocAccessFields_focalY_Create",
		read:"MediaDocAccessFields_focalY_Read",
		update:"MediaDocAccessFields_focalY_Update",
		delete:"MediaDocAccessFields_focalY_Delete"
	},
	MediaDocAccessFields_focalY_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalY_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalY_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_focalY_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes:{
		create:"MediaDocAccessFields_sizes_Create",
		read:"MediaDocAccessFields_sizes_Read",
		update:"MediaDocAccessFields_sizes_Update",
		delete:"MediaDocAccessFields_sizes_Delete",
		fields:"MediaDocAccessFields_sizes_Fields"
	},
	MediaDocAccessFields_sizes_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_Fields:{
		small:"MediaDocAccessFields_sizes_small",
		medium:"MediaDocAccessFields_sizes_medium",
		large:"MediaDocAccessFields_sizes_large",
		small_jpeg:"MediaDocAccessFields_sizes_small_jpeg",
		medium_jpeg:"MediaDocAccessFields_sizes_medium_jpeg",
		large_jpeg:"MediaDocAccessFields_sizes_large_jpeg"
	},
	MediaDocAccessFields_sizes_small:{
		create:"MediaDocAccessFields_sizes_small_Create",
		read:"MediaDocAccessFields_sizes_small_Read",
		update:"MediaDocAccessFields_sizes_small_Update",
		delete:"MediaDocAccessFields_sizes_small_Delete",
		fields:"MediaDocAccessFields_sizes_small_Fields"
	},
	MediaDocAccessFields_sizes_small_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_Fields:{
		url:"MediaDocAccessFields_sizes_small_url",
		width:"MediaDocAccessFields_sizes_small_width",
		height:"MediaDocAccessFields_sizes_small_height",
		mimeType:"MediaDocAccessFields_sizes_small_mimeType",
		filesize:"MediaDocAccessFields_sizes_small_filesize",
		filename:"MediaDocAccessFields_sizes_small_filename"
	},
	MediaDocAccessFields_sizes_small_url:{
		create:"MediaDocAccessFields_sizes_small_url_Create",
		read:"MediaDocAccessFields_sizes_small_url_Read",
		update:"MediaDocAccessFields_sizes_small_url_Update",
		delete:"MediaDocAccessFields_sizes_small_url_Delete"
	},
	MediaDocAccessFields_sizes_small_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_width:{
		create:"MediaDocAccessFields_sizes_small_width_Create",
		read:"MediaDocAccessFields_sizes_small_width_Read",
		update:"MediaDocAccessFields_sizes_small_width_Update",
		delete:"MediaDocAccessFields_sizes_small_width_Delete"
	},
	MediaDocAccessFields_sizes_small_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_height:{
		create:"MediaDocAccessFields_sizes_small_height_Create",
		read:"MediaDocAccessFields_sizes_small_height_Read",
		update:"MediaDocAccessFields_sizes_small_height_Update",
		delete:"MediaDocAccessFields_sizes_small_height_Delete"
	},
	MediaDocAccessFields_sizes_small_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_mimeType:{
		create:"MediaDocAccessFields_sizes_small_mimeType_Create",
		read:"MediaDocAccessFields_sizes_small_mimeType_Read",
		update:"MediaDocAccessFields_sizes_small_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_small_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_small_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filesize:{
		create:"MediaDocAccessFields_sizes_small_filesize_Create",
		read:"MediaDocAccessFields_sizes_small_filesize_Read",
		update:"MediaDocAccessFields_sizes_small_filesize_Update",
		delete:"MediaDocAccessFields_sizes_small_filesize_Delete"
	},
	MediaDocAccessFields_sizes_small_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filename:{
		create:"MediaDocAccessFields_sizes_small_filename_Create",
		read:"MediaDocAccessFields_sizes_small_filename_Read",
		update:"MediaDocAccessFields_sizes_small_filename_Update",
		delete:"MediaDocAccessFields_sizes_small_filename_Delete"
	},
	MediaDocAccessFields_sizes_small_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium:{
		create:"MediaDocAccessFields_sizes_medium_Create",
		read:"MediaDocAccessFields_sizes_medium_Read",
		update:"MediaDocAccessFields_sizes_medium_Update",
		delete:"MediaDocAccessFields_sizes_medium_Delete",
		fields:"MediaDocAccessFields_sizes_medium_Fields"
	},
	MediaDocAccessFields_sizes_medium_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_Fields:{
		url:"MediaDocAccessFields_sizes_medium_url",
		width:"MediaDocAccessFields_sizes_medium_width",
		height:"MediaDocAccessFields_sizes_medium_height",
		mimeType:"MediaDocAccessFields_sizes_medium_mimeType",
		filesize:"MediaDocAccessFields_sizes_medium_filesize",
		filename:"MediaDocAccessFields_sizes_medium_filename"
	},
	MediaDocAccessFields_sizes_medium_url:{
		create:"MediaDocAccessFields_sizes_medium_url_Create",
		read:"MediaDocAccessFields_sizes_medium_url_Read",
		update:"MediaDocAccessFields_sizes_medium_url_Update",
		delete:"MediaDocAccessFields_sizes_medium_url_Delete"
	},
	MediaDocAccessFields_sizes_medium_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_width:{
		create:"MediaDocAccessFields_sizes_medium_width_Create",
		read:"MediaDocAccessFields_sizes_medium_width_Read",
		update:"MediaDocAccessFields_sizes_medium_width_Update",
		delete:"MediaDocAccessFields_sizes_medium_width_Delete"
	},
	MediaDocAccessFields_sizes_medium_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_height:{
		create:"MediaDocAccessFields_sizes_medium_height_Create",
		read:"MediaDocAccessFields_sizes_medium_height_Read",
		update:"MediaDocAccessFields_sizes_medium_height_Update",
		delete:"MediaDocAccessFields_sizes_medium_height_Delete"
	},
	MediaDocAccessFields_sizes_medium_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_mimeType:{
		create:"MediaDocAccessFields_sizes_medium_mimeType_Create",
		read:"MediaDocAccessFields_sizes_medium_mimeType_Read",
		update:"MediaDocAccessFields_sizes_medium_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_medium_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_medium_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filesize:{
		create:"MediaDocAccessFields_sizes_medium_filesize_Create",
		read:"MediaDocAccessFields_sizes_medium_filesize_Read",
		update:"MediaDocAccessFields_sizes_medium_filesize_Update",
		delete:"MediaDocAccessFields_sizes_medium_filesize_Delete"
	},
	MediaDocAccessFields_sizes_medium_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filename:{
		create:"MediaDocAccessFields_sizes_medium_filename_Create",
		read:"MediaDocAccessFields_sizes_medium_filename_Read",
		update:"MediaDocAccessFields_sizes_medium_filename_Update",
		delete:"MediaDocAccessFields_sizes_medium_filename_Delete"
	},
	MediaDocAccessFields_sizes_medium_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large:{
		create:"MediaDocAccessFields_sizes_large_Create",
		read:"MediaDocAccessFields_sizes_large_Read",
		update:"MediaDocAccessFields_sizes_large_Update",
		delete:"MediaDocAccessFields_sizes_large_Delete",
		fields:"MediaDocAccessFields_sizes_large_Fields"
	},
	MediaDocAccessFields_sizes_large_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_Fields:{
		url:"MediaDocAccessFields_sizes_large_url",
		width:"MediaDocAccessFields_sizes_large_width",
		height:"MediaDocAccessFields_sizes_large_height",
		mimeType:"MediaDocAccessFields_sizes_large_mimeType",
		filesize:"MediaDocAccessFields_sizes_large_filesize",
		filename:"MediaDocAccessFields_sizes_large_filename"
	},
	MediaDocAccessFields_sizes_large_url:{
		create:"MediaDocAccessFields_sizes_large_url_Create",
		read:"MediaDocAccessFields_sizes_large_url_Read",
		update:"MediaDocAccessFields_sizes_large_url_Update",
		delete:"MediaDocAccessFields_sizes_large_url_Delete"
	},
	MediaDocAccessFields_sizes_large_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_width:{
		create:"MediaDocAccessFields_sizes_large_width_Create",
		read:"MediaDocAccessFields_sizes_large_width_Read",
		update:"MediaDocAccessFields_sizes_large_width_Update",
		delete:"MediaDocAccessFields_sizes_large_width_Delete"
	},
	MediaDocAccessFields_sizes_large_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_height:{
		create:"MediaDocAccessFields_sizes_large_height_Create",
		read:"MediaDocAccessFields_sizes_large_height_Read",
		update:"MediaDocAccessFields_sizes_large_height_Update",
		delete:"MediaDocAccessFields_sizes_large_height_Delete"
	},
	MediaDocAccessFields_sizes_large_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_mimeType:{
		create:"MediaDocAccessFields_sizes_large_mimeType_Create",
		read:"MediaDocAccessFields_sizes_large_mimeType_Read",
		update:"MediaDocAccessFields_sizes_large_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_large_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_large_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filesize:{
		create:"MediaDocAccessFields_sizes_large_filesize_Create",
		read:"MediaDocAccessFields_sizes_large_filesize_Read",
		update:"MediaDocAccessFields_sizes_large_filesize_Update",
		delete:"MediaDocAccessFields_sizes_large_filesize_Delete"
	},
	MediaDocAccessFields_sizes_large_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filename:{
		create:"MediaDocAccessFields_sizes_large_filename_Create",
		read:"MediaDocAccessFields_sizes_large_filename_Read",
		update:"MediaDocAccessFields_sizes_large_filename_Update",
		delete:"MediaDocAccessFields_sizes_large_filename_Delete"
	},
	MediaDocAccessFields_sizes_large_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg:{
		create:"MediaDocAccessFields_sizes_small_jpeg_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_Delete",
		fields:"MediaDocAccessFields_sizes_small_jpeg_Fields"
	},
	MediaDocAccessFields_sizes_small_jpeg_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_Fields:{
		url:"MediaDocAccessFields_sizes_small_jpeg_url",
		width:"MediaDocAccessFields_sizes_small_jpeg_width",
		height:"MediaDocAccessFields_sizes_small_jpeg_height",
		mimeType:"MediaDocAccessFields_sizes_small_jpeg_mimeType",
		filesize:"MediaDocAccessFields_sizes_small_jpeg_filesize",
		filename:"MediaDocAccessFields_sizes_small_jpeg_filename"
	},
	MediaDocAccessFields_sizes_small_jpeg_url:{
		create:"MediaDocAccessFields_sizes_small_jpeg_url_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_url_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_url_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_url_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_width:{
		create:"MediaDocAccessFields_sizes_small_jpeg_width_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_width_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_width_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_width_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_height:{
		create:"MediaDocAccessFields_sizes_small_jpeg_height_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_height_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_height_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_height_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_mimeType:{
		create:"MediaDocAccessFields_sizes_small_jpeg_mimeType_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_mimeType_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filesize:{
		create:"MediaDocAccessFields_sizes_small_jpeg_filesize_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_filesize_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_filesize_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filename:{
		create:"MediaDocAccessFields_sizes_small_jpeg_filename_Create",
		read:"MediaDocAccessFields_sizes_small_jpeg_filename_Read",
		update:"MediaDocAccessFields_sizes_small_jpeg_filename_Update",
		delete:"MediaDocAccessFields_sizes_small_jpeg_filename_Delete"
	},
	MediaDocAccessFields_sizes_small_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_small_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_Delete",
		fields:"MediaDocAccessFields_sizes_medium_jpeg_Fields"
	},
	MediaDocAccessFields_sizes_medium_jpeg_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_Fields:{
		url:"MediaDocAccessFields_sizes_medium_jpeg_url",
		width:"MediaDocAccessFields_sizes_medium_jpeg_width",
		height:"MediaDocAccessFields_sizes_medium_jpeg_height",
		mimeType:"MediaDocAccessFields_sizes_medium_jpeg_mimeType",
		filesize:"MediaDocAccessFields_sizes_medium_jpeg_filesize",
		filename:"MediaDocAccessFields_sizes_medium_jpeg_filename"
	},
	MediaDocAccessFields_sizes_medium_jpeg_url:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_url_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_url_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_url_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_url_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_width:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_width_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_width_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_width_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_width_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_height:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_height_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_height_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_height_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_height_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_mimeType:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filesize:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_filesize_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_filesize_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_filesize_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filename:{
		create:"MediaDocAccessFields_sizes_medium_jpeg_filename_Create",
		read:"MediaDocAccessFields_sizes_medium_jpeg_filename_Read",
		update:"MediaDocAccessFields_sizes_medium_jpeg_filename_Update",
		delete:"MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_medium_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg:{
		create:"MediaDocAccessFields_sizes_large_jpeg_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_Delete",
		fields:"MediaDocAccessFields_sizes_large_jpeg_Fields"
	},
	MediaDocAccessFields_sizes_large_jpeg_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_Fields:{
		url:"MediaDocAccessFields_sizes_large_jpeg_url",
		width:"MediaDocAccessFields_sizes_large_jpeg_width",
		height:"MediaDocAccessFields_sizes_large_jpeg_height",
		mimeType:"MediaDocAccessFields_sizes_large_jpeg_mimeType",
		filesize:"MediaDocAccessFields_sizes_large_jpeg_filesize",
		filename:"MediaDocAccessFields_sizes_large_jpeg_filename"
	},
	MediaDocAccessFields_sizes_large_jpeg_url:{
		create:"MediaDocAccessFields_sizes_large_jpeg_url_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_url_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_url_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_url_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_width:{
		create:"MediaDocAccessFields_sizes_large_jpeg_width_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_width_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_width_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_width_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_height:{
		create:"MediaDocAccessFields_sizes_large_jpeg_height_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_height_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_height_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_height_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_mimeType:{
		create:"MediaDocAccessFields_sizes_large_jpeg_mimeType_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_mimeType_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_mimeType_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filesize:{
		create:"MediaDocAccessFields_sizes_large_jpeg_filesize_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_filesize_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_filesize_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filename:{
		create:"MediaDocAccessFields_sizes_large_jpeg_filename_Create",
		read:"MediaDocAccessFields_sizes_large_jpeg_filename_Read",
		update:"MediaDocAccessFields_sizes_large_jpeg_filename_Update",
		delete:"MediaDocAccessFields_sizes_large_jpeg_filename_Delete"
	},
	MediaDocAccessFields_sizes_large_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaDocAccessFields_sizes_large_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMedia:{
		id:"String",
		alt:"String",
		creator:"User",
		updatedAt:"DateTime",
		createdAt:"DateTime",
		url:"String",
		filename:"String",
		mimeType:"String",
		filesize:"Float",
		width:"Float",
		height:"Float",
		focalX:"Float",
		focalY:"Float",
		sizes:"BackupMedia_Sizes"
	},
	BackupMedia_Sizes:{
		thumbnail:"BackupMedia_Sizes_Thumbnail",
		sixteenByNineMedium:"BackupMedia_Sizes_SixteenByNineMedium"
	},
	BackupMedia_Sizes_Thumbnail:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	BackupMedia_Sizes_SixteenByNineMedium:{
		url:"String",
		width:"Float",
		height:"Float",
		mimeType:"String",
		filesize:"Float",
		filename:"String"
	},
	BackupMedias:{
		docs:"BackupMedia",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countBackupMedias:{
		totalDocs:"Int"
	},
	backupMediaDocAccess:{
		fields:"BackupMediaDocAccessFields",
		create:"BackupMediaCreateDocAccess",
		read:"BackupMediaReadDocAccess",
		update:"BackupMediaUpdateDocAccess",
		delete:"BackupMediaDeleteDocAccess"
	},
	BackupMediaDocAccessFields:{
		alt:"BackupMediaDocAccessFields_alt",
		creator:"BackupMediaDocAccessFields_creator",
		updatedAt:"BackupMediaDocAccessFields_updatedAt",
		createdAt:"BackupMediaDocAccessFields_createdAt",
		url:"BackupMediaDocAccessFields_url",
		filename:"BackupMediaDocAccessFields_filename",
		mimeType:"BackupMediaDocAccessFields_mimeType",
		filesize:"BackupMediaDocAccessFields_filesize",
		width:"BackupMediaDocAccessFields_width",
		height:"BackupMediaDocAccessFields_height",
		focalX:"BackupMediaDocAccessFields_focalX",
		focalY:"BackupMediaDocAccessFields_focalY",
		sizes:"BackupMediaDocAccessFields_sizes"
	},
	BackupMediaDocAccessFields_alt:{
		create:"BackupMediaDocAccessFields_alt_Create",
		read:"BackupMediaDocAccessFields_alt_Read",
		update:"BackupMediaDocAccessFields_alt_Update",
		delete:"BackupMediaDocAccessFields_alt_Delete"
	},
	BackupMediaDocAccessFields_alt_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_alt_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_alt_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_alt_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_creator:{
		create:"BackupMediaDocAccessFields_creator_Create",
		read:"BackupMediaDocAccessFields_creator_Read",
		update:"BackupMediaDocAccessFields_creator_Update",
		delete:"BackupMediaDocAccessFields_creator_Delete"
	},
	BackupMediaDocAccessFields_creator_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_creator_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_creator_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_creator_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_updatedAt:{
		create:"BackupMediaDocAccessFields_updatedAt_Create",
		read:"BackupMediaDocAccessFields_updatedAt_Read",
		update:"BackupMediaDocAccessFields_updatedAt_Update",
		delete:"BackupMediaDocAccessFields_updatedAt_Delete"
	},
	BackupMediaDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_createdAt:{
		create:"BackupMediaDocAccessFields_createdAt_Create",
		read:"BackupMediaDocAccessFields_createdAt_Read",
		update:"BackupMediaDocAccessFields_createdAt_Update",
		delete:"BackupMediaDocAccessFields_createdAt_Delete"
	},
	BackupMediaDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_url:{
		create:"BackupMediaDocAccessFields_url_Create",
		read:"BackupMediaDocAccessFields_url_Read",
		update:"BackupMediaDocAccessFields_url_Update",
		delete:"BackupMediaDocAccessFields_url_Delete"
	},
	BackupMediaDocAccessFields_url_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_url_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_url_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filename:{
		create:"BackupMediaDocAccessFields_filename_Create",
		read:"BackupMediaDocAccessFields_filename_Read",
		update:"BackupMediaDocAccessFields_filename_Update",
		delete:"BackupMediaDocAccessFields_filename_Delete"
	},
	BackupMediaDocAccessFields_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_mimeType:{
		create:"BackupMediaDocAccessFields_mimeType_Create",
		read:"BackupMediaDocAccessFields_mimeType_Read",
		update:"BackupMediaDocAccessFields_mimeType_Update",
		delete:"BackupMediaDocAccessFields_mimeType_Delete"
	},
	BackupMediaDocAccessFields_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filesize:{
		create:"BackupMediaDocAccessFields_filesize_Create",
		read:"BackupMediaDocAccessFields_filesize_Read",
		update:"BackupMediaDocAccessFields_filesize_Update",
		delete:"BackupMediaDocAccessFields_filesize_Delete"
	},
	BackupMediaDocAccessFields_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_width:{
		create:"BackupMediaDocAccessFields_width_Create",
		read:"BackupMediaDocAccessFields_width_Read",
		update:"BackupMediaDocAccessFields_width_Update",
		delete:"BackupMediaDocAccessFields_width_Delete"
	},
	BackupMediaDocAccessFields_width_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_width_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_width_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_height:{
		create:"BackupMediaDocAccessFields_height_Create",
		read:"BackupMediaDocAccessFields_height_Read",
		update:"BackupMediaDocAccessFields_height_Update",
		delete:"BackupMediaDocAccessFields_height_Delete"
	},
	BackupMediaDocAccessFields_height_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_height_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_height_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalX:{
		create:"BackupMediaDocAccessFields_focalX_Create",
		read:"BackupMediaDocAccessFields_focalX_Read",
		update:"BackupMediaDocAccessFields_focalX_Update",
		delete:"BackupMediaDocAccessFields_focalX_Delete"
	},
	BackupMediaDocAccessFields_focalX_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalX_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalX_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalX_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalY:{
		create:"BackupMediaDocAccessFields_focalY_Create",
		read:"BackupMediaDocAccessFields_focalY_Read",
		update:"BackupMediaDocAccessFields_focalY_Update",
		delete:"BackupMediaDocAccessFields_focalY_Delete"
	},
	BackupMediaDocAccessFields_focalY_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalY_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalY_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_focalY_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes:{
		create:"BackupMediaDocAccessFields_sizes_Create",
		read:"BackupMediaDocAccessFields_sizes_Read",
		update:"BackupMediaDocAccessFields_sizes_Update",
		delete:"BackupMediaDocAccessFields_sizes_Delete",
		fields:"BackupMediaDocAccessFields_sizes_Fields"
	},
	BackupMediaDocAccessFields_sizes_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_Fields:{
		thumbnail:"BackupMediaDocAccessFields_sizes_thumbnail",
		sixteenByNineMedium:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium"
	},
	BackupMediaDocAccessFields_sizes_thumbnail:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_Delete",
		fields:"BackupMediaDocAccessFields_sizes_thumbnail_Fields"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_Fields:{
		url:"BackupMediaDocAccessFields_sizes_thumbnail_url",
		width:"BackupMediaDocAccessFields_sizes_thumbnail_width",
		height:"BackupMediaDocAccessFields_sizes_thumbnail_height",
		mimeType:"BackupMediaDocAccessFields_sizes_thumbnail_mimeType",
		filesize:"BackupMediaDocAccessFields_sizes_thumbnail_filesize",
		filename:"BackupMediaDocAccessFields_sizes_thumbnail_filename"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_url:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_url_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_url_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_url_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_url_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_url_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_url_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_width:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_width_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_width_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_width_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_width_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_width_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_width_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_height:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_height_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_height_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_height_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_height_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_height_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_height_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_mimeType:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filesize:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filename:{
		create:"BackupMediaDocAccessFields_sizes_thumbnail_filename_Create",
		read:"BackupMediaDocAccessFields_sizes_thumbnail_filename_Read",
		update:"BackupMediaDocAccessFields_sizes_thumbnail_filename_Update",
		delete:"BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete",
		fields:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields:{
		url:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url",
		width:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width",
		height:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height",
		mimeType:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType",
		filesize:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize",
		filename:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename:{
		create:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create",
		read:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read",
		update:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update",
		delete:"BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventRanking:{
		id:"String",
		name:"String",
		visible:"Boolean",
		maxItems:"Float",
		template:"EventRanking_template",
		list:"EventRanking_List",
		region:"EventRanking_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	EventRanking_List:{
		item:"Event",
		id:"String"
	},
	EventRankings:{
		docs:"EventRanking",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEventRankings:{
		totalDocs:"Int"
	},
	ranking_eventsDocAccess:{
		fields:"RankingEventsDocAccessFields",
		create:"RankingEventsCreateDocAccess",
		read:"RankingEventsReadDocAccess",
		update:"RankingEventsUpdateDocAccess",
		delete:"RankingEventsDeleteDocAccess"
	},
	RankingEventsDocAccessFields:{
		name:"RankingEventsDocAccessFields_name",
		visible:"RankingEventsDocAccessFields_visible",
		maxItems:"RankingEventsDocAccessFields_maxItems",
		template:"RankingEventsDocAccessFields_template",
		list:"RankingEventsDocAccessFields_list",
		region:"RankingEventsDocAccessFields_region",
		updatedAt:"RankingEventsDocAccessFields_updatedAt",
		createdAt:"RankingEventsDocAccessFields_createdAt"
	},
	RankingEventsDocAccessFields_name:{
		create:"RankingEventsDocAccessFields_name_Create",
		read:"RankingEventsDocAccessFields_name_Read",
		update:"RankingEventsDocAccessFields_name_Update",
		delete:"RankingEventsDocAccessFields_name_Delete"
	},
	RankingEventsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_visible:{
		create:"RankingEventsDocAccessFields_visible_Create",
		read:"RankingEventsDocAccessFields_visible_Read",
		update:"RankingEventsDocAccessFields_visible_Update",
		delete:"RankingEventsDocAccessFields_visible_Delete"
	},
	RankingEventsDocAccessFields_visible_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_visible_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_visible_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_visible_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_maxItems:{
		create:"RankingEventsDocAccessFields_maxItems_Create",
		read:"RankingEventsDocAccessFields_maxItems_Read",
		update:"RankingEventsDocAccessFields_maxItems_Update",
		delete:"RankingEventsDocAccessFields_maxItems_Delete"
	},
	RankingEventsDocAccessFields_maxItems_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_maxItems_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_maxItems_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_maxItems_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_template:{
		create:"RankingEventsDocAccessFields_template_Create",
		read:"RankingEventsDocAccessFields_template_Read",
		update:"RankingEventsDocAccessFields_template_Update",
		delete:"RankingEventsDocAccessFields_template_Delete"
	},
	RankingEventsDocAccessFields_template_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_template_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_template_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_template_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list:{
		create:"RankingEventsDocAccessFields_list_Create",
		read:"RankingEventsDocAccessFields_list_Read",
		update:"RankingEventsDocAccessFields_list_Update",
		delete:"RankingEventsDocAccessFields_list_Delete",
		fields:"RankingEventsDocAccessFields_list_Fields"
	},
	RankingEventsDocAccessFields_list_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_Fields:{
		item:"RankingEventsDocAccessFields_list_item",
		id:"RankingEventsDocAccessFields_list_id"
	},
	RankingEventsDocAccessFields_list_item:{
		create:"RankingEventsDocAccessFields_list_item_Create",
		read:"RankingEventsDocAccessFields_list_item_Read",
		update:"RankingEventsDocAccessFields_list_item_Update",
		delete:"RankingEventsDocAccessFields_list_item_Delete"
	},
	RankingEventsDocAccessFields_list_item_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_item_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_item_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_item_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_id:{
		create:"RankingEventsDocAccessFields_list_id_Create",
		read:"RankingEventsDocAccessFields_list_id_Read",
		update:"RankingEventsDocAccessFields_list_id_Update",
		delete:"RankingEventsDocAccessFields_list_id_Delete"
	},
	RankingEventsDocAccessFields_list_id_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_id_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_id_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_list_id_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_region:{
		create:"RankingEventsDocAccessFields_region_Create",
		read:"RankingEventsDocAccessFields_region_Read",
		update:"RankingEventsDocAccessFields_region_Update",
		delete:"RankingEventsDocAccessFields_region_Delete"
	},
	RankingEventsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_updatedAt:{
		create:"RankingEventsDocAccessFields_updatedAt_Create",
		read:"RankingEventsDocAccessFields_updatedAt_Read",
		update:"RankingEventsDocAccessFields_updatedAt_Update",
		delete:"RankingEventsDocAccessFields_updatedAt_Delete"
	},
	RankingEventsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_createdAt:{
		create:"RankingEventsDocAccessFields_createdAt_Create",
		read:"RankingEventsDocAccessFields_createdAt_Read",
		update:"RankingEventsDocAccessFields_createdAt_Update",
		delete:"RankingEventsDocAccessFields_createdAt_Delete"
	},
	RankingEventsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	RankingEventsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RankingEventsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationRanking:{
		id:"String",
		name:"String",
		visible:"Boolean",
		maxItems:"Float",
		template:"LocationRanking_template",
		list:"LocationRanking_List",
		region:"LocationRanking_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	LocationRanking_List:{
		item:"Location",
		id:"String"
	},
	LocationRankings:{
		docs:"LocationRanking",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countLocationRankings:{
		totalDocs:"Int"
	},
	ranking_locationsDocAccess:{
		fields:"RankingLocationsDocAccessFields",
		create:"RankingLocationsCreateDocAccess",
		read:"RankingLocationsReadDocAccess",
		update:"RankingLocationsUpdateDocAccess",
		delete:"RankingLocationsDeleteDocAccess"
	},
	RankingLocationsDocAccessFields:{
		name:"RankingLocationsDocAccessFields_name",
		visible:"RankingLocationsDocAccessFields_visible",
		maxItems:"RankingLocationsDocAccessFields_maxItems",
		template:"RankingLocationsDocAccessFields_template",
		list:"RankingLocationsDocAccessFields_list",
		region:"RankingLocationsDocAccessFields_region",
		updatedAt:"RankingLocationsDocAccessFields_updatedAt",
		createdAt:"RankingLocationsDocAccessFields_createdAt"
	},
	RankingLocationsDocAccessFields_name:{
		create:"RankingLocationsDocAccessFields_name_Create",
		read:"RankingLocationsDocAccessFields_name_Read",
		update:"RankingLocationsDocAccessFields_name_Update",
		delete:"RankingLocationsDocAccessFields_name_Delete"
	},
	RankingLocationsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_visible:{
		create:"RankingLocationsDocAccessFields_visible_Create",
		read:"RankingLocationsDocAccessFields_visible_Read",
		update:"RankingLocationsDocAccessFields_visible_Update",
		delete:"RankingLocationsDocAccessFields_visible_Delete"
	},
	RankingLocationsDocAccessFields_visible_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_visible_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_visible_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_visible_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_maxItems:{
		create:"RankingLocationsDocAccessFields_maxItems_Create",
		read:"RankingLocationsDocAccessFields_maxItems_Read",
		update:"RankingLocationsDocAccessFields_maxItems_Update",
		delete:"RankingLocationsDocAccessFields_maxItems_Delete"
	},
	RankingLocationsDocAccessFields_maxItems_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_maxItems_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_maxItems_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_maxItems_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_template:{
		create:"RankingLocationsDocAccessFields_template_Create",
		read:"RankingLocationsDocAccessFields_template_Read",
		update:"RankingLocationsDocAccessFields_template_Update",
		delete:"RankingLocationsDocAccessFields_template_Delete"
	},
	RankingLocationsDocAccessFields_template_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_template_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_template_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_template_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list:{
		create:"RankingLocationsDocAccessFields_list_Create",
		read:"RankingLocationsDocAccessFields_list_Read",
		update:"RankingLocationsDocAccessFields_list_Update",
		delete:"RankingLocationsDocAccessFields_list_Delete",
		fields:"RankingLocationsDocAccessFields_list_Fields"
	},
	RankingLocationsDocAccessFields_list_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_Fields:{
		item:"RankingLocationsDocAccessFields_list_item",
		id:"RankingLocationsDocAccessFields_list_id"
	},
	RankingLocationsDocAccessFields_list_item:{
		create:"RankingLocationsDocAccessFields_list_item_Create",
		read:"RankingLocationsDocAccessFields_list_item_Read",
		update:"RankingLocationsDocAccessFields_list_item_Update",
		delete:"RankingLocationsDocAccessFields_list_item_Delete"
	},
	RankingLocationsDocAccessFields_list_item_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_item_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_item_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_item_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_id:{
		create:"RankingLocationsDocAccessFields_list_id_Create",
		read:"RankingLocationsDocAccessFields_list_id_Read",
		update:"RankingLocationsDocAccessFields_list_id_Update",
		delete:"RankingLocationsDocAccessFields_list_id_Delete"
	},
	RankingLocationsDocAccessFields_list_id_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_id_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_id_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_list_id_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_region:{
		create:"RankingLocationsDocAccessFields_region_Create",
		read:"RankingLocationsDocAccessFields_region_Read",
		update:"RankingLocationsDocAccessFields_region_Update",
		delete:"RankingLocationsDocAccessFields_region_Delete"
	},
	RankingLocationsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_updatedAt:{
		create:"RankingLocationsDocAccessFields_updatedAt_Create",
		read:"RankingLocationsDocAccessFields_updatedAt_Read",
		update:"RankingLocationsDocAccessFields_updatedAt_Update",
		delete:"RankingLocationsDocAccessFields_updatedAt_Delete"
	},
	RankingLocationsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_createdAt:{
		create:"RankingLocationsDocAccessFields_createdAt_Create",
		read:"RankingLocationsDocAccessFields_createdAt_Read",
		update:"RankingLocationsDocAccessFields_createdAt_Update",
		delete:"RankingLocationsDocAccessFields_createdAt_Delete"
	},
	RankingLocationsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	RankingLocationsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RankingLocationsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequest:{
		id:"String",
		user:"User",
		handledBy:"User",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	AccountSuspendRequests:{
		docs:"AccountSuspendRequest",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countAccountSuspendRequests:{
		totalDocs:"Int"
	},
	account_suspend_requestsDocAccess:{
		fields:"AccountSuspendRequestsDocAccessFields",
		create:"AccountSuspendRequestsCreateDocAccess",
		read:"AccountSuspendRequestsReadDocAccess",
		update:"AccountSuspendRequestsUpdateDocAccess",
		delete:"AccountSuspendRequestsDeleteDocAccess"
	},
	AccountSuspendRequestsDocAccessFields:{
		user:"AccountSuspendRequestsDocAccessFields_user",
		handledBy:"AccountSuspendRequestsDocAccessFields_handledBy",
		updatedAt:"AccountSuspendRequestsDocAccessFields_updatedAt",
		createdAt:"AccountSuspendRequestsDocAccessFields_createdAt"
	},
	AccountSuspendRequestsDocAccessFields_user:{
		create:"AccountSuspendRequestsDocAccessFields_user_Create",
		read:"AccountSuspendRequestsDocAccessFields_user_Read",
		update:"AccountSuspendRequestsDocAccessFields_user_Update",
		delete:"AccountSuspendRequestsDocAccessFields_user_Delete"
	},
	AccountSuspendRequestsDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_handledBy:{
		create:"AccountSuspendRequestsDocAccessFields_handledBy_Create",
		read:"AccountSuspendRequestsDocAccessFields_handledBy_Read",
		update:"AccountSuspendRequestsDocAccessFields_handledBy_Update",
		delete:"AccountSuspendRequestsDocAccessFields_handledBy_Delete"
	},
	AccountSuspendRequestsDocAccessFields_handledBy_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_handledBy_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_handledBy_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_handledBy_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_updatedAt:{
		create:"AccountSuspendRequestsDocAccessFields_updatedAt_Create",
		read:"AccountSuspendRequestsDocAccessFields_updatedAt_Read",
		update:"AccountSuspendRequestsDocAccessFields_updatedAt_Update",
		delete:"AccountSuspendRequestsDocAccessFields_updatedAt_Delete"
	},
	AccountSuspendRequestsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_createdAt:{
		create:"AccountSuspendRequestsDocAccessFields_createdAt_Create",
		read:"AccountSuspendRequestsDocAccessFields_createdAt_Read",
		update:"AccountSuspendRequestsDocAccessFields_createdAt_Update",
		delete:"AccountSuspendRequestsDocAccessFields_createdAt_Delete"
	},
	AccountSuspendRequestsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Bookmark:{
		id:"String",
		user:"Bookmark_User_Relationship",
		item:"Bookmark_Item_Relationship",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Bookmark_User_Relationship:{
		relationTo:"Bookmark_User_RelationTo",
		value:"Bookmark_User"
	},
	Bookmark_User:{
		"...on User":"User"
	},
	Bookmark_Item_Relationship:{
		relationTo:"Bookmark_Item_RelationTo",
		value:"Bookmark_Item"
	},
	Bookmark_Item:{
		"...on Event":"Event",
		"...on Location":"Location",
		"...on Post":"Post"
	},
	Bookmarks:{
		docs:"Bookmark",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countBookmarks:{
		totalDocs:"Int"
	},
	bookmarksDocAccess:{
		fields:"BookmarksDocAccessFields",
		create:"BookmarksCreateDocAccess",
		read:"BookmarksReadDocAccess",
		update:"BookmarksUpdateDocAccess",
		delete:"BookmarksDeleteDocAccess"
	},
	BookmarksDocAccessFields:{
		user:"BookmarksDocAccessFields_user",
		item:"BookmarksDocAccessFields_item",
		updatedAt:"BookmarksDocAccessFields_updatedAt",
		createdAt:"BookmarksDocAccessFields_createdAt"
	},
	BookmarksDocAccessFields_user:{
		create:"BookmarksDocAccessFields_user_Create",
		read:"BookmarksDocAccessFields_user_Read",
		update:"BookmarksDocAccessFields_user_Update",
		delete:"BookmarksDocAccessFields_user_Delete"
	},
	BookmarksDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_item:{
		create:"BookmarksDocAccessFields_item_Create",
		read:"BookmarksDocAccessFields_item_Read",
		update:"BookmarksDocAccessFields_item_Update",
		delete:"BookmarksDocAccessFields_item_Delete"
	},
	BookmarksDocAccessFields_item_Create:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_item_Read:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_item_Update:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_item_Delete:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_updatedAt:{
		create:"BookmarksDocAccessFields_updatedAt_Create",
		read:"BookmarksDocAccessFields_updatedAt_Read",
		update:"BookmarksDocAccessFields_updatedAt_Update",
		delete:"BookmarksDocAccessFields_updatedAt_Delete"
	},
	BookmarksDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_createdAt:{
		create:"BookmarksDocAccessFields_createdAt_Create",
		read:"BookmarksDocAccessFields_createdAt_Read",
		update:"BookmarksDocAccessFields_createdAt_Update",
		delete:"BookmarksDocAccessFields_createdAt_Delete"
	},
	BookmarksDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	BookmarksDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BookmarksCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecord:{
		id:"String",
		user:"ViewRecord_User_Relationship",
		item:"ViewRecord_Item_Relationship",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	ViewRecord_User_Relationship:{
		relationTo:"ViewRecord_User_RelationTo",
		value:"ViewRecord_User"
	},
	ViewRecord_User:{
		"...on User":"User"
	},
	ViewRecord_Item_Relationship:{
		relationTo:"ViewRecord_Item_RelationTo",
		value:"ViewRecord_Item"
	},
	ViewRecord_Item:{
		"...on Event":"Event",
		"...on Location":"Location",
		"...on Post":"Post"
	},
	ViewRecords:{
		docs:"ViewRecord",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countViewRecords:{
		totalDocs:"Int"
	},
	view_recordsDocAccess:{
		fields:"ViewRecordsDocAccessFields",
		create:"ViewRecordsCreateDocAccess",
		read:"ViewRecordsReadDocAccess",
		update:"ViewRecordsUpdateDocAccess",
		delete:"ViewRecordsDeleteDocAccess"
	},
	ViewRecordsDocAccessFields:{
		user:"ViewRecordsDocAccessFields_user",
		item:"ViewRecordsDocAccessFields_item",
		updatedAt:"ViewRecordsDocAccessFields_updatedAt",
		createdAt:"ViewRecordsDocAccessFields_createdAt"
	},
	ViewRecordsDocAccessFields_user:{
		create:"ViewRecordsDocAccessFields_user_Create",
		read:"ViewRecordsDocAccessFields_user_Read",
		update:"ViewRecordsDocAccessFields_user_Update",
		delete:"ViewRecordsDocAccessFields_user_Delete"
	},
	ViewRecordsDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_item:{
		create:"ViewRecordsDocAccessFields_item_Create",
		read:"ViewRecordsDocAccessFields_item_Read",
		update:"ViewRecordsDocAccessFields_item_Update",
		delete:"ViewRecordsDocAccessFields_item_Delete"
	},
	ViewRecordsDocAccessFields_item_Create:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_item_Read:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_item_Update:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_item_Delete:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_updatedAt:{
		create:"ViewRecordsDocAccessFields_updatedAt_Create",
		read:"ViewRecordsDocAccessFields_updatedAt_Read",
		update:"ViewRecordsDocAccessFields_updatedAt_Update",
		delete:"ViewRecordsDocAccessFields_updatedAt_Delete"
	},
	ViewRecordsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_createdAt:{
		create:"ViewRecordsDocAccessFields_createdAt_Create",
		read:"ViewRecordsDocAccessFields_createdAt_Read",
		update:"ViewRecordsDocAccessFields_createdAt_Update",
		delete:"ViewRecordsDocAccessFields_createdAt_Delete"
	},
	ViewRecordsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	ViewRecordsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	ViewRecordsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCategories:{
		docs:"EventCategory",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEventCategories:{
		totalDocs:"Int"
	},
	categoryDocAccess:{
		fields:"CategoryDocAccessFields",
		create:"CategoryCreateDocAccess",
		read:"CategoryReadDocAccess",
		update:"CategoryUpdateDocAccess",
		delete:"CategoryDeleteDocAccess"
	},
	CategoryDocAccessFields:{
		name:"CategoryDocAccessFields_name",
		value:"CategoryDocAccessFields_value",
		tags:"CategoryDocAccessFields_tags",
		icon:"CategoryDocAccessFields_icon",
		region:"CategoryDocAccessFields_region",
		updatedAt:"CategoryDocAccessFields_updatedAt",
		createdAt:"CategoryDocAccessFields_createdAt"
	},
	CategoryDocAccessFields_name:{
		create:"CategoryDocAccessFields_name_Create",
		read:"CategoryDocAccessFields_name_Read",
		update:"CategoryDocAccessFields_name_Update",
		delete:"CategoryDocAccessFields_name_Delete"
	},
	CategoryDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_value:{
		create:"CategoryDocAccessFields_value_Create",
		read:"CategoryDocAccessFields_value_Read",
		update:"CategoryDocAccessFields_value_Update",
		delete:"CategoryDocAccessFields_value_Delete"
	},
	CategoryDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_tags:{
		create:"CategoryDocAccessFields_tags_Create",
		read:"CategoryDocAccessFields_tags_Read",
		update:"CategoryDocAccessFields_tags_Update",
		delete:"CategoryDocAccessFields_tags_Delete"
	},
	CategoryDocAccessFields_tags_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_tags_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_tags_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_tags_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_icon:{
		create:"CategoryDocAccessFields_icon_Create",
		read:"CategoryDocAccessFields_icon_Read",
		update:"CategoryDocAccessFields_icon_Update",
		delete:"CategoryDocAccessFields_icon_Delete"
	},
	CategoryDocAccessFields_icon_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_icon_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_icon_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_icon_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_region:{
		create:"CategoryDocAccessFields_region_Create",
		read:"CategoryDocAccessFields_region_Read",
		update:"CategoryDocAccessFields_region_Update",
		delete:"CategoryDocAccessFields_region_Delete"
	},
	CategoryDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_updatedAt:{
		create:"CategoryDocAccessFields_updatedAt_Create",
		read:"CategoryDocAccessFields_updatedAt_Read",
		update:"CategoryDocAccessFields_updatedAt_Update",
		delete:"CategoryDocAccessFields_updatedAt_Delete"
	},
	CategoryDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_createdAt:{
		create:"CategoryDocAccessFields_createdAt_Create",
		read:"CategoryDocAccessFields_createdAt_Read",
		update:"CategoryDocAccessFields_createdAt_Update",
		delete:"CategoryDocAccessFields_createdAt_Delete"
	},
	CategoryDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	CategoryDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CategoryCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Comment:{
		id:"String",
		commenter:"User",
		content:"String",
		image:"Media",
		target:"Comment_Target_Relationship",
		parent:"Comment_Parent_Relationship",
		isReviewed:"Boolean",
		reviewedBy:"User",
		banned:"Boolean",
		type:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Comment_Target_Relationship:{
		relationTo:"Comment_Target_RelationTo",
		value:"Comment_Target"
	},
	Comment_Target:{
		"...on Event":"Event",
		"...on Post":"Post",
		"...on Location":"Location"
	},
	Comment_Parent_Relationship:{
		relationTo:"Comment_Parent_RelationTo",
		value:"Comment_Parent"
	},
	Comment_Parent:{
		"...on Comment":"Comment"
	},
	Comments:{
		docs:"Comment",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countComments:{
		totalDocs:"Int"
	},
	commentsDocAccess:{
		fields:"CommentsDocAccessFields",
		create:"CommentsCreateDocAccess",
		read:"CommentsReadDocAccess",
		update:"CommentsUpdateDocAccess",
		delete:"CommentsDeleteDocAccess"
	},
	CommentsDocAccessFields:{
		commenter:"CommentsDocAccessFields_commenter",
		content:"CommentsDocAccessFields_content",
		image:"CommentsDocAccessFields_image",
		target:"CommentsDocAccessFields_target",
		parent:"CommentsDocAccessFields_parent",
		isReviewed:"CommentsDocAccessFields_isReviewed",
		reviewedBy:"CommentsDocAccessFields_reviewedBy",
		banned:"CommentsDocAccessFields_banned",
		type:"CommentsDocAccessFields_type",
		updatedAt:"CommentsDocAccessFields_updatedAt",
		createdAt:"CommentsDocAccessFields_createdAt"
	},
	CommentsDocAccessFields_commenter:{
		create:"CommentsDocAccessFields_commenter_Create",
		read:"CommentsDocAccessFields_commenter_Read",
		update:"CommentsDocAccessFields_commenter_Update",
		delete:"CommentsDocAccessFields_commenter_Delete"
	},
	CommentsDocAccessFields_commenter_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_commenter_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_commenter_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_commenter_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_content:{
		create:"CommentsDocAccessFields_content_Create",
		read:"CommentsDocAccessFields_content_Read",
		update:"CommentsDocAccessFields_content_Update",
		delete:"CommentsDocAccessFields_content_Delete"
	},
	CommentsDocAccessFields_content_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_content_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_content_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_content_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_image:{
		create:"CommentsDocAccessFields_image_Create",
		read:"CommentsDocAccessFields_image_Read",
		update:"CommentsDocAccessFields_image_Update",
		delete:"CommentsDocAccessFields_image_Delete"
	},
	CommentsDocAccessFields_image_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_image_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_image_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_image_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_target:{
		create:"CommentsDocAccessFields_target_Create",
		read:"CommentsDocAccessFields_target_Read",
		update:"CommentsDocAccessFields_target_Update",
		delete:"CommentsDocAccessFields_target_Delete"
	},
	CommentsDocAccessFields_target_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_target_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_target_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_target_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_parent:{
		create:"CommentsDocAccessFields_parent_Create",
		read:"CommentsDocAccessFields_parent_Read",
		update:"CommentsDocAccessFields_parent_Update",
		delete:"CommentsDocAccessFields_parent_Delete"
	},
	CommentsDocAccessFields_parent_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_parent_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_parent_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_parent_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_isReviewed:{
		create:"CommentsDocAccessFields_isReviewed_Create",
		read:"CommentsDocAccessFields_isReviewed_Read",
		update:"CommentsDocAccessFields_isReviewed_Update",
		delete:"CommentsDocAccessFields_isReviewed_Delete"
	},
	CommentsDocAccessFields_isReviewed_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_isReviewed_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_isReviewed_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_isReviewed_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_reviewedBy:{
		create:"CommentsDocAccessFields_reviewedBy_Create",
		read:"CommentsDocAccessFields_reviewedBy_Read",
		update:"CommentsDocAccessFields_reviewedBy_Update",
		delete:"CommentsDocAccessFields_reviewedBy_Delete"
	},
	CommentsDocAccessFields_reviewedBy_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_reviewedBy_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_reviewedBy_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_reviewedBy_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_banned:{
		create:"CommentsDocAccessFields_banned_Create",
		read:"CommentsDocAccessFields_banned_Read",
		update:"CommentsDocAccessFields_banned_Update",
		delete:"CommentsDocAccessFields_banned_Delete"
	},
	CommentsDocAccessFields_banned_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_banned_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_banned_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_banned_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_type:{
		create:"CommentsDocAccessFields_type_Create",
		read:"CommentsDocAccessFields_type_Read",
		update:"CommentsDocAccessFields_type_Update",
		delete:"CommentsDocAccessFields_type_Delete"
	},
	CommentsDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_updatedAt:{
		create:"CommentsDocAccessFields_updatedAt_Create",
		read:"CommentsDocAccessFields_updatedAt_Read",
		update:"CommentsDocAccessFields_updatedAt_Update",
		delete:"CommentsDocAccessFields_updatedAt_Delete"
	},
	CommentsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_createdAt:{
		create:"CommentsDocAccessFields_createdAt_Create",
		read:"CommentsDocAccessFields_createdAt_Read",
		update:"CommentsDocAccessFields_createdAt_Update",
		delete:"CommentsDocAccessFields_createdAt_Delete"
	},
	CommentsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	CommentsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CommentsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendar:{
		id:"String",
		date:"DateTime",
		item:"Event",
		title:"String",
		region:"EventCalendar_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	EventCalendars:{
		docs:"EventCalendar",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEventCalendars:{
		totalDocs:"Int"
	},
	event_calendarDocAccess:{
		fields:"EventCalendarDocAccessFields",
		create:"EventCalendarCreateDocAccess",
		read:"EventCalendarReadDocAccess",
		update:"EventCalendarUpdateDocAccess",
		delete:"EventCalendarDeleteDocAccess"
	},
	EventCalendarDocAccessFields:{
		date:"EventCalendarDocAccessFields_date",
		item:"EventCalendarDocAccessFields_item",
		title:"EventCalendarDocAccessFields_title",
		region:"EventCalendarDocAccessFields_region",
		updatedAt:"EventCalendarDocAccessFields_updatedAt",
		createdAt:"EventCalendarDocAccessFields_createdAt"
	},
	EventCalendarDocAccessFields_date:{
		create:"EventCalendarDocAccessFields_date_Create",
		read:"EventCalendarDocAccessFields_date_Read",
		update:"EventCalendarDocAccessFields_date_Update",
		delete:"EventCalendarDocAccessFields_date_Delete"
	},
	EventCalendarDocAccessFields_date_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_date_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_date_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_date_Delete:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_item:{
		create:"EventCalendarDocAccessFields_item_Create",
		read:"EventCalendarDocAccessFields_item_Read",
		update:"EventCalendarDocAccessFields_item_Update",
		delete:"EventCalendarDocAccessFields_item_Delete"
	},
	EventCalendarDocAccessFields_item_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_item_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_item_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_item_Delete:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_title:{
		create:"EventCalendarDocAccessFields_title_Create",
		read:"EventCalendarDocAccessFields_title_Read",
		update:"EventCalendarDocAccessFields_title_Update",
		delete:"EventCalendarDocAccessFields_title_Delete"
	},
	EventCalendarDocAccessFields_title_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_title_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_title_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_title_Delete:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_region:{
		create:"EventCalendarDocAccessFields_region_Create",
		read:"EventCalendarDocAccessFields_region_Read",
		update:"EventCalendarDocAccessFields_region_Update",
		delete:"EventCalendarDocAccessFields_region_Delete"
	},
	EventCalendarDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_updatedAt:{
		create:"EventCalendarDocAccessFields_updatedAt_Create",
		read:"EventCalendarDocAccessFields_updatedAt_Read",
		update:"EventCalendarDocAccessFields_updatedAt_Update",
		delete:"EventCalendarDocAccessFields_updatedAt_Delete"
	},
	EventCalendarDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_createdAt:{
		create:"EventCalendarDocAccessFields_createdAt_Create",
		read:"EventCalendarDocAccessFields_createdAt_Read",
		update:"EventCalendarDocAccessFields_createdAt_Update",
		delete:"EventCalendarDocAccessFields_createdAt_Delete"
	},
	EventCalendarDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventCalendarDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventCalendarCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Subscription:{
		id:"String",
		user:"Subscription_User_Relationship",
		organiser:"Subscription_Organiser_Relationship",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Subscription_User_Relationship:{
		relationTo:"Subscription_User_RelationTo",
		value:"Subscription_User"
	},
	Subscription_User:{
		"...on User":"User"
	},
	Subscription_Organiser_Relationship:{
		relationTo:"Subscription_Organiser_RelationTo",
		value:"Subscription_Organiser"
	},
	Subscription_Organiser:{
		"...on User":"User"
	},
	Subscriptions:{
		docs:"Subscription",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countSubscriptions:{
		totalDocs:"Int"
	},
	subscriptionsDocAccess:{
		fields:"SubscriptionsDocAccessFields",
		create:"SubscriptionsCreateDocAccess",
		read:"SubscriptionsReadDocAccess",
		update:"SubscriptionsUpdateDocAccess",
		delete:"SubscriptionsDeleteDocAccess"
	},
	SubscriptionsDocAccessFields:{
		user:"SubscriptionsDocAccessFields_user",
		organiser:"SubscriptionsDocAccessFields_organiser",
		updatedAt:"SubscriptionsDocAccessFields_updatedAt",
		createdAt:"SubscriptionsDocAccessFields_createdAt"
	},
	SubscriptionsDocAccessFields_user:{
		create:"SubscriptionsDocAccessFields_user_Create",
		read:"SubscriptionsDocAccessFields_user_Read",
		update:"SubscriptionsDocAccessFields_user_Update",
		delete:"SubscriptionsDocAccessFields_user_Delete"
	},
	SubscriptionsDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_organiser:{
		create:"SubscriptionsDocAccessFields_organiser_Create",
		read:"SubscriptionsDocAccessFields_organiser_Read",
		update:"SubscriptionsDocAccessFields_organiser_Update",
		delete:"SubscriptionsDocAccessFields_organiser_Delete"
	},
	SubscriptionsDocAccessFields_organiser_Create:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_organiser_Read:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_organiser_Update:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_organiser_Delete:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_updatedAt:{
		create:"SubscriptionsDocAccessFields_updatedAt_Create",
		read:"SubscriptionsDocAccessFields_updatedAt_Read",
		update:"SubscriptionsDocAccessFields_updatedAt_Update",
		delete:"SubscriptionsDocAccessFields_updatedAt_Delete"
	},
	SubscriptionsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_createdAt:{
		create:"SubscriptionsDocAccessFields_createdAt_Create",
		read:"SubscriptionsDocAccessFields_createdAt_Read",
		update:"SubscriptionsDocAccessFields_createdAt_Update",
		delete:"SubscriptionsDocAccessFields_createdAt_Delete"
	},
	SubscriptionsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	SubscriptionsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	SubscriptionsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoice:{
		id:"String",
		name:"String",
		visible:"Boolean",
		editorialPicks:"Event",
		list:"EditorChoice_List",
		region:"EditorChoice_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	EditorChoice_List:{
		item:"Event",
		id:"String"
	},
	EditorChoices:{
		docs:"EditorChoice",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEditorChoices:{
		totalDocs:"Int"
	},
	editor_choicesDocAccess:{
		fields:"EditorChoicesDocAccessFields",
		create:"EditorChoicesCreateDocAccess",
		read:"EditorChoicesReadDocAccess",
		update:"EditorChoicesUpdateDocAccess",
		delete:"EditorChoicesDeleteDocAccess"
	},
	EditorChoicesDocAccessFields:{
		name:"EditorChoicesDocAccessFields_name",
		visible:"EditorChoicesDocAccessFields_visible",
		editorialPicks:"EditorChoicesDocAccessFields_editorialPicks",
		list:"EditorChoicesDocAccessFields_list",
		region:"EditorChoicesDocAccessFields_region",
		updatedAt:"EditorChoicesDocAccessFields_updatedAt",
		createdAt:"EditorChoicesDocAccessFields_createdAt"
	},
	EditorChoicesDocAccessFields_name:{
		create:"EditorChoicesDocAccessFields_name_Create",
		read:"EditorChoicesDocAccessFields_name_Read",
		update:"EditorChoicesDocAccessFields_name_Update",
		delete:"EditorChoicesDocAccessFields_name_Delete"
	},
	EditorChoicesDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_visible:{
		create:"EditorChoicesDocAccessFields_visible_Create",
		read:"EditorChoicesDocAccessFields_visible_Read",
		update:"EditorChoicesDocAccessFields_visible_Update",
		delete:"EditorChoicesDocAccessFields_visible_Delete"
	},
	EditorChoicesDocAccessFields_visible_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_visible_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_visible_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_visible_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_editorialPicks:{
		create:"EditorChoicesDocAccessFields_editorialPicks_Create",
		read:"EditorChoicesDocAccessFields_editorialPicks_Read",
		update:"EditorChoicesDocAccessFields_editorialPicks_Update",
		delete:"EditorChoicesDocAccessFields_editorialPicks_Delete"
	},
	EditorChoicesDocAccessFields_editorialPicks_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_editorialPicks_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_editorialPicks_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_editorialPicks_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list:{
		create:"EditorChoicesDocAccessFields_list_Create",
		read:"EditorChoicesDocAccessFields_list_Read",
		update:"EditorChoicesDocAccessFields_list_Update",
		delete:"EditorChoicesDocAccessFields_list_Delete",
		fields:"EditorChoicesDocAccessFields_list_Fields"
	},
	EditorChoicesDocAccessFields_list_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_Fields:{
		item:"EditorChoicesDocAccessFields_list_item",
		id:"EditorChoicesDocAccessFields_list_id"
	},
	EditorChoicesDocAccessFields_list_item:{
		create:"EditorChoicesDocAccessFields_list_item_Create",
		read:"EditorChoicesDocAccessFields_list_item_Read",
		update:"EditorChoicesDocAccessFields_list_item_Update",
		delete:"EditorChoicesDocAccessFields_list_item_Delete"
	},
	EditorChoicesDocAccessFields_list_item_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_item_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_item_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_item_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_id:{
		create:"EditorChoicesDocAccessFields_list_id_Create",
		read:"EditorChoicesDocAccessFields_list_id_Read",
		update:"EditorChoicesDocAccessFields_list_id_Update",
		delete:"EditorChoicesDocAccessFields_list_id_Delete"
	},
	EditorChoicesDocAccessFields_list_id_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_id_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_id_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_list_id_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_region:{
		create:"EditorChoicesDocAccessFields_region_Create",
		read:"EditorChoicesDocAccessFields_region_Read",
		update:"EditorChoicesDocAccessFields_region_Update",
		delete:"EditorChoicesDocAccessFields_region_Delete"
	},
	EditorChoicesDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_updatedAt:{
		create:"EditorChoicesDocAccessFields_updatedAt_Create",
		read:"EditorChoicesDocAccessFields_updatedAt_Read",
		update:"EditorChoicesDocAccessFields_updatedAt_Update",
		delete:"EditorChoicesDocAccessFields_updatedAt_Delete"
	},
	EditorChoicesDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_createdAt:{
		create:"EditorChoicesDocAccessFields_createdAt_Create",
		read:"EditorChoicesDocAccessFields_createdAt_Read",
		update:"EditorChoicesDocAccessFields_createdAt_Update",
		delete:"EditorChoicesDocAccessFields_createdAt_Delete"
	},
	EditorChoicesDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	EditorChoicesDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EditorChoicesCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSection:{
		id:"String",
		name:"String",
		slug:"String",
		banners:"BannerSection_Banners",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	BannerSection_Banners:{
		name:"String",
		status:"String",
		desktop:"Media",
		bannerStartDate:"DateTime",
		bannerEndDate:"DateTime",
		region:"BannerSection_Banners_region",
		id:"String"
	},
	BannerSections:{
		docs:"BannerSection",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countBannerSections:{
		totalDocs:"Int"
	},
	banner_sectionDocAccess:{
		fields:"BannerSectionDocAccessFields",
		create:"BannerSectionCreateDocAccess",
		read:"BannerSectionReadDocAccess",
		update:"BannerSectionUpdateDocAccess",
		delete:"BannerSectionDeleteDocAccess"
	},
	BannerSectionDocAccessFields:{
		name:"BannerSectionDocAccessFields_name",
		slug:"BannerSectionDocAccessFields_slug",
		banners:"BannerSectionDocAccessFields_banners",
		updatedAt:"BannerSectionDocAccessFields_updatedAt",
		createdAt:"BannerSectionDocAccessFields_createdAt"
	},
	BannerSectionDocAccessFields_name:{
		create:"BannerSectionDocAccessFields_name_Create",
		read:"BannerSectionDocAccessFields_name_Read",
		update:"BannerSectionDocAccessFields_name_Update",
		delete:"BannerSectionDocAccessFields_name_Delete"
	},
	BannerSectionDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_slug:{
		create:"BannerSectionDocAccessFields_slug_Create",
		read:"BannerSectionDocAccessFields_slug_Read",
		update:"BannerSectionDocAccessFields_slug_Update",
		delete:"BannerSectionDocAccessFields_slug_Delete"
	},
	BannerSectionDocAccessFields_slug_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_slug_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_slug_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_slug_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners:{
		create:"BannerSectionDocAccessFields_banners_Create",
		read:"BannerSectionDocAccessFields_banners_Read",
		update:"BannerSectionDocAccessFields_banners_Update",
		delete:"BannerSectionDocAccessFields_banners_Delete",
		fields:"BannerSectionDocAccessFields_banners_Fields"
	},
	BannerSectionDocAccessFields_banners_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_Fields:{
		name:"BannerSectionDocAccessFields_banners_name",
		status:"BannerSectionDocAccessFields_banners_status",
		desktop:"BannerSectionDocAccessFields_banners_desktop",
		bannerStartDate:"BannerSectionDocAccessFields_banners_bannerStartDate",
		bannerEndDate:"BannerSectionDocAccessFields_banners_bannerEndDate",
		region:"BannerSectionDocAccessFields_banners_region",
		id:"BannerSectionDocAccessFields_banners_id"
	},
	BannerSectionDocAccessFields_banners_name:{
		create:"BannerSectionDocAccessFields_banners_name_Create",
		read:"BannerSectionDocAccessFields_banners_name_Read",
		update:"BannerSectionDocAccessFields_banners_name_Update",
		delete:"BannerSectionDocAccessFields_banners_name_Delete"
	},
	BannerSectionDocAccessFields_banners_name_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_name_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_name_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_name_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_status:{
		create:"BannerSectionDocAccessFields_banners_status_Create",
		read:"BannerSectionDocAccessFields_banners_status_Read",
		update:"BannerSectionDocAccessFields_banners_status_Update",
		delete:"BannerSectionDocAccessFields_banners_status_Delete"
	},
	BannerSectionDocAccessFields_banners_status_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_status_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_status_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_status_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_desktop:{
		create:"BannerSectionDocAccessFields_banners_desktop_Create",
		read:"BannerSectionDocAccessFields_banners_desktop_Read",
		update:"BannerSectionDocAccessFields_banners_desktop_Update",
		delete:"BannerSectionDocAccessFields_banners_desktop_Delete"
	},
	BannerSectionDocAccessFields_banners_desktop_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_desktop_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_desktop_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_desktop_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerStartDate:{
		create:"BannerSectionDocAccessFields_banners_bannerStartDate_Create",
		read:"BannerSectionDocAccessFields_banners_bannerStartDate_Read",
		update:"BannerSectionDocAccessFields_banners_bannerStartDate_Update",
		delete:"BannerSectionDocAccessFields_banners_bannerStartDate_Delete"
	},
	BannerSectionDocAccessFields_banners_bannerStartDate_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerStartDate_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerStartDate_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerEndDate:{
		create:"BannerSectionDocAccessFields_banners_bannerEndDate_Create",
		read:"BannerSectionDocAccessFields_banners_bannerEndDate_Read",
		update:"BannerSectionDocAccessFields_banners_bannerEndDate_Update",
		delete:"BannerSectionDocAccessFields_banners_bannerEndDate_Delete"
	},
	BannerSectionDocAccessFields_banners_bannerEndDate_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerEndDate_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerEndDate_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_region:{
		create:"BannerSectionDocAccessFields_banners_region_Create",
		read:"BannerSectionDocAccessFields_banners_region_Read",
		update:"BannerSectionDocAccessFields_banners_region_Update",
		delete:"BannerSectionDocAccessFields_banners_region_Delete"
	},
	BannerSectionDocAccessFields_banners_region_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_region_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_region_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_region_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_id:{
		create:"BannerSectionDocAccessFields_banners_id_Create",
		read:"BannerSectionDocAccessFields_banners_id_Read",
		update:"BannerSectionDocAccessFields_banners_id_Update",
		delete:"BannerSectionDocAccessFields_banners_id_Delete"
	},
	BannerSectionDocAccessFields_banners_id_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_id_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_id_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_banners_id_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_updatedAt:{
		create:"BannerSectionDocAccessFields_updatedAt_Create",
		read:"BannerSectionDocAccessFields_updatedAt_Read",
		update:"BannerSectionDocAccessFields_updatedAt_Update",
		delete:"BannerSectionDocAccessFields_updatedAt_Delete"
	},
	BannerSectionDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_createdAt:{
		create:"BannerSectionDocAccessFields_createdAt_Create",
		read:"BannerSectionDocAccessFields_createdAt_Read",
		update:"BannerSectionDocAccessFields_createdAt_Update",
		delete:"BannerSectionDocAccessFields_createdAt_Delete"
	},
	BannerSectionDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	BannerSectionDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BannerSectionCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSection:{
		id:"String",
		name:"String",
		slug:"String",
		banners:"MacaoBannerSection_Banners",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	MacaoBannerSection_Banners:{
		name:"String",
		status:"String",
		desktop:"Media",
		bannerStartDate:"DateTime",
		bannerEndDate:"DateTime",
		region:"MacaoBannerSection_Banners_region",
		id:"String"
	},
	MacaoBannerSections:{
		docs:"MacaoBannerSection",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countMacaoBannerSections:{
		totalDocs:"Int"
	},
	macao_banner_sectionDocAccess:{
		fields:"MacaoBannerSectionDocAccessFields",
		create:"MacaoBannerSectionCreateDocAccess",
		read:"MacaoBannerSectionReadDocAccess",
		update:"MacaoBannerSectionUpdateDocAccess",
		delete:"MacaoBannerSectionDeleteDocAccess"
	},
	MacaoBannerSectionDocAccessFields:{
		name:"MacaoBannerSectionDocAccessFields_name",
		slug:"MacaoBannerSectionDocAccessFields_slug",
		banners:"MacaoBannerSectionDocAccessFields_banners",
		updatedAt:"MacaoBannerSectionDocAccessFields_updatedAt",
		createdAt:"MacaoBannerSectionDocAccessFields_createdAt"
	},
	MacaoBannerSectionDocAccessFields_name:{
		create:"MacaoBannerSectionDocAccessFields_name_Create",
		read:"MacaoBannerSectionDocAccessFields_name_Read",
		update:"MacaoBannerSectionDocAccessFields_name_Update",
		delete:"MacaoBannerSectionDocAccessFields_name_Delete"
	},
	MacaoBannerSectionDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_slug:{
		create:"MacaoBannerSectionDocAccessFields_slug_Create",
		read:"MacaoBannerSectionDocAccessFields_slug_Read",
		update:"MacaoBannerSectionDocAccessFields_slug_Update",
		delete:"MacaoBannerSectionDocAccessFields_slug_Delete"
	},
	MacaoBannerSectionDocAccessFields_slug_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_slug_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_slug_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_slug_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners:{
		create:"MacaoBannerSectionDocAccessFields_banners_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_Delete",
		fields:"MacaoBannerSectionDocAccessFields_banners_Fields"
	},
	MacaoBannerSectionDocAccessFields_banners_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_Fields:{
		name:"MacaoBannerSectionDocAccessFields_banners_name",
		status:"MacaoBannerSectionDocAccessFields_banners_status",
		desktop:"MacaoBannerSectionDocAccessFields_banners_desktop",
		bannerStartDate:"MacaoBannerSectionDocAccessFields_banners_bannerStartDate",
		bannerEndDate:"MacaoBannerSectionDocAccessFields_banners_bannerEndDate",
		region:"MacaoBannerSectionDocAccessFields_banners_region",
		id:"MacaoBannerSectionDocAccessFields_banners_id"
	},
	MacaoBannerSectionDocAccessFields_banners_name:{
		create:"MacaoBannerSectionDocAccessFields_banners_name_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_name_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_name_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_name_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_name_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_name_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_name_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_name_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_status:{
		create:"MacaoBannerSectionDocAccessFields_banners_status_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_status_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_status_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_status_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_status_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_status_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_status_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_status_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_desktop:{
		create:"MacaoBannerSectionDocAccessFields_banners_desktop_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_desktop_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_desktop_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_desktop_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_desktop_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_desktop_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_desktop_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_desktop_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerStartDate:{
		create:"MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerEndDate:{
		create:"MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_region:{
		create:"MacaoBannerSectionDocAccessFields_banners_region_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_region_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_region_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_region_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_region_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_region_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_region_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_region_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_id:{
		create:"MacaoBannerSectionDocAccessFields_banners_id_Create",
		read:"MacaoBannerSectionDocAccessFields_banners_id_Read",
		update:"MacaoBannerSectionDocAccessFields_banners_id_Update",
		delete:"MacaoBannerSectionDocAccessFields_banners_id_Delete"
	},
	MacaoBannerSectionDocAccessFields_banners_id_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_id_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_id_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_banners_id_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_updatedAt:{
		create:"MacaoBannerSectionDocAccessFields_updatedAt_Create",
		read:"MacaoBannerSectionDocAccessFields_updatedAt_Read",
		update:"MacaoBannerSectionDocAccessFields_updatedAt_Update",
		delete:"MacaoBannerSectionDocAccessFields_updatedAt_Delete"
	},
	MacaoBannerSectionDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_createdAt:{
		create:"MacaoBannerSectionDocAccessFields_createdAt_Create",
		read:"MacaoBannerSectionDocAccessFields_createdAt_Read",
		update:"MacaoBannerSectionDocAccessFields_createdAt_Update",
		delete:"MacaoBannerSectionDocAccessFields_createdAt_Delete"
	},
	MacaoBannerSectionDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Banner:{
		id:"String",
		name:"String",
		desktop:"Media",
		mobile:"Media",
		bannerStartDate:"DateTime",
		bannerEndDate:"DateTime",
		location:"Banner_Location",
		link:"String",
		external:"Boolean",
		type:"Banner_type",
		status:"Banner_status",
		advertise:"Banner_Advertise",
		_locations:"String",
		region:"Banner_region",
		homePageClick:"Float",
		homePageImpression:"Float",
		latestEventClick:"Float",
		latestEventImpression:"Float",
		editorChoiceClick:"Float",
		editorChoiceImpression:"Float",
		eventListClick:"Float",
		eventListImpression:"Float",
		topTenClick:"Float",
		topTenImpression:"Float",
		miscClick:"Float",
		miscImpression:"Float",
		clickTotal:"Float",
		totalImpression:"Float",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Banner_Location:{
		page:"Banner_Location_page",
		eventRankingList:"EventRanking",
		locationRankingList:"LocationRanking",
		categories:"EventCategory",
		time:"Boolean",
		district:"Boolean",
		audience:"Boolean",
		multipleCriteriaSearch:"Boolean"
	},
	Banner_Advertise:{
		campaign:"Campaign",
		invoiceDescription:"String",
		standardPrice:"Float",
		discount:"Float",
		timableCost:"Float",
		remark:"String",
		public:"Boolean"
	},
	Campaign:{
		id:"String",
		creator:"Campaign_Creator_Relationship",
		invoiceNumber:"String",
		quotationNumber:"String",
		campaignName:"String",
		client:"String",
		contactPerson:"String",
		email:"String",
		phone:"String",
		agentName:"String",
		agentCommission:"Float",
		agentOrderNumber:"String",
		_firstPublishedTime:"DateTime",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Campaign_Creator_Relationship:{
		relationTo:"Campaign_Creator_RelationTo",
		value:"Campaign_Creator"
	},
	Campaign_Creator:{
		"...on User":"User"
	},
	Banners:{
		docs:"Banner",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countBanners:{
		totalDocs:"Int"
	},
	bannersDocAccess:{
		fields:"BannersDocAccessFields",
		create:"BannersCreateDocAccess",
		read:"BannersReadDocAccess",
		update:"BannersUpdateDocAccess",
		delete:"BannersDeleteDocAccess"
	},
	BannersDocAccessFields:{
		name:"BannersDocAccessFields_name",
		desktop:"BannersDocAccessFields_desktop",
		mobile:"BannersDocAccessFields_mobile",
		bannerStartDate:"BannersDocAccessFields_bannerStartDate",
		bannerEndDate:"BannersDocAccessFields_bannerEndDate",
		location:"BannersDocAccessFields_location",
		link:"BannersDocAccessFields_link",
		external:"BannersDocAccessFields_external",
		type:"BannersDocAccessFields_type",
		status:"BannersDocAccessFields_status",
		advertise:"BannersDocAccessFields_advertise",
		_locations:"BannersDocAccessFields__locations",
		region:"BannersDocAccessFields_region",
		homePageClick:"BannersDocAccessFields_homePageClick",
		homePageImpression:"BannersDocAccessFields_homePageImpression",
		latestEventClick:"BannersDocAccessFields_latestEventClick",
		latestEventImpression:"BannersDocAccessFields_latestEventImpression",
		editorChoiceClick:"BannersDocAccessFields_editorChoiceClick",
		editorChoiceImpression:"BannersDocAccessFields_editorChoiceImpression",
		eventListClick:"BannersDocAccessFields_eventListClick",
		eventListImpression:"BannersDocAccessFields_eventListImpression",
		topTenClick:"BannersDocAccessFields_topTenClick",
		topTenImpression:"BannersDocAccessFields_topTenImpression",
		miscClick:"BannersDocAccessFields_miscClick",
		miscImpression:"BannersDocAccessFields_miscImpression",
		clickTotal:"BannersDocAccessFields_clickTotal",
		totalImpression:"BannersDocAccessFields_totalImpression",
		updatedAt:"BannersDocAccessFields_updatedAt",
		createdAt:"BannersDocAccessFields_createdAt"
	},
	BannersDocAccessFields_name:{
		create:"BannersDocAccessFields_name_Create",
		read:"BannersDocAccessFields_name_Read",
		update:"BannersDocAccessFields_name_Update",
		delete:"BannersDocAccessFields_name_Delete"
	},
	BannersDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_desktop:{
		create:"BannersDocAccessFields_desktop_Create",
		read:"BannersDocAccessFields_desktop_Read",
		update:"BannersDocAccessFields_desktop_Update",
		delete:"BannersDocAccessFields_desktop_Delete"
	},
	BannersDocAccessFields_desktop_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_desktop_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_desktop_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_desktop_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_mobile:{
		create:"BannersDocAccessFields_mobile_Create",
		read:"BannersDocAccessFields_mobile_Read",
		update:"BannersDocAccessFields_mobile_Update",
		delete:"BannersDocAccessFields_mobile_Delete"
	},
	BannersDocAccessFields_mobile_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_mobile_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_mobile_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_mobile_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerStartDate:{
		create:"BannersDocAccessFields_bannerStartDate_Create",
		read:"BannersDocAccessFields_bannerStartDate_Read",
		update:"BannersDocAccessFields_bannerStartDate_Update",
		delete:"BannersDocAccessFields_bannerStartDate_Delete"
	},
	BannersDocAccessFields_bannerStartDate_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerStartDate_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerStartDate_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerEndDate:{
		create:"BannersDocAccessFields_bannerEndDate_Create",
		read:"BannersDocAccessFields_bannerEndDate_Read",
		update:"BannersDocAccessFields_bannerEndDate_Update",
		delete:"BannersDocAccessFields_bannerEndDate_Delete"
	},
	BannersDocAccessFields_bannerEndDate_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerEndDate_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerEndDate_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location:{
		create:"BannersDocAccessFields_location_Create",
		read:"BannersDocAccessFields_location_Read",
		update:"BannersDocAccessFields_location_Update",
		delete:"BannersDocAccessFields_location_Delete",
		fields:"BannersDocAccessFields_location_Fields"
	},
	BannersDocAccessFields_location_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_Fields:{
		page:"BannersDocAccessFields_location_page",
		eventRankingList:"BannersDocAccessFields_location_eventRankingList",
		locationRankingList:"BannersDocAccessFields_location_locationRankingList",
		categories:"BannersDocAccessFields_location_categories",
		time:"BannersDocAccessFields_location_time",
		district:"BannersDocAccessFields_location_district",
		audience:"BannersDocAccessFields_location_audience",
		multipleCriteriaSearch:"BannersDocAccessFields_location_multipleCriteriaSearch"
	},
	BannersDocAccessFields_location_page:{
		create:"BannersDocAccessFields_location_page_Create",
		read:"BannersDocAccessFields_location_page_Read",
		update:"BannersDocAccessFields_location_page_Update",
		delete:"BannersDocAccessFields_location_page_Delete"
	},
	BannersDocAccessFields_location_page_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_page_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_page_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_page_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_eventRankingList:{
		create:"BannersDocAccessFields_location_eventRankingList_Create",
		read:"BannersDocAccessFields_location_eventRankingList_Read",
		update:"BannersDocAccessFields_location_eventRankingList_Update",
		delete:"BannersDocAccessFields_location_eventRankingList_Delete"
	},
	BannersDocAccessFields_location_eventRankingList_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_eventRankingList_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_eventRankingList_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_eventRankingList_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_locationRankingList:{
		create:"BannersDocAccessFields_location_locationRankingList_Create",
		read:"BannersDocAccessFields_location_locationRankingList_Read",
		update:"BannersDocAccessFields_location_locationRankingList_Update",
		delete:"BannersDocAccessFields_location_locationRankingList_Delete"
	},
	BannersDocAccessFields_location_locationRankingList_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_locationRankingList_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_locationRankingList_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_locationRankingList_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_categories:{
		create:"BannersDocAccessFields_location_categories_Create",
		read:"BannersDocAccessFields_location_categories_Read",
		update:"BannersDocAccessFields_location_categories_Update",
		delete:"BannersDocAccessFields_location_categories_Delete"
	},
	BannersDocAccessFields_location_categories_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_categories_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_categories_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_categories_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_time:{
		create:"BannersDocAccessFields_location_time_Create",
		read:"BannersDocAccessFields_location_time_Read",
		update:"BannersDocAccessFields_location_time_Update",
		delete:"BannersDocAccessFields_location_time_Delete"
	},
	BannersDocAccessFields_location_time_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_time_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_time_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_time_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_district:{
		create:"BannersDocAccessFields_location_district_Create",
		read:"BannersDocAccessFields_location_district_Read",
		update:"BannersDocAccessFields_location_district_Update",
		delete:"BannersDocAccessFields_location_district_Delete"
	},
	BannersDocAccessFields_location_district_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_district_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_district_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_district_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_audience:{
		create:"BannersDocAccessFields_location_audience_Create",
		read:"BannersDocAccessFields_location_audience_Read",
		update:"BannersDocAccessFields_location_audience_Update",
		delete:"BannersDocAccessFields_location_audience_Delete"
	},
	BannersDocAccessFields_location_audience_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_audience_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_audience_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_audience_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_multipleCriteriaSearch:{
		create:"BannersDocAccessFields_location_multipleCriteriaSearch_Create",
		read:"BannersDocAccessFields_location_multipleCriteriaSearch_Read",
		update:"BannersDocAccessFields_location_multipleCriteriaSearch_Update",
		delete:"BannersDocAccessFields_location_multipleCriteriaSearch_Delete"
	},
	BannersDocAccessFields_location_multipleCriteriaSearch_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_multipleCriteriaSearch_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_multipleCriteriaSearch_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_location_multipleCriteriaSearch_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_link:{
		create:"BannersDocAccessFields_link_Create",
		read:"BannersDocAccessFields_link_Read",
		update:"BannersDocAccessFields_link_Update",
		delete:"BannersDocAccessFields_link_Delete"
	},
	BannersDocAccessFields_link_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_link_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_link_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_link_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_external:{
		create:"BannersDocAccessFields_external_Create",
		read:"BannersDocAccessFields_external_Read",
		update:"BannersDocAccessFields_external_Update",
		delete:"BannersDocAccessFields_external_Delete"
	},
	BannersDocAccessFields_external_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_external_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_external_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_external_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_type:{
		create:"BannersDocAccessFields_type_Create",
		read:"BannersDocAccessFields_type_Read",
		update:"BannersDocAccessFields_type_Update",
		delete:"BannersDocAccessFields_type_Delete"
	},
	BannersDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_status:{
		create:"BannersDocAccessFields_status_Create",
		read:"BannersDocAccessFields_status_Read",
		update:"BannersDocAccessFields_status_Update",
		delete:"BannersDocAccessFields_status_Delete"
	},
	BannersDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise:{
		create:"BannersDocAccessFields_advertise_Create",
		read:"BannersDocAccessFields_advertise_Read",
		update:"BannersDocAccessFields_advertise_Update",
		delete:"BannersDocAccessFields_advertise_Delete",
		fields:"BannersDocAccessFields_advertise_Fields"
	},
	BannersDocAccessFields_advertise_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_Fields:{
		campaign:"BannersDocAccessFields_advertise_campaign",
		invoiceDescription:"BannersDocAccessFields_advertise_invoiceDescription",
		standardPrice:"BannersDocAccessFields_advertise_standardPrice",
		discount:"BannersDocAccessFields_advertise_discount",
		timableCost:"BannersDocAccessFields_advertise_timableCost",
		remark:"BannersDocAccessFields_advertise_remark",
		public:"BannersDocAccessFields_advertise_public"
	},
	BannersDocAccessFields_advertise_campaign:{
		create:"BannersDocAccessFields_advertise_campaign_Create",
		read:"BannersDocAccessFields_advertise_campaign_Read",
		update:"BannersDocAccessFields_advertise_campaign_Update",
		delete:"BannersDocAccessFields_advertise_campaign_Delete"
	},
	BannersDocAccessFields_advertise_campaign_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_campaign_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_campaign_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_campaign_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_invoiceDescription:{
		create:"BannersDocAccessFields_advertise_invoiceDescription_Create",
		read:"BannersDocAccessFields_advertise_invoiceDescription_Read",
		update:"BannersDocAccessFields_advertise_invoiceDescription_Update",
		delete:"BannersDocAccessFields_advertise_invoiceDescription_Delete"
	},
	BannersDocAccessFields_advertise_invoiceDescription_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_invoiceDescription_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_invoiceDescription_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_invoiceDescription_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_standardPrice:{
		create:"BannersDocAccessFields_advertise_standardPrice_Create",
		read:"BannersDocAccessFields_advertise_standardPrice_Read",
		update:"BannersDocAccessFields_advertise_standardPrice_Update",
		delete:"BannersDocAccessFields_advertise_standardPrice_Delete"
	},
	BannersDocAccessFields_advertise_standardPrice_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_standardPrice_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_standardPrice_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_standardPrice_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_discount:{
		create:"BannersDocAccessFields_advertise_discount_Create",
		read:"BannersDocAccessFields_advertise_discount_Read",
		update:"BannersDocAccessFields_advertise_discount_Update",
		delete:"BannersDocAccessFields_advertise_discount_Delete"
	},
	BannersDocAccessFields_advertise_discount_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_discount_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_discount_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_discount_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_timableCost:{
		create:"BannersDocAccessFields_advertise_timableCost_Create",
		read:"BannersDocAccessFields_advertise_timableCost_Read",
		update:"BannersDocAccessFields_advertise_timableCost_Update",
		delete:"BannersDocAccessFields_advertise_timableCost_Delete"
	},
	BannersDocAccessFields_advertise_timableCost_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_timableCost_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_timableCost_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_timableCost_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_remark:{
		create:"BannersDocAccessFields_advertise_remark_Create",
		read:"BannersDocAccessFields_advertise_remark_Read",
		update:"BannersDocAccessFields_advertise_remark_Update",
		delete:"BannersDocAccessFields_advertise_remark_Delete"
	},
	BannersDocAccessFields_advertise_remark_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_remark_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_remark_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_remark_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_public:{
		create:"BannersDocAccessFields_advertise_public_Create",
		read:"BannersDocAccessFields_advertise_public_Read",
		update:"BannersDocAccessFields_advertise_public_Update",
		delete:"BannersDocAccessFields_advertise_public_Delete"
	},
	BannersDocAccessFields_advertise_public_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_public_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_public_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_advertise_public_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields__locations:{
		create:"BannersDocAccessFields__locations_Create",
		read:"BannersDocAccessFields__locations_Read",
		update:"BannersDocAccessFields__locations_Update",
		delete:"BannersDocAccessFields__locations_Delete"
	},
	BannersDocAccessFields__locations_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields__locations_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields__locations_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields__locations_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_region:{
		create:"BannersDocAccessFields_region_Create",
		read:"BannersDocAccessFields_region_Read",
		update:"BannersDocAccessFields_region_Update",
		delete:"BannersDocAccessFields_region_Delete"
	},
	BannersDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageClick:{
		create:"BannersDocAccessFields_homePageClick_Create",
		read:"BannersDocAccessFields_homePageClick_Read",
		update:"BannersDocAccessFields_homePageClick_Update",
		delete:"BannersDocAccessFields_homePageClick_Delete"
	},
	BannersDocAccessFields_homePageClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageImpression:{
		create:"BannersDocAccessFields_homePageImpression_Create",
		read:"BannersDocAccessFields_homePageImpression_Read",
		update:"BannersDocAccessFields_homePageImpression_Update",
		delete:"BannersDocAccessFields_homePageImpression_Delete"
	},
	BannersDocAccessFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventClick:{
		create:"BannersDocAccessFields_latestEventClick_Create",
		read:"BannersDocAccessFields_latestEventClick_Read",
		update:"BannersDocAccessFields_latestEventClick_Update",
		delete:"BannersDocAccessFields_latestEventClick_Delete"
	},
	BannersDocAccessFields_latestEventClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventImpression:{
		create:"BannersDocAccessFields_latestEventImpression_Create",
		read:"BannersDocAccessFields_latestEventImpression_Read",
		update:"BannersDocAccessFields_latestEventImpression_Update",
		delete:"BannersDocAccessFields_latestEventImpression_Delete"
	},
	BannersDocAccessFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceClick:{
		create:"BannersDocAccessFields_editorChoiceClick_Create",
		read:"BannersDocAccessFields_editorChoiceClick_Read",
		update:"BannersDocAccessFields_editorChoiceClick_Update",
		delete:"BannersDocAccessFields_editorChoiceClick_Delete"
	},
	BannersDocAccessFields_editorChoiceClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceImpression:{
		create:"BannersDocAccessFields_editorChoiceImpression_Create",
		read:"BannersDocAccessFields_editorChoiceImpression_Read",
		update:"BannersDocAccessFields_editorChoiceImpression_Update",
		delete:"BannersDocAccessFields_editorChoiceImpression_Delete"
	},
	BannersDocAccessFields_editorChoiceImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_editorChoiceImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListClick:{
		create:"BannersDocAccessFields_eventListClick_Create",
		read:"BannersDocAccessFields_eventListClick_Read",
		update:"BannersDocAccessFields_eventListClick_Update",
		delete:"BannersDocAccessFields_eventListClick_Delete"
	},
	BannersDocAccessFields_eventListClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListImpression:{
		create:"BannersDocAccessFields_eventListImpression_Create",
		read:"BannersDocAccessFields_eventListImpression_Read",
		update:"BannersDocAccessFields_eventListImpression_Update",
		delete:"BannersDocAccessFields_eventListImpression_Delete"
	},
	BannersDocAccessFields_eventListImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_eventListImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenClick:{
		create:"BannersDocAccessFields_topTenClick_Create",
		read:"BannersDocAccessFields_topTenClick_Read",
		update:"BannersDocAccessFields_topTenClick_Update",
		delete:"BannersDocAccessFields_topTenClick_Delete"
	},
	BannersDocAccessFields_topTenClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenImpression:{
		create:"BannersDocAccessFields_topTenImpression_Create",
		read:"BannersDocAccessFields_topTenImpression_Read",
		update:"BannersDocAccessFields_topTenImpression_Update",
		delete:"BannersDocAccessFields_topTenImpression_Delete"
	},
	BannersDocAccessFields_topTenImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_topTenImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscClick:{
		create:"BannersDocAccessFields_miscClick_Create",
		read:"BannersDocAccessFields_miscClick_Read",
		update:"BannersDocAccessFields_miscClick_Update",
		delete:"BannersDocAccessFields_miscClick_Delete"
	},
	BannersDocAccessFields_miscClick_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscClick_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscClick_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscClick_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscImpression:{
		create:"BannersDocAccessFields_miscImpression_Create",
		read:"BannersDocAccessFields_miscImpression_Read",
		update:"BannersDocAccessFields_miscImpression_Update",
		delete:"BannersDocAccessFields_miscImpression_Delete"
	},
	BannersDocAccessFields_miscImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_miscImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_clickTotal:{
		create:"BannersDocAccessFields_clickTotal_Create",
		read:"BannersDocAccessFields_clickTotal_Read",
		update:"BannersDocAccessFields_clickTotal_Update",
		delete:"BannersDocAccessFields_clickTotal_Delete"
	},
	BannersDocAccessFields_clickTotal_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_clickTotal_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_clickTotal_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_clickTotal_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_totalImpression:{
		create:"BannersDocAccessFields_totalImpression_Create",
		read:"BannersDocAccessFields_totalImpression_Read",
		update:"BannersDocAccessFields_totalImpression_Update",
		delete:"BannersDocAccessFields_totalImpression_Delete"
	},
	BannersDocAccessFields_totalImpression_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_totalImpression_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_totalImpression_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_updatedAt:{
		create:"BannersDocAccessFields_updatedAt_Create",
		read:"BannersDocAccessFields_updatedAt_Read",
		update:"BannersDocAccessFields_updatedAt_Update",
		delete:"BannersDocAccessFields_updatedAt_Delete"
	},
	BannersDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BannersDocAccessFields_createdAt:{
		create:"BannersDocAccessFields_createdAt_Create",
		read:"BannersDocAccessFields_createdAt_Read",
		update:"BannersDocAccessFields_createdAt_Update",
		delete:"BannersDocAccessFields_createdAt_Delete"
	},
	BannersDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	BannersDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	BannersDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	BannersDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BannersCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Campaigns:{
		docs:"Campaign",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countCampaigns:{
		totalDocs:"Int"
	},
	campaignsDocAccess:{
		fields:"CampaignsDocAccessFields",
		create:"CampaignsCreateDocAccess",
		read:"CampaignsReadDocAccess",
		update:"CampaignsUpdateDocAccess",
		delete:"CampaignsDeleteDocAccess"
	},
	CampaignsDocAccessFields:{
		creator:"CampaignsDocAccessFields_creator",
		invoiceNumber:"CampaignsDocAccessFields_invoiceNumber",
		quotationNumber:"CampaignsDocAccessFields_quotationNumber",
		campaignName:"CampaignsDocAccessFields_campaignName",
		client:"CampaignsDocAccessFields_client",
		contactPerson:"CampaignsDocAccessFields_contactPerson",
		email:"CampaignsDocAccessFields_email",
		phone:"CampaignsDocAccessFields_phone",
		agentName:"CampaignsDocAccessFields_agentName",
		agentCommission:"CampaignsDocAccessFields_agentCommission",
		agentOrderNumber:"CampaignsDocAccessFields_agentOrderNumber",
		_firstPublishedTime:"CampaignsDocAccessFields__firstPublishedTime",
		updatedAt:"CampaignsDocAccessFields_updatedAt",
		createdAt:"CampaignsDocAccessFields_createdAt"
	},
	CampaignsDocAccessFields_creator:{
		create:"CampaignsDocAccessFields_creator_Create",
		read:"CampaignsDocAccessFields_creator_Read",
		update:"CampaignsDocAccessFields_creator_Update",
		delete:"CampaignsDocAccessFields_creator_Delete"
	},
	CampaignsDocAccessFields_creator_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_creator_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_creator_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_creator_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_invoiceNumber:{
		create:"CampaignsDocAccessFields_invoiceNumber_Create",
		read:"CampaignsDocAccessFields_invoiceNumber_Read",
		update:"CampaignsDocAccessFields_invoiceNumber_Update",
		delete:"CampaignsDocAccessFields_invoiceNumber_Delete"
	},
	CampaignsDocAccessFields_invoiceNumber_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_invoiceNumber_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_invoiceNumber_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_invoiceNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_quotationNumber:{
		create:"CampaignsDocAccessFields_quotationNumber_Create",
		read:"CampaignsDocAccessFields_quotationNumber_Read",
		update:"CampaignsDocAccessFields_quotationNumber_Update",
		delete:"CampaignsDocAccessFields_quotationNumber_Delete"
	},
	CampaignsDocAccessFields_quotationNumber_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_quotationNumber_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_quotationNumber_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_quotationNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_campaignName:{
		create:"CampaignsDocAccessFields_campaignName_Create",
		read:"CampaignsDocAccessFields_campaignName_Read",
		update:"CampaignsDocAccessFields_campaignName_Update",
		delete:"CampaignsDocAccessFields_campaignName_Delete"
	},
	CampaignsDocAccessFields_campaignName_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_campaignName_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_campaignName_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_campaignName_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_client:{
		create:"CampaignsDocAccessFields_client_Create",
		read:"CampaignsDocAccessFields_client_Read",
		update:"CampaignsDocAccessFields_client_Update",
		delete:"CampaignsDocAccessFields_client_Delete"
	},
	CampaignsDocAccessFields_client_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_client_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_client_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_client_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_contactPerson:{
		create:"CampaignsDocAccessFields_contactPerson_Create",
		read:"CampaignsDocAccessFields_contactPerson_Read",
		update:"CampaignsDocAccessFields_contactPerson_Update",
		delete:"CampaignsDocAccessFields_contactPerson_Delete"
	},
	CampaignsDocAccessFields_contactPerson_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_contactPerson_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_contactPerson_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_contactPerson_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_email:{
		create:"CampaignsDocAccessFields_email_Create",
		read:"CampaignsDocAccessFields_email_Read",
		update:"CampaignsDocAccessFields_email_Update",
		delete:"CampaignsDocAccessFields_email_Delete"
	},
	CampaignsDocAccessFields_email_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_email_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_email_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_email_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_phone:{
		create:"CampaignsDocAccessFields_phone_Create",
		read:"CampaignsDocAccessFields_phone_Read",
		update:"CampaignsDocAccessFields_phone_Update",
		delete:"CampaignsDocAccessFields_phone_Delete"
	},
	CampaignsDocAccessFields_phone_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_phone_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_phone_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_phone_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentName:{
		create:"CampaignsDocAccessFields_agentName_Create",
		read:"CampaignsDocAccessFields_agentName_Read",
		update:"CampaignsDocAccessFields_agentName_Update",
		delete:"CampaignsDocAccessFields_agentName_Delete"
	},
	CampaignsDocAccessFields_agentName_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentName_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentName_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentName_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentCommission:{
		create:"CampaignsDocAccessFields_agentCommission_Create",
		read:"CampaignsDocAccessFields_agentCommission_Read",
		update:"CampaignsDocAccessFields_agentCommission_Update",
		delete:"CampaignsDocAccessFields_agentCommission_Delete"
	},
	CampaignsDocAccessFields_agentCommission_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentCommission_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentCommission_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentCommission_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentOrderNumber:{
		create:"CampaignsDocAccessFields_agentOrderNumber_Create",
		read:"CampaignsDocAccessFields_agentOrderNumber_Read",
		update:"CampaignsDocAccessFields_agentOrderNumber_Update",
		delete:"CampaignsDocAccessFields_agentOrderNumber_Delete"
	},
	CampaignsDocAccessFields_agentOrderNumber_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentOrderNumber_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentOrderNumber_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_agentOrderNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields__firstPublishedTime:{
		create:"CampaignsDocAccessFields__firstPublishedTime_Create",
		read:"CampaignsDocAccessFields__firstPublishedTime_Read",
		update:"CampaignsDocAccessFields__firstPublishedTime_Update",
		delete:"CampaignsDocAccessFields__firstPublishedTime_Delete"
	},
	CampaignsDocAccessFields__firstPublishedTime_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields__firstPublishedTime_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields__firstPublishedTime_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields__firstPublishedTime_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_updatedAt:{
		create:"CampaignsDocAccessFields_updatedAt_Create",
		read:"CampaignsDocAccessFields_updatedAt_Read",
		update:"CampaignsDocAccessFields_updatedAt_Update",
		delete:"CampaignsDocAccessFields_updatedAt_Delete"
	},
	CampaignsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_createdAt:{
		create:"CampaignsDocAccessFields_createdAt_Create",
		read:"CampaignsDocAccessFields_createdAt_Read",
		update:"CampaignsDocAccessFields_createdAt_Update",
		delete:"CampaignsDocAccessFields_createdAt_Delete"
	},
	CampaignsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	CampaignsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CampaignsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Advertise:{
		id:"String",
		advertise:"Advertise_Advertise",
		advertiseStartDate:"DateTime",
		advertiseEndDate:"DateTime",
		type:"Advertise_type",
		invoiceDescription:"String",
		standardPrice:"Float",
		discount:"Float",
		discountPercentage:"Float",
		_calculateByDiscount:"Boolean",
		timableCost:"Float",
		remark:"String",
		public:"Boolean",
		campaignName:"String",
		campaignId:"String",
		status:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Advertise_Advertise:{
		type:"Advertise_Advertise_type",
		event:"Event",
		keyword:"String",
		location:"Location",
		displayPosition:"Advertise_Advertise_displayPosition",
		rankingListRemark:"String"
	},
	Advertises:{
		docs:"Advertise",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countAdvertises:{
		totalDocs:"Int"
	},
	advertisesDocAccess:{
		fields:"AdvertisesDocAccessFields",
		create:"AdvertisesCreateDocAccess",
		read:"AdvertisesReadDocAccess",
		update:"AdvertisesUpdateDocAccess",
		delete:"AdvertisesDeleteDocAccess"
	},
	AdvertisesDocAccessFields:{
		advertise:"AdvertisesDocAccessFields_advertise",
		advertiseStartDate:"AdvertisesDocAccessFields_advertiseStartDate",
		advertiseEndDate:"AdvertisesDocAccessFields_advertiseEndDate",
		type:"AdvertisesDocAccessFields_type",
		invoiceDescription:"AdvertisesDocAccessFields_invoiceDescription",
		standardPrice:"AdvertisesDocAccessFields_standardPrice",
		discount:"AdvertisesDocAccessFields_discount",
		discountPercentage:"AdvertisesDocAccessFields_discountPercentage",
		_calculateByDiscount:"AdvertisesDocAccessFields__calculateByDiscount",
		timableCost:"AdvertisesDocAccessFields_timableCost",
		remark:"AdvertisesDocAccessFields_remark",
		public:"AdvertisesDocAccessFields_public",
		campaignName:"AdvertisesDocAccessFields_campaignName",
		campaignId:"AdvertisesDocAccessFields_campaignId",
		status:"AdvertisesDocAccessFields_status",
		updatedAt:"AdvertisesDocAccessFields_updatedAt",
		createdAt:"AdvertisesDocAccessFields_createdAt"
	},
	AdvertisesDocAccessFields_advertise:{
		create:"AdvertisesDocAccessFields_advertise_Create",
		read:"AdvertisesDocAccessFields_advertise_Read",
		update:"AdvertisesDocAccessFields_advertise_Update",
		delete:"AdvertisesDocAccessFields_advertise_Delete",
		fields:"AdvertisesDocAccessFields_advertise_Fields"
	},
	AdvertisesDocAccessFields_advertise_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_Fields:{
		type:"AdvertisesDocAccessFields_advertise_type",
		event:"AdvertisesDocAccessFields_advertise_event",
		keyword:"AdvertisesDocAccessFields_advertise_keyword",
		location:"AdvertisesDocAccessFields_advertise_location",
		displayPosition:"AdvertisesDocAccessFields_advertise_displayPosition",
		rankingListRemark:"AdvertisesDocAccessFields_advertise_rankingListRemark"
	},
	AdvertisesDocAccessFields_advertise_type:{
		create:"AdvertisesDocAccessFields_advertise_type_Create",
		read:"AdvertisesDocAccessFields_advertise_type_Read",
		update:"AdvertisesDocAccessFields_advertise_type_Update",
		delete:"AdvertisesDocAccessFields_advertise_type_Delete"
	},
	AdvertisesDocAccessFields_advertise_type_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_type_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_type_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_type_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_event:{
		create:"AdvertisesDocAccessFields_advertise_event_Create",
		read:"AdvertisesDocAccessFields_advertise_event_Read",
		update:"AdvertisesDocAccessFields_advertise_event_Update",
		delete:"AdvertisesDocAccessFields_advertise_event_Delete"
	},
	AdvertisesDocAccessFields_advertise_event_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_event_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_event_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_event_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_keyword:{
		create:"AdvertisesDocAccessFields_advertise_keyword_Create",
		read:"AdvertisesDocAccessFields_advertise_keyword_Read",
		update:"AdvertisesDocAccessFields_advertise_keyword_Update",
		delete:"AdvertisesDocAccessFields_advertise_keyword_Delete"
	},
	AdvertisesDocAccessFields_advertise_keyword_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_keyword_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_keyword_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_keyword_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_location:{
		create:"AdvertisesDocAccessFields_advertise_location_Create",
		read:"AdvertisesDocAccessFields_advertise_location_Read",
		update:"AdvertisesDocAccessFields_advertise_location_Update",
		delete:"AdvertisesDocAccessFields_advertise_location_Delete"
	},
	AdvertisesDocAccessFields_advertise_location_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_location_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_location_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_location_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_displayPosition:{
		create:"AdvertisesDocAccessFields_advertise_displayPosition_Create",
		read:"AdvertisesDocAccessFields_advertise_displayPosition_Read",
		update:"AdvertisesDocAccessFields_advertise_displayPosition_Update",
		delete:"AdvertisesDocAccessFields_advertise_displayPosition_Delete"
	},
	AdvertisesDocAccessFields_advertise_displayPosition_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_displayPosition_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_displayPosition_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_displayPosition_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_rankingListRemark:{
		create:"AdvertisesDocAccessFields_advertise_rankingListRemark_Create",
		read:"AdvertisesDocAccessFields_advertise_rankingListRemark_Read",
		update:"AdvertisesDocAccessFields_advertise_rankingListRemark_Update",
		delete:"AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"
	},
	AdvertisesDocAccessFields_advertise_rankingListRemark_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_rankingListRemark_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_rankingListRemark_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertise_rankingListRemark_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseStartDate:{
		create:"AdvertisesDocAccessFields_advertiseStartDate_Create",
		read:"AdvertisesDocAccessFields_advertiseStartDate_Read",
		update:"AdvertisesDocAccessFields_advertiseStartDate_Update",
		delete:"AdvertisesDocAccessFields_advertiseStartDate_Delete"
	},
	AdvertisesDocAccessFields_advertiseStartDate_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseStartDate_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseStartDate_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseStartDate_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseEndDate:{
		create:"AdvertisesDocAccessFields_advertiseEndDate_Create",
		read:"AdvertisesDocAccessFields_advertiseEndDate_Read",
		update:"AdvertisesDocAccessFields_advertiseEndDate_Update",
		delete:"AdvertisesDocAccessFields_advertiseEndDate_Delete"
	},
	AdvertisesDocAccessFields_advertiseEndDate_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseEndDate_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseEndDate_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_advertiseEndDate_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_type:{
		create:"AdvertisesDocAccessFields_type_Create",
		read:"AdvertisesDocAccessFields_type_Read",
		update:"AdvertisesDocAccessFields_type_Update",
		delete:"AdvertisesDocAccessFields_type_Delete"
	},
	AdvertisesDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_invoiceDescription:{
		create:"AdvertisesDocAccessFields_invoiceDescription_Create",
		read:"AdvertisesDocAccessFields_invoiceDescription_Read",
		update:"AdvertisesDocAccessFields_invoiceDescription_Update",
		delete:"AdvertisesDocAccessFields_invoiceDescription_Delete"
	},
	AdvertisesDocAccessFields_invoiceDescription_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_invoiceDescription_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_invoiceDescription_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_invoiceDescription_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_standardPrice:{
		create:"AdvertisesDocAccessFields_standardPrice_Create",
		read:"AdvertisesDocAccessFields_standardPrice_Read",
		update:"AdvertisesDocAccessFields_standardPrice_Update",
		delete:"AdvertisesDocAccessFields_standardPrice_Delete"
	},
	AdvertisesDocAccessFields_standardPrice_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_standardPrice_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_standardPrice_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_standardPrice_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discount:{
		create:"AdvertisesDocAccessFields_discount_Create",
		read:"AdvertisesDocAccessFields_discount_Read",
		update:"AdvertisesDocAccessFields_discount_Update",
		delete:"AdvertisesDocAccessFields_discount_Delete"
	},
	AdvertisesDocAccessFields_discount_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discount_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discount_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discount_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discountPercentage:{
		create:"AdvertisesDocAccessFields_discountPercentage_Create",
		read:"AdvertisesDocAccessFields_discountPercentage_Read",
		update:"AdvertisesDocAccessFields_discountPercentage_Update",
		delete:"AdvertisesDocAccessFields_discountPercentage_Delete"
	},
	AdvertisesDocAccessFields_discountPercentage_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discountPercentage_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discountPercentage_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_discountPercentage_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields__calculateByDiscount:{
		create:"AdvertisesDocAccessFields__calculateByDiscount_Create",
		read:"AdvertisesDocAccessFields__calculateByDiscount_Read",
		update:"AdvertisesDocAccessFields__calculateByDiscount_Update",
		delete:"AdvertisesDocAccessFields__calculateByDiscount_Delete"
	},
	AdvertisesDocAccessFields__calculateByDiscount_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields__calculateByDiscount_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields__calculateByDiscount_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields__calculateByDiscount_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_timableCost:{
		create:"AdvertisesDocAccessFields_timableCost_Create",
		read:"AdvertisesDocAccessFields_timableCost_Read",
		update:"AdvertisesDocAccessFields_timableCost_Update",
		delete:"AdvertisesDocAccessFields_timableCost_Delete"
	},
	AdvertisesDocAccessFields_timableCost_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_timableCost_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_timableCost_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_timableCost_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_remark:{
		create:"AdvertisesDocAccessFields_remark_Create",
		read:"AdvertisesDocAccessFields_remark_Read",
		update:"AdvertisesDocAccessFields_remark_Update",
		delete:"AdvertisesDocAccessFields_remark_Delete"
	},
	AdvertisesDocAccessFields_remark_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_remark_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_remark_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_remark_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_public:{
		create:"AdvertisesDocAccessFields_public_Create",
		read:"AdvertisesDocAccessFields_public_Read",
		update:"AdvertisesDocAccessFields_public_Update",
		delete:"AdvertisesDocAccessFields_public_Delete"
	},
	AdvertisesDocAccessFields_public_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_public_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_public_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_public_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignName:{
		create:"AdvertisesDocAccessFields_campaignName_Create",
		read:"AdvertisesDocAccessFields_campaignName_Read",
		update:"AdvertisesDocAccessFields_campaignName_Update",
		delete:"AdvertisesDocAccessFields_campaignName_Delete"
	},
	AdvertisesDocAccessFields_campaignName_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignName_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignName_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignName_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignId:{
		create:"AdvertisesDocAccessFields_campaignId_Create",
		read:"AdvertisesDocAccessFields_campaignId_Read",
		update:"AdvertisesDocAccessFields_campaignId_Update",
		delete:"AdvertisesDocAccessFields_campaignId_Delete"
	},
	AdvertisesDocAccessFields_campaignId_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignId_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignId_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_campaignId_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_status:{
		create:"AdvertisesDocAccessFields_status_Create",
		read:"AdvertisesDocAccessFields_status_Read",
		update:"AdvertisesDocAccessFields_status_Update",
		delete:"AdvertisesDocAccessFields_status_Delete"
	},
	AdvertisesDocAccessFields_status_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_status_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_status_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_status_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_updatedAt:{
		create:"AdvertisesDocAccessFields_updatedAt_Create",
		read:"AdvertisesDocAccessFields_updatedAt_Read",
		update:"AdvertisesDocAccessFields_updatedAt_Update",
		delete:"AdvertisesDocAccessFields_updatedAt_Delete"
	},
	AdvertisesDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_createdAt:{
		create:"AdvertisesDocAccessFields_createdAt_Create",
		read:"AdvertisesDocAccessFields_createdAt_Read",
		update:"AdvertisesDocAccessFields_createdAt_Update",
		delete:"AdvertisesDocAccessFields_createdAt_Delete"
	},
	AdvertisesDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	AdvertisesDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AdvertisesCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Device:{
		id:"String",
		user:"Device_User_Relationship",
		udid:"String",
		pushToken:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Device_User_Relationship:{
		relationTo:"Device_User_RelationTo",
		value:"Device_User"
	},
	Device_User:{
		"...on User":"User"
	},
	Devices:{
		docs:"Device",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countDevices:{
		totalDocs:"Int"
	},
	devicesDocAccess:{
		fields:"DevicesDocAccessFields",
		create:"DevicesCreateDocAccess",
		read:"DevicesReadDocAccess",
		update:"DevicesUpdateDocAccess",
		delete:"DevicesDeleteDocAccess"
	},
	DevicesDocAccessFields:{
		user:"DevicesDocAccessFields_user",
		udid:"DevicesDocAccessFields_udid",
		pushToken:"DevicesDocAccessFields_pushToken",
		updatedAt:"DevicesDocAccessFields_updatedAt",
		createdAt:"DevicesDocAccessFields_createdAt"
	},
	DevicesDocAccessFields_user:{
		create:"DevicesDocAccessFields_user_Create",
		read:"DevicesDocAccessFields_user_Read",
		update:"DevicesDocAccessFields_user_Update",
		delete:"DevicesDocAccessFields_user_Delete"
	},
	DevicesDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_udid:{
		create:"DevicesDocAccessFields_udid_Create",
		read:"DevicesDocAccessFields_udid_Read",
		update:"DevicesDocAccessFields_udid_Update",
		delete:"DevicesDocAccessFields_udid_Delete"
	},
	DevicesDocAccessFields_udid_Create:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_udid_Read:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_udid_Update:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_udid_Delete:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_pushToken:{
		create:"DevicesDocAccessFields_pushToken_Create",
		read:"DevicesDocAccessFields_pushToken_Read",
		update:"DevicesDocAccessFields_pushToken_Update",
		delete:"DevicesDocAccessFields_pushToken_Delete"
	},
	DevicesDocAccessFields_pushToken_Create:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_pushToken_Read:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_pushToken_Update:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_pushToken_Delete:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_updatedAt:{
		create:"DevicesDocAccessFields_updatedAt_Create",
		read:"DevicesDocAccessFields_updatedAt_Read",
		update:"DevicesDocAccessFields_updatedAt_Update",
		delete:"DevicesDocAccessFields_updatedAt_Delete"
	},
	DevicesDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_createdAt:{
		create:"DevicesDocAccessFields_createdAt_Create",
		read:"DevicesDocAccessFields_createdAt_Read",
		update:"DevicesDocAccessFields_createdAt_Update",
		delete:"DevicesDocAccessFields_createdAt_Delete"
	},
	DevicesDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	DevicesDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DevicesCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Notification:{
		id:"String",
		receiver:"User",
		type:"Notification_type",
		meta:"Notification_Meta_Relationship",
		message:"String",
		sender:"User",
		readed:"Boolean",
		deletedByUser:"Boolean",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Notification_Meta_Relationship:{
		relationTo:"Notification_Meta_RelationTo",
		value:"Notification_Meta"
	},
	Notification_Meta:{
		"...on Event":"Event",
		"...on Location":"Location",
		"...on Post":"Post",
		"...on Comment":"Comment"
	},
	Notifications:{
		docs:"Notification",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countNotifications:{
		totalDocs:"Int"
	},
	notificationsDocAccess:{
		fields:"NotificationsDocAccessFields",
		create:"NotificationsCreateDocAccess",
		read:"NotificationsReadDocAccess",
		update:"NotificationsUpdateDocAccess",
		delete:"NotificationsDeleteDocAccess"
	},
	NotificationsDocAccessFields:{
		receiver:"NotificationsDocAccessFields_receiver",
		type:"NotificationsDocAccessFields_type",
		meta:"NotificationsDocAccessFields_meta",
		message:"NotificationsDocAccessFields_message",
		sender:"NotificationsDocAccessFields_sender",
		readed:"NotificationsDocAccessFields_readed",
		deletedByUser:"NotificationsDocAccessFields_deletedByUser",
		updatedAt:"NotificationsDocAccessFields_updatedAt",
		createdAt:"NotificationsDocAccessFields_createdAt"
	},
	NotificationsDocAccessFields_receiver:{
		create:"NotificationsDocAccessFields_receiver_Create",
		read:"NotificationsDocAccessFields_receiver_Read",
		update:"NotificationsDocAccessFields_receiver_Update",
		delete:"NotificationsDocAccessFields_receiver_Delete"
	},
	NotificationsDocAccessFields_receiver_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_receiver_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_receiver_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_receiver_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_type:{
		create:"NotificationsDocAccessFields_type_Create",
		read:"NotificationsDocAccessFields_type_Read",
		update:"NotificationsDocAccessFields_type_Update",
		delete:"NotificationsDocAccessFields_type_Delete"
	},
	NotificationsDocAccessFields_type_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_type_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_type_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_type_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_meta:{
		create:"NotificationsDocAccessFields_meta_Create",
		read:"NotificationsDocAccessFields_meta_Read",
		update:"NotificationsDocAccessFields_meta_Update",
		delete:"NotificationsDocAccessFields_meta_Delete"
	},
	NotificationsDocAccessFields_meta_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_meta_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_meta_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_meta_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_message:{
		create:"NotificationsDocAccessFields_message_Create",
		read:"NotificationsDocAccessFields_message_Read",
		update:"NotificationsDocAccessFields_message_Update",
		delete:"NotificationsDocAccessFields_message_Delete"
	},
	NotificationsDocAccessFields_message_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_message_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_message_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_message_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_sender:{
		create:"NotificationsDocAccessFields_sender_Create",
		read:"NotificationsDocAccessFields_sender_Read",
		update:"NotificationsDocAccessFields_sender_Update",
		delete:"NotificationsDocAccessFields_sender_Delete"
	},
	NotificationsDocAccessFields_sender_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_sender_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_sender_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_sender_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_readed:{
		create:"NotificationsDocAccessFields_readed_Create",
		read:"NotificationsDocAccessFields_readed_Read",
		update:"NotificationsDocAccessFields_readed_Update",
		delete:"NotificationsDocAccessFields_readed_Delete"
	},
	NotificationsDocAccessFields_readed_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_readed_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_readed_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_readed_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_deletedByUser:{
		create:"NotificationsDocAccessFields_deletedByUser_Create",
		read:"NotificationsDocAccessFields_deletedByUser_Read",
		update:"NotificationsDocAccessFields_deletedByUser_Update",
		delete:"NotificationsDocAccessFields_deletedByUser_Delete"
	},
	NotificationsDocAccessFields_deletedByUser_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_deletedByUser_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_deletedByUser_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_deletedByUser_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_updatedAt:{
		create:"NotificationsDocAccessFields_updatedAt_Create",
		read:"NotificationsDocAccessFields_updatedAt_Read",
		update:"NotificationsDocAccessFields_updatedAt_Update",
		delete:"NotificationsDocAccessFields_updatedAt_Delete"
	},
	NotificationsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_createdAt:{
		create:"NotificationsDocAccessFields_createdAt_Create",
		read:"NotificationsDocAccessFields_createdAt_Read",
		update:"NotificationsDocAccessFields_createdAt_Update",
		delete:"NotificationsDocAccessFields_createdAt_Delete"
	},
	NotificationsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	NotificationsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	NotificationsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Newsletter:{
		id:"String",
		email:"String",
		region:"Newsletter_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Newsletters:{
		docs:"Newsletter",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countNewsletters:{
		totalDocs:"Int"
	},
	newsletterDocAccess:{
		fields:"NewsletterDocAccessFields",
		create:"NewsletterCreateDocAccess",
		read:"NewsletterReadDocAccess",
		update:"NewsletterUpdateDocAccess",
		delete:"NewsletterDeleteDocAccess"
	},
	NewsletterDocAccessFields:{
		email:"NewsletterDocAccessFields_email",
		region:"NewsletterDocAccessFields_region",
		updatedAt:"NewsletterDocAccessFields_updatedAt",
		createdAt:"NewsletterDocAccessFields_createdAt"
	},
	NewsletterDocAccessFields_email:{
		create:"NewsletterDocAccessFields_email_Create",
		read:"NewsletterDocAccessFields_email_Read",
		update:"NewsletterDocAccessFields_email_Update",
		delete:"NewsletterDocAccessFields_email_Delete"
	},
	NewsletterDocAccessFields_email_Create:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_email_Read:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_email_Update:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_email_Delete:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_region:{
		create:"NewsletterDocAccessFields_region_Create",
		read:"NewsletterDocAccessFields_region_Read",
		update:"NewsletterDocAccessFields_region_Update",
		delete:"NewsletterDocAccessFields_region_Delete"
	},
	NewsletterDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_updatedAt:{
		create:"NewsletterDocAccessFields_updatedAt_Create",
		read:"NewsletterDocAccessFields_updatedAt_Read",
		update:"NewsletterDocAccessFields_updatedAt_Update",
		delete:"NewsletterDocAccessFields_updatedAt_Delete"
	},
	NewsletterDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_createdAt:{
		create:"NewsletterDocAccessFields_createdAt_Create",
		read:"NewsletterDocAccessFields_createdAt_Read",
		update:"NewsletterDocAccessFields_createdAt_Update",
		delete:"NewsletterDocAccessFields_createdAt_Delete"
	},
	NewsletterDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	NewsletterDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	NewsletterCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSetting:{
		id:"String",
		from:"String",
		to:"String",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	RedirectSettings:{
		docs:"RedirectSetting",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countRedirectSettings:{
		totalDocs:"Int"
	},
	redirectSettingDocAccess:{
		fields:"RedirectSettingDocAccessFields",
		create:"RedirectSettingCreateDocAccess",
		read:"RedirectSettingReadDocAccess",
		update:"RedirectSettingUpdateDocAccess",
		delete:"RedirectSettingDeleteDocAccess"
	},
	RedirectSettingDocAccessFields:{
		from:"RedirectSettingDocAccessFields_from",
		to:"RedirectSettingDocAccessFields_to",
		updatedAt:"RedirectSettingDocAccessFields_updatedAt",
		createdAt:"RedirectSettingDocAccessFields_createdAt"
	},
	RedirectSettingDocAccessFields_from:{
		create:"RedirectSettingDocAccessFields_from_Create",
		read:"RedirectSettingDocAccessFields_from_Read",
		update:"RedirectSettingDocAccessFields_from_Update",
		delete:"RedirectSettingDocAccessFields_from_Delete"
	},
	RedirectSettingDocAccessFields_from_Create:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_from_Read:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_from_Update:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_from_Delete:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_to:{
		create:"RedirectSettingDocAccessFields_to_Create",
		read:"RedirectSettingDocAccessFields_to_Read",
		update:"RedirectSettingDocAccessFields_to_Update",
		delete:"RedirectSettingDocAccessFields_to_Delete"
	},
	RedirectSettingDocAccessFields_to_Create:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_to_Read:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_to_Update:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_to_Delete:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_updatedAt:{
		create:"RedirectSettingDocAccessFields_updatedAt_Create",
		read:"RedirectSettingDocAccessFields_updatedAt_Read",
		update:"RedirectSettingDocAccessFields_updatedAt_Update",
		delete:"RedirectSettingDocAccessFields_updatedAt_Delete"
	},
	RedirectSettingDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_createdAt:{
		create:"RedirectSettingDocAccessFields_createdAt_Create",
		read:"RedirectSettingDocAccessFields_createdAt_Read",
		update:"RedirectSettingDocAccessFields_createdAt_Update",
		delete:"RedirectSettingDocAccessFields_createdAt_Delete"
	},
	RedirectSettingDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	RedirectSettingDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RedirectSettingCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Static:{
		id:"String",
		name:"String",
		key:"String",
		content:"Static_Content",
		region:"Static_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	Static_Content:{
		"...on RichText":"RichText"
	},
	Statics:{
		docs:"Static",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countStatics:{
		totalDocs:"Int"
	},
	staticsDocAccess:{
		fields:"StaticsDocAccessFields",
		create:"StaticsCreateDocAccess",
		read:"StaticsReadDocAccess",
		update:"StaticsUpdateDocAccess",
		delete:"StaticsDeleteDocAccess"
	},
	StaticsDocAccessFields:{
		name:"StaticsDocAccessFields_name",
		key:"StaticsDocAccessFields_key",
		content:"StaticsDocAccessFields_content",
		region:"StaticsDocAccessFields_region",
		updatedAt:"StaticsDocAccessFields_updatedAt",
		createdAt:"StaticsDocAccessFields_createdAt"
	},
	StaticsDocAccessFields_name:{
		create:"StaticsDocAccessFields_name_Create",
		read:"StaticsDocAccessFields_name_Read",
		update:"StaticsDocAccessFields_name_Update",
		delete:"StaticsDocAccessFields_name_Delete"
	},
	StaticsDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_key:{
		create:"StaticsDocAccessFields_key_Create",
		read:"StaticsDocAccessFields_key_Read",
		update:"StaticsDocAccessFields_key_Update",
		delete:"StaticsDocAccessFields_key_Delete"
	},
	StaticsDocAccessFields_key_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_key_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_key_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_key_Delete:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_content:{
		create:"StaticsDocAccessFields_content_Create",
		read:"StaticsDocAccessFields_content_Read",
		update:"StaticsDocAccessFields_content_Update",
		delete:"StaticsDocAccessFields_content_Delete"
	},
	StaticsDocAccessFields_content_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_content_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_content_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_content_Delete:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_region:{
		create:"StaticsDocAccessFields_region_Create",
		read:"StaticsDocAccessFields_region_Read",
		update:"StaticsDocAccessFields_region_Update",
		delete:"StaticsDocAccessFields_region_Delete"
	},
	StaticsDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_updatedAt:{
		create:"StaticsDocAccessFields_updatedAt_Create",
		read:"StaticsDocAccessFields_updatedAt_Read",
		update:"StaticsDocAccessFields_updatedAt_Update",
		delete:"StaticsDocAccessFields_updatedAt_Delete"
	},
	StaticsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_createdAt:{
		create:"StaticsDocAccessFields_createdAt_Create",
		read:"StaticsDocAccessFields_createdAt_Read",
		update:"StaticsDocAccessFields_createdAt_Update",
		delete:"StaticsDocAccessFields_createdAt_Delete"
	},
	StaticsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	StaticsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	StaticsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSetting:{
		id:"String",
		criteria:"CriteriaSetting_Criteria",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	CriteriaSetting_Criteria:{
		date:"Date",
		audience:"Audience",
		category:"EventCategory",
		tag:"Tag",
		region:"CriteriaSetting_Criteria_region"
	},
	CriteriaSettings:{
		docs:"CriteriaSetting",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countCriteriaSettings:{
		totalDocs:"Int"
	},
	criteria_settingDocAccess:{
		fields:"CriteriaSettingDocAccessFields",
		create:"CriteriaSettingCreateDocAccess",
		read:"CriteriaSettingReadDocAccess",
		update:"CriteriaSettingUpdateDocAccess",
		delete:"CriteriaSettingDeleteDocAccess"
	},
	CriteriaSettingDocAccessFields:{
		criteria:"CriteriaSettingDocAccessFields_criteria",
		updatedAt:"CriteriaSettingDocAccessFields_updatedAt",
		createdAt:"CriteriaSettingDocAccessFields_createdAt"
	},
	CriteriaSettingDocAccessFields_criteria:{
		create:"CriteriaSettingDocAccessFields_criteria_Create",
		read:"CriteriaSettingDocAccessFields_criteria_Read",
		update:"CriteriaSettingDocAccessFields_criteria_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_Delete",
		fields:"CriteriaSettingDocAccessFields_criteria_Fields"
	},
	CriteriaSettingDocAccessFields_criteria_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_Fields:{
		date:"CriteriaSettingDocAccessFields_criteria_date",
		audience:"CriteriaSettingDocAccessFields_criteria_audience",
		category:"CriteriaSettingDocAccessFields_criteria_category",
		tag:"CriteriaSettingDocAccessFields_criteria_tag",
		region:"CriteriaSettingDocAccessFields_criteria_region"
	},
	CriteriaSettingDocAccessFields_criteria_date:{
		create:"CriteriaSettingDocAccessFields_criteria_date_Create",
		read:"CriteriaSettingDocAccessFields_criteria_date_Read",
		update:"CriteriaSettingDocAccessFields_criteria_date_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_date_Delete"
	},
	CriteriaSettingDocAccessFields_criteria_date_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_date_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_date_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_date_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_audience:{
		create:"CriteriaSettingDocAccessFields_criteria_audience_Create",
		read:"CriteriaSettingDocAccessFields_criteria_audience_Read",
		update:"CriteriaSettingDocAccessFields_criteria_audience_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_audience_Delete"
	},
	CriteriaSettingDocAccessFields_criteria_audience_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_audience_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_audience_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_audience_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_category:{
		create:"CriteriaSettingDocAccessFields_criteria_category_Create",
		read:"CriteriaSettingDocAccessFields_criteria_category_Read",
		update:"CriteriaSettingDocAccessFields_criteria_category_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_category_Delete"
	},
	CriteriaSettingDocAccessFields_criteria_category_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_category_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_category_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_category_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_tag:{
		create:"CriteriaSettingDocAccessFields_criteria_tag_Create",
		read:"CriteriaSettingDocAccessFields_criteria_tag_Read",
		update:"CriteriaSettingDocAccessFields_criteria_tag_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_tag_Delete"
	},
	CriteriaSettingDocAccessFields_criteria_tag_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_tag_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_tag_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_tag_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_region:{
		create:"CriteriaSettingDocAccessFields_criteria_region_Create",
		read:"CriteriaSettingDocAccessFields_criteria_region_Read",
		update:"CriteriaSettingDocAccessFields_criteria_region_Update",
		delete:"CriteriaSettingDocAccessFields_criteria_region_Delete"
	},
	CriteriaSettingDocAccessFields_criteria_region_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_region_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_region_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_criteria_region_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_updatedAt:{
		create:"CriteriaSettingDocAccessFields_updatedAt_Create",
		read:"CriteriaSettingDocAccessFields_updatedAt_Read",
		update:"CriteriaSettingDocAccessFields_updatedAt_Update",
		delete:"CriteriaSettingDocAccessFields_updatedAt_Delete"
	},
	CriteriaSettingDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_createdAt:{
		create:"CriteriaSettingDocAccessFields_createdAt_Create",
		read:"CriteriaSettingDocAccessFields_createdAt_Read",
		update:"CriteriaSettingDocAccessFields_createdAt_Update",
		delete:"CriteriaSettingDocAccessFields_createdAt_Delete"
	},
	CriteriaSettingDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	CriteriaSettingDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSetting:{
		id:"String",
		name:"String",
		organiserList:"User",
		region:"OrganiserSetting_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	OrganiserSettings:{
		docs:"OrganiserSetting",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countOrganiserSettings:{
		totalDocs:"Int"
	},
	organiserSettingDocAccess:{
		fields:"OrganiserSettingDocAccessFields",
		create:"OrganiserSettingCreateDocAccess",
		read:"OrganiserSettingReadDocAccess",
		update:"OrganiserSettingUpdateDocAccess",
		delete:"OrganiserSettingDeleteDocAccess"
	},
	OrganiserSettingDocAccessFields:{
		name:"OrganiserSettingDocAccessFields_name",
		organiserList:"OrganiserSettingDocAccessFields_organiserList",
		region:"OrganiserSettingDocAccessFields_region",
		updatedAt:"OrganiserSettingDocAccessFields_updatedAt",
		createdAt:"OrganiserSettingDocAccessFields_createdAt"
	},
	OrganiserSettingDocAccessFields_name:{
		create:"OrganiserSettingDocAccessFields_name_Create",
		read:"OrganiserSettingDocAccessFields_name_Read",
		update:"OrganiserSettingDocAccessFields_name_Update",
		delete:"OrganiserSettingDocAccessFields_name_Delete"
	},
	OrganiserSettingDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_organiserList:{
		create:"OrganiserSettingDocAccessFields_organiserList_Create",
		read:"OrganiserSettingDocAccessFields_organiserList_Read",
		update:"OrganiserSettingDocAccessFields_organiserList_Update",
		delete:"OrganiserSettingDocAccessFields_organiserList_Delete"
	},
	OrganiserSettingDocAccessFields_organiserList_Create:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_organiserList_Read:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_organiserList_Update:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_organiserList_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_region:{
		create:"OrganiserSettingDocAccessFields_region_Create",
		read:"OrganiserSettingDocAccessFields_region_Read",
		update:"OrganiserSettingDocAccessFields_region_Update",
		delete:"OrganiserSettingDocAccessFields_region_Delete"
	},
	OrganiserSettingDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_updatedAt:{
		create:"OrganiserSettingDocAccessFields_updatedAt_Create",
		read:"OrganiserSettingDocAccessFields_updatedAt_Read",
		update:"OrganiserSettingDocAccessFields_updatedAt_Update",
		delete:"OrganiserSettingDocAccessFields_updatedAt_Delete"
	},
	OrganiserSettingDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_createdAt:{
		create:"OrganiserSettingDocAccessFields_createdAt_Create",
		read:"OrganiserSettingDocAccessFields_createdAt_Read",
		update:"OrganiserSettingDocAccessFields_createdAt_Update",
		delete:"OrganiserSettingDocAccessFields_createdAt_Delete"
	},
	OrganiserSettingDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	OrganiserSettingDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSetting:{
		id:"String",
		name:"String",
		featuredPosts:"Post",
		region:"PostSetting_region",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	PostSettings:{
		docs:"PostSetting",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countPostSettings:{
		totalDocs:"Int"
	},
	post_settingDocAccess:{
		fields:"PostSettingDocAccessFields",
		create:"PostSettingCreateDocAccess",
		read:"PostSettingReadDocAccess",
		update:"PostSettingUpdateDocAccess",
		delete:"PostSettingDeleteDocAccess"
	},
	PostSettingDocAccessFields:{
		name:"PostSettingDocAccessFields_name",
		featuredPosts:"PostSettingDocAccessFields_featuredPosts",
		region:"PostSettingDocAccessFields_region",
		updatedAt:"PostSettingDocAccessFields_updatedAt",
		createdAt:"PostSettingDocAccessFields_createdAt"
	},
	PostSettingDocAccessFields_name:{
		create:"PostSettingDocAccessFields_name_Create",
		read:"PostSettingDocAccessFields_name_Read",
		update:"PostSettingDocAccessFields_name_Update",
		delete:"PostSettingDocAccessFields_name_Delete"
	},
	PostSettingDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_featuredPosts:{
		create:"PostSettingDocAccessFields_featuredPosts_Create",
		read:"PostSettingDocAccessFields_featuredPosts_Read",
		update:"PostSettingDocAccessFields_featuredPosts_Update",
		delete:"PostSettingDocAccessFields_featuredPosts_Delete"
	},
	PostSettingDocAccessFields_featuredPosts_Create:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_featuredPosts_Read:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_featuredPosts_Update:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_featuredPosts_Delete:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_region:{
		create:"PostSettingDocAccessFields_region_Create",
		read:"PostSettingDocAccessFields_region_Read",
		update:"PostSettingDocAccessFields_region_Update",
		delete:"PostSettingDocAccessFields_region_Delete"
	},
	PostSettingDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_updatedAt:{
		create:"PostSettingDocAccessFields_updatedAt_Create",
		read:"PostSettingDocAccessFields_updatedAt_Read",
		update:"PostSettingDocAccessFields_updatedAt_Update",
		delete:"PostSettingDocAccessFields_updatedAt_Delete"
	},
	PostSettingDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_createdAt:{
		create:"PostSettingDocAccessFields_createdAt_Create",
		read:"PostSettingDocAccessFields_createdAt_Read",
		update:"PostSettingDocAccessFields_createdAt_Update",
		delete:"PostSettingDocAccessFields_createdAt_Delete"
	},
	PostSettingDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostSettingDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostSettingCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSetting:{
		id:"String",
		name:"String",
		region:"HomepageSetting_region",
		sections:"HomepageSetting_Sections",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	HomepageSetting_Sections:{
		"...on ComingWeekEventAndLocationRankingBlock":"ComingWeekEventAndLocationRankingBlock",
		"...on CategoryListBlock":"CategoryListBlock",
		"...on UserPreferenceBlock":"UserPreferenceBlock",
		"...on EventRankingBlock":"EventRankingBlock",
		"...on EventTrendBlock":"EventTrendBlock",
		"...on LocationRankingBlock":"LocationRankingBlock",
		"...on OrganizarBlock":"OrganizarBlock",
		"...on SubscriptionBlock":"SubscriptionBlock",
		"...on TimableRankingBlock":"TimableRankingBlock",
		"...on TicketingBlock":"TicketingBlock",
		"...on BlogBlock":"BlogBlock",
		"...on EditorChoiceBlock":"EditorChoiceBlock"
	},
	ComingWeekEventAndLocationRankingBlock:{
		title:"String",
		locationRankingTitle:"String",
		appearance:"ComingWeekEventAndLocationRankingBlock_appearance",
		byRegion:"ComingWeekEventAndLocationRankingBlock_byRegion",
		rankings:"LocationRanking",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	CategoryListBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	UserPreferenceBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	EventRankingBlock:{
		title:"String",
		appearance:"EventRankingBlock_appearance",
		ranking:"EventRanking",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	EventTrendBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	LocationRankingBlock:{
		title:"String",
		appearance:"LocationRankingBlock_appearance",
		ranking:"LocationRanking",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	OrganizarBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	SubscriptionBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	TimableRankingBlock:{
		title:"String",
		ranking:"LocationRanking",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	TicketingBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	BlogBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	EditorChoiceBlock:{
		title:"String",
		id:"String",
		blockName:"String",
		blockType:"String"
	},
	HomepageSettings:{
		docs:"HomepageSetting",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countHomepageSettings:{
		totalDocs:"Int"
	},
	homepageSettingDocAccess:{
		fields:"HomepageSettingDocAccessFields",
		create:"HomepageSettingCreateDocAccess",
		read:"HomepageSettingReadDocAccess",
		update:"HomepageSettingUpdateDocAccess",
		delete:"HomepageSettingDeleteDocAccess"
	},
	HomepageSettingDocAccessFields:{
		name:"HomepageSettingDocAccessFields_name",
		region:"HomepageSettingDocAccessFields_region",
		sections:"HomepageSettingDocAccessFields_sections",
		updatedAt:"HomepageSettingDocAccessFields_updatedAt",
		createdAt:"HomepageSettingDocAccessFields_createdAt"
	},
	HomepageSettingDocAccessFields_name:{
		create:"HomepageSettingDocAccessFields_name_Create",
		read:"HomepageSettingDocAccessFields_name_Read",
		update:"HomepageSettingDocAccessFields_name_Update",
		delete:"HomepageSettingDocAccessFields_name_Delete"
	},
	HomepageSettingDocAccessFields_name_Create:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_name_Read:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_name_Update:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_name_Delete:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_region:{
		create:"HomepageSettingDocAccessFields_region_Create",
		read:"HomepageSettingDocAccessFields_region_Read",
		update:"HomepageSettingDocAccessFields_region_Update",
		delete:"HomepageSettingDocAccessFields_region_Delete"
	},
	HomepageSettingDocAccessFields_region_Create:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_region_Read:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_region_Update:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_region_Delete:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_sections:{
		create:"HomepageSettingDocAccessFields_sections_Create",
		read:"HomepageSettingDocAccessFields_sections_Read",
		update:"HomepageSettingDocAccessFields_sections_Update",
		delete:"HomepageSettingDocAccessFields_sections_Delete"
	},
	HomepageSettingDocAccessFields_sections_Create:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_sections_Read:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_sections_Update:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_sections_Delete:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_updatedAt:{
		create:"HomepageSettingDocAccessFields_updatedAt_Create",
		read:"HomepageSettingDocAccessFields_updatedAt_Read",
		update:"HomepageSettingDocAccessFields_updatedAt_Update",
		delete:"HomepageSettingDocAccessFields_updatedAt_Delete"
	},
	HomepageSettingDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_createdAt:{
		create:"HomepageSettingDocAccessFields_createdAt_Create",
		read:"HomepageSettingDocAccessFields_createdAt_Read",
		update:"HomepageSettingDocAccessFields_createdAt_Update",
		delete:"HomepageSettingDocAccessFields_createdAt_Delete"
	},
	HomepageSettingDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	HomepageSettingDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	HomepageSettingCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventView:{
		id:"String",
		date:"DateTime",
		eventId:"Event",
		viewCount:"Float",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	EventViews:{
		docs:"EventView",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countEventViews:{
		totalDocs:"Int"
	},
	event_viewsDocAccess:{
		fields:"EventViewsDocAccessFields",
		create:"EventViewsCreateDocAccess",
		read:"EventViewsReadDocAccess",
		update:"EventViewsUpdateDocAccess",
		delete:"EventViewsDeleteDocAccess"
	},
	EventViewsDocAccessFields:{
		date:"EventViewsDocAccessFields_date",
		eventId:"EventViewsDocAccessFields_eventId",
		viewCount:"EventViewsDocAccessFields_viewCount",
		updatedAt:"EventViewsDocAccessFields_updatedAt",
		createdAt:"EventViewsDocAccessFields_createdAt"
	},
	EventViewsDocAccessFields_date:{
		create:"EventViewsDocAccessFields_date_Create",
		read:"EventViewsDocAccessFields_date_Read",
		update:"EventViewsDocAccessFields_date_Update",
		delete:"EventViewsDocAccessFields_date_Delete"
	},
	EventViewsDocAccessFields_date_Create:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_date_Read:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_date_Update:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_date_Delete:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_eventId:{
		create:"EventViewsDocAccessFields_eventId_Create",
		read:"EventViewsDocAccessFields_eventId_Read",
		update:"EventViewsDocAccessFields_eventId_Update",
		delete:"EventViewsDocAccessFields_eventId_Delete"
	},
	EventViewsDocAccessFields_eventId_Create:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_eventId_Read:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_eventId_Update:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_eventId_Delete:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_viewCount:{
		create:"EventViewsDocAccessFields_viewCount_Create",
		read:"EventViewsDocAccessFields_viewCount_Read",
		update:"EventViewsDocAccessFields_viewCount_Update",
		delete:"EventViewsDocAccessFields_viewCount_Delete"
	},
	EventViewsDocAccessFields_viewCount_Create:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_viewCount_Read:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_viewCount_Update:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_viewCount_Delete:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_updatedAt:{
		create:"EventViewsDocAccessFields_updatedAt_Create",
		read:"EventViewsDocAccessFields_updatedAt_Read",
		update:"EventViewsDocAccessFields_updatedAt_Update",
		delete:"EventViewsDocAccessFields_updatedAt_Delete"
	},
	EventViewsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_createdAt:{
		create:"EventViewsDocAccessFields_createdAt_Create",
		read:"EventViewsDocAccessFields_createdAt_Read",
		update:"EventViewsDocAccessFields_createdAt_Update",
		delete:"EventViewsDocAccessFields_createdAt_Delete"
	},
	EventViewsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventViewsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventViewsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostView:{
		id:"String",
		date:"DateTime",
		postId:"Post",
		viewCount:"Float",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	PostViews:{
		docs:"PostView",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countPostViews:{
		totalDocs:"Int"
	},
	post_viewsDocAccess:{
		fields:"PostViewsDocAccessFields",
		create:"PostViewsCreateDocAccess",
		read:"PostViewsReadDocAccess",
		update:"PostViewsUpdateDocAccess",
		delete:"PostViewsDeleteDocAccess"
	},
	PostViewsDocAccessFields:{
		date:"PostViewsDocAccessFields_date",
		postId:"PostViewsDocAccessFields_postId",
		viewCount:"PostViewsDocAccessFields_viewCount",
		updatedAt:"PostViewsDocAccessFields_updatedAt",
		createdAt:"PostViewsDocAccessFields_createdAt"
	},
	PostViewsDocAccessFields_date:{
		create:"PostViewsDocAccessFields_date_Create",
		read:"PostViewsDocAccessFields_date_Read",
		update:"PostViewsDocAccessFields_date_Update",
		delete:"PostViewsDocAccessFields_date_Delete"
	},
	PostViewsDocAccessFields_date_Create:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_date_Read:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_date_Update:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_date_Delete:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_postId:{
		create:"PostViewsDocAccessFields_postId_Create",
		read:"PostViewsDocAccessFields_postId_Read",
		update:"PostViewsDocAccessFields_postId_Update",
		delete:"PostViewsDocAccessFields_postId_Delete"
	},
	PostViewsDocAccessFields_postId_Create:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_postId_Read:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_postId_Update:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_postId_Delete:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_viewCount:{
		create:"PostViewsDocAccessFields_viewCount_Create",
		read:"PostViewsDocAccessFields_viewCount_Read",
		update:"PostViewsDocAccessFields_viewCount_Update",
		delete:"PostViewsDocAccessFields_viewCount_Delete"
	},
	PostViewsDocAccessFields_viewCount_Create:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_viewCount_Read:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_viewCount_Update:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_viewCount_Delete:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_updatedAt:{
		create:"PostViewsDocAccessFields_updatedAt_Create",
		read:"PostViewsDocAccessFields_updatedAt_Read",
		update:"PostViewsDocAccessFields_updatedAt_Update",
		delete:"PostViewsDocAccessFields_updatedAt_Delete"
	},
	PostViewsDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_createdAt:{
		create:"PostViewsDocAccessFields_createdAt_Create",
		read:"PostViewsDocAccessFields_createdAt_Read",
		update:"PostViewsDocAccessFields_createdAt_Update",
		delete:"PostViewsDocAccessFields_createdAt_Delete"
	},
	PostViewsDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostViewsDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostViewsCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreference:{
		id:"String",
		user:"PayloadPreference_User_Relationship",
		key:"String",
		value:"JSON",
		updatedAt:"DateTime",
		createdAt:"DateTime"
	},
	PayloadPreference_User_Relationship:{
		relationTo:"PayloadPreference_User_RelationTo",
		value:"PayloadPreference_User"
	},
	PayloadPreference_User:{
		"...on User":"User"
	},
	PayloadPreferences:{
		docs:"PayloadPreference",
		hasNextPage:"Boolean",
		hasPrevPage:"Boolean",
		limit:"Int",
		nextPage:"Int",
		offset:"Int",
		page:"Int",
		pagingCounter:"Int",
		prevPage:"Int",
		totalDocs:"Int",
		totalPages:"Int"
	},
	countPayloadPreferences:{
		totalDocs:"Int"
	},
	payload_preferencesDocAccess:{
		fields:"PayloadPreferencesDocAccessFields",
		create:"PayloadPreferencesCreateDocAccess",
		read:"PayloadPreferencesReadDocAccess",
		update:"PayloadPreferencesUpdateDocAccess",
		delete:"PayloadPreferencesDeleteDocAccess"
	},
	PayloadPreferencesDocAccessFields:{
		user:"PayloadPreferencesDocAccessFields_user",
		key:"PayloadPreferencesDocAccessFields_key",
		value:"PayloadPreferencesDocAccessFields_value",
		updatedAt:"PayloadPreferencesDocAccessFields_updatedAt",
		createdAt:"PayloadPreferencesDocAccessFields_createdAt"
	},
	PayloadPreferencesDocAccessFields_user:{
		create:"PayloadPreferencesDocAccessFields_user_Create",
		read:"PayloadPreferencesDocAccessFields_user_Read",
		update:"PayloadPreferencesDocAccessFields_user_Update",
		delete:"PayloadPreferencesDocAccessFields_user_Delete"
	},
	PayloadPreferencesDocAccessFields_user_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_user_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_user_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_user_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_key:{
		create:"PayloadPreferencesDocAccessFields_key_Create",
		read:"PayloadPreferencesDocAccessFields_key_Read",
		update:"PayloadPreferencesDocAccessFields_key_Update",
		delete:"PayloadPreferencesDocAccessFields_key_Delete"
	},
	PayloadPreferencesDocAccessFields_key_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_key_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_key_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_key_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_value:{
		create:"PayloadPreferencesDocAccessFields_value_Create",
		read:"PayloadPreferencesDocAccessFields_value_Read",
		update:"PayloadPreferencesDocAccessFields_value_Update",
		delete:"PayloadPreferencesDocAccessFields_value_Delete"
	},
	PayloadPreferencesDocAccessFields_value_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_value_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_value_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_value_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_updatedAt:{
		create:"PayloadPreferencesDocAccessFields_updatedAt_Create",
		read:"PayloadPreferencesDocAccessFields_updatedAt_Read",
		update:"PayloadPreferencesDocAccessFields_updatedAt_Update",
		delete:"PayloadPreferencesDocAccessFields_updatedAt_Delete"
	},
	PayloadPreferencesDocAccessFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_createdAt:{
		create:"PayloadPreferencesDocAccessFields_createdAt_Create",
		read:"PayloadPreferencesDocAccessFields_createdAt_Read",
		update:"PayloadPreferencesDocAccessFields_createdAt_Update",
		delete:"PayloadPreferencesDocAccessFields_createdAt_Delete"
	},
	PayloadPreferencesDocAccessFields_createdAt_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_createdAt_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_createdAt_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesDocAccessFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesCreateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesReadDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesUpdateDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesDeleteDocAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	Access:{
		canAccessAdmin:"Boolean",
		users:"usersAccess",
		blog_categories:"blog_categoriesAccess",
		tags:"tagsAccess",
		specialTags:"specialTagsAccess",
		date:"dateAccess",
		districts:"districtsAccess",
		audience:"audienceAccess",
		events:"eventsAccess",
		locations:"locationsAccess",
		posts:"postsAccess",
		private_media:"private_mediaAccess",
		media:"mediaAccess",
		backupMedia:"backupMediaAccess",
		ranking_events:"ranking_eventsAccess",
		ranking_locations:"ranking_locationsAccess",
		account_suspend_requests:"account_suspend_requestsAccess",
		bookmarks:"bookmarksAccess",
		view_records:"view_recordsAccess",
		category:"categoryAccess",
		comments:"commentsAccess",
		event_calendar:"event_calendarAccess",
		subscriptions:"subscriptionsAccess",
		editor_choices:"editor_choicesAccess",
		banner_section:"banner_sectionAccess",
		macao_banner_section:"macao_banner_sectionAccess",
		banners:"bannersAccess",
		campaigns:"campaignsAccess",
		advertises:"advertisesAccess",
		devices:"devicesAccess",
		notifications:"notificationsAccess",
		newsletter:"newsletterAccess",
		redirectSetting:"redirectSettingAccess",
		statics:"staticsAccess",
		criteria_setting:"criteria_settingAccess",
		organiserSetting:"organiserSettingAccess",
		post_setting:"post_settingAccess",
		homepageSetting:"homepageSettingAccess",
		event_views:"event_viewsAccess",
		post_views:"post_viewsAccess",
		payload_preferences:"payload_preferencesAccess"
	},
	usersAccess:{
		fields:"UsersFields",
		create:"UsersCreateAccess",
		read:"UsersReadAccess",
		update:"UsersUpdateAccess",
		delete:"UsersDeleteAccess",
		unlock:"UsersUnlockAccess"
	},
	UsersFields:{
		region:"UsersFields_region",
		name:"UsersFields_name",
		displayEmail:"UsersFields_displayEmail",
		legacyUsername:"UsersFields_legacyUsername",
		roles:"UsersFields_roles",
		member:"UsersFields_member",
		organiser:"UsersFields_organiser",
		emailVerified:"UsersFields_emailVerified",
		sso:"UsersFields_sso",
		oldPassword:"UsersFields_oldPassword",
		status:"UsersFields_status",
		updatedAt:"UsersFields_updatedAt",
		createdAt:"UsersFields_createdAt",
		email:"UsersFields_email",
		password:"UsersFields_password"
	},
	UsersFields_region:{
		create:"UsersFields_region_Create",
		read:"UsersFields_region_Read",
		update:"UsersFields_region_Update",
		delete:"UsersFields_region_Delete"
	},
	UsersFields_region_Create:{
		permission:"Boolean"
	},
	UsersFields_region_Read:{
		permission:"Boolean"
	},
	UsersFields_region_Update:{
		permission:"Boolean"
	},
	UsersFields_region_Delete:{
		permission:"Boolean"
	},
	UsersFields_name:{
		create:"UsersFields_name_Create",
		read:"UsersFields_name_Read",
		update:"UsersFields_name_Update",
		delete:"UsersFields_name_Delete"
	},
	UsersFields_name_Create:{
		permission:"Boolean"
	},
	UsersFields_name_Read:{
		permission:"Boolean"
	},
	UsersFields_name_Update:{
		permission:"Boolean"
	},
	UsersFields_name_Delete:{
		permission:"Boolean"
	},
	UsersFields_displayEmail:{
		create:"UsersFields_displayEmail_Create",
		read:"UsersFields_displayEmail_Read",
		update:"UsersFields_displayEmail_Update",
		delete:"UsersFields_displayEmail_Delete"
	},
	UsersFields_displayEmail_Create:{
		permission:"Boolean"
	},
	UsersFields_displayEmail_Read:{
		permission:"Boolean"
	},
	UsersFields_displayEmail_Update:{
		permission:"Boolean"
	},
	UsersFields_displayEmail_Delete:{
		permission:"Boolean"
	},
	UsersFields_legacyUsername:{
		create:"UsersFields_legacyUsername_Create",
		read:"UsersFields_legacyUsername_Read",
		update:"UsersFields_legacyUsername_Update",
		delete:"UsersFields_legacyUsername_Delete"
	},
	UsersFields_legacyUsername_Create:{
		permission:"Boolean"
	},
	UsersFields_legacyUsername_Read:{
		permission:"Boolean"
	},
	UsersFields_legacyUsername_Update:{
		permission:"Boolean"
	},
	UsersFields_legacyUsername_Delete:{
		permission:"Boolean"
	},
	UsersFields_roles:{
		create:"UsersFields_roles_Create",
		read:"UsersFields_roles_Read",
		update:"UsersFields_roles_Update",
		delete:"UsersFields_roles_Delete"
	},
	UsersFields_roles_Create:{
		permission:"Boolean"
	},
	UsersFields_roles_Read:{
		permission:"Boolean"
	},
	UsersFields_roles_Update:{
		permission:"Boolean"
	},
	UsersFields_roles_Delete:{
		permission:"Boolean"
	},
	UsersFields_member:{
		create:"UsersFields_member_Create",
		read:"UsersFields_member_Read",
		update:"UsersFields_member_Update",
		delete:"UsersFields_member_Delete",
		fields:"UsersFields_member_Fields"
	},
	UsersFields_member_Create:{
		permission:"Boolean"
	},
	UsersFields_member_Read:{
		permission:"Boolean"
	},
	UsersFields_member_Update:{
		permission:"Boolean"
	},
	UsersFields_member_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_Fields:{
		region:"UsersFields_member_region",
		language:"UsersFields_member_language",
		phone:"UsersFields_member_phone",
		avatar:"UsersFields_member_avatar",
		birthday:"UsersFields_member_birthday",
		gender:"UsersFields_member_gender",
		categories:"UsersFields_member_categories"
	},
	UsersFields_member_region:{
		create:"UsersFields_member_region_Create",
		read:"UsersFields_member_region_Read",
		update:"UsersFields_member_region_Update",
		delete:"UsersFields_member_region_Delete"
	},
	UsersFields_member_region_Create:{
		permission:"Boolean"
	},
	UsersFields_member_region_Read:{
		permission:"Boolean"
	},
	UsersFields_member_region_Update:{
		permission:"Boolean"
	},
	UsersFields_member_region_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_language:{
		create:"UsersFields_member_language_Create",
		read:"UsersFields_member_language_Read",
		update:"UsersFields_member_language_Update",
		delete:"UsersFields_member_language_Delete"
	},
	UsersFields_member_language_Create:{
		permission:"Boolean"
	},
	UsersFields_member_language_Read:{
		permission:"Boolean"
	},
	UsersFields_member_language_Update:{
		permission:"Boolean"
	},
	UsersFields_member_language_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_phone:{
		create:"UsersFields_member_phone_Create",
		read:"UsersFields_member_phone_Read",
		update:"UsersFields_member_phone_Update",
		delete:"UsersFields_member_phone_Delete"
	},
	UsersFields_member_phone_Create:{
		permission:"Boolean"
	},
	UsersFields_member_phone_Read:{
		permission:"Boolean"
	},
	UsersFields_member_phone_Update:{
		permission:"Boolean"
	},
	UsersFields_member_phone_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_avatar:{
		create:"UsersFields_member_avatar_Create",
		read:"UsersFields_member_avatar_Read",
		update:"UsersFields_member_avatar_Update",
		delete:"UsersFields_member_avatar_Delete"
	},
	UsersFields_member_avatar_Create:{
		permission:"Boolean"
	},
	UsersFields_member_avatar_Read:{
		permission:"Boolean"
	},
	UsersFields_member_avatar_Update:{
		permission:"Boolean"
	},
	UsersFields_member_avatar_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_birthday:{
		create:"UsersFields_member_birthday_Create",
		read:"UsersFields_member_birthday_Read",
		update:"UsersFields_member_birthday_Update",
		delete:"UsersFields_member_birthday_Delete"
	},
	UsersFields_member_birthday_Create:{
		permission:"Boolean"
	},
	UsersFields_member_birthday_Read:{
		permission:"Boolean"
	},
	UsersFields_member_birthday_Update:{
		permission:"Boolean"
	},
	UsersFields_member_birthday_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_gender:{
		create:"UsersFields_member_gender_Create",
		read:"UsersFields_member_gender_Read",
		update:"UsersFields_member_gender_Update",
		delete:"UsersFields_member_gender_Delete"
	},
	UsersFields_member_gender_Create:{
		permission:"Boolean"
	},
	UsersFields_member_gender_Read:{
		permission:"Boolean"
	},
	UsersFields_member_gender_Update:{
		permission:"Boolean"
	},
	UsersFields_member_gender_Delete:{
		permission:"Boolean"
	},
	UsersFields_member_categories:{
		create:"UsersFields_member_categories_Create",
		read:"UsersFields_member_categories_Read",
		update:"UsersFields_member_categories_Update",
		delete:"UsersFields_member_categories_Delete"
	},
	UsersFields_member_categories_Create:{
		permission:"Boolean"
	},
	UsersFields_member_categories_Read:{
		permission:"Boolean"
	},
	UsersFields_member_categories_Update:{
		permission:"Boolean"
	},
	UsersFields_member_categories_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser:{
		create:"UsersFields_organiser_Create",
		read:"UsersFields_organiser_Read",
		update:"UsersFields_organiser_Update",
		delete:"UsersFields_organiser_Delete",
		fields:"UsersFields_organiser_Fields"
	},
	UsersFields_organiser_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_Fields:{
		logo:"UsersFields_organiser_logo",
		banner:"UsersFields_organiser_banner",
		description:"UsersFields_organiser_description",
		foundedIn:"UsersFields_organiser_foundedIn",
		address:"UsersFields_organiser_address",
		website:"UsersFields_organiser_website",
		facebook:"UsersFields_organiser_facebook",
		instagram:"UsersFields_organiser_instagram",
		language:"UsersFields_organiser_language",
		phone:"UsersFields_organiser_phone",
		followers:"UsersFields_organiser_followers"
	},
	UsersFields_organiser_logo:{
		create:"UsersFields_organiser_logo_Create",
		read:"UsersFields_organiser_logo_Read",
		update:"UsersFields_organiser_logo_Update",
		delete:"UsersFields_organiser_logo_Delete"
	},
	UsersFields_organiser_logo_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_logo_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_logo_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_logo_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_banner:{
		create:"UsersFields_organiser_banner_Create",
		read:"UsersFields_organiser_banner_Read",
		update:"UsersFields_organiser_banner_Update",
		delete:"UsersFields_organiser_banner_Delete"
	},
	UsersFields_organiser_banner_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_banner_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_banner_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_banner_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_description:{
		create:"UsersFields_organiser_description_Create",
		read:"UsersFields_organiser_description_Read",
		update:"UsersFields_organiser_description_Update",
		delete:"UsersFields_organiser_description_Delete"
	},
	UsersFields_organiser_description_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_description_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_description_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_description_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_foundedIn:{
		create:"UsersFields_organiser_foundedIn_Create",
		read:"UsersFields_organiser_foundedIn_Read",
		update:"UsersFields_organiser_foundedIn_Update",
		delete:"UsersFields_organiser_foundedIn_Delete"
	},
	UsersFields_organiser_foundedIn_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_foundedIn_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_foundedIn_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_foundedIn_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_address:{
		create:"UsersFields_organiser_address_Create",
		read:"UsersFields_organiser_address_Read",
		update:"UsersFields_organiser_address_Update",
		delete:"UsersFields_organiser_address_Delete"
	},
	UsersFields_organiser_address_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_address_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_address_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_address_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_website:{
		create:"UsersFields_organiser_website_Create",
		read:"UsersFields_organiser_website_Read",
		update:"UsersFields_organiser_website_Update",
		delete:"UsersFields_organiser_website_Delete"
	},
	UsersFields_organiser_website_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_website_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_website_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_website_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_facebook:{
		create:"UsersFields_organiser_facebook_Create",
		read:"UsersFields_organiser_facebook_Read",
		update:"UsersFields_organiser_facebook_Update",
		delete:"UsersFields_organiser_facebook_Delete"
	},
	UsersFields_organiser_facebook_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_facebook_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_facebook_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_facebook_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_instagram:{
		create:"UsersFields_organiser_instagram_Create",
		read:"UsersFields_organiser_instagram_Read",
		update:"UsersFields_organiser_instagram_Update",
		delete:"UsersFields_organiser_instagram_Delete"
	},
	UsersFields_organiser_instagram_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_instagram_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_instagram_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_instagram_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_language:{
		create:"UsersFields_organiser_language_Create",
		read:"UsersFields_organiser_language_Read",
		update:"UsersFields_organiser_language_Update",
		delete:"UsersFields_organiser_language_Delete"
	},
	UsersFields_organiser_language_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_language_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_language_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_language_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_phone:{
		create:"UsersFields_organiser_phone_Create",
		read:"UsersFields_organiser_phone_Read",
		update:"UsersFields_organiser_phone_Update",
		delete:"UsersFields_organiser_phone_Delete"
	},
	UsersFields_organiser_phone_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_phone_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_phone_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_phone_Delete:{
		permission:"Boolean"
	},
	UsersFields_organiser_followers:{
		create:"UsersFields_organiser_followers_Create",
		read:"UsersFields_organiser_followers_Read",
		update:"UsersFields_organiser_followers_Update",
		delete:"UsersFields_organiser_followers_Delete"
	},
	UsersFields_organiser_followers_Create:{
		permission:"Boolean"
	},
	UsersFields_organiser_followers_Read:{
		permission:"Boolean"
	},
	UsersFields_organiser_followers_Update:{
		permission:"Boolean"
	},
	UsersFields_organiser_followers_Delete:{
		permission:"Boolean"
	},
	UsersFields_emailVerified:{
		create:"UsersFields_emailVerified_Create",
		read:"UsersFields_emailVerified_Read",
		update:"UsersFields_emailVerified_Update",
		delete:"UsersFields_emailVerified_Delete"
	},
	UsersFields_emailVerified_Create:{
		permission:"Boolean"
	},
	UsersFields_emailVerified_Read:{
		permission:"Boolean"
	},
	UsersFields_emailVerified_Update:{
		permission:"Boolean"
	},
	UsersFields_emailVerified_Delete:{
		permission:"Boolean"
	},
	UsersFields_sso:{
		create:"UsersFields_sso_Create",
		read:"UsersFields_sso_Read",
		update:"UsersFields_sso_Update",
		delete:"UsersFields_sso_Delete",
		fields:"UsersFields_sso_Fields"
	},
	UsersFields_sso_Create:{
		permission:"Boolean"
	},
	UsersFields_sso_Read:{
		permission:"Boolean"
	},
	UsersFields_sso_Update:{
		permission:"Boolean"
	},
	UsersFields_sso_Delete:{
		permission:"Boolean"
	},
	UsersFields_sso_Fields:{
		googleId:"UsersFields_sso_googleId",
		facebookId:"UsersFields_sso_facebookId",
		appleId:"UsersFields_sso_appleId"
	},
	UsersFields_sso_googleId:{
		create:"UsersFields_sso_googleId_Create",
		read:"UsersFields_sso_googleId_Read",
		update:"UsersFields_sso_googleId_Update",
		delete:"UsersFields_sso_googleId_Delete"
	},
	UsersFields_sso_googleId_Create:{
		permission:"Boolean"
	},
	UsersFields_sso_googleId_Read:{
		permission:"Boolean"
	},
	UsersFields_sso_googleId_Update:{
		permission:"Boolean"
	},
	UsersFields_sso_googleId_Delete:{
		permission:"Boolean"
	},
	UsersFields_sso_facebookId:{
		create:"UsersFields_sso_facebookId_Create",
		read:"UsersFields_sso_facebookId_Read",
		update:"UsersFields_sso_facebookId_Update",
		delete:"UsersFields_sso_facebookId_Delete"
	},
	UsersFields_sso_facebookId_Create:{
		permission:"Boolean"
	},
	UsersFields_sso_facebookId_Read:{
		permission:"Boolean"
	},
	UsersFields_sso_facebookId_Update:{
		permission:"Boolean"
	},
	UsersFields_sso_facebookId_Delete:{
		permission:"Boolean"
	},
	UsersFields_sso_appleId:{
		create:"UsersFields_sso_appleId_Create",
		read:"UsersFields_sso_appleId_Read",
		update:"UsersFields_sso_appleId_Update",
		delete:"UsersFields_sso_appleId_Delete"
	},
	UsersFields_sso_appleId_Create:{
		permission:"Boolean"
	},
	UsersFields_sso_appleId_Read:{
		permission:"Boolean"
	},
	UsersFields_sso_appleId_Update:{
		permission:"Boolean"
	},
	UsersFields_sso_appleId_Delete:{
		permission:"Boolean"
	},
	UsersFields_oldPassword:{
		create:"UsersFields_oldPassword_Create",
		read:"UsersFields_oldPassword_Read",
		update:"UsersFields_oldPassword_Update",
		delete:"UsersFields_oldPassword_Delete"
	},
	UsersFields_oldPassword_Create:{
		permission:"Boolean"
	},
	UsersFields_oldPassword_Read:{
		permission:"Boolean"
	},
	UsersFields_oldPassword_Update:{
		permission:"Boolean"
	},
	UsersFields_oldPassword_Delete:{
		permission:"Boolean"
	},
	UsersFields_status:{
		create:"UsersFields_status_Create",
		read:"UsersFields_status_Read",
		update:"UsersFields_status_Update",
		delete:"UsersFields_status_Delete"
	},
	UsersFields_status_Create:{
		permission:"Boolean"
	},
	UsersFields_status_Read:{
		permission:"Boolean"
	},
	UsersFields_status_Update:{
		permission:"Boolean"
	},
	UsersFields_status_Delete:{
		permission:"Boolean"
	},
	UsersFields_updatedAt:{
		create:"UsersFields_updatedAt_Create",
		read:"UsersFields_updatedAt_Read",
		update:"UsersFields_updatedAt_Update",
		delete:"UsersFields_updatedAt_Delete"
	},
	UsersFields_updatedAt_Create:{
		permission:"Boolean"
	},
	UsersFields_updatedAt_Read:{
		permission:"Boolean"
	},
	UsersFields_updatedAt_Update:{
		permission:"Boolean"
	},
	UsersFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	UsersFields_createdAt:{
		create:"UsersFields_createdAt_Create",
		read:"UsersFields_createdAt_Read",
		update:"UsersFields_createdAt_Update",
		delete:"UsersFields_createdAt_Delete"
	},
	UsersFields_createdAt_Create:{
		permission:"Boolean"
	},
	UsersFields_createdAt_Read:{
		permission:"Boolean"
	},
	UsersFields_createdAt_Update:{
		permission:"Boolean"
	},
	UsersFields_createdAt_Delete:{
		permission:"Boolean"
	},
	UsersFields_email:{
		create:"UsersFields_email_Create",
		read:"UsersFields_email_Read",
		update:"UsersFields_email_Update",
		delete:"UsersFields_email_Delete"
	},
	UsersFields_email_Create:{
		permission:"Boolean"
	},
	UsersFields_email_Read:{
		permission:"Boolean"
	},
	UsersFields_email_Update:{
		permission:"Boolean"
	},
	UsersFields_email_Delete:{
		permission:"Boolean"
	},
	UsersFields_password:{
		create:"UsersFields_password_Create",
		read:"UsersFields_password_Read",
		update:"UsersFields_password_Update",
		delete:"UsersFields_password_Delete"
	},
	UsersFields_password_Create:{
		permission:"Boolean"
	},
	UsersFields_password_Read:{
		permission:"Boolean"
	},
	UsersFields_password_Update:{
		permission:"Boolean"
	},
	UsersFields_password_Delete:{
		permission:"Boolean"
	},
	UsersCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	UsersUnlockAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	blog_categoriesAccess:{
		fields:"BlogCategoriesFields",
		create:"BlogCategoriesCreateAccess",
		read:"BlogCategoriesReadAccess",
		update:"BlogCategoriesUpdateAccess",
		delete:"BlogCategoriesDeleteAccess"
	},
	BlogCategoriesFields:{
		name:"BlogCategoriesFields_name",
		value:"BlogCategoriesFields_value",
		icon:"BlogCategoriesFields_icon",
		region:"BlogCategoriesFields_region",
		updatedAt:"BlogCategoriesFields_updatedAt",
		createdAt:"BlogCategoriesFields_createdAt"
	},
	BlogCategoriesFields_name:{
		create:"BlogCategoriesFields_name_Create",
		read:"BlogCategoriesFields_name_Read",
		update:"BlogCategoriesFields_name_Update",
		delete:"BlogCategoriesFields_name_Delete"
	},
	BlogCategoriesFields_name_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_name_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_name_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_name_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesFields_value:{
		create:"BlogCategoriesFields_value_Create",
		read:"BlogCategoriesFields_value_Read",
		update:"BlogCategoriesFields_value_Update",
		delete:"BlogCategoriesFields_value_Delete"
	},
	BlogCategoriesFields_value_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_value_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_value_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_value_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesFields_icon:{
		create:"BlogCategoriesFields_icon_Create",
		read:"BlogCategoriesFields_icon_Read",
		update:"BlogCategoriesFields_icon_Update",
		delete:"BlogCategoriesFields_icon_Delete"
	},
	BlogCategoriesFields_icon_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_icon_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_icon_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_icon_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesFields_region:{
		create:"BlogCategoriesFields_region_Create",
		read:"BlogCategoriesFields_region_Read",
		update:"BlogCategoriesFields_region_Update",
		delete:"BlogCategoriesFields_region_Delete"
	},
	BlogCategoriesFields_region_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_region_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_region_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_region_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesFields_updatedAt:{
		create:"BlogCategoriesFields_updatedAt_Create",
		read:"BlogCategoriesFields_updatedAt_Read",
		update:"BlogCategoriesFields_updatedAt_Update",
		delete:"BlogCategoriesFields_updatedAt_Delete"
	},
	BlogCategoriesFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesFields_createdAt:{
		create:"BlogCategoriesFields_createdAt_Create",
		read:"BlogCategoriesFields_createdAt_Read",
		update:"BlogCategoriesFields_createdAt_Update",
		delete:"BlogCategoriesFields_createdAt_Delete"
	},
	BlogCategoriesFields_createdAt_Create:{
		permission:"Boolean"
	},
	BlogCategoriesFields_createdAt_Read:{
		permission:"Boolean"
	},
	BlogCategoriesFields_createdAt_Update:{
		permission:"Boolean"
	},
	BlogCategoriesFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BlogCategoriesCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BlogCategoriesDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	tagsAccess:{
		fields:"TagsFields",
		create:"TagsCreateAccess",
		read:"TagsReadAccess",
		update:"TagsUpdateAccess",
		delete:"TagsDeleteAccess"
	},
	TagsFields:{
		name:"TagsFields_name",
		value:"TagsFields_value",
		region:"TagsFields_region",
		count:"TagsFields_count",
		skipCountCheck:"TagsFields_skipCountCheck",
		removeNextCron:"TagsFields_removeNextCron"
	},
	TagsFields_name:{
		create:"TagsFields_name_Create",
		read:"TagsFields_name_Read",
		update:"TagsFields_name_Update",
		delete:"TagsFields_name_Delete"
	},
	TagsFields_name_Create:{
		permission:"Boolean"
	},
	TagsFields_name_Read:{
		permission:"Boolean"
	},
	TagsFields_name_Update:{
		permission:"Boolean"
	},
	TagsFields_name_Delete:{
		permission:"Boolean"
	},
	TagsFields_value:{
		create:"TagsFields_value_Create",
		read:"TagsFields_value_Read",
		update:"TagsFields_value_Update",
		delete:"TagsFields_value_Delete"
	},
	TagsFields_value_Create:{
		permission:"Boolean"
	},
	TagsFields_value_Read:{
		permission:"Boolean"
	},
	TagsFields_value_Update:{
		permission:"Boolean"
	},
	TagsFields_value_Delete:{
		permission:"Boolean"
	},
	TagsFields_region:{
		create:"TagsFields_region_Create",
		read:"TagsFields_region_Read",
		update:"TagsFields_region_Update",
		delete:"TagsFields_region_Delete"
	},
	TagsFields_region_Create:{
		permission:"Boolean"
	},
	TagsFields_region_Read:{
		permission:"Boolean"
	},
	TagsFields_region_Update:{
		permission:"Boolean"
	},
	TagsFields_region_Delete:{
		permission:"Boolean"
	},
	TagsFields_count:{
		create:"TagsFields_count_Create",
		read:"TagsFields_count_Read",
		update:"TagsFields_count_Update",
		delete:"TagsFields_count_Delete"
	},
	TagsFields_count_Create:{
		permission:"Boolean"
	},
	TagsFields_count_Read:{
		permission:"Boolean"
	},
	TagsFields_count_Update:{
		permission:"Boolean"
	},
	TagsFields_count_Delete:{
		permission:"Boolean"
	},
	TagsFields_skipCountCheck:{
		create:"TagsFields_skipCountCheck_Create",
		read:"TagsFields_skipCountCheck_Read",
		update:"TagsFields_skipCountCheck_Update",
		delete:"TagsFields_skipCountCheck_Delete"
	},
	TagsFields_skipCountCheck_Create:{
		permission:"Boolean"
	},
	TagsFields_skipCountCheck_Read:{
		permission:"Boolean"
	},
	TagsFields_skipCountCheck_Update:{
		permission:"Boolean"
	},
	TagsFields_skipCountCheck_Delete:{
		permission:"Boolean"
	},
	TagsFields_removeNextCron:{
		create:"TagsFields_removeNextCron_Create",
		read:"TagsFields_removeNextCron_Read",
		update:"TagsFields_removeNextCron_Update",
		delete:"TagsFields_removeNextCron_Delete"
	},
	TagsFields_removeNextCron_Create:{
		permission:"Boolean"
	},
	TagsFields_removeNextCron_Read:{
		permission:"Boolean"
	},
	TagsFields_removeNextCron_Update:{
		permission:"Boolean"
	},
	TagsFields_removeNextCron_Delete:{
		permission:"Boolean"
	},
	TagsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	TagsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	specialTagsAccess:{
		fields:"SpecialTagsFields",
		create:"SpecialTagsCreateAccess",
		read:"SpecialTagsReadAccess",
		update:"SpecialTagsUpdateAccess",
		delete:"SpecialTagsDeleteAccess"
	},
	SpecialTagsFields:{
		name:"SpecialTagsFields_name",
		value:"SpecialTagsFields_value",
		region:"SpecialTagsFields_region"
	},
	SpecialTagsFields_name:{
		create:"SpecialTagsFields_name_Create",
		read:"SpecialTagsFields_name_Read",
		update:"SpecialTagsFields_name_Update",
		delete:"SpecialTagsFields_name_Delete"
	},
	SpecialTagsFields_name_Create:{
		permission:"Boolean"
	},
	SpecialTagsFields_name_Read:{
		permission:"Boolean"
	},
	SpecialTagsFields_name_Update:{
		permission:"Boolean"
	},
	SpecialTagsFields_name_Delete:{
		permission:"Boolean"
	},
	SpecialTagsFields_value:{
		create:"SpecialTagsFields_value_Create",
		read:"SpecialTagsFields_value_Read",
		update:"SpecialTagsFields_value_Update",
		delete:"SpecialTagsFields_value_Delete"
	},
	SpecialTagsFields_value_Create:{
		permission:"Boolean"
	},
	SpecialTagsFields_value_Read:{
		permission:"Boolean"
	},
	SpecialTagsFields_value_Update:{
		permission:"Boolean"
	},
	SpecialTagsFields_value_Delete:{
		permission:"Boolean"
	},
	SpecialTagsFields_region:{
		create:"SpecialTagsFields_region_Create",
		read:"SpecialTagsFields_region_Read",
		update:"SpecialTagsFields_region_Update",
		delete:"SpecialTagsFields_region_Delete"
	},
	SpecialTagsFields_region_Create:{
		permission:"Boolean"
	},
	SpecialTagsFields_region_Read:{
		permission:"Boolean"
	},
	SpecialTagsFields_region_Update:{
		permission:"Boolean"
	},
	SpecialTagsFields_region_Delete:{
		permission:"Boolean"
	},
	SpecialTagsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SpecialTagsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	dateAccess:{
		fields:"DateFields",
		create:"DateCreateAccess",
		read:"DateReadAccess",
		update:"DateUpdateAccess",
		delete:"DateDeleteAccess"
	},
	DateFields:{
		name:"DateFields_name",
		value:"DateFields_value",
		type:"DateFields_type",
		start:"DateFields_start",
		end:"DateFields_end",
		_title:"DateFields__title",
		region:"DateFields_region",
		updatedAt:"DateFields_updatedAt",
		createdAt:"DateFields_createdAt"
	},
	DateFields_name:{
		create:"DateFields_name_Create",
		read:"DateFields_name_Read",
		update:"DateFields_name_Update",
		delete:"DateFields_name_Delete"
	},
	DateFields_name_Create:{
		permission:"Boolean"
	},
	DateFields_name_Read:{
		permission:"Boolean"
	},
	DateFields_name_Update:{
		permission:"Boolean"
	},
	DateFields_name_Delete:{
		permission:"Boolean"
	},
	DateFields_value:{
		create:"DateFields_value_Create",
		read:"DateFields_value_Read",
		update:"DateFields_value_Update",
		delete:"DateFields_value_Delete"
	},
	DateFields_value_Create:{
		permission:"Boolean"
	},
	DateFields_value_Read:{
		permission:"Boolean"
	},
	DateFields_value_Update:{
		permission:"Boolean"
	},
	DateFields_value_Delete:{
		permission:"Boolean"
	},
	DateFields_type:{
		create:"DateFields_type_Create",
		read:"DateFields_type_Read",
		update:"DateFields_type_Update",
		delete:"DateFields_type_Delete"
	},
	DateFields_type_Create:{
		permission:"Boolean"
	},
	DateFields_type_Read:{
		permission:"Boolean"
	},
	DateFields_type_Update:{
		permission:"Boolean"
	},
	DateFields_type_Delete:{
		permission:"Boolean"
	},
	DateFields_start:{
		create:"DateFields_start_Create",
		read:"DateFields_start_Read",
		update:"DateFields_start_Update",
		delete:"DateFields_start_Delete"
	},
	DateFields_start_Create:{
		permission:"Boolean"
	},
	DateFields_start_Read:{
		permission:"Boolean"
	},
	DateFields_start_Update:{
		permission:"Boolean"
	},
	DateFields_start_Delete:{
		permission:"Boolean"
	},
	DateFields_end:{
		create:"DateFields_end_Create",
		read:"DateFields_end_Read",
		update:"DateFields_end_Update",
		delete:"DateFields_end_Delete"
	},
	DateFields_end_Create:{
		permission:"Boolean"
	},
	DateFields_end_Read:{
		permission:"Boolean"
	},
	DateFields_end_Update:{
		permission:"Boolean"
	},
	DateFields_end_Delete:{
		permission:"Boolean"
	},
	DateFields__title:{
		create:"DateFields__title_Create",
		read:"DateFields__title_Read",
		update:"DateFields__title_Update",
		delete:"DateFields__title_Delete"
	},
	DateFields__title_Create:{
		permission:"Boolean"
	},
	DateFields__title_Read:{
		permission:"Boolean"
	},
	DateFields__title_Update:{
		permission:"Boolean"
	},
	DateFields__title_Delete:{
		permission:"Boolean"
	},
	DateFields_region:{
		create:"DateFields_region_Create",
		read:"DateFields_region_Read",
		update:"DateFields_region_Update",
		delete:"DateFields_region_Delete"
	},
	DateFields_region_Create:{
		permission:"Boolean"
	},
	DateFields_region_Read:{
		permission:"Boolean"
	},
	DateFields_region_Update:{
		permission:"Boolean"
	},
	DateFields_region_Delete:{
		permission:"Boolean"
	},
	DateFields_updatedAt:{
		create:"DateFields_updatedAt_Create",
		read:"DateFields_updatedAt_Read",
		update:"DateFields_updatedAt_Update",
		delete:"DateFields_updatedAt_Delete"
	},
	DateFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DateFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DateFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DateFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DateFields_createdAt:{
		create:"DateFields_createdAt_Create",
		read:"DateFields_createdAt_Read",
		update:"DateFields_createdAt_Update",
		delete:"DateFields_createdAt_Delete"
	},
	DateFields_createdAt_Create:{
		permission:"Boolean"
	},
	DateFields_createdAt_Read:{
		permission:"Boolean"
	},
	DateFields_createdAt_Update:{
		permission:"Boolean"
	},
	DateFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DateCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DateDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	districtsAccess:{
		fields:"DistrictsFields",
		create:"DistrictsCreateAccess",
		read:"DistrictsReadAccess",
		update:"DistrictsUpdateAccess",
		delete:"DistrictsDeleteAccess"
	},
	DistrictsFields:{
		name:"DistrictsFields_name",
		level:"DistrictsFields_level",
		children:"DistrictsFields_children",
		value:"DistrictsFields_value",
		lat:"DistrictsFields_lat",
		long:"DistrictsFields_long",
		radius:"DistrictsFields_radius",
		region:"DistrictsFields_region",
		updatedAt:"DistrictsFields_updatedAt",
		createdAt:"DistrictsFields_createdAt"
	},
	DistrictsFields_name:{
		create:"DistrictsFields_name_Create",
		read:"DistrictsFields_name_Read",
		update:"DistrictsFields_name_Update",
		delete:"DistrictsFields_name_Delete"
	},
	DistrictsFields_name_Create:{
		permission:"Boolean"
	},
	DistrictsFields_name_Read:{
		permission:"Boolean"
	},
	DistrictsFields_name_Update:{
		permission:"Boolean"
	},
	DistrictsFields_name_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_level:{
		create:"DistrictsFields_level_Create",
		read:"DistrictsFields_level_Read",
		update:"DistrictsFields_level_Update",
		delete:"DistrictsFields_level_Delete"
	},
	DistrictsFields_level_Create:{
		permission:"Boolean"
	},
	DistrictsFields_level_Read:{
		permission:"Boolean"
	},
	DistrictsFields_level_Update:{
		permission:"Boolean"
	},
	DistrictsFields_level_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_children:{
		create:"DistrictsFields_children_Create",
		read:"DistrictsFields_children_Read",
		update:"DistrictsFields_children_Update",
		delete:"DistrictsFields_children_Delete"
	},
	DistrictsFields_children_Create:{
		permission:"Boolean"
	},
	DistrictsFields_children_Read:{
		permission:"Boolean"
	},
	DistrictsFields_children_Update:{
		permission:"Boolean"
	},
	DistrictsFields_children_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_value:{
		create:"DistrictsFields_value_Create",
		read:"DistrictsFields_value_Read",
		update:"DistrictsFields_value_Update",
		delete:"DistrictsFields_value_Delete"
	},
	DistrictsFields_value_Create:{
		permission:"Boolean"
	},
	DistrictsFields_value_Read:{
		permission:"Boolean"
	},
	DistrictsFields_value_Update:{
		permission:"Boolean"
	},
	DistrictsFields_value_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_lat:{
		create:"DistrictsFields_lat_Create",
		read:"DistrictsFields_lat_Read",
		update:"DistrictsFields_lat_Update",
		delete:"DistrictsFields_lat_Delete"
	},
	DistrictsFields_lat_Create:{
		permission:"Boolean"
	},
	DistrictsFields_lat_Read:{
		permission:"Boolean"
	},
	DistrictsFields_lat_Update:{
		permission:"Boolean"
	},
	DistrictsFields_lat_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_long:{
		create:"DistrictsFields_long_Create",
		read:"DistrictsFields_long_Read",
		update:"DistrictsFields_long_Update",
		delete:"DistrictsFields_long_Delete"
	},
	DistrictsFields_long_Create:{
		permission:"Boolean"
	},
	DistrictsFields_long_Read:{
		permission:"Boolean"
	},
	DistrictsFields_long_Update:{
		permission:"Boolean"
	},
	DistrictsFields_long_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_radius:{
		create:"DistrictsFields_radius_Create",
		read:"DistrictsFields_radius_Read",
		update:"DistrictsFields_radius_Update",
		delete:"DistrictsFields_radius_Delete"
	},
	DistrictsFields_radius_Create:{
		permission:"Boolean"
	},
	DistrictsFields_radius_Read:{
		permission:"Boolean"
	},
	DistrictsFields_radius_Update:{
		permission:"Boolean"
	},
	DistrictsFields_radius_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_region:{
		create:"DistrictsFields_region_Create",
		read:"DistrictsFields_region_Read",
		update:"DistrictsFields_region_Update",
		delete:"DistrictsFields_region_Delete"
	},
	DistrictsFields_region_Create:{
		permission:"Boolean"
	},
	DistrictsFields_region_Read:{
		permission:"Boolean"
	},
	DistrictsFields_region_Update:{
		permission:"Boolean"
	},
	DistrictsFields_region_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_updatedAt:{
		create:"DistrictsFields_updatedAt_Create",
		read:"DistrictsFields_updatedAt_Read",
		update:"DistrictsFields_updatedAt_Update",
		delete:"DistrictsFields_updatedAt_Delete"
	},
	DistrictsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DistrictsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DistrictsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DistrictsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DistrictsFields_createdAt:{
		create:"DistrictsFields_createdAt_Create",
		read:"DistrictsFields_createdAt_Read",
		update:"DistrictsFields_createdAt_Update",
		delete:"DistrictsFields_createdAt_Delete"
	},
	DistrictsFields_createdAt_Create:{
		permission:"Boolean"
	},
	DistrictsFields_createdAt_Read:{
		permission:"Boolean"
	},
	DistrictsFields_createdAt_Update:{
		permission:"Boolean"
	},
	DistrictsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DistrictsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DistrictsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	audienceAccess:{
		fields:"AudienceFields",
		create:"AudienceCreateAccess",
		read:"AudienceReadAccess",
		update:"AudienceUpdateAccess",
		delete:"AudienceDeleteAccess"
	},
	AudienceFields:{
		name:"AudienceFields_name",
		value:"AudienceFields_value",
		icon:"AudienceFields_icon",
		region:"AudienceFields_region",
		updatedAt:"AudienceFields_updatedAt",
		createdAt:"AudienceFields_createdAt"
	},
	AudienceFields_name:{
		create:"AudienceFields_name_Create",
		read:"AudienceFields_name_Read",
		update:"AudienceFields_name_Update",
		delete:"AudienceFields_name_Delete"
	},
	AudienceFields_name_Create:{
		permission:"Boolean"
	},
	AudienceFields_name_Read:{
		permission:"Boolean"
	},
	AudienceFields_name_Update:{
		permission:"Boolean"
	},
	AudienceFields_name_Delete:{
		permission:"Boolean"
	},
	AudienceFields_value:{
		create:"AudienceFields_value_Create",
		read:"AudienceFields_value_Read",
		update:"AudienceFields_value_Update",
		delete:"AudienceFields_value_Delete"
	},
	AudienceFields_value_Create:{
		permission:"Boolean"
	},
	AudienceFields_value_Read:{
		permission:"Boolean"
	},
	AudienceFields_value_Update:{
		permission:"Boolean"
	},
	AudienceFields_value_Delete:{
		permission:"Boolean"
	},
	AudienceFields_icon:{
		create:"AudienceFields_icon_Create",
		read:"AudienceFields_icon_Read",
		update:"AudienceFields_icon_Update",
		delete:"AudienceFields_icon_Delete"
	},
	AudienceFields_icon_Create:{
		permission:"Boolean"
	},
	AudienceFields_icon_Read:{
		permission:"Boolean"
	},
	AudienceFields_icon_Update:{
		permission:"Boolean"
	},
	AudienceFields_icon_Delete:{
		permission:"Boolean"
	},
	AudienceFields_region:{
		create:"AudienceFields_region_Create",
		read:"AudienceFields_region_Read",
		update:"AudienceFields_region_Update",
		delete:"AudienceFields_region_Delete"
	},
	AudienceFields_region_Create:{
		permission:"Boolean"
	},
	AudienceFields_region_Read:{
		permission:"Boolean"
	},
	AudienceFields_region_Update:{
		permission:"Boolean"
	},
	AudienceFields_region_Delete:{
		permission:"Boolean"
	},
	AudienceFields_updatedAt:{
		create:"AudienceFields_updatedAt_Create",
		read:"AudienceFields_updatedAt_Read",
		update:"AudienceFields_updatedAt_Update",
		delete:"AudienceFields_updatedAt_Delete"
	},
	AudienceFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AudienceFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AudienceFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AudienceFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AudienceFields_createdAt:{
		create:"AudienceFields_createdAt_Create",
		read:"AudienceFields_createdAt_Read",
		update:"AudienceFields_createdAt_Update",
		delete:"AudienceFields_createdAt_Delete"
	},
	AudienceFields_createdAt_Create:{
		permission:"Boolean"
	},
	AudienceFields_createdAt_Read:{
		permission:"Boolean"
	},
	AudienceFields_createdAt_Update:{
		permission:"Boolean"
	},
	AudienceFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AudienceCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AudienceDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	eventsAccess:{
		fields:"EventsFields",
		create:"EventsCreateAccess",
		read:"EventsReadAccess",
		update:"EventsUpdateAccess",
		delete:"EventsDeleteAccess"
	},
	EventsFields:{
		region:"EventsFields_region",
		creator:"EventsFields_creator",
		permalink:"EventsFields_permalink",
		status:"EventsFields_status",
		level:"EventsFields_level",
		parent:"EventsFields_parent",
		name:"EventsFields_name",
		thumbnail:"EventsFields_thumbnail",
		banner:"EventsFields_banner",
		mobileBanner:"EventsFields_mobileBanner",
		sections:"EventsFields_sections",
		ticketing:"EventsFields_ticketing",
		content:"EventsFields_content",
		legacyContent:"EventsFields_legacyContent",
		showLegacyContent:"EventsFields_showLegacyContent",
		criteria:"EventsFields_criteria",
		walkIn:"EventsFields_walkIn",
		contact:"EventsFields_contact",
		views:"EventsFields_views",
		free:"EventsFields_free",
		fees:"EventsFields_fees",
		homePageInternalClick:"EventsFields_homePageInternalClick",
		homePageImpression:"EventsFields_homePageImpression",
		eventSearchInternalClick:"EventsFields_eventSearchInternalClick",
		eventListImpression:"EventsFields_eventListImpression",
		latestEventInternalClick:"EventsFields_latestEventInternalClick",
		latestEventImpression:"EventsFields_latestEventImpression",
		topTenEventRankingInternalClick:"EventsFields_topTenEventRankingInternalClick",
		topTenImpression:"EventsFields_topTenImpression",
		editorChoiceInternalClick:"EventsFields_editorChoiceInternalClick",
		editorChoiceImpression:"EventsFields_editorChoiceImpression",
		otherEventRankingInternalClick:"EventsFields_otherEventRankingInternalClick",
		otherEventRankingImpression:"EventsFields_otherEventRankingImpression",
		miscInternalClick:"EventsFields_miscInternalClick",
		miscImpression:"EventsFields_miscImpression",
		internalClick:"EventsFields_internalClick",
		totalImpression:"EventsFields_totalImpression",
		externalClick:"EventsFields_externalClick",
		outboundClick:"EventsFields_outboundClick",
		_viewsCountByMonth:"EventsFields__viewsCountByMonth",
		legacyGallery:"EventsFields_legacyGallery",
		earliestStartDate:"EventsFields_earliestStartDate",
		latestEndDate:"EventsFields_latestEndDate",
		updatedAt:"EventsFields_updatedAt",
		createdAt:"EventsFields_createdAt"
	},
	EventsFields_region:{
		create:"EventsFields_region_Create",
		read:"EventsFields_region_Read",
		update:"EventsFields_region_Update",
		delete:"EventsFields_region_Delete"
	},
	EventsFields_region_Create:{
		permission:"Boolean"
	},
	EventsFields_region_Read:{
		permission:"Boolean"
	},
	EventsFields_region_Update:{
		permission:"Boolean"
	},
	EventsFields_region_Delete:{
		permission:"Boolean"
	},
	EventsFields_creator:{
		create:"EventsFields_creator_Create",
		read:"EventsFields_creator_Read",
		update:"EventsFields_creator_Update",
		delete:"EventsFields_creator_Delete"
	},
	EventsFields_creator_Create:{
		permission:"Boolean"
	},
	EventsFields_creator_Read:{
		permission:"Boolean"
	},
	EventsFields_creator_Update:{
		permission:"Boolean"
	},
	EventsFields_creator_Delete:{
		permission:"Boolean"
	},
	EventsFields_permalink:{
		create:"EventsFields_permalink_Create",
		read:"EventsFields_permalink_Read",
		update:"EventsFields_permalink_Update",
		delete:"EventsFields_permalink_Delete"
	},
	EventsFields_permalink_Create:{
		permission:"Boolean"
	},
	EventsFields_permalink_Read:{
		permission:"Boolean"
	},
	EventsFields_permalink_Update:{
		permission:"Boolean"
	},
	EventsFields_permalink_Delete:{
		permission:"Boolean"
	},
	EventsFields_status:{
		create:"EventsFields_status_Create",
		read:"EventsFields_status_Read",
		update:"EventsFields_status_Update",
		delete:"EventsFields_status_Delete"
	},
	EventsFields_status_Create:{
		permission:"Boolean"
	},
	EventsFields_status_Read:{
		permission:"Boolean"
	},
	EventsFields_status_Update:{
		permission:"Boolean"
	},
	EventsFields_status_Delete:{
		permission:"Boolean"
	},
	EventsFields_level:{
		create:"EventsFields_level_Create",
		read:"EventsFields_level_Read",
		update:"EventsFields_level_Update",
		delete:"EventsFields_level_Delete"
	},
	EventsFields_level_Create:{
		permission:"Boolean"
	},
	EventsFields_level_Read:{
		permission:"Boolean"
	},
	EventsFields_level_Update:{
		permission:"Boolean"
	},
	EventsFields_level_Delete:{
		permission:"Boolean"
	},
	EventsFields_parent:{
		create:"EventsFields_parent_Create",
		read:"EventsFields_parent_Read",
		update:"EventsFields_parent_Update",
		delete:"EventsFields_parent_Delete"
	},
	EventsFields_parent_Create:{
		permission:"Boolean"
	},
	EventsFields_parent_Read:{
		permission:"Boolean"
	},
	EventsFields_parent_Update:{
		permission:"Boolean"
	},
	EventsFields_parent_Delete:{
		permission:"Boolean"
	},
	EventsFields_name:{
		create:"EventsFields_name_Create",
		read:"EventsFields_name_Read",
		update:"EventsFields_name_Update",
		delete:"EventsFields_name_Delete"
	},
	EventsFields_name_Create:{
		permission:"Boolean"
	},
	EventsFields_name_Read:{
		permission:"Boolean"
	},
	EventsFields_name_Update:{
		permission:"Boolean"
	},
	EventsFields_name_Delete:{
		permission:"Boolean"
	},
	EventsFields_thumbnail:{
		create:"EventsFields_thumbnail_Create",
		read:"EventsFields_thumbnail_Read",
		update:"EventsFields_thumbnail_Update",
		delete:"EventsFields_thumbnail_Delete"
	},
	EventsFields_thumbnail_Create:{
		permission:"Boolean"
	},
	EventsFields_thumbnail_Read:{
		permission:"Boolean"
	},
	EventsFields_thumbnail_Update:{
		permission:"Boolean"
	},
	EventsFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	EventsFields_banner:{
		create:"EventsFields_banner_Create",
		read:"EventsFields_banner_Read",
		update:"EventsFields_banner_Update",
		delete:"EventsFields_banner_Delete"
	},
	EventsFields_banner_Create:{
		permission:"Boolean"
	},
	EventsFields_banner_Read:{
		permission:"Boolean"
	},
	EventsFields_banner_Update:{
		permission:"Boolean"
	},
	EventsFields_banner_Delete:{
		permission:"Boolean"
	},
	EventsFields_mobileBanner:{
		create:"EventsFields_mobileBanner_Create",
		read:"EventsFields_mobileBanner_Read",
		update:"EventsFields_mobileBanner_Update",
		delete:"EventsFields_mobileBanner_Delete"
	},
	EventsFields_mobileBanner_Create:{
		permission:"Boolean"
	},
	EventsFields_mobileBanner_Read:{
		permission:"Boolean"
	},
	EventsFields_mobileBanner_Update:{
		permission:"Boolean"
	},
	EventsFields_mobileBanner_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections:{
		create:"EventsFields_sections_Create",
		read:"EventsFields_sections_Read",
		update:"EventsFields_sections_Update",
		delete:"EventsFields_sections_Delete",
		fields:"EventsFields_sections_Fields"
	},
	EventsFields_sections_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_Fields:{
		startDatetime:"EventsFields_sections_startDatetime",
		endDatetime:"EventsFields_sections_endDatetime",
		fullDay:"EventsFields_sections_fullDay",
		repeat:"EventsFields_sections_repeat",
		toThisDay:"EventsFields_sections_toThisDay",
		recurrance:"EventsFields_sections_recurrance",
		sameLocation:"EventsFields_sections_sameLocation",
		location:"EventsFields_sections_location",
		district:"EventsFields_sections_district",
		coordinate:"EventsFields_sections_coordinate",
		linkAddress:"EventsFields_sections_linkAddress",
		address:"EventsFields_sections_address",
		id:"EventsFields_sections_id"
	},
	EventsFields_sections_startDatetime:{
		create:"EventsFields_sections_startDatetime_Create",
		read:"EventsFields_sections_startDatetime_Read",
		update:"EventsFields_sections_startDatetime_Update",
		delete:"EventsFields_sections_startDatetime_Delete"
	},
	EventsFields_sections_startDatetime_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_startDatetime_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_startDatetime_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_startDatetime_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_endDatetime:{
		create:"EventsFields_sections_endDatetime_Create",
		read:"EventsFields_sections_endDatetime_Read",
		update:"EventsFields_sections_endDatetime_Update",
		delete:"EventsFields_sections_endDatetime_Delete"
	},
	EventsFields_sections_endDatetime_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_endDatetime_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_endDatetime_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_endDatetime_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_fullDay:{
		create:"EventsFields_sections_fullDay_Create",
		read:"EventsFields_sections_fullDay_Read",
		update:"EventsFields_sections_fullDay_Update",
		delete:"EventsFields_sections_fullDay_Delete"
	},
	EventsFields_sections_fullDay_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_fullDay_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_fullDay_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_fullDay_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_repeat:{
		create:"EventsFields_sections_repeat_Create",
		read:"EventsFields_sections_repeat_Read",
		update:"EventsFields_sections_repeat_Update",
		delete:"EventsFields_sections_repeat_Delete"
	},
	EventsFields_sections_repeat_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_repeat_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_repeat_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_repeat_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_toThisDay:{
		create:"EventsFields_sections_toThisDay_Create",
		read:"EventsFields_sections_toThisDay_Read",
		update:"EventsFields_sections_toThisDay_Update",
		delete:"EventsFields_sections_toThisDay_Delete"
	},
	EventsFields_sections_toThisDay_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_toThisDay_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_toThisDay_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_toThisDay_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance:{
		create:"EventsFields_sections_recurrance_Create",
		read:"EventsFields_sections_recurrance_Read",
		update:"EventsFields_sections_recurrance_Update",
		delete:"EventsFields_sections_recurrance_Delete",
		fields:"EventsFields_sections_recurrance_Fields"
	},
	EventsFields_sections_recurrance_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_Fields:{
		type:"EventsFields_sections_recurrance_type",
		weekday:"EventsFields_sections_recurrance_weekday"
	},
	EventsFields_sections_recurrance_type:{
		create:"EventsFields_sections_recurrance_type_Create",
		read:"EventsFields_sections_recurrance_type_Read",
		update:"EventsFields_sections_recurrance_type_Update",
		delete:"EventsFields_sections_recurrance_type_Delete"
	},
	EventsFields_sections_recurrance_type_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_type_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_type_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_type_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_weekday:{
		create:"EventsFields_sections_recurrance_weekday_Create",
		read:"EventsFields_sections_recurrance_weekday_Read",
		update:"EventsFields_sections_recurrance_weekday_Update",
		delete:"EventsFields_sections_recurrance_weekday_Delete"
	},
	EventsFields_sections_recurrance_weekday_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_weekday_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_weekday_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_recurrance_weekday_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_sameLocation:{
		create:"EventsFields_sections_sameLocation_Create",
		read:"EventsFields_sections_sameLocation_Read",
		update:"EventsFields_sections_sameLocation_Update",
		delete:"EventsFields_sections_sameLocation_Delete"
	},
	EventsFields_sections_sameLocation_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_sameLocation_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_sameLocation_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_sameLocation_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_location:{
		create:"EventsFields_sections_location_Create",
		read:"EventsFields_sections_location_Read",
		update:"EventsFields_sections_location_Update",
		delete:"EventsFields_sections_location_Delete"
	},
	EventsFields_sections_location_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_location_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_location_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_location_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_district:{
		create:"EventsFields_sections_district_Create",
		read:"EventsFields_sections_district_Read",
		update:"EventsFields_sections_district_Update",
		delete:"EventsFields_sections_district_Delete"
	},
	EventsFields_sections_district_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_district_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_district_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_district_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_coordinate:{
		create:"EventsFields_sections_coordinate_Create",
		read:"EventsFields_sections_coordinate_Read",
		update:"EventsFields_sections_coordinate_Update",
		delete:"EventsFields_sections_coordinate_Delete"
	},
	EventsFields_sections_coordinate_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_coordinate_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_coordinate_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_coordinate_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_linkAddress:{
		create:"EventsFields_sections_linkAddress_Create",
		read:"EventsFields_sections_linkAddress_Read",
		update:"EventsFields_sections_linkAddress_Update",
		delete:"EventsFields_sections_linkAddress_Delete"
	},
	EventsFields_sections_linkAddress_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_linkAddress_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_linkAddress_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_linkAddress_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_address:{
		create:"EventsFields_sections_address_Create",
		read:"EventsFields_sections_address_Read",
		update:"EventsFields_sections_address_Update",
		delete:"EventsFields_sections_address_Delete"
	},
	EventsFields_sections_address_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_address_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_address_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_address_Delete:{
		permission:"Boolean"
	},
	EventsFields_sections_id:{
		create:"EventsFields_sections_id_Create",
		read:"EventsFields_sections_id_Read",
		update:"EventsFields_sections_id_Update",
		delete:"EventsFields_sections_id_Delete"
	},
	EventsFields_sections_id_Create:{
		permission:"Boolean"
	},
	EventsFields_sections_id_Read:{
		permission:"Boolean"
	},
	EventsFields_sections_id_Update:{
		permission:"Boolean"
	},
	EventsFields_sections_id_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing:{
		create:"EventsFields_ticketing_Create",
		read:"EventsFields_ticketing_Read",
		update:"EventsFields_ticketing_Update",
		delete:"EventsFields_ticketing_Delete",
		fields:"EventsFields_ticketing_Fields"
	},
	EventsFields_ticketing_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_Fields:{
		startDatetime:"EventsFields_ticketing_startDatetime",
		endDatetime:"EventsFields_ticketing_endDatetime",
		fullDay:"EventsFields_ticketing_fullDay",
		type:"EventsFields_ticketing_type",
		ticketingType:"EventsFields_ticketing_ticketingType",
		promotionType:"EventsFields_ticketing_promotionType",
		location:"EventsFields_ticketing_location",
		website:"EventsFields_ticketing_website",
		description:"EventsFields_ticketing_description",
		id:"EventsFields_ticketing_id"
	},
	EventsFields_ticketing_startDatetime:{
		create:"EventsFields_ticketing_startDatetime_Create",
		read:"EventsFields_ticketing_startDatetime_Read",
		update:"EventsFields_ticketing_startDatetime_Update",
		delete:"EventsFields_ticketing_startDatetime_Delete"
	},
	EventsFields_ticketing_startDatetime_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_startDatetime_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_startDatetime_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_startDatetime_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_endDatetime:{
		create:"EventsFields_ticketing_endDatetime_Create",
		read:"EventsFields_ticketing_endDatetime_Read",
		update:"EventsFields_ticketing_endDatetime_Update",
		delete:"EventsFields_ticketing_endDatetime_Delete"
	},
	EventsFields_ticketing_endDatetime_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_endDatetime_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_endDatetime_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_endDatetime_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_fullDay:{
		create:"EventsFields_ticketing_fullDay_Create",
		read:"EventsFields_ticketing_fullDay_Read",
		update:"EventsFields_ticketing_fullDay_Update",
		delete:"EventsFields_ticketing_fullDay_Delete"
	},
	EventsFields_ticketing_fullDay_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_fullDay_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_fullDay_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_fullDay_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_type:{
		create:"EventsFields_ticketing_type_Create",
		read:"EventsFields_ticketing_type_Read",
		update:"EventsFields_ticketing_type_Update",
		delete:"EventsFields_ticketing_type_Delete"
	},
	EventsFields_ticketing_type_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_type_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_type_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_type_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_ticketingType:{
		create:"EventsFields_ticketing_ticketingType_Create",
		read:"EventsFields_ticketing_ticketingType_Read",
		update:"EventsFields_ticketing_ticketingType_Update",
		delete:"EventsFields_ticketing_ticketingType_Delete"
	},
	EventsFields_ticketing_ticketingType_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_ticketingType_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_ticketingType_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_ticketingType_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_promotionType:{
		create:"EventsFields_ticketing_promotionType_Create",
		read:"EventsFields_ticketing_promotionType_Read",
		update:"EventsFields_ticketing_promotionType_Update",
		delete:"EventsFields_ticketing_promotionType_Delete"
	},
	EventsFields_ticketing_promotionType_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_promotionType_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_promotionType_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_promotionType_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_location:{
		create:"EventsFields_ticketing_location_Create",
		read:"EventsFields_ticketing_location_Read",
		update:"EventsFields_ticketing_location_Update",
		delete:"EventsFields_ticketing_location_Delete"
	},
	EventsFields_ticketing_location_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_location_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_location_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_location_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_website:{
		create:"EventsFields_ticketing_website_Create",
		read:"EventsFields_ticketing_website_Read",
		update:"EventsFields_ticketing_website_Update",
		delete:"EventsFields_ticketing_website_Delete"
	},
	EventsFields_ticketing_website_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_website_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_website_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_website_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_description:{
		create:"EventsFields_ticketing_description_Create",
		read:"EventsFields_ticketing_description_Read",
		update:"EventsFields_ticketing_description_Update",
		delete:"EventsFields_ticketing_description_Delete"
	},
	EventsFields_ticketing_description_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_description_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_description_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_description_Delete:{
		permission:"Boolean"
	},
	EventsFields_ticketing_id:{
		create:"EventsFields_ticketing_id_Create",
		read:"EventsFields_ticketing_id_Read",
		update:"EventsFields_ticketing_id_Update",
		delete:"EventsFields_ticketing_id_Delete"
	},
	EventsFields_ticketing_id_Create:{
		permission:"Boolean"
	},
	EventsFields_ticketing_id_Read:{
		permission:"Boolean"
	},
	EventsFields_ticketing_id_Update:{
		permission:"Boolean"
	},
	EventsFields_ticketing_id_Delete:{
		permission:"Boolean"
	},
	EventsFields_content:{
		create:"EventsFields_content_Create",
		read:"EventsFields_content_Read",
		update:"EventsFields_content_Update",
		delete:"EventsFields_content_Delete"
	},
	EventsFields_content_Create:{
		permission:"Boolean"
	},
	EventsFields_content_Read:{
		permission:"Boolean"
	},
	EventsFields_content_Update:{
		permission:"Boolean"
	},
	EventsFields_content_Delete:{
		permission:"Boolean"
	},
	EventsFields_legacyContent:{
		create:"EventsFields_legacyContent_Create",
		read:"EventsFields_legacyContent_Read",
		update:"EventsFields_legacyContent_Update",
		delete:"EventsFields_legacyContent_Delete"
	},
	EventsFields_legacyContent_Create:{
		permission:"Boolean"
	},
	EventsFields_legacyContent_Read:{
		permission:"Boolean"
	},
	EventsFields_legacyContent_Update:{
		permission:"Boolean"
	},
	EventsFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	EventsFields_showLegacyContent:{
		create:"EventsFields_showLegacyContent_Create",
		read:"EventsFields_showLegacyContent_Read",
		update:"EventsFields_showLegacyContent_Update",
		delete:"EventsFields_showLegacyContent_Delete"
	},
	EventsFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	EventsFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	EventsFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	EventsFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	EventsFields_criteria:{
		create:"EventsFields_criteria_Create",
		read:"EventsFields_criteria_Read",
		update:"EventsFields_criteria_Update",
		delete:"EventsFields_criteria_Delete",
		fields:"EventsFields_criteria_Fields"
	},
	EventsFields_criteria_Create:{
		permission:"Boolean"
	},
	EventsFields_criteria_Read:{
		permission:"Boolean"
	},
	EventsFields_criteria_Update:{
		permission:"Boolean"
	},
	EventsFields_criteria_Delete:{
		permission:"Boolean"
	},
	EventsFields_criteria_Fields:{
		categories:"EventsFields_criteria_categories",
		audiences:"EventsFields_criteria_audiences",
		tags:"EventsFields_criteria_tags",
		specialTags:"EventsFields_criteria_specialTags"
	},
	EventsFields_criteria_categories:{
		create:"EventsFields_criteria_categories_Create",
		read:"EventsFields_criteria_categories_Read",
		update:"EventsFields_criteria_categories_Update",
		delete:"EventsFields_criteria_categories_Delete"
	},
	EventsFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	EventsFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	EventsFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	EventsFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	EventsFields_criteria_audiences:{
		create:"EventsFields_criteria_audiences_Create",
		read:"EventsFields_criteria_audiences_Read",
		update:"EventsFields_criteria_audiences_Update",
		delete:"EventsFields_criteria_audiences_Delete"
	},
	EventsFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	EventsFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	EventsFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	EventsFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	EventsFields_criteria_tags:{
		create:"EventsFields_criteria_tags_Create",
		read:"EventsFields_criteria_tags_Read",
		update:"EventsFields_criteria_tags_Update",
		delete:"EventsFields_criteria_tags_Delete"
	},
	EventsFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	EventsFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	EventsFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	EventsFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	EventsFields_criteria_specialTags:{
		create:"EventsFields_criteria_specialTags_Create",
		read:"EventsFields_criteria_specialTags_Read",
		update:"EventsFields_criteria_specialTags_Update",
		delete:"EventsFields_criteria_specialTags_Delete"
	},
	EventsFields_criteria_specialTags_Create:{
		permission:"Boolean"
	},
	EventsFields_criteria_specialTags_Read:{
		permission:"Boolean"
	},
	EventsFields_criteria_specialTags_Update:{
		permission:"Boolean"
	},
	EventsFields_criteria_specialTags_Delete:{
		permission:"Boolean"
	},
	EventsFields_walkIn:{
		create:"EventsFields_walkIn_Create",
		read:"EventsFields_walkIn_Read",
		update:"EventsFields_walkIn_Update",
		delete:"EventsFields_walkIn_Delete"
	},
	EventsFields_walkIn_Create:{
		permission:"Boolean"
	},
	EventsFields_walkIn_Read:{
		permission:"Boolean"
	},
	EventsFields_walkIn_Update:{
		permission:"Boolean"
	},
	EventsFields_walkIn_Delete:{
		permission:"Boolean"
	},
	EventsFields_contact:{
		create:"EventsFields_contact_Create",
		read:"EventsFields_contact_Read",
		update:"EventsFields_contact_Update",
		delete:"EventsFields_contact_Delete",
		fields:"EventsFields_contact_Fields"
	},
	EventsFields_contact_Create:{
		permission:"Boolean"
	},
	EventsFields_contact_Read:{
		permission:"Boolean"
	},
	EventsFields_contact_Update:{
		permission:"Boolean"
	},
	EventsFields_contact_Delete:{
		permission:"Boolean"
	},
	EventsFields_contact_Fields:{
		code:"EventsFields_contact_code",
		phone:"EventsFields_contact_phone",
		page:"EventsFields_contact_page",
		email:"EventsFields_contact_email"
	},
	EventsFields_contact_code:{
		create:"EventsFields_contact_code_Create",
		read:"EventsFields_contact_code_Read",
		update:"EventsFields_contact_code_Update",
		delete:"EventsFields_contact_code_Delete"
	},
	EventsFields_contact_code_Create:{
		permission:"Boolean"
	},
	EventsFields_contact_code_Read:{
		permission:"Boolean"
	},
	EventsFields_contact_code_Update:{
		permission:"Boolean"
	},
	EventsFields_contact_code_Delete:{
		permission:"Boolean"
	},
	EventsFields_contact_phone:{
		create:"EventsFields_contact_phone_Create",
		read:"EventsFields_contact_phone_Read",
		update:"EventsFields_contact_phone_Update",
		delete:"EventsFields_contact_phone_Delete"
	},
	EventsFields_contact_phone_Create:{
		permission:"Boolean"
	},
	EventsFields_contact_phone_Read:{
		permission:"Boolean"
	},
	EventsFields_contact_phone_Update:{
		permission:"Boolean"
	},
	EventsFields_contact_phone_Delete:{
		permission:"Boolean"
	},
	EventsFields_contact_page:{
		create:"EventsFields_contact_page_Create",
		read:"EventsFields_contact_page_Read",
		update:"EventsFields_contact_page_Update",
		delete:"EventsFields_contact_page_Delete"
	},
	EventsFields_contact_page_Create:{
		permission:"Boolean"
	},
	EventsFields_contact_page_Read:{
		permission:"Boolean"
	},
	EventsFields_contact_page_Update:{
		permission:"Boolean"
	},
	EventsFields_contact_page_Delete:{
		permission:"Boolean"
	},
	EventsFields_contact_email:{
		create:"EventsFields_contact_email_Create",
		read:"EventsFields_contact_email_Read",
		update:"EventsFields_contact_email_Update",
		delete:"EventsFields_contact_email_Delete"
	},
	EventsFields_contact_email_Create:{
		permission:"Boolean"
	},
	EventsFields_contact_email_Read:{
		permission:"Boolean"
	},
	EventsFields_contact_email_Update:{
		permission:"Boolean"
	},
	EventsFields_contact_email_Delete:{
		permission:"Boolean"
	},
	EventsFields_views:{
		create:"EventsFields_views_Create",
		read:"EventsFields_views_Read",
		update:"EventsFields_views_Update",
		delete:"EventsFields_views_Delete"
	},
	EventsFields_views_Create:{
		permission:"Boolean"
	},
	EventsFields_views_Read:{
		permission:"Boolean"
	},
	EventsFields_views_Update:{
		permission:"Boolean"
	},
	EventsFields_views_Delete:{
		permission:"Boolean"
	},
	EventsFields_free:{
		create:"EventsFields_free_Create",
		read:"EventsFields_free_Read",
		update:"EventsFields_free_Update",
		delete:"EventsFields_free_Delete"
	},
	EventsFields_free_Create:{
		permission:"Boolean"
	},
	EventsFields_free_Read:{
		permission:"Boolean"
	},
	EventsFields_free_Update:{
		permission:"Boolean"
	},
	EventsFields_free_Delete:{
		permission:"Boolean"
	},
	EventsFields_fees:{
		create:"EventsFields_fees_Create",
		read:"EventsFields_fees_Read",
		update:"EventsFields_fees_Update",
		delete:"EventsFields_fees_Delete",
		fields:"EventsFields_fees_Fields"
	},
	EventsFields_fees_Create:{
		permission:"Boolean"
	},
	EventsFields_fees_Read:{
		permission:"Boolean"
	},
	EventsFields_fees_Update:{
		permission:"Boolean"
	},
	EventsFields_fees_Delete:{
		permission:"Boolean"
	},
	EventsFields_fees_Fields:{
		fee:"EventsFields_fees_fee",
		remark:"EventsFields_fees_remark",
		id:"EventsFields_fees_id"
	},
	EventsFields_fees_fee:{
		create:"EventsFields_fees_fee_Create",
		read:"EventsFields_fees_fee_Read",
		update:"EventsFields_fees_fee_Update",
		delete:"EventsFields_fees_fee_Delete"
	},
	EventsFields_fees_fee_Create:{
		permission:"Boolean"
	},
	EventsFields_fees_fee_Read:{
		permission:"Boolean"
	},
	EventsFields_fees_fee_Update:{
		permission:"Boolean"
	},
	EventsFields_fees_fee_Delete:{
		permission:"Boolean"
	},
	EventsFields_fees_remark:{
		create:"EventsFields_fees_remark_Create",
		read:"EventsFields_fees_remark_Read",
		update:"EventsFields_fees_remark_Update",
		delete:"EventsFields_fees_remark_Delete"
	},
	EventsFields_fees_remark_Create:{
		permission:"Boolean"
	},
	EventsFields_fees_remark_Read:{
		permission:"Boolean"
	},
	EventsFields_fees_remark_Update:{
		permission:"Boolean"
	},
	EventsFields_fees_remark_Delete:{
		permission:"Boolean"
	},
	EventsFields_fees_id:{
		create:"EventsFields_fees_id_Create",
		read:"EventsFields_fees_id_Read",
		update:"EventsFields_fees_id_Update",
		delete:"EventsFields_fees_id_Delete"
	},
	EventsFields_fees_id_Create:{
		permission:"Boolean"
	},
	EventsFields_fees_id_Read:{
		permission:"Boolean"
	},
	EventsFields_fees_id_Update:{
		permission:"Boolean"
	},
	EventsFields_fees_id_Delete:{
		permission:"Boolean"
	},
	EventsFields_homePageInternalClick:{
		create:"EventsFields_homePageInternalClick_Create",
		read:"EventsFields_homePageInternalClick_Read",
		update:"EventsFields_homePageInternalClick_Update",
		delete:"EventsFields_homePageInternalClick_Delete"
	},
	EventsFields_homePageInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_homePageInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_homePageInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_homePageInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_homePageImpression:{
		create:"EventsFields_homePageImpression_Create",
		read:"EventsFields_homePageImpression_Read",
		update:"EventsFields_homePageImpression_Update",
		delete:"EventsFields_homePageImpression_Delete"
	},
	EventsFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_eventSearchInternalClick:{
		create:"EventsFields_eventSearchInternalClick_Create",
		read:"EventsFields_eventSearchInternalClick_Read",
		update:"EventsFields_eventSearchInternalClick_Update",
		delete:"EventsFields_eventSearchInternalClick_Delete"
	},
	EventsFields_eventSearchInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_eventSearchInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_eventSearchInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_eventSearchInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_eventListImpression:{
		create:"EventsFields_eventListImpression_Create",
		read:"EventsFields_eventListImpression_Read",
		update:"EventsFields_eventListImpression_Update",
		delete:"EventsFields_eventListImpression_Delete"
	},
	EventsFields_eventListImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_eventListImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_eventListImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_eventListImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_latestEventInternalClick:{
		create:"EventsFields_latestEventInternalClick_Create",
		read:"EventsFields_latestEventInternalClick_Read",
		update:"EventsFields_latestEventInternalClick_Update",
		delete:"EventsFields_latestEventInternalClick_Delete"
	},
	EventsFields_latestEventInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_latestEventInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_latestEventInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_latestEventInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_latestEventImpression:{
		create:"EventsFields_latestEventImpression_Create",
		read:"EventsFields_latestEventImpression_Read",
		update:"EventsFields_latestEventImpression_Update",
		delete:"EventsFields_latestEventImpression_Delete"
	},
	EventsFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_topTenEventRankingInternalClick:{
		create:"EventsFields_topTenEventRankingInternalClick_Create",
		read:"EventsFields_topTenEventRankingInternalClick_Read",
		update:"EventsFields_topTenEventRankingInternalClick_Update",
		delete:"EventsFields_topTenEventRankingInternalClick_Delete"
	},
	EventsFields_topTenEventRankingInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_topTenEventRankingInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_topTenEventRankingInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_topTenEventRankingInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_topTenImpression:{
		create:"EventsFields_topTenImpression_Create",
		read:"EventsFields_topTenImpression_Read",
		update:"EventsFields_topTenImpression_Update",
		delete:"EventsFields_topTenImpression_Delete"
	},
	EventsFields_topTenImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_topTenImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_topTenImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_topTenImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceInternalClick:{
		create:"EventsFields_editorChoiceInternalClick_Create",
		read:"EventsFields_editorChoiceInternalClick_Read",
		update:"EventsFields_editorChoiceInternalClick_Update",
		delete:"EventsFields_editorChoiceInternalClick_Delete"
	},
	EventsFields_editorChoiceInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceImpression:{
		create:"EventsFields_editorChoiceImpression_Create",
		read:"EventsFields_editorChoiceImpression_Read",
		update:"EventsFields_editorChoiceImpression_Update",
		delete:"EventsFields_editorChoiceImpression_Delete"
	},
	EventsFields_editorChoiceImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_editorChoiceImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingInternalClick:{
		create:"EventsFields_otherEventRankingInternalClick_Create",
		read:"EventsFields_otherEventRankingInternalClick_Read",
		update:"EventsFields_otherEventRankingInternalClick_Update",
		delete:"EventsFields_otherEventRankingInternalClick_Delete"
	},
	EventsFields_otherEventRankingInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingImpression:{
		create:"EventsFields_otherEventRankingImpression_Create",
		read:"EventsFields_otherEventRankingImpression_Read",
		update:"EventsFields_otherEventRankingImpression_Update",
		delete:"EventsFields_otherEventRankingImpression_Delete"
	},
	EventsFields_otherEventRankingImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_otherEventRankingImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_miscInternalClick:{
		create:"EventsFields_miscInternalClick_Create",
		read:"EventsFields_miscInternalClick_Read",
		update:"EventsFields_miscInternalClick_Update",
		delete:"EventsFields_miscInternalClick_Delete"
	},
	EventsFields_miscInternalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_miscInternalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_miscInternalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_miscInternalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_miscImpression:{
		create:"EventsFields_miscImpression_Create",
		read:"EventsFields_miscImpression_Read",
		update:"EventsFields_miscImpression_Update",
		delete:"EventsFields_miscImpression_Delete"
	},
	EventsFields_miscImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_miscImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_miscImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_miscImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_internalClick:{
		create:"EventsFields_internalClick_Create",
		read:"EventsFields_internalClick_Read",
		update:"EventsFields_internalClick_Update",
		delete:"EventsFields_internalClick_Delete"
	},
	EventsFields_internalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_internalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_internalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_internalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_totalImpression:{
		create:"EventsFields_totalImpression_Create",
		read:"EventsFields_totalImpression_Read",
		update:"EventsFields_totalImpression_Update",
		delete:"EventsFields_totalImpression_Delete"
	},
	EventsFields_totalImpression_Create:{
		permission:"Boolean"
	},
	EventsFields_totalImpression_Read:{
		permission:"Boolean"
	},
	EventsFields_totalImpression_Update:{
		permission:"Boolean"
	},
	EventsFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	EventsFields_externalClick:{
		create:"EventsFields_externalClick_Create",
		read:"EventsFields_externalClick_Read",
		update:"EventsFields_externalClick_Update",
		delete:"EventsFields_externalClick_Delete"
	},
	EventsFields_externalClick_Create:{
		permission:"Boolean"
	},
	EventsFields_externalClick_Read:{
		permission:"Boolean"
	},
	EventsFields_externalClick_Update:{
		permission:"Boolean"
	},
	EventsFields_externalClick_Delete:{
		permission:"Boolean"
	},
	EventsFields_outboundClick:{
		create:"EventsFields_outboundClick_Create",
		read:"EventsFields_outboundClick_Read",
		update:"EventsFields_outboundClick_Update",
		delete:"EventsFields_outboundClick_Delete"
	},
	EventsFields_outboundClick_Create:{
		permission:"Boolean"
	},
	EventsFields_outboundClick_Read:{
		permission:"Boolean"
	},
	EventsFields_outboundClick_Update:{
		permission:"Boolean"
	},
	EventsFields_outboundClick_Delete:{
		permission:"Boolean"
	},
	EventsFields__viewsCountByMonth:{
		create:"EventsFields__viewsCountByMonth_Create",
		read:"EventsFields__viewsCountByMonth_Read",
		update:"EventsFields__viewsCountByMonth_Update",
		delete:"EventsFields__viewsCountByMonth_Delete"
	},
	EventsFields__viewsCountByMonth_Create:{
		permission:"Boolean"
	},
	EventsFields__viewsCountByMonth_Read:{
		permission:"Boolean"
	},
	EventsFields__viewsCountByMonth_Update:{
		permission:"Boolean"
	},
	EventsFields__viewsCountByMonth_Delete:{
		permission:"Boolean"
	},
	EventsFields_legacyGallery:{
		create:"EventsFields_legacyGallery_Create",
		read:"EventsFields_legacyGallery_Read",
		update:"EventsFields_legacyGallery_Update",
		delete:"EventsFields_legacyGallery_Delete"
	},
	EventsFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	EventsFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	EventsFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	EventsFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	EventsFields_earliestStartDate:{
		create:"EventsFields_earliestStartDate_Create",
		read:"EventsFields_earliestStartDate_Read",
		update:"EventsFields_earliestStartDate_Update",
		delete:"EventsFields_earliestStartDate_Delete"
	},
	EventsFields_earliestStartDate_Create:{
		permission:"Boolean"
	},
	EventsFields_earliestStartDate_Read:{
		permission:"Boolean"
	},
	EventsFields_earliestStartDate_Update:{
		permission:"Boolean"
	},
	EventsFields_earliestStartDate_Delete:{
		permission:"Boolean"
	},
	EventsFields_latestEndDate:{
		create:"EventsFields_latestEndDate_Create",
		read:"EventsFields_latestEndDate_Read",
		update:"EventsFields_latestEndDate_Update",
		delete:"EventsFields_latestEndDate_Delete"
	},
	EventsFields_latestEndDate_Create:{
		permission:"Boolean"
	},
	EventsFields_latestEndDate_Read:{
		permission:"Boolean"
	},
	EventsFields_latestEndDate_Update:{
		permission:"Boolean"
	},
	EventsFields_latestEndDate_Delete:{
		permission:"Boolean"
	},
	EventsFields_updatedAt:{
		create:"EventsFields_updatedAt_Create",
		read:"EventsFields_updatedAt_Read",
		update:"EventsFields_updatedAt_Update",
		delete:"EventsFields_updatedAt_Delete"
	},
	EventsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventsFields_createdAt:{
		create:"EventsFields_createdAt_Create",
		read:"EventsFields_createdAt_Read",
		update:"EventsFields_createdAt_Update",
		delete:"EventsFields_createdAt_Delete"
	},
	EventsFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventsFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventsFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	locationsAccess:{
		fields:"LocationsFields",
		create:"LocationsCreateAccess",
		read:"LocationsReadAccess",
		update:"LocationsUpdateAccess",
		delete:"LocationsDeleteAccess"
	},
	LocationsFields:{
		region:"LocationsFields_region",
		creator:"LocationsFields_creator",
		permalink:"LocationsFields_permalink",
		status:"LocationsFields_status",
		level:"LocationsFields_level",
		parent:"LocationsFields_parent",
		name:"LocationsFields_name",
		thumbnail:"LocationsFields_thumbnail",
		banner:"LocationsFields_banner",
		type:"LocationsFields_type",
		category:"LocationsFields_category",
		district:"LocationsFields_district",
		coordinate:"LocationsFields_coordinate",
		linkAddress:"LocationsFields_linkAddress",
		address:"LocationsFields_address",
		contact:"LocationsFields_contact",
		content:"LocationsFields_content",
		legacyContent:"LocationsFields_legacyContent",
		showLegacyContent:"LocationsFields_showLegacyContent",
		criteria:"LocationsFields_criteria",
		sections:"LocationsFields_sections",
		_title:"LocationsFields__title",
		legacyGallery:"LocationsFields_legacyGallery",
		updatedAt:"LocationsFields_updatedAt",
		createdAt:"LocationsFields_createdAt"
	},
	LocationsFields_region:{
		create:"LocationsFields_region_Create",
		read:"LocationsFields_region_Read",
		update:"LocationsFields_region_Update",
		delete:"LocationsFields_region_Delete"
	},
	LocationsFields_region_Create:{
		permission:"Boolean"
	},
	LocationsFields_region_Read:{
		permission:"Boolean"
	},
	LocationsFields_region_Update:{
		permission:"Boolean"
	},
	LocationsFields_region_Delete:{
		permission:"Boolean"
	},
	LocationsFields_creator:{
		create:"LocationsFields_creator_Create",
		read:"LocationsFields_creator_Read",
		update:"LocationsFields_creator_Update",
		delete:"LocationsFields_creator_Delete"
	},
	LocationsFields_creator_Create:{
		permission:"Boolean"
	},
	LocationsFields_creator_Read:{
		permission:"Boolean"
	},
	LocationsFields_creator_Update:{
		permission:"Boolean"
	},
	LocationsFields_creator_Delete:{
		permission:"Boolean"
	},
	LocationsFields_permalink:{
		create:"LocationsFields_permalink_Create",
		read:"LocationsFields_permalink_Read",
		update:"LocationsFields_permalink_Update",
		delete:"LocationsFields_permalink_Delete"
	},
	LocationsFields_permalink_Create:{
		permission:"Boolean"
	},
	LocationsFields_permalink_Read:{
		permission:"Boolean"
	},
	LocationsFields_permalink_Update:{
		permission:"Boolean"
	},
	LocationsFields_permalink_Delete:{
		permission:"Boolean"
	},
	LocationsFields_status:{
		create:"LocationsFields_status_Create",
		read:"LocationsFields_status_Read",
		update:"LocationsFields_status_Update",
		delete:"LocationsFields_status_Delete"
	},
	LocationsFields_status_Create:{
		permission:"Boolean"
	},
	LocationsFields_status_Read:{
		permission:"Boolean"
	},
	LocationsFields_status_Update:{
		permission:"Boolean"
	},
	LocationsFields_status_Delete:{
		permission:"Boolean"
	},
	LocationsFields_level:{
		create:"LocationsFields_level_Create",
		read:"LocationsFields_level_Read",
		update:"LocationsFields_level_Update",
		delete:"LocationsFields_level_Delete"
	},
	LocationsFields_level_Create:{
		permission:"Boolean"
	},
	LocationsFields_level_Read:{
		permission:"Boolean"
	},
	LocationsFields_level_Update:{
		permission:"Boolean"
	},
	LocationsFields_level_Delete:{
		permission:"Boolean"
	},
	LocationsFields_parent:{
		create:"LocationsFields_parent_Create",
		read:"LocationsFields_parent_Read",
		update:"LocationsFields_parent_Update",
		delete:"LocationsFields_parent_Delete"
	},
	LocationsFields_parent_Create:{
		permission:"Boolean"
	},
	LocationsFields_parent_Read:{
		permission:"Boolean"
	},
	LocationsFields_parent_Update:{
		permission:"Boolean"
	},
	LocationsFields_parent_Delete:{
		permission:"Boolean"
	},
	LocationsFields_name:{
		create:"LocationsFields_name_Create",
		read:"LocationsFields_name_Read",
		update:"LocationsFields_name_Update",
		delete:"LocationsFields_name_Delete"
	},
	LocationsFields_name_Create:{
		permission:"Boolean"
	},
	LocationsFields_name_Read:{
		permission:"Boolean"
	},
	LocationsFields_name_Update:{
		permission:"Boolean"
	},
	LocationsFields_name_Delete:{
		permission:"Boolean"
	},
	LocationsFields_thumbnail:{
		create:"LocationsFields_thumbnail_Create",
		read:"LocationsFields_thumbnail_Read",
		update:"LocationsFields_thumbnail_Update",
		delete:"LocationsFields_thumbnail_Delete"
	},
	LocationsFields_thumbnail_Create:{
		permission:"Boolean"
	},
	LocationsFields_thumbnail_Read:{
		permission:"Boolean"
	},
	LocationsFields_thumbnail_Update:{
		permission:"Boolean"
	},
	LocationsFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	LocationsFields_banner:{
		create:"LocationsFields_banner_Create",
		read:"LocationsFields_banner_Read",
		update:"LocationsFields_banner_Update",
		delete:"LocationsFields_banner_Delete"
	},
	LocationsFields_banner_Create:{
		permission:"Boolean"
	},
	LocationsFields_banner_Read:{
		permission:"Boolean"
	},
	LocationsFields_banner_Update:{
		permission:"Boolean"
	},
	LocationsFields_banner_Delete:{
		permission:"Boolean"
	},
	LocationsFields_type:{
		create:"LocationsFields_type_Create",
		read:"LocationsFields_type_Read",
		update:"LocationsFields_type_Update",
		delete:"LocationsFields_type_Delete"
	},
	LocationsFields_type_Create:{
		permission:"Boolean"
	},
	LocationsFields_type_Read:{
		permission:"Boolean"
	},
	LocationsFields_type_Update:{
		permission:"Boolean"
	},
	LocationsFields_type_Delete:{
		permission:"Boolean"
	},
	LocationsFields_category:{
		create:"LocationsFields_category_Create",
		read:"LocationsFields_category_Read",
		update:"LocationsFields_category_Update",
		delete:"LocationsFields_category_Delete"
	},
	LocationsFields_category_Create:{
		permission:"Boolean"
	},
	LocationsFields_category_Read:{
		permission:"Boolean"
	},
	LocationsFields_category_Update:{
		permission:"Boolean"
	},
	LocationsFields_category_Delete:{
		permission:"Boolean"
	},
	LocationsFields_district:{
		create:"LocationsFields_district_Create",
		read:"LocationsFields_district_Read",
		update:"LocationsFields_district_Update",
		delete:"LocationsFields_district_Delete"
	},
	LocationsFields_district_Create:{
		permission:"Boolean"
	},
	LocationsFields_district_Read:{
		permission:"Boolean"
	},
	LocationsFields_district_Update:{
		permission:"Boolean"
	},
	LocationsFields_district_Delete:{
		permission:"Boolean"
	},
	LocationsFields_coordinate:{
		create:"LocationsFields_coordinate_Create",
		read:"LocationsFields_coordinate_Read",
		update:"LocationsFields_coordinate_Update",
		delete:"LocationsFields_coordinate_Delete"
	},
	LocationsFields_coordinate_Create:{
		permission:"Boolean"
	},
	LocationsFields_coordinate_Read:{
		permission:"Boolean"
	},
	LocationsFields_coordinate_Update:{
		permission:"Boolean"
	},
	LocationsFields_coordinate_Delete:{
		permission:"Boolean"
	},
	LocationsFields_linkAddress:{
		create:"LocationsFields_linkAddress_Create",
		read:"LocationsFields_linkAddress_Read",
		update:"LocationsFields_linkAddress_Update",
		delete:"LocationsFields_linkAddress_Delete"
	},
	LocationsFields_linkAddress_Create:{
		permission:"Boolean"
	},
	LocationsFields_linkAddress_Read:{
		permission:"Boolean"
	},
	LocationsFields_linkAddress_Update:{
		permission:"Boolean"
	},
	LocationsFields_linkAddress_Delete:{
		permission:"Boolean"
	},
	LocationsFields_address:{
		create:"LocationsFields_address_Create",
		read:"LocationsFields_address_Read",
		update:"LocationsFields_address_Update",
		delete:"LocationsFields_address_Delete"
	},
	LocationsFields_address_Create:{
		permission:"Boolean"
	},
	LocationsFields_address_Read:{
		permission:"Boolean"
	},
	LocationsFields_address_Update:{
		permission:"Boolean"
	},
	LocationsFields_address_Delete:{
		permission:"Boolean"
	},
	LocationsFields_contact:{
		create:"LocationsFields_contact_Create",
		read:"LocationsFields_contact_Read",
		update:"LocationsFields_contact_Update",
		delete:"LocationsFields_contact_Delete",
		fields:"LocationsFields_contact_Fields"
	},
	LocationsFields_contact_Create:{
		permission:"Boolean"
	},
	LocationsFields_contact_Read:{
		permission:"Boolean"
	},
	LocationsFields_contact_Update:{
		permission:"Boolean"
	},
	LocationsFields_contact_Delete:{
		permission:"Boolean"
	},
	LocationsFields_contact_Fields:{
		code:"LocationsFields_contact_code",
		phone:"LocationsFields_contact_phone",
		page:"LocationsFields_contact_page",
		email:"LocationsFields_contact_email"
	},
	LocationsFields_contact_code:{
		create:"LocationsFields_contact_code_Create",
		read:"LocationsFields_contact_code_Read",
		update:"LocationsFields_contact_code_Update",
		delete:"LocationsFields_contact_code_Delete"
	},
	LocationsFields_contact_code_Create:{
		permission:"Boolean"
	},
	LocationsFields_contact_code_Read:{
		permission:"Boolean"
	},
	LocationsFields_contact_code_Update:{
		permission:"Boolean"
	},
	LocationsFields_contact_code_Delete:{
		permission:"Boolean"
	},
	LocationsFields_contact_phone:{
		create:"LocationsFields_contact_phone_Create",
		read:"LocationsFields_contact_phone_Read",
		update:"LocationsFields_contact_phone_Update",
		delete:"LocationsFields_contact_phone_Delete"
	},
	LocationsFields_contact_phone_Create:{
		permission:"Boolean"
	},
	LocationsFields_contact_phone_Read:{
		permission:"Boolean"
	},
	LocationsFields_contact_phone_Update:{
		permission:"Boolean"
	},
	LocationsFields_contact_phone_Delete:{
		permission:"Boolean"
	},
	LocationsFields_contact_page:{
		create:"LocationsFields_contact_page_Create",
		read:"LocationsFields_contact_page_Read",
		update:"LocationsFields_contact_page_Update",
		delete:"LocationsFields_contact_page_Delete"
	},
	LocationsFields_contact_page_Create:{
		permission:"Boolean"
	},
	LocationsFields_contact_page_Read:{
		permission:"Boolean"
	},
	LocationsFields_contact_page_Update:{
		permission:"Boolean"
	},
	LocationsFields_contact_page_Delete:{
		permission:"Boolean"
	},
	LocationsFields_contact_email:{
		create:"LocationsFields_contact_email_Create",
		read:"LocationsFields_contact_email_Read",
		update:"LocationsFields_contact_email_Update",
		delete:"LocationsFields_contact_email_Delete"
	},
	LocationsFields_contact_email_Create:{
		permission:"Boolean"
	},
	LocationsFields_contact_email_Read:{
		permission:"Boolean"
	},
	LocationsFields_contact_email_Update:{
		permission:"Boolean"
	},
	LocationsFields_contact_email_Delete:{
		permission:"Boolean"
	},
	LocationsFields_content:{
		create:"LocationsFields_content_Create",
		read:"LocationsFields_content_Read",
		update:"LocationsFields_content_Update",
		delete:"LocationsFields_content_Delete"
	},
	LocationsFields_content_Create:{
		permission:"Boolean"
	},
	LocationsFields_content_Read:{
		permission:"Boolean"
	},
	LocationsFields_content_Update:{
		permission:"Boolean"
	},
	LocationsFields_content_Delete:{
		permission:"Boolean"
	},
	LocationsFields_legacyContent:{
		create:"LocationsFields_legacyContent_Create",
		read:"LocationsFields_legacyContent_Read",
		update:"LocationsFields_legacyContent_Update",
		delete:"LocationsFields_legacyContent_Delete"
	},
	LocationsFields_legacyContent_Create:{
		permission:"Boolean"
	},
	LocationsFields_legacyContent_Read:{
		permission:"Boolean"
	},
	LocationsFields_legacyContent_Update:{
		permission:"Boolean"
	},
	LocationsFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	LocationsFields_showLegacyContent:{
		create:"LocationsFields_showLegacyContent_Create",
		read:"LocationsFields_showLegacyContent_Read",
		update:"LocationsFields_showLegacyContent_Update",
		delete:"LocationsFields_showLegacyContent_Delete"
	},
	LocationsFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	LocationsFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	LocationsFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	LocationsFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	LocationsFields_criteria:{
		create:"LocationsFields_criteria_Create",
		read:"LocationsFields_criteria_Read",
		update:"LocationsFields_criteria_Update",
		delete:"LocationsFields_criteria_Delete",
		fields:"LocationsFields_criteria_Fields"
	},
	LocationsFields_criteria_Create:{
		permission:"Boolean"
	},
	LocationsFields_criteria_Read:{
		permission:"Boolean"
	},
	LocationsFields_criteria_Update:{
		permission:"Boolean"
	},
	LocationsFields_criteria_Delete:{
		permission:"Boolean"
	},
	LocationsFields_criteria_Fields:{
		audiences:"LocationsFields_criteria_audiences",
		dates:"LocationsFields_criteria_dates",
		categories:"LocationsFields_criteria_categories",
		tags:"LocationsFields_criteria_tags"
	},
	LocationsFields_criteria_audiences:{
		create:"LocationsFields_criteria_audiences_Create",
		read:"LocationsFields_criteria_audiences_Read",
		update:"LocationsFields_criteria_audiences_Update",
		delete:"LocationsFields_criteria_audiences_Delete"
	},
	LocationsFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	LocationsFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	LocationsFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	LocationsFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	LocationsFields_criteria_dates:{
		create:"LocationsFields_criteria_dates_Create",
		read:"LocationsFields_criteria_dates_Read",
		update:"LocationsFields_criteria_dates_Update",
		delete:"LocationsFields_criteria_dates_Delete"
	},
	LocationsFields_criteria_dates_Create:{
		permission:"Boolean"
	},
	LocationsFields_criteria_dates_Read:{
		permission:"Boolean"
	},
	LocationsFields_criteria_dates_Update:{
		permission:"Boolean"
	},
	LocationsFields_criteria_dates_Delete:{
		permission:"Boolean"
	},
	LocationsFields_criteria_categories:{
		create:"LocationsFields_criteria_categories_Create",
		read:"LocationsFields_criteria_categories_Read",
		update:"LocationsFields_criteria_categories_Update",
		delete:"LocationsFields_criteria_categories_Delete"
	},
	LocationsFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	LocationsFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	LocationsFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	LocationsFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	LocationsFields_criteria_tags:{
		create:"LocationsFields_criteria_tags_Create",
		read:"LocationsFields_criteria_tags_Read",
		update:"LocationsFields_criteria_tags_Update",
		delete:"LocationsFields_criteria_tags_Delete"
	},
	LocationsFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	LocationsFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	LocationsFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	LocationsFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections:{
		create:"LocationsFields_sections_Create",
		read:"LocationsFields_sections_Read",
		update:"LocationsFields_sections_Update",
		delete:"LocationsFields_sections_Delete",
		fields:"LocationsFields_sections_Fields"
	},
	LocationsFields_sections_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_Fields:{
		startDatetime:"LocationsFields_sections_startDatetime",
		endDatetime:"LocationsFields_sections_endDatetime",
		fullDay:"LocationsFields_sections_fullDay",
		repeat:"LocationsFields_sections_repeat",
		recurrance:"LocationsFields_sections_recurrance",
		id:"LocationsFields_sections_id"
	},
	LocationsFields_sections_startDatetime:{
		create:"LocationsFields_sections_startDatetime_Create",
		read:"LocationsFields_sections_startDatetime_Read",
		update:"LocationsFields_sections_startDatetime_Update",
		delete:"LocationsFields_sections_startDatetime_Delete"
	},
	LocationsFields_sections_startDatetime_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_startDatetime_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_startDatetime_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_startDatetime_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_endDatetime:{
		create:"LocationsFields_sections_endDatetime_Create",
		read:"LocationsFields_sections_endDatetime_Read",
		update:"LocationsFields_sections_endDatetime_Update",
		delete:"LocationsFields_sections_endDatetime_Delete"
	},
	LocationsFields_sections_endDatetime_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_endDatetime_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_endDatetime_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_endDatetime_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_fullDay:{
		create:"LocationsFields_sections_fullDay_Create",
		read:"LocationsFields_sections_fullDay_Read",
		update:"LocationsFields_sections_fullDay_Update",
		delete:"LocationsFields_sections_fullDay_Delete"
	},
	LocationsFields_sections_fullDay_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_fullDay_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_fullDay_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_fullDay_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_repeat:{
		create:"LocationsFields_sections_repeat_Create",
		read:"LocationsFields_sections_repeat_Read",
		update:"LocationsFields_sections_repeat_Update",
		delete:"LocationsFields_sections_repeat_Delete"
	},
	LocationsFields_sections_repeat_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_repeat_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_repeat_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_repeat_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance:{
		create:"LocationsFields_sections_recurrance_Create",
		read:"LocationsFields_sections_recurrance_Read",
		update:"LocationsFields_sections_recurrance_Update",
		delete:"LocationsFields_sections_recurrance_Delete",
		fields:"LocationsFields_sections_recurrance_Fields"
	},
	LocationsFields_sections_recurrance_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_Fields:{
		type:"LocationsFields_sections_recurrance_type",
		weekday:"LocationsFields_sections_recurrance_weekday"
	},
	LocationsFields_sections_recurrance_type:{
		create:"LocationsFields_sections_recurrance_type_Create",
		read:"LocationsFields_sections_recurrance_type_Read",
		update:"LocationsFields_sections_recurrance_type_Update",
		delete:"LocationsFields_sections_recurrance_type_Delete"
	},
	LocationsFields_sections_recurrance_type_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_type_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_type_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_type_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_weekday:{
		create:"LocationsFields_sections_recurrance_weekday_Create",
		read:"LocationsFields_sections_recurrance_weekday_Read",
		update:"LocationsFields_sections_recurrance_weekday_Update",
		delete:"LocationsFields_sections_recurrance_weekday_Delete"
	},
	LocationsFields_sections_recurrance_weekday_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_weekday_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_weekday_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_recurrance_weekday_Delete:{
		permission:"Boolean"
	},
	LocationsFields_sections_id:{
		create:"LocationsFields_sections_id_Create",
		read:"LocationsFields_sections_id_Read",
		update:"LocationsFields_sections_id_Update",
		delete:"LocationsFields_sections_id_Delete"
	},
	LocationsFields_sections_id_Create:{
		permission:"Boolean"
	},
	LocationsFields_sections_id_Read:{
		permission:"Boolean"
	},
	LocationsFields_sections_id_Update:{
		permission:"Boolean"
	},
	LocationsFields_sections_id_Delete:{
		permission:"Boolean"
	},
	LocationsFields__title:{
		create:"LocationsFields__title_Create",
		read:"LocationsFields__title_Read",
		update:"LocationsFields__title_Update",
		delete:"LocationsFields__title_Delete"
	},
	LocationsFields__title_Create:{
		permission:"Boolean"
	},
	LocationsFields__title_Read:{
		permission:"Boolean"
	},
	LocationsFields__title_Update:{
		permission:"Boolean"
	},
	LocationsFields__title_Delete:{
		permission:"Boolean"
	},
	LocationsFields_legacyGallery:{
		create:"LocationsFields_legacyGallery_Create",
		read:"LocationsFields_legacyGallery_Read",
		update:"LocationsFields_legacyGallery_Update",
		delete:"LocationsFields_legacyGallery_Delete"
	},
	LocationsFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	LocationsFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	LocationsFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	LocationsFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	LocationsFields_updatedAt:{
		create:"LocationsFields_updatedAt_Create",
		read:"LocationsFields_updatedAt_Read",
		update:"LocationsFields_updatedAt_Update",
		delete:"LocationsFields_updatedAt_Delete"
	},
	LocationsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	LocationsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	LocationsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	LocationsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	LocationsFields_createdAt:{
		create:"LocationsFields_createdAt_Create",
		read:"LocationsFields_createdAt_Read",
		update:"LocationsFields_createdAt_Update",
		delete:"LocationsFields_createdAt_Delete"
	},
	LocationsFields_createdAt_Create:{
		permission:"Boolean"
	},
	LocationsFields_createdAt_Read:{
		permission:"Boolean"
	},
	LocationsFields_createdAt_Update:{
		permission:"Boolean"
	},
	LocationsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	LocationsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	LocationsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	postsAccess:{
		fields:"PostsFields",
		create:"PostsCreateAccess",
		read:"PostsReadAccess",
		update:"PostsUpdateAccess",
		delete:"PostsDeleteAccess"
	},
	PostsFields:{
		title:"PostsFields_title",
		region:"PostsFields_region",
		authur:"PostsFields_authur",
		publishDateTime:"PostsFields_publishDateTime",
		permalink:"PostsFields_permalink",
		status:"PostsFields_status",
		content:"PostsFields_content",
		legacyContent:"PostsFields_legacyContent",
		showLegacyContent:"PostsFields_showLegacyContent",
		blogCategories:"PostsFields_blogCategories",
		thumbnail:"PostsFields_thumbnail",
		banner:"PostsFields_banner",
		criteria:"PostsFields_criteria",
		location:"PostsFields_location",
		homePageInternalClick:"PostsFields_homePageInternalClick",
		homePageImpression:"PostsFields_homePageImpression",
		blogListInternalClick:"PostsFields_blogListInternalClick",
		blogListImpression:"PostsFields_blogListImpression",
		blogDetailInternalClick:"PostsFields_blogDetailInternalClick",
		blogDetailImpression:"PostsFields_blogDetailImpression",
		relatedEventInternalClick:"PostsFields_relatedEventInternalClick",
		relatedEventImpression:"PostsFields_relatedEventImpression",
		latestEventInternalClick:"PostsFields_latestEventInternalClick",
		latestEventImpression:"PostsFields_latestEventImpression",
		internalClick:"PostsFields_internalClick",
		totalImpression:"PostsFields_totalImpression",
		externalClick:"PostsFields_externalClick",
		outboundClick:"PostsFields_outboundClick",
		legacyGallery:"PostsFields_legacyGallery",
		updatedAt:"PostsFields_updatedAt",
		createdAt:"PostsFields_createdAt"
	},
	PostsFields_title:{
		create:"PostsFields_title_Create",
		read:"PostsFields_title_Read",
		update:"PostsFields_title_Update",
		delete:"PostsFields_title_Delete"
	},
	PostsFields_title_Create:{
		permission:"Boolean"
	},
	PostsFields_title_Read:{
		permission:"Boolean"
	},
	PostsFields_title_Update:{
		permission:"Boolean"
	},
	PostsFields_title_Delete:{
		permission:"Boolean"
	},
	PostsFields_region:{
		create:"PostsFields_region_Create",
		read:"PostsFields_region_Read",
		update:"PostsFields_region_Update",
		delete:"PostsFields_region_Delete"
	},
	PostsFields_region_Create:{
		permission:"Boolean"
	},
	PostsFields_region_Read:{
		permission:"Boolean"
	},
	PostsFields_region_Update:{
		permission:"Boolean"
	},
	PostsFields_region_Delete:{
		permission:"Boolean"
	},
	PostsFields_authur:{
		create:"PostsFields_authur_Create",
		read:"PostsFields_authur_Read",
		update:"PostsFields_authur_Update",
		delete:"PostsFields_authur_Delete"
	},
	PostsFields_authur_Create:{
		permission:"Boolean"
	},
	PostsFields_authur_Read:{
		permission:"Boolean"
	},
	PostsFields_authur_Update:{
		permission:"Boolean"
	},
	PostsFields_authur_Delete:{
		permission:"Boolean"
	},
	PostsFields_publishDateTime:{
		create:"PostsFields_publishDateTime_Create",
		read:"PostsFields_publishDateTime_Read",
		update:"PostsFields_publishDateTime_Update",
		delete:"PostsFields_publishDateTime_Delete"
	},
	PostsFields_publishDateTime_Create:{
		permission:"Boolean"
	},
	PostsFields_publishDateTime_Read:{
		permission:"Boolean"
	},
	PostsFields_publishDateTime_Update:{
		permission:"Boolean"
	},
	PostsFields_publishDateTime_Delete:{
		permission:"Boolean"
	},
	PostsFields_permalink:{
		create:"PostsFields_permalink_Create",
		read:"PostsFields_permalink_Read",
		update:"PostsFields_permalink_Update",
		delete:"PostsFields_permalink_Delete"
	},
	PostsFields_permalink_Create:{
		permission:"Boolean"
	},
	PostsFields_permalink_Read:{
		permission:"Boolean"
	},
	PostsFields_permalink_Update:{
		permission:"Boolean"
	},
	PostsFields_permalink_Delete:{
		permission:"Boolean"
	},
	PostsFields_status:{
		create:"PostsFields_status_Create",
		read:"PostsFields_status_Read",
		update:"PostsFields_status_Update",
		delete:"PostsFields_status_Delete"
	},
	PostsFields_status_Create:{
		permission:"Boolean"
	},
	PostsFields_status_Read:{
		permission:"Boolean"
	},
	PostsFields_status_Update:{
		permission:"Boolean"
	},
	PostsFields_status_Delete:{
		permission:"Boolean"
	},
	PostsFields_content:{
		create:"PostsFields_content_Create",
		read:"PostsFields_content_Read",
		update:"PostsFields_content_Update",
		delete:"PostsFields_content_Delete"
	},
	PostsFields_content_Create:{
		permission:"Boolean"
	},
	PostsFields_content_Read:{
		permission:"Boolean"
	},
	PostsFields_content_Update:{
		permission:"Boolean"
	},
	PostsFields_content_Delete:{
		permission:"Boolean"
	},
	PostsFields_legacyContent:{
		create:"PostsFields_legacyContent_Create",
		read:"PostsFields_legacyContent_Read",
		update:"PostsFields_legacyContent_Update",
		delete:"PostsFields_legacyContent_Delete"
	},
	PostsFields_legacyContent_Create:{
		permission:"Boolean"
	},
	PostsFields_legacyContent_Read:{
		permission:"Boolean"
	},
	PostsFields_legacyContent_Update:{
		permission:"Boolean"
	},
	PostsFields_legacyContent_Delete:{
		permission:"Boolean"
	},
	PostsFields_showLegacyContent:{
		create:"PostsFields_showLegacyContent_Create",
		read:"PostsFields_showLegacyContent_Read",
		update:"PostsFields_showLegacyContent_Update",
		delete:"PostsFields_showLegacyContent_Delete"
	},
	PostsFields_showLegacyContent_Create:{
		permission:"Boolean"
	},
	PostsFields_showLegacyContent_Read:{
		permission:"Boolean"
	},
	PostsFields_showLegacyContent_Update:{
		permission:"Boolean"
	},
	PostsFields_showLegacyContent_Delete:{
		permission:"Boolean"
	},
	PostsFields_blogCategories:{
		create:"PostsFields_blogCategories_Create",
		read:"PostsFields_blogCategories_Read",
		update:"PostsFields_blogCategories_Update",
		delete:"PostsFields_blogCategories_Delete"
	},
	PostsFields_blogCategories_Create:{
		permission:"Boolean"
	},
	PostsFields_blogCategories_Read:{
		permission:"Boolean"
	},
	PostsFields_blogCategories_Update:{
		permission:"Boolean"
	},
	PostsFields_blogCategories_Delete:{
		permission:"Boolean"
	},
	PostsFields_thumbnail:{
		create:"PostsFields_thumbnail_Create",
		read:"PostsFields_thumbnail_Read",
		update:"PostsFields_thumbnail_Update",
		delete:"PostsFields_thumbnail_Delete"
	},
	PostsFields_thumbnail_Create:{
		permission:"Boolean"
	},
	PostsFields_thumbnail_Read:{
		permission:"Boolean"
	},
	PostsFields_thumbnail_Update:{
		permission:"Boolean"
	},
	PostsFields_thumbnail_Delete:{
		permission:"Boolean"
	},
	PostsFields_banner:{
		create:"PostsFields_banner_Create",
		read:"PostsFields_banner_Read",
		update:"PostsFields_banner_Update",
		delete:"PostsFields_banner_Delete"
	},
	PostsFields_banner_Create:{
		permission:"Boolean"
	},
	PostsFields_banner_Read:{
		permission:"Boolean"
	},
	PostsFields_banner_Update:{
		permission:"Boolean"
	},
	PostsFields_banner_Delete:{
		permission:"Boolean"
	},
	PostsFields_criteria:{
		create:"PostsFields_criteria_Create",
		read:"PostsFields_criteria_Read",
		update:"PostsFields_criteria_Update",
		delete:"PostsFields_criteria_Delete",
		fields:"PostsFields_criteria_Fields"
	},
	PostsFields_criteria_Create:{
		permission:"Boolean"
	},
	PostsFields_criteria_Read:{
		permission:"Boolean"
	},
	PostsFields_criteria_Update:{
		permission:"Boolean"
	},
	PostsFields_criteria_Delete:{
		permission:"Boolean"
	},
	PostsFields_criteria_Fields:{
		audiences:"PostsFields_criteria_audiences",
		dates:"PostsFields_criteria_dates",
		categories:"PostsFields_criteria_categories",
		tags:"PostsFields_criteria_tags"
	},
	PostsFields_criteria_audiences:{
		create:"PostsFields_criteria_audiences_Create",
		read:"PostsFields_criteria_audiences_Read",
		update:"PostsFields_criteria_audiences_Update",
		delete:"PostsFields_criteria_audiences_Delete"
	},
	PostsFields_criteria_audiences_Create:{
		permission:"Boolean"
	},
	PostsFields_criteria_audiences_Read:{
		permission:"Boolean"
	},
	PostsFields_criteria_audiences_Update:{
		permission:"Boolean"
	},
	PostsFields_criteria_audiences_Delete:{
		permission:"Boolean"
	},
	PostsFields_criteria_dates:{
		create:"PostsFields_criteria_dates_Create",
		read:"PostsFields_criteria_dates_Read",
		update:"PostsFields_criteria_dates_Update",
		delete:"PostsFields_criteria_dates_Delete"
	},
	PostsFields_criteria_dates_Create:{
		permission:"Boolean"
	},
	PostsFields_criteria_dates_Read:{
		permission:"Boolean"
	},
	PostsFields_criteria_dates_Update:{
		permission:"Boolean"
	},
	PostsFields_criteria_dates_Delete:{
		permission:"Boolean"
	},
	PostsFields_criteria_categories:{
		create:"PostsFields_criteria_categories_Create",
		read:"PostsFields_criteria_categories_Read",
		update:"PostsFields_criteria_categories_Update",
		delete:"PostsFields_criteria_categories_Delete"
	},
	PostsFields_criteria_categories_Create:{
		permission:"Boolean"
	},
	PostsFields_criteria_categories_Read:{
		permission:"Boolean"
	},
	PostsFields_criteria_categories_Update:{
		permission:"Boolean"
	},
	PostsFields_criteria_categories_Delete:{
		permission:"Boolean"
	},
	PostsFields_criteria_tags:{
		create:"PostsFields_criteria_tags_Create",
		read:"PostsFields_criteria_tags_Read",
		update:"PostsFields_criteria_tags_Update",
		delete:"PostsFields_criteria_tags_Delete"
	},
	PostsFields_criteria_tags_Create:{
		permission:"Boolean"
	},
	PostsFields_criteria_tags_Read:{
		permission:"Boolean"
	},
	PostsFields_criteria_tags_Update:{
		permission:"Boolean"
	},
	PostsFields_criteria_tags_Delete:{
		permission:"Boolean"
	},
	PostsFields_location:{
		create:"PostsFields_location_Create",
		read:"PostsFields_location_Read",
		update:"PostsFields_location_Update",
		delete:"PostsFields_location_Delete"
	},
	PostsFields_location_Create:{
		permission:"Boolean"
	},
	PostsFields_location_Read:{
		permission:"Boolean"
	},
	PostsFields_location_Update:{
		permission:"Boolean"
	},
	PostsFields_location_Delete:{
		permission:"Boolean"
	},
	PostsFields_homePageInternalClick:{
		create:"PostsFields_homePageInternalClick_Create",
		read:"PostsFields_homePageInternalClick_Read",
		update:"PostsFields_homePageInternalClick_Update",
		delete:"PostsFields_homePageInternalClick_Delete"
	},
	PostsFields_homePageInternalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_homePageInternalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_homePageInternalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_homePageInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_homePageImpression:{
		create:"PostsFields_homePageImpression_Create",
		read:"PostsFields_homePageImpression_Read",
		update:"PostsFields_homePageImpression_Update",
		delete:"PostsFields_homePageImpression_Delete"
	},
	PostsFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_blogListInternalClick:{
		create:"PostsFields_blogListInternalClick_Create",
		read:"PostsFields_blogListInternalClick_Read",
		update:"PostsFields_blogListInternalClick_Update",
		delete:"PostsFields_blogListInternalClick_Delete"
	},
	PostsFields_blogListInternalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_blogListInternalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_blogListInternalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_blogListInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_blogListImpression:{
		create:"PostsFields_blogListImpression_Create",
		read:"PostsFields_blogListImpression_Read",
		update:"PostsFields_blogListImpression_Update",
		delete:"PostsFields_blogListImpression_Delete"
	},
	PostsFields_blogListImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_blogListImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_blogListImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_blogListImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_blogDetailInternalClick:{
		create:"PostsFields_blogDetailInternalClick_Create",
		read:"PostsFields_blogDetailInternalClick_Read",
		update:"PostsFields_blogDetailInternalClick_Update",
		delete:"PostsFields_blogDetailInternalClick_Delete"
	},
	PostsFields_blogDetailInternalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_blogDetailInternalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_blogDetailInternalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_blogDetailInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_blogDetailImpression:{
		create:"PostsFields_blogDetailImpression_Create",
		read:"PostsFields_blogDetailImpression_Read",
		update:"PostsFields_blogDetailImpression_Update",
		delete:"PostsFields_blogDetailImpression_Delete"
	},
	PostsFields_blogDetailImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_blogDetailImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_blogDetailImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_blogDetailImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_relatedEventInternalClick:{
		create:"PostsFields_relatedEventInternalClick_Create",
		read:"PostsFields_relatedEventInternalClick_Read",
		update:"PostsFields_relatedEventInternalClick_Update",
		delete:"PostsFields_relatedEventInternalClick_Delete"
	},
	PostsFields_relatedEventInternalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_relatedEventInternalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_relatedEventInternalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_relatedEventInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_relatedEventImpression:{
		create:"PostsFields_relatedEventImpression_Create",
		read:"PostsFields_relatedEventImpression_Read",
		update:"PostsFields_relatedEventImpression_Update",
		delete:"PostsFields_relatedEventImpression_Delete"
	},
	PostsFields_relatedEventImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_relatedEventImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_relatedEventImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_relatedEventImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_latestEventInternalClick:{
		create:"PostsFields_latestEventInternalClick_Create",
		read:"PostsFields_latestEventInternalClick_Read",
		update:"PostsFields_latestEventInternalClick_Update",
		delete:"PostsFields_latestEventInternalClick_Delete"
	},
	PostsFields_latestEventInternalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_latestEventInternalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_latestEventInternalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_latestEventInternalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_latestEventImpression:{
		create:"PostsFields_latestEventImpression_Create",
		read:"PostsFields_latestEventImpression_Read",
		update:"PostsFields_latestEventImpression_Update",
		delete:"PostsFields_latestEventImpression_Delete"
	},
	PostsFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_internalClick:{
		create:"PostsFields_internalClick_Create",
		read:"PostsFields_internalClick_Read",
		update:"PostsFields_internalClick_Update",
		delete:"PostsFields_internalClick_Delete"
	},
	PostsFields_internalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_internalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_internalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_internalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_totalImpression:{
		create:"PostsFields_totalImpression_Create",
		read:"PostsFields_totalImpression_Read",
		update:"PostsFields_totalImpression_Update",
		delete:"PostsFields_totalImpression_Delete"
	},
	PostsFields_totalImpression_Create:{
		permission:"Boolean"
	},
	PostsFields_totalImpression_Read:{
		permission:"Boolean"
	},
	PostsFields_totalImpression_Update:{
		permission:"Boolean"
	},
	PostsFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	PostsFields_externalClick:{
		create:"PostsFields_externalClick_Create",
		read:"PostsFields_externalClick_Read",
		update:"PostsFields_externalClick_Update",
		delete:"PostsFields_externalClick_Delete"
	},
	PostsFields_externalClick_Create:{
		permission:"Boolean"
	},
	PostsFields_externalClick_Read:{
		permission:"Boolean"
	},
	PostsFields_externalClick_Update:{
		permission:"Boolean"
	},
	PostsFields_externalClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_outboundClick:{
		create:"PostsFields_outboundClick_Create",
		read:"PostsFields_outboundClick_Read",
		update:"PostsFields_outboundClick_Update",
		delete:"PostsFields_outboundClick_Delete"
	},
	PostsFields_outboundClick_Create:{
		permission:"Boolean"
	},
	PostsFields_outboundClick_Read:{
		permission:"Boolean"
	},
	PostsFields_outboundClick_Update:{
		permission:"Boolean"
	},
	PostsFields_outboundClick_Delete:{
		permission:"Boolean"
	},
	PostsFields_legacyGallery:{
		create:"PostsFields_legacyGallery_Create",
		read:"PostsFields_legacyGallery_Read",
		update:"PostsFields_legacyGallery_Update",
		delete:"PostsFields_legacyGallery_Delete"
	},
	PostsFields_legacyGallery_Create:{
		permission:"Boolean"
	},
	PostsFields_legacyGallery_Read:{
		permission:"Boolean"
	},
	PostsFields_legacyGallery_Update:{
		permission:"Boolean"
	},
	PostsFields_legacyGallery_Delete:{
		permission:"Boolean"
	},
	PostsFields_updatedAt:{
		create:"PostsFields_updatedAt_Create",
		read:"PostsFields_updatedAt_Read",
		update:"PostsFields_updatedAt_Update",
		delete:"PostsFields_updatedAt_Delete"
	},
	PostsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostsFields_createdAt:{
		create:"PostsFields_createdAt_Create",
		read:"PostsFields_createdAt_Read",
		update:"PostsFields_createdAt_Update",
		delete:"PostsFields_createdAt_Delete"
	},
	PostsFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostsFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostsFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	private_mediaAccess:{
		fields:"PrivateMediaFields",
		create:"PrivateMediaCreateAccess",
		read:"PrivateMediaReadAccess",
		update:"PrivateMediaUpdateAccess",
		delete:"PrivateMediaDeleteAccess"
	},
	PrivateMediaFields:{
		alt:"PrivateMediaFields_alt",
		updatedAt:"PrivateMediaFields_updatedAt",
		createdAt:"PrivateMediaFields_createdAt",
		url:"PrivateMediaFields_url",
		filename:"PrivateMediaFields_filename",
		mimeType:"PrivateMediaFields_mimeType",
		filesize:"PrivateMediaFields_filesize",
		width:"PrivateMediaFields_width",
		height:"PrivateMediaFields_height",
		focalX:"PrivateMediaFields_focalX",
		focalY:"PrivateMediaFields_focalY",
		sizes:"PrivateMediaFields_sizes"
	},
	PrivateMediaFields_alt:{
		create:"PrivateMediaFields_alt_Create",
		read:"PrivateMediaFields_alt_Read",
		update:"PrivateMediaFields_alt_Update",
		delete:"PrivateMediaFields_alt_Delete"
	},
	PrivateMediaFields_alt_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_alt_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_alt_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_alt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_updatedAt:{
		create:"PrivateMediaFields_updatedAt_Create",
		read:"PrivateMediaFields_updatedAt_Read",
		update:"PrivateMediaFields_updatedAt_Update",
		delete:"PrivateMediaFields_updatedAt_Delete"
	},
	PrivateMediaFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_createdAt:{
		create:"PrivateMediaFields_createdAt_Create",
		read:"PrivateMediaFields_createdAt_Read",
		update:"PrivateMediaFields_createdAt_Update",
		delete:"PrivateMediaFields_createdAt_Delete"
	},
	PrivateMediaFields_createdAt_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_createdAt_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_createdAt_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_url:{
		create:"PrivateMediaFields_url_Create",
		read:"PrivateMediaFields_url_Read",
		update:"PrivateMediaFields_url_Update",
		delete:"PrivateMediaFields_url_Delete"
	},
	PrivateMediaFields_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_filename:{
		create:"PrivateMediaFields_filename_Create",
		read:"PrivateMediaFields_filename_Read",
		update:"PrivateMediaFields_filename_Update",
		delete:"PrivateMediaFields_filename_Delete"
	},
	PrivateMediaFields_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_mimeType:{
		create:"PrivateMediaFields_mimeType_Create",
		read:"PrivateMediaFields_mimeType_Read",
		update:"PrivateMediaFields_mimeType_Update",
		delete:"PrivateMediaFields_mimeType_Delete"
	},
	PrivateMediaFields_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_filesize:{
		create:"PrivateMediaFields_filesize_Create",
		read:"PrivateMediaFields_filesize_Read",
		update:"PrivateMediaFields_filesize_Update",
		delete:"PrivateMediaFields_filesize_Delete"
	},
	PrivateMediaFields_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_width:{
		create:"PrivateMediaFields_width_Create",
		read:"PrivateMediaFields_width_Read",
		update:"PrivateMediaFields_width_Update",
		delete:"PrivateMediaFields_width_Delete"
	},
	PrivateMediaFields_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_height:{
		create:"PrivateMediaFields_height_Create",
		read:"PrivateMediaFields_height_Read",
		update:"PrivateMediaFields_height_Update",
		delete:"PrivateMediaFields_height_Delete"
	},
	PrivateMediaFields_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalX:{
		create:"PrivateMediaFields_focalX_Create",
		read:"PrivateMediaFields_focalX_Read",
		update:"PrivateMediaFields_focalX_Update",
		delete:"PrivateMediaFields_focalX_Delete"
	},
	PrivateMediaFields_focalX_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalX_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalX_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalX_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalY:{
		create:"PrivateMediaFields_focalY_Create",
		read:"PrivateMediaFields_focalY_Read",
		update:"PrivateMediaFields_focalY_Update",
		delete:"PrivateMediaFields_focalY_Delete"
	},
	PrivateMediaFields_focalY_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalY_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalY_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_focalY_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes:{
		create:"PrivateMediaFields_sizes_Create",
		read:"PrivateMediaFields_sizes_Read",
		update:"PrivateMediaFields_sizes_Update",
		delete:"PrivateMediaFields_sizes_Delete",
		fields:"PrivateMediaFields_sizes_Fields"
	},
	PrivateMediaFields_sizes_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_Fields:{
		thumbnail:"PrivateMediaFields_sizes_thumbnail",
		portrait:"PrivateMediaFields_sizes_portrait",
		hero:"PrivateMediaFields_sizes_hero",
		thumbnail_jpeg:"PrivateMediaFields_sizes_thumbnail_jpeg",
		portrait_jpeg:"PrivateMediaFields_sizes_portrait_jpeg",
		hero_jpeg:"PrivateMediaFields_sizes_hero_jpeg"
	},
	PrivateMediaFields_sizes_thumbnail:{
		create:"PrivateMediaFields_sizes_thumbnail_Create",
		read:"PrivateMediaFields_sizes_thumbnail_Read",
		update:"PrivateMediaFields_sizes_thumbnail_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_Delete",
		fields:"PrivateMediaFields_sizes_thumbnail_Fields"
	},
	PrivateMediaFields_sizes_thumbnail_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_Fields:{
		url:"PrivateMediaFields_sizes_thumbnail_url",
		width:"PrivateMediaFields_sizes_thumbnail_width",
		height:"PrivateMediaFields_sizes_thumbnail_height",
		mimeType:"PrivateMediaFields_sizes_thumbnail_mimeType",
		filesize:"PrivateMediaFields_sizes_thumbnail_filesize",
		filename:"PrivateMediaFields_sizes_thumbnail_filename"
	},
	PrivateMediaFields_sizes_thumbnail_url:{
		create:"PrivateMediaFields_sizes_thumbnail_url_Create",
		read:"PrivateMediaFields_sizes_thumbnail_url_Read",
		update:"PrivateMediaFields_sizes_thumbnail_url_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_url_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_width:{
		create:"PrivateMediaFields_sizes_thumbnail_width_Create",
		read:"PrivateMediaFields_sizes_thumbnail_width_Read",
		update:"PrivateMediaFields_sizes_thumbnail_width_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_width_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_height:{
		create:"PrivateMediaFields_sizes_thumbnail_height_Create",
		read:"PrivateMediaFields_sizes_thumbnail_height_Read",
		update:"PrivateMediaFields_sizes_thumbnail_height_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_height_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_mimeType:{
		create:"PrivateMediaFields_sizes_thumbnail_mimeType_Create",
		read:"PrivateMediaFields_sizes_thumbnail_mimeType_Read",
		update:"PrivateMediaFields_sizes_thumbnail_mimeType_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_mimeType_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filesize:{
		create:"PrivateMediaFields_sizes_thumbnail_filesize_Create",
		read:"PrivateMediaFields_sizes_thumbnail_filesize_Read",
		update:"PrivateMediaFields_sizes_thumbnail_filesize_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_filesize_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filename:{
		create:"PrivateMediaFields_sizes_thumbnail_filename_Create",
		read:"PrivateMediaFields_sizes_thumbnail_filename_Read",
		update:"PrivateMediaFields_sizes_thumbnail_filename_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_filename_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait:{
		create:"PrivateMediaFields_sizes_portrait_Create",
		read:"PrivateMediaFields_sizes_portrait_Read",
		update:"PrivateMediaFields_sizes_portrait_Update",
		delete:"PrivateMediaFields_sizes_portrait_Delete",
		fields:"PrivateMediaFields_sizes_portrait_Fields"
	},
	PrivateMediaFields_sizes_portrait_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_Fields:{
		url:"PrivateMediaFields_sizes_portrait_url",
		width:"PrivateMediaFields_sizes_portrait_width",
		height:"PrivateMediaFields_sizes_portrait_height",
		mimeType:"PrivateMediaFields_sizes_portrait_mimeType",
		filesize:"PrivateMediaFields_sizes_portrait_filesize",
		filename:"PrivateMediaFields_sizes_portrait_filename"
	},
	PrivateMediaFields_sizes_portrait_url:{
		create:"PrivateMediaFields_sizes_portrait_url_Create",
		read:"PrivateMediaFields_sizes_portrait_url_Read",
		update:"PrivateMediaFields_sizes_portrait_url_Update",
		delete:"PrivateMediaFields_sizes_portrait_url_Delete"
	},
	PrivateMediaFields_sizes_portrait_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_width:{
		create:"PrivateMediaFields_sizes_portrait_width_Create",
		read:"PrivateMediaFields_sizes_portrait_width_Read",
		update:"PrivateMediaFields_sizes_portrait_width_Update",
		delete:"PrivateMediaFields_sizes_portrait_width_Delete"
	},
	PrivateMediaFields_sizes_portrait_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_height:{
		create:"PrivateMediaFields_sizes_portrait_height_Create",
		read:"PrivateMediaFields_sizes_portrait_height_Read",
		update:"PrivateMediaFields_sizes_portrait_height_Update",
		delete:"PrivateMediaFields_sizes_portrait_height_Delete"
	},
	PrivateMediaFields_sizes_portrait_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_mimeType:{
		create:"PrivateMediaFields_sizes_portrait_mimeType_Create",
		read:"PrivateMediaFields_sizes_portrait_mimeType_Read",
		update:"PrivateMediaFields_sizes_portrait_mimeType_Update",
		delete:"PrivateMediaFields_sizes_portrait_mimeType_Delete"
	},
	PrivateMediaFields_sizes_portrait_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filesize:{
		create:"PrivateMediaFields_sizes_portrait_filesize_Create",
		read:"PrivateMediaFields_sizes_portrait_filesize_Read",
		update:"PrivateMediaFields_sizes_portrait_filesize_Update",
		delete:"PrivateMediaFields_sizes_portrait_filesize_Delete"
	},
	PrivateMediaFields_sizes_portrait_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filename:{
		create:"PrivateMediaFields_sizes_portrait_filename_Create",
		read:"PrivateMediaFields_sizes_portrait_filename_Read",
		update:"PrivateMediaFields_sizes_portrait_filename_Update",
		delete:"PrivateMediaFields_sizes_portrait_filename_Delete"
	},
	PrivateMediaFields_sizes_portrait_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero:{
		create:"PrivateMediaFields_sizes_hero_Create",
		read:"PrivateMediaFields_sizes_hero_Read",
		update:"PrivateMediaFields_sizes_hero_Update",
		delete:"PrivateMediaFields_sizes_hero_Delete",
		fields:"PrivateMediaFields_sizes_hero_Fields"
	},
	PrivateMediaFields_sizes_hero_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_Fields:{
		url:"PrivateMediaFields_sizes_hero_url",
		width:"PrivateMediaFields_sizes_hero_width",
		height:"PrivateMediaFields_sizes_hero_height",
		mimeType:"PrivateMediaFields_sizes_hero_mimeType",
		filesize:"PrivateMediaFields_sizes_hero_filesize",
		filename:"PrivateMediaFields_sizes_hero_filename"
	},
	PrivateMediaFields_sizes_hero_url:{
		create:"PrivateMediaFields_sizes_hero_url_Create",
		read:"PrivateMediaFields_sizes_hero_url_Read",
		update:"PrivateMediaFields_sizes_hero_url_Update",
		delete:"PrivateMediaFields_sizes_hero_url_Delete"
	},
	PrivateMediaFields_sizes_hero_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_width:{
		create:"PrivateMediaFields_sizes_hero_width_Create",
		read:"PrivateMediaFields_sizes_hero_width_Read",
		update:"PrivateMediaFields_sizes_hero_width_Update",
		delete:"PrivateMediaFields_sizes_hero_width_Delete"
	},
	PrivateMediaFields_sizes_hero_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_height:{
		create:"PrivateMediaFields_sizes_hero_height_Create",
		read:"PrivateMediaFields_sizes_hero_height_Read",
		update:"PrivateMediaFields_sizes_hero_height_Update",
		delete:"PrivateMediaFields_sizes_hero_height_Delete"
	},
	PrivateMediaFields_sizes_hero_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_mimeType:{
		create:"PrivateMediaFields_sizes_hero_mimeType_Create",
		read:"PrivateMediaFields_sizes_hero_mimeType_Read",
		update:"PrivateMediaFields_sizes_hero_mimeType_Update",
		delete:"PrivateMediaFields_sizes_hero_mimeType_Delete"
	},
	PrivateMediaFields_sizes_hero_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filesize:{
		create:"PrivateMediaFields_sizes_hero_filesize_Create",
		read:"PrivateMediaFields_sizes_hero_filesize_Read",
		update:"PrivateMediaFields_sizes_hero_filesize_Update",
		delete:"PrivateMediaFields_sizes_hero_filesize_Delete"
	},
	PrivateMediaFields_sizes_hero_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filename:{
		create:"PrivateMediaFields_sizes_hero_filename_Create",
		read:"PrivateMediaFields_sizes_hero_filename_Read",
		update:"PrivateMediaFields_sizes_hero_filename_Update",
		delete:"PrivateMediaFields_sizes_hero_filename_Delete"
	},
	PrivateMediaFields_sizes_hero_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_Delete",
		fields:"PrivateMediaFields_sizes_thumbnail_jpeg_Fields"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_Fields:{
		url:"PrivateMediaFields_sizes_thumbnail_jpeg_url",
		width:"PrivateMediaFields_sizes_thumbnail_jpeg_width",
		height:"PrivateMediaFields_sizes_thumbnail_jpeg_height",
		mimeType:"PrivateMediaFields_sizes_thumbnail_jpeg_mimeType",
		filesize:"PrivateMediaFields_sizes_thumbnail_jpeg_filesize",
		filename:"PrivateMediaFields_sizes_thumbnail_jpeg_filename"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_url:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_url_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_url_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_url_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_width:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_width_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_width_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_width_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_height:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_height_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_height_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_height_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_mimeType:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filesize:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filename:{
		create:"PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create",
		read:"PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read",
		update:"PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update",
		delete:"PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_Delete",
		fields:"PrivateMediaFields_sizes_portrait_jpeg_Fields"
	},
	PrivateMediaFields_sizes_portrait_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_Fields:{
		url:"PrivateMediaFields_sizes_portrait_jpeg_url",
		width:"PrivateMediaFields_sizes_portrait_jpeg_width",
		height:"PrivateMediaFields_sizes_portrait_jpeg_height",
		mimeType:"PrivateMediaFields_sizes_portrait_jpeg_mimeType",
		filesize:"PrivateMediaFields_sizes_portrait_jpeg_filesize",
		filename:"PrivateMediaFields_sizes_portrait_jpeg_filename"
	},
	PrivateMediaFields_sizes_portrait_jpeg_url:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_url_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_url_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_url_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_url_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_width:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_width_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_width_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_width_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_width_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_height:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_height_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_height_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_height_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_height_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_mimeType:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filesize:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_filesize_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_filesize_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_filesize_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filename:{
		create:"PrivateMediaFields_sizes_portrait_jpeg_filename_Create",
		read:"PrivateMediaFields_sizes_portrait_jpeg_filename_Read",
		update:"PrivateMediaFields_sizes_portrait_jpeg_filename_Update",
		delete:"PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_portrait_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg:{
		create:"PrivateMediaFields_sizes_hero_jpeg_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_Delete",
		fields:"PrivateMediaFields_sizes_hero_jpeg_Fields"
	},
	PrivateMediaFields_sizes_hero_jpeg_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_Fields:{
		url:"PrivateMediaFields_sizes_hero_jpeg_url",
		width:"PrivateMediaFields_sizes_hero_jpeg_width",
		height:"PrivateMediaFields_sizes_hero_jpeg_height",
		mimeType:"PrivateMediaFields_sizes_hero_jpeg_mimeType",
		filesize:"PrivateMediaFields_sizes_hero_jpeg_filesize",
		filename:"PrivateMediaFields_sizes_hero_jpeg_filename"
	},
	PrivateMediaFields_sizes_hero_jpeg_url:{
		create:"PrivateMediaFields_sizes_hero_jpeg_url_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_url_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_url_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_url_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_url_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_url_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_url_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_url_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_width:{
		create:"PrivateMediaFields_sizes_hero_jpeg_width_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_width_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_width_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_width_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_width_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_width_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_width_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_width_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_height:{
		create:"PrivateMediaFields_sizes_hero_jpeg_height_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_height_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_height_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_height_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_height_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_height_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_height_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_height_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_mimeType:{
		create:"PrivateMediaFields_sizes_hero_jpeg_mimeType_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_mimeType_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_mimeType_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filesize:{
		create:"PrivateMediaFields_sizes_hero_jpeg_filesize_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_filesize_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_filesize_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filename:{
		create:"PrivateMediaFields_sizes_hero_jpeg_filename_Create",
		read:"PrivateMediaFields_sizes_hero_jpeg_filename_Read",
		update:"PrivateMediaFields_sizes_hero_jpeg_filename_Update",
		delete:"PrivateMediaFields_sizes_hero_jpeg_filename_Delete"
	},
	PrivateMediaFields_sizes_hero_jpeg_filename_Create:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filename_Read:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filename_Update:{
		permission:"Boolean"
	},
	PrivateMediaFields_sizes_hero_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	PrivateMediaCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PrivateMediaDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	mediaAccess:{
		fields:"MediaFields",
		create:"MediaCreateAccess",
		read:"MediaReadAccess",
		update:"MediaUpdateAccess",
		delete:"MediaDeleteAccess"
	},
	MediaFields:{
		alt:"MediaFields_alt",
		updatedAt:"MediaFields_updatedAt",
		createdAt:"MediaFields_createdAt",
		url:"MediaFields_url",
		filename:"MediaFields_filename",
		mimeType:"MediaFields_mimeType",
		filesize:"MediaFields_filesize",
		width:"MediaFields_width",
		height:"MediaFields_height",
		focalX:"MediaFields_focalX",
		focalY:"MediaFields_focalY",
		sizes:"MediaFields_sizes"
	},
	MediaFields_alt:{
		create:"MediaFields_alt_Create",
		read:"MediaFields_alt_Read",
		update:"MediaFields_alt_Update",
		delete:"MediaFields_alt_Delete"
	},
	MediaFields_alt_Create:{
		permission:"Boolean"
	},
	MediaFields_alt_Read:{
		permission:"Boolean"
	},
	MediaFields_alt_Update:{
		permission:"Boolean"
	},
	MediaFields_alt_Delete:{
		permission:"Boolean"
	},
	MediaFields_updatedAt:{
		create:"MediaFields_updatedAt_Create",
		read:"MediaFields_updatedAt_Read",
		update:"MediaFields_updatedAt_Update",
		delete:"MediaFields_updatedAt_Delete"
	},
	MediaFields_updatedAt_Create:{
		permission:"Boolean"
	},
	MediaFields_updatedAt_Read:{
		permission:"Boolean"
	},
	MediaFields_updatedAt_Update:{
		permission:"Boolean"
	},
	MediaFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	MediaFields_createdAt:{
		create:"MediaFields_createdAt_Create",
		read:"MediaFields_createdAt_Read",
		update:"MediaFields_createdAt_Update",
		delete:"MediaFields_createdAt_Delete"
	},
	MediaFields_createdAt_Create:{
		permission:"Boolean"
	},
	MediaFields_createdAt_Read:{
		permission:"Boolean"
	},
	MediaFields_createdAt_Update:{
		permission:"Boolean"
	},
	MediaFields_createdAt_Delete:{
		permission:"Boolean"
	},
	MediaFields_url:{
		create:"MediaFields_url_Create",
		read:"MediaFields_url_Read",
		update:"MediaFields_url_Update",
		delete:"MediaFields_url_Delete"
	},
	MediaFields_url_Create:{
		permission:"Boolean"
	},
	MediaFields_url_Read:{
		permission:"Boolean"
	},
	MediaFields_url_Update:{
		permission:"Boolean"
	},
	MediaFields_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_filename:{
		create:"MediaFields_filename_Create",
		read:"MediaFields_filename_Read",
		update:"MediaFields_filename_Update",
		delete:"MediaFields_filename_Delete"
	},
	MediaFields_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_mimeType:{
		create:"MediaFields_mimeType_Create",
		read:"MediaFields_mimeType_Read",
		update:"MediaFields_mimeType_Update",
		delete:"MediaFields_mimeType_Delete"
	},
	MediaFields_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_filesize:{
		create:"MediaFields_filesize_Create",
		read:"MediaFields_filesize_Read",
		update:"MediaFields_filesize_Update",
		delete:"MediaFields_filesize_Delete"
	},
	MediaFields_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_width:{
		create:"MediaFields_width_Create",
		read:"MediaFields_width_Read",
		update:"MediaFields_width_Update",
		delete:"MediaFields_width_Delete"
	},
	MediaFields_width_Create:{
		permission:"Boolean"
	},
	MediaFields_width_Read:{
		permission:"Boolean"
	},
	MediaFields_width_Update:{
		permission:"Boolean"
	},
	MediaFields_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_height:{
		create:"MediaFields_height_Create",
		read:"MediaFields_height_Read",
		update:"MediaFields_height_Update",
		delete:"MediaFields_height_Delete"
	},
	MediaFields_height_Create:{
		permission:"Boolean"
	},
	MediaFields_height_Read:{
		permission:"Boolean"
	},
	MediaFields_height_Update:{
		permission:"Boolean"
	},
	MediaFields_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_focalX:{
		create:"MediaFields_focalX_Create",
		read:"MediaFields_focalX_Read",
		update:"MediaFields_focalX_Update",
		delete:"MediaFields_focalX_Delete"
	},
	MediaFields_focalX_Create:{
		permission:"Boolean"
	},
	MediaFields_focalX_Read:{
		permission:"Boolean"
	},
	MediaFields_focalX_Update:{
		permission:"Boolean"
	},
	MediaFields_focalX_Delete:{
		permission:"Boolean"
	},
	MediaFields_focalY:{
		create:"MediaFields_focalY_Create",
		read:"MediaFields_focalY_Read",
		update:"MediaFields_focalY_Update",
		delete:"MediaFields_focalY_Delete"
	},
	MediaFields_focalY_Create:{
		permission:"Boolean"
	},
	MediaFields_focalY_Read:{
		permission:"Boolean"
	},
	MediaFields_focalY_Update:{
		permission:"Boolean"
	},
	MediaFields_focalY_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes:{
		create:"MediaFields_sizes_Create",
		read:"MediaFields_sizes_Read",
		update:"MediaFields_sizes_Update",
		delete:"MediaFields_sizes_Delete",
		fields:"MediaFields_sizes_Fields"
	},
	MediaFields_sizes_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_Fields:{
		small:"MediaFields_sizes_small",
		medium:"MediaFields_sizes_medium",
		large:"MediaFields_sizes_large",
		small_jpeg:"MediaFields_sizes_small_jpeg",
		medium_jpeg:"MediaFields_sizes_medium_jpeg",
		large_jpeg:"MediaFields_sizes_large_jpeg"
	},
	MediaFields_sizes_small:{
		create:"MediaFields_sizes_small_Create",
		read:"MediaFields_sizes_small_Read",
		update:"MediaFields_sizes_small_Update",
		delete:"MediaFields_sizes_small_Delete",
		fields:"MediaFields_sizes_small_Fields"
	},
	MediaFields_sizes_small_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_Fields:{
		url:"MediaFields_sizes_small_url",
		width:"MediaFields_sizes_small_width",
		height:"MediaFields_sizes_small_height",
		mimeType:"MediaFields_sizes_small_mimeType",
		filesize:"MediaFields_sizes_small_filesize",
		filename:"MediaFields_sizes_small_filename"
	},
	MediaFields_sizes_small_url:{
		create:"MediaFields_sizes_small_url_Create",
		read:"MediaFields_sizes_small_url_Read",
		update:"MediaFields_sizes_small_url_Update",
		delete:"MediaFields_sizes_small_url_Delete"
	},
	MediaFields_sizes_small_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_width:{
		create:"MediaFields_sizes_small_width_Create",
		read:"MediaFields_sizes_small_width_Read",
		update:"MediaFields_sizes_small_width_Update",
		delete:"MediaFields_sizes_small_width_Delete"
	},
	MediaFields_sizes_small_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_height:{
		create:"MediaFields_sizes_small_height_Create",
		read:"MediaFields_sizes_small_height_Read",
		update:"MediaFields_sizes_small_height_Update",
		delete:"MediaFields_sizes_small_height_Delete"
	},
	MediaFields_sizes_small_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_mimeType:{
		create:"MediaFields_sizes_small_mimeType_Create",
		read:"MediaFields_sizes_small_mimeType_Read",
		update:"MediaFields_sizes_small_mimeType_Update",
		delete:"MediaFields_sizes_small_mimeType_Delete"
	},
	MediaFields_sizes_small_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filesize:{
		create:"MediaFields_sizes_small_filesize_Create",
		read:"MediaFields_sizes_small_filesize_Read",
		update:"MediaFields_sizes_small_filesize_Update",
		delete:"MediaFields_sizes_small_filesize_Delete"
	},
	MediaFields_sizes_small_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filename:{
		create:"MediaFields_sizes_small_filename_Create",
		read:"MediaFields_sizes_small_filename_Read",
		update:"MediaFields_sizes_small_filename_Update",
		delete:"MediaFields_sizes_small_filename_Delete"
	},
	MediaFields_sizes_small_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium:{
		create:"MediaFields_sizes_medium_Create",
		read:"MediaFields_sizes_medium_Read",
		update:"MediaFields_sizes_medium_Update",
		delete:"MediaFields_sizes_medium_Delete",
		fields:"MediaFields_sizes_medium_Fields"
	},
	MediaFields_sizes_medium_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_Fields:{
		url:"MediaFields_sizes_medium_url",
		width:"MediaFields_sizes_medium_width",
		height:"MediaFields_sizes_medium_height",
		mimeType:"MediaFields_sizes_medium_mimeType",
		filesize:"MediaFields_sizes_medium_filesize",
		filename:"MediaFields_sizes_medium_filename"
	},
	MediaFields_sizes_medium_url:{
		create:"MediaFields_sizes_medium_url_Create",
		read:"MediaFields_sizes_medium_url_Read",
		update:"MediaFields_sizes_medium_url_Update",
		delete:"MediaFields_sizes_medium_url_Delete"
	},
	MediaFields_sizes_medium_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_width:{
		create:"MediaFields_sizes_medium_width_Create",
		read:"MediaFields_sizes_medium_width_Read",
		update:"MediaFields_sizes_medium_width_Update",
		delete:"MediaFields_sizes_medium_width_Delete"
	},
	MediaFields_sizes_medium_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_height:{
		create:"MediaFields_sizes_medium_height_Create",
		read:"MediaFields_sizes_medium_height_Read",
		update:"MediaFields_sizes_medium_height_Update",
		delete:"MediaFields_sizes_medium_height_Delete"
	},
	MediaFields_sizes_medium_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_mimeType:{
		create:"MediaFields_sizes_medium_mimeType_Create",
		read:"MediaFields_sizes_medium_mimeType_Read",
		update:"MediaFields_sizes_medium_mimeType_Update",
		delete:"MediaFields_sizes_medium_mimeType_Delete"
	},
	MediaFields_sizes_medium_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filesize:{
		create:"MediaFields_sizes_medium_filesize_Create",
		read:"MediaFields_sizes_medium_filesize_Read",
		update:"MediaFields_sizes_medium_filesize_Update",
		delete:"MediaFields_sizes_medium_filesize_Delete"
	},
	MediaFields_sizes_medium_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filename:{
		create:"MediaFields_sizes_medium_filename_Create",
		read:"MediaFields_sizes_medium_filename_Read",
		update:"MediaFields_sizes_medium_filename_Update",
		delete:"MediaFields_sizes_medium_filename_Delete"
	},
	MediaFields_sizes_medium_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large:{
		create:"MediaFields_sizes_large_Create",
		read:"MediaFields_sizes_large_Read",
		update:"MediaFields_sizes_large_Update",
		delete:"MediaFields_sizes_large_Delete",
		fields:"MediaFields_sizes_large_Fields"
	},
	MediaFields_sizes_large_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_Fields:{
		url:"MediaFields_sizes_large_url",
		width:"MediaFields_sizes_large_width",
		height:"MediaFields_sizes_large_height",
		mimeType:"MediaFields_sizes_large_mimeType",
		filesize:"MediaFields_sizes_large_filesize",
		filename:"MediaFields_sizes_large_filename"
	},
	MediaFields_sizes_large_url:{
		create:"MediaFields_sizes_large_url_Create",
		read:"MediaFields_sizes_large_url_Read",
		update:"MediaFields_sizes_large_url_Update",
		delete:"MediaFields_sizes_large_url_Delete"
	},
	MediaFields_sizes_large_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_width:{
		create:"MediaFields_sizes_large_width_Create",
		read:"MediaFields_sizes_large_width_Read",
		update:"MediaFields_sizes_large_width_Update",
		delete:"MediaFields_sizes_large_width_Delete"
	},
	MediaFields_sizes_large_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_height:{
		create:"MediaFields_sizes_large_height_Create",
		read:"MediaFields_sizes_large_height_Read",
		update:"MediaFields_sizes_large_height_Update",
		delete:"MediaFields_sizes_large_height_Delete"
	},
	MediaFields_sizes_large_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_mimeType:{
		create:"MediaFields_sizes_large_mimeType_Create",
		read:"MediaFields_sizes_large_mimeType_Read",
		update:"MediaFields_sizes_large_mimeType_Update",
		delete:"MediaFields_sizes_large_mimeType_Delete"
	},
	MediaFields_sizes_large_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filesize:{
		create:"MediaFields_sizes_large_filesize_Create",
		read:"MediaFields_sizes_large_filesize_Read",
		update:"MediaFields_sizes_large_filesize_Update",
		delete:"MediaFields_sizes_large_filesize_Delete"
	},
	MediaFields_sizes_large_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filename:{
		create:"MediaFields_sizes_large_filename_Create",
		read:"MediaFields_sizes_large_filename_Read",
		update:"MediaFields_sizes_large_filename_Update",
		delete:"MediaFields_sizes_large_filename_Delete"
	},
	MediaFields_sizes_large_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg:{
		create:"MediaFields_sizes_small_jpeg_Create",
		read:"MediaFields_sizes_small_jpeg_Read",
		update:"MediaFields_sizes_small_jpeg_Update",
		delete:"MediaFields_sizes_small_jpeg_Delete",
		fields:"MediaFields_sizes_small_jpeg_Fields"
	},
	MediaFields_sizes_small_jpeg_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_Fields:{
		url:"MediaFields_sizes_small_jpeg_url",
		width:"MediaFields_sizes_small_jpeg_width",
		height:"MediaFields_sizes_small_jpeg_height",
		mimeType:"MediaFields_sizes_small_jpeg_mimeType",
		filesize:"MediaFields_sizes_small_jpeg_filesize",
		filename:"MediaFields_sizes_small_jpeg_filename"
	},
	MediaFields_sizes_small_jpeg_url:{
		create:"MediaFields_sizes_small_jpeg_url_Create",
		read:"MediaFields_sizes_small_jpeg_url_Read",
		update:"MediaFields_sizes_small_jpeg_url_Update",
		delete:"MediaFields_sizes_small_jpeg_url_Delete"
	},
	MediaFields_sizes_small_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_width:{
		create:"MediaFields_sizes_small_jpeg_width_Create",
		read:"MediaFields_sizes_small_jpeg_width_Read",
		update:"MediaFields_sizes_small_jpeg_width_Update",
		delete:"MediaFields_sizes_small_jpeg_width_Delete"
	},
	MediaFields_sizes_small_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_height:{
		create:"MediaFields_sizes_small_jpeg_height_Create",
		read:"MediaFields_sizes_small_jpeg_height_Read",
		update:"MediaFields_sizes_small_jpeg_height_Update",
		delete:"MediaFields_sizes_small_jpeg_height_Delete"
	},
	MediaFields_sizes_small_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_mimeType:{
		create:"MediaFields_sizes_small_jpeg_mimeType_Create",
		read:"MediaFields_sizes_small_jpeg_mimeType_Read",
		update:"MediaFields_sizes_small_jpeg_mimeType_Update",
		delete:"MediaFields_sizes_small_jpeg_mimeType_Delete"
	},
	MediaFields_sizes_small_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filesize:{
		create:"MediaFields_sizes_small_jpeg_filesize_Create",
		read:"MediaFields_sizes_small_jpeg_filesize_Read",
		update:"MediaFields_sizes_small_jpeg_filesize_Update",
		delete:"MediaFields_sizes_small_jpeg_filesize_Delete"
	},
	MediaFields_sizes_small_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filename:{
		create:"MediaFields_sizes_small_jpeg_filename_Create",
		read:"MediaFields_sizes_small_jpeg_filename_Read",
		update:"MediaFields_sizes_small_jpeg_filename_Update",
		delete:"MediaFields_sizes_small_jpeg_filename_Delete"
	},
	MediaFields_sizes_small_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_small_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg:{
		create:"MediaFields_sizes_medium_jpeg_Create",
		read:"MediaFields_sizes_medium_jpeg_Read",
		update:"MediaFields_sizes_medium_jpeg_Update",
		delete:"MediaFields_sizes_medium_jpeg_Delete",
		fields:"MediaFields_sizes_medium_jpeg_Fields"
	},
	MediaFields_sizes_medium_jpeg_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_Fields:{
		url:"MediaFields_sizes_medium_jpeg_url",
		width:"MediaFields_sizes_medium_jpeg_width",
		height:"MediaFields_sizes_medium_jpeg_height",
		mimeType:"MediaFields_sizes_medium_jpeg_mimeType",
		filesize:"MediaFields_sizes_medium_jpeg_filesize",
		filename:"MediaFields_sizes_medium_jpeg_filename"
	},
	MediaFields_sizes_medium_jpeg_url:{
		create:"MediaFields_sizes_medium_jpeg_url_Create",
		read:"MediaFields_sizes_medium_jpeg_url_Read",
		update:"MediaFields_sizes_medium_jpeg_url_Update",
		delete:"MediaFields_sizes_medium_jpeg_url_Delete"
	},
	MediaFields_sizes_medium_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_width:{
		create:"MediaFields_sizes_medium_jpeg_width_Create",
		read:"MediaFields_sizes_medium_jpeg_width_Read",
		update:"MediaFields_sizes_medium_jpeg_width_Update",
		delete:"MediaFields_sizes_medium_jpeg_width_Delete"
	},
	MediaFields_sizes_medium_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_height:{
		create:"MediaFields_sizes_medium_jpeg_height_Create",
		read:"MediaFields_sizes_medium_jpeg_height_Read",
		update:"MediaFields_sizes_medium_jpeg_height_Update",
		delete:"MediaFields_sizes_medium_jpeg_height_Delete"
	},
	MediaFields_sizes_medium_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_mimeType:{
		create:"MediaFields_sizes_medium_jpeg_mimeType_Create",
		read:"MediaFields_sizes_medium_jpeg_mimeType_Read",
		update:"MediaFields_sizes_medium_jpeg_mimeType_Update",
		delete:"MediaFields_sizes_medium_jpeg_mimeType_Delete"
	},
	MediaFields_sizes_medium_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filesize:{
		create:"MediaFields_sizes_medium_jpeg_filesize_Create",
		read:"MediaFields_sizes_medium_jpeg_filesize_Read",
		update:"MediaFields_sizes_medium_jpeg_filesize_Update",
		delete:"MediaFields_sizes_medium_jpeg_filesize_Delete"
	},
	MediaFields_sizes_medium_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filename:{
		create:"MediaFields_sizes_medium_jpeg_filename_Create",
		read:"MediaFields_sizes_medium_jpeg_filename_Read",
		update:"MediaFields_sizes_medium_jpeg_filename_Update",
		delete:"MediaFields_sizes_medium_jpeg_filename_Delete"
	},
	MediaFields_sizes_medium_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_medium_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg:{
		create:"MediaFields_sizes_large_jpeg_Create",
		read:"MediaFields_sizes_large_jpeg_Read",
		update:"MediaFields_sizes_large_jpeg_Update",
		delete:"MediaFields_sizes_large_jpeg_Delete",
		fields:"MediaFields_sizes_large_jpeg_Fields"
	},
	MediaFields_sizes_large_jpeg_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_Fields:{
		url:"MediaFields_sizes_large_jpeg_url",
		width:"MediaFields_sizes_large_jpeg_width",
		height:"MediaFields_sizes_large_jpeg_height",
		mimeType:"MediaFields_sizes_large_jpeg_mimeType",
		filesize:"MediaFields_sizes_large_jpeg_filesize",
		filename:"MediaFields_sizes_large_jpeg_filename"
	},
	MediaFields_sizes_large_jpeg_url:{
		create:"MediaFields_sizes_large_jpeg_url_Create",
		read:"MediaFields_sizes_large_jpeg_url_Read",
		update:"MediaFields_sizes_large_jpeg_url_Update",
		delete:"MediaFields_sizes_large_jpeg_url_Delete"
	},
	MediaFields_sizes_large_jpeg_url_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_url_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_url_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_url_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_width:{
		create:"MediaFields_sizes_large_jpeg_width_Create",
		read:"MediaFields_sizes_large_jpeg_width_Read",
		update:"MediaFields_sizes_large_jpeg_width_Update",
		delete:"MediaFields_sizes_large_jpeg_width_Delete"
	},
	MediaFields_sizes_large_jpeg_width_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_width_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_width_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_width_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_height:{
		create:"MediaFields_sizes_large_jpeg_height_Create",
		read:"MediaFields_sizes_large_jpeg_height_Read",
		update:"MediaFields_sizes_large_jpeg_height_Update",
		delete:"MediaFields_sizes_large_jpeg_height_Delete"
	},
	MediaFields_sizes_large_jpeg_height_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_height_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_height_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_height_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_mimeType:{
		create:"MediaFields_sizes_large_jpeg_mimeType_Create",
		read:"MediaFields_sizes_large_jpeg_mimeType_Read",
		update:"MediaFields_sizes_large_jpeg_mimeType_Update",
		delete:"MediaFields_sizes_large_jpeg_mimeType_Delete"
	},
	MediaFields_sizes_large_jpeg_mimeType_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_mimeType_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_mimeType_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_mimeType_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filesize:{
		create:"MediaFields_sizes_large_jpeg_filesize_Create",
		read:"MediaFields_sizes_large_jpeg_filesize_Read",
		update:"MediaFields_sizes_large_jpeg_filesize_Update",
		delete:"MediaFields_sizes_large_jpeg_filesize_Delete"
	},
	MediaFields_sizes_large_jpeg_filesize_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filesize_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filesize_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filesize_Delete:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filename:{
		create:"MediaFields_sizes_large_jpeg_filename_Create",
		read:"MediaFields_sizes_large_jpeg_filename_Read",
		update:"MediaFields_sizes_large_jpeg_filename_Update",
		delete:"MediaFields_sizes_large_jpeg_filename_Delete"
	},
	MediaFields_sizes_large_jpeg_filename_Create:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filename_Read:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filename_Update:{
		permission:"Boolean"
	},
	MediaFields_sizes_large_jpeg_filename_Delete:{
		permission:"Boolean"
	},
	MediaCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MediaDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	backupMediaAccess:{
		fields:"BackupMediaFields",
		create:"BackupMediaCreateAccess",
		read:"BackupMediaReadAccess",
		update:"BackupMediaUpdateAccess",
		delete:"BackupMediaDeleteAccess"
	},
	BackupMediaFields:{
		alt:"BackupMediaFields_alt",
		creator:"BackupMediaFields_creator",
		updatedAt:"BackupMediaFields_updatedAt",
		createdAt:"BackupMediaFields_createdAt",
		url:"BackupMediaFields_url",
		filename:"BackupMediaFields_filename",
		mimeType:"BackupMediaFields_mimeType",
		filesize:"BackupMediaFields_filesize",
		width:"BackupMediaFields_width",
		height:"BackupMediaFields_height",
		focalX:"BackupMediaFields_focalX",
		focalY:"BackupMediaFields_focalY",
		sizes:"BackupMediaFields_sizes"
	},
	BackupMediaFields_alt:{
		create:"BackupMediaFields_alt_Create",
		read:"BackupMediaFields_alt_Read",
		update:"BackupMediaFields_alt_Update",
		delete:"BackupMediaFields_alt_Delete"
	},
	BackupMediaFields_alt_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_alt_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_alt_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_alt_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_creator:{
		create:"BackupMediaFields_creator_Create",
		read:"BackupMediaFields_creator_Read",
		update:"BackupMediaFields_creator_Update",
		delete:"BackupMediaFields_creator_Delete"
	},
	BackupMediaFields_creator_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_creator_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_creator_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_creator_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_updatedAt:{
		create:"BackupMediaFields_updatedAt_Create",
		read:"BackupMediaFields_updatedAt_Read",
		update:"BackupMediaFields_updatedAt_Update",
		delete:"BackupMediaFields_updatedAt_Delete"
	},
	BackupMediaFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_createdAt:{
		create:"BackupMediaFields_createdAt_Create",
		read:"BackupMediaFields_createdAt_Read",
		update:"BackupMediaFields_createdAt_Update",
		delete:"BackupMediaFields_createdAt_Delete"
	},
	BackupMediaFields_createdAt_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_createdAt_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_createdAt_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_url:{
		create:"BackupMediaFields_url_Create",
		read:"BackupMediaFields_url_Read",
		update:"BackupMediaFields_url_Update",
		delete:"BackupMediaFields_url_Delete"
	},
	BackupMediaFields_url_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_url_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_url_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_filename:{
		create:"BackupMediaFields_filename_Create",
		read:"BackupMediaFields_filename_Read",
		update:"BackupMediaFields_filename_Update",
		delete:"BackupMediaFields_filename_Delete"
	},
	BackupMediaFields_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_mimeType:{
		create:"BackupMediaFields_mimeType_Create",
		read:"BackupMediaFields_mimeType_Read",
		update:"BackupMediaFields_mimeType_Update",
		delete:"BackupMediaFields_mimeType_Delete"
	},
	BackupMediaFields_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_filesize:{
		create:"BackupMediaFields_filesize_Create",
		read:"BackupMediaFields_filesize_Read",
		update:"BackupMediaFields_filesize_Update",
		delete:"BackupMediaFields_filesize_Delete"
	},
	BackupMediaFields_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_width:{
		create:"BackupMediaFields_width_Create",
		read:"BackupMediaFields_width_Read",
		update:"BackupMediaFields_width_Update",
		delete:"BackupMediaFields_width_Delete"
	},
	BackupMediaFields_width_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_width_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_width_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_height:{
		create:"BackupMediaFields_height_Create",
		read:"BackupMediaFields_height_Read",
		update:"BackupMediaFields_height_Update",
		delete:"BackupMediaFields_height_Delete"
	},
	BackupMediaFields_height_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_height_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_height_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_focalX:{
		create:"BackupMediaFields_focalX_Create",
		read:"BackupMediaFields_focalX_Read",
		update:"BackupMediaFields_focalX_Update",
		delete:"BackupMediaFields_focalX_Delete"
	},
	BackupMediaFields_focalX_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_focalX_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_focalX_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_focalX_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_focalY:{
		create:"BackupMediaFields_focalY_Create",
		read:"BackupMediaFields_focalY_Read",
		update:"BackupMediaFields_focalY_Update",
		delete:"BackupMediaFields_focalY_Delete"
	},
	BackupMediaFields_focalY_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_focalY_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_focalY_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_focalY_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes:{
		create:"BackupMediaFields_sizes_Create",
		read:"BackupMediaFields_sizes_Read",
		update:"BackupMediaFields_sizes_Update",
		delete:"BackupMediaFields_sizes_Delete",
		fields:"BackupMediaFields_sizes_Fields"
	},
	BackupMediaFields_sizes_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_Fields:{
		thumbnail:"BackupMediaFields_sizes_thumbnail",
		sixteenByNineMedium:"BackupMediaFields_sizes_sixteenByNineMedium"
	},
	BackupMediaFields_sizes_thumbnail:{
		create:"BackupMediaFields_sizes_thumbnail_Create",
		read:"BackupMediaFields_sizes_thumbnail_Read",
		update:"BackupMediaFields_sizes_thumbnail_Update",
		delete:"BackupMediaFields_sizes_thumbnail_Delete",
		fields:"BackupMediaFields_sizes_thumbnail_Fields"
	},
	BackupMediaFields_sizes_thumbnail_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_Fields:{
		url:"BackupMediaFields_sizes_thumbnail_url",
		width:"BackupMediaFields_sizes_thumbnail_width",
		height:"BackupMediaFields_sizes_thumbnail_height",
		mimeType:"BackupMediaFields_sizes_thumbnail_mimeType",
		filesize:"BackupMediaFields_sizes_thumbnail_filesize",
		filename:"BackupMediaFields_sizes_thumbnail_filename"
	},
	BackupMediaFields_sizes_thumbnail_url:{
		create:"BackupMediaFields_sizes_thumbnail_url_Create",
		read:"BackupMediaFields_sizes_thumbnail_url_Read",
		update:"BackupMediaFields_sizes_thumbnail_url_Update",
		delete:"BackupMediaFields_sizes_thumbnail_url_Delete"
	},
	BackupMediaFields_sizes_thumbnail_url_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_url_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_url_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_width:{
		create:"BackupMediaFields_sizes_thumbnail_width_Create",
		read:"BackupMediaFields_sizes_thumbnail_width_Read",
		update:"BackupMediaFields_sizes_thumbnail_width_Update",
		delete:"BackupMediaFields_sizes_thumbnail_width_Delete"
	},
	BackupMediaFields_sizes_thumbnail_width_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_width_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_width_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_height:{
		create:"BackupMediaFields_sizes_thumbnail_height_Create",
		read:"BackupMediaFields_sizes_thumbnail_height_Read",
		update:"BackupMediaFields_sizes_thumbnail_height_Update",
		delete:"BackupMediaFields_sizes_thumbnail_height_Delete"
	},
	BackupMediaFields_sizes_thumbnail_height_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_height_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_height_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_mimeType:{
		create:"BackupMediaFields_sizes_thumbnail_mimeType_Create",
		read:"BackupMediaFields_sizes_thumbnail_mimeType_Read",
		update:"BackupMediaFields_sizes_thumbnail_mimeType_Update",
		delete:"BackupMediaFields_sizes_thumbnail_mimeType_Delete"
	},
	BackupMediaFields_sizes_thumbnail_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filesize:{
		create:"BackupMediaFields_sizes_thumbnail_filesize_Create",
		read:"BackupMediaFields_sizes_thumbnail_filesize_Read",
		update:"BackupMediaFields_sizes_thumbnail_filesize_Update",
		delete:"BackupMediaFields_sizes_thumbnail_filesize_Delete"
	},
	BackupMediaFields_sizes_thumbnail_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filename:{
		create:"BackupMediaFields_sizes_thumbnail_filename_Create",
		read:"BackupMediaFields_sizes_thumbnail_filename_Read",
		update:"BackupMediaFields_sizes_thumbnail_filename_Update",
		delete:"BackupMediaFields_sizes_thumbnail_filename_Delete"
	},
	BackupMediaFields_sizes_thumbnail_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_thumbnail_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_Delete",
		fields:"BackupMediaFields_sizes_sixteenByNineMedium_Fields"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_Fields:{
		url:"BackupMediaFields_sizes_sixteenByNineMedium_url",
		width:"BackupMediaFields_sizes_sixteenByNineMedium_width",
		height:"BackupMediaFields_sizes_sixteenByNineMedium_height",
		mimeType:"BackupMediaFields_sizes_sixteenByNineMedium_mimeType",
		filesize:"BackupMediaFields_sizes_sixteenByNineMedium_filesize",
		filename:"BackupMediaFields_sizes_sixteenByNineMedium_filename"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_url:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_url_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_url_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_url_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_url_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_url_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_url_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_url_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_width:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_width_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_width_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_width_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_width_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_width_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_width_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_width_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_height:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_height_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_height_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_height_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_height_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_height_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_height_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_height_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_mimeType:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filesize:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filename:{
		create:"BackupMediaFields_sizes_sixteenByNineMedium_filename_Create",
		read:"BackupMediaFields_sizes_sixteenByNineMedium_filename_Read",
		update:"BackupMediaFields_sizes_sixteenByNineMedium_filename_Update",
		delete:"BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filename_Create:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filename_Read:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filename_Update:{
		permission:"Boolean"
	},
	BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete:{
		permission:"Boolean"
	},
	BackupMediaCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BackupMediaDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ranking_eventsAccess:{
		fields:"RankingEventsFields",
		create:"RankingEventsCreateAccess",
		read:"RankingEventsReadAccess",
		update:"RankingEventsUpdateAccess",
		delete:"RankingEventsDeleteAccess"
	},
	RankingEventsFields:{
		name:"RankingEventsFields_name",
		visible:"RankingEventsFields_visible",
		maxItems:"RankingEventsFields_maxItems",
		template:"RankingEventsFields_template",
		list:"RankingEventsFields_list",
		region:"RankingEventsFields_region",
		updatedAt:"RankingEventsFields_updatedAt",
		createdAt:"RankingEventsFields_createdAt"
	},
	RankingEventsFields_name:{
		create:"RankingEventsFields_name_Create",
		read:"RankingEventsFields_name_Read",
		update:"RankingEventsFields_name_Update",
		delete:"RankingEventsFields_name_Delete"
	},
	RankingEventsFields_name_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_name_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_name_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_name_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_visible:{
		create:"RankingEventsFields_visible_Create",
		read:"RankingEventsFields_visible_Read",
		update:"RankingEventsFields_visible_Update",
		delete:"RankingEventsFields_visible_Delete"
	},
	RankingEventsFields_visible_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_visible_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_visible_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_visible_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_maxItems:{
		create:"RankingEventsFields_maxItems_Create",
		read:"RankingEventsFields_maxItems_Read",
		update:"RankingEventsFields_maxItems_Update",
		delete:"RankingEventsFields_maxItems_Delete"
	},
	RankingEventsFields_maxItems_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_maxItems_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_maxItems_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_maxItems_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_template:{
		create:"RankingEventsFields_template_Create",
		read:"RankingEventsFields_template_Read",
		update:"RankingEventsFields_template_Update",
		delete:"RankingEventsFields_template_Delete"
	},
	RankingEventsFields_template_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_template_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_template_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_template_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_list:{
		create:"RankingEventsFields_list_Create",
		read:"RankingEventsFields_list_Read",
		update:"RankingEventsFields_list_Update",
		delete:"RankingEventsFields_list_Delete",
		fields:"RankingEventsFields_list_Fields"
	},
	RankingEventsFields_list_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_list_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_list_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_list_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_list_Fields:{
		item:"RankingEventsFields_list_item",
		id:"RankingEventsFields_list_id"
	},
	RankingEventsFields_list_item:{
		create:"RankingEventsFields_list_item_Create",
		read:"RankingEventsFields_list_item_Read",
		update:"RankingEventsFields_list_item_Update",
		delete:"RankingEventsFields_list_item_Delete"
	},
	RankingEventsFields_list_item_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_list_item_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_list_item_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_list_item_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_list_id:{
		create:"RankingEventsFields_list_id_Create",
		read:"RankingEventsFields_list_id_Read",
		update:"RankingEventsFields_list_id_Update",
		delete:"RankingEventsFields_list_id_Delete"
	},
	RankingEventsFields_list_id_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_list_id_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_list_id_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_list_id_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_region:{
		create:"RankingEventsFields_region_Create",
		read:"RankingEventsFields_region_Read",
		update:"RankingEventsFields_region_Update",
		delete:"RankingEventsFields_region_Delete"
	},
	RankingEventsFields_region_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_region_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_region_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_region_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_updatedAt:{
		create:"RankingEventsFields_updatedAt_Create",
		read:"RankingEventsFields_updatedAt_Read",
		update:"RankingEventsFields_updatedAt_Update",
		delete:"RankingEventsFields_updatedAt_Delete"
	},
	RankingEventsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RankingEventsFields_createdAt:{
		create:"RankingEventsFields_createdAt_Create",
		read:"RankingEventsFields_createdAt_Read",
		update:"RankingEventsFields_createdAt_Update",
		delete:"RankingEventsFields_createdAt_Delete"
	},
	RankingEventsFields_createdAt_Create:{
		permission:"Boolean"
	},
	RankingEventsFields_createdAt_Read:{
		permission:"Boolean"
	},
	RankingEventsFields_createdAt_Update:{
		permission:"Boolean"
	},
	RankingEventsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RankingEventsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingEventsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ranking_locationsAccess:{
		fields:"RankingLocationsFields",
		create:"RankingLocationsCreateAccess",
		read:"RankingLocationsReadAccess",
		update:"RankingLocationsUpdateAccess",
		delete:"RankingLocationsDeleteAccess"
	},
	RankingLocationsFields:{
		name:"RankingLocationsFields_name",
		visible:"RankingLocationsFields_visible",
		maxItems:"RankingLocationsFields_maxItems",
		template:"RankingLocationsFields_template",
		list:"RankingLocationsFields_list",
		region:"RankingLocationsFields_region",
		updatedAt:"RankingLocationsFields_updatedAt",
		createdAt:"RankingLocationsFields_createdAt"
	},
	RankingLocationsFields_name:{
		create:"RankingLocationsFields_name_Create",
		read:"RankingLocationsFields_name_Read",
		update:"RankingLocationsFields_name_Update",
		delete:"RankingLocationsFields_name_Delete"
	},
	RankingLocationsFields_name_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_name_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_name_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_name_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_visible:{
		create:"RankingLocationsFields_visible_Create",
		read:"RankingLocationsFields_visible_Read",
		update:"RankingLocationsFields_visible_Update",
		delete:"RankingLocationsFields_visible_Delete"
	},
	RankingLocationsFields_visible_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_visible_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_visible_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_visible_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_maxItems:{
		create:"RankingLocationsFields_maxItems_Create",
		read:"RankingLocationsFields_maxItems_Read",
		update:"RankingLocationsFields_maxItems_Update",
		delete:"RankingLocationsFields_maxItems_Delete"
	},
	RankingLocationsFields_maxItems_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_maxItems_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_maxItems_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_maxItems_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_template:{
		create:"RankingLocationsFields_template_Create",
		read:"RankingLocationsFields_template_Read",
		update:"RankingLocationsFields_template_Update",
		delete:"RankingLocationsFields_template_Delete"
	},
	RankingLocationsFields_template_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_template_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_template_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_template_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_list:{
		create:"RankingLocationsFields_list_Create",
		read:"RankingLocationsFields_list_Read",
		update:"RankingLocationsFields_list_Update",
		delete:"RankingLocationsFields_list_Delete",
		fields:"RankingLocationsFields_list_Fields"
	},
	RankingLocationsFields_list_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_Fields:{
		item:"RankingLocationsFields_list_item",
		id:"RankingLocationsFields_list_id"
	},
	RankingLocationsFields_list_item:{
		create:"RankingLocationsFields_list_item_Create",
		read:"RankingLocationsFields_list_item_Read",
		update:"RankingLocationsFields_list_item_Update",
		delete:"RankingLocationsFields_list_item_Delete"
	},
	RankingLocationsFields_list_item_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_item_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_item_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_item_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_id:{
		create:"RankingLocationsFields_list_id_Create",
		read:"RankingLocationsFields_list_id_Read",
		update:"RankingLocationsFields_list_id_Update",
		delete:"RankingLocationsFields_list_id_Delete"
	},
	RankingLocationsFields_list_id_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_id_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_id_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_list_id_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_region:{
		create:"RankingLocationsFields_region_Create",
		read:"RankingLocationsFields_region_Read",
		update:"RankingLocationsFields_region_Update",
		delete:"RankingLocationsFields_region_Delete"
	},
	RankingLocationsFields_region_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_region_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_region_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_region_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_updatedAt:{
		create:"RankingLocationsFields_updatedAt_Create",
		read:"RankingLocationsFields_updatedAt_Read",
		update:"RankingLocationsFields_updatedAt_Update",
		delete:"RankingLocationsFields_updatedAt_Delete"
	},
	RankingLocationsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RankingLocationsFields_createdAt:{
		create:"RankingLocationsFields_createdAt_Create",
		read:"RankingLocationsFields_createdAt_Read",
		update:"RankingLocationsFields_createdAt_Update",
		delete:"RankingLocationsFields_createdAt_Delete"
	},
	RankingLocationsFields_createdAt_Create:{
		permission:"Boolean"
	},
	RankingLocationsFields_createdAt_Read:{
		permission:"Boolean"
	},
	RankingLocationsFields_createdAt_Update:{
		permission:"Boolean"
	},
	RankingLocationsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RankingLocationsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RankingLocationsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	account_suspend_requestsAccess:{
		fields:"AccountSuspendRequestsFields",
		create:"AccountSuspendRequestsCreateAccess",
		read:"AccountSuspendRequestsReadAccess",
		update:"AccountSuspendRequestsUpdateAccess",
		delete:"AccountSuspendRequestsDeleteAccess"
	},
	AccountSuspendRequestsFields:{
		user:"AccountSuspendRequestsFields_user",
		handledBy:"AccountSuspendRequestsFields_handledBy",
		updatedAt:"AccountSuspendRequestsFields_updatedAt",
		createdAt:"AccountSuspendRequestsFields_createdAt"
	},
	AccountSuspendRequestsFields_user:{
		create:"AccountSuspendRequestsFields_user_Create",
		read:"AccountSuspendRequestsFields_user_Read",
		update:"AccountSuspendRequestsFields_user_Update",
		delete:"AccountSuspendRequestsFields_user_Delete"
	},
	AccountSuspendRequestsFields_user_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_user_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_user_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_user_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_handledBy:{
		create:"AccountSuspendRequestsFields_handledBy_Create",
		read:"AccountSuspendRequestsFields_handledBy_Read",
		update:"AccountSuspendRequestsFields_handledBy_Update",
		delete:"AccountSuspendRequestsFields_handledBy_Delete"
	},
	AccountSuspendRequestsFields_handledBy_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_handledBy_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_handledBy_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_handledBy_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_updatedAt:{
		create:"AccountSuspendRequestsFields_updatedAt_Create",
		read:"AccountSuspendRequestsFields_updatedAt_Read",
		update:"AccountSuspendRequestsFields_updatedAt_Update",
		delete:"AccountSuspendRequestsFields_updatedAt_Delete"
	},
	AccountSuspendRequestsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_createdAt:{
		create:"AccountSuspendRequestsFields_createdAt_Create",
		read:"AccountSuspendRequestsFields_createdAt_Read",
		update:"AccountSuspendRequestsFields_createdAt_Update",
		delete:"AccountSuspendRequestsFields_createdAt_Delete"
	},
	AccountSuspendRequestsFields_createdAt_Create:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_createdAt_Read:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_createdAt_Update:{
		permission:"Boolean"
	},
	AccountSuspendRequestsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AccountSuspendRequestsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AccountSuspendRequestsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	bookmarksAccess:{
		fields:"BookmarksFields",
		create:"BookmarksCreateAccess",
		read:"BookmarksReadAccess",
		update:"BookmarksUpdateAccess",
		delete:"BookmarksDeleteAccess"
	},
	BookmarksFields:{
		user:"BookmarksFields_user",
		item:"BookmarksFields_item",
		updatedAt:"BookmarksFields_updatedAt",
		createdAt:"BookmarksFields_createdAt"
	},
	BookmarksFields_user:{
		create:"BookmarksFields_user_Create",
		read:"BookmarksFields_user_Read",
		update:"BookmarksFields_user_Update",
		delete:"BookmarksFields_user_Delete"
	},
	BookmarksFields_user_Create:{
		permission:"Boolean"
	},
	BookmarksFields_user_Read:{
		permission:"Boolean"
	},
	BookmarksFields_user_Update:{
		permission:"Boolean"
	},
	BookmarksFields_user_Delete:{
		permission:"Boolean"
	},
	BookmarksFields_item:{
		create:"BookmarksFields_item_Create",
		read:"BookmarksFields_item_Read",
		update:"BookmarksFields_item_Update",
		delete:"BookmarksFields_item_Delete"
	},
	BookmarksFields_item_Create:{
		permission:"Boolean"
	},
	BookmarksFields_item_Read:{
		permission:"Boolean"
	},
	BookmarksFields_item_Update:{
		permission:"Boolean"
	},
	BookmarksFields_item_Delete:{
		permission:"Boolean"
	},
	BookmarksFields_updatedAt:{
		create:"BookmarksFields_updatedAt_Create",
		read:"BookmarksFields_updatedAt_Read",
		update:"BookmarksFields_updatedAt_Update",
		delete:"BookmarksFields_updatedAt_Delete"
	},
	BookmarksFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BookmarksFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BookmarksFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BookmarksFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BookmarksFields_createdAt:{
		create:"BookmarksFields_createdAt_Create",
		read:"BookmarksFields_createdAt_Read",
		update:"BookmarksFields_createdAt_Update",
		delete:"BookmarksFields_createdAt_Delete"
	},
	BookmarksFields_createdAt_Create:{
		permission:"Boolean"
	},
	BookmarksFields_createdAt_Read:{
		permission:"Boolean"
	},
	BookmarksFields_createdAt_Update:{
		permission:"Boolean"
	},
	BookmarksFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BookmarksCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BookmarksDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	view_recordsAccess:{
		fields:"ViewRecordsFields",
		create:"ViewRecordsCreateAccess",
		read:"ViewRecordsReadAccess",
		update:"ViewRecordsUpdateAccess",
		delete:"ViewRecordsDeleteAccess"
	},
	ViewRecordsFields:{
		user:"ViewRecordsFields_user",
		item:"ViewRecordsFields_item",
		updatedAt:"ViewRecordsFields_updatedAt",
		createdAt:"ViewRecordsFields_createdAt"
	},
	ViewRecordsFields_user:{
		create:"ViewRecordsFields_user_Create",
		read:"ViewRecordsFields_user_Read",
		update:"ViewRecordsFields_user_Update",
		delete:"ViewRecordsFields_user_Delete"
	},
	ViewRecordsFields_user_Create:{
		permission:"Boolean"
	},
	ViewRecordsFields_user_Read:{
		permission:"Boolean"
	},
	ViewRecordsFields_user_Update:{
		permission:"Boolean"
	},
	ViewRecordsFields_user_Delete:{
		permission:"Boolean"
	},
	ViewRecordsFields_item:{
		create:"ViewRecordsFields_item_Create",
		read:"ViewRecordsFields_item_Read",
		update:"ViewRecordsFields_item_Update",
		delete:"ViewRecordsFields_item_Delete"
	},
	ViewRecordsFields_item_Create:{
		permission:"Boolean"
	},
	ViewRecordsFields_item_Read:{
		permission:"Boolean"
	},
	ViewRecordsFields_item_Update:{
		permission:"Boolean"
	},
	ViewRecordsFields_item_Delete:{
		permission:"Boolean"
	},
	ViewRecordsFields_updatedAt:{
		create:"ViewRecordsFields_updatedAt_Create",
		read:"ViewRecordsFields_updatedAt_Read",
		update:"ViewRecordsFields_updatedAt_Update",
		delete:"ViewRecordsFields_updatedAt_Delete"
	},
	ViewRecordsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	ViewRecordsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	ViewRecordsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	ViewRecordsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	ViewRecordsFields_createdAt:{
		create:"ViewRecordsFields_createdAt_Create",
		read:"ViewRecordsFields_createdAt_Read",
		update:"ViewRecordsFields_createdAt_Update",
		delete:"ViewRecordsFields_createdAt_Delete"
	},
	ViewRecordsFields_createdAt_Create:{
		permission:"Boolean"
	},
	ViewRecordsFields_createdAt_Read:{
		permission:"Boolean"
	},
	ViewRecordsFields_createdAt_Update:{
		permission:"Boolean"
	},
	ViewRecordsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	ViewRecordsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	ViewRecordsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	categoryAccess:{
		fields:"CategoryFields",
		create:"CategoryCreateAccess",
		read:"CategoryReadAccess",
		update:"CategoryUpdateAccess",
		delete:"CategoryDeleteAccess"
	},
	CategoryFields:{
		name:"CategoryFields_name",
		value:"CategoryFields_value",
		tags:"CategoryFields_tags",
		icon:"CategoryFields_icon",
		region:"CategoryFields_region",
		updatedAt:"CategoryFields_updatedAt",
		createdAt:"CategoryFields_createdAt"
	},
	CategoryFields_name:{
		create:"CategoryFields_name_Create",
		read:"CategoryFields_name_Read",
		update:"CategoryFields_name_Update",
		delete:"CategoryFields_name_Delete"
	},
	CategoryFields_name_Create:{
		permission:"Boolean"
	},
	CategoryFields_name_Read:{
		permission:"Boolean"
	},
	CategoryFields_name_Update:{
		permission:"Boolean"
	},
	CategoryFields_name_Delete:{
		permission:"Boolean"
	},
	CategoryFields_value:{
		create:"CategoryFields_value_Create",
		read:"CategoryFields_value_Read",
		update:"CategoryFields_value_Update",
		delete:"CategoryFields_value_Delete"
	},
	CategoryFields_value_Create:{
		permission:"Boolean"
	},
	CategoryFields_value_Read:{
		permission:"Boolean"
	},
	CategoryFields_value_Update:{
		permission:"Boolean"
	},
	CategoryFields_value_Delete:{
		permission:"Boolean"
	},
	CategoryFields_tags:{
		create:"CategoryFields_tags_Create",
		read:"CategoryFields_tags_Read",
		update:"CategoryFields_tags_Update",
		delete:"CategoryFields_tags_Delete"
	},
	CategoryFields_tags_Create:{
		permission:"Boolean"
	},
	CategoryFields_tags_Read:{
		permission:"Boolean"
	},
	CategoryFields_tags_Update:{
		permission:"Boolean"
	},
	CategoryFields_tags_Delete:{
		permission:"Boolean"
	},
	CategoryFields_icon:{
		create:"CategoryFields_icon_Create",
		read:"CategoryFields_icon_Read",
		update:"CategoryFields_icon_Update",
		delete:"CategoryFields_icon_Delete"
	},
	CategoryFields_icon_Create:{
		permission:"Boolean"
	},
	CategoryFields_icon_Read:{
		permission:"Boolean"
	},
	CategoryFields_icon_Update:{
		permission:"Boolean"
	},
	CategoryFields_icon_Delete:{
		permission:"Boolean"
	},
	CategoryFields_region:{
		create:"CategoryFields_region_Create",
		read:"CategoryFields_region_Read",
		update:"CategoryFields_region_Update",
		delete:"CategoryFields_region_Delete"
	},
	CategoryFields_region_Create:{
		permission:"Boolean"
	},
	CategoryFields_region_Read:{
		permission:"Boolean"
	},
	CategoryFields_region_Update:{
		permission:"Boolean"
	},
	CategoryFields_region_Delete:{
		permission:"Boolean"
	},
	CategoryFields_updatedAt:{
		create:"CategoryFields_updatedAt_Create",
		read:"CategoryFields_updatedAt_Read",
		update:"CategoryFields_updatedAt_Update",
		delete:"CategoryFields_updatedAt_Delete"
	},
	CategoryFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CategoryFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CategoryFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CategoryFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CategoryFields_createdAt:{
		create:"CategoryFields_createdAt_Create",
		read:"CategoryFields_createdAt_Read",
		update:"CategoryFields_createdAt_Update",
		delete:"CategoryFields_createdAt_Delete"
	},
	CategoryFields_createdAt_Create:{
		permission:"Boolean"
	},
	CategoryFields_createdAt_Read:{
		permission:"Boolean"
	},
	CategoryFields_createdAt_Update:{
		permission:"Boolean"
	},
	CategoryFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CategoryCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CategoryDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	commentsAccess:{
		fields:"CommentsFields",
		create:"CommentsCreateAccess",
		read:"CommentsReadAccess",
		update:"CommentsUpdateAccess",
		delete:"CommentsDeleteAccess"
	},
	CommentsFields:{
		commenter:"CommentsFields_commenter",
		content:"CommentsFields_content",
		image:"CommentsFields_image",
		target:"CommentsFields_target",
		parent:"CommentsFields_parent",
		isReviewed:"CommentsFields_isReviewed",
		reviewedBy:"CommentsFields_reviewedBy",
		banned:"CommentsFields_banned",
		type:"CommentsFields_type",
		updatedAt:"CommentsFields_updatedAt",
		createdAt:"CommentsFields_createdAt"
	},
	CommentsFields_commenter:{
		create:"CommentsFields_commenter_Create",
		read:"CommentsFields_commenter_Read",
		update:"CommentsFields_commenter_Update",
		delete:"CommentsFields_commenter_Delete"
	},
	CommentsFields_commenter_Create:{
		permission:"Boolean"
	},
	CommentsFields_commenter_Read:{
		permission:"Boolean"
	},
	CommentsFields_commenter_Update:{
		permission:"Boolean"
	},
	CommentsFields_commenter_Delete:{
		permission:"Boolean"
	},
	CommentsFields_content:{
		create:"CommentsFields_content_Create",
		read:"CommentsFields_content_Read",
		update:"CommentsFields_content_Update",
		delete:"CommentsFields_content_Delete"
	},
	CommentsFields_content_Create:{
		permission:"Boolean"
	},
	CommentsFields_content_Read:{
		permission:"Boolean"
	},
	CommentsFields_content_Update:{
		permission:"Boolean"
	},
	CommentsFields_content_Delete:{
		permission:"Boolean"
	},
	CommentsFields_image:{
		create:"CommentsFields_image_Create",
		read:"CommentsFields_image_Read",
		update:"CommentsFields_image_Update",
		delete:"CommentsFields_image_Delete"
	},
	CommentsFields_image_Create:{
		permission:"Boolean"
	},
	CommentsFields_image_Read:{
		permission:"Boolean"
	},
	CommentsFields_image_Update:{
		permission:"Boolean"
	},
	CommentsFields_image_Delete:{
		permission:"Boolean"
	},
	CommentsFields_target:{
		create:"CommentsFields_target_Create",
		read:"CommentsFields_target_Read",
		update:"CommentsFields_target_Update",
		delete:"CommentsFields_target_Delete"
	},
	CommentsFields_target_Create:{
		permission:"Boolean"
	},
	CommentsFields_target_Read:{
		permission:"Boolean"
	},
	CommentsFields_target_Update:{
		permission:"Boolean"
	},
	CommentsFields_target_Delete:{
		permission:"Boolean"
	},
	CommentsFields_parent:{
		create:"CommentsFields_parent_Create",
		read:"CommentsFields_parent_Read",
		update:"CommentsFields_parent_Update",
		delete:"CommentsFields_parent_Delete"
	},
	CommentsFields_parent_Create:{
		permission:"Boolean"
	},
	CommentsFields_parent_Read:{
		permission:"Boolean"
	},
	CommentsFields_parent_Update:{
		permission:"Boolean"
	},
	CommentsFields_parent_Delete:{
		permission:"Boolean"
	},
	CommentsFields_isReviewed:{
		create:"CommentsFields_isReviewed_Create",
		read:"CommentsFields_isReviewed_Read",
		update:"CommentsFields_isReviewed_Update",
		delete:"CommentsFields_isReviewed_Delete"
	},
	CommentsFields_isReviewed_Create:{
		permission:"Boolean"
	},
	CommentsFields_isReviewed_Read:{
		permission:"Boolean"
	},
	CommentsFields_isReviewed_Update:{
		permission:"Boolean"
	},
	CommentsFields_isReviewed_Delete:{
		permission:"Boolean"
	},
	CommentsFields_reviewedBy:{
		create:"CommentsFields_reviewedBy_Create",
		read:"CommentsFields_reviewedBy_Read",
		update:"CommentsFields_reviewedBy_Update",
		delete:"CommentsFields_reviewedBy_Delete"
	},
	CommentsFields_reviewedBy_Create:{
		permission:"Boolean"
	},
	CommentsFields_reviewedBy_Read:{
		permission:"Boolean"
	},
	CommentsFields_reviewedBy_Update:{
		permission:"Boolean"
	},
	CommentsFields_reviewedBy_Delete:{
		permission:"Boolean"
	},
	CommentsFields_banned:{
		create:"CommentsFields_banned_Create",
		read:"CommentsFields_banned_Read",
		update:"CommentsFields_banned_Update",
		delete:"CommentsFields_banned_Delete"
	},
	CommentsFields_banned_Create:{
		permission:"Boolean"
	},
	CommentsFields_banned_Read:{
		permission:"Boolean"
	},
	CommentsFields_banned_Update:{
		permission:"Boolean"
	},
	CommentsFields_banned_Delete:{
		permission:"Boolean"
	},
	CommentsFields_type:{
		create:"CommentsFields_type_Create",
		read:"CommentsFields_type_Read",
		update:"CommentsFields_type_Update",
		delete:"CommentsFields_type_Delete"
	},
	CommentsFields_type_Create:{
		permission:"Boolean"
	},
	CommentsFields_type_Read:{
		permission:"Boolean"
	},
	CommentsFields_type_Update:{
		permission:"Boolean"
	},
	CommentsFields_type_Delete:{
		permission:"Boolean"
	},
	CommentsFields_updatedAt:{
		create:"CommentsFields_updatedAt_Create",
		read:"CommentsFields_updatedAt_Read",
		update:"CommentsFields_updatedAt_Update",
		delete:"CommentsFields_updatedAt_Delete"
	},
	CommentsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CommentsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CommentsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CommentsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CommentsFields_createdAt:{
		create:"CommentsFields_createdAt_Create",
		read:"CommentsFields_createdAt_Read",
		update:"CommentsFields_createdAt_Update",
		delete:"CommentsFields_createdAt_Delete"
	},
	CommentsFields_createdAt_Create:{
		permission:"Boolean"
	},
	CommentsFields_createdAt_Read:{
		permission:"Boolean"
	},
	CommentsFields_createdAt_Update:{
		permission:"Boolean"
	},
	CommentsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CommentsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CommentsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	event_calendarAccess:{
		fields:"EventCalendarFields",
		create:"EventCalendarCreateAccess",
		read:"EventCalendarReadAccess",
		update:"EventCalendarUpdateAccess",
		delete:"EventCalendarDeleteAccess"
	},
	EventCalendarFields:{
		date:"EventCalendarFields_date",
		item:"EventCalendarFields_item",
		title:"EventCalendarFields_title",
		region:"EventCalendarFields_region",
		updatedAt:"EventCalendarFields_updatedAt",
		createdAt:"EventCalendarFields_createdAt"
	},
	EventCalendarFields_date:{
		create:"EventCalendarFields_date_Create",
		read:"EventCalendarFields_date_Read",
		update:"EventCalendarFields_date_Update",
		delete:"EventCalendarFields_date_Delete"
	},
	EventCalendarFields_date_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_date_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_date_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_date_Delete:{
		permission:"Boolean"
	},
	EventCalendarFields_item:{
		create:"EventCalendarFields_item_Create",
		read:"EventCalendarFields_item_Read",
		update:"EventCalendarFields_item_Update",
		delete:"EventCalendarFields_item_Delete"
	},
	EventCalendarFields_item_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_item_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_item_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_item_Delete:{
		permission:"Boolean"
	},
	EventCalendarFields_title:{
		create:"EventCalendarFields_title_Create",
		read:"EventCalendarFields_title_Read",
		update:"EventCalendarFields_title_Update",
		delete:"EventCalendarFields_title_Delete"
	},
	EventCalendarFields_title_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_title_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_title_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_title_Delete:{
		permission:"Boolean"
	},
	EventCalendarFields_region:{
		create:"EventCalendarFields_region_Create",
		read:"EventCalendarFields_region_Read",
		update:"EventCalendarFields_region_Update",
		delete:"EventCalendarFields_region_Delete"
	},
	EventCalendarFields_region_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_region_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_region_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_region_Delete:{
		permission:"Boolean"
	},
	EventCalendarFields_updatedAt:{
		create:"EventCalendarFields_updatedAt_Create",
		read:"EventCalendarFields_updatedAt_Read",
		update:"EventCalendarFields_updatedAt_Update",
		delete:"EventCalendarFields_updatedAt_Delete"
	},
	EventCalendarFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventCalendarFields_createdAt:{
		create:"EventCalendarFields_createdAt_Create",
		read:"EventCalendarFields_createdAt_Read",
		update:"EventCalendarFields_createdAt_Update",
		delete:"EventCalendarFields_createdAt_Delete"
	},
	EventCalendarFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventCalendarFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventCalendarFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventCalendarFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventCalendarCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventCalendarDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	subscriptionsAccess:{
		fields:"SubscriptionsFields",
		create:"SubscriptionsCreateAccess",
		read:"SubscriptionsReadAccess",
		update:"SubscriptionsUpdateAccess",
		delete:"SubscriptionsDeleteAccess"
	},
	SubscriptionsFields:{
		user:"SubscriptionsFields_user",
		organiser:"SubscriptionsFields_organiser",
		updatedAt:"SubscriptionsFields_updatedAt",
		createdAt:"SubscriptionsFields_createdAt"
	},
	SubscriptionsFields_user:{
		create:"SubscriptionsFields_user_Create",
		read:"SubscriptionsFields_user_Read",
		update:"SubscriptionsFields_user_Update",
		delete:"SubscriptionsFields_user_Delete"
	},
	SubscriptionsFields_user_Create:{
		permission:"Boolean"
	},
	SubscriptionsFields_user_Read:{
		permission:"Boolean"
	},
	SubscriptionsFields_user_Update:{
		permission:"Boolean"
	},
	SubscriptionsFields_user_Delete:{
		permission:"Boolean"
	},
	SubscriptionsFields_organiser:{
		create:"SubscriptionsFields_organiser_Create",
		read:"SubscriptionsFields_organiser_Read",
		update:"SubscriptionsFields_organiser_Update",
		delete:"SubscriptionsFields_organiser_Delete"
	},
	SubscriptionsFields_organiser_Create:{
		permission:"Boolean"
	},
	SubscriptionsFields_organiser_Read:{
		permission:"Boolean"
	},
	SubscriptionsFields_organiser_Update:{
		permission:"Boolean"
	},
	SubscriptionsFields_organiser_Delete:{
		permission:"Boolean"
	},
	SubscriptionsFields_updatedAt:{
		create:"SubscriptionsFields_updatedAt_Create",
		read:"SubscriptionsFields_updatedAt_Read",
		update:"SubscriptionsFields_updatedAt_Update",
		delete:"SubscriptionsFields_updatedAt_Delete"
	},
	SubscriptionsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	SubscriptionsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	SubscriptionsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	SubscriptionsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	SubscriptionsFields_createdAt:{
		create:"SubscriptionsFields_createdAt_Create",
		read:"SubscriptionsFields_createdAt_Read",
		update:"SubscriptionsFields_createdAt_Update",
		delete:"SubscriptionsFields_createdAt_Delete"
	},
	SubscriptionsFields_createdAt_Create:{
		permission:"Boolean"
	},
	SubscriptionsFields_createdAt_Read:{
		permission:"Boolean"
	},
	SubscriptionsFields_createdAt_Update:{
		permission:"Boolean"
	},
	SubscriptionsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	SubscriptionsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	SubscriptionsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	editor_choicesAccess:{
		fields:"EditorChoicesFields",
		create:"EditorChoicesCreateAccess",
		read:"EditorChoicesReadAccess",
		update:"EditorChoicesUpdateAccess",
		delete:"EditorChoicesDeleteAccess"
	},
	EditorChoicesFields:{
		name:"EditorChoicesFields_name",
		visible:"EditorChoicesFields_visible",
		editorialPicks:"EditorChoicesFields_editorialPicks",
		list:"EditorChoicesFields_list",
		region:"EditorChoicesFields_region",
		updatedAt:"EditorChoicesFields_updatedAt",
		createdAt:"EditorChoicesFields_createdAt"
	},
	EditorChoicesFields_name:{
		create:"EditorChoicesFields_name_Create",
		read:"EditorChoicesFields_name_Read",
		update:"EditorChoicesFields_name_Update",
		delete:"EditorChoicesFields_name_Delete"
	},
	EditorChoicesFields_name_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_name_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_name_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_name_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_visible:{
		create:"EditorChoicesFields_visible_Create",
		read:"EditorChoicesFields_visible_Read",
		update:"EditorChoicesFields_visible_Update",
		delete:"EditorChoicesFields_visible_Delete"
	},
	EditorChoicesFields_visible_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_visible_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_visible_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_visible_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_editorialPicks:{
		create:"EditorChoicesFields_editorialPicks_Create",
		read:"EditorChoicesFields_editorialPicks_Read",
		update:"EditorChoicesFields_editorialPicks_Update",
		delete:"EditorChoicesFields_editorialPicks_Delete"
	},
	EditorChoicesFields_editorialPicks_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_editorialPicks_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_editorialPicks_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_editorialPicks_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_list:{
		create:"EditorChoicesFields_list_Create",
		read:"EditorChoicesFields_list_Read",
		update:"EditorChoicesFields_list_Update",
		delete:"EditorChoicesFields_list_Delete",
		fields:"EditorChoicesFields_list_Fields"
	},
	EditorChoicesFields_list_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_Fields:{
		item:"EditorChoicesFields_list_item",
		id:"EditorChoicesFields_list_id"
	},
	EditorChoicesFields_list_item:{
		create:"EditorChoicesFields_list_item_Create",
		read:"EditorChoicesFields_list_item_Read",
		update:"EditorChoicesFields_list_item_Update",
		delete:"EditorChoicesFields_list_item_Delete"
	},
	EditorChoicesFields_list_item_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_item_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_item_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_item_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_id:{
		create:"EditorChoicesFields_list_id_Create",
		read:"EditorChoicesFields_list_id_Read",
		update:"EditorChoicesFields_list_id_Update",
		delete:"EditorChoicesFields_list_id_Delete"
	},
	EditorChoicesFields_list_id_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_id_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_id_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_list_id_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_region:{
		create:"EditorChoicesFields_region_Create",
		read:"EditorChoicesFields_region_Read",
		update:"EditorChoicesFields_region_Update",
		delete:"EditorChoicesFields_region_Delete"
	},
	EditorChoicesFields_region_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_region_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_region_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_region_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_updatedAt:{
		create:"EditorChoicesFields_updatedAt_Create",
		read:"EditorChoicesFields_updatedAt_Read",
		update:"EditorChoicesFields_updatedAt_Update",
		delete:"EditorChoicesFields_updatedAt_Delete"
	},
	EditorChoicesFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EditorChoicesFields_createdAt:{
		create:"EditorChoicesFields_createdAt_Create",
		read:"EditorChoicesFields_createdAt_Read",
		update:"EditorChoicesFields_createdAt_Update",
		delete:"EditorChoicesFields_createdAt_Delete"
	},
	EditorChoicesFields_createdAt_Create:{
		permission:"Boolean"
	},
	EditorChoicesFields_createdAt_Read:{
		permission:"Boolean"
	},
	EditorChoicesFields_createdAt_Update:{
		permission:"Boolean"
	},
	EditorChoicesFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EditorChoicesCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EditorChoicesDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	banner_sectionAccess:{
		fields:"BannerSectionFields",
		create:"BannerSectionCreateAccess",
		read:"BannerSectionReadAccess",
		update:"BannerSectionUpdateAccess",
		delete:"BannerSectionDeleteAccess"
	},
	BannerSectionFields:{
		name:"BannerSectionFields_name",
		slug:"BannerSectionFields_slug",
		banners:"BannerSectionFields_banners",
		updatedAt:"BannerSectionFields_updatedAt",
		createdAt:"BannerSectionFields_createdAt"
	},
	BannerSectionFields_name:{
		create:"BannerSectionFields_name_Create",
		read:"BannerSectionFields_name_Read",
		update:"BannerSectionFields_name_Update",
		delete:"BannerSectionFields_name_Delete"
	},
	BannerSectionFields_name_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_name_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_name_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_name_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_slug:{
		create:"BannerSectionFields_slug_Create",
		read:"BannerSectionFields_slug_Read",
		update:"BannerSectionFields_slug_Update",
		delete:"BannerSectionFields_slug_Delete"
	},
	BannerSectionFields_slug_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_slug_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_slug_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_slug_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners:{
		create:"BannerSectionFields_banners_Create",
		read:"BannerSectionFields_banners_Read",
		update:"BannerSectionFields_banners_Update",
		delete:"BannerSectionFields_banners_Delete",
		fields:"BannerSectionFields_banners_Fields"
	},
	BannerSectionFields_banners_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_Fields:{
		name:"BannerSectionFields_banners_name",
		status:"BannerSectionFields_banners_status",
		desktop:"BannerSectionFields_banners_desktop",
		bannerStartDate:"BannerSectionFields_banners_bannerStartDate",
		bannerEndDate:"BannerSectionFields_banners_bannerEndDate",
		region:"BannerSectionFields_banners_region",
		id:"BannerSectionFields_banners_id"
	},
	BannerSectionFields_banners_name:{
		create:"BannerSectionFields_banners_name_Create",
		read:"BannerSectionFields_banners_name_Read",
		update:"BannerSectionFields_banners_name_Update",
		delete:"BannerSectionFields_banners_name_Delete"
	},
	BannerSectionFields_banners_name_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_name_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_name_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_name_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_status:{
		create:"BannerSectionFields_banners_status_Create",
		read:"BannerSectionFields_banners_status_Read",
		update:"BannerSectionFields_banners_status_Update",
		delete:"BannerSectionFields_banners_status_Delete"
	},
	BannerSectionFields_banners_status_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_status_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_status_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_status_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_desktop:{
		create:"BannerSectionFields_banners_desktop_Create",
		read:"BannerSectionFields_banners_desktop_Read",
		update:"BannerSectionFields_banners_desktop_Update",
		delete:"BannerSectionFields_banners_desktop_Delete"
	},
	BannerSectionFields_banners_desktop_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_desktop_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_desktop_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_desktop_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerStartDate:{
		create:"BannerSectionFields_banners_bannerStartDate_Create",
		read:"BannerSectionFields_banners_bannerStartDate_Read",
		update:"BannerSectionFields_banners_bannerStartDate_Update",
		delete:"BannerSectionFields_banners_bannerStartDate_Delete"
	},
	BannerSectionFields_banners_bannerStartDate_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerStartDate_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerStartDate_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerEndDate:{
		create:"BannerSectionFields_banners_bannerEndDate_Create",
		read:"BannerSectionFields_banners_bannerEndDate_Read",
		update:"BannerSectionFields_banners_bannerEndDate_Update",
		delete:"BannerSectionFields_banners_bannerEndDate_Delete"
	},
	BannerSectionFields_banners_bannerEndDate_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerEndDate_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerEndDate_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_region:{
		create:"BannerSectionFields_banners_region_Create",
		read:"BannerSectionFields_banners_region_Read",
		update:"BannerSectionFields_banners_region_Update",
		delete:"BannerSectionFields_banners_region_Delete"
	},
	BannerSectionFields_banners_region_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_region_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_region_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_region_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_id:{
		create:"BannerSectionFields_banners_id_Create",
		read:"BannerSectionFields_banners_id_Read",
		update:"BannerSectionFields_banners_id_Update",
		delete:"BannerSectionFields_banners_id_Delete"
	},
	BannerSectionFields_banners_id_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_id_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_id_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_banners_id_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_updatedAt:{
		create:"BannerSectionFields_updatedAt_Create",
		read:"BannerSectionFields_updatedAt_Read",
		update:"BannerSectionFields_updatedAt_Update",
		delete:"BannerSectionFields_updatedAt_Delete"
	},
	BannerSectionFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BannerSectionFields_createdAt:{
		create:"BannerSectionFields_createdAt_Create",
		read:"BannerSectionFields_createdAt_Read",
		update:"BannerSectionFields_createdAt_Update",
		delete:"BannerSectionFields_createdAt_Delete"
	},
	BannerSectionFields_createdAt_Create:{
		permission:"Boolean"
	},
	BannerSectionFields_createdAt_Read:{
		permission:"Boolean"
	},
	BannerSectionFields_createdAt_Update:{
		permission:"Boolean"
	},
	BannerSectionFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BannerSectionCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannerSectionDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	macao_banner_sectionAccess:{
		fields:"MacaoBannerSectionFields",
		create:"MacaoBannerSectionCreateAccess",
		read:"MacaoBannerSectionReadAccess",
		update:"MacaoBannerSectionUpdateAccess",
		delete:"MacaoBannerSectionDeleteAccess"
	},
	MacaoBannerSectionFields:{
		name:"MacaoBannerSectionFields_name",
		slug:"MacaoBannerSectionFields_slug",
		banners:"MacaoBannerSectionFields_banners",
		updatedAt:"MacaoBannerSectionFields_updatedAt",
		createdAt:"MacaoBannerSectionFields_createdAt"
	},
	MacaoBannerSectionFields_name:{
		create:"MacaoBannerSectionFields_name_Create",
		read:"MacaoBannerSectionFields_name_Read",
		update:"MacaoBannerSectionFields_name_Update",
		delete:"MacaoBannerSectionFields_name_Delete"
	},
	MacaoBannerSectionFields_name_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_name_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_name_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_name_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_slug:{
		create:"MacaoBannerSectionFields_slug_Create",
		read:"MacaoBannerSectionFields_slug_Read",
		update:"MacaoBannerSectionFields_slug_Update",
		delete:"MacaoBannerSectionFields_slug_Delete"
	},
	MacaoBannerSectionFields_slug_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_slug_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_slug_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_slug_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners:{
		create:"MacaoBannerSectionFields_banners_Create",
		read:"MacaoBannerSectionFields_banners_Read",
		update:"MacaoBannerSectionFields_banners_Update",
		delete:"MacaoBannerSectionFields_banners_Delete",
		fields:"MacaoBannerSectionFields_banners_Fields"
	},
	MacaoBannerSectionFields_banners_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_Fields:{
		name:"MacaoBannerSectionFields_banners_name",
		status:"MacaoBannerSectionFields_banners_status",
		desktop:"MacaoBannerSectionFields_banners_desktop",
		bannerStartDate:"MacaoBannerSectionFields_banners_bannerStartDate",
		bannerEndDate:"MacaoBannerSectionFields_banners_bannerEndDate",
		region:"MacaoBannerSectionFields_banners_region",
		id:"MacaoBannerSectionFields_banners_id"
	},
	MacaoBannerSectionFields_banners_name:{
		create:"MacaoBannerSectionFields_banners_name_Create",
		read:"MacaoBannerSectionFields_banners_name_Read",
		update:"MacaoBannerSectionFields_banners_name_Update",
		delete:"MacaoBannerSectionFields_banners_name_Delete"
	},
	MacaoBannerSectionFields_banners_name_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_name_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_name_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_name_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_status:{
		create:"MacaoBannerSectionFields_banners_status_Create",
		read:"MacaoBannerSectionFields_banners_status_Read",
		update:"MacaoBannerSectionFields_banners_status_Update",
		delete:"MacaoBannerSectionFields_banners_status_Delete"
	},
	MacaoBannerSectionFields_banners_status_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_status_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_status_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_status_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_desktop:{
		create:"MacaoBannerSectionFields_banners_desktop_Create",
		read:"MacaoBannerSectionFields_banners_desktop_Read",
		update:"MacaoBannerSectionFields_banners_desktop_Update",
		delete:"MacaoBannerSectionFields_banners_desktop_Delete"
	},
	MacaoBannerSectionFields_banners_desktop_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_desktop_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_desktop_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_desktop_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerStartDate:{
		create:"MacaoBannerSectionFields_banners_bannerStartDate_Create",
		read:"MacaoBannerSectionFields_banners_bannerStartDate_Read",
		update:"MacaoBannerSectionFields_banners_bannerStartDate_Update",
		delete:"MacaoBannerSectionFields_banners_bannerStartDate_Delete"
	},
	MacaoBannerSectionFields_banners_bannerStartDate_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerStartDate_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerStartDate_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerEndDate:{
		create:"MacaoBannerSectionFields_banners_bannerEndDate_Create",
		read:"MacaoBannerSectionFields_banners_bannerEndDate_Read",
		update:"MacaoBannerSectionFields_banners_bannerEndDate_Update",
		delete:"MacaoBannerSectionFields_banners_bannerEndDate_Delete"
	},
	MacaoBannerSectionFields_banners_bannerEndDate_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerEndDate_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerEndDate_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_region:{
		create:"MacaoBannerSectionFields_banners_region_Create",
		read:"MacaoBannerSectionFields_banners_region_Read",
		update:"MacaoBannerSectionFields_banners_region_Update",
		delete:"MacaoBannerSectionFields_banners_region_Delete"
	},
	MacaoBannerSectionFields_banners_region_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_region_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_region_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_region_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_id:{
		create:"MacaoBannerSectionFields_banners_id_Create",
		read:"MacaoBannerSectionFields_banners_id_Read",
		update:"MacaoBannerSectionFields_banners_id_Update",
		delete:"MacaoBannerSectionFields_banners_id_Delete"
	},
	MacaoBannerSectionFields_banners_id_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_id_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_id_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_banners_id_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_updatedAt:{
		create:"MacaoBannerSectionFields_updatedAt_Create",
		read:"MacaoBannerSectionFields_updatedAt_Read",
		update:"MacaoBannerSectionFields_updatedAt_Update",
		delete:"MacaoBannerSectionFields_updatedAt_Delete"
	},
	MacaoBannerSectionFields_updatedAt_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_updatedAt_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_updatedAt_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_createdAt:{
		create:"MacaoBannerSectionFields_createdAt_Create",
		read:"MacaoBannerSectionFields_createdAt_Read",
		update:"MacaoBannerSectionFields_createdAt_Update",
		delete:"MacaoBannerSectionFields_createdAt_Delete"
	},
	MacaoBannerSectionFields_createdAt_Create:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_createdAt_Read:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_createdAt_Update:{
		permission:"Boolean"
	},
	MacaoBannerSectionFields_createdAt_Delete:{
		permission:"Boolean"
	},
	MacaoBannerSectionCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	MacaoBannerSectionDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	bannersAccess:{
		fields:"BannersFields",
		create:"BannersCreateAccess",
		read:"BannersReadAccess",
		update:"BannersUpdateAccess",
		delete:"BannersDeleteAccess"
	},
	BannersFields:{
		name:"BannersFields_name",
		desktop:"BannersFields_desktop",
		mobile:"BannersFields_mobile",
		bannerStartDate:"BannersFields_bannerStartDate",
		bannerEndDate:"BannersFields_bannerEndDate",
		location:"BannersFields_location",
		link:"BannersFields_link",
		external:"BannersFields_external",
		type:"BannersFields_type",
		status:"BannersFields_status",
		advertise:"BannersFields_advertise",
		_locations:"BannersFields__locations",
		region:"BannersFields_region",
		homePageClick:"BannersFields_homePageClick",
		homePageImpression:"BannersFields_homePageImpression",
		latestEventClick:"BannersFields_latestEventClick",
		latestEventImpression:"BannersFields_latestEventImpression",
		editorChoiceClick:"BannersFields_editorChoiceClick",
		editorChoiceImpression:"BannersFields_editorChoiceImpression",
		eventListClick:"BannersFields_eventListClick",
		eventListImpression:"BannersFields_eventListImpression",
		topTenClick:"BannersFields_topTenClick",
		topTenImpression:"BannersFields_topTenImpression",
		miscClick:"BannersFields_miscClick",
		miscImpression:"BannersFields_miscImpression",
		clickTotal:"BannersFields_clickTotal",
		totalImpression:"BannersFields_totalImpression",
		updatedAt:"BannersFields_updatedAt",
		createdAt:"BannersFields_createdAt"
	},
	BannersFields_name:{
		create:"BannersFields_name_Create",
		read:"BannersFields_name_Read",
		update:"BannersFields_name_Update",
		delete:"BannersFields_name_Delete"
	},
	BannersFields_name_Create:{
		permission:"Boolean"
	},
	BannersFields_name_Read:{
		permission:"Boolean"
	},
	BannersFields_name_Update:{
		permission:"Boolean"
	},
	BannersFields_name_Delete:{
		permission:"Boolean"
	},
	BannersFields_desktop:{
		create:"BannersFields_desktop_Create",
		read:"BannersFields_desktop_Read",
		update:"BannersFields_desktop_Update",
		delete:"BannersFields_desktop_Delete"
	},
	BannersFields_desktop_Create:{
		permission:"Boolean"
	},
	BannersFields_desktop_Read:{
		permission:"Boolean"
	},
	BannersFields_desktop_Update:{
		permission:"Boolean"
	},
	BannersFields_desktop_Delete:{
		permission:"Boolean"
	},
	BannersFields_mobile:{
		create:"BannersFields_mobile_Create",
		read:"BannersFields_mobile_Read",
		update:"BannersFields_mobile_Update",
		delete:"BannersFields_mobile_Delete"
	},
	BannersFields_mobile_Create:{
		permission:"Boolean"
	},
	BannersFields_mobile_Read:{
		permission:"Boolean"
	},
	BannersFields_mobile_Update:{
		permission:"Boolean"
	},
	BannersFields_mobile_Delete:{
		permission:"Boolean"
	},
	BannersFields_bannerStartDate:{
		create:"BannersFields_bannerStartDate_Create",
		read:"BannersFields_bannerStartDate_Read",
		update:"BannersFields_bannerStartDate_Update",
		delete:"BannersFields_bannerStartDate_Delete"
	},
	BannersFields_bannerStartDate_Create:{
		permission:"Boolean"
	},
	BannersFields_bannerStartDate_Read:{
		permission:"Boolean"
	},
	BannersFields_bannerStartDate_Update:{
		permission:"Boolean"
	},
	BannersFields_bannerStartDate_Delete:{
		permission:"Boolean"
	},
	BannersFields_bannerEndDate:{
		create:"BannersFields_bannerEndDate_Create",
		read:"BannersFields_bannerEndDate_Read",
		update:"BannersFields_bannerEndDate_Update",
		delete:"BannersFields_bannerEndDate_Delete"
	},
	BannersFields_bannerEndDate_Create:{
		permission:"Boolean"
	},
	BannersFields_bannerEndDate_Read:{
		permission:"Boolean"
	},
	BannersFields_bannerEndDate_Update:{
		permission:"Boolean"
	},
	BannersFields_bannerEndDate_Delete:{
		permission:"Boolean"
	},
	BannersFields_location:{
		create:"BannersFields_location_Create",
		read:"BannersFields_location_Read",
		update:"BannersFields_location_Update",
		delete:"BannersFields_location_Delete",
		fields:"BannersFields_location_Fields"
	},
	BannersFields_location_Create:{
		permission:"Boolean"
	},
	BannersFields_location_Read:{
		permission:"Boolean"
	},
	BannersFields_location_Update:{
		permission:"Boolean"
	},
	BannersFields_location_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_Fields:{
		page:"BannersFields_location_page",
		eventRankingList:"BannersFields_location_eventRankingList",
		locationRankingList:"BannersFields_location_locationRankingList",
		categories:"BannersFields_location_categories",
		time:"BannersFields_location_time",
		district:"BannersFields_location_district",
		audience:"BannersFields_location_audience",
		multipleCriteriaSearch:"BannersFields_location_multipleCriteriaSearch"
	},
	BannersFields_location_page:{
		create:"BannersFields_location_page_Create",
		read:"BannersFields_location_page_Read",
		update:"BannersFields_location_page_Update",
		delete:"BannersFields_location_page_Delete"
	},
	BannersFields_location_page_Create:{
		permission:"Boolean"
	},
	BannersFields_location_page_Read:{
		permission:"Boolean"
	},
	BannersFields_location_page_Update:{
		permission:"Boolean"
	},
	BannersFields_location_page_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_eventRankingList:{
		create:"BannersFields_location_eventRankingList_Create",
		read:"BannersFields_location_eventRankingList_Read",
		update:"BannersFields_location_eventRankingList_Update",
		delete:"BannersFields_location_eventRankingList_Delete"
	},
	BannersFields_location_eventRankingList_Create:{
		permission:"Boolean"
	},
	BannersFields_location_eventRankingList_Read:{
		permission:"Boolean"
	},
	BannersFields_location_eventRankingList_Update:{
		permission:"Boolean"
	},
	BannersFields_location_eventRankingList_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_locationRankingList:{
		create:"BannersFields_location_locationRankingList_Create",
		read:"BannersFields_location_locationRankingList_Read",
		update:"BannersFields_location_locationRankingList_Update",
		delete:"BannersFields_location_locationRankingList_Delete"
	},
	BannersFields_location_locationRankingList_Create:{
		permission:"Boolean"
	},
	BannersFields_location_locationRankingList_Read:{
		permission:"Boolean"
	},
	BannersFields_location_locationRankingList_Update:{
		permission:"Boolean"
	},
	BannersFields_location_locationRankingList_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_categories:{
		create:"BannersFields_location_categories_Create",
		read:"BannersFields_location_categories_Read",
		update:"BannersFields_location_categories_Update",
		delete:"BannersFields_location_categories_Delete"
	},
	BannersFields_location_categories_Create:{
		permission:"Boolean"
	},
	BannersFields_location_categories_Read:{
		permission:"Boolean"
	},
	BannersFields_location_categories_Update:{
		permission:"Boolean"
	},
	BannersFields_location_categories_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_time:{
		create:"BannersFields_location_time_Create",
		read:"BannersFields_location_time_Read",
		update:"BannersFields_location_time_Update",
		delete:"BannersFields_location_time_Delete"
	},
	BannersFields_location_time_Create:{
		permission:"Boolean"
	},
	BannersFields_location_time_Read:{
		permission:"Boolean"
	},
	BannersFields_location_time_Update:{
		permission:"Boolean"
	},
	BannersFields_location_time_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_district:{
		create:"BannersFields_location_district_Create",
		read:"BannersFields_location_district_Read",
		update:"BannersFields_location_district_Update",
		delete:"BannersFields_location_district_Delete"
	},
	BannersFields_location_district_Create:{
		permission:"Boolean"
	},
	BannersFields_location_district_Read:{
		permission:"Boolean"
	},
	BannersFields_location_district_Update:{
		permission:"Boolean"
	},
	BannersFields_location_district_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_audience:{
		create:"BannersFields_location_audience_Create",
		read:"BannersFields_location_audience_Read",
		update:"BannersFields_location_audience_Update",
		delete:"BannersFields_location_audience_Delete"
	},
	BannersFields_location_audience_Create:{
		permission:"Boolean"
	},
	BannersFields_location_audience_Read:{
		permission:"Boolean"
	},
	BannersFields_location_audience_Update:{
		permission:"Boolean"
	},
	BannersFields_location_audience_Delete:{
		permission:"Boolean"
	},
	BannersFields_location_multipleCriteriaSearch:{
		create:"BannersFields_location_multipleCriteriaSearch_Create",
		read:"BannersFields_location_multipleCriteriaSearch_Read",
		update:"BannersFields_location_multipleCriteriaSearch_Update",
		delete:"BannersFields_location_multipleCriteriaSearch_Delete"
	},
	BannersFields_location_multipleCriteriaSearch_Create:{
		permission:"Boolean"
	},
	BannersFields_location_multipleCriteriaSearch_Read:{
		permission:"Boolean"
	},
	BannersFields_location_multipleCriteriaSearch_Update:{
		permission:"Boolean"
	},
	BannersFields_location_multipleCriteriaSearch_Delete:{
		permission:"Boolean"
	},
	BannersFields_link:{
		create:"BannersFields_link_Create",
		read:"BannersFields_link_Read",
		update:"BannersFields_link_Update",
		delete:"BannersFields_link_Delete"
	},
	BannersFields_link_Create:{
		permission:"Boolean"
	},
	BannersFields_link_Read:{
		permission:"Boolean"
	},
	BannersFields_link_Update:{
		permission:"Boolean"
	},
	BannersFields_link_Delete:{
		permission:"Boolean"
	},
	BannersFields_external:{
		create:"BannersFields_external_Create",
		read:"BannersFields_external_Read",
		update:"BannersFields_external_Update",
		delete:"BannersFields_external_Delete"
	},
	BannersFields_external_Create:{
		permission:"Boolean"
	},
	BannersFields_external_Read:{
		permission:"Boolean"
	},
	BannersFields_external_Update:{
		permission:"Boolean"
	},
	BannersFields_external_Delete:{
		permission:"Boolean"
	},
	BannersFields_type:{
		create:"BannersFields_type_Create",
		read:"BannersFields_type_Read",
		update:"BannersFields_type_Update",
		delete:"BannersFields_type_Delete"
	},
	BannersFields_type_Create:{
		permission:"Boolean"
	},
	BannersFields_type_Read:{
		permission:"Boolean"
	},
	BannersFields_type_Update:{
		permission:"Boolean"
	},
	BannersFields_type_Delete:{
		permission:"Boolean"
	},
	BannersFields_status:{
		create:"BannersFields_status_Create",
		read:"BannersFields_status_Read",
		update:"BannersFields_status_Update",
		delete:"BannersFields_status_Delete"
	},
	BannersFields_status_Create:{
		permission:"Boolean"
	},
	BannersFields_status_Read:{
		permission:"Boolean"
	},
	BannersFields_status_Update:{
		permission:"Boolean"
	},
	BannersFields_status_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise:{
		create:"BannersFields_advertise_Create",
		read:"BannersFields_advertise_Read",
		update:"BannersFields_advertise_Update",
		delete:"BannersFields_advertise_Delete",
		fields:"BannersFields_advertise_Fields"
	},
	BannersFields_advertise_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_Fields:{
		campaign:"BannersFields_advertise_campaign",
		invoiceDescription:"BannersFields_advertise_invoiceDescription",
		standardPrice:"BannersFields_advertise_standardPrice",
		discount:"BannersFields_advertise_discount",
		timableCost:"BannersFields_advertise_timableCost",
		remark:"BannersFields_advertise_remark",
		public:"BannersFields_advertise_public"
	},
	BannersFields_advertise_campaign:{
		create:"BannersFields_advertise_campaign_Create",
		read:"BannersFields_advertise_campaign_Read",
		update:"BannersFields_advertise_campaign_Update",
		delete:"BannersFields_advertise_campaign_Delete"
	},
	BannersFields_advertise_campaign_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_campaign_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_campaign_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_campaign_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_invoiceDescription:{
		create:"BannersFields_advertise_invoiceDescription_Create",
		read:"BannersFields_advertise_invoiceDescription_Read",
		update:"BannersFields_advertise_invoiceDescription_Update",
		delete:"BannersFields_advertise_invoiceDescription_Delete"
	},
	BannersFields_advertise_invoiceDescription_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_invoiceDescription_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_invoiceDescription_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_invoiceDescription_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_standardPrice:{
		create:"BannersFields_advertise_standardPrice_Create",
		read:"BannersFields_advertise_standardPrice_Read",
		update:"BannersFields_advertise_standardPrice_Update",
		delete:"BannersFields_advertise_standardPrice_Delete"
	},
	BannersFields_advertise_standardPrice_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_standardPrice_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_standardPrice_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_standardPrice_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_discount:{
		create:"BannersFields_advertise_discount_Create",
		read:"BannersFields_advertise_discount_Read",
		update:"BannersFields_advertise_discount_Update",
		delete:"BannersFields_advertise_discount_Delete"
	},
	BannersFields_advertise_discount_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_discount_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_discount_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_discount_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_timableCost:{
		create:"BannersFields_advertise_timableCost_Create",
		read:"BannersFields_advertise_timableCost_Read",
		update:"BannersFields_advertise_timableCost_Update",
		delete:"BannersFields_advertise_timableCost_Delete"
	},
	BannersFields_advertise_timableCost_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_timableCost_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_timableCost_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_timableCost_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_remark:{
		create:"BannersFields_advertise_remark_Create",
		read:"BannersFields_advertise_remark_Read",
		update:"BannersFields_advertise_remark_Update",
		delete:"BannersFields_advertise_remark_Delete"
	},
	BannersFields_advertise_remark_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_remark_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_remark_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_remark_Delete:{
		permission:"Boolean"
	},
	BannersFields_advertise_public:{
		create:"BannersFields_advertise_public_Create",
		read:"BannersFields_advertise_public_Read",
		update:"BannersFields_advertise_public_Update",
		delete:"BannersFields_advertise_public_Delete"
	},
	BannersFields_advertise_public_Create:{
		permission:"Boolean"
	},
	BannersFields_advertise_public_Read:{
		permission:"Boolean"
	},
	BannersFields_advertise_public_Update:{
		permission:"Boolean"
	},
	BannersFields_advertise_public_Delete:{
		permission:"Boolean"
	},
	BannersFields__locations:{
		create:"BannersFields__locations_Create",
		read:"BannersFields__locations_Read",
		update:"BannersFields__locations_Update",
		delete:"BannersFields__locations_Delete"
	},
	BannersFields__locations_Create:{
		permission:"Boolean"
	},
	BannersFields__locations_Read:{
		permission:"Boolean"
	},
	BannersFields__locations_Update:{
		permission:"Boolean"
	},
	BannersFields__locations_Delete:{
		permission:"Boolean"
	},
	BannersFields_region:{
		create:"BannersFields_region_Create",
		read:"BannersFields_region_Read",
		update:"BannersFields_region_Update",
		delete:"BannersFields_region_Delete"
	},
	BannersFields_region_Create:{
		permission:"Boolean"
	},
	BannersFields_region_Read:{
		permission:"Boolean"
	},
	BannersFields_region_Update:{
		permission:"Boolean"
	},
	BannersFields_region_Delete:{
		permission:"Boolean"
	},
	BannersFields_homePageClick:{
		create:"BannersFields_homePageClick_Create",
		read:"BannersFields_homePageClick_Read",
		update:"BannersFields_homePageClick_Update",
		delete:"BannersFields_homePageClick_Delete"
	},
	BannersFields_homePageClick_Create:{
		permission:"Boolean"
	},
	BannersFields_homePageClick_Read:{
		permission:"Boolean"
	},
	BannersFields_homePageClick_Update:{
		permission:"Boolean"
	},
	BannersFields_homePageClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_homePageImpression:{
		create:"BannersFields_homePageImpression_Create",
		read:"BannersFields_homePageImpression_Read",
		update:"BannersFields_homePageImpression_Update",
		delete:"BannersFields_homePageImpression_Delete"
	},
	BannersFields_homePageImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_homePageImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_homePageImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_homePageImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_latestEventClick:{
		create:"BannersFields_latestEventClick_Create",
		read:"BannersFields_latestEventClick_Read",
		update:"BannersFields_latestEventClick_Update",
		delete:"BannersFields_latestEventClick_Delete"
	},
	BannersFields_latestEventClick_Create:{
		permission:"Boolean"
	},
	BannersFields_latestEventClick_Read:{
		permission:"Boolean"
	},
	BannersFields_latestEventClick_Update:{
		permission:"Boolean"
	},
	BannersFields_latestEventClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_latestEventImpression:{
		create:"BannersFields_latestEventImpression_Create",
		read:"BannersFields_latestEventImpression_Read",
		update:"BannersFields_latestEventImpression_Update",
		delete:"BannersFields_latestEventImpression_Delete"
	},
	BannersFields_latestEventImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_latestEventImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_latestEventImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_latestEventImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceClick:{
		create:"BannersFields_editorChoiceClick_Create",
		read:"BannersFields_editorChoiceClick_Read",
		update:"BannersFields_editorChoiceClick_Update",
		delete:"BannersFields_editorChoiceClick_Delete"
	},
	BannersFields_editorChoiceClick_Create:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceClick_Read:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceClick_Update:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceImpression:{
		create:"BannersFields_editorChoiceImpression_Create",
		read:"BannersFields_editorChoiceImpression_Read",
		update:"BannersFields_editorChoiceImpression_Update",
		delete:"BannersFields_editorChoiceImpression_Delete"
	},
	BannersFields_editorChoiceImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_editorChoiceImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_eventListClick:{
		create:"BannersFields_eventListClick_Create",
		read:"BannersFields_eventListClick_Read",
		update:"BannersFields_eventListClick_Update",
		delete:"BannersFields_eventListClick_Delete"
	},
	BannersFields_eventListClick_Create:{
		permission:"Boolean"
	},
	BannersFields_eventListClick_Read:{
		permission:"Boolean"
	},
	BannersFields_eventListClick_Update:{
		permission:"Boolean"
	},
	BannersFields_eventListClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_eventListImpression:{
		create:"BannersFields_eventListImpression_Create",
		read:"BannersFields_eventListImpression_Read",
		update:"BannersFields_eventListImpression_Update",
		delete:"BannersFields_eventListImpression_Delete"
	},
	BannersFields_eventListImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_eventListImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_eventListImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_eventListImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_topTenClick:{
		create:"BannersFields_topTenClick_Create",
		read:"BannersFields_topTenClick_Read",
		update:"BannersFields_topTenClick_Update",
		delete:"BannersFields_topTenClick_Delete"
	},
	BannersFields_topTenClick_Create:{
		permission:"Boolean"
	},
	BannersFields_topTenClick_Read:{
		permission:"Boolean"
	},
	BannersFields_topTenClick_Update:{
		permission:"Boolean"
	},
	BannersFields_topTenClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_topTenImpression:{
		create:"BannersFields_topTenImpression_Create",
		read:"BannersFields_topTenImpression_Read",
		update:"BannersFields_topTenImpression_Update",
		delete:"BannersFields_topTenImpression_Delete"
	},
	BannersFields_topTenImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_topTenImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_topTenImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_topTenImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_miscClick:{
		create:"BannersFields_miscClick_Create",
		read:"BannersFields_miscClick_Read",
		update:"BannersFields_miscClick_Update",
		delete:"BannersFields_miscClick_Delete"
	},
	BannersFields_miscClick_Create:{
		permission:"Boolean"
	},
	BannersFields_miscClick_Read:{
		permission:"Boolean"
	},
	BannersFields_miscClick_Update:{
		permission:"Boolean"
	},
	BannersFields_miscClick_Delete:{
		permission:"Boolean"
	},
	BannersFields_miscImpression:{
		create:"BannersFields_miscImpression_Create",
		read:"BannersFields_miscImpression_Read",
		update:"BannersFields_miscImpression_Update",
		delete:"BannersFields_miscImpression_Delete"
	},
	BannersFields_miscImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_miscImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_miscImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_miscImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_clickTotal:{
		create:"BannersFields_clickTotal_Create",
		read:"BannersFields_clickTotal_Read",
		update:"BannersFields_clickTotal_Update",
		delete:"BannersFields_clickTotal_Delete"
	},
	BannersFields_clickTotal_Create:{
		permission:"Boolean"
	},
	BannersFields_clickTotal_Read:{
		permission:"Boolean"
	},
	BannersFields_clickTotal_Update:{
		permission:"Boolean"
	},
	BannersFields_clickTotal_Delete:{
		permission:"Boolean"
	},
	BannersFields_totalImpression:{
		create:"BannersFields_totalImpression_Create",
		read:"BannersFields_totalImpression_Read",
		update:"BannersFields_totalImpression_Update",
		delete:"BannersFields_totalImpression_Delete"
	},
	BannersFields_totalImpression_Create:{
		permission:"Boolean"
	},
	BannersFields_totalImpression_Read:{
		permission:"Boolean"
	},
	BannersFields_totalImpression_Update:{
		permission:"Boolean"
	},
	BannersFields_totalImpression_Delete:{
		permission:"Boolean"
	},
	BannersFields_updatedAt:{
		create:"BannersFields_updatedAt_Create",
		read:"BannersFields_updatedAt_Read",
		update:"BannersFields_updatedAt_Update",
		delete:"BannersFields_updatedAt_Delete"
	},
	BannersFields_updatedAt_Create:{
		permission:"Boolean"
	},
	BannersFields_updatedAt_Read:{
		permission:"Boolean"
	},
	BannersFields_updatedAt_Update:{
		permission:"Boolean"
	},
	BannersFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	BannersFields_createdAt:{
		create:"BannersFields_createdAt_Create",
		read:"BannersFields_createdAt_Read",
		update:"BannersFields_createdAt_Update",
		delete:"BannersFields_createdAt_Delete"
	},
	BannersFields_createdAt_Create:{
		permission:"Boolean"
	},
	BannersFields_createdAt_Read:{
		permission:"Boolean"
	},
	BannersFields_createdAt_Update:{
		permission:"Boolean"
	},
	BannersFields_createdAt_Delete:{
		permission:"Boolean"
	},
	BannersCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	BannersDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	campaignsAccess:{
		fields:"CampaignsFields",
		create:"CampaignsCreateAccess",
		read:"CampaignsReadAccess",
		update:"CampaignsUpdateAccess",
		delete:"CampaignsDeleteAccess"
	},
	CampaignsFields:{
		creator:"CampaignsFields_creator",
		invoiceNumber:"CampaignsFields_invoiceNumber",
		quotationNumber:"CampaignsFields_quotationNumber",
		campaignName:"CampaignsFields_campaignName",
		client:"CampaignsFields_client",
		contactPerson:"CampaignsFields_contactPerson",
		email:"CampaignsFields_email",
		phone:"CampaignsFields_phone",
		agentName:"CampaignsFields_agentName",
		agentCommission:"CampaignsFields_agentCommission",
		agentOrderNumber:"CampaignsFields_agentOrderNumber",
		_firstPublishedTime:"CampaignsFields__firstPublishedTime",
		updatedAt:"CampaignsFields_updatedAt",
		createdAt:"CampaignsFields_createdAt"
	},
	CampaignsFields_creator:{
		create:"CampaignsFields_creator_Create",
		read:"CampaignsFields_creator_Read",
		update:"CampaignsFields_creator_Update",
		delete:"CampaignsFields_creator_Delete"
	},
	CampaignsFields_creator_Create:{
		permission:"Boolean"
	},
	CampaignsFields_creator_Read:{
		permission:"Boolean"
	},
	CampaignsFields_creator_Update:{
		permission:"Boolean"
	},
	CampaignsFields_creator_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_invoiceNumber:{
		create:"CampaignsFields_invoiceNumber_Create",
		read:"CampaignsFields_invoiceNumber_Read",
		update:"CampaignsFields_invoiceNumber_Update",
		delete:"CampaignsFields_invoiceNumber_Delete"
	},
	CampaignsFields_invoiceNumber_Create:{
		permission:"Boolean"
	},
	CampaignsFields_invoiceNumber_Read:{
		permission:"Boolean"
	},
	CampaignsFields_invoiceNumber_Update:{
		permission:"Boolean"
	},
	CampaignsFields_invoiceNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_quotationNumber:{
		create:"CampaignsFields_quotationNumber_Create",
		read:"CampaignsFields_quotationNumber_Read",
		update:"CampaignsFields_quotationNumber_Update",
		delete:"CampaignsFields_quotationNumber_Delete"
	},
	CampaignsFields_quotationNumber_Create:{
		permission:"Boolean"
	},
	CampaignsFields_quotationNumber_Read:{
		permission:"Boolean"
	},
	CampaignsFields_quotationNumber_Update:{
		permission:"Boolean"
	},
	CampaignsFields_quotationNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_campaignName:{
		create:"CampaignsFields_campaignName_Create",
		read:"CampaignsFields_campaignName_Read",
		update:"CampaignsFields_campaignName_Update",
		delete:"CampaignsFields_campaignName_Delete"
	},
	CampaignsFields_campaignName_Create:{
		permission:"Boolean"
	},
	CampaignsFields_campaignName_Read:{
		permission:"Boolean"
	},
	CampaignsFields_campaignName_Update:{
		permission:"Boolean"
	},
	CampaignsFields_campaignName_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_client:{
		create:"CampaignsFields_client_Create",
		read:"CampaignsFields_client_Read",
		update:"CampaignsFields_client_Update",
		delete:"CampaignsFields_client_Delete"
	},
	CampaignsFields_client_Create:{
		permission:"Boolean"
	},
	CampaignsFields_client_Read:{
		permission:"Boolean"
	},
	CampaignsFields_client_Update:{
		permission:"Boolean"
	},
	CampaignsFields_client_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_contactPerson:{
		create:"CampaignsFields_contactPerson_Create",
		read:"CampaignsFields_contactPerson_Read",
		update:"CampaignsFields_contactPerson_Update",
		delete:"CampaignsFields_contactPerson_Delete"
	},
	CampaignsFields_contactPerson_Create:{
		permission:"Boolean"
	},
	CampaignsFields_contactPerson_Read:{
		permission:"Boolean"
	},
	CampaignsFields_contactPerson_Update:{
		permission:"Boolean"
	},
	CampaignsFields_contactPerson_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_email:{
		create:"CampaignsFields_email_Create",
		read:"CampaignsFields_email_Read",
		update:"CampaignsFields_email_Update",
		delete:"CampaignsFields_email_Delete"
	},
	CampaignsFields_email_Create:{
		permission:"Boolean"
	},
	CampaignsFields_email_Read:{
		permission:"Boolean"
	},
	CampaignsFields_email_Update:{
		permission:"Boolean"
	},
	CampaignsFields_email_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_phone:{
		create:"CampaignsFields_phone_Create",
		read:"CampaignsFields_phone_Read",
		update:"CampaignsFields_phone_Update",
		delete:"CampaignsFields_phone_Delete"
	},
	CampaignsFields_phone_Create:{
		permission:"Boolean"
	},
	CampaignsFields_phone_Read:{
		permission:"Boolean"
	},
	CampaignsFields_phone_Update:{
		permission:"Boolean"
	},
	CampaignsFields_phone_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_agentName:{
		create:"CampaignsFields_agentName_Create",
		read:"CampaignsFields_agentName_Read",
		update:"CampaignsFields_agentName_Update",
		delete:"CampaignsFields_agentName_Delete"
	},
	CampaignsFields_agentName_Create:{
		permission:"Boolean"
	},
	CampaignsFields_agentName_Read:{
		permission:"Boolean"
	},
	CampaignsFields_agentName_Update:{
		permission:"Boolean"
	},
	CampaignsFields_agentName_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_agentCommission:{
		create:"CampaignsFields_agentCommission_Create",
		read:"CampaignsFields_agentCommission_Read",
		update:"CampaignsFields_agentCommission_Update",
		delete:"CampaignsFields_agentCommission_Delete"
	},
	CampaignsFields_agentCommission_Create:{
		permission:"Boolean"
	},
	CampaignsFields_agentCommission_Read:{
		permission:"Boolean"
	},
	CampaignsFields_agentCommission_Update:{
		permission:"Boolean"
	},
	CampaignsFields_agentCommission_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_agentOrderNumber:{
		create:"CampaignsFields_agentOrderNumber_Create",
		read:"CampaignsFields_agentOrderNumber_Read",
		update:"CampaignsFields_agentOrderNumber_Update",
		delete:"CampaignsFields_agentOrderNumber_Delete"
	},
	CampaignsFields_agentOrderNumber_Create:{
		permission:"Boolean"
	},
	CampaignsFields_agentOrderNumber_Read:{
		permission:"Boolean"
	},
	CampaignsFields_agentOrderNumber_Update:{
		permission:"Boolean"
	},
	CampaignsFields_agentOrderNumber_Delete:{
		permission:"Boolean"
	},
	CampaignsFields__firstPublishedTime:{
		create:"CampaignsFields__firstPublishedTime_Create",
		read:"CampaignsFields__firstPublishedTime_Read",
		update:"CampaignsFields__firstPublishedTime_Update",
		delete:"CampaignsFields__firstPublishedTime_Delete"
	},
	CampaignsFields__firstPublishedTime_Create:{
		permission:"Boolean"
	},
	CampaignsFields__firstPublishedTime_Read:{
		permission:"Boolean"
	},
	CampaignsFields__firstPublishedTime_Update:{
		permission:"Boolean"
	},
	CampaignsFields__firstPublishedTime_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_updatedAt:{
		create:"CampaignsFields_updatedAt_Create",
		read:"CampaignsFields_updatedAt_Read",
		update:"CampaignsFields_updatedAt_Update",
		delete:"CampaignsFields_updatedAt_Delete"
	},
	CampaignsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CampaignsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CampaignsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CampaignsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CampaignsFields_createdAt:{
		create:"CampaignsFields_createdAt_Create",
		read:"CampaignsFields_createdAt_Read",
		update:"CampaignsFields_createdAt_Update",
		delete:"CampaignsFields_createdAt_Delete"
	},
	CampaignsFields_createdAt_Create:{
		permission:"Boolean"
	},
	CampaignsFields_createdAt_Read:{
		permission:"Boolean"
	},
	CampaignsFields_createdAt_Update:{
		permission:"Boolean"
	},
	CampaignsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CampaignsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CampaignsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	advertisesAccess:{
		fields:"AdvertisesFields",
		create:"AdvertisesCreateAccess",
		read:"AdvertisesReadAccess",
		update:"AdvertisesUpdateAccess",
		delete:"AdvertisesDeleteAccess"
	},
	AdvertisesFields:{
		advertise:"AdvertisesFields_advertise",
		advertiseStartDate:"AdvertisesFields_advertiseStartDate",
		advertiseEndDate:"AdvertisesFields_advertiseEndDate",
		type:"AdvertisesFields_type",
		invoiceDescription:"AdvertisesFields_invoiceDescription",
		standardPrice:"AdvertisesFields_standardPrice",
		discount:"AdvertisesFields_discount",
		discountPercentage:"AdvertisesFields_discountPercentage",
		_calculateByDiscount:"AdvertisesFields__calculateByDiscount",
		timableCost:"AdvertisesFields_timableCost",
		remark:"AdvertisesFields_remark",
		public:"AdvertisesFields_public",
		campaignName:"AdvertisesFields_campaignName",
		campaignId:"AdvertisesFields_campaignId",
		status:"AdvertisesFields_status",
		updatedAt:"AdvertisesFields_updatedAt",
		createdAt:"AdvertisesFields_createdAt"
	},
	AdvertisesFields_advertise:{
		create:"AdvertisesFields_advertise_Create",
		read:"AdvertisesFields_advertise_Read",
		update:"AdvertisesFields_advertise_Update",
		delete:"AdvertisesFields_advertise_Delete",
		fields:"AdvertisesFields_advertise_Fields"
	},
	AdvertisesFields_advertise_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_Fields:{
		type:"AdvertisesFields_advertise_type",
		event:"AdvertisesFields_advertise_event",
		keyword:"AdvertisesFields_advertise_keyword",
		location:"AdvertisesFields_advertise_location",
		displayPosition:"AdvertisesFields_advertise_displayPosition",
		rankingListRemark:"AdvertisesFields_advertise_rankingListRemark"
	},
	AdvertisesFields_advertise_type:{
		create:"AdvertisesFields_advertise_type_Create",
		read:"AdvertisesFields_advertise_type_Read",
		update:"AdvertisesFields_advertise_type_Update",
		delete:"AdvertisesFields_advertise_type_Delete"
	},
	AdvertisesFields_advertise_type_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_type_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_type_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_type_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_event:{
		create:"AdvertisesFields_advertise_event_Create",
		read:"AdvertisesFields_advertise_event_Read",
		update:"AdvertisesFields_advertise_event_Update",
		delete:"AdvertisesFields_advertise_event_Delete"
	},
	AdvertisesFields_advertise_event_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_event_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_event_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_event_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_keyword:{
		create:"AdvertisesFields_advertise_keyword_Create",
		read:"AdvertisesFields_advertise_keyword_Read",
		update:"AdvertisesFields_advertise_keyword_Update",
		delete:"AdvertisesFields_advertise_keyword_Delete"
	},
	AdvertisesFields_advertise_keyword_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_keyword_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_keyword_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_keyword_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_location:{
		create:"AdvertisesFields_advertise_location_Create",
		read:"AdvertisesFields_advertise_location_Read",
		update:"AdvertisesFields_advertise_location_Update",
		delete:"AdvertisesFields_advertise_location_Delete"
	},
	AdvertisesFields_advertise_location_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_location_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_location_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_location_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_displayPosition:{
		create:"AdvertisesFields_advertise_displayPosition_Create",
		read:"AdvertisesFields_advertise_displayPosition_Read",
		update:"AdvertisesFields_advertise_displayPosition_Update",
		delete:"AdvertisesFields_advertise_displayPosition_Delete"
	},
	AdvertisesFields_advertise_displayPosition_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_displayPosition_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_displayPosition_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_displayPosition_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_rankingListRemark:{
		create:"AdvertisesFields_advertise_rankingListRemark_Create",
		read:"AdvertisesFields_advertise_rankingListRemark_Read",
		update:"AdvertisesFields_advertise_rankingListRemark_Update",
		delete:"AdvertisesFields_advertise_rankingListRemark_Delete"
	},
	AdvertisesFields_advertise_rankingListRemark_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_rankingListRemark_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_rankingListRemark_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertise_rankingListRemark_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseStartDate:{
		create:"AdvertisesFields_advertiseStartDate_Create",
		read:"AdvertisesFields_advertiseStartDate_Read",
		update:"AdvertisesFields_advertiseStartDate_Update",
		delete:"AdvertisesFields_advertiseStartDate_Delete"
	},
	AdvertisesFields_advertiseStartDate_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseStartDate_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseStartDate_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseStartDate_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseEndDate:{
		create:"AdvertisesFields_advertiseEndDate_Create",
		read:"AdvertisesFields_advertiseEndDate_Read",
		update:"AdvertisesFields_advertiseEndDate_Update",
		delete:"AdvertisesFields_advertiseEndDate_Delete"
	},
	AdvertisesFields_advertiseEndDate_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseEndDate_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseEndDate_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_advertiseEndDate_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_type:{
		create:"AdvertisesFields_type_Create",
		read:"AdvertisesFields_type_Read",
		update:"AdvertisesFields_type_Update",
		delete:"AdvertisesFields_type_Delete"
	},
	AdvertisesFields_type_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_type_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_type_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_type_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_invoiceDescription:{
		create:"AdvertisesFields_invoiceDescription_Create",
		read:"AdvertisesFields_invoiceDescription_Read",
		update:"AdvertisesFields_invoiceDescription_Update",
		delete:"AdvertisesFields_invoiceDescription_Delete"
	},
	AdvertisesFields_invoiceDescription_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_invoiceDescription_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_invoiceDescription_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_invoiceDescription_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_standardPrice:{
		create:"AdvertisesFields_standardPrice_Create",
		read:"AdvertisesFields_standardPrice_Read",
		update:"AdvertisesFields_standardPrice_Update",
		delete:"AdvertisesFields_standardPrice_Delete"
	},
	AdvertisesFields_standardPrice_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_standardPrice_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_standardPrice_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_standardPrice_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_discount:{
		create:"AdvertisesFields_discount_Create",
		read:"AdvertisesFields_discount_Read",
		update:"AdvertisesFields_discount_Update",
		delete:"AdvertisesFields_discount_Delete"
	},
	AdvertisesFields_discount_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_discount_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_discount_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_discount_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_discountPercentage:{
		create:"AdvertisesFields_discountPercentage_Create",
		read:"AdvertisesFields_discountPercentage_Read",
		update:"AdvertisesFields_discountPercentage_Update",
		delete:"AdvertisesFields_discountPercentage_Delete"
	},
	AdvertisesFields_discountPercentage_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_discountPercentage_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_discountPercentage_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_discountPercentage_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields__calculateByDiscount:{
		create:"AdvertisesFields__calculateByDiscount_Create",
		read:"AdvertisesFields__calculateByDiscount_Read",
		update:"AdvertisesFields__calculateByDiscount_Update",
		delete:"AdvertisesFields__calculateByDiscount_Delete"
	},
	AdvertisesFields__calculateByDiscount_Create:{
		permission:"Boolean"
	},
	AdvertisesFields__calculateByDiscount_Read:{
		permission:"Boolean"
	},
	AdvertisesFields__calculateByDiscount_Update:{
		permission:"Boolean"
	},
	AdvertisesFields__calculateByDiscount_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_timableCost:{
		create:"AdvertisesFields_timableCost_Create",
		read:"AdvertisesFields_timableCost_Read",
		update:"AdvertisesFields_timableCost_Update",
		delete:"AdvertisesFields_timableCost_Delete"
	},
	AdvertisesFields_timableCost_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_timableCost_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_timableCost_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_timableCost_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_remark:{
		create:"AdvertisesFields_remark_Create",
		read:"AdvertisesFields_remark_Read",
		update:"AdvertisesFields_remark_Update",
		delete:"AdvertisesFields_remark_Delete"
	},
	AdvertisesFields_remark_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_remark_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_remark_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_remark_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_public:{
		create:"AdvertisesFields_public_Create",
		read:"AdvertisesFields_public_Read",
		update:"AdvertisesFields_public_Update",
		delete:"AdvertisesFields_public_Delete"
	},
	AdvertisesFields_public_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_public_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_public_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_public_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignName:{
		create:"AdvertisesFields_campaignName_Create",
		read:"AdvertisesFields_campaignName_Read",
		update:"AdvertisesFields_campaignName_Update",
		delete:"AdvertisesFields_campaignName_Delete"
	},
	AdvertisesFields_campaignName_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignName_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignName_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignName_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignId:{
		create:"AdvertisesFields_campaignId_Create",
		read:"AdvertisesFields_campaignId_Read",
		update:"AdvertisesFields_campaignId_Update",
		delete:"AdvertisesFields_campaignId_Delete"
	},
	AdvertisesFields_campaignId_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignId_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignId_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_campaignId_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_status:{
		create:"AdvertisesFields_status_Create",
		read:"AdvertisesFields_status_Read",
		update:"AdvertisesFields_status_Update",
		delete:"AdvertisesFields_status_Delete"
	},
	AdvertisesFields_status_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_status_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_status_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_status_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_updatedAt:{
		create:"AdvertisesFields_updatedAt_Create",
		read:"AdvertisesFields_updatedAt_Read",
		update:"AdvertisesFields_updatedAt_Update",
		delete:"AdvertisesFields_updatedAt_Delete"
	},
	AdvertisesFields_updatedAt_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_updatedAt_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_updatedAt_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	AdvertisesFields_createdAt:{
		create:"AdvertisesFields_createdAt_Create",
		read:"AdvertisesFields_createdAt_Read",
		update:"AdvertisesFields_createdAt_Update",
		delete:"AdvertisesFields_createdAt_Delete"
	},
	AdvertisesFields_createdAt_Create:{
		permission:"Boolean"
	},
	AdvertisesFields_createdAt_Read:{
		permission:"Boolean"
	},
	AdvertisesFields_createdAt_Update:{
		permission:"Boolean"
	},
	AdvertisesFields_createdAt_Delete:{
		permission:"Boolean"
	},
	AdvertisesCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	AdvertisesDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	devicesAccess:{
		fields:"DevicesFields",
		create:"DevicesCreateAccess",
		read:"DevicesReadAccess",
		update:"DevicesUpdateAccess",
		delete:"DevicesDeleteAccess"
	},
	DevicesFields:{
		user:"DevicesFields_user",
		udid:"DevicesFields_udid",
		pushToken:"DevicesFields_pushToken",
		updatedAt:"DevicesFields_updatedAt",
		createdAt:"DevicesFields_createdAt"
	},
	DevicesFields_user:{
		create:"DevicesFields_user_Create",
		read:"DevicesFields_user_Read",
		update:"DevicesFields_user_Update",
		delete:"DevicesFields_user_Delete"
	},
	DevicesFields_user_Create:{
		permission:"Boolean"
	},
	DevicesFields_user_Read:{
		permission:"Boolean"
	},
	DevicesFields_user_Update:{
		permission:"Boolean"
	},
	DevicesFields_user_Delete:{
		permission:"Boolean"
	},
	DevicesFields_udid:{
		create:"DevicesFields_udid_Create",
		read:"DevicesFields_udid_Read",
		update:"DevicesFields_udid_Update",
		delete:"DevicesFields_udid_Delete"
	},
	DevicesFields_udid_Create:{
		permission:"Boolean"
	},
	DevicesFields_udid_Read:{
		permission:"Boolean"
	},
	DevicesFields_udid_Update:{
		permission:"Boolean"
	},
	DevicesFields_udid_Delete:{
		permission:"Boolean"
	},
	DevicesFields_pushToken:{
		create:"DevicesFields_pushToken_Create",
		read:"DevicesFields_pushToken_Read",
		update:"DevicesFields_pushToken_Update",
		delete:"DevicesFields_pushToken_Delete"
	},
	DevicesFields_pushToken_Create:{
		permission:"Boolean"
	},
	DevicesFields_pushToken_Read:{
		permission:"Boolean"
	},
	DevicesFields_pushToken_Update:{
		permission:"Boolean"
	},
	DevicesFields_pushToken_Delete:{
		permission:"Boolean"
	},
	DevicesFields_updatedAt:{
		create:"DevicesFields_updatedAt_Create",
		read:"DevicesFields_updatedAt_Read",
		update:"DevicesFields_updatedAt_Update",
		delete:"DevicesFields_updatedAt_Delete"
	},
	DevicesFields_updatedAt_Create:{
		permission:"Boolean"
	},
	DevicesFields_updatedAt_Read:{
		permission:"Boolean"
	},
	DevicesFields_updatedAt_Update:{
		permission:"Boolean"
	},
	DevicesFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	DevicesFields_createdAt:{
		create:"DevicesFields_createdAt_Create",
		read:"DevicesFields_createdAt_Read",
		update:"DevicesFields_createdAt_Update",
		delete:"DevicesFields_createdAt_Delete"
	},
	DevicesFields_createdAt_Create:{
		permission:"Boolean"
	},
	DevicesFields_createdAt_Read:{
		permission:"Boolean"
	},
	DevicesFields_createdAt_Update:{
		permission:"Boolean"
	},
	DevicesFields_createdAt_Delete:{
		permission:"Boolean"
	},
	DevicesCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	DevicesDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	notificationsAccess:{
		fields:"NotificationsFields",
		create:"NotificationsCreateAccess",
		read:"NotificationsReadAccess",
		update:"NotificationsUpdateAccess",
		delete:"NotificationsDeleteAccess"
	},
	NotificationsFields:{
		receiver:"NotificationsFields_receiver",
		type:"NotificationsFields_type",
		meta:"NotificationsFields_meta",
		message:"NotificationsFields_message",
		sender:"NotificationsFields_sender",
		readed:"NotificationsFields_readed",
		deletedByUser:"NotificationsFields_deletedByUser",
		updatedAt:"NotificationsFields_updatedAt",
		createdAt:"NotificationsFields_createdAt"
	},
	NotificationsFields_receiver:{
		create:"NotificationsFields_receiver_Create",
		read:"NotificationsFields_receiver_Read",
		update:"NotificationsFields_receiver_Update",
		delete:"NotificationsFields_receiver_Delete"
	},
	NotificationsFields_receiver_Create:{
		permission:"Boolean"
	},
	NotificationsFields_receiver_Read:{
		permission:"Boolean"
	},
	NotificationsFields_receiver_Update:{
		permission:"Boolean"
	},
	NotificationsFields_receiver_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_type:{
		create:"NotificationsFields_type_Create",
		read:"NotificationsFields_type_Read",
		update:"NotificationsFields_type_Update",
		delete:"NotificationsFields_type_Delete"
	},
	NotificationsFields_type_Create:{
		permission:"Boolean"
	},
	NotificationsFields_type_Read:{
		permission:"Boolean"
	},
	NotificationsFields_type_Update:{
		permission:"Boolean"
	},
	NotificationsFields_type_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_meta:{
		create:"NotificationsFields_meta_Create",
		read:"NotificationsFields_meta_Read",
		update:"NotificationsFields_meta_Update",
		delete:"NotificationsFields_meta_Delete"
	},
	NotificationsFields_meta_Create:{
		permission:"Boolean"
	},
	NotificationsFields_meta_Read:{
		permission:"Boolean"
	},
	NotificationsFields_meta_Update:{
		permission:"Boolean"
	},
	NotificationsFields_meta_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_message:{
		create:"NotificationsFields_message_Create",
		read:"NotificationsFields_message_Read",
		update:"NotificationsFields_message_Update",
		delete:"NotificationsFields_message_Delete"
	},
	NotificationsFields_message_Create:{
		permission:"Boolean"
	},
	NotificationsFields_message_Read:{
		permission:"Boolean"
	},
	NotificationsFields_message_Update:{
		permission:"Boolean"
	},
	NotificationsFields_message_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_sender:{
		create:"NotificationsFields_sender_Create",
		read:"NotificationsFields_sender_Read",
		update:"NotificationsFields_sender_Update",
		delete:"NotificationsFields_sender_Delete"
	},
	NotificationsFields_sender_Create:{
		permission:"Boolean"
	},
	NotificationsFields_sender_Read:{
		permission:"Boolean"
	},
	NotificationsFields_sender_Update:{
		permission:"Boolean"
	},
	NotificationsFields_sender_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_readed:{
		create:"NotificationsFields_readed_Create",
		read:"NotificationsFields_readed_Read",
		update:"NotificationsFields_readed_Update",
		delete:"NotificationsFields_readed_Delete"
	},
	NotificationsFields_readed_Create:{
		permission:"Boolean"
	},
	NotificationsFields_readed_Read:{
		permission:"Boolean"
	},
	NotificationsFields_readed_Update:{
		permission:"Boolean"
	},
	NotificationsFields_readed_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_deletedByUser:{
		create:"NotificationsFields_deletedByUser_Create",
		read:"NotificationsFields_deletedByUser_Read",
		update:"NotificationsFields_deletedByUser_Update",
		delete:"NotificationsFields_deletedByUser_Delete"
	},
	NotificationsFields_deletedByUser_Create:{
		permission:"Boolean"
	},
	NotificationsFields_deletedByUser_Read:{
		permission:"Boolean"
	},
	NotificationsFields_deletedByUser_Update:{
		permission:"Boolean"
	},
	NotificationsFields_deletedByUser_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_updatedAt:{
		create:"NotificationsFields_updatedAt_Create",
		read:"NotificationsFields_updatedAt_Read",
		update:"NotificationsFields_updatedAt_Update",
		delete:"NotificationsFields_updatedAt_Delete"
	},
	NotificationsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	NotificationsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	NotificationsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	NotificationsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	NotificationsFields_createdAt:{
		create:"NotificationsFields_createdAt_Create",
		read:"NotificationsFields_createdAt_Read",
		update:"NotificationsFields_createdAt_Update",
		delete:"NotificationsFields_createdAt_Delete"
	},
	NotificationsFields_createdAt_Create:{
		permission:"Boolean"
	},
	NotificationsFields_createdAt_Read:{
		permission:"Boolean"
	},
	NotificationsFields_createdAt_Update:{
		permission:"Boolean"
	},
	NotificationsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	NotificationsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NotificationsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	newsletterAccess:{
		fields:"NewsletterFields",
		create:"NewsletterCreateAccess",
		read:"NewsletterReadAccess",
		update:"NewsletterUpdateAccess",
		delete:"NewsletterDeleteAccess"
	},
	NewsletterFields:{
		email:"NewsletterFields_email",
		region:"NewsletterFields_region",
		updatedAt:"NewsletterFields_updatedAt",
		createdAt:"NewsletterFields_createdAt"
	},
	NewsletterFields_email:{
		create:"NewsletterFields_email_Create",
		read:"NewsletterFields_email_Read",
		update:"NewsletterFields_email_Update",
		delete:"NewsletterFields_email_Delete"
	},
	NewsletterFields_email_Create:{
		permission:"Boolean"
	},
	NewsletterFields_email_Read:{
		permission:"Boolean"
	},
	NewsletterFields_email_Update:{
		permission:"Boolean"
	},
	NewsletterFields_email_Delete:{
		permission:"Boolean"
	},
	NewsletterFields_region:{
		create:"NewsletterFields_region_Create",
		read:"NewsletterFields_region_Read",
		update:"NewsletterFields_region_Update",
		delete:"NewsletterFields_region_Delete"
	},
	NewsletterFields_region_Create:{
		permission:"Boolean"
	},
	NewsletterFields_region_Read:{
		permission:"Boolean"
	},
	NewsletterFields_region_Update:{
		permission:"Boolean"
	},
	NewsletterFields_region_Delete:{
		permission:"Boolean"
	},
	NewsletterFields_updatedAt:{
		create:"NewsletterFields_updatedAt_Create",
		read:"NewsletterFields_updatedAt_Read",
		update:"NewsletterFields_updatedAt_Update",
		delete:"NewsletterFields_updatedAt_Delete"
	},
	NewsletterFields_updatedAt_Create:{
		permission:"Boolean"
	},
	NewsletterFields_updatedAt_Read:{
		permission:"Boolean"
	},
	NewsletterFields_updatedAt_Update:{
		permission:"Boolean"
	},
	NewsletterFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	NewsletterFields_createdAt:{
		create:"NewsletterFields_createdAt_Create",
		read:"NewsletterFields_createdAt_Read",
		update:"NewsletterFields_createdAt_Update",
		delete:"NewsletterFields_createdAt_Delete"
	},
	NewsletterFields_createdAt_Create:{
		permission:"Boolean"
	},
	NewsletterFields_createdAt_Read:{
		permission:"Boolean"
	},
	NewsletterFields_createdAt_Update:{
		permission:"Boolean"
	},
	NewsletterFields_createdAt_Delete:{
		permission:"Boolean"
	},
	NewsletterCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	NewsletterDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	redirectSettingAccess:{
		fields:"RedirectSettingFields",
		create:"RedirectSettingCreateAccess",
		read:"RedirectSettingReadAccess",
		update:"RedirectSettingUpdateAccess",
		delete:"RedirectSettingDeleteAccess"
	},
	RedirectSettingFields:{
		from:"RedirectSettingFields_from",
		to:"RedirectSettingFields_to",
		updatedAt:"RedirectSettingFields_updatedAt",
		createdAt:"RedirectSettingFields_createdAt"
	},
	RedirectSettingFields_from:{
		create:"RedirectSettingFields_from_Create",
		read:"RedirectSettingFields_from_Read",
		update:"RedirectSettingFields_from_Update",
		delete:"RedirectSettingFields_from_Delete"
	},
	RedirectSettingFields_from_Create:{
		permission:"Boolean"
	},
	RedirectSettingFields_from_Read:{
		permission:"Boolean"
	},
	RedirectSettingFields_from_Update:{
		permission:"Boolean"
	},
	RedirectSettingFields_from_Delete:{
		permission:"Boolean"
	},
	RedirectSettingFields_to:{
		create:"RedirectSettingFields_to_Create",
		read:"RedirectSettingFields_to_Read",
		update:"RedirectSettingFields_to_Update",
		delete:"RedirectSettingFields_to_Delete"
	},
	RedirectSettingFields_to_Create:{
		permission:"Boolean"
	},
	RedirectSettingFields_to_Read:{
		permission:"Boolean"
	},
	RedirectSettingFields_to_Update:{
		permission:"Boolean"
	},
	RedirectSettingFields_to_Delete:{
		permission:"Boolean"
	},
	RedirectSettingFields_updatedAt:{
		create:"RedirectSettingFields_updatedAt_Create",
		read:"RedirectSettingFields_updatedAt_Read",
		update:"RedirectSettingFields_updatedAt_Update",
		delete:"RedirectSettingFields_updatedAt_Delete"
	},
	RedirectSettingFields_updatedAt_Create:{
		permission:"Boolean"
	},
	RedirectSettingFields_updatedAt_Read:{
		permission:"Boolean"
	},
	RedirectSettingFields_updatedAt_Update:{
		permission:"Boolean"
	},
	RedirectSettingFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	RedirectSettingFields_createdAt:{
		create:"RedirectSettingFields_createdAt_Create",
		read:"RedirectSettingFields_createdAt_Read",
		update:"RedirectSettingFields_createdAt_Update",
		delete:"RedirectSettingFields_createdAt_Delete"
	},
	RedirectSettingFields_createdAt_Create:{
		permission:"Boolean"
	},
	RedirectSettingFields_createdAt_Read:{
		permission:"Boolean"
	},
	RedirectSettingFields_createdAt_Update:{
		permission:"Boolean"
	},
	RedirectSettingFields_createdAt_Delete:{
		permission:"Boolean"
	},
	RedirectSettingCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	RedirectSettingDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	staticsAccess:{
		fields:"StaticsFields",
		create:"StaticsCreateAccess",
		read:"StaticsReadAccess",
		update:"StaticsUpdateAccess",
		delete:"StaticsDeleteAccess"
	},
	StaticsFields:{
		name:"StaticsFields_name",
		key:"StaticsFields_key",
		content:"StaticsFields_content",
		region:"StaticsFields_region",
		updatedAt:"StaticsFields_updatedAt",
		createdAt:"StaticsFields_createdAt"
	},
	StaticsFields_name:{
		create:"StaticsFields_name_Create",
		read:"StaticsFields_name_Read",
		update:"StaticsFields_name_Update",
		delete:"StaticsFields_name_Delete"
	},
	StaticsFields_name_Create:{
		permission:"Boolean"
	},
	StaticsFields_name_Read:{
		permission:"Boolean"
	},
	StaticsFields_name_Update:{
		permission:"Boolean"
	},
	StaticsFields_name_Delete:{
		permission:"Boolean"
	},
	StaticsFields_key:{
		create:"StaticsFields_key_Create",
		read:"StaticsFields_key_Read",
		update:"StaticsFields_key_Update",
		delete:"StaticsFields_key_Delete"
	},
	StaticsFields_key_Create:{
		permission:"Boolean"
	},
	StaticsFields_key_Read:{
		permission:"Boolean"
	},
	StaticsFields_key_Update:{
		permission:"Boolean"
	},
	StaticsFields_key_Delete:{
		permission:"Boolean"
	},
	StaticsFields_content:{
		create:"StaticsFields_content_Create",
		read:"StaticsFields_content_Read",
		update:"StaticsFields_content_Update",
		delete:"StaticsFields_content_Delete"
	},
	StaticsFields_content_Create:{
		permission:"Boolean"
	},
	StaticsFields_content_Read:{
		permission:"Boolean"
	},
	StaticsFields_content_Update:{
		permission:"Boolean"
	},
	StaticsFields_content_Delete:{
		permission:"Boolean"
	},
	StaticsFields_region:{
		create:"StaticsFields_region_Create",
		read:"StaticsFields_region_Read",
		update:"StaticsFields_region_Update",
		delete:"StaticsFields_region_Delete"
	},
	StaticsFields_region_Create:{
		permission:"Boolean"
	},
	StaticsFields_region_Read:{
		permission:"Boolean"
	},
	StaticsFields_region_Update:{
		permission:"Boolean"
	},
	StaticsFields_region_Delete:{
		permission:"Boolean"
	},
	StaticsFields_updatedAt:{
		create:"StaticsFields_updatedAt_Create",
		read:"StaticsFields_updatedAt_Read",
		update:"StaticsFields_updatedAt_Update",
		delete:"StaticsFields_updatedAt_Delete"
	},
	StaticsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	StaticsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	StaticsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	StaticsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	StaticsFields_createdAt:{
		create:"StaticsFields_createdAt_Create",
		read:"StaticsFields_createdAt_Read",
		update:"StaticsFields_createdAt_Update",
		delete:"StaticsFields_createdAt_Delete"
	},
	StaticsFields_createdAt_Create:{
		permission:"Boolean"
	},
	StaticsFields_createdAt_Read:{
		permission:"Boolean"
	},
	StaticsFields_createdAt_Update:{
		permission:"Boolean"
	},
	StaticsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	StaticsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	StaticsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	criteria_settingAccess:{
		fields:"CriteriaSettingFields",
		create:"CriteriaSettingCreateAccess",
		read:"CriteriaSettingReadAccess",
		update:"CriteriaSettingUpdateAccess",
		delete:"CriteriaSettingDeleteAccess"
	},
	CriteriaSettingFields:{
		criteria:"CriteriaSettingFields_criteria",
		updatedAt:"CriteriaSettingFields_updatedAt",
		createdAt:"CriteriaSettingFields_createdAt"
	},
	CriteriaSettingFields_criteria:{
		create:"CriteriaSettingFields_criteria_Create",
		read:"CriteriaSettingFields_criteria_Read",
		update:"CriteriaSettingFields_criteria_Update",
		delete:"CriteriaSettingFields_criteria_Delete",
		fields:"CriteriaSettingFields_criteria_Fields"
	},
	CriteriaSettingFields_criteria_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_Fields:{
		date:"CriteriaSettingFields_criteria_date",
		audience:"CriteriaSettingFields_criteria_audience",
		category:"CriteriaSettingFields_criteria_category",
		tag:"CriteriaSettingFields_criteria_tag",
		region:"CriteriaSettingFields_criteria_region"
	},
	CriteriaSettingFields_criteria_date:{
		create:"CriteriaSettingFields_criteria_date_Create",
		read:"CriteriaSettingFields_criteria_date_Read",
		update:"CriteriaSettingFields_criteria_date_Update",
		delete:"CriteriaSettingFields_criteria_date_Delete"
	},
	CriteriaSettingFields_criteria_date_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_date_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_date_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_date_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_audience:{
		create:"CriteriaSettingFields_criteria_audience_Create",
		read:"CriteriaSettingFields_criteria_audience_Read",
		update:"CriteriaSettingFields_criteria_audience_Update",
		delete:"CriteriaSettingFields_criteria_audience_Delete"
	},
	CriteriaSettingFields_criteria_audience_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_audience_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_audience_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_audience_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_category:{
		create:"CriteriaSettingFields_criteria_category_Create",
		read:"CriteriaSettingFields_criteria_category_Read",
		update:"CriteriaSettingFields_criteria_category_Update",
		delete:"CriteriaSettingFields_criteria_category_Delete"
	},
	CriteriaSettingFields_criteria_category_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_category_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_category_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_category_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_tag:{
		create:"CriteriaSettingFields_criteria_tag_Create",
		read:"CriteriaSettingFields_criteria_tag_Read",
		update:"CriteriaSettingFields_criteria_tag_Update",
		delete:"CriteriaSettingFields_criteria_tag_Delete"
	},
	CriteriaSettingFields_criteria_tag_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_tag_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_tag_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_tag_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_region:{
		create:"CriteriaSettingFields_criteria_region_Create",
		read:"CriteriaSettingFields_criteria_region_Read",
		update:"CriteriaSettingFields_criteria_region_Update",
		delete:"CriteriaSettingFields_criteria_region_Delete"
	},
	CriteriaSettingFields_criteria_region_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_region_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_region_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_criteria_region_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_updatedAt:{
		create:"CriteriaSettingFields_updatedAt_Create",
		read:"CriteriaSettingFields_updatedAt_Read",
		update:"CriteriaSettingFields_updatedAt_Update",
		delete:"CriteriaSettingFields_updatedAt_Delete"
	},
	CriteriaSettingFields_updatedAt_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_updatedAt_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_updatedAt_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingFields_createdAt:{
		create:"CriteriaSettingFields_createdAt_Create",
		read:"CriteriaSettingFields_createdAt_Read",
		update:"CriteriaSettingFields_createdAt_Update",
		delete:"CriteriaSettingFields_createdAt_Delete"
	},
	CriteriaSettingFields_createdAt_Create:{
		permission:"Boolean"
	},
	CriteriaSettingFields_createdAt_Read:{
		permission:"Boolean"
	},
	CriteriaSettingFields_createdAt_Update:{
		permission:"Boolean"
	},
	CriteriaSettingFields_createdAt_Delete:{
		permission:"Boolean"
	},
	CriteriaSettingCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	CriteriaSettingDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	organiserSettingAccess:{
		fields:"OrganiserSettingFields",
		create:"OrganiserSettingCreateAccess",
		read:"OrganiserSettingReadAccess",
		update:"OrganiserSettingUpdateAccess",
		delete:"OrganiserSettingDeleteAccess"
	},
	OrganiserSettingFields:{
		name:"OrganiserSettingFields_name",
		organiserList:"OrganiserSettingFields_organiserList",
		region:"OrganiserSettingFields_region",
		updatedAt:"OrganiserSettingFields_updatedAt",
		createdAt:"OrganiserSettingFields_createdAt"
	},
	OrganiserSettingFields_name:{
		create:"OrganiserSettingFields_name_Create",
		read:"OrganiserSettingFields_name_Read",
		update:"OrganiserSettingFields_name_Update",
		delete:"OrganiserSettingFields_name_Delete"
	},
	OrganiserSettingFields_name_Create:{
		permission:"Boolean"
	},
	OrganiserSettingFields_name_Read:{
		permission:"Boolean"
	},
	OrganiserSettingFields_name_Update:{
		permission:"Boolean"
	},
	OrganiserSettingFields_name_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingFields_organiserList:{
		create:"OrganiserSettingFields_organiserList_Create",
		read:"OrganiserSettingFields_organiserList_Read",
		update:"OrganiserSettingFields_organiserList_Update",
		delete:"OrganiserSettingFields_organiserList_Delete"
	},
	OrganiserSettingFields_organiserList_Create:{
		permission:"Boolean"
	},
	OrganiserSettingFields_organiserList_Read:{
		permission:"Boolean"
	},
	OrganiserSettingFields_organiserList_Update:{
		permission:"Boolean"
	},
	OrganiserSettingFields_organiserList_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingFields_region:{
		create:"OrganiserSettingFields_region_Create",
		read:"OrganiserSettingFields_region_Read",
		update:"OrganiserSettingFields_region_Update",
		delete:"OrganiserSettingFields_region_Delete"
	},
	OrganiserSettingFields_region_Create:{
		permission:"Boolean"
	},
	OrganiserSettingFields_region_Read:{
		permission:"Boolean"
	},
	OrganiserSettingFields_region_Update:{
		permission:"Boolean"
	},
	OrganiserSettingFields_region_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingFields_updatedAt:{
		create:"OrganiserSettingFields_updatedAt_Create",
		read:"OrganiserSettingFields_updatedAt_Read",
		update:"OrganiserSettingFields_updatedAt_Update",
		delete:"OrganiserSettingFields_updatedAt_Delete"
	},
	OrganiserSettingFields_updatedAt_Create:{
		permission:"Boolean"
	},
	OrganiserSettingFields_updatedAt_Read:{
		permission:"Boolean"
	},
	OrganiserSettingFields_updatedAt_Update:{
		permission:"Boolean"
	},
	OrganiserSettingFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingFields_createdAt:{
		create:"OrganiserSettingFields_createdAt_Create",
		read:"OrganiserSettingFields_createdAt_Read",
		update:"OrganiserSettingFields_createdAt_Update",
		delete:"OrganiserSettingFields_createdAt_Delete"
	},
	OrganiserSettingFields_createdAt_Create:{
		permission:"Boolean"
	},
	OrganiserSettingFields_createdAt_Read:{
		permission:"Boolean"
	},
	OrganiserSettingFields_createdAt_Update:{
		permission:"Boolean"
	},
	OrganiserSettingFields_createdAt_Delete:{
		permission:"Boolean"
	},
	OrganiserSettingCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	OrganiserSettingDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	post_settingAccess:{
		fields:"PostSettingFields",
		create:"PostSettingCreateAccess",
		read:"PostSettingReadAccess",
		update:"PostSettingUpdateAccess",
		delete:"PostSettingDeleteAccess"
	},
	PostSettingFields:{
		name:"PostSettingFields_name",
		featuredPosts:"PostSettingFields_featuredPosts",
		region:"PostSettingFields_region",
		updatedAt:"PostSettingFields_updatedAt",
		createdAt:"PostSettingFields_createdAt"
	},
	PostSettingFields_name:{
		create:"PostSettingFields_name_Create",
		read:"PostSettingFields_name_Read",
		update:"PostSettingFields_name_Update",
		delete:"PostSettingFields_name_Delete"
	},
	PostSettingFields_name_Create:{
		permission:"Boolean"
	},
	PostSettingFields_name_Read:{
		permission:"Boolean"
	},
	PostSettingFields_name_Update:{
		permission:"Boolean"
	},
	PostSettingFields_name_Delete:{
		permission:"Boolean"
	},
	PostSettingFields_featuredPosts:{
		create:"PostSettingFields_featuredPosts_Create",
		read:"PostSettingFields_featuredPosts_Read",
		update:"PostSettingFields_featuredPosts_Update",
		delete:"PostSettingFields_featuredPosts_Delete"
	},
	PostSettingFields_featuredPosts_Create:{
		permission:"Boolean"
	},
	PostSettingFields_featuredPosts_Read:{
		permission:"Boolean"
	},
	PostSettingFields_featuredPosts_Update:{
		permission:"Boolean"
	},
	PostSettingFields_featuredPosts_Delete:{
		permission:"Boolean"
	},
	PostSettingFields_region:{
		create:"PostSettingFields_region_Create",
		read:"PostSettingFields_region_Read",
		update:"PostSettingFields_region_Update",
		delete:"PostSettingFields_region_Delete"
	},
	PostSettingFields_region_Create:{
		permission:"Boolean"
	},
	PostSettingFields_region_Read:{
		permission:"Boolean"
	},
	PostSettingFields_region_Update:{
		permission:"Boolean"
	},
	PostSettingFields_region_Delete:{
		permission:"Boolean"
	},
	PostSettingFields_updatedAt:{
		create:"PostSettingFields_updatedAt_Create",
		read:"PostSettingFields_updatedAt_Read",
		update:"PostSettingFields_updatedAt_Update",
		delete:"PostSettingFields_updatedAt_Delete"
	},
	PostSettingFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostSettingFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostSettingFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostSettingFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostSettingFields_createdAt:{
		create:"PostSettingFields_createdAt_Create",
		read:"PostSettingFields_createdAt_Read",
		update:"PostSettingFields_createdAt_Update",
		delete:"PostSettingFields_createdAt_Delete"
	},
	PostSettingFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostSettingFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostSettingFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostSettingFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostSettingCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostSettingDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	homepageSettingAccess:{
		fields:"HomepageSettingFields",
		create:"HomepageSettingCreateAccess",
		read:"HomepageSettingReadAccess",
		update:"HomepageSettingUpdateAccess",
		delete:"HomepageSettingDeleteAccess"
	},
	HomepageSettingFields:{
		name:"HomepageSettingFields_name",
		region:"HomepageSettingFields_region",
		sections:"HomepageSettingFields_sections",
		updatedAt:"HomepageSettingFields_updatedAt",
		createdAt:"HomepageSettingFields_createdAt"
	},
	HomepageSettingFields_name:{
		create:"HomepageSettingFields_name_Create",
		read:"HomepageSettingFields_name_Read",
		update:"HomepageSettingFields_name_Update",
		delete:"HomepageSettingFields_name_Delete"
	},
	HomepageSettingFields_name_Create:{
		permission:"Boolean"
	},
	HomepageSettingFields_name_Read:{
		permission:"Boolean"
	},
	HomepageSettingFields_name_Update:{
		permission:"Boolean"
	},
	HomepageSettingFields_name_Delete:{
		permission:"Boolean"
	},
	HomepageSettingFields_region:{
		create:"HomepageSettingFields_region_Create",
		read:"HomepageSettingFields_region_Read",
		update:"HomepageSettingFields_region_Update",
		delete:"HomepageSettingFields_region_Delete"
	},
	HomepageSettingFields_region_Create:{
		permission:"Boolean"
	},
	HomepageSettingFields_region_Read:{
		permission:"Boolean"
	},
	HomepageSettingFields_region_Update:{
		permission:"Boolean"
	},
	HomepageSettingFields_region_Delete:{
		permission:"Boolean"
	},
	HomepageSettingFields_sections:{
		create:"HomepageSettingFields_sections_Create",
		read:"HomepageSettingFields_sections_Read",
		update:"HomepageSettingFields_sections_Update",
		delete:"HomepageSettingFields_sections_Delete"
	},
	HomepageSettingFields_sections_Create:{
		permission:"Boolean"
	},
	HomepageSettingFields_sections_Read:{
		permission:"Boolean"
	},
	HomepageSettingFields_sections_Update:{
		permission:"Boolean"
	},
	HomepageSettingFields_sections_Delete:{
		permission:"Boolean"
	},
	HomepageSettingFields_updatedAt:{
		create:"HomepageSettingFields_updatedAt_Create",
		read:"HomepageSettingFields_updatedAt_Read",
		update:"HomepageSettingFields_updatedAt_Update",
		delete:"HomepageSettingFields_updatedAt_Delete"
	},
	HomepageSettingFields_updatedAt_Create:{
		permission:"Boolean"
	},
	HomepageSettingFields_updatedAt_Read:{
		permission:"Boolean"
	},
	HomepageSettingFields_updatedAt_Update:{
		permission:"Boolean"
	},
	HomepageSettingFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	HomepageSettingFields_createdAt:{
		create:"HomepageSettingFields_createdAt_Create",
		read:"HomepageSettingFields_createdAt_Read",
		update:"HomepageSettingFields_createdAt_Update",
		delete:"HomepageSettingFields_createdAt_Delete"
	},
	HomepageSettingFields_createdAt_Create:{
		permission:"Boolean"
	},
	HomepageSettingFields_createdAt_Read:{
		permission:"Boolean"
	},
	HomepageSettingFields_createdAt_Update:{
		permission:"Boolean"
	},
	HomepageSettingFields_createdAt_Delete:{
		permission:"Boolean"
	},
	HomepageSettingCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	HomepageSettingDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	event_viewsAccess:{
		fields:"EventViewsFields",
		create:"EventViewsCreateAccess",
		read:"EventViewsReadAccess",
		update:"EventViewsUpdateAccess",
		delete:"EventViewsDeleteAccess"
	},
	EventViewsFields:{
		date:"EventViewsFields_date",
		eventId:"EventViewsFields_eventId",
		viewCount:"EventViewsFields_viewCount",
		updatedAt:"EventViewsFields_updatedAt",
		createdAt:"EventViewsFields_createdAt"
	},
	EventViewsFields_date:{
		create:"EventViewsFields_date_Create",
		read:"EventViewsFields_date_Read",
		update:"EventViewsFields_date_Update",
		delete:"EventViewsFields_date_Delete"
	},
	EventViewsFields_date_Create:{
		permission:"Boolean"
	},
	EventViewsFields_date_Read:{
		permission:"Boolean"
	},
	EventViewsFields_date_Update:{
		permission:"Boolean"
	},
	EventViewsFields_date_Delete:{
		permission:"Boolean"
	},
	EventViewsFields_eventId:{
		create:"EventViewsFields_eventId_Create",
		read:"EventViewsFields_eventId_Read",
		update:"EventViewsFields_eventId_Update",
		delete:"EventViewsFields_eventId_Delete"
	},
	EventViewsFields_eventId_Create:{
		permission:"Boolean"
	},
	EventViewsFields_eventId_Read:{
		permission:"Boolean"
	},
	EventViewsFields_eventId_Update:{
		permission:"Boolean"
	},
	EventViewsFields_eventId_Delete:{
		permission:"Boolean"
	},
	EventViewsFields_viewCount:{
		create:"EventViewsFields_viewCount_Create",
		read:"EventViewsFields_viewCount_Read",
		update:"EventViewsFields_viewCount_Update",
		delete:"EventViewsFields_viewCount_Delete"
	},
	EventViewsFields_viewCount_Create:{
		permission:"Boolean"
	},
	EventViewsFields_viewCount_Read:{
		permission:"Boolean"
	},
	EventViewsFields_viewCount_Update:{
		permission:"Boolean"
	},
	EventViewsFields_viewCount_Delete:{
		permission:"Boolean"
	},
	EventViewsFields_updatedAt:{
		create:"EventViewsFields_updatedAt_Create",
		read:"EventViewsFields_updatedAt_Read",
		update:"EventViewsFields_updatedAt_Update",
		delete:"EventViewsFields_updatedAt_Delete"
	},
	EventViewsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	EventViewsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	EventViewsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	EventViewsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	EventViewsFields_createdAt:{
		create:"EventViewsFields_createdAt_Create",
		read:"EventViewsFields_createdAt_Read",
		update:"EventViewsFields_createdAt_Update",
		delete:"EventViewsFields_createdAt_Delete"
	},
	EventViewsFields_createdAt_Create:{
		permission:"Boolean"
	},
	EventViewsFields_createdAt_Read:{
		permission:"Boolean"
	},
	EventViewsFields_createdAt_Update:{
		permission:"Boolean"
	},
	EventViewsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	EventViewsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	EventViewsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	post_viewsAccess:{
		fields:"PostViewsFields",
		create:"PostViewsCreateAccess",
		read:"PostViewsReadAccess",
		update:"PostViewsUpdateAccess",
		delete:"PostViewsDeleteAccess"
	},
	PostViewsFields:{
		date:"PostViewsFields_date",
		postId:"PostViewsFields_postId",
		viewCount:"PostViewsFields_viewCount",
		updatedAt:"PostViewsFields_updatedAt",
		createdAt:"PostViewsFields_createdAt"
	},
	PostViewsFields_date:{
		create:"PostViewsFields_date_Create",
		read:"PostViewsFields_date_Read",
		update:"PostViewsFields_date_Update",
		delete:"PostViewsFields_date_Delete"
	},
	PostViewsFields_date_Create:{
		permission:"Boolean"
	},
	PostViewsFields_date_Read:{
		permission:"Boolean"
	},
	PostViewsFields_date_Update:{
		permission:"Boolean"
	},
	PostViewsFields_date_Delete:{
		permission:"Boolean"
	},
	PostViewsFields_postId:{
		create:"PostViewsFields_postId_Create",
		read:"PostViewsFields_postId_Read",
		update:"PostViewsFields_postId_Update",
		delete:"PostViewsFields_postId_Delete"
	},
	PostViewsFields_postId_Create:{
		permission:"Boolean"
	},
	PostViewsFields_postId_Read:{
		permission:"Boolean"
	},
	PostViewsFields_postId_Update:{
		permission:"Boolean"
	},
	PostViewsFields_postId_Delete:{
		permission:"Boolean"
	},
	PostViewsFields_viewCount:{
		create:"PostViewsFields_viewCount_Create",
		read:"PostViewsFields_viewCount_Read",
		update:"PostViewsFields_viewCount_Update",
		delete:"PostViewsFields_viewCount_Delete"
	},
	PostViewsFields_viewCount_Create:{
		permission:"Boolean"
	},
	PostViewsFields_viewCount_Read:{
		permission:"Boolean"
	},
	PostViewsFields_viewCount_Update:{
		permission:"Boolean"
	},
	PostViewsFields_viewCount_Delete:{
		permission:"Boolean"
	},
	PostViewsFields_updatedAt:{
		create:"PostViewsFields_updatedAt_Create",
		read:"PostViewsFields_updatedAt_Read",
		update:"PostViewsFields_updatedAt_Update",
		delete:"PostViewsFields_updatedAt_Delete"
	},
	PostViewsFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PostViewsFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PostViewsFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PostViewsFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PostViewsFields_createdAt:{
		create:"PostViewsFields_createdAt_Create",
		read:"PostViewsFields_createdAt_Read",
		update:"PostViewsFields_createdAt_Update",
		delete:"PostViewsFields_createdAt_Delete"
	},
	PostViewsFields_createdAt_Create:{
		permission:"Boolean"
	},
	PostViewsFields_createdAt_Read:{
		permission:"Boolean"
	},
	PostViewsFields_createdAt_Update:{
		permission:"Boolean"
	},
	PostViewsFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PostViewsCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PostViewsDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	payload_preferencesAccess:{
		fields:"PayloadPreferencesFields",
		create:"PayloadPreferencesCreateAccess",
		read:"PayloadPreferencesReadAccess",
		update:"PayloadPreferencesUpdateAccess",
		delete:"PayloadPreferencesDeleteAccess"
	},
	PayloadPreferencesFields:{
		user:"PayloadPreferencesFields_user",
		key:"PayloadPreferencesFields_key",
		value:"PayloadPreferencesFields_value",
		updatedAt:"PayloadPreferencesFields_updatedAt",
		createdAt:"PayloadPreferencesFields_createdAt"
	},
	PayloadPreferencesFields_user:{
		create:"PayloadPreferencesFields_user_Create",
		read:"PayloadPreferencesFields_user_Read",
		update:"PayloadPreferencesFields_user_Update",
		delete:"PayloadPreferencesFields_user_Delete"
	},
	PayloadPreferencesFields_user_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_user_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_user_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_user_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_key:{
		create:"PayloadPreferencesFields_key_Create",
		read:"PayloadPreferencesFields_key_Read",
		update:"PayloadPreferencesFields_key_Update",
		delete:"PayloadPreferencesFields_key_Delete"
	},
	PayloadPreferencesFields_key_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_key_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_key_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_key_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_value:{
		create:"PayloadPreferencesFields_value_Create",
		read:"PayloadPreferencesFields_value_Read",
		update:"PayloadPreferencesFields_value_Update",
		delete:"PayloadPreferencesFields_value_Delete"
	},
	PayloadPreferencesFields_value_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_value_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_value_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_value_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_updatedAt:{
		create:"PayloadPreferencesFields_updatedAt_Create",
		read:"PayloadPreferencesFields_updatedAt_Read",
		update:"PayloadPreferencesFields_updatedAt_Update",
		delete:"PayloadPreferencesFields_updatedAt_Delete"
	},
	PayloadPreferencesFields_updatedAt_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_updatedAt_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_updatedAt_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_updatedAt_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_createdAt:{
		create:"PayloadPreferencesFields_createdAt_Create",
		read:"PayloadPreferencesFields_createdAt_Read",
		update:"PayloadPreferencesFields_createdAt_Update",
		delete:"PayloadPreferencesFields_createdAt_Delete"
	},
	PayloadPreferencesFields_createdAt_Create:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_createdAt_Read:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_createdAt_Update:{
		permission:"Boolean"
	},
	PayloadPreferencesFields_createdAt_Delete:{
		permission:"Boolean"
	},
	PayloadPreferencesCreateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesReadAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesUpdateAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	PayloadPreferencesDeleteAccess:{
		permission:"Boolean",
		where:"JSONObject"
	},
	GetEventMaxUsedTag:{
		tag:"maxUsedTag"
	},
	maxUsedTag:{
		id:"String",
		name:"String"
	},
	Mutation:{
		createUser:"User",
		updateUser:"User",
		deleteUser:"User",
		refreshTokenUser:"usersRefreshedUser",
		logoutUser:"String",
		unlockUser:"Boolean",
		loginUser:"usersLoginResult",
		forgotPasswordUser:"Boolean",
		resetPasswordUser:"usersResetPassword",
		verifyEmailUser:"Boolean",
		createBlogCategory:"BlogCategory",
		updateBlogCategory:"BlogCategory",
		deleteBlogCategory:"BlogCategory",
		createTag:"Tag",
		updateTag:"Tag",
		deleteTag:"Tag",
		createSpecialTag:"SpecialTag",
		updateSpecialTag:"SpecialTag",
		deleteSpecialTag:"SpecialTag",
		createDate:"Date",
		updateDate:"Date",
		deleteDate:"Date",
		createDistrict:"District",
		updateDistrict:"District",
		deleteDistrict:"District",
		createAudience:"Audience",
		updateAudience:"Audience",
		deleteAudience:"Audience",
		createEvent:"Event",
		updateEvent:"Event",
		deleteEvent:"Event",
		createLocation:"Location",
		updateLocation:"Location",
		deleteLocation:"Location",
		createPost:"Post",
		updatePost:"Post",
		deletePost:"Post",
		createPrivateMedia:"PrivateMedia",
		updatePrivateMedia:"PrivateMedia",
		deletePrivateMedia:"PrivateMedia",
		createMedia:"Media",
		updateMedia:"Media",
		deleteMedia:"Media",
		createBackupMedia:"BackupMedia",
		updateBackupMedia:"BackupMedia",
		deleteBackupMedia:"BackupMedia",
		createEventRanking:"EventRanking",
		updateEventRanking:"EventRanking",
		deleteEventRanking:"EventRanking",
		createLocationRanking:"LocationRanking",
		updateLocationRanking:"LocationRanking",
		deleteLocationRanking:"LocationRanking",
		createAccountSuspendRequest:"AccountSuspendRequest",
		updateAccountSuspendRequest:"AccountSuspendRequest",
		deleteAccountSuspendRequest:"AccountSuspendRequest",
		createBookmark:"Bookmark",
		updateBookmark:"Bookmark",
		deleteBookmark:"Bookmark",
		createViewRecord:"ViewRecord",
		updateViewRecord:"ViewRecord",
		deleteViewRecord:"ViewRecord",
		createEventCategory:"EventCategory",
		updateEventCategory:"EventCategory",
		deleteEventCategory:"EventCategory",
		createComment:"Comment",
		updateComment:"Comment",
		deleteComment:"Comment",
		createEventCalendar:"EventCalendar",
		updateEventCalendar:"EventCalendar",
		deleteEventCalendar:"EventCalendar",
		createSubscription:"Subscription",
		updateSubscription:"Subscription",
		deleteSubscription:"Subscription",
		createEditorChoice:"EditorChoice",
		updateEditorChoice:"EditorChoice",
		deleteEditorChoice:"EditorChoice",
		createBannerSection:"BannerSection",
		updateBannerSection:"BannerSection",
		deleteBannerSection:"BannerSection",
		createMacaoBannerSection:"MacaoBannerSection",
		updateMacaoBannerSection:"MacaoBannerSection",
		deleteMacaoBannerSection:"MacaoBannerSection",
		createBanner:"Banner",
		updateBanner:"Banner",
		deleteBanner:"Banner",
		createCampaign:"Campaign",
		updateCampaign:"Campaign",
		deleteCampaign:"Campaign",
		createAdvertise:"Advertise",
		updateAdvertise:"Advertise",
		deleteAdvertise:"Advertise",
		createDevice:"Device",
		updateDevice:"Device",
		deleteDevice:"Device",
		createNotification:"Notification",
		updateNotification:"Notification",
		deleteNotification:"Notification",
		createNewsletter:"Newsletter",
		updateNewsletter:"Newsletter",
		deleteNewsletter:"Newsletter",
		createRedirectSetting:"RedirectSetting",
		updateRedirectSetting:"RedirectSetting",
		deleteRedirectSetting:"RedirectSetting",
		createStatic:"Static",
		updateStatic:"Static",
		deleteStatic:"Static",
		createCriteriaSetting:"CriteriaSetting",
		updateCriteriaSetting:"CriteriaSetting",
		deleteCriteriaSetting:"CriteriaSetting",
		createOrganiserSetting:"OrganiserSetting",
		updateOrganiserSetting:"OrganiserSetting",
		deleteOrganiserSetting:"OrganiserSetting",
		createPostSetting:"PostSetting",
		updatePostSetting:"PostSetting",
		deletePostSetting:"PostSetting",
		createHomepageSetting:"HomepageSetting",
		updateHomepageSetting:"HomepageSetting",
		deleteHomepageSetting:"HomepageSetting",
		createEventView:"EventView",
		updateEventView:"EventView",
		deleteEventView:"EventView",
		createPostView:"PostView",
		updatePostView:"PostView",
		deletePostView:"PostView",
		createPayloadPreference:"PayloadPreference",
		updatePayloadPreference:"PayloadPreference",
		deletePayloadPreference:"PayloadPreference",
		AddEventView:"Boolean",
		ItemBookmark:"Boolean",
		ItemUnbookmark:"Boolean",
		readItem:"Boolean",
		AddPostView:"Boolean",
		LoginUser:"usersLoginResult",
		RegisterUser:"User",
		ActiveUser:"Boolean",
		ReActivePasswordUser:"String",
		SSOLoginUser:"usersLoginResult",
		SuspendUser:"Boolean",
		UpdateUserPassword:"Boolean",
		SubscribeOrganiser:"Boolean",
		UnSubscribeOrganiser:"Boolean",
		PostMessage:"Boolean",
		AddBannerView:"Boolean",
		AddDevice:"Boolean",
		RemoveDevice:"Boolean",
		UpdateNotifications:"Boolean",
		DeleteNotification:"Boolean",
		CreateNewsletter:"Boolean"
	},
	usersRefreshedUser:{
		exp:"Int",
		refreshedToken:"String",
		strategy:"String",
		user:"usersJWT"
	},
	usersJWT:{
		name:"String",
		email:"EmailAddress",
		collection:"String"
	},
	usersLoginResult:{
		exp:"Int",
		token:"String",
		user:"User"
	},
	usersResetPassword:{
		token:"String",
		user:"User"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}