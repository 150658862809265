import {
  ApolloClient,
  from,
  HttpLink,
  type resetApolloContext,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import {
  InvalidationPolicyCache,
  RenewalPolicy,
} from "@nerdwallet/apollo-cache-policies";
import getConfig from "~/libs/config";
import { getPlatform } from "~/libs/platform";
import { transformArrayToObject } from "./commons";
import { setContext } from '@apollo/client/link/context';

const cache = new InvalidationPolicyCache({
  invalidationPolicies: {
    timeToLive: 5 * 60 * 1000, // 5mins TTL on all types in the cache
    renewalPolicy: RenewalPolicy.WriteOnly,
    // types: {
    //   Events: {
    //     timeToLive: 3600 * 1000 * 24 // 24hr TTL specifically for the Events type in the cache
    //   }
    // }
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Extensions: ${JSON.stringify(extensions)}`
      );
    });
  if (networkError) console.error(`[Network error]: ${networkError}`);
});


const requestURL = (url:string) => {
  return from([
    errorLink,
    new HttpLink({ uri: url}),
  ])
}

const serviceRequestHeader = setContext((_, { headers }) => {
  const cookieHeader = headers?.get("Cookie");
  const cookieHeaderArray = cookieHeader?.split(";");
  const token =
    cookieHeaderArray &&
    cookieHeaderArray?.length &&
    cookieHeaderArray?.length > 0
      ? transformArrayToObject(cookieHeaderArray)?.["server-token"]
      : undefined;
      
  return {
    headers: {
      "x-platform-type": getPlatform(),
      client: "mainsite",
      ...(token && { authorization: `JWT ${token}` }),
    },
  };
});
export const getServerSideApollo = (request: Request) => {
  const GRAPHQL_SERVER_URL = process?.env?.GRAPHQL_SERVER_URL ?? '';
  return new ApolloClient({
    cache,
    link: serviceRequestHeader.concat(requestURL(GRAPHQL_SERVER_URL)),
  });
};


export const getClientSideApollo = () => {
  const REMIX_PUBLIC_GRAPHQL_CLIENT_URL = getConfig().REMIX_PUBLIC_GRAPHQL_CLIENT_URL
  return new ApolloClient({
    cache,
    link: requestURL(REMIX_PUBLIC_GRAPHQL_CLIENT_URL),
    headers: {
      "x-platform-type": getPlatform(),
      client: "mainsite",
    },
  });
};
