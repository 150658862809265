import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";
import { FallbackLocaleInputType } from "~/zeus";

const GetCriteriaSettingsQuery = typedGql("query")({
  GetCriteriaSettings: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      where: $("where", "CriteriaSetting_where"),
      limit: $("limit", "Int"),
    },
    {
      docs: {
        criteria: {
          date: [
            {},
            {
              id: true,
              name: true,
              value: true,
            },
          ],
          audience: [
            {},
            {
              id: true,
              name: true,
              value: true,
              icon: [
                {},
                {
                  url: true,
                },
              ],
            },
          ],
          category: [
            {},
            {
              id: true,
              name: true,
              value: true,
              icon: [
                {},
                {
                  url: true,
                },
              ],
            },
          ],
          tag: [
            {},
            {
              id: true,
              name: true,
              value: true,
            },
          ],
          region: true,
        },
      },
    },
  ],
});

export default GetCriteriaSettingsQuery;
